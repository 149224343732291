import React from "react"
import PropTypes from "prop-types"
import { Card } from "../common/card-component"
import { getCareerLevelName, getFullName } from "../../lib/helpers"
import { CandidateLocation } from "./candidate-location-component"
import { Img } from "../common/img-component"

export function CandidateCard({
  candidate,
  controls,
  onClick,
  style,
  className,
}) {
  const name = getFullName(candidate)

  return (
    <Card
      className={className}
      title={name}
      additionalInfo={<CandidateLocation candidate={candidate} />}
      subtitle={getCareerLevelName(candidate.careerLevel)}
      controls={controls}
      onClick={onClick}
      style={style}
      image={
        <Img
          src={candidate.picture}
          alt={name}
          width="80"
          height="80"
          className="d-none d-md-inline rounded-circle clickable"
        />
      }
    />
  )
}

CandidateCard.propTypes = {
  candidate: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  controls: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

CandidateCard.defaultProps = {
  candidate: {},
  className: "",
}
