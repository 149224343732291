import React from "react"
import { map } from "ramda"
import { FormattedMessage } from "react-intl"
import { config } from "../config"
import { Link } from "react-router-dom"
import { PROBLEMS } from "../constants"

export const atsErrorTypes = Object.freeze({
  notFound: "notFound",
  unique: "unique",
  invalid: "invalid",
})

export const atsErrorsMapping = {
  [atsErrorTypes.notFound]: () => (
    <FormattedMessage id="app.error.ats.code.notFound" />
  ),
  [atsErrorTypes.unique]: () => (
    <FormattedMessage id="app.error.ats.code.unique" />
  ),
  [atsErrorTypes.invalid]: () => (
    <FormattedMessage id="app.error.ats.code.invalid" />
  ),
}

export const moErrorTypes = Object.freeze({
  defaultError: "defaultError",
  serverError: "serverError",
  networkError: "networkError",
  companySlugExists: "companySlugExists",
  emailNotUnique: "emailNotUnique",
  accountExists: "accountExists",
  notFound: "notFound",
  emailNotFound: "emailNotFound",
  accountNotConfirmed: "accountNotConfirmed",
  wrongPassword: "wrongPassword",
  matchRejected: "matchRejected",
  managerAssignmentError: "managerAssignmentError",
  notEmpty: "notEmpty",
  cardError: "cardError",
  wrongAccountTypeCandidate: "wrongAccountTypeCandidate",
  wrongAccountTypeRecruiter: "wrongAccountTypeRecruiter",
  accountInactive: "accountInactive",
  wrongCredentials: "wrongCredentials",
  setupIntentAuthenticationFailure: "setupIntentAuthenticationFailure",
  cardAttachFail: "cardAttachFail",
  notAuthenticated: "notAuthenticated",
  atsJobsWereNotImported: "atsJobsWereNotImported",
  blank: "blank",
  authenticationFailed: "authenticationFailed",
  cancelSubscriptionFailed: "cancelSubscriptionFailed",
  duplicationError: "duplicationError",
  permissionDenied: "permissionDenied",
  machineError: "machineError",
  couponNotApplicable: "couponNotApplicable",
  stripeError: "stripeError",
})

const moSimpleErrors = map(
  code => () => <FormattedMessage id={`app.error.code.${code}`} />,
  moErrorTypes,
)

const moCustomErrors = {
  [moErrorTypes.cardAttachFail]: () => (
    <FormattedMessage id="app.error.code.cardError" />
  ),
  [moErrorTypes.wrongAccountTypeCandidate]: () => (
    <FormattedMessage
      id="app.error.code.wrongAccountType.candidate"
      values={{
        link: (
          <a href={config.moberries.candidate.url}>
            {config.moberries.candidate.url}
          </a>
        ),
      }}
    />
  ),
  [moErrorTypes.wrongAccountTypeRecruiter]: () => (
    <FormattedMessage
      id="app.error.code.wrongAccountType.recruiter"
      values={{
        link: (
          <a href={config.moberries.affiliate.url}>
            {config.moberries.affiliate.url}
          </a>
        ),
      }}
    />
  ),
  [moErrorTypes.wrongCredentials]: values => (
    <FormattedMessage
      id="app.error.code.wrongCredentials.text"
      values={{
        link: (
          <Link
            to={{
              pathname: "/password-recovery",
              state: { email: values.email },
            }}
          >
            <FormattedMessage id="app.error.code.wrongCredentials.link" />
          </Link>
        ),
      }}
    />
  ),
  [moErrorTypes.accountInactive]: () => (
    <FormattedMessage
      id="app.error.code.accountInactive.text"
      values={{
        link: (
          <Link to="/password-recovery">
            <FormattedMessage id="app.error.code.accountInactive.link" />,
          </Link>
        ),
      }}
    />
  ),
  [moErrorTypes.atsJobsWereNotImported]: () => (
    <FormattedMessage
      id="app.error.ats.jobs.notImported"
      values={{
        link: (
          <Link to="/integrations/ats/import">
            <FormattedMessage id="app.error.ats.jobs.notImported.link" />
          </Link>
        ),
      }}
    />
  ),
  [moErrorTypes.cancelSubscriptionFailed]: () => (
    <FormattedMessage
      id="app.error.code.cancelSubscriptionFailed"
      values={{
        email: (
          <a href="mailto:am@moberries.com">
            <FormattedMessage id="app.common.accountManagerEmail" />
          </a>
        ),
      }}
    />
  ),
  [moErrorTypes.stripeError]: () => (
    <FormattedMessage
      id="app.error.code.stripeError"
      values={{
        email: <a href="mailto:support@moberries.com">support@moberries.com</a>,
      }}
    />
  ),
}

export const moErrorsMapping = {
  ...moSimpleErrors,
  ...moCustomErrors,
}

class CardError extends Error {
  constructor() {
    super("Card error")
    this.problem = PROBLEMS.CLIENT_ERROR
    this.code = moErrorTypes.cardError
  }
}

export const customErrors = {
  CardError,
}
