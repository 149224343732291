import React from "react"
import PropTypes from "prop-types"
import { Media } from "reactstrap"
import { Img } from "../common/img-component"
import { Link } from "react-router-dom"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"
import { ATS_JOB_SYNC_OPTIONS } from "../../constants"

export const AtsProviderSection = ({ provider, atsCompanyIntegration }) => {
  const { name, slug, logoUrl } = provider
  const { jobSyncType } = atsCompanyIntegration
  return (
    <div className="px-0 ">
      <div className="bg-white p-3 rounded">
        <h4 className="mb-3">
          <span className="icon-circled mr-2">
            <i className="fas fa-plug" />
          </span>
          <FormattedMessage id="app.page.dashboard.integrationCard.header" />
        </h4>
        <p className="mb-3">
          <FormattedMessage id="app.page.dashboard.integrationCard.description" />
        </p>

        <Media>
          <Media left>
            <Link to={`/integrations/ats/providers/${slug}`}>
              <Img
                src={logoUrl}
                width="90"
                height="90"
                className="d-none d-sm-inline"
              />
            </Link>
          </Media>
          <Media body className="px-sm-3">
            <h4 className="border-bottom pb-2 mb-0"> {name}</h4>
            <div className="border-bottom py-2 d-flex justify-content-between m-0">
              <div>
                <FormattedMessage id="app.page.integrations.settings.applicationExport.header" />
              </div>
              <div className="text-success">
                <strong>
                  <FormattedMessage id="app.page.integrations.settings.matchExportType.onCanAccept.label" />
                </strong>
              </div>
            </div>
            <div className="border-bottom py-2 d-flex justify-content-between">
              <div>
                <FormattedMessage id="app.page.integrations.settings.jobsImport.header" />
              </div>
              <div className="text-success">
                {jobSyncType === ATS_JOB_SYNC_OPTIONS.MANUAL ? (
                  <strong>
                    <FormattedMessage id="app.page.integrations.settings.jobsImport.manual" />
                  </strong>
                ) : (
                  <strong>
                    <FormattedMessage id="app.page.dashboard.integrationCard.autoJobSync" />
                  </strong>
                )}
              </div>
            </div>
            <div className="bg-white mt-2">
              {jobSyncType === ATS_JOB_SYNC_OPTIONS.MANUAL && (
                <Button
                  color="primary"
                  className="mr-2"
                  tag={Link}
                  to="/integrations/ats/import"
                >
                  <i className="fas fa-arrow-alt-circle-down mr-md-1" />
                  <span className="d-none d-md-inline">
                    <FormattedMessage id="app.page.integrations.importJobs" />
                  </span>
                </Button>
              )}
              <Button
                outline
                color="secondary"
                tag={Link}
                to={`/integrations/ats/${provider.slug}/connect`}
              >
                <i className="fas fa-cog mr-md-1" />
                <span className="d-none d-md-inline">
                  <FormattedMessage id="app.page.integrations.manage" />
                </span>
              </Button>
            </div>
          </Media>
        </Media>
      </div>
    </div>
  )
}

AtsProviderSection.propTypes = {
  provider: PropTypes.object,
  atsCompanyIntegration: PropTypes.object,
}
