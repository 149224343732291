import React, { useMemo } from "react"
import { Container, Row, Col } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { DualChartVerticalLayout } from "../../../components/layouts/analytics/dual-chart-layout"
import { JobCategoryCareerLevelChart } from "../../../components/charts/job-category-career-level/job-category-career-level"
import { JobCategoryJobRoleChart } from "../../../components/charts/job-category-job-role/job-category-job-role"
import { RemoteWorkPopularityRateChart } from "../../../components/charts/remote-work-popularity-rate/remote-work-popularity-rate"
import { JobOfferPopularityRateChart } from "../../../components/charts/job-offer-popularity-rate/job-offer-popularity-rate"
import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { categoryMapper } from "../../../components/charts/lib/helpers"
import { StrategyBuilderPageSkeleton } from "./strategy-builder-page-skeleton/strategy-builder-page-skeleton"

import styles from "./strategy-builder-page.module.scss"

export const StrategyBuilderPage = () => {
  const { data: categories, isLoading: isLoadingCategories } = useAxiosQuery(
    ApiKeys.JobCategories,
    moberriesApi.getJobCategories,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  const availableCategories = useMemo(
    () => categoryMapper(categories?.results),
    [categories],
  )

  if (isLoadingCategories) {
    return <StrategyBuilderPageSkeleton />
  }

  return (
    <Container className="container-analytics">
      <Row className={styles.header}>
        <Col lg="12">
          <h1>
            <FormattedMessage id="app.page.analytics.strategyBuilder.header" />
          </h1>
          <h6>
            <FormattedMessage id="app.page.analytics.strategyBuilder.subheader" />
          </h6>
        </Col>
      </Row>

      <Row className={styles.content}>
        <Col lg="6">
          <DualChartVerticalLayout
            header={
              <FormattedMessage id="app.page.analytics.strategyBuilder.content.salary.header" />
            }
            separator={styles.separator}
          >
            <DualChartVerticalLayout.Top>
              <JobCategoryCareerLevelChart />
            </DualChartVerticalLayout.Top>
            <DualChartVerticalLayout.Bottom>
              <JobCategoryJobRoleChart categories={availableCategories} />
            </DualChartVerticalLayout.Bottom>
          </DualChartVerticalLayout>
        </Col>

        <Col lg="6">
          <DualChartVerticalLayout
            header={
              <FormattedMessage id="app.page.analytics.strategyBuilder.content.trends.header" />
            }
            separator={styles.separator}
          >
            <DualChartVerticalLayout.Top>
              <JobOfferPopularityRateChart categories={availableCategories} />
            </DualChartVerticalLayout.Top>
            <DualChartVerticalLayout.Bottom>
              <RemoteWorkPopularityRateChart />
            </DualChartVerticalLayout.Bottom>
          </DualChartVerticalLayout>
        </Col>
      </Row>
    </Container>
  )
}
