import { FormattedMessage } from "react-intl"
import styles from "./campaigns-header.module.scss"

export const CampaignsHeader = () => (
  <>
    <h2 className={styles.title}>
      <FormattedMessage id="app.modal.virtualRecruiter.listOfCampaigns" />
    </h2>
  </>
)
