import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap"
import { JobConditionSelect } from "../forms/job-condition-select-component"
import { ACT, JOB_CONDITION_TYPES } from "../../constants"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api as moberriesApi, ApiKeys } from "../../lib/moberries-api"
import { queryClient } from "../../lib/query-client"

export const JobActivationModal = ({
  isOpen,
  toggle,
  companySubscription,
  jobId,
}) => {
  const [condition, setCondition] = useState({ type: JOB_CONDITION_TYPES.flat })

  const { mutateAsync: updateJob } = useAxiosMutation(moberriesApi.updateJob, {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiKeys.Job, { id: jobId }])
      toggle()
    },
  })
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id="app.common.confirm" />
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <FormattedMessage id="app.page.jobEdit.limit.notReached.confirmation.content" />
          </div>
          <JobConditionSelect
            companySubscription={companySubscription}
            input={{
              onChange: setCondition,
              value: condition,
              name: "job-condition-select-modal",
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() =>
              updateJob({ id: jobId, job: { condition, status: ACT } })
            }
          >
            <FormattedMessage id="app.common.confirm" />
          </Button>
          <Button color="light" onClick={toggle}>
            <FormattedMessage id="app.common.cancel" />
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

JobActivationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  companySubscription: PropTypes.object.isRequired,
  jobId: PropTypes.number.isRequired,
}

JobActivationModal.defaultProps = {
  isOpen: false,
}
