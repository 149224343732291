import { showErrorAlert } from "../lib/alerts"
import { store } from "../lib/store"
import { atsApi } from "../lib/ats-api"
import { getProviderBySlug } from "../lib/helpers"

export const refreshAtsConnection = async () => {
  try {
    const { data: atsCompanyIntegationList } =
      await atsApi.getAtsCompanyIntegrationList()

    const { data: providers } = await atsApi.getAtsProvidersList()

    const atsCompanyIntegration = atsCompanyIntegationList?.[0]

    const connectedProvider = getProviderBySlug(
      providers,
      atsCompanyIntegration?.provider,
    )

    store.setState({ atsCompanyIntegration, connectedProvider, providers })
  } catch (err) {
    showErrorAlert({ err })
  }
}
