import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Nav } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { BUTTON_TAGNAME } from "../../constants"
import { Button } from "../common/button-component"
import { useOpenClose } from "../../hooks/use-open-close"

export const MobileNav = ({ children }) => {
  const { value: isOpen, toggle } = useOpenClose({ initialValue: false })
  return (
    <Fragment>
      <Button onClick={toggle} className="d-md-none" color="dark">
        {isOpen ? <i className="fa fa-times" /> : <i className="fa fa-bars" />}
      </Button>
      {isOpen && (
        <Nav
          vertical
          className="offcanvas-nav position-fixed h-100 py-2 d-md-none bg-white w-80"
        >
          <div className="mb-2">
            <Button
              color="link"
              className="text-dark font-weight-bold nav-link"
              onClick={toggle}
            >
              <i className="fas fa-times mr-2" />
              <FormattedMessage id="app.navbar.menu" />
            </Button>
          </div>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              onClick:
                child.props.tag === BUTTON_TAGNAME
                  ? child.props.onClick
                  : toggle,
            }),
          )}
        </Nav>
      )}
    </Fragment>
  )
}

MobileNav.propTypes = {
  children: PropTypes.node,
}
