import React from "react"
import { HeaderLoggedIn } from "./header-logged-in-component"
import { HeaderLoggedOut } from "./header-logged-out-component"
import { useStore } from "../../lib/store"
import { isLoggedInSelector } from "../../lib/helpers"

export const Header = () => {
  const isLoggedIn = useStore(isLoggedInSelector)
  if (isLoggedIn) {
    return <HeaderLoggedIn />
  } else {
    return <HeaderLoggedOut />
  }
}
