import * as yup from "yup"
import { arePredefinedAnswersRequired } from "../lib/helpers"

export const jobApplicationQuestionSchema = yup.object().shape({
  questionType: yup.object().required("app.validators.value.required"),
  title: yup.string().required("app.validators.value.required"),
  isRequired: yup.boolean(),
  predefinedAnswers: yup.array().when("questionType", (questionType, schema) =>
    arePredefinedAnswersRequired(questionType?.value)
      ? schema.of(
          yup.object().shape({
            answer: yup.string().required("app.validators.value.required"),
          }),
        )
      : schema,
  ),
})
