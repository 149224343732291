import React, { Fragment } from "react"
import { Badge } from "reactstrap"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { ReferredCandidatePageSkeleton } from "../components/skeletons/referred-candidate-page-skeleton"
import { ErrorPage } from "../pages/error-page"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api, ApiKeys } from "../lib/moberries-api"
import { useRouteMatch } from "react-router"
import { CandidateCard } from "../components/candidate/candidate-card-component"
import { Referred } from "../components/common/referred-component"
import { CandidateCVButton } from "../components/candidate/candidate-cv-button"
import {
  formatSalary,
  getCareerLevelName,
  getLocationName,
  isCvUploaded,
  isLinkedinProfileConnected,
  trimWebReference,
} from "../lib/helpers"
import { FormattedMessage } from "react-intl"
import { DetailView } from "../components/detail-view/detail-view-component"
import { DetailViewSection } from "../components/detail-view/detail-view-section-component"
import { DetailViewProgressBar } from "../components/detail-view/detail-view-progress-bar-component"
import { JOB_LEVELS, JOB_TYPES, LANG_LEVELS } from "../default-options"
import { DetailViewAttribute } from "../components/detail-view/detail-view-attribute-component"

export const ReferredCandidatePage = () => {
  const routeMatch = useRouteMatch()
  const { data: candidate, error } = useAxiosQuery(
    [ApiKeys.Candidate, { id: routeMatch.params.id }],
    api.getCandidate,
  )
  if (error) {
    return <ErrorPage err={error} />
  }

  if (!candidate) {
    return <ReferredCandidatePageSkeleton />
  }

  return (
    <Fragment>
      <SingleColumnLayout>
        <CandidateCard
          candidate={candidate}
          controls={
            <div className="mb-1 d-flex flex-column">
              <Referred referredAt={candidate.completedAt} />
              {(isCvUploaded(candidate.candidateCv) ||
                isLinkedinProfileConnected(candidate.socialAccounts)) && (
                <CandidateCVButton
                  candidate={candidate}
                  color="primary"
                  className="btn-pill mt-1"
                >
                  <i className="fas fa-eye mr-lg-1" />
                  <FormattedMessage id="app.common.previewCv" />
                </CandidateCVButton>
              )}
            </div>
          }
        />
        <div className="bg-white p-3">
          <DetailView>
            {candidate.jobRoles.length > 0 && (
              <DetailViewSection
                title={
                  <FormattedMessage id="app.candidate.details.experience" />
                }
              >
                {candidate.jobRoles.map(jobRole => (
                  <DetailViewProgressBar
                    key={jobRole.id}
                    total={JOB_LEVELS.length}
                    completed={jobRole.level}
                    title={jobRole.name}
                    value={
                      <FormattedMessage id={JOB_LEVELS[jobRole.level].name} />
                    }
                  />
                ))}
              </DetailViewSection>
            )}
            {candidate.skills.length > 0 && (
              <DetailViewSection
                title={<FormattedMessage id="app.candidate.details.skills" />}
              >
                <div className="mt-3">
                  {candidate.skills.map(skill => (
                    <Badge key={skill.id} color="light" className="mb-1 mr-1">
                      {skill.name}
                    </Badge>
                  ))}
                </div>
              </DetailViewSection>
            )}

            {candidate.languages.length > 0 && (
              <DetailViewSection
                title={
                  <FormattedMessage id="app.candidate.details.languages" />
                }
              >
                {candidate.languages.map(language => (
                  <DetailViewProgressBar
                    key={language.id}
                    total={LANG_LEVELS.length}
                    completed={language.level}
                    title={language.name}
                    value={
                      <FormattedMessage id={LANG_LEVELS[language.level].name} />
                    }
                  />
                ))}
              </DetailViewSection>
            )}

            <DetailViewSection
              title={<FormattedMessage id="app.page.jobDetails.jobClaims" />}
            >
              <DetailViewAttribute
                title={<FormattedMessage id="app.candidate.details.salary" />}
              >
                {formatSalary(candidate.salaryMin, 0)}
              </DetailViewAttribute>
              <DetailViewAttribute
                title={
                  <FormattedMessage id="app.candidate.details.career.level" />
                }
              >
                {getCareerLevelName(candidate.careerLevel)}
              </DetailViewAttribute>
              <DetailViewAttribute
                title={
                  <FormattedMessage id="app.candidate.details.job.types" />
                }
              >
                {candidate.jobTypes.map((jobType, idx) => (
                  <span key={jobType}>
                    <FormattedMessage
                      id={JOB_TYPES.find(jt => jt.id === jobType).name}
                    />
                    {idx !== candidate.jobTypes.length - 1 && ", "}
                  </span>
                ))}
              </DetailViewAttribute>
            </DetailViewSection>

            <DetailViewSection
              title={<FormattedMessage id="app.page.jobDetails.location" />}
            >
              <DetailViewAttribute
                title={<FormattedMessage id="app.candidate.details.remote" />}
              >
                <FormattedMessage
                  id={candidate.remote ? "app.common.yes" : "app.common.no"}
                />
              </DetailViewAttribute>
              <DetailViewAttribute
                title={
                  <FormattedMessage id="app.candidate.details.relocation" />
                }
              >
                <FormattedMessage
                  id={candidate.relocate ? "app.common.yes" : "app.common.no"}
                />
              </DetailViewAttribute>
              {candidate.locations.length > 0 && (
                <DetailViewAttribute
                  title={
                    <FormattedMessage id="app.candidate.details.relocations" />
                  }
                >
                  {candidate.locations.map((location, idx) => (
                    <span key={location.placeId}>
                      {getLocationName(location)}
                      {idx !== candidate.locations.length - 1 && " "}
                    </span>
                  ))}
                </DetailViewAttribute>
              )}

              {candidate.locations.length > 0 && (
                <DetailViewAttribute
                  title={
                    <FormattedMessage id="app.page.match.jobClaims.relocation.preferredCities" />
                  }
                >
                  {candidate.locations.map((l, idx) => (
                    <span key={l.placeId}>
                      {getLocationName(l)}
                      {idx !== candidate.locations.length - 1 && "; "}
                    </span>
                  ))}
                </DetailViewAttribute>
              )}

              {candidate.relocationCountries.length > 0 && (
                <DetailViewAttribute
                  title={
                    <FormattedMessage id="app.page.match.jobClaims.relocation.preferredCountries" />
                  }
                >
                  {candidate.relocationCountries.map((l, idx) => (
                    <span key={l.placeId}>
                      {getLocationName(l)}
                      {idx !== candidate.relocationCountries.length - 1 && "; "}
                    </span>
                  ))}
                </DetailViewAttribute>
              )}
            </DetailViewSection>

            <DetailViewSection
              title={
                <FormattedMessage id="app.candidate.details.webreferences" />
              }
            >
              <div className="mt-2">
                <ul className="m-0 list-unstyled text-smaller">
                  {candidate.references.map(r => (
                    <li key={r} className="mt-1">
                      <a href={r} target="_blank" rel="noopener noreferrer">
                        {trimWebReference(r)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </DetailViewSection>
            <DetailViewSection title="Contact data">
              <div className="mt-2 text-smaller">
                <i className="fas fa-envelope mr-2 fa-fw" />
                <a href={"mailto:" + candidate.email}>{candidate.email}</a>
              </div>
            </DetailViewSection>
          </DetailView>
        </div>
      </SingleColumnLayout>
    </Fragment>
  )
}
