import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { formatDate, isMatchRejected } from "../../lib/helpers"
import { PopoverBody, UncontrolledPopover } from "reactstrap"
import cn from "classnames"

export function Matched({ match }) {
  const icon = cn("mr-1 fas", {
    "fa-check text-muted": !isMatchRejected(match),
    "fa-times text-danger": isMatchRejected(match),
  })
  const classNames = cn("text-right text-smaller", {
    "text-muted": !isMatchRejected(match),
    "text-danger": isMatchRejected(match),
  })

  return (
    <Fragment>
      <div
        className={classNames}
        id={`uncontrolled-popover-accepted-by-${match.id}`}
      >
        <i className={icon} />
        {formatDate(match.reviewedAt)}
      </div>
      {match.reviewedBy.name && (
        <UncontrolledPopover
          trigger="hover"
          target={`uncontrolled-popover-accepted-by-${match.id}`}
          placement="top"
        >
          <PopoverBody>
            {isMatchRejected(match) ? (
              <FormattedMessage
                values={{
                  manager: match.reviewedBy.name,
                }}
                id="app.page.matchList.rejected.tooltip"
              />
            ) : (
              <FormattedMessage
                values={{
                  manager: match.reviewedBy.name,
                }}
                id="app.page.matchList.accepted.tooltip"
              />
            )}
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </Fragment>
  )
}

Matched.propTypes = {
  match: PropTypes.object.isRequired,
}

Matched.defaultProps = {}
