export const legendsMap = {
  onlineAds: {
    color: "#F4D01E",
    mutedColor: "#ffee97",
  },
  otherMarketing: {
    color: "#A1CD49",
    mutedColor: "#c6e58a",
  },
  jobBoards: {
    color: "#69A265",
    mutedColor: "#8ed08a",
  },
  organic: {
    color: "#00C3F9",
    mutedColor: "#93eaff",
  },
  referral: {
    color: "#2F80ED",
    mutedColor: "#659eee",
  },
}

export const candidatesChartDataMapper = (data = {}) => {
  const final = {}
  Object.entries(data)
    .filter(d => d[1] > 0)
    .forEach(d => {
      final[d[0]] = {}
      final[d[0]].value = d[1]
      final[d[0]].colors = legendsMap[d[0]]
    })
  return {
    data: Object.values(final).map(i => Math.round(i.value)),
    labels: Object.entries(final).map(([label, value]) => ({
      color: value.colors.color,
      label,
    })),
    colors: Object.values(final).map(i => i.colors),
  }
}
