import * as Sentry from "@sentry/react"
import { config } from "../../config"
import cookie from "js-cookie"
import cn from "classnames"
import Hashids from "hashids"
import humps from "humps"
import {
  differenceInHours,
  format,
  formatISO,
  isToday,
  parseISO,
} from "date-fns"
import {
  CAREER_LEVELS,
  CANDIDATE_AVAILABILITY,
  JOB_LEVELS,
} from "../../default-options"
import { FormattedMessage, FormattedTime } from "react-intl"
import React from "react"
import qs from "qs"
import { moErrorsMapping, atsErrorsMapping } from "../server-errors"
import {
  curry,
  is,
  gte,
  ifElse,
  always,
  prop,
  isNil,
  uniq,
  difference,
  intersection,
  innerJoin,
  differenceWith,
  map,
  pluck,
  eqProps,
  head,
  pipe,
  reduce,
  set,
  lensProp,
  pathSatisfies,
  path,
  compose,
  join,
  juxt,
  toUpper,
  tail,
  replace,
  addIndex,
} from "ramda"
import {
  LOCALES,
  MATCH_STATUSES,
  ACT,
  PAU,
  DEL,
  REV,
  USER_GROUPS,
  PROBLEMS,
  JOB_CONDITION_TYPES,
  APPLICATION_QUESTION_TYPES,
} from "../../constants"
import { scroller } from "react-scroll"
import { FormattedRelative } from "../../components/common/formatted-relative"
import { api } from "../moberries-api"
import { FormattedDate } from "../../components/common/formatted-date-component"
import {
  isComSubscriptionExpired,
  isComSubscriptionPaused,
} from "./subscription-helpers"
import { store } from "../store"
import LogRocket from "logrocket"
import { requestSanitizer, responseSanitizer } from "../logrocket-sanitizers"

export * from "./subscription-helpers"
export * from "./gtm"
export * from "./ats-integrations-helpers"
export * from "./selectors"

export function getActionType(...args) {
  return args.join("_")
}

export function pageToOffset(page, limit) {
  return limit * (page - 1)
}

export const isWithin = curry((min, max, value) => {
  const isNumber = is(Number)
  return (
    isNumber(min) &&
    isNumber(max) &&
    isNumber(value) &&
    gte(value, min) &&
    gte(max, value)
  )
})

export const in200s = isWithin(200, 299)
export const in400s = isWithin(400, 499)
export const in500s = isWithin(500, 599)
export const statusNil = ifElse(isNil, always(undefined), prop("status"))

export function isMethod(method, err) {
  return pathSatisfies(
    m => m === method.toLowerCase(),
    ["config", "method"],
    err,
  )
}

export function isStatus(status, err) {
  return pathSatisfies(s => s === status, ["response", "status"], err)
}

export function isProblem(problem, err) {
  return pathSatisfies(s => s === problem, ["problem"], err)
}

export function hasCode(err) {
  return Boolean(path(["code"], err))
}

export function hasProblem(err) {
  return Boolean(path(["problem"], err))
}

export function getDefaultLocale() {
  let locale = "en"
  const browserLang = navigator.language || navigator.browserLanguage

  if (browserLang) {
    locale = browserLang.split("-")[0]
  }

  const cookieLocale = cookie.get(config.cookie.locale)

  if (cookieLocale) {
    locale = cookieLocale
  }

  return ensureLocale(locale)
}

export function ensureLocale(locale) {
  if (!LOCALES.includes(locale)) {
    return "en"
  }

  return locale
}

export async function getMessages(locale) {
  const messages = await import(
    /* webpackChunkName: "locale/[request]" */ `../../locales/${locale}.json`
  )
  return messages
}

// ramda stuff

export const arrayToObject = curry((array, idProp) =>
  reduce((prev, curr) => set(lensProp(curr[idProp]), curr, prev), {}, array),
)

export const mapIndexed = addIndex(map)

export function mapMoberriesApiValidationError(data) {
  let errors = {}

  if (data.nonFieldErrors) {
    errors._error = pipe(pluck("code"), head)(data.nonFieldErrors)
  }

  if (data.fieldErrors) {
    errors = {
      ...errors,
      ...map(pipe(pluck("code"), head), data.fieldErrors),
    }
  }

  if (!data.fieldErrors && !data.nonFieldErrors) {
    errors._error = data.code
  }

  errors = {
    ...errors,
    ...map(
      code => moErrorsMapping[humps.camelize(code)] || humps.camelize(code),
      errors,
    ),
  }

  return errors
}

export function mapAtsIntegrationApiValidationError(data) {
  return map(({ code }) => atsErrorsMapping[code] || code, data)
}

export function stopPropagation(fn) {
  return e => {
    e.stopPropagation()
    return fn(e)
  }
}

export function preventDefault(fn) {
  return e => {
    e.preventDefault()
    return fn(e)
  }
}

export function generateInviteURL(userId, companyId) {
  const hashIds = new Hashids()

  const value = `${config.moberries.candidate.url}/invite/${hashIds.encode(
    userId,
    companyId,
  )}`
  return value
}

export function randomInteger(min, max) {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1))
}

export function formatPriceValue(price) {
  if (!price || price === "0.00") return null
  return `€ ${price}`
}

export function sample(array) {
  if (!array || array.length === 0) return null
  if (array.length === 1) return head(array)

  return array[randomInteger(0, array.length - 1)]
}

export function isMatchStatusCandidateAccepted(match) {
  return match.status === MATCH_STATUSES.CANDIDATE_ACCEPTED
}
export function shouldRenderExpire(match) {
  if (!match) return false
  if (!match.expiresAt) return false
  return [MATCH_STATUSES.CANDIDATE_ACCEPTED].includes(match.status)
}

export function shouldRenderMatched(match) {
  if (!match) return false
  if (!match.comRepliedAt) return false
  if (match.status === MATCH_STATUSES.COMPANY_ACCEPTED) return true
  if (match.status === MATCH_STATUSES.COMPANY_APPROVED) return true
  if (match.status === MATCH_STATUSES.COMPANY_REJECTED) return true
  return false
}

export function shouldRenderDeclineFeedback(match) {
  if (!isMatchStatusCandidateAccepted(match)) return false
  if (![ACT, PAU].includes(match.job.status)) return false
  return true
}

export function shouldRenderAcceptDeclineControls(match) {
  if (!isMatchStatusCandidateAccepted(match)) return false
  if (![ACT, PAU].includes(match.job.status)) return false
  return true
}

export function shouldRenderApproveRejectControls(match) {
  if (
    ![MATCH_STATUSES.COMPANY_ACCEPTED, MATCH_STATUSES.EXPORTED].includes(
      match.status,
    )
  )
    return false
  if (![ACT, PAU, DEL].includes(match.job.status)) return false
  return true
}

export function shouldRenderMatchMessage(match) {
  if (match.status !== MATCH_STATUSES.COMPANY_ACCEPTED) return false
  if (match.job.status !== ACT && match.job.status !== PAU) return false
  if (match.thread) {
    return false
  }
  return true
}

export function isMatchRejected(match) {
  return match?.status === MATCH_STATUSES.COMPANY_REJECTED
}

export function isMatchApproved(match) {
  return match?.status === MATCH_STATUSES.COMPANY_APPROVED
}

export function shouldRenderMatchHiringFeeHint(match) {
  if (
    !match.hasHiringFee ||
    match.job.condition.type === JOB_CONDITION_TYPES.flat
  )
    return false

  if (
    ![
      MATCH_STATUSES.CANDIDATE_ACCEPTED,
      MATCH_STATUSES.COMPANY_ACCEPTED,
    ].includes(match.status)
  ) {
    return false
  }

  if (![ACT, PAU, DEL].includes(match.job.status)) return false
  return true
}

export function canSendMessage(match) {
  if (!match) return false
  if (match.status === MATCH_STATUSES.COMPANY_ACCEPTED) return true
  if (match.status === MATCH_STATUSES.COMPANY_APPROVED) return true
  return false
}

export function canSeeContactDataSection(match) {
  if (!match) return false

  return [
    MATCH_STATUSES.COMPANY_ACCEPTED,
    MATCH_STATUSES.COMPANY_APPROVED,
  ].includes(match.status)
}

export function canSeeCv(match) {
  if (!match) return false
  return [
    MATCH_STATUSES.CANDIDATE_ACCEPTED,
    MATCH_STATUSES.COMPANY_ACCEPTED,
    MATCH_STATUSES.COMPANY_APPROVED,
    MATCH_STATUSES.EXPORTED,
  ].includes(match.status)
}

export function canDownloadCv(match) {
  if (!match) return false
  return [
    MATCH_STATUSES.COMPANY_ACCEPTED,
    MATCH_STATUSES.COMPANY_APPROVED,
  ].includes(match.status)
}

export function isPreviewCvVisible(match, candidate) {
  if (!match) return false

  if (!canSeeCv(match)) {
    return false
  }

  return (
    isCvUploaded(candidate.candidateCv) ||
    isLinkedinProfileConnected(candidate.socialAccounts)
  )
}

export function getArrowClassName(isOpen) {
  return cn(
    {
      "fa-angle-down": !isOpen,
      "fa-angle-up": isOpen,
    },
    "fas",
  )
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onabort = error => {
      reject(error)
    }

    reader.onerror = error => {
      reject(error)
    }
  })
}

export function isCvUploaded(cv) {
  if (!cv) return false
  if (!cv.isUploaded) return false

  return true
}

export function hasOriginalCv(cv) {
  if (!isCvUploaded(cv)) return false
  if (!cv.urls) return false
  if (!cv.urls.original) return false

  return true
}

export function hasPdfCv(cv) {
  if (!isCvUploaded(cv)) return false
  if (!cv.urls) return false
  if (!cv.urls.pdf) return false

  return true
}

export function hasImagesCv(cv) {
  if (!isCvUploaded(cv)) return false
  if (!cv.urls) return false
  if (!cv.urls.images || cv.urls.images.length === 0) return false

  return true
}

export function isLinkedinProfileConnected(socialAccounts) {
  if (!socialAccounts) return false

  return socialAccounts.some(acc =>
    Boolean(acc.platform === "linkedin" && acc.webReference),
  )
}

export function trimWebReference(r) {
  if (r.length >= 50) {
    return r.substring(0, 50)
  }
  return r
}

export function getLinkedinProfileLink(socialAccounts = []) {
  const account = socialAccounts.find(acc => acc.platform === "linkedin")

  if (!account || !account.webReference) return null

  return account.webReference
}

export function popupCenter(url, title, w, h) {
  // Fixes dual-screen position
  const dualScreenLeft =
    typeof window.screenLeft !== "undefined"
      ? window.screenLeft
      : window.screen.left

  const dualScreenTop =
    typeof window.screenTop !== "undefined"
      ? window.screenTop
      : window.screen.top

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left,
  )

  // Puts focus on the newWindow
  if (newWindow && newWindow.focus) {
    newWindow.focus()
  }
}

export function getAnonymousEmail(id) {
  return `${id}@moberries.com`
}

export function getChangedFields(newEntity, oldEntity) {
  return uniq(
    Object.entries(newEntity)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          if (Array.isArray(oldEntity[key])) {
            if (difference(oldEntity[key], value).length) {
              return key
            }

            if (difference(value, oldEntity[key]).length) {
              return key
            }

            if (
              differenceWith((a, b) => a.id === b.id, value, oldEntity[key])
                .length
            ) {
              return key
            }

            if (
              differenceWith((a, b) => a.id === b.id, oldEntity[key], value)
                .length
            ) {
              return key
            }
          }

          return undefined
        }

        if (value !== oldEntity[key]) {
          return key
        }

        return undefined
      })
      .filter(i => i !== undefined),
  )
}

export function getLocationName(location, countryOnly = false) {
  if (!location) return null

  let locationName = ""

  if (location.name) {
    locationName = location.name
  }

  if (location.country && location.country.name) {
    if (countryOnly) {
      return location.country.name
    }

    locationName = locationName + ", " + location.country.name
  }

  return locationName
}

export function getFullName(
  user,
  shortenLastName = false,
  shortenFirstName = false,
) {
  if (!user) return null

  let first = ""
  let last = ""

  if (user.firstName) {
    first = shortenFirstName ? user.firstName.split(" ")[0] : user.firstName
  }

  if (user.lastName) {
    last = shortenLastName
      ? ` ${user.lastName.slice(0, 1)}.`
      : ` ${user.lastName}`
  }

  return shortenFirstName ? first : first + last
}

export function formatSalary(salaryMin, salaryMax) {
  let salary = ""

  if (salaryMin > 0 && salaryMax > 0 && salaryMax >= salaryMin) {
    salary = `${salaryMin / 1000} - ${salaryMax / 1000}K €`
  }

  if (salaryMax > 0 && salaryMin === 0) {
    salary = `${salaryMax / 1000}K €`
  }

  if (salaryMin > 0 && salaryMax === 0) {
    salary = `${salaryMin / 1000}K €`
  }

  return salary
}

export function getCareerLevelName(careerLevel) {
  if (isNil(careerLevel)) return null

  const level = CAREER_LEVELS.find(c => c.id === careerLevel)

  if (!level) return null

  return <FormattedMessage id={level.name} />
}

export function getAvailabilityName(availability) {
  if (isNil(availability)) return null

  const availabilityFound = CANDIDATE_AVAILABILITY.find(
    c => c.value === availability,
  )

  if (!availabilityFound) return null

  return <FormattedMessage id={availabilityFound.name} />
}

export function formatUnreadCount(unreadCount) {
  return unreadCount > 10 ? "10+" : unreadCount
}

export function divideSafe(firstNumber, secondNumber) {
  const result = firstNumber / secondNumber

  if (!Number.isFinite(result)) {
    return 0
  }

  return result
}

export function updateQueryParams({ search, params = {} }) {
  const query = qs.parse(search, { ignoreQueryPrefix: true })

  Object.entries(params).forEach(([key, value]) => {
    if (value === null) {
      delete query[key]
    } else {
      query[key] = value
    }
  })

  return qs.stringify(query, { addQueryPrefix: true })
}

export const resetPageQueryParam = search => {
  return updateQueryParams({
    search,
    params: {
      page: null,
    },
  })
}

export function isQueryLinkActive(key, value) {
  return (match, location) => {
    if (!match) {
      return false
    }

    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (!query[key] && value === null) return true

    return query[key] === String(value)
  }
}

export function statusToQueryTransformer(status) {
  switch (status) {
    case "paused":
      return [PAU]
    case "review":
      return [REV]
    case "all":
      return [ACT, REV, PAU]
    default:
      return [ACT]
  }
}
export function orderingToJobListQueryTransformer(ordering) {
  switch (ordering) {
    case "title":
      return "title"
    case "-title":
      return "-title"
    case "date":
      return "created_at"
    default:
      return "-created_at"
  }
}

export function orderingToMatchListQueryTransformer(ordering) {
  switch (ordering) {
    case "title":
      return "job__title"
    case "-title":
      return "-job__title"
    case "date":
      return "com_replied_at"
    default:
      return "-com_replied_at"
  }
}

export function matchStatusToQueryTransformer(status) {
  switch (status) {
    case "approved":
      return MATCH_STATUSES.COMPANY_APPROVED
    default:
      return [MATCH_STATUSES.COMPANY_ACCEPTED, MATCH_STATUSES.EXPORTED]
  }
}

export const getJobTitleFromParam = async ({ id }) => {
  try {
    const { data: job } = await api.getJob({
      id,
      params: { fields: ["title", "id"] },
    })
    return { id: job.id, name: job.title }
  } catch (err) {
    return `Job ${id}`
  }
}

export function isJobEditConfirmationRequired(prevJob, job) {
  if (!prevJob || !job || !prevJob.statistics) return false
  if (prevJob.title === job.title) return false
  if (prevJob.description === job.description) return false

  return prevJob.statistics.offersTotal > 0
}

export function isPendingMatch(match) {
  return match.status === MATCH_STATUSES.CANDIDATE_PENDING
}

export function shouldShowMatchLabels(match) {
  return match.status === MATCH_STATUSES.CANDIDATE_ACCEPTED
}

export function getSkillsDiff(candidateSkills, jobSkills) {
  if (!candidateSkills || !jobSkills) return null

  if (jobSkills.length === 0) {
    return { matched: [], missing: [], rest: candidateSkills }
  }

  if (candidateSkills.length === 0) {
    return { matched: [], missing: jobSkills, rest: [] }
  }

  return {
    matched: innerJoin(eqProps("id"), candidateSkills, jobSkills),
    missing: differenceWith(eqProps("id"), jobSkills, candidateSkills),
    rest: differenceWith(eqProps("id"), candidateSkills, jobSkills),
  }
}

function byIdAndLevelComparator(first, second) {
  return first.id === second.id && first.level >= second.level
}

function byIdComparator(first, second) {
  return first.id === second.id
}

export function getJobRolesDiff(candidateRoles, jobRoles) {
  if (!candidateRoles || !jobRoles) return null

  if (jobRoles.length === 0) {
    return { matched: [], missing: [], rest: candidateRoles }
  }

  if (candidateRoles.length === 0) {
    return { matched: [], missing: jobRoles, rest: [] }
  }

  return {
    matched: innerJoin(byIdAndLevelComparator, candidateRoles, jobRoles),
    missing: differenceWith(
      (jobRole, candidateRole) =>
        byIdAndLevelComparator(jobRole, candidateRole),
      jobRoles,
      candidateRoles,
    ),
    rest: differenceWith(byIdAndLevelComparator, candidateRoles, jobRoles),
  }
}

export function getCertificatesDiff(certificateRoles, jobRoles) {
  if (!certificateRoles || !jobRoles) return null

  if (jobRoles.length === 0) {
    return { matched: [], rest: certificateRoles }
  }

  if (certificateRoles.length === 0) {
    return { matched: [], rest: [] }
  }

  return {
    matched: innerJoin(
      (first, second) => first.roleId === second.id,
      certificateRoles,
      jobRoles,
    ),
    rest: differenceWith(
      (first, second) => first.roleId === second.id,
      certificateRoles,
      jobRoles,
    ),
  }
}

export function getLanguagesDiff(candidateLanguages, jobLanguages) {
  if (!candidateLanguages || !jobLanguages) return null

  if (jobLanguages.length === 0) {
    return { matched: [], missing: [], rest: candidateLanguages }
  }

  if (candidateLanguages.length === 0) {
    return { matched: [], missing: jobLanguages, rest: [] }
  }

  return {
    matched: innerJoin(
      (c, j) => {
        const isMatch = byIdComparator(c, j)
        if (isMatch) {
          const diff = j.level - c.level
          c.diff = diff > 0 ? diff : 0
        }
        return isMatch
      },
      candidateLanguages,
      jobLanguages,
    ),
    missing: differenceWith(byIdComparator, jobLanguages, candidateLanguages),
    rest: differenceWith(byIdComparator, candidateLanguages, jobLanguages),
  }
}

export function getJobTypesDiff(candidateJobTypes, jobJobTypes) {
  if (!candidateJobTypes || !jobJobTypes) return null

  if (jobJobTypes.length === 0) {
    return { matched: [], missing: [], rest: candidateJobTypes }
  }

  if (candidateJobTypes.length === 0) {
    return { matched: [], missing: jobJobTypes, rest: [] }
  }

  return {
    matched: intersection(candidateJobTypes, jobJobTypes),
    missing: difference(jobJobTypes, candidateJobTypes),
    rest: difference(candidateJobTypes, jobJobTypes),
  }
}

export function createReferralLink(companyId) {
  return `https://berries.io/r/c-${companyId}`
}

export function createSurveyLink({ companyId, rating, code }) {
  return `https://moberries-form.typeform.com/to/${code}?company=${companyId}&rating=${rating}`
}

export function companyHasJobs(statistics) {
  return statistics.jobCount > 0
}

export function companyHasActiveJobs(company) {
  return company.activeJobsCount > 0
}

export const capitalize = compose(
  join(""),
  juxt([compose(toUpper, head), tail]),
)

export function getUtmParams() {
  const paramNames = [
    config.cookie.utmSource,
    config.cookie.utmMedium,
    config.cookie.utmCampaign,
    config.cookie.utmTerm,
    config.cookie.utmContent,
  ]

  const params = paramNames.reduce((params, name) => {
    const param = cookie.get(name)
    if (param) {
      params[name] = param
    }

    return params
  }, {})

  if (Object.keys(params).length < 1) {
    return null
  }

  return params
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const convertNameToInteger = (
  firstName = "",
  lastName = "",
  base = 10,
) => {
  return (
    (firstName + lastName)
      .split("")
      .map(c => c.charCodeAt(0))
      .reduce((acc, n) => acc + n, 0) % base
  )
}

export const hasSuperManagerRights = manager =>
  manager.groups.includes(USER_GROUPS.SUPER_GROUP)

export const isMessageFromManager = message => {
  if (!message.authorData) {
    return false
  }
  return message.authorData.type === "manager"
}

export const hasMore = (totalCount, lastFetchedPage, itemsPerPage) =>
  lastFetchedPage < Math.ceil(totalCount / itemsPerPage)

export const scrollToFirstError = (errors, formName) => {
  const errorFields = Object.keys(errors)
  for (const field of errorFields) {
    const fieldName = `position-${formName}-${field}`
    if (document.querySelector(`[name="${fieldName}"]`)) {
      scroller.scrollTo(fieldName, { offset: -200, smooth: true })
      return
    }
  }
}

export const delay = timeout =>
  new Promise(resolve => {
    setTimeout(resolve, timeout)
  })

export const isFormValidation = err =>
  isProblem(PROBLEMS.CLIENT_ERROR, err) && isStatus(400, err)

export const formatDate = timestamp => {
  const date = parseISO(timestamp)
  const now = new Date()

  if (differenceInHours(now, date) < 1) {
    return <FormattedRelative timestamp={timestamp} />
  } else if (isToday(date)) {
    return <FormattedTime value={date} />
  } else {
    return <FormattedDate value={date} />
  }
}

export function shouldRedirectToDashboard(match, companySubscription) {
  if (
    match.status === MATCH_STATUSES.COMPANY_ACCEPTED ||
    match.status === MATCH_STATUSES.COMPANY_APPROVED
  ) {
    return false
  }

  if (isComSubscriptionExpired(companySubscription)) {
    return true
  }

  if (isComSubscriptionPaused(companySubscription)) {
    return true
  }

  return false
}

export const isDocumentVisible = () => document.visibilityState === "visible"

export const downloadCv = candidate => {
  if (hasPdfCv(candidate.candidateCv)) {
    window.open(candidate.candidateCv.urls.pdf)
    return
  }

  if (hasOriginalCv(candidate.candidateCv)) {
    window.open(candidate.candidateCv.urls.original)
  }
}

export const downloadCvFromPdfService = (match, token) => {
  window.open(
    `${config.moberries.pdf.url}/matches/${match.id}/cv.pdf?addHeader=true&token=${token}`,
  )
}

export const isGreaterThanZero = num => Math.abs(num) > 0

export const isJobPph = job => job?.condition?.type === JOB_CONDITION_TYPES.pph

export const isJobFlat = job =>
  job?.condition?.type === JOB_CONDITION_TYPES.flat

export const removeNonAlphanumericCharacters = string =>
  replace(/[\W_]+/g, "", string)

export const arrayToMultiWordRegex = array => new RegExp(array.join("|"), "g")

export const dateRangeToOption = ({ startDate, endDate, id }) => ({
  id,
  startDate: formatISO(startDate, { representation: "date" }),
  endDate: formatISO(endDate, { representation: "date" }),
  formattedDate: `${format(startDate, "dd.MM.yyyy")}-${format(
    endDate,
    "dd.MM.yyyy",
  )}`,
})

export const initLogrocket = () => {
  if (!config.branch.isRelease) return

  store.subscribe(({ manager }) => {
    // We want to keep logrocket always enabled for managers
    // const logrocketEnabled = logrocketSelector(getState())
    // if (!logrocketEnabled) return

    if (!manager) return

    // unsubscribe()

    LogRocket.init(config.logrocket.url, {
      ...config.logrocket.options,
      network: {
        requestSanitizer,
        responseSanitizer,
      },
    })

    LogRocket.identify(manager.id, {
      email: getAnonymousEmail(manager.id),
      id: manager.id,
    })

    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setContext("logrocket", { sessionURL })
      })
    })
  })
}
export const applicationQuestionTypeToOption = questionType => ({
  value: questionType,
  name: `app.job.questions.type.${questionType}`,
})

export const reorder = ({ array, startIndex, endIndex }) => {
  const result = Array.from(array)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const arePredefinedAnswersRequired = questionType =>
  [
    APPLICATION_QUESTION_TYPES.SINGLE_SELECTION,
    APPLICATION_QUESTION_TYPES.MULTIPLE_SELECTION,
  ].includes(questionType)

export const formatMoney = value => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  }).format(value)
}

export const isIntlId = value =>
  typeof value === "string" && value.startsWith("app.")

export const getJobLevelName = jobLevel => {
  if (jobLevel < 0) return "app.common.job.experience.level.first"

  const jobLevelObj = JOB_LEVELS.find(level => level.id === jobLevel)
  return jobLevelObj ? jobLevelObj.name : "app.common.na"
}
