import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

export const FormattedErrorMessage = ({ error }) => {
  if (typeof error === "string") {
    return <FormattedMessage id={error} />
  } else {
    return <FormattedMessage {...error} />
  }
}

FormattedErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}
