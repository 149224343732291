import * as yup from "yup"

const MIN_LENGTH = 1
const MAX_LENGTH = 50
const EMAIL_MAX_LENGTH = 50

export const companyBillingSchema = yup.object().shape({
  companyName: yup
    .string()
    .required("app.validators.value.required")
    .min(MIN_LENGTH, "app.validators.length.min")
    .max(MAX_LENGTH, "app.validators.length.max"),
  firstName: yup
    .string()
    .required("app.validators.value.required")
    .min(MIN_LENGTH, "app.validators.length.min")
    .max(MAX_LENGTH, "app.validators.length.max"),
  lastName: yup
    .string()
    .required("app.validators.value.required")
    .min(MIN_LENGTH, "app.validators.length.min")
    .max(MAX_LENGTH, "app.validators.length.max"),
  email: yup
    .string()
    .email("app.validators.email.required")
    .max(EMAIL_MAX_LENGTH, "app.validators.length.max")
    .required("app.validators.value.required"),
  countryName: yup
    .string()
    .required("app.validators.value.required")
    .min(MIN_LENGTH, "app.validators.length.min")
    .max(MAX_LENGTH, "app.validators.length.max"),
  cityName: yup
    .string()
    .required("app.validators.value.required")
    .min(MIN_LENGTH, "app.validators.length.min")
    .max(MAX_LENGTH, "app.validators.length.max"),
  postcode: yup
    .string()
    .required("app.validators.value.required")
    .min(MIN_LENGTH, "app.validators.length.min")
    .max(MAX_LENGTH, "app.validators.length.max"),
  street: yup
    .string()
    .required("app.validators.value.required")
    .min(MIN_LENGTH, "app.validators.length.min")
    .max(MAX_LENGTH, "app.validators.length.max"),
  vatId: yup.string().when("isVatChecked", {
    is: true,
    then: yup
      .string()
      .required("app.validators.vat.invalid")
      .matches(/^DE[0-9]{9}$/, "app.validators.vat.invalid"),
  }),
})
