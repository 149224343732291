import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import {
  isComSubscriptionPaused,
  isComSubscriptionCanceled,
  isComSubscriptionExpired,
} from "../../lib/helpers"
import { Container, Row, Col } from "reactstrap"

export const DashboardHeader = ({
  className,
  companySubscription,
  isAtsAutoMatchExportEnabled,
  isActualMatchesCountZero,
}) => {
  if (isComSubscriptionPaused(companySubscription)) {
    return (
      <Container className={className}>
        <Row>
          <Col lg="8">
            <h1>
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.paused.header" />
            </h1>
            <p>
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.paused.subheader" />
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
  if (isComSubscriptionCanceled(companySubscription)) {
    return (
      <Container className={className}>
        <Row>
          <Col lg="8">
            <h1>
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.canceled.header" />
            </h1>
            <p>
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.canceled.subheader" />
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
  if (isComSubscriptionExpired(companySubscription)) {
    return (
      <Container className={className}>
        <Row>
          <Col lg="8">
            <h1>
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.expired.header" />
            </h1>
            <p>
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.expired.subheader" />
            </p>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!isActualMatchesCountZero) {
    return (
      <Container className={className}>
        <Row>
          <Col lg="8">
            <h1>
              <FormattedMessage id="app.page.dashboard.matches.header" />
            </h1>
            <p>
              {isAtsAutoMatchExportEnabled ? (
                <FormattedMessage id="app.page.dashboard.autoMatchExport.subheader" />
              ) : (
                <FormattedMessage id="app.page.dashboard.matches.subheader" />
              )}
            </p>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className={className}>
      <Row>
        <Col lg="8">
          <h1>
            <FormattedMessage id="app.page.dashboard.matches.header.empty" />
          </h1>
          <p>
            <FormattedMessage id="app.page.dashboard.matches.subheader.empty" />
          </p>
        </Col>
      </Row>
    </Container>
  )
}

DashboardHeader.propTypes = {
  className: PropTypes.string,
  companySubscription: PropTypes.object.isRequired,
  isAtsAutoMatchExportEnabled: PropTypes.bool,
  isActualMatchesCountZero: PropTypes.bool.isRequired,
}

DashboardHeader.defaultProps = {
  isAtsAutoMatchExportEnabled: false,
}
