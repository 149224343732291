import { getActionType, arrayToObject } from "../lib/helpers"
import {
  evolve,
  F,
  T,
  always,
  mergeLeft,
  over,
  lensProp,
  inc,
  dec,
  without,
  reject,
  propEq,
} from "ramda"
import {
  SUCCESS,
  GET,
  CREATE,
  CARD,
  LIST,
  FAIL,
  START,
  LOGOUT,
  DELETE,
} from "../constants"

export const defaultState = {
  loading: false,
  err: null,
  entities: {},
  cards: [],
  count: null,
  creating: false,
  deleting: false,
}

export const reducer = function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, CARD, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, CARD, LIST, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: mergeLeft(arrayToObject(payload.cards, "id")),
          cards: always(payload.cards.map(c => c.id)),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, CARD, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(CREATE, CARD, START):
      return evolve({ creating: T }, state)

    case getActionType(CREATE, CARD, SUCCESS):
      return evolve(
        {
          creating: F,
          err: always(null),
          entities: over(lensProp(payload.card.id), mergeLeft(payload.card)),
          cards: always([payload.card.id]),
          count: inc,
        },
        state,
      )

    case getActionType(CREATE, CARD, FAIL):
      return evolve({ creating: F, err: always(payload.err) }, state)

    case getActionType(DELETE, CARD, START):
      return evolve({ deleting: T }, state)

    case getActionType(DELETE, CARD, SUCCESS):
      return evolve(
        {
          deleting: F,
          err: always(null),
          cards: without([payload.cardId]),
          entities: reject(propEq("id", payload.cardId)),
          count: dec,
        },
        state,
      )

    case getActionType(DELETE, CARD, FAIL):
      return evolve({ deleting: F, err: always(payload.err) }, state)

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
