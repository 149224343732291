import {
  arrayToObject,
  getActionType,
  normalizeAtsIntegrationJob,
} from "../lib/helpers"
import {
  evolve,
  F,
  T,
  always,
  mergeLeft,
  over,
  lensProp,
  omit,
  prop,
  without,
  path,
  inc,
  dec,
} from "ramda"
import {
  GET,
  CREATE,
  DELETE,
  ATS,
  JOB,
  INTEGRATION,
  LIST,
  START,
  SUCCESS,
  FAIL,
  JOBS,
  UNINTEGRATED,
  UPDATE,
  PAU,
  LOGOUT,
  IMPORT,
  FROM,
  COMPANY,
} from "../constants"

export const defaultState = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  err: null,
  entities: {},
  count: null,
  unintegratedJobIds: [],
  isLoadingUnintegratedJobs: false,
  unintegratedJobsCount: null,
}

export const reducer = function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, ATS, JOB, INTEGRATION, LIST, START):
      return evolve({ isLoading: T }, state)

    case getActionType(GET, ATS, JOB, INTEGRATION, LIST, SUCCESS):
      return evolve(
        {
          isLoading: F,
          err: always(null),
          entities: mergeLeft(arrayToObject(payload.integrations, "id")),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, ATS, JOB, INTEGRATION, LIST, FAIL):
      return evolve({ isLoading: F, err: always(payload.err) }, state)

    case getActionType(CREATE, ATS, JOB, INTEGRATION, START):
      return evolve({ isCreating: T }, state)

    case getActionType(CREATE, ATS, JOB, INTEGRATION, SUCCESS):
      return evolve(
        {
          isCreating: F,
          err: always(null),
          entities: over(
            lensProp(payload.integration.id),
            mergeLeft(normalizeAtsIntegrationJob(payload.integration)),
          ),
          count: inc,
          unintegratedJobIds: without([payload.integration.moberriesJob]),
          unintegratedJobsCount: dec,
        },
        state,
      )

    case getActionType(CREATE, ATS, JOB, INTEGRATION, FAIL):
      return evolve({ isCreating: F, err: always(payload.err) }, state)

    case getActionType(GET, ATS, JOB, INTEGRATION, FAIL):
      return evolve({ isLoading: F, err: always(payload.err) }, state)

    case getActionType(DELETE, ATS, JOB, INTEGRATION, START):
      return evolve({ isDeleting: T }, state)

    case getActionType(DELETE, ATS, JOB, INTEGRATION, SUCCESS):
      return evolve(
        {
          isDeleting: F,
          err: always(null),
          entities: omit([payload.integration.id]),
        },
        state,
      )

    case getActionType(DELETE, ATS, JOB, INTEGRATION, FAIL):
      return evolve({ isDeleting: F, err: always(payload.err) }, state)

    case getActionType(ATS, GET, UNINTEGRATED, JOBS, START):
      return evolve(
        {
          isLoadingUnintegratedJobs: T,
        },
        state,
      )

    case getActionType(ATS, GET, UNINTEGRATED, JOBS, SUCCESS):
      return evolve(
        {
          unintegratedJobIds: always(payload.jobs.map(prop("id"))),
          isLoadingUnintegratedJobs: F,
          unintegratedJobsCount: always(payload.count),
        },
        state,
      )

    case getActionType(UPDATE, JOB, SUCCESS):
      return evolve(
        {
          unintegratedJobIds:
            path(["job", "status"], payload) === PAU
              ? without([payload.job.id])
              : state.unintegratedJobIds,
          unintegratedJobsCount:
            path(["job", "status"], payload) === PAU
              ? dec
              : state.unintegratedJobsCount,
        },
        state,
      )

    case getActionType(IMPORT, JOBS, FROM, ATS, START):
      return evolve(
        {
          isCreating: T,
        },
        state,
      )

    case getActionType(IMPORT, JOBS, FROM, ATS, FAIL):
    case getActionType(IMPORT, JOBS, FROM, ATS, SUCCESS):
      return evolve(
        {
          isCreating: F,
        },
        state,
      )
    case getActionType(DELETE, ATS, COMPANY, INTEGRATION, SUCCESS):
    case getActionType(LOGOUT, SUCCESS):
      return defaultState
    default:
      return state
  }
}
