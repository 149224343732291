import React from "react"
import { times } from "ramda"
import { List } from "../loaders/list-loader"

export function PaymentCardsSkeleton() {
  return (
    <div className="bg-white px-1 py-3">
      {times(
        i => (
          <List key={i} />
        ),
        2,
      )}
    </div>
  )
}
