import { push } from "connected-react-router"
import qs from "qs"
import { camelizeKeys } from "humps"
import { createSlackIntegration } from "./slack-integration-actions"
import { getActionType } from "../lib/helpers"
import {
  OAUTH,
  START,
  FAIL,
  CONNECT,
  SLACK,
  PROBLEMS,
  LEVER,
  ALERT_COLORS,
} from "../constants"
import { createAtsCompanyIntegration } from "./ats-company-integration-actions"
import { alertTypes, showAlert } from "../lib/alerts"
import { refreshAtsConnection } from "../controllers/ats-controller"
import { useStore } from "../lib/store"

function resolveOauthQueryFromLocation(location) {
  let params

  if (location.hash && location.hash !== "#_=_") {
    params = decodeURIComponent(location.hash.slice(1))
  } else {
    params = decodeURIComponent(location.search.slice(1))
  }

  if (!params) {
    return null
  }

  const query = camelizeKeys(qs.parse(params))

  if (!query.state || (!query.code && !query.accessToken && !query.error)) {
    return null
  }

  return query
}
// TODO: move this to the component and remove create ats integration action
export function connectOauthProvider() {
  return async function (dispatch, getState) {
    const { location } = getState().router
    let finalFrom = "/"

    try {
      const query = resolveOauthQueryFromLocation(location)

      if (!query) {
        return dispatch(push(finalFrom))
      }

      query.state = JSON.parse(window.atob(query.state))

      const { accessToken, code, state, error } = query
      state.provider = state.provider.toUpperCase()
      const { from, provider, data, companyId } = state
      if (from) finalFrom = from

      if (!provider) {
        dispatch(push(finalFrom))
        throw new Error("No oauth provider")
      }

      dispatch({ type: getActionType(CONNECT, provider, START) })

      if (error) {
        const err = new Error(error)

        if (typeof error === "string") {
          err.code = error
          err.provider = provider
        }

        dispatch(push(finalFrom))
        throw err
      }

      // add here more oauth providers
      switch (provider) {
        case SLACK:
          await dispatch(createSlackIntegration({ accessToken, code, state }))
          break
        case LEVER:
          await dispatch(
            createAtsCompanyIntegration({
              provider: provider.toLowerCase(),
              companyId,
              data: {
                ...data,
                code,
              },
              redirectTo: from,
            }),
          )
          await refreshAtsConnection()
          useStore.setState({
            isAtsConnectionFlowInProgress: true,
          })
          break
        default:
          const err = new Error("Unknown provider")
          err.provider = provider
          err.problem = PROBLEMS.CLIENT_ERROR
          err.code = "unknown_provider"
          dispatch(push(finalFrom))
          throw err
      }
      dispatch(push(finalFrom))
    } catch (err) {
      dispatch(push(finalFrom))
      showAlert({
        code: alertTypes.atsCompanyCreationFailed,
        color: ALERT_COLORS.ERROR,
      })
      dispatch({
        type: getActionType(CONNECT, err.provider || OAUTH, FAIL),
        payload: { err },
      })
    }
  }
}
