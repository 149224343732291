import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { NavLink as RLink } from "react-router-dom"
import { Container, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import { SmallSingleColumnLayout } from "./small-single-column-layout"

export const CompanyPageLayout = props => {
  return (
    <Fragment>
      <Container>
        <h1 className="text-center mb-4">{props.title}</h1>
      </Container>
      <SmallSingleColumnLayout>
        <Nav tabs justified className="settings-tabs">
          <NavItem>
            <NavLink
              tag={RLink}
              to="/company"
              exact
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.company.layout.tabs.profile" />
            </NavLink>
          </NavItem>
          {props.isSuperManager && (
            <NavItem>
              <NavLink
                tag={RLink}
                to="/company/managers"
                className="font-weight-bold px-0"
              >
                <FormattedMessage id="app.page.company.layout.tabs.managers" />
              </NavLink>
            </NavItem>
          )}
          {props.isSuperManager && (
            <NavItem>
              <NavLink
                tag={RLink}
                to="/company/teams"
                className="font-weight-bold px-0"
              >
                <FormattedMessage id="app.page.company.layout.tabs.teams" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent>{props.children}</TabContent>
      </SmallSingleColumnLayout>
    </Fragment>
  )
}

CompanyPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  isSuperManager: PropTypes.bool,
}

CompanyPageLayout.defaultProps = {
  title: <FormattedMessage id="app.page.company.layout.header" />,
}
