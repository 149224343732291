import React from "react"
import PropTypes from "prop-types"
import {
  isComSubscriptionIncomplete,
  isLoggedInSelector,
  companySubscriptionSelector,
} from "../../lib/helpers"
import { Container, Alert } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useStripe } from "@stripe/react-stripe-js"
import { useStore } from "../../lib/store"

export const IncompleteSubscriptionAlert = ({ isLogged }) => {
  const isLoggedIn = useStore(isLoggedInSelector)
  const companySubscription = useStore(companySubscriptionSelector)
  const stripe = useStripe()
  const location = useLocation()
  if (
    !isLoggedIn ||
    !isComSubscriptionIncomplete(companySubscription) ||
    !stripe ||
    !isLogged ||
    location.pathname.startsWith("/subscribe")
  ) {
    return null
  }

  return (
    <Alert
      className="mb-3 alert-floating"
      color="danger"
      isOpen={true}
      transition={{
        appear: false,
        enter: false,
        exit: false,
        timeout: 0,
      }}
    >
      <Container className="position-relative px-3">
        <div className="mr-3">
          <FormattedMessage
            id="app.alertbar.subscription.incomplete.authorizationText"
            values={{
              a: (...children) => <Link to="/subscribe">{children}</Link>,
            }}
          />
        </div>
      </Container>
    </Alert>
  )
}

IncompleteSubscriptionAlert.propTypes = {
  isLogged: PropTypes.bool,
}
