import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Col } from "reactstrap"
import { Button } from "../common/button-component"
import { useDispatch, useSelector } from "react-redux"
import { deleteMessageTemplate } from "../../actions/message-template-actions"
import { useOpenClose } from "../../hooks/use-open-close"
import { isDeletingSelector } from "../../selectors/message-template-selector"
import { EditMessageTemplateModal } from "../modals/edit-message-template-modal"
import Truncate from "react-truncate"

export const MessageTemplateCard = ({ template }) => {
  const dispatch = useDispatch()
  const isDeleting = useSelector(state =>
    isDeletingSelector(state, { id: template.id }),
  )
  const {
    value: isModalOpen,
    close: closeModal,
    open: openModal,
  } = useOpenClose({
    initialValue: false,
  })

  return (
    <Fragment>
      <EditMessageTemplateModal
        isOpen={isModalOpen}
        close={closeModal}
        toggle={closeModal}
        initialValues={template}
      />

      <div key={template.id} className="bg-white p-3 border-bottom rounded">
        <div className="d-flex">
          <Col xs="8" sm="9" md="10" className="px-0">
            <h4>{template.name}</h4>
            <Truncate lines={2}>{template.text}</Truncate>
          </Col>
          <Col xs="4" sm="3" md="2" className="px-0 align-self-end text-right">
            <Button
              color="white"
              loading={isDeleting}
              disabled={isDeleting}
              className="ml-1 btn-circle border"
              onClick={() =>
                dispatch(
                  deleteMessageTemplate({ messageTemplateId: template.id }),
                )
              }
            >
              {!isDeleting && <i className="fas fa-trash" />}
            </Button>
            <Button
              color="light"
              className="ml-3 btn-circle"
              onClick={openModal}
            >
              <i className="fas fa-pen" />
            </Button>
          </Col>
        </div>
      </div>
    </Fragment>
  )
}

MessageTemplateCard.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    text: PropTypes.string,
  }),
}
