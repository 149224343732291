import React from "react"
import PropTypes from "prop-types"
import { last } from "ramda"

export class InfiniteScroll extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    hasMore: PropTypes.bool.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    upsideDown: PropTypes.bool,
    rootMargin: PropTypes.number,
    useWindowScroll: PropTypes.bool,
    loader: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  static defaultProps = {
    hasMore: false,
    upsideDown: false,
    rootMargin: 0,
    useWindowScroll: false,
  }

  wrapperElement = { parentNode: null }

  didLastChildOrLengthChange(prevProps) {
    return (
      this.props.children.length !== prevProps.children.length ||
      last(this.props.children).key !== last(prevProps.children).key
    )
  }

  componentDidMount() {
    if (this.props.upsideDown) {
      this.scrollToBottom()
    }
    this.createObserver()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.upsideDown && this.props.id !== prevProps.id) {
      this.scrollToBottom()
    }

    if (
      this.props.upsideDown &&
      this.didLastChildOrLengthChange(prevProps) &&
      this.props.id === prevProps.id
    ) {
      const parent = this.getParentElement()

      if (snapshot.distanceFromBottom === parent.clientHeight) {
        parent.scrollTop = parent.scrollHeight
      } else {
        parent.scrollTop = parent.scrollHeight - snapshot.distanceFromBottom
      }
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (
      this.props.upsideDown &&
      this.didLastChildOrLengthChange(prevProps) &&
      this.props.id === prevProps.id
    ) {
      const parent = this.getParentElement()
      return {
        distanceFromBottom: parent.scrollHeight - parent.scrollTop,
      }
    } else return null
  }

  getParentElement = () => this.wrapperElement.parentNode

  scrollToBottom = () => {
    const parent = this.getParentElement()
    parent.scrollTop = parent.scrollHeight
  }

  createObserver = () => {
    const { rootMargin, useWindowScroll } = this.props
    const options = {
      root: useWindowScroll ? null : this.getParentElement(),
      rootMargin: `${rootMargin}px 0px`,
      threshold: 0.01,
    }

    this.observer = new IntersectionObserver(this.handleIntersect, options)
    this.observer.observe(this.intersectionHook)
  }

  handleIntersect = entries => {
    const { hasMore, onLoadMore } = this.props

    entries.forEach(e => {
      if (e.isIntersecting && hasMore) {
        onLoadMore()
      }
    })
  }

  render() {
    const { children, upsideDown, loader, hasMore, className } = this.props

    return (
      <div className={className} ref={el => (this.wrapperElement = el)}>
        {upsideDown && hasMore && loader}
        {upsideDown && <span ref={el => (this.intersectionHook = el)} />}
        {children}
        {!upsideDown && <span ref={el => (this.intersectionHook = el)} />}
        {!upsideDown && hasMore && loader}
      </div>
    )
  }
}
