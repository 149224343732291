import React, { useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import {
  errorSelector,
  isListLoadingSelector as isIntegrationListLoading,
} from "../selectors/ats-company-integration-selector"
import { connect } from "react-redux"
import {
  getAtsCompanyIntegrationList,
  getAtsProvidersList,
} from "../actions/ats-company-integration-actions"
import { ErrorPage } from "./error-page"
import { FormattedMessage } from "react-intl"
import { importJobsFromAts } from "../actions/ats-job-integration-actions"
import { getAtsOpenJobsAndJobIntegrations } from "../actions/ats-company-integration-open-jobs-actions"
import {
  countSelector as atsJobIntegrationsCountSelector,
  isCreatingSelector as isCreatingIntegrationsSelector,
} from "../selectors/ats-job-integration-selector"
import {
  atsCompanyIntegrationOpenJobsSelector,
  countSelector as atsCompanyIntegrationOpenJobsCountSelector,
  loadingSelector as isLoadingAtsCompanyIntegrationOpenJobsSelector,
} from "../selectors/ats-company-integration-open-jobs-selector"
import { AtsImportJobs } from "../components/forms/ats-import-jobs"
import { TextLineLoader } from "../components/loaders/text-line-loader"
import { AtsIntegrationJobListLayout } from "../components/layouts/ats-integration-job-list-layout"
import { prop } from "ramda"
import { Redirect } from "react-router"
import { ATS_JOB_SYNC_OPTIONS } from "../constants"
import { push } from "connected-react-router"
import { useStore } from "../lib/store"
import { refreshAtsConnection } from "../controllers/ats-controller"

export const AtsIntegrationImportJobsPageComponent = ({
  atsCompanyIntegrationOpenJobs,

  getAtsCompanyIntegrationList,
  getAtsOpenJobsAndJobIntegrations,
  getAtsProvidersList,
  importJobsFromAts,

  push,

  atsCompanyIntegrationCount,
  atsCompanyIntegrationOpenJobsCount,
  atsJobIntegrationsCount,

  isIntegrationListLoading,
  isLoadingAtsCompanyIntegrationOpenJobs,
  isCreatingIntegrations,

  err,
}) => {
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const connectedProvider = useStore(state => state.connectedProvider)

  useEffect(() => {
    getAtsProvidersList()
  }, [getAtsProvidersList])

  useEffect(() => {
    getAtsCompanyIntegrationList({})
    getAtsOpenJobsAndJobIntegrations({})
  }, [getAtsCompanyIntegrationList, getAtsOpenJobsAndJobIntegrations])

  if (err) {
    return <ErrorPage err={err} />
  }

  if (
    (!atsCompanyIntegration && atsCompanyIntegrationCount !== null) ||
    prop("jobSyncType", atsCompanyIntegration) === ATS_JOB_SYNC_OPTIONS.AUTO
  ) {
    return <Redirect to="/integrations/ats" />
  }

  const isLoadingCounters =
    isIntegrationListLoading || isLoadingAtsCompanyIntegrationOpenJobs
  const handleImportJobs = selectedJobIds => {
    const providerJobIds = selectedJobIds.map(j => {
      return { providerJobId: j }
    })
    importJobsFromAts(providerJobIds).then(finishConnectionFlow)
  }
  const handleSkipImportJobs = async () => {
    await finishConnectionFlow()
    push("/jobs")
  }

  const finishConnectionFlow = async () => {
    await refreshAtsConnection()
    useStore.setState({
      isAtsConnectionFlowInProgress: false,
    })
  }

  return (
    <AtsIntegrationJobListLayout provider={connectedProvider}>
      <div className="bg-white rounded-top ats-jobs-page-container d-flex flex-column">
        <div className="p-3">
          {isLoadingCounters ? (
            <TextLineLoader width={260} />
          ) : (
            <Fragment>
              <FormattedMessage
                id="app.page.integrations.import.jobsOnAts"
                values={{ count: atsCompanyIntegrationOpenJobsCount }}
              />
              <span className="mx-1">|</span>
              <FormattedMessage
                id="app.page.integrations.import.imported"
                values={{ count: atsJobIntegrationsCount }}
              />
            </Fragment>
          )}
        </div>
        <h5 className="pb-3 px-3">
          <FormattedMessage id="app.page.integrations.import.header" />
        </h5>
        <AtsImportJobs
          atsCompanyIntegrationOpenJobs={atsCompanyIntegrationOpenJobs}
          isLoadingAtsCompanyIntegrationOpenJobs={
            isLoadingAtsCompanyIntegrationOpenJobs
          }
          isCreatingIntegrations={isCreatingIntegrations}
          handleImportJobs={handleImportJobs}
          handleSkipImportJobs={handleSkipImportJobs}
        />
      </div>
    </AtsIntegrationJobListLayout>
  )
}

AtsIntegrationImportJobsPageComponent.propTypes = {
  atsCompanyIntegrationOpenJobs: PropTypes.array,

  getAtsCompanyIntegrationList: PropTypes.func.isRequired,
  getAtsOpenJobsAndJobIntegrations: PropTypes.func.isRequired,
  getAtsProvidersList: PropTypes.func.isRequired,
  importJobsFromAts: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,

  atsCompanyIntegrationCount: PropTypes.number,
  atsJobIntegrationsCount: PropTypes.number,
  atsCompanyIntegrationOpenJobsCount: PropTypes.number,

  isIntegrationListLoading: PropTypes.bool,
  isLoadingAtsCompanyIntegrationOpenJobs: PropTypes.bool,
  isCreatingIntegrations: PropTypes.bool,

  err: PropTypes.object,
}

AtsIntegrationImportJobsPageComponent.defaultProps = {
  isIntegrationListLoading: false,
}

const mapStateToProps = state => {
  return {
    atsCompanyIntegrationOpenJobs: atsCompanyIntegrationOpenJobsSelector(state),
    atsJobIntegrationsCount: atsJobIntegrationsCountSelector(state),
    atsCompanyIntegrationOpenJobsCount:
      atsCompanyIntegrationOpenJobsCountSelector(state),

    isLoadingAtsCompanyIntegrationOpenJobs:
      isLoadingAtsCompanyIntegrationOpenJobsSelector(state),
    isIntegrationListLoading: isIntegrationListLoading(state),
    isCreatingIntegrations: isCreatingIntegrationsSelector(state),

    err: errorSelector(state),
  }
}

const mapDispatchToProps = {
  getAtsProvidersList,
  getAtsCompanyIntegrationList,
  getAtsOpenJobsAndJobIntegrations,
  importJobsFromAts,
  push,
}

export const AtsIntegrationImportJobsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AtsIntegrationImportJobsPageComponent)
