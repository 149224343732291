import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import styles from "./card-component-aboard.module.scss"

export const Card = ({ img, title, description, footer }) => (
  <div className={styles.container}>
    <div className={styles.imgWrap}>
      <img
        className={styles.img}
        src={img}
        alt={<FormattedMessage id={title} />}
      />
    </div>
    <h3 className={styles.title}>
      <FormattedMessage id={title} />
    </h3>
    <p className={styles.description}>
      <FormattedMessage id={description} />
    </p>
    <div className={styles.footerWrap}>
      <p className={styles.footer}>
        <FormattedMessage
          id={footer.text}
          values={
            footer?.url && {
              a: (...children) => (
                <a href={footer.url} target="_blank" rel="noopener noreferrer">
                  {children}
                </a>
              ),
            }
          }
        />
      </p>
    </div>
  </div>
)

Card.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.object,
}
