import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { Input } from "../common/input-component"
import { Col, Row } from "reactstrap"
import { CheckboxGroup } from "../common/checkbox-group-component"
import { Select } from "../common/select-component"
import { AtsCheckbox } from "../integration/ats-checkbox"
import { SelectionControl } from "../common/selection-control"
import { BulletListLoader } from "../loaders/bullet-list-loader"
import { INTEGRATION_STATE_FILTER_OPTIONS } from "../../constants"
import { filterJobsByStatus, isJobConnected } from "../../lib/helpers"
import { FormattedMessage, useIntl } from "react-intl"
import { Button } from "../common/button-component"
import { isEmpty, reject, prop } from "ramda"

export const AtsImportJobs = ({
  atsCompanyIntegrationOpenJobs,
  isLoadingAtsCompanyIntegrationOpenJobs,
  isCreatingIntegrations,
  handleImportJobs,
  handleSkipImportJobs,
}) => {
  const intl = useIntl()
  const [search, setSearch] = useState("")
  const [selectedJobs, setSelectedJobs] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(
    INTEGRATION_STATE_FILTER_OPTIONS.ALL,
  )
  const filteredJobs = filterJobsByStatus(
    selectedFilter.value,
    atsCompanyIntegrationOpenJobs,
  )
  const visibleJobs = filteredJobs.filter(j =>
    j.title.toLowerCase().includes(search.toLowerCase()),
  )

  const visibleJobIds = visibleJobs.map(j => j.id)

  const showJobsLoader =
    isLoadingAtsCompanyIntegrationOpenJobs && atsCompanyIntegrationOpenJobs

  const checkboxGroup = isEmpty(atsCompanyIntegrationOpenJobs) ? (
    <div className="p-3">
      <FormattedMessage id="app.page.integrations.import.no.atsJobs" />
    </div>
  ) : (
    <CheckboxGroup
      input={{
        value: selectedJobs,
        onChange: newSelectedJobs => setSelectedJobs(newSelectedJobs),
      }}
      id="ats-jobs-import"
      meta={{}}
      options={visibleJobs}
      inputClassName="py-2 mb-1 pl-5"
      className="mb-0"
      checkboxComponent={AtsCheckbox}
    />
  )

  return (
    <Fragment>
      <div>
        <Input
          input={{
            value: search,
            onChange: e => setSearch(e.target.value),
          }}
          type="text"
          placeholder={intl.formatMessage({
            id: "app.filter.byTitle",
          })}
          className="mb-0 px-3"
          meta={{}}
          disabled={isCreatingIntegrations}
        />
        <Row className="align-items-center">
          <Col xs="6" md="6">
            <SelectionControl
              visibleOptionIds={visibleJobIds}
              selectableOptionIds={reject(
                isJobConnected,
                atsCompanyIntegrationOpenJobs,
              ).map(prop("id"))}
              selectedOptionIds={selectedJobs}
              onChange={newSelectedJobs => setSelectedJobs(newSelectedJobs)}
              className="py-2 pl-3"
              isDisabled={isCreatingIntegrations}
            />
          </Col>
          <Col
            xs="0"
            md="3"
            className="d-none d-md-flex align-items-center justify-content-end px-0"
          >
            <FormattedMessage id="app.page.integrations.import.integrationState" />
          </Col>

          <Col xs="6" md="3" className="d-flex align-items-center my-1">
            <div className="w-100 pr-3">
              <Select
                input={{
                  value: selectedFilter,
                  onChange: setSelectedFilter,
                }}
                meta={{}}
                options={Object.values(INTEGRATION_STATE_FILTER_OPTIONS)}
                getOptionValue={option => option.value}
                getOptionLabel={option => <FormattedMessage id={option.name} />}
                activeFilter={selectedFilter}
                withCaret
                isSearchable={false}
                wrapper={Fragment}
                isDisabled={isCreatingIntegrations}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="d-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden">
        <div className="bg-white flex-grow-1 overflow-auto border-top">
          {showJobsLoader ? (
            <div className="mt-1 px-3">
              <BulletListLoader count={10} />
            </div>
          ) : (
            checkboxGroup
          )}
        </div>
        <div className="bg-white border-top p-3 text-right rounded-bottom flex-shrink-0">
          <Button
            onClick={handleSkipImportJobs}
            className="mr-2"
            color="light"
            disabled={isCreatingIntegrations}
          >
            <FormattedMessage id="app.page.integrations.import.skip" />
          </Button>
          <Button
            color="primary"
            disabled={selectedJobs.length === 0 || isCreatingIntegrations}
            loading={isCreatingIntegrations}
            onClick={() => handleImportJobs(selectedJobs)}
          >
            <FormattedMessage
              id="app.page.integrations.import.confirm"
              values={{
                count: selectedJobs.length > 0 ? selectedJobs.length : "",
              }}
            />
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

AtsImportJobs.propTypes = {
  atsCompanyIntegrationOpenJobs: PropTypes.array,
  isLoadingAtsCompanyIntegrationOpenJobs: PropTypes.bool,
  isCreatingIntegrations: PropTypes.bool,
  handleImportJobs: PropTypes.func.isRequired,
  handleSkipImportJobs: PropTypes.func.isRequired,
}
