import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  Input as Binput,
  Label,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap"
import classNames from "classnames"
import { JobStatusTag } from "../job/job-status-tag-component"
import { FormattedMessage } from "react-intl"
import { isJobConnected } from "../../lib/helpers"
import { ATS_JOB_STATUSES_COLOR_MAP } from "../../constants"

export const AtsCheckbox = ({ onChange, onBlur, option, onFocus, checked }) => {
  const isConnected = isJobConnected(option)
  const checkboxId = `ats-import-checkbox-${option.id}`

  return (
    <Label
      for={checkboxId}
      className={classNames(
        "custom-control custom-checkbox clickable py-2 mb-0 clickable border-bottom",
        {
          "bg-light": checked,
          "bg-success-100": isConnected,
        },
      )}
      key={checkboxId}
    >
      <div className="px-3 d-flex justify-content-start">
        <Binput
          type="checkbox"
          className="custom-control-input"
          id={checkboxId}
          disabled={isConnected}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          checked={checked}
          value={option.id}
        />
        <Label
          className="custom-control-label clickable text-break"
          for={checkboxId}
        >
          {option.title}
          {isConnected && (
            <Fragment>
              <i className="fas fa-plug text-success ml-2" />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/job/${option.moberriesJobId}`}
              >
                <i className="fas fa-external-link-alt ml-2 text-muted" />
              </a>
            </Fragment>
          )}
        </Label>

        {isConnected && (
          <div className="d-inline-flex justify-content-end flex-grow-1 ml-2">
            <span id={`hint-status-${option.id}`}>
              <JobStatusTag
                job={{ status: option.moberriesStatus, id: option.id }}
                colorMap={ATS_JOB_STATUSES_COLOR_MAP}
                jobIntegration={{
                  isExcludedFromSync: option.isExcludedFromSync,
                }}
              />
            </span>
            <UncontrolledPopover
              placement="auto"
              target={`hint-status-${option.id}`}
              trigger="hover"
            >
              <PopoverBody>
                <FormattedMessage id="app.page.integrations.import.jobStatus.popover" />
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        )}
      </div>
    </Label>
  )
}

AtsCheckbox.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  onFocus: PropTypes.func,
  checked: PropTypes.bool.isRequired,
}

AtsCheckbox.defaultProps = {
  checked: false,
}
