import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { CardStatusTag } from "../common/card-status-tag"
import {
  INVOICE_STATUSES_TEXT_MAP,
  INVOICE_STATUSES_COLOR_MAP,
  INVOICE_STATUSES_ICON_MAP,
} from "../../default-options"

export const InvoiceStatusTag = ({ paid, id }) => {
  const status = paid ? "paid" : "unpaid"
  return (
    <CardStatusTag
      id={id}
      text={<FormattedMessage id={INVOICE_STATUSES_TEXT_MAP[status]} />}
      color={INVOICE_STATUSES_COLOR_MAP[status]}
      icon={INVOICE_STATUSES_ICON_MAP[status]}
    />
  )
}

InvoiceStatusTag.propTypes = {
  id: PropTypes.string.isRequired,
  paid: PropTypes.bool.isRequired,
}

InvoiceStatusTag.defaultProps = {
  id: "",
}
