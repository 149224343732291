import { useField, useFormikContext } from "formik"
import { dissocPath, path } from "ramda"

export const useFormikField = props => {
  const { name } = props
  if (!name) {
    throw new Error("useFormikField called without name provided")
  }

  const { status, setStatus } = useFormikContext()
  const [field, meta, helpers] = useField(props)

  const serverError = path(["serverErrors", name], status)
  const onChange = (...args) => {
    if (serverError) {
      setStatus(dissocPath(["serverErrors", name], status))
    }
    field.onChange(...args)
  }

  return [
    { ...field, onChange },
    { ...meta, error: meta.error || serverError },
    helpers,
  ]
}
