import { arrayToObject, getActionType } from "../lib/helpers"
import { evolve, F, T, always, mergeLeft, over, lensProp } from "ramda"
import {
  SUCCESS,
  GET,
  LOGOUT,
  ATS,
  INTEGRATION,
  LIST,
  FAIL,
  START,
  COMPANY,
  OPEN,
  JOB,
  CREATE,
  DELETE,
} from "../constants"

export const defaultState = {
  loading: false,
  err: null,
  openJobs: [],
  entities: {},
  count: null,
}

export const reducer = function (state = defaultState, action) {
  const { type, payload } = action
  switch (type) {
    case getActionType(GET, ATS, COMPANY, INTEGRATION, OPEN, JOB, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(
      GET,
      ATS,
      COMPANY,
      INTEGRATION,
      OPEN,
      JOB,
      LIST,
      SUCCESS,
    ):
      return evolve(
        {
          loading: F,
          err: always(null),
          openJobs: always(payload.openJobs.map(c => c.id)),
          entities: mergeLeft(arrayToObject(payload.openJobs, "id")),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, ATS, COMPANY, INTEGRATION, OPEN, JOB, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(CREATE, ATS, JOB, INTEGRATION, SUCCESS):
      const moberriesStatus = payload.integration.moJobStatus
      return evolve(
        {
          entities: over(
            lensProp(payload.integration.providerJob.id),
            always({ ...payload.integration.providerJob, moberriesStatus }),
          ),
        },
        state,
      )
    case getActionType(DELETE, ATS, JOB, INTEGRATION, SUCCESS):
      return evolve(
        {
          entities: over(
            lensProp(payload.integration.providerJob.id),
            always(payload.integration.providerJob),
          ),
        },
        state,
      )
    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
