import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { ImageScore } from "../common/image-score-component"
import { Media } from "reactstrap"
import { Link } from "react-router-dom"
import { MatchLabels } from "./match-labels-component"
import { Expires } from "../common/expires-component"
import { Matched } from "../common/matched-component"
import {
  canSendMessage,
  getFullName,
  getLocationName,
  shouldRenderExpire,
  shouldRenderMatched,
  isPreviewCvVisible,
} from "../../lib/helpers"
import { CandidateCVButton } from "../candidate/candidate-cv-button"

export const ExtendedMatchCard = ({ match, candidate, job }) => {
  return (
    <div className="position-relative">
      <Media className="bg-white p-3 align-items-stretch rounded-top">
        <Media left className="mr-3 d-none d-md-block">
          <ImageScore
            score={match.score}
            picture={candidate.picture}
            alt={getFullName(candidate)}
          />
        </Media>
        <Media body className="pr-2 d-flex flex-column">
          <small className="text-break">{job.title}</small>
          <h4 className="media-heading text-break">{getFullName(candidate)}</h4>
          <p className="text-smaller mb-2">
            <i className="fas fa-map-marker-alt text-muted" />{" "}
            {getLocationName(candidate.city)}
          </p>
          <div className="tags-wrapper mt-auto">
            <MatchLabels match={match} />
          </div>
        </Media>
        <div className="d-flex flex-column text-right">
          {shouldRenderExpire(match) && (
            <div className="pb-1">
              <Expires small expiresAt={match.expiresAt} />
            </div>
          )}
          {shouldRenderMatched(match) && (
            <div className="pb-1">
              <Matched match={match} />
            </div>
          )}
          <div className="d-flex flex-row align-items-center justify-content-end">
            {canSendMessage(match) && match.thread && (
              <Button
                className="mr-2 btn-circle d-flex justify-content-center align-items-center"
                tag={Link}
                to={`/messages/${match.thread.id}`}
                color="light"
              >
                <i className="fab fa-telegram-plane fa-lg" />
              </Button>
            )}
            {isPreviewCvVisible(match, candidate) && (
              <CandidateCVButton
                color="primary"
                className="btn-pill"
                candidate={candidate}
                match={match}
              >
                <i className="fas fa-eye mr-md-1" />
                <span className="d-none d-md-inline">
                  <FormattedMessage id="app.common.previewCv" />
                </span>
              </CandidateCVButton>
            )}
          </div>
        </div>
      </Media>
    </div>
  )
}

ExtendedMatchCard.propTypes = {
  match: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  thread: PropTypes.object,
}
