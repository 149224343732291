import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { FormattedRelative } from "./formatted-relative"

export function Referred({ referredAt }) {
  return (
    <small className="text-muted">
      <FormattedMessage
        id="app.candidate.referredAt"
        values={{
          time: <FormattedRelative timestamp={referredAt} />,
        }}
      />
    </small>
  )
}

Referred.propTypes = {
  referredAt: PropTypes.string.isRequired,
}

Referred.defaultProps = {}
