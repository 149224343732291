import React from "react"
import { Media } from "reactstrap"
import PropTypes from "prop-types"
import { CardStatusTag } from "../common/card-status-tag"
import {
  MANAGER_STATUSES_COLOR_MAP,
  MANAGER_STATUSES_ICON_MAP,
  MANAGER_STATUSES_TEXT_MAP,
} from "../../default-options"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { getFullName } from "../../lib/helpers"
import { PersonInitials } from "../common/person-initials"
import { Badge } from "../common/badge-component"
import { Button } from "../common/button-component"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api } from "../../lib/moberries-api"

export function ManagerCard({
  firstName,
  lastName,
  isAccountActive,
  teams,
  email,
  id,
  isSuperManager,
}) {
  const fullName = getFullName({ firstName, lastName })
  const editUrl = `/managers/${id}/edit`
  const { mutate: invite, isLoading: isInviting } = useAxiosMutation(
    api.inviteManager,
  )

  return (
    <Media className="bg-white p-3 mb-1">
      <Media left>
        <PersonInitials
          firstName={firstName}
          lastName={lastName}
          className="mr-3 d-none d-md-flex rounded-circle"
        />
      </Media>
      <Media body>
        <div className="d-flex align-items-start">
          <Media heading>{fullName}</Media>
          {isSuperManager && (
            <Badge className="ml-2" color="success-light">
              <FormattedMessage id="app.common.admin" />
            </Badge>
          )}
        </div>
        <a className="text-break" href={`mailto:${email}`}>
          {email}
        </a>
        {teams && teams.length > 0 && (
          <div className="tags-wrapper mt-1">
            {teams.map(d => (
              <Badge key={(d && d.id) || d} color={d.color || "default"}>
                {(d && d.name) || d}
              </Badge>
            ))}
          </div>
        )}
      </Media>

      <Media right className="card-controls d-flex align-items-center pl-1">
        {isAccountActive ? (
          <CardStatusTag
            id={id}
            icon={MANAGER_STATUSES_ICON_MAP.ACT}
            color={MANAGER_STATUSES_COLOR_MAP.ACT}
            text={<FormattedMessage id={MANAGER_STATUSES_TEXT_MAP.ACT} />}
          />
        ) : (
          <Button
            disabled={isInviting}
            loading={isInviting}
            onClick={() => {
              invite({ id })
            }}
            color="primary"
            size="sm"
            className="btn-sm-circle"
          >
            <i className="fas fa-redo mr-md-1" />
            <span className="d-none d-md-inline">
              <FormattedMessage id="app.manager.resend" />
            </span>
          </Button>
        )}

        <Button
          tag={Link}
          to={editUrl}
          color="light"
          className="ml-1 btn-circle"
        >
          <i className="fas fa-pen" />
        </Button>
      </Media>
    </Media>
  )
}

ManagerCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAccountActive: PropTypes.bool.isRequired,
  isSuperManager: PropTypes.bool.isRequired,
}

ManagerCard.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  teams: [],
  isAccountActive: false,
  isSuperManager: false,
}
