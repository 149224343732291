import { getActionType, arrayToObject } from "../lib/helpers"
import {
  always,
  evolve,
  F,
  lensProp,
  mergeLeft,
  over,
  T,
  unionWith,
  eqBy,
  prop,
  differenceWith,
} from "ramda"
import {
  GET,
  JOB,
  LIST,
  START,
  FAIL,
  COMPANY,
  SUCCESS,
  UPDATE,
  SET,
  INTEGRATION,
  SELECTED,
  JOBS,
  CONDITIONS,
  FEEDBACK,
} from "../constants"
import { DEFAULT_JOB_FEEDBACK_REASONS } from "../default-options"

export const defaultState = {
  count: null,
  jobs: [],
  entities: {},
  isLoading: false,
  isUpdating: false,
  creating: false,
  deleting: false,
  integrationSelected: false,
  err: null,
  updatingErr: null,
  isUpdatingJobsConditions: false,
  jobFeedbackByJobId: {},
  isLoadingJobFeedback: false,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, JOB, START):
    case getActionType(GET, COMPANY, JOB, LIST, START):
      return evolve(
        {
          isLoading: T,
        },
        state,
      )

    case getActionType(GET, JOB, SUCCESS):
      return evolve(
        {
          isLoading: F,
          err: always(null),
          entities: over(lensProp(payload.job.id), mergeLeft(payload.job)),
        },
        state,
      )

    case getActionType(GET, COMPANY, JOB, LIST, SUCCESS):
      return evolve(
        {
          isLoading: F,
          err: always(null),
          entities: mergeLeft(arrayToObject(payload.jobs, "id")),
          jobs: always(payload.jobs.map(j => j.id)),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(UPDATE, COMPANY, JOBS, SUCCESS):
      return evolve(
        {
          entities: mergeLeft(arrayToObject(payload.jobs, "id")),
        },
        state,
      )

    case getActionType(GET, COMPANY, JOB, LIST, FAIL):
      return evolve(
        {
          isLoading: F,
          err: always(payload.err),
        },
        state,
      )

    case getActionType(GET, JOB, FAIL):
      return evolve({ isLoading: F, err: always(payload.err) }, state)

    case getActionType(UPDATE, JOB, START):
      return evolve({ isUpdating: T }, state)

    case getActionType(UPDATE, JOB, SUCCESS):
      return evolve(
        {
          isUpdating: F,
          entities: over(lensProp(payload.job.id), mergeLeft(payload.job)),
          updatingErr: always(null),
        },
        state,
      )

    case getActionType(UPDATE, JOB, FAIL):
      return evolve({ isUpdating: F, updatingErr: always(payload.err) }, state)

    case getActionType(SET, INTEGRATION, SELECTED):
      return evolve(
        { integrationSelected: always(payload.integrationSelected) },
        state,
      )

    case getActionType(UPDATE, JOBS, CONDITIONS, START):
      return evolve({ isUpdatingJobsConditions: T }, state)

    case getActionType(UPDATE, JOBS, CONDITIONS, SUCCESS):
      return evolve({ isUpdatingJobsConditions: F }, state)

    case getActionType(UPDATE, JOBS, CONDITIONS, FAIL):
      return evolve({ isUpdatingJobsConditions: F }, state)
    case getActionType(GET, JOB, FEEDBACK, START):
      return evolve(
        {
          isLoadingJobFeedback: T,
        },
        state,
      )
    case getActionType(GET, JOB, FEEDBACK, SUCCESS):
      return evolve(
        {
          isLoadingJobFeedback: F,
          jobFeedbackByJobId: over(
            lensProp(payload.jobId),
            always(
              evolve(
                {
                  jobReasons: unionWith(
                    eqBy(prop("value")),
                    differenceWith(
                      (jobReason, excluded) => jobReason.value === excluded,
                      payload.jobFeedback.jobReasons,
                      ["job_role", "other", "already_applied"],
                    ),
                  ),
                  companyReasons: unionWith(
                    eqBy(prop("value")),
                    payload.jobFeedback.companyReasons,
                  ),
                  missingSkills: always(payload.jobFeedback.missingSkills),
                },
                DEFAULT_JOB_FEEDBACK_REASONS,
              ),
            ),
          ),
        },
        state,
      )
    case getActionType(GET, JOB, FEEDBACK, FAIL):
      return evolve({ isLoadingJobFeedback: F }, state)

    default:
      return state
  }
}
