import React from "react"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { NotFound } from "../components/common/not-found-component"

export function NotFoundPage() {
  return (
    <SingleColumnLayout>
      <div className="mb-4 text-center">
        <NotFound />
      </div>
    </SingleColumnLayout>
  )
}
