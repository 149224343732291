import React from "react"
import { FormattedMessage } from "react-intl"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { api, ApiKeys } from "../../lib/moberries-api"
import { MonetizationList } from "./monetization-list"

const TEN_MIN_IN_MS = 10 * 60 * 1000

export const MonetizationOverviewWidget = () => {
  const { data: companyRewards, isFetched } = useAxiosQuery(
    [ApiKeys.CompanyRewards],
    api.getCompanyRewards,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: TEN_MIN_IN_MS,
    },
  )

  return (
    <div className="bg-white p-3 rounded mb-3">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-coins" />
        </div>
        <FormattedMessage id="app.widget.monetization.overview.title" />
      </h5>
      {isFetched && (
        <MonetizationList
          companyRewards={companyRewards}
          dataKeys={[
            "candidates",
            "leads",
            "applications",
            "referredIntros",
            "hires",
            "revenueSum",
          ]}
        />
      )}
    </div>
  )
}
