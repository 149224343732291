import * as Sentry from "@sentry/react"
import { config } from "../config"

let environment = "local"
if (config.branch.isMaster) {
  environment = "development"
} else if (config.branch.isRelease) {
  environment = "production"
}

if (environment !== "local") {
  Sentry.init({
    dsn:
      "https://c085b79901fa4e2d90cc19ecf6c04cb4@o50485.ingest.sentry.io/136943",
    release: `${config.package.name}@${config.package.version}`,
    normalizeDepth: 7,
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb.category === "console" && !config.branch.isRelease) {
        return null
      }
      return breadcrumb
    },
    environment: environment,
    ignoreErrors: [
      "top.GLOBALS",
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      "fb_xd_fragment",
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      "conduitPage",
      "vid_mate_check is not defined",
      "window._LRLogger is not a constructor",
      "ResizeObserver loop limit exceeded",
    ],
  })
}
