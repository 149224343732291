import React, { Fragment } from "react"
import qs from "qs"
import { Container, Col, Row } from "reactstrap"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { ReferredCandidateListPageSkeleton } from "../components/skeletons/referred-candidate-list-page-skeleton"
import { CandidateCard } from "../components/candidate/candidate-card-component"
import { Pagination } from "../components/common/pagination-component"
import { Button } from "../components/common/button-component"
import { FormattedMessage } from "react-intl"
import { Referred } from "../components/common/referred-component"

import { REFERRED_CANDIDATES_LIMIT } from "../constants"
import { Redirect, useHistory, useLocation } from "react-router"
import { resetPageQueryParam } from "../lib/helpers"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api, ApiKeys } from "../lib/moberries-api"
import { ErrorPage } from "./error-page"
import { JobRoleFilter } from "../components/filters/job-role-filter"
import { JobCategoryFilter } from "../components/filters/job-category-filter"
import defaultImage from "../assets/img/default-empty-state.svg"

export const ReferredCandidatesListPage = () => {
  const location = useLocation()
  const history = useHistory()
  const { page, jobRole, jobCategory } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const {
    data: { results: candidates = [], count } = {},
    isLoading,
    error,
  } = useAxiosQuery(
    [
      ApiKeys.ReferredCandidates,
      {
        params: {
          ordering: "-completed_at",
          page,
          jobRoles: jobRole,
          job_roles__categories: jobCategory,
          limit: REFERRED_CANDIDATES_LIMIT,
        },
      },
    ],
    api.getReferredCandidates,
  )

  if (error) {
    return <ErrorPage err={error} />
  }

  if (isLoading) {
    return <ReferredCandidateListPageSkeleton />
  }

  if (candidates.length === 0 && page > 1) {
    return (
      <Redirect
        to={{
          pathname: "/referrals",
          search: resetPageQueryParam(location.search),
        }}
      />
    )
  }

  return (
    <Fragment>
      <Container>
        <h1 className="text-center">
          <FormattedMessage id="app.talentPool.title" />
        </h1>
        <p className="text-center">
          <FormattedMessage id="app.talentPool.text" />
        </p>
      </Container>
      <SingleColumnLayout>
        <Row noGutters className="bg-white mb-2 p-3 rounded-bottom">
          <Col xs="12" lg="6" className="pr-3">
            <JobRoleFilter />
          </Col>
          <Col xs="12" lg="6" className="pl-3">
            <JobCategoryFilter />
          </Col>
        </Row>
        {count > 0 ? (
          candidates.map(c => (
            <div key={c.id} className="mb-2">
              <CandidateCard
                onClick={() => history.push(`/referral/${c.id}`)}
                candidate={c}
                controls={
                  <Fragment>
                    <div className="mb-1">
                      <Referred referredAt={c.completedAt} />
                    </div>
                    <Button>
                      <FormattedMessage id="app.common.details" />{" "}
                      <i className="fas fa-angle-right" />
                    </Button>
                  </Fragment>
                }
              />
            </div>
          ))
        ) : (
          <div className="p-3 text-center">
            <img
              alt="Empty"
              width={600}
              className="img-fluid"
              src={defaultImage}
            />
          </div>
        )}

        {count > REFERRED_CANDIDATES_LIMIT && (
          <div className="mt-3">
            <Pagination
              listClassName="justify-content-end"
              page={Number(page ?? 1)}
              count={count}
              disabled={isLoading}
              limit={REFERRED_CANDIDATES_LIMIT}
              basePath="/referrals"
            />
          </div>
        )}
      </SingleColumnLayout>
    </Fragment>
  )
}
