import React, { useEffect } from "react"
import { FormGroup, Label, PopoverBody, UncontrolledPopover } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Select } from "../common/select-component-new"
import { useFormikField } from "../../hooks/use-formik-field"
import { InfoHint } from "../common/info-hint-component"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import { htmlToEditorState } from "../../lib/helpers/text-helpers"
import { JobStatusTag } from "./job-status-tag-component"
import { ATS_JOB_STATUSES_COLOR_MAP } from "../../constants"
import { isJobConnected } from "../../lib/helpers"
import { getAtsOpenJobsAndJobIntegrations } from "../../actions/ats-company-integration-open-jobs-actions"
import { atsCompanyIntegrationOpenJobsSelector } from "../../selectors/ats-company-integration-open-jobs-selector"
import { useStore } from "../../lib/store"

export const LabeledJobAtsIntegrationField = props => {
  const [field, , actions] = useFormikField(props)
  const { setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const atsOpenJobs = useSelector(atsCompanyIntegrationOpenJobsSelector)

  useEffect(() => {
    dispatch(getAtsOpenJobsAndJobIntegrations({}))
  }, [dispatch])

  if (!atsCompanyIntegration) {
    return null
  }

  return (
    <FormGroup>
      <Label for="atsJob">
        <FormattedMessage id="app.job.form.ats.title.label" />
        <InfoHint
          id="atsJobHint"
          popover={
            <PopoverBody>
              <FormattedMessage id="app.job.form.ats.hint" />
            </PopoverBody>
          }
        />
      </Label>
      <Select
        {...field}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.title}
        isOptionDisabled={isJobConnected}
        options={atsOpenJobs}
        defaultOptions
        onChange={atsJob => {
          actions.setValue(atsJob)
          setFieldValue("description", htmlToEditorState(atsJob.description))
          setFieldValue("title", atsJob.title)
          // for reverse compatibility with old redux ats logic, remove when completely got rid of redux :)
          setFieldValue("atsJob", atsJob)
        }}
        formatOptionLabel={option => {
          const isConnected = isJobConnected(option)
          const isSelected = option.id === field.value?.id
          return (
            <div className="d-flex w-100 justify-content-between">
              <div>
                {option.title}
                {isConnected && !isSelected && (
                  <i className="fas fa-plug text-success ml-2" />
                )}
              </div>
              <div>
                {isConnected && !isSelected && (
                  <div>
                    <span id={`hint-status-${option.id}`}>
                      <JobStatusTag
                        job={{
                          status: option.moberriesStatus,
                          id: option.id,
                        }}
                        colorMap={ATS_JOB_STATUSES_COLOR_MAP}
                      />
                    </span>
                    <UncontrolledPopover
                      placement="auto"
                      target={`hint-status-${option.id}`}
                      trigger="hover"
                    >
                      <PopoverBody>
                        <FormattedMessage id="app.page.integrations.import.jobStatus.popover" />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                )}
              </div>
            </div>
          )
        }}
      />
    </FormGroup>
  )
}
