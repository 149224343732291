import React from "react"
import PropTypes from "prop-types"
import { Button as BButton } from "reactstrap"

export function Button({ loading, children, className, ...rest }) {
  return (
    <BButton {...rest} className={className}>
      {loading && <i className="fas fa-circle-notch fa-spin mr-1" />}
      {children}
    </BButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  className: null,
  loading: false,
}
