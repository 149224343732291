import React from "react"
import PropTypes from "prop-types"

import { CircularProgress } from "./circular-progress"
import styles from "./sliced-doughnuts.module.scss"
import { FormattedMessage } from "react-intl"
import { isIntlId } from "../../../../lib/helpers"

export function SlicedDoughnuts(props) {
  return (
    <>
      <h4 className={styles.title}>
        <FormattedMessage id={props.title} />
      </h4>
      {(props.data || []).map((item, index, arr) => {
        const percentage = Math.round(item.value * 10) / 10
        const startDeg = index
          ? arr.slice(0, index).reduce((acc, item) => acc + item.value, 0)
          : 0
        return (
          <div className={styles.itemWrapper} key={item.title}>
            <div className={styles.item}>
              <div className={styles.textWrapper}>
                <p className={styles.text}>
                  {isIntlId(item.title) ? (
                    <FormattedMessage id={item.title} />
                  ) : (
                    item.title
                  )}
                </p>
                <h2 className={styles.percentage}>{percentage}%</h2>
              </div>
              <div
                style={{
                  transform: `rotate(${startDeg * 3.6}deg) scale(.7)`,
                }}
              >
                <CircularProgress color={item.color} percentage={percentage} />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
SlicedDoughnuts.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
