import { Howl } from "howler"
import messageNotificationSound from "../../assets/sounds/message-sound-bell.mp3"

const bellSound = new Howl({
  src: [messageNotificationSound],
})

export const playBellSound = () => {
  bellSound.play()
}
