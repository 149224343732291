import React from "react"
import PropTypes from "prop-types"
import styles from "./chart-legend.module.scss"
import { FormattedMessage } from "react-intl"
import { isIntlId } from "../../../lib/helpers"

export function ChartLegend(props) {
  const { value, title, color } = props
  return (
    <div className={styles.container}>
      {(typeof value === "string" || typeof value === "number") && (
        <span className={styles.value}>{value}</span>
      )}
      <div className={styles.figureTitleWrapper}>
        <div className={styles.figure} style={{ backgroundColor: color }} />
        <span className={styles.title}>
          {isIntlId(title) ? <FormattedMessage id={title} /> : title}
        </span>
      </div>
    </div>
  )
}

ChartLegend.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}
