import { createSelector } from "reselect"

export const stateSelector = state => state.vrCandidate

export const countSelector = createSelector(stateSelector, state => state.count)

export const isLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)
export const emailsSelector = createSelector(
  stateSelector,
  state => state.vrCandidates,
)

export const vrCandidatesSelector = createSelector(
  entitiesSelector,
  emailsSelector,
  (entities, emails) => emails.map(email => entities[email]),
)

export const statusUpdatingSelector = createSelector(
  stateSelector,
  state => state.statusIsUpdating,
)

export const searchSelector = createSelector(
  stateSelector,
  state => state.search,
)

export const campaignSelector = createSelector(
  stateSelector,
  state => state.campaign,
)
