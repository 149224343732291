import React from "react"
import { Media } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { FormattedLink } from "../../../common/formatted-link-component"
import { Img } from "../../../common/img-component"
import { DashboardWidgetLayout } from "../../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import leagueBg from "../../../../assets/img/league-bg.svg"
import LeagueIcon from "../../../../assets/img/league-icon.svg"
import info from "../../../../assets/img/info-icon.svg"
import styles from "./talent-affiliation-widget-empty.module.scss"
import { store } from "../../../../lib/store"
import { config } from "../../../../config"

export const TalentAffiliationWidgetEmpty = () => {
  const { jwt } = store.getState()
  const affiliateRedirectUrl = `${config.moberries.affiliate.url}?token=${jwt}&type=redirect&purpose=0`
  return (
    <DashboardWidgetLayout
      title={
        <FormattedMessage id="app.page.analytics.charts.talentAffiliation.name" />
      }
    >
      <div className={styles.content}>
        <div className={styles.illustration}>
          <Img src={leagueBg} width="138" height="94" alt="Moberries" />
          <Img src={LeagueIcon} width="77" height="99" alt="Moberries" />
        </div>
        <div className={styles.text}>
          <p className="mb-2">
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.title" />
          </p>
          <p>
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.subTitle" />
          </p>
        </div>
        <a
          className={styles.button}
          href={affiliateRedirectUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.button" />
        </a>
      </div>
      <Media className={styles.info}>
        <Img src={info} alt="info" width="21" height="21" />
        <Media body>
          <p>
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.info1" />{" "}
            <FormattedLink
              target="_blank"
              rel="noopener noreferrer"
              href="recruiters"
            >
              <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.info1.here" />
            </FormattedLink>
            .
          </p>
          <p className="m-0">
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.info2" />{" "}
            <a className={styles.email} href="mailto:am@moberries.com">
              am@moberries.com
            </a>
          </p>
        </Media>
      </Media>
    </DashboardWidgetLayout>
  )
}
