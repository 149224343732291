import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { times } from "ramda"
import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { formatMoney } from "../../../lib/helpers"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { Lines } from "../../charts/lib/lines"
import { ValuePicker } from "../../charts/lib/value-picker/value-picker"
import { ChartLegend } from "../../charts/chart-legend/chart-legend"
import {
  availableSelections,
  getLegends,
  getTalentStatsDataAndLabels,
} from "../../charts/talent-affiliation/helpers"
import { DashboardWidgetLayout } from "../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import { TalentAffiliationWidgetEmpty } from "./talent-affiliation-widget-empty/talent-affiliation-widget-empty"
import styles from "./talent-affiliation-widget.module.scss"
import { store } from "../../../lib/store"
import { config } from "../../../config"
import { List } from "../../loaders/list-loader"

export const TalentAffiliationWidget = () => {
  const { jwt } = store.getState()
  const affiliateRedirectUrl = `${config.moberries.affiliate.url}?token=${jwt}&type=redirect&purpose=0`
  const [selection, setSelection] = useState(availableSelections[0])

  const { data: kpisData, isLoading: isLoadingTalentKpis } = useAxiosQuery(
    ApiKeys.TalentKpis,
    moberriesApi.getRewardsKpis,
  )
  const isEmpty = kpisData?.active + kpisData?.invited <= 0

  const { data: data1, isLoading: isLoadingTalentRewardsTimeSeries } =
    useAxiosQuery(
      [
        ApiKeys.TalentRewardsTimeSeries,
        {
          end: selection.value.end.split("T")[0],
          start: selection.value.start.split("T")[0],
        },
      ],
      moberriesApi.getRewardsTimeSeries,
    )
  const { data: data2, isLoading: isLoadingTalentRewardsReferrals } =
    useAxiosQuery(
      [
        ApiKeys.TalentRewardsReferrals,
        {
          end: selection.value.end.split("T")[0],
          start: selection.value.start.split("T")[0],
        },
      ],
      moberriesApi.getRewardsReferrals,
    )

  const isLoading =
    isLoadingTalentKpis ||
    isLoadingTalentRewardsTimeSeries ||
    isLoadingTalentRewardsReferrals

  if (isEmpty && !isLoading) {
    return <TalentAffiliationWidgetEmpty />
  }

  return (
    <DashboardWidgetLayout
      title={
        <FormattedMessage id="app.page.analytics.charts.talentAffiliation.name" />
      }
    >
      {isLoading ? (
        <div className={styles.listLoader}>
          {times(
            i => (
              <List key={i} />
            ),
            2,
          )}
        </div>
      ) : (
        <>
          <div>
            <div className={styles.head}>
              <h4>
                <FormattedMessage id="app.page.analytics.charts.talentAffiliation.totalRevenue" />{" "}
                -{" "}
                {formatMoney(
                  kpisData?.totalRevenue ? kpisData.totalRevenue / 100 : 0,
                )}
              </h4>
              <ValuePicker
                availableValues={availableSelections}
                setSelected={setSelection}
                selectedValue={selection}
              />
            </div>
            <p className={styles.subTitle}>
              <FormattedMessage id="app.page.analytics.charts.talentAffiliation.candidates" />
            </p>
          </div>
          {/* TODO: reduce the chart canvas height to make this widget pixel perfect */}
          <Lines {...getTalentStatsDataAndLabels(data1, data2, selection)} />
          <div className={styles.legends}>
            {getLegends(kpisData).map(legendProps => (
              <ChartLegend {...legendProps} key={legendProps.title} />
            ))}
          </div>
          <div className={styles.separator} />
          <div className={styles.bottom}>
            <h4>
              <FormattedMessage id="app.page.analytics.charts.talentAffiliation.footer" />
            </h4>
            <a
              className={styles.button}
              href={affiliateRedirectUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.button" />
            </a>
          </div>
        </>
      )}
    </DashboardWidgetLayout>
  )
}
