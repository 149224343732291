import React from "react"
import styles from "./candidates-chart.module.scss"
import { FormattedMessage } from "react-intl"
import { Doughnut } from "../lib/doughnut"
import { ChartLegend } from "../chart-legend/chart-legend"
import { candidatesChartDataMapper } from "./helpers"
import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { ChartEmptyState } from "../chart-empty-state/chart-empty-state"
import emptyIcon from "../../../assets/img/illustration-girl-head.svg"
import { CandidateChartSkeleton } from "./candidate-chart-skeleton/candidate-chart-skeleton"

export function CandidatesChart() {
  const { data: candidateJobSourceStats, isLoading } = useAxiosQuery(
    ApiKeys.CandidatesJobSourceStats,
    moberriesApi.getCandidatesJobSourceStats,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const chartData = candidatesChartDataMapper(
    candidateJobSourceStats?.jobSources,
  )

  const isEmpty = !chartData.data.length

  if (isLoading) return <CandidateChartSkeleton />

  if (isEmpty && !isLoading) {
    return (
      <ChartEmptyState
        title="app.page.analytics.charts.candidates.name"
        info="app.page.analytics.charts.common.empty.text"
        img={emptyIcon}
      />
    )
  }

  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="app.page.analytics.charts.candidates.name" />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.chart}>
            <Doughnut {...chartData} />
          </div>
          {!!chartData.labels.length && (
            <div className={styles.legends}>
              <h5>
                <FormattedMessage id="app.page.analytics.charts.candidates.legends.title" />
              </h5>
              {chartData.labels.map(legend => (
                <ChartLegend
                  key={legend.label}
                  title={`app.page.analytics.charts.candidates.legends.${legend.label}`}
                  color={legend.color}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
