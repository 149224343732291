import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./dashboard-widget-layout.module.scss"

export const DashboardWidgetLayout = ({ className, title, children }) => {
  return (
    <div className={styles.wrapper}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={cn(styles.container, className)}>{children}</div>
    </div>
  )
}

DashboardWidgetLayout.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
}
