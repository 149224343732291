export const categoryColors = ["#E6413C", "#FC7808", "#EACB17", "#589452"]

const activeJobsCareerLevelMap = {
  student: {
    title: "app.common.career.level.student",
    color: "#E6413C",
  },
  entryLevel: {
    title: "app.common.career.level.entry",
    color: "#FC7808",
  },
  intermediate: {
    title: "app.common.career.level.intermediate",
    color: "#4E4584",
  },
  senior: {
    title: "app.common.career.level.senior",
    color: "#a2e63c",
  },
  expert: {
    title: "app.common.career.level.lead",
    color: "#3ccde6",
  },
  executive: {
    title: "app.common.career.level.executive",
    color: "#c73ce6",
  },
}

export const careerLevelMapper = (data = {}) => {
  const highestPercentageLevels = Object.entries(data)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 4)
  return Object.entries(activeJobsCareerLevelMap)
    .map(([mapKey, m]) => {
      const foundValueEntry = highestPercentageLevels.find(
        ([key]) => key === mapKey,
      )
      return {
        ...m,
        value:
          foundValueEntry && foundValueEntry.length ? foundValueEntry[1] : 0,
      }
    })
    .filter(i => i.value)
}

export const categoryMapper = data =>
  (data || [])
    .sort((a, b) => b.value - a.value)
    .slice(0, 4)
    .map((d, i) => ({
      ...d,
      title: d.name,
      color: categoryColors[i],
    }))
