import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Media } from "reactstrap"
import { Img } from "../common/img-component"
import {
  formatDate,
  getFullName,
  isMessageFromManager,
} from "../../lib/helpers"
import { FormattedMessage } from "react-intl"
import Linkify from "linkifyjs/react"
import { MessageReadStatus } from "./message-read-status-component"

export const Message = ({ message, isArchived }) => {
  const isFromManager = isMessageFromManager(message)
  const fullName = getFullName(message.authorData)

  return (
    <Media
      className={classNames("px-3 py-2", { "flex-row-reverse": isFromManager })}
    >
      <Img
        src={message.authorData && message.authorData.image}
        width="40"
        height="40"
        alt="Author"
        className={classNames(
          "rounded-circle align-self-end mb-4 _lr-hide flex-shrink-0",
          {
            grayscale: isArchived,
            "ml-2": isFromManager,
            "mr-2": !isFromManager,
          },
        )}
      />
      <div
        className={classNames("_lr-hide", {
          "pl-md-7": isFromManager,
          "pr-md-7": !isFromManager,
        })}
      >
        <div
          className={classNames("p-3 rounded", {
            "bg-active": isFromManager && !isArchived,
            "bg-light": !isFromManager || isArchived,
          })}
        >
          <div className="font-weight-bold pb-1">
            {fullName || <FormattedMessage id="app.common.user.deleted" />}
          </div>
          <div>
            {message.text.split(/\r\n|\r|\n/g).map((chunk, idx) => {
              return (
                <Fragment key={idx}>
                  <Linkify className="text-break">{chunk}</Linkify>
                  <br />
                </Fragment>
              )
            })}
          </div>
        </div>
        <div
          className={classNames("text-muted text-smaller mt-1", {
            "text-right": isFromManager,
          })}
        >
          {isFromManager && !isArchived && (
            <MessageReadStatus isRead={message.isRead} />
          )}
          {formatDate(message.created)}
        </div>
      </div>
    </Media>
  )
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  isArchived: PropTypes.bool,
}

Message.defaultProps = {
  isArchived: false,
}
