import React, { useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import {
  Row,
  Col,
  Input,
  Label,
  PopoverBody,
  FormGroup,
  FormFeedback,
} from "reactstrap"
import { Element as ScrollElement } from "react-scroll"
import { api as moberriesApi, ApiKeys } from "../../lib/moberries-api"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { FormattedMessage } from "react-intl"
import { JOB_LEVELS } from "../../default-options"
import { InfoHint } from "../common/info-hint-component"
import { nanoid } from "nanoid"
import { useFormikField } from "../../hooks/use-formik-field"
import { useBreakpoint } from "../../hooks/use-breakpoint"
import { FormattedErrorMessage } from "../common/formatted-error-message"
import { Select } from "../common/select-component-new"
import { Button } from "../common/button-component"

export const JobRolesSelectField = ({ label, hint, isRequired, ...rest }) => {
  const isXsOrSmScreen = useBreakpoint("xs", "md")
  const size = isXsOrSmScreen ? "1" : "6"
  const [field, meta, actions] = useFormikField(rest)
  const { data: { results: jobCategories = [] } = {} } = useAxiosQuery(
    [ApiKeys.JobCategories],
    moberriesApi.getJobCategories,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const [selectedCategoryId, selectCategoryId] = useState("")
  const [selectedRoleId, selectRoleId] = useState("")

  const [hintId] = useState(nanoid())

  const rolesInSelectedCategory =
    jobCategories.find(
      jobCategory => String(jobCategory.id) === selectedCategoryId,
    )?.roles ?? []

  const selectChangeHandler = ({ value, item }) => {
    const newItem = { ...item, ...value }
    const newItems = field.value.map(i => (i.id === item.id ? newItem : i))
    actions.setTouched()
    actions.setValue(newItems)
  }

  const jobRoleRemoveHandler = item => {
    const newItems = field.value.filter(i => i.id !== item.id)
    actions.setTouched()
    actions.setValue(newItems)
  }

  return (
    <FormGroup>
      <ScrollElement name={`form-position-${rest.name}`} />

      <Label>
        {label}
        {isRequired && " *"}
        {hint && (
          <InfoHint id={hintId} popover={<PopoverBody>{hint}</PopoverBody>} />
        )}
      </Label>
      <Row
        className={cn("job-role-select", { "mb-2": field.value.length > 0 })}
        noGutters
      >
        <Col xs={12} md={4} className="mt-1 mt-md-0">
          <small className="pb-1 d-block">
            <FormattedMessage id="app.common.category" />
          </small>

          <Input
            type="select"
            size={size}
            value={selectedCategoryId}
            onChange={e => selectCategoryId(e.target.value)}
            disabled={field.value.length >= 5}
            className={cn({
              "border-danger": meta.touched && Boolean(meta.error),
            })}
          >
            <FormattedMessage id="app.common.select">
              {txt => (
                <option value="" disabled>
                  {txt}
                </option>
              )}
            </FormattedMessage>
            {jobCategories.map(jobCategory => (
              <option key={jobCategory.id} value={jobCategory.id}>
                {jobCategory.name}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs={12} md={4} className="pl-md-1 mt-1 mt-md-0">
          <small className="pb-1 d-block">
            <FormattedMessage id="app.job.form.job.roles.role" />
          </small>

          <Input
            type="select"
            size={size}
            value={selectedRoleId}
            disabled={field.value.length >= 5 || selectedCategoryId === ""}
            onChange={e => selectRoleId(e.target.value)}
            className={cn({
              "border-danger": meta.touched && Boolean(meta.error),
            })}
          >
            <FormattedMessage id="app.common.select">
              {txt => (
                <option value="" disabled>
                  {txt}
                </option>
              )}
            </FormattedMessage>
            {rolesInSelectedCategory
              .filter(role => !field.value.some(v => v.id === role.id))
              .map(jobRole => (
                <option key={jobRole.id} value={jobRole.id}>
                  {jobRole.name}
                </option>
              ))}
          </Input>
        </Col>
        <Col xs={12} md={4} className="mt-1 mt-md-0 pl-md-1">
          <small className="pb-1 d-block">
            <FormattedMessage id="app.job.form.job.roles.experience" />
          </small>

          <Input
            type="select"
            size={size}
            disabled={selectedRoleId === "" || field.value.length >= 5}
            onChange={e => {
              actions.setTouched()
              actions.setValue([
                ...field.value,
                {
                  id: Number(selectedRoleId),
                  level: Number(e.target.value),
                  name: rolesInSelectedCategory.find(
                    r => String(r.id) === selectedRoleId,
                  ).name,
                  levelName: JOB_LEVELS.find(
                    jl => jl.id === Number(e.target.value),
                  ).name,
                },
              ])
              selectCategoryId("")
              selectRoleId("")
            }}
            className={cn({
              "border-danger": meta.touched && Boolean(meta.error),
            })}
          >
            <FormattedMessage id="app.common.select">
              {txt => (
                <option value="" disabled>
                  {txt}
                </option>
              )}
            </FormattedMessage>
            {selectedRoleId === ""
              ? []
              : JOB_LEVELS.map(l => (
                  <FormattedMessage id={l.name} key={l.id} value={l.id}>
                    {txt => <option value={l.id}>{txt}</option>}
                  </FormattedMessage>
                ))}
          </Input>
        </Col>
      </Row>

      {field.value.map(item => (
        <Row
          noGutters
          key={item.id}
          className="d-flex border border-success rounded my-1 p-2 bg-success-light"
        >
          <Col xs="12" md="5" className="rounded">
            <Select
              value={item}
              loadOptions={async search => {
                const {
                  data: { results: roles },
                } = await moberriesApi.getJobRoles({ params: { search } })
                return roles
              }}
              filterOption={option =>
                !field.value.some(i => i.id === option.value)
              }
              onChange={value => selectChangeHandler({ value, item })}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              async
              defaultOptions
            />
          </Col>
          <Col xs="12" md="3" className="mt-1 mt-md-0 pl-md-2 rounded">
            <Select
              value={item}
              options={JOB_LEVELS.map(l => ({
                levelName: l.name,
                level: l.id,
              }))}
              getOptionValue={option => option.level}
              getOptionLabel={option => (
                <FormattedMessage id={option.levelName} />
              )}
              onChange={value => selectChangeHandler({ value, item })}
              isSearchable={false}
              defaultOptions
            />
          </Col>
          <Col xs="12" md="3" className="mt-1 mt-md-0 pl-md-2 rounded">
            <Select
              value={item}
              options={[{ mustHave: true }, { mustHave: false }]}
              getOptionValue={option => option.mustHave}
              getOptionLabel={option =>
                option.mustHave ? "Must Have" : "Optional"
              }
              onChange={value => selectChangeHandler({ value, item })}
              isSearchable={false}
              defaultOptions
            />
          </Col>
          <Col
            xs="12"
            md="1"
            className="d-flex justify-content-md-center justify-content-end align-content-center"
          >
            <Button
              type="button"
              color="success-light"
              size="sm"
              className="ml-md-2 mt-2 mt-md-0"
              onClick={() => {
                jobRoleRemoveHandler(item)
              }}
              block={isXsOrSmScreen}
            >
              <i className="fas fa-times align-middle mr-2 mr-md-0" />
              <span className="d-md-none">
                <FormattedMessage id="app.common.delete" />
              </span>
            </Button>
          </Col>
        </Row>
      ))}

      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

JobRolesSelectField.propTypes = {
  label: PropTypes.node.isRequired,
  hint: PropTypes.node,
  isRequired: PropTypes.bool,
}

JobRolesSelectField.defaultProps = {
  isRequired: false,
  hint: null,
}
