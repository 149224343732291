import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Select } from "../common/select-component"
import { useFilter } from "../../hooks/use-filter"

export const SelectFilter = ({
  filterOptions,
  filterKey,
  loadFilterOptions,
  placeholder,
  disabled,
  async,
  label,
  resetFilters,
  isSearchable,
  isClearable,
}) => {
  const { activeFilter, filters, applyFilter } = useFilter({
    filterOptions,
    filterKey,
    getValue: filter => filter.id,
    getTitle: filter => filter.name,
    resetFilters,
  })
  return (
    <Select
      async={async}
      input={{
        value: activeFilter,
        onChange: applyFilter,
      }}
      isClearable={isClearable}
      withCaret
      meta={{}}
      options={filters}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.title}
      loadOptions={loadFilterOptions}
      placeholder={placeholder}
      noOptionsMessage={() => (
        <FormattedMessage id="app.filter.select.noResultsText" />
      )}
      isDisabled={disabled}
      isSearchable={isSearchable}
      wrapper="div"
      controlShouldRenderValue
      label={label}
    />
  )
}

SelectFilter.propTypes = {
  filterOptions: PropTypes.array,
  filterKey: PropTypes.string.isRequired,
  loadFilterOptions: PropTypes.func,
  placeholder: PropTypes.node,
  disabled: PropTypes.bool.isRequired,
  async: PropTypes.bool,
  label: PropTypes.node,
  resetFilters: PropTypes.arrayOf(PropTypes.string),
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
}

SelectFilter.defaultProps = {
  async: false,
  disabled: false,
  isSearchable: true,
  isClearable: true,
}
