import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Visibility from "visibilityjs"
import { usePrevious } from "react-hanger"
import favicon from "../../assets/img/favicon.ico"
import faviconWithMessage from "../../assets/img/favicon-with-badge.ico"
import { useEvent } from "../../hooks/use-event"
import { useStore } from "../../lib/store"
import { refreshManager } from "../../controllers/manager-controller"

const defaultTitle =
  "MoBerries - hire the best talent. Candidates matched to your needs."

export const Head = () => {
  const manager = useStore(state => state.manager)
  const unreadCount = manager?.unreadCount ?? 0

  useEvent("NEW_MESSAGE", () => {
    // TODO: Use map for events
    refreshManager()
  })

  const [isDocumentVisible, setIsDocumentVisible] = useState(
    !Visibility.hidden(),
  )
  const [isNotificationVisible, setIsNotificationVisisble] = useState(false)
  const [tick, setTick] = useState(0)

  const prevUnreadCount = usePrevious(unreadCount)
  const prevIsDocumentVisible = usePrevious(isDocumentVisible)

  useEffect(() => {
    if (unreadCount > prevUnreadCount && !isDocumentVisible) {
      setIsNotificationVisisble(true)
    }
  }, [unreadCount, prevUnreadCount, isDocumentVisible])

  useEffect(() => {
    if (!prevIsDocumentVisible && isDocumentVisible) {
      setIsNotificationVisisble(false)
    }
  }, [isDocumentVisible, prevIsDocumentVisible])

  useEffect(() => {
    Visibility.every(999999, 2000, () => {
      setTick(tick => tick + 1)
    })
    Visibility.change((e, state) => {
      setIsDocumentVisible(state === "visible")
    })
  }, [])

  const getTitle = () => {
    if (tick % 2 === 0) {
      return `(${unreadCount}) ${defaultTitle}`
    } else {
      return "New message"
    }
  }

  return (
    <Helmet defer={false}>
      <title>{isNotificationVisible ? getTitle() : defaultTitle}</title>
      <link
        rel="shortcut icon"
        href={isNotificationVisible ? faviconWithMessage : favicon}
      />
    </Helmet>
  )
}
