import { useState, useCallback } from "react"
import debounce from "debounce-promise"
import { api as atsIntegrationsApi } from "../../lib/ats-api"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../constants"
export const useAtsJobsLoader = ({
  atsCompanyIntegrationId,
  excludeImported,
  moberriesJobId,
}) => {
  const [jobs, setJobs] = useState([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadJobs = useCallback(
    debounce(async search => {
      if (!search || !search.length) {
        return []
      }
      const { data: openJobs } =
        await atsIntegrationsApi.getAtsCompanyIntegrationOpenJobList({
          atsCompanyIntegrationId,
          params: { _q: search, excludeImported },
        })

      setJobs(openJobs)

      return openJobs
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [atsCompanyIntegrationId, moberriesJobId, excludeImported],
  )

  return {
    jobs,
    loadJobs,
  }
}
