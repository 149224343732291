import React, { Fragment } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { UncontrolledPopover } from "reactstrap"
import { Badge } from "./badge-component"
import styles from "./card-status-tag.module.scss"

export const CardStatusTag = ({
  id,
  text,
  popover,
  color,
  icon,
  background,
}) => {
  return (
    <Fragment>
      <span id={`card-status-tag-popover-${id}`}>
        <Badge
          className={styles.badge}
          style={{ color: color, background: background }}
        >
          {icon && <i className={`fas ${icon}`} />}
          <span className={cn({ "d-none d-md-inline": icon })}>{text}</span>
        </Badge>
      </span>
      {popover && (
        <UncontrolledPopover
          placement="top"
          target={`card-status-tag-popover-${id}`}
          trigger="hover"
        >
          {popover}
        </UncontrolledPopover>
      )}
    </Fragment>
  )
}

CardStatusTag.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  text: PropTypes.node.isRequired,
  popover: PropTypes.node,
  icon: PropTypes.string,
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
}

CardStatusTag.defaultProps = {
  background: "#969cac",
}
