import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { NavLink as RLink } from "react-router-dom"
import { Container, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import { SmallSingleColumnLayout } from "./small-single-column-layout"
import { SmallInnerFormLayout } from "./small-inner-form-layout"

export const AccountPageLayout = props => {
  return (
    <Fragment>
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.page.account.layout.header" />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <Nav tabs justified className="settings-tabs">
          <NavItem>
            <NavLink
              tag={RLink}
              to="/account"
              exact
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.account.layout.tabs.personal" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              to="/account/credentials"
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.account.layout.tabs.credentials" />
            </NavLink>
          </NavItem>
        </Nav>
        <div className="bg-white px-3 py-5">
          <SmallInnerFormLayout>
            <TabContent>{props.children}</TabContent>
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </Fragment>
  )
}

AccountPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
