import React, { useState } from "react"
import { FormattedMessage } from "react-intl"

import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { ApiKeys, api as moberriesApi } from "../../../lib/moberries-api"
import { HorizontalBarsV2 } from "../../../components/charts/lib/horizontal-bars"
import { AnalyticsChartLayout } from "../../layouts/analytics/analytics-chart-layout/analytics-chart-layout"
import { careerLevelSelections } from "../lib/helpers"
import { ValuePicker } from "../lib/value-picker/value-picker"
import { ChartLegend } from "../chart-legend/chart-legend"
import { ChartSkeletonState } from "../chart-skeleton-state/chart-skeleton-state"
import { ChartEmptyStateV2 } from "../chart-empty-state/chart-empty-state"
import { levelMapper, empty, mapper } from "./helpers"

import emptyIcon from "../../../assets/img/illustration-search.svg"
import styles from "./job-category-career-level.module.scss"

export const JobCategoryCareerLevelChart = () => {
  const availableSelections = levelMapper(careerLevelSelections)
  const [selection, setSelection] = useState(availableSelections[1]) // default selection: Intermediate
  const { data, isLoading } = useAxiosQuery(
    [
      ApiKeys.SalaryRangeStats,
      {
        level: selection.value,
      },
    ],
    moberriesApi.getSalaryRangeStats,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  const isEmpty = empty(data)
  const chartProps = !isEmpty ? mapper(data) : {}

  if (isLoading) {
    return (
      <ChartSkeletonState
        title={
          <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobCategoryCareerLevel.header" />
        }
      />
    )
  }

  if (isEmpty && !isLoading) {
    return (
      <ChartEmptyStateV2
        title={
          <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobCategoryCareerLevel.header" />
        }
        info={
          <FormattedMessage
            id="app.page.analytics.strategyBuilder.charts.jobCategoryCareerLevel.empty"
            values={{
              level: <FormattedMessage id={selection.label} />,
            }}
          />
        }
        img={emptyIcon}
      >
        <div className={styles.head}>
          <ValuePicker
            availableValues={availableSelections}
            selectedValue={selection}
            setSelected={setSelection}
          />
        </div>
      </ChartEmptyStateV2>
    )
  }

  return (
    <AnalyticsChartLayout
      title={
        <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobCategoryCareerLevel.header" />
      }
    >
      <div className={styles.head}>
        <ValuePicker
          availableValues={availableSelections}
          selectedValue={selection}
          setSelected={setSelection}
        />
      </div>
      <HorizontalBarsV2 {...chartProps} />
      <div className={styles.bottom}>
        <div className={styles.legends}>
          <ChartLegend
            title="app.page.analytics.strategyBuilder.charts.jobCategoryCareerLevel.legends.typicalSalaryRange"
            color="#0738DA"
          />
        </div>
      </div>
    </AnalyticsChartLayout>
  )
}
