import React, { useRef } from "react"
import { propOr } from "ramda"
import { TabPane } from "reactstrap"
import { Form, Formik } from "formik"
import { AtsIntegrationSettingsLayout } from "../components/layouts/ats-integration-settings-layout"
import { Button } from "../components/common/button-component"
import { FormattedMessage } from "react-intl"
import { Redirect, useHistory } from "react-router"
import { useOpenClose } from "../hooks/use-open-close"
import { AtsAutoSyncWarningModal } from "../components/modals/ats-auto-sync-warning-modal"
import {
  ATS_APPLICATION_SYNC_OPTIONS,
  ATS_JOB_SYNC_OPTIONS,
} from "../constants"
import { atsApi } from "../lib/ats-api"
import { useStore } from "../lib/store"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { showErrorAlert } from "../lib/alerts"
import { AtsOptionsFields } from "../components/ats-company-settings/ats-options-fields"
import { refreshAtsConnection } from "../controllers/ats-controller"

export const AtsIntegrationSettingsPage = () => {
  const {
    value: isWarningModalOpen,
    close: closeWarningModal,
    open: openWarningModal,
  } = useOpenClose({ initialValue: false })

  const atsSettingsFormRef = useRef()
  const history = useHistory()

  const isConnectionFlowInProgress = useStore(
    state => state.isAtsConnectionFlowInProgress,
  )

  const nextStepUrl = "/integrations/ats/merge"

  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const provider = useStore(state => state.connectedProvider)

  const {
    mutateAsync: updateCompanyIntegration,
    isLoading: updatingCompanySettings,
  } = useAxiosMutation(atsApi.updateAtsCompanyIntegration, {
    onSuccess: async () => {
      await refreshAtsConnection()
      closeWarningModal()
      history.push(nextStepUrl)
    },
    onError: err => {
      showErrorAlert({ err })
    },
  })

  const submitFirstStep = async values => {
    const isJobSyncAuto = values.jobSyncType === ATS_JOB_SYNC_OPTIONS.AUTO
    const isMatchExportAuto =
      values.matchExportType === ATS_APPLICATION_SYNC_OPTIONS.ON_CAN_ACCEPT

    useStore.setState({
      atsSettingsFormValues: {
        jobSyncType: values.jobSyncType,
        matchExportType: values.matchExportType,
      },
    })

    if (isJobSyncAuto || isMatchExportAuto) {
      openWarningModal()
    } else {
      await updateCompanyIntegration({
        ...values,
        id: atsCompanyIntegration.id,
      })
    }
  }

  const submitWarningModal = async values => {
    const isJobSyncAuto = values.jobSyncType === ATS_JOB_SYNC_OPTIONS.AUTO
    useStore.setState({
      atsSettingsFormValues: {
        jobSyncType: values.jobSyncType,
        matchExportType: values.matchExportType,
      },
    })
    if (isJobSyncAuto) {
      history.push(nextStepUrl)
    } else {
      await updateCompanyIntegration({
        ...values,
        id: atsCompanyIntegration.id,
      })
    }
  }

  if (
    !atsCompanyIntegration ||
    atsCompanyIntegration?.provider !== provider?.slug
  ) {
    return <Redirect to="/integrations/ats" />
  }

  return (
    <Formik
      onSubmit={submitFirstStep}
      noValidate
      initialValues={atsCompanyIntegration}
      innerRef={atsSettingsFormRef}
      enableReinitialize={true}
    >
      {({ values, dirty }) => (
        <Form>
          <AtsIntegrationSettingsLayout
            provider={provider}
            isProviderConnected={
              propOr("", "provider", atsCompanyIntegration) === provider.slug
            }
          >
            <TabPane className="bg-white rounded-bottom">
              <AtsOptionsFields />

              <div className="p-3 d-flex justify-content-end">
                <AtsAutoSyncWarningModal
                  onConfirm={() => submitWarningModal(values)}
                  isOpen={isWarningModalOpen}
                  onClose={closeWarningModal}
                  providerSlug={provider.slug}
                  isJobSyncAuto={
                    values.jobSyncType === ATS_JOB_SYNC_OPTIONS.AUTO
                  }
                  isMatchExportAuto={
                    values.matchExportType ===
                    ATS_APPLICATION_SYNC_OPTIONS.ON_CAN_ACCEPT
                  }
                  isUpdatingIntegration={updatingCompanySettings}
                />
                <Button
                  type="submit"
                  color="primary"
                  disabled={
                    updatingCompanySettings ||
                    (!dirty && !isConnectionFlowInProgress)
                  }
                  loading={updatingCompanySettings}
                >
                  {isConnectionFlowInProgress ? (
                    <FormattedMessage id="app.common.next" />
                  ) : (
                    <FormattedMessage id="app.common.save" />
                  )}
                </Button>
              </div>
            </TabPane>
          </AtsIntegrationSettingsLayout>
        </Form>
      )}
    </Formik>
  )
}
