const configs = {
  callbackLabel: function (value) {
    return `${value.dataset.label}: ${value.raw[0] / 1000}K - ${
      value.raw[1] / 1000
    }K`
  },
  callbackX: function (value) {
    return `${value / 1000}K`
  },
}

const isAllZero = f => f?.value[0] === 0 && f?.value[1] === 0
const isDataExists = f => f?.value[0] !== 0 && f?.value[1] !== 0

export const levelMapper = f => f.filter(l => l.value > 0 && l.value < 5) // exclude "Student" and "Executive" career levels

export const format = e =>
  e
    .map(f => ({
      name: f.name,
      value: [f.salaryRange.salaryMin ?? 0, f.salaryRange.salaryMax ?? 0],
    }))
    .filter(isDataExists)

export const empty = (data = []) => format(data).every(isAllZero)

export const mapper = (data = []) => ({
  configs: configs,
  labels: format(data).map(f => f.name),
  datasets: [
    {
      label: "Typical Salary Range",
      backgroundColor: "#0738DA",
      data: format(data).map(f => f.value),
    },
  ],
})
