import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { IntlProvider as ReactIntlProvider } from "react-intl"
import { useStore } from "../../lib/store"
import { setLocale } from "../../controllers/user-controller"

export const IntlProvider = ({ children }) => {
  const manager = useStore(state => state.manager)
  const managerLocale = manager?.settings?.locale
  const [messages, setMessages] = useState(null)
  const locale = useStore(state => state.locale)

  useEffect(() => {
    if (managerLocale) {
      setLocale(managerLocale)
    }
  }, [managerLocale])

  useEffect(() => {
    import(`../../locales/${locale}.json`).then(setMessages)
  }, [locale])

  return (
    <ReactIntlProvider locale={locale} messages={messages} defaultLocale="en">
      {messages ? children : null}
    </ReactIntlProvider>
  )
}

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
