import React, { Fragment, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import { MESSAGE_TEMPLATE_TAGS } from "../../constants"
import { Select } from "../common/select-component"
import { useDispatch, useSelector } from "react-redux"
import { getMessageTemplateList } from "../../actions/message-template-actions"
import {
  messageTemplatesCountSelector,
  messageTemplatesSelector,
} from "../../selectors/message-template-selector"
import { arrayToMultiWordRegex } from "../../lib/helpers"
import { Button } from "../common/button-component"
import { EditMessageTemplateModal } from "../modals/edit-message-template-modal"
import { useOpenClose } from "../../hooks/use-open-close"
import { usePrevious } from "react-hanger"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

export const MessageTemplateSelect = ({
  setMessage,
  currentMessage,
  defaultTemplate,
  selectedTemplate,
  setSelectedTemplate,
  candidateFirstName,
  candidateLastName,
  jobTitle,
  companyCity,
  companyName,
  managerName,
  managerLastName,
}) => {
  const [selectedTemplateOnConfirmation, setSelectedTemplateOnConfirmation] =
    useState(null)

  const {
    value: isEditTemplateModalOpen,
    close: closeEditTemplateModal,
    open: openEditTemplateModal,
  } = useOpenClose({
    initialValue: false,
  })

  const mapTemplateToMessage = {
    [MESSAGE_TEMPLATE_TAGS.CANDIDATE_NAME]: candidateFirstName,
    [MESSAGE_TEMPLATE_TAGS.CANDIDATE_LAST_NAME]: candidateLastName,
    [MESSAGE_TEMPLATE_TAGS.JOB_TITLE]: jobTitle,
    [MESSAGE_TEMPLATE_TAGS.COMPANY_CITY]: companyCity,
    [MESSAGE_TEMPLATE_TAGS.COMPANY_NAME]: companyName,
    [MESSAGE_TEMPLATE_TAGS.MANAGER_NAME]: managerName,
    [MESSAGE_TEMPLATE_TAGS.MANAGER_LAST_NAME]: managerLastName,
  }
  const processedTemplateText = selectedTemplate?.text?.replace(
    arrayToMultiWordRegex(Object.values(MESSAGE_TEMPLATE_TAGS)),
    tag => mapTemplateToMessage[tag],
  )
  const prevProcessedTemplate = usePrevious(processedTemplateText)
  useEffect(() => {
    if (
      processedTemplateText &&
      processedTemplateText !== prevProcessedTemplate
    ) {
      setMessage(processedTemplateText)
    }
  }, [processedTemplateText, prevProcessedTemplate, setMessage])

  const isPristine = processedTemplateText === currentMessage

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getMessageTemplateList())
  }, [dispatch])
  const messageTemplates = useSelector(messageTemplatesSelector)
  const messageTemplatesCount = useSelector(messageTemplatesCountSelector)
  const areAllValuesAvailable = Boolean(
    candidateFirstName &&
      candidateLastName &&
      jobTitle &&
      companyCity &&
      companyName &&
      managerName &&
      managerLastName,
  )
  const defaultTemplateText = defaultTemplate?.text
  const defaultTemplateName = defaultTemplate?.name
  useEffect(() => {
    if (
      messageTemplatesCount === 0 &&
      defaultTemplateText &&
      !currentMessage &&
      areAllValuesAvailable
    ) {
      setSelectedTemplate({
        name: defaultTemplateName,
        text: defaultTemplateText,
      })
    }
  }, [
    messageTemplatesCount,
    setSelectedTemplate,
    defaultTemplateText,
    defaultTemplateName,
    currentMessage,
    areAllValuesAvailable,
  ])

  return (
    <Fragment>
      {isEditTemplateModalOpen && (
        <EditMessageTemplateModal
          isOpen={isEditTemplateModalOpen}
          close={closeEditTemplateModal}
          toggle={closeEditTemplateModal}
          initialValues={selectedTemplate}
          onSave={isPristine && setSelectedTemplate}
        />
      )}
      <Modal
        isOpen={Boolean(selectedTemplateOnConfirmation)}
        toggle={() => setSelectedTemplateOnConfirmation(null)}
        centered
      >
        <ModalHeader toggle={() => setSelectedTemplateOnConfirmation(null)}>
          <FormattedMessage id="app.messageTemplate.select.confirm.title" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id="app.messageTemplate.select.confirm.text" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={() => setSelectedTemplateOnConfirmation(null)}
          >
            <FormattedMessage id="app.common.cancel" />
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setSelectedTemplate(selectedTemplateOnConfirmation)
              setSelectedTemplateOnConfirmation(null)
            }}
          >
            <FormattedMessage id="app.common.confirm" />
          </Button>
        </ModalFooter>
      </Modal>

      <div className="p-2 d-flex border-top">
        <div className="align-self-center text-center mr-2 d-none d-sm-inline">
          <FormattedMessage id="app.messageTemplate.select.label" />
        </div>
        <Select
          input={{
            onChange: selectedTemplate =>
              currentMessage
                ? setSelectedTemplateOnConfirmation(selectedTemplate)
                : setSelectedTemplate(selectedTemplate),
            value: selectedTemplate || {
              name: (
                <FormattedMessage id="app.messageTemplate.select.placeholder" />
              ),
            },
          }}
          meta={{}}
          options={messageTemplates}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          withCaret
          isDisabled={!areAllValuesAvailable}
          wrapper={Fragment}
          maxMenuHeight={150}
          className="flex-grow-1"
        />

        <Button
          className="ml-2 border-dark bg-white text-dark"
          onClick={openEditTemplateModal}
          disabled={!selectedTemplate}
        >
          <i className="fas fa-pen" />
          <span className="d-none d-md-inline ml-1">
            <FormattedMessage id="app.common.edit" />
          </span>
        </Button>
      </div>
    </Fragment>
  )
}

MessageTemplateSelect.propTypes = {
  setMessage: PropTypes.func.isRequired,
  defaultTemplate: PropTypes.objectOf(PropTypes.string),
  currentMessage: PropTypes.string,
  selectedTemplate: PropTypes.object,
  setSelectedTemplate: PropTypes.func,
  candidateFirstName: PropTypes.string,
  candidateLastName: PropTypes.string,
  jobTitle: PropTypes.string,
  companyCity: PropTypes.string,
  companyName: PropTypes.string,
  managerName: PropTypes.string.isRequired,
  managerLastName: PropTypes.string.isRequired,
}
