import {
  getFirstDateOfPreviousMonth,
  getFirstDayOfPrevMonths,
  getFirstDayOfThisMonth,
  getLastDateOfPreviousMonth,
  getNow,
} from "../../../lib/helpers/date"

export const timeRangeMap = [
  {
    label: "app.page.analytics.charts.values.currentMonth",
    value: {
      start: getFirstDayOfThisMonth(),
      end: getNow(),
    },
  },
  {
    label: "app.page.analytics.charts.values.lastMonth",
    value: {
      start: getFirstDateOfPreviousMonth(),
      end: getLastDateOfPreviousMonth(),
    },
  },
  {
    label: "app.page.analytics.charts.values.lastQuarter",
    value: {
      end: getLastDateOfPreviousMonth(),
      start: getFirstDayOfPrevMonths(3),
    },
  },
  {
    label: "app.page.analytics.charts.values.last6Months",
    value: {
      end: getLastDateOfPreviousMonth(),
      start: getFirstDayOfPrevMonths(6),
    },
  },
]

// TODO: future-improvements: Mutation: mutate ValuePicker component and use CAREER_LEVELS (from defalut-option.js) as below careerLevelSelections values
export const careerLevelSelections = [
  { value: 0, label: "app.common.career.level.student" },
  { value: 1, label: "app.common.career.level.entry" },
  { value: 2, label: "app.common.career.level.intermediate" },
  { value: 3, label: "app.common.career.level.senior" },
  { value: 4, label: "app.common.career.level.lead" },
  { value: 5, label: "app.common.career.level.executive" },
]

export const categoryMapper = (data = []) =>
  data.map(f => ({ value: f.id, label: f.name, roles: f.roles }))
