import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { flatten, length, not, values, find, keys } from "ramda"

import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { VerticalBar } from "../lib/vertical-bar"
import { ValuePicker } from "../lib/value-picker/value-picker"
import { ChartLegend } from "../chart-legend/chart-legend"
import { ChartEmptyState } from "../chart-empty-state/chart-empty-state"
import {
  availableSelections,
  selectionsConfig,
  mapper,
  formatData,
} from "./helpers"
import { FeedbackInsightsChartSkeleton } from "./feedback-chart-skeleton/feedback-chart-skeleton"

import emptyIcon from "../../../assets/img/illustration-old-speaker.svg"
import styles from "./feedback-chart.module.scss"

export function FeedbackInsightsChart() {
  const { formatMessage } = useIntl()
  const [selection, setSelection] = useState(availableSelections[0])

  const { data, isLoading } = useAxiosQuery(
    ApiKeys.FeedbackSummary,
    moberriesApi.getFeedbackSummary,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const formattedData = formatData(data)
  const isEmptyState = not(length(flatten(values(formattedData))))

  const initialSelectionName =
    find(key => length(formattedData[key]), keys(formattedData)) || "jobReasons"

  useEffect(() => {
    const initialSelection = find(
      selection => selection.value === initialSelectionName,
      availableSelections,
    )
    setSelection(initialSelection)
  }, [initialSelectionName])

  const dataAndLabels = mapper(formattedData, selection, formatMessage)

  if (isLoading) return <FeedbackInsightsChartSkeleton />

  if (isEmptyState && !isLoading) {
    return (
      <ChartEmptyState
        title="app.page.analytics.charts.feedback.name"
        info="app.page.analytics.charts.common.empty.text"
        img={emptyIcon}
      />
    )
  }

  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="app.page.analytics.charts.feedback.name" />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.head}>
            <ChartLegend
              title={selectionsConfig[selection.value].legendName}
              color={selectionsConfig[selection.value].color}
            />
            <ValuePicker
              availableValues={availableSelections}
              selectedValue={selection}
              setSelected={setSelection}
            />
          </div>
          <VerticalBar
            {...dataAndLabels}
            options={{
              backgroundColor: selectionsConfig[selection.value].color,
            }}
          />
        </div>
        <div className={styles.bottom}>
          <Link to="/candidates-feedback">
            <FormattedMessage id="app.page.analytics.charts.feedback.text.seeMore" />
          </Link>
        </div>
      </div>
    </div>
  )
}
