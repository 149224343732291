import { getActionType, arrayToObject } from "../lib/helpers"
import {
  GET,
  SUCCESS,
  MESSAGE,
  LIST,
  FAIL,
  LOGOUT,
  START,
  CREATE,
} from "../constants"
import {
  always,
  evolve,
  F,
  T,
  mergeLeft,
  over,
  lensProp,
  inc,
  append,
  union,
} from "ramda"

export const defaultState = {
  countByThread: {},
  messagesByThread: {},
  entities: {},
  creating: false,
  creatingErr: null,
  err: null,
  lastFetchedPageByThread: {},
  loadingPage: null,
  hasQueuedMessageFetch: false,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, MESSAGE, LIST, START):
      return evolve({ loadingPage: always(payload.page) }, state)

    case getActionType(GET, MESSAGE, LIST, SUCCESS):
      return evolve(
        {
          loadingPage: always(null),
          lastFetchedPageByThread: over(
            lensProp(payload.threadId),
            always(payload.page),
          ),
          err: always(null),
          messagesByThread: over(
            lensProp(payload.threadId),
            payload.page === 1
              ? always(payload.messages.map(m => m.id))
              : union(payload.messages.map(m => m.id)),
          ),
          countByThread: over(
            lensProp(payload.threadId),
            always(payload.count),
          ),
          entities: mergeLeft(arrayToObject(payload.messages, "id")),
        },
        state,
      )

    case getActionType(GET, MESSAGE, LIST, FAIL):
      return evolve(
        {
          loadingPage: always(null),
          err: always(payload.err),
          lastFetchedPageByThread: over(
            lensProp(payload.threadId),
            always(null),
          ),
        },
        state,
      )

    case getActionType(CREATE, MESSAGE, START):
      return evolve({ creating: T }, state)

    case getActionType(CREATE, MESSAGE, SUCCESS):
      return evolve(
        {
          creating: F,
          creatingErr: always(null),
          messagesByThread: evolve({
            [payload.threadId]: append(payload.message.id),
          }),
          countByThread: evolve({ [payload.threadId]: inc }),
          entities: over(
            lensProp(payload.message.id),
            mergeLeft(payload.message),
          ),
        },
        state,
      )

    case getActionType(CREATE, MESSAGE, FAIL):
      return evolve({ creating: F, creatingErr: always(payload.err) }, state)

    case getActionType(GET, MESSAGE, SUCCESS):
      return evolve(
        {
          entities: over(
            lensProp(payload.message.id),
            mergeLeft(payload.message),
          ),
        },
        state,
      )

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
