import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"

export const SingleColumnLayout = props => {
  return (
    <Container className="container-dashboard">
      <Row>
        <Col xl={{ size: 10, offset: 1 }} md="12">
          {props.children}
        </Col>
      </Row>
    </Container>
  )
}

SingleColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
