import React from "react"
import PropTypes from "prop-types"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"
import { clamp, map } from "ramda"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormGroup, Label, PopoverBody } from "reactstrap"
import { InfoHint } from "../common/info-hint-component"
import { FormattedMessage } from "react-intl"

export const RangeField = props => {
  const [field, , actions] = useFormikField(props)
  return <InputRange {...field} {...props} onChange={actions.setValue} />
}

export const LabeledRangeField = ({ label, hint, id, isRequired, ...rest }) => {
  const [field, , actions] = useFormikField(rest)
  return (
    <FormGroup>
      <Label for={id}>
        {label}
        {isRequired && " *"}
        <InfoHint
          id={`${id}-hint`}
          popover={
            <PopoverBody>
              <FormattedMessage id="app.job.form.salary.hint" />
            </PopoverBody>
          }
        />
      </Label>
      <div className="py-3 px-2">
        <InputRange
          id={id}
          {...field}
          {...rest}
          onChange={newValue => {
            actions.setValue(map(clamp(rest.minValue, rest.maxValue), newValue))
          }}
        />
      </div>
    </FormGroup>
  )
}

LabeledRangeField.propTypes = {
  label: PropTypes.node.isRequired,
  hint: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isRequired: PropTypes.bool,
}

LabeledRangeField.defaultProps = {
  isRequired: false,
}
