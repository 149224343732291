import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormFeedback, FormGroup, Input, Label, PopoverBody } from "reactstrap"
import { nanoid } from "nanoid"
import { Element as ScrollElement } from "react-scroll"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"
import { MultiSelect } from "../common/multi-select-new"
import { InfoHint } from "../common/info-hint-component"

export const LabeledMultiSelectField = ({
  label,
  hint,
  id,
  isRequired,
  ...rest
}) => {
  const [field, meta, actions] = useFormikField(rest)
  const [hintId] = useState(nanoid())
  return (
    <FormGroup>
      <ScrollElement name={`form-position-${rest.name}`} />
      <Label for={id}>
        {label}
        {isRequired && " *"}
        {hint && (
          <InfoHint
            color="primary"
            id={hintId}
            popover={<PopoverBody>{hint}</PopoverBody>}
          />
        )}
      </Label>
      <MultiSelect
        id={id}
        {...rest}
        {...field}
        invalid={meta.touched && Boolean(meta.error)}
        onChange={value => {
          actions.setTouched()
          actions.setValue(value)
        }}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

LabeledMultiSelectField.propTypes = {
  label: PropTypes.node.isRequired,
  hint: PropTypes.node,

  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isRequired: PropTypes.bool,
}

LabeledMultiSelectField.defaultProps = {
  hint: null,
  isRequired: false,
}
