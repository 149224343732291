import React from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import cn from "classnames"
import PropTypes from "prop-types"
import {
  Input as BInput,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardDeck,
  CardLink,
} from "reactstrap"
import { Button } from "../common/button-component"
import { FormattedLink } from "../common/formatted-link-component"
import { JOB_CONDITION_TYPES } from "../../constants"
import {
  getTotalFlatSlots,
  getCurrentAvailableFlatSlots,
  isComSubscriptionCanceled,
} from "../../lib/helpers"

export function JobConditionSelect({ input, companySubscription }) {
  const totalFlatSlots = getTotalFlatSlots(companySubscription)
  const flatSlotsAvailable = getCurrentAvailableFlatSlots(companySubscription)
  return (
    <FormGroup>
      <CardDeck>
        <Card
          tag="label"
          htmlFor={`${input.name}-job-condition-pph`}
          className={cn("clickable", {
            "border-primary": input.value.type === JOB_CONDITION_TYPES.pph,
          })}
        >
          <CardBody className="p-3">
            <CardTitle className="mb-3">
              <FormattedMessage id="app.job.form.posting.job.unlimited" />
            </CardTitle>
            <FormGroup check>
              <BInput
                type="radio"
                id={`${input.name}-job-condition-pph`}
                name="pph"
                onChange={() => {
                  input.onChange({ type: JOB_CONDITION_TYPES.pph })
                }}
                checked={input.value.type === JOB_CONDITION_TYPES.pph}
              />
              <strong className="h5">
                <FormattedMessage id="app.job.form.create.pph" />
              </strong>
            </FormGroup>
          </CardBody>
          <div className="mt-auto px-3 pb-3">
            <FormattedLink
              className="d-inline-block py-1"
              href="payHire"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="app.job.form.posting.job.readMore" />
            </FormattedLink>
          </div>
        </Card>

        <Card
          className={cn("clickable", {
            "border-primary": input.value.type === JOB_CONDITION_TYPES.flat,
          })}
          tag="label"
          htmlFor={`${input.name}-job-condition-flat`}
        >
          <CardBody className="p-3">
            <CardTitle
              className={cn({
                "mb-3": true,
                "text-muted": flatSlotsAvailable <= 0,
              })}
            >
              <FormattedMessage
                id="app.job.form.create.slotsAvailable"
                values={{
                  availableSlots: isComSubscriptionCanceled(companySubscription)
                    ? 0
                    : flatSlotsAvailable,
                  totalSlots: totalFlatSlots,
                }}
              />
            </CardTitle>
            <FormGroup check>
              <BInput
                disabled={
                  flatSlotsAvailable <= 0 ||
                  isComSubscriptionCanceled(companySubscription)
                }
                onChange={() =>
                  input.onChange({ type: JOB_CONDITION_TYPES.flat })
                }
                type="radio"
                name="flat"
                id={`${input.name}-job-condition-flat`}
                checked={input.value.type === JOB_CONDITION_TYPES.flat}
              />
              <strong
                className={cn({
                  h5: true,
                  "text-muted": flatSlotsAvailable <= 0,
                })}
              >
                <FormattedMessage id="app.job.form.create.flatSubscription" />
              </strong>
            </FormGroup>
          </CardBody>
          <div className="d-flex px-3 pb-3 justify-content-between align-items-center">
            <div className="pr-md-2">
              <FormattedLink
                className="card-link"
                href="pricing"
                as={CardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  className="card-link"
                  id="app.job.form.posting.job.readMore"
                />
              </FormattedLink>
            </div>
            <div>
              {flatSlotsAvailable <= 0 && (
                <Button
                  size="sm"
                  color="primary"
                  className="text-nowrap"
                  tag={Link}
                  to="/subscribe"
                >
                  <FormattedMessage id="app.common.upgrade.now" />
                </Button>
              )}
            </div>
          </div>
        </Card>
      </CardDeck>
    </FormGroup>
  )
}

JobConditionSelect.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.shape({ type: PropTypes.string }),
    name: PropTypes.string.isRequired,
  }),
  companySubscription: PropTypes.object,
}
