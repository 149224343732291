import React from "react"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import { IntegrationModal } from "../modals/integration-modal"
import { useOpenClose } from "../../hooks/use-open-close"

export const WebsiteIntegrationWidget = ({ jobId }) => {
  const { open, close, value: isOpen } = useOpenClose({ initialValue: false })
  return (
    <div className="bg-white mb-4 p-3 rounded">
      <div className="mb-3">
        <FormattedMessage id="app.page.jobDetails.integration.text" />
      </div>
      <Button color="secondary" block onClick={open}>
        <i className="fas fa-plus pr-2" />
        <FormattedMessage id="app.common.integrate" />
      </Button>
      <IntegrationModal close={close} isOpen={isOpen} jobId={jobId} />
    </div>
  )
}

WebsiteIntegrationWidget.propTypes = {
  jobId: PropTypes.number.isRequired,
}
