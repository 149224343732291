import * as typeformEmbed from "@typeform/embed"
import React, { useEffect } from "react"
import { addWeeks } from "date-fns"
import { connect } from "react-redux"
import { config } from "../../config"
import PropTypes from "prop-types"
import { getSurveyStatus, closeSurvey } from "../../actions/survey-actions"
import {
  completedSelector,
  isClosedSelector,
  errorSelector,
} from "../../selectors/survey-selector"
import { createSurveyLink, isLoggedInSelector } from "../../lib/helpers"
import { Button } from "reactstrap"
import { range } from "ramda"
import { FormattedMessage } from "react-intl"
import { useStore } from "../../lib/store"

const TypeFormWidgetComponent = ({
  getSurveyStatus,
  isCompleted,
  closeSurvey,
  isClosed,
  err,
}) => {
  const { company, locale } = useStore(({ company, locale }) => ({
    company,
    locale,
  }))
  const isCompanyOlderThan4Weeks =
    Boolean(company) && addWeeks(new Date(company.createdAt), 4) < new Date()
  const isLoggedIn = useStore(isLoggedInSelector)
  useEffect(() => {
    if (!isClosed && isLoggedIn && isCompanyOlderThan4Weeks) {
      getSurveyStatus({ companyId: company.id })
    }
  }, [company, getSurveyStatus, isClosed, isLoggedIn, isCompanyOlderThan4Weeks])

  const handleOnWidgetClick = e =>
    typeformEmbed
      .makePopup(
        createSurveyLink({
          companyId: company.id,
          rating: e.target.value,
          code: config.typeform.codes[locale],
        }),
        {
          hideFooter: true,
          hideHeaders: true,
          opacity: 100,
          autoClose: 2,
          onSubmit: closeSurvey,
        },
      )
      .open()

  if (
    isCompleted ||
    isClosed ||
    err ||
    !isLoggedIn ||
    !isCompanyOlderThan4Weeks
  ) {
    return null
  }

  return (
    <div className="d-none d-sm-inline-flex flex-column bg-white typeform-widget rounded  p-1 shadow">
      <div className="d-flex flex-row justify-content-between border-bottom align-items-center m-2 pb-2">
        <span className="font-weight-bold">
          <FormattedMessage id="app.widget.survey.question" />
        </span>
        <i
          className="fas fa-times fa-lg text-muted clickable"
          onClick={closeSurvey}
        />
      </div>
      <div className="d-flex flex-row align-items-center m-2">
        <span className="text-muted mr-1 font-weight-bold">
          <FormattedMessage id="app.widget.survey.answer.notLikely" />
        </span>
        <div className="survey-buttons d-flex flex-row">
          {range(1, 11).map(n => (
            <div key={n} className="pr-1 survey-button-wrapper">
              <Button
                onClick={handleOnWidgetClick}
                color="light"
                value={n}
                className="survey-button font-weight-bold p-0"
              >
                {n}
              </Button>
            </div>
          ))}
        </div>
        <span className="text-muted font-weight-bold">
          <FormattedMessage id="app.widget.survey.answer.likely" />
        </span>
      </div>
    </div>
  )
}

TypeFormWidgetComponent.propTypes = {
  getSurveyStatus: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  closeSurvey: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
  err: PropTypes.object,
}

TypeFormWidgetComponent.defaultProps = {
  isCompleted: true,
  isClosed: true,
}

const mapStateToProps = function (state) {
  return {
    isCompleted: completedSelector(state),
    isClosed: isClosedSelector(state),
    err: errorSelector(state),
  }
}

const mapDispatchToProps = {
  getSurveyStatus,
  closeSurvey,
}

export const TypeFormWidget = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TypeFormWidgetComponent)
