import React from "react"
import PropTypes from "prop-types"
import { SmallSingleColumnLayout } from "./small-single-column-layout"
import { Nav, NavItem, NavLink } from "reactstrap"
import { NavLink as RLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { AtsIntegrationProviderCard } from "../integration/ats-integration-provider-card-component"
import { propOr } from "ramda"
import { CardLoader } from "../loaders/card-loader"
import { Button } from "../common/button-component"
import { FormattedLink } from "../common/formatted-link-component"

export const AtsIntegrationSettingsLayout = ({
  children,
  provider,
  isProviderConnected,
}) => {
  return (
    <SmallSingleColumnLayout>
      {provider ? (
        <AtsIntegrationProviderCard
          isConnected={isProviderConnected}
          provider={provider}
          className="mb-2"
          controls={
            <Button
              className="btn-pill"
              outline
              color="primary"
              tag={FormattedLink}
              href="atsIntegrationsGuide"
              target="_blank"
            >
              <i className="fas fa-book-open mr-md-1" />
              <span className="d-none d-md-inline">
                <FormattedMessage id="app.page.integrations.guide" />
              </span>
            </Button>
          }
        />
      ) : (
        <div className="bg-white mb-2 p-3">
          <CardLoader />
        </div>
      )}
      <Nav tabs justified className="settings-tabs">
        <NavItem>
          <NavLink
            tag={RLink}
            to={`/integrations/ats/${propOr("", "slug", provider)}/connect`}
            exact
            className="font-weight-bold px-0"
          >
            <FormattedMessage id="app.page.integrations.settings.tabs.connect" />
          </NavLink>
        </NavItem>
        {isProviderConnected && (
          <NavItem>
            <NavLink
              tag={RLink}
              to="/integrations/ats/options"
              exact
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.integrations.settings.tabs.options" />
            </NavLink>
          </NavItem>
        )}
      </Nav>
      {children}
    </SmallSingleColumnLayout>
  )
}

AtsIntegrationSettingsLayout.propTypes = {
  children: PropTypes.node,
  provider: PropTypes.object,
  isProviderConnected: PropTypes.bool,
}

AtsIntegrationSettingsLayout.defaultProps = {
  isProviderConnected: false,
}
