import { useLocation } from "react-router"
import { isQueryLinkActive, updateQueryParams } from "../lib/helpers"
import { push } from "connected-react-router"
import { useDispatch } from "react-redux"
import { identity, zipObj, times, always, length } from "ramda"
import qs from "qs"

export const useFilter = ({
  filterOptions,
  filterKey,
  filterName,
  getValue = identity,
  getTitle = identity,
  resetFilters = [],
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const resetFiltersObj = zipObj(
    resetFilters,
    times(always(null), length(resetFilters)),
  )

  const filters = filterOptions.map(v => {
    const value = v && getValue(v)
    return {
      value,
      title: getTitle(v),
      key: filterKey,
      isActive: isQueryLinkActive(filterKey, value),
      name: filterName,
      to: {
        pathname: location.pathname,
        search: updateQueryParams({
          search: location.search,
          params: { [filterKey]: value, ...resetFiltersObj },
        }),
      },
    }
  })

  const applyFilter = v => {
    dispatch(
      push(
        v?.to ?? {
          pathname: location.pathname,
          search: updateQueryParams({
            search: location.search,
            params: { [filterKey]: null },
          }),
        },
      ),
    )
  }

  const activeFilter =
    filters.find(v => params[v.key] === String(v.value)) ??
    filters.find(v => v.value === null) ?? //find default option
    null

  return {
    activeFilter,
    filters,
    applyFilter,
  }
}
