import React from "react"
import PropTypes from "prop-types"
import { CardLoader } from "../loaders/card-loader"
import { times } from "ramda"
import { TabPane } from "reactstrap"
import { CompanyPageLayout } from "../layouts/company-page-layout"

export function CompanyTabSkeleton({ isSuperManager, title }) {
  return (
    <CompanyPageLayout title={title} isSuperManager={isSuperManager}>
      <TabPane>
        <div className="bg-white">
          {times(
            i => (
              <div key={i} className="bg-white p-3 mb-2">
                <CardLoader />
              </div>
            ),
            4,
          )}
        </div>
      </TabPane>
    </CompanyPageLayout>
  )
}

CompanyTabSkeleton.propTypes = {
  isSuperManager: PropTypes.bool,
  title: PropTypes.node,
}
