import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Media } from "reactstrap"
import { Img } from "../common/img-component"
import { Button } from "../common/button-component"
import moreMatches from "../../assets/img/moreMatches.png"

export function EmptyBoost() {
  return (
    <Fragment>
      <div className="mb-2">
        <Media className="bg-white border-bottom p-3">
          <Media left>
            <Img
              src={moreMatches}
              alt="Icon"
              width="80"
              height="80"
              className="mr-3 d-none d-md-inline"
            />
          </Media>
          <Media body>
            <small>
              <FormattedMessage id="app.common.tip" />
            </small>
            <Media heading>
              <FormattedMessage id="app.page.dashboard.matches.empty.hint.header" />
            </Media>
            <p>
              <FormattedMessage
                id="app.page.dashboard.matches.empty.hint.boost.text"
                values={{
                  link: (
                    <a href="mailto:support@moberries.com">
                      support@moberries.com
                    </a>
                  ),
                }}
              />
            </p>
          </Media>
        </Media>
      </div>
      <div className="mb-2">
        <Media className="bg-white p-3 justify-content-end">
          <Button disabled>
            <FormattedMessage id="app.page.dashboard.matches.empty.button.wait" />
          </Button>
        </Media>
      </div>
    </Fragment>
  )
}
