import { useEffect, useState } from "react"
import { api as moApi } from "../lib/moberries-api"
import { prop } from "ramda"

export const useFeedbackChoices = feedbackSetType => {
  const [feedbackChoices, setFeedbackChoices] = useState([])
  useEffect(() => {
    const fetch = async () => {
      const res = await moApi.getFeedbackChoices({ type: feedbackSetType })
      setFeedbackChoices(res?.data?.results)
    }
    fetch()
  }, [feedbackSetType])
  return feedbackChoices.filter(prop("isActive"))
}
