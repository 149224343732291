import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import { SmallSingleColumnLayout } from "./small-single-column-layout"
import { NavLink as RLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"

export const IntegrationsPageLayout = ({ children }) => (
  <Fragment>
    <SmallSingleColumnLayout>
      <h1 className="mb-3 ">
        <FormattedMessage id="app.page.integrations.header" />
      </h1>

      <Nav tabs justified className="settings-tabs">
        <NavItem>
          <NavLink
            tag={RLink}
            to="/integrations/ats"
            exact
            className="font-weight-bold px-0"
          >
            <FormattedMessage id="app.page.integrations.tabs.ats" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RLink}
            to="/integrations/other"
            exact
            className="font-weight-bold px-0"
          >
            <FormattedMessage id="app.page.integrations.tabs.other" />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent>{children}</TabContent>
    </SmallSingleColumnLayout>
  </Fragment>
)

IntegrationsPageLayout.propTypes = {
  children: PropTypes.node,
}
