import React from "react"
import PropTypes from "prop-types"
import { DoubleColumnLayout } from "./double-column-layout"

const Content = ({ children }) => children

const Sidebar = ({ children }) => children

export const SubscriptionOrderLayout = ({ children }) => {
  return (
    <DoubleColumnLayout noGutters>
      <DoubleColumnLayout.Content>
        <div className="bg-white p-3 h-100">
          {React.Children.toArray(children).find(c => c.type === Content)}
        </div>
      </DoubleColumnLayout.Content>
      <DoubleColumnLayout.Sidebar>
        <div className="bg-light p-3 h-100 d-flex flex-column justify-content-between border-lg-left">
          {React.Children.toArray(children).find(c => c.type === Sidebar)}
        </div>
      </DoubleColumnLayout.Sidebar>
    </DoubleColumnLayout>
  )
}

SubscriptionOrderLayout.Content = Content
SubscriptionOrderLayout.Sidebar = Sidebar

SubscriptionOrderLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}
Content.propTypes = {
  children: PropTypes.node.isRequired,
}
