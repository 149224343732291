import { MESSAGE_TEMPLATE_TAGS } from "./constants"

export const JOB_TYPES = [
  { id: 1, name: "app.common.job.type.fulltime" },
  { id: 2, name: "app.common.job.type.parttime" },
  { id: 3, name: "app.common.job.type.contract" },
  { id: 4, name: "app.common.job.type.intership" },
  { id: 5, name: "app.common.job.type.temporary" },
]

export const JOB_LEVELS = [
  { id: 0, name: "app.common.job.experience.level.first" },
  { id: 1, name: "app.common.job.experience.level.second" },
  { id: 2, name: "app.common.job.experience.level.third" },
  { id: 3, name: "app.common.job.experience.level.fourth" },
]

export const LANG_LEVELS = [
  { id: 0, name: "app.common.language.level.beginner" },
  { id: 1, name: "app.common.language.level.elementary" },
  { id: 2, name: "app.common.language.level.intermediate" },
  { id: 3, name: "app.common.language.level.upperintermediate" },
  { id: 4, name: "app.common.language.level.advanced" },
  { id: 5, name: "app.common.language.level.mastery" },
  { id: 6, name: "app.common.language.level.native" },
]

export const CAREER_LEVELS = [
  { id: 0, name: "app.common.career.level.student" },
  { id: 1, name: "app.common.career.level.entry" },
  { id: 2, name: "app.common.career.level.intermediate" },
  { id: 3, name: "app.common.career.level.senior" },
  { id: 4, name: "app.common.career.level.lead" },
  { id: 5, name: "app.common.career.level.executive" },
]

export const CANDIDATE_AVAILABILITY = [
  { name: "app.common.availability.period.immediately", value: "immediately" },
  { name: "app.common.availability.period.oneMonth", value: "in_one_month" },
  { name: "app.common.availability.period.twoMonths", value: "in_two_months" },
  {
    name: "app.common.availability.period.threeMonths",
    value: "in_three_months",
  },
  {
    name: "app.common.availability.period.moreThenThreeMonths",
    value: "more_than_three_months",
  },
]

// JOB STATUSES:
export const JOB_STATUSES_TEXT_MAP = {
  ACT: "app.job.status.active",
  REV: "app.job.status.review",
  PAU: "app.job.status.paused",
  DEL: "app.job.status.deleted",
}

export const JOB_STATUSES_COLOR_MAP = {
  ACT: "success",
  REV: "warning",
  PAU: "primary",
  DEL: "danger",
}

export const JOB_CONDITION_TYPES_MAP = {
  pph: "app.job.type.pph",
  flat: "app.job.type.flat",
}

export const MANAGER_STATUSES_COLOR_MAP = {
  ACT: "success-light",
}

export const MANAGER_STATUSES_ICON_MAP = {
  ACT: "fa-check",
}

export const MANAGER_STATUSES_TEXT_MAP = {
  ACT: "app.job.status.active",
}

export const SUBSCRIPTION_STATUSES_TEXT_MAP = {
  openedPaid: "app.widget.subscription.active",
  openedPastDue: "app.widget.subscription.active",
  cancelingPaid: "app.widget.subscription.active",
  cancelingPastDue: "app.widget.subscription.active",

  expiredPaid: "app.widget.subscription.expired",
  incompleteExpired: "app.widget.subscription.expired",

  canceledPaid: "app.widget.subscription.canceled",
  canceledPastDue: "app.widget.subscription.canceled",

  paused: "app.widget.subscription.paused",
  incomplete: "app.widget.subscription.incomplete",
}

export const SUBSCRIPTION_TYPE_TEXT_MAP = {
  payHire: "app.widget.subscription.pph",
  main: "app.widget.subscription.main",
  basic: "app.widget.subscription.basic",
  pro: "app.widget.subscription.pro",
}

export const SUBSCRIPTION_STATUSES_COLOR_MAP = {
  openedPaid: "success",
  openedPastDue: "success",
  cancelingPaid: "success",
  cancelingPastDue: "success",

  expiredPaid: "warning",
  canceledPaid: "warning",
  canceledPastDue: "warning",
  incomplete: "warning",
  incompleteExpired: "warning",

  paused: "primary",
}

export const SUBSCRIPTION_STATUSES_ICON_MAP = {
  openedPaid: "fa-check",
  openedPastDue: "fa-check",
  cancelingPaid: "fa-check",
  cancelingPastDue: "fa-check",

  expiredPaid: "fa-clock",
  canceledPaid: "fa-clock",
  canceledPastDue: "fa-clock",
  incomplete: "fa-clock",
  incompleteExpired: "fa-clock",

  paused: "fa-pause-circle",
}

export const INVOICE_STATUSES_TEXT_MAP = {
  paid: "app.invoice.status.paid",
  unpaid: "app.invoice.status.unpaid",
}

export const INVOICE_STATUSES_COLOR_MAP = {
  paid: "success-light",
  unpaid: "warning-light",
}

export const INVOICE_STATUSES_ICON_MAP = {
  paid: "fa-check",
  unpaid: "fa-clock",
}

export const TEMPLATE_LANGS = [
  { id: "en", name: "app.common.english" },
  { id: "de", name: "app.common.german" },
]

export const COMPANY_SIZES = [
  { id: "1_to_10", name: "1 - 10" },
  { id: "11_to_50", name: "11 - 50" },
  { id: "51_to_200", name: "51 - 200" },
  { id: "201_to_500", name: "201 - 500" },
  { id: "501_to_1000", name: "501 - 1000" },
  { id: "1001_to_5000", name: "1001 - 5000" },
  { id: "5001_to_10000", name: "5001 - 10000" },
  { id: "10000_to_inf", name: "> 10000" },
]

export const DEFAULT_JOB_FEEDBACK_REASONS = {
  jobReasons: [
    { value: "job_type", feedbacksTotal: 0 },
    { value: "language", feedbacksTotal: 0 },
    { value: "career_level", feedbacksTotal: 0 },
    { value: "requirements", feedbacksTotal: 0 },
    { value: "job_description", feedbacksTotal: 0 },
    { value: "recolation_support", feedbacksTotal: 0 },
    { value: "salary", feedbacksTotal: 0 },
  ],
  companyReasons: [
    { value: "industry", feedbacksTotal: 0 },
    { value: "product", feedbacksTotal: 0 },
    { value: "reputation", feedbacksTotal: 0 },
    { value: "location", feedbacksTotal: 0 },
    { value: "company_culture", feedbacksTotal: 0 },
  ],
  missingSkills: [],
}

export const DEFAULT_INTRO_MESSAGE_TEMPLATE = {
  name: "Intro message",
  text: `Dear ${MESSAGE_TEMPLATE_TAGS.CANDIDATE_NAME},\n\nThanks for your interest in ${MESSAGE_TEMPLATE_TAGS.COMPANY_NAME}. We were introduced to your profile via MoBerries. We are glad to inform you that we believe you could potentially be a great fit for the role of ${MESSAGE_TEMPLATE_TAGS.JOB_TITLE} at our company. If it sounds interesting, kindly get back to me with suggested time slots.\n\nBest Regards,\n\n${MESSAGE_TEMPLATE_TAGS.MANAGER_NAME} ${MESSAGE_TEMPLATE_TAGS.MANAGER_LAST_NAME}`,
}

export const ALL_REGIONS = [
  "western_europe",
  "eastern_europe",
  "north_america",
  "south_america",
  "oceania",
  "asia",
  "middle_east",
  "africa",
]

export const VR_CANDIDATE_STATUSES = [
  "ACCEPTED",
  "DECLINED",
  "PENDING",
  "HIRED",
]
