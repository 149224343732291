import React from "react"
import PropTypes from "prop-types"
import {
  Col,
  CustomInput,
  Input as Binput,
  Label,
  PopoverBody,
} from "reactstrap"
import { InfoHint } from "./info-hint-component"

export const DoubleSwitch = ({
  label,
  subLabel,
  leftSwitch,
  rightSwitch,
  disabled,

  name,
  value,
  onChange,
}) => {
  const {
    value: leftValue,
    label: leftLabel,
    hint: leftHint,
    ...leftProps
  } = leftSwitch
  const {
    value: rightValue,
    label: rightLabel,
    hint: rightHint,
    ...rightProps
  } = rightSwitch

  return (
    <div className="mb-4">
      {label && (
        <Label>
          <h5 className="mb-0">{label}</h5>
        </Label>
      )}
      {subLabel && <div className="mb-2">{subLabel}</div>}
      <div className="d-flex border p-2 flex-wrap rounded">
        <Col
          xs="12"
          md="6"
          className="d-flex flex-row align-items-center p-0 mb-3 mb-md-0"
        >
          <CustomInput
            id={`l${name}`}
            disabled={disabled}
            onChange={e => {
              onChange({
                target: {
                  name,
                  value: e.target.checked ? leftValue : rightValue,
                },
              })
            }}
            checked={leftValue === value}
            type="switch"
            className="pr-4"
            {...leftProps}
          />
          <Label className="mb-0 clickable">{leftLabel}</Label>
          <InfoHint
            popover={<PopoverBody>{leftHint}</PopoverBody>}
            id={`l-hint-${name}`}
          />
        </Col>
        <Col xs="12" md="6" className="d-flex flex-row align-items-center p-0">
          <CustomInput
            id={`r${name}`}
            disabled={disabled}
            onChange={e => {
              onChange({
                target: {
                  name,
                  value: e.target.checked ? rightValue : leftValue,
                },
              })
            }}
            checked={rightValue === value}
            type="switch"
            className="pr-4"
            {...rightProps}
          />
          <Label className="mb-0 clickable">
            <span id="jobSyncAutoLabel">{rightLabel}</span>
          </Label>
          <InfoHint
            popover={<PopoverBody>{rightHint}</PopoverBody>}
            id={`r-hint-${name}`}
          />
        </Col>

        <Binput type="hidden" />
      </div>
    </div>
  )
}

DoubleSwitch.defaultProps = {
  disabled: false,
}

DoubleSwitch.propTypes = {
  label: PropTypes.node.isRequired,
  subLabel: PropTypes.node.isRequired,
  leftSwitch: PropTypes.object.isRequired,
  rightSwitch: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
