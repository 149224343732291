import { Form, Formik } from "formik"
import { isNil, reject } from "ramda"
import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Container, FormGroup } from "reactstrap"
import { Button } from "../components/common/button-component"
import { FormattedLink } from "../components/common/formatted-link-component"
import { MenuListPoweredByGoogle } from "../components/common/menu-list-powered-by-google"
import { LabeledInputField } from "../components/fields/input-field"
import { RecaptchaField } from "../components/fields/recaptcha-field"
import { LabeledSelectField } from "../components/fields/select-field"
import { LabeledTextareaField } from "../components/fields/textarea-field"
import { BaseLayout } from "../components/layouts/base-layout"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { noAuthRequired } from "../decorators/no-auth-required"
import { COMPANY_SIZES } from "../default-options"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { useGoogleLocationsLoader } from "../hooks/use-google-maps-places-loader"
import { getLocationName, getUtmParams, track } from "../lib/helpers"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { api, ApiKeys } from "../lib/moberries-api"
import { companySignupSchema } from "../schemas/company-schemas"

// We're filtering out nil values with reject, because in case getUtmParams returns null the backend validation won't pass
const toRequest = values =>
  reject(isNil, {
    ...values,
    type: 1,
    size: values?.size?.id,
    invitationTracking: getUtmParams(),
  })

export const SignupPageComponent = () => {
  // TODO: Make this better with react-query
  const { loadLocations } = useGoogleLocationsLoader()

  const {
    mutateAsync: signUp,
    isSuccess,
    isLoading: isSigningUp,
  } = useAxiosMutation(api.createCompany, {
    onSuccess: ({ name }) => {
      track({ event: "companyInquiryCreate", payload: { name } })
    },
  })

  const { data: { results: industries = [] } = {} } = useAxiosQuery(
    ApiKeys.Industries,
    api.getIndustriesList,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  return (
    <BaseLayout className="py-5">
      <Container>
        {isSuccess ? (
          <Fragment>
            <h1 className="text-center mb-4">
              <FormattedMessage id="app.page.signup.created.header" />
            </h1>
            <p className="text-center mb-4">
              <FormattedMessage id="app.page.signup.created.subheader" />
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <h1 className="text-center mb-2">
              <FormattedMessage id="app.page.signup.header" />
            </h1>
            <p className="text-center mb-4">
              <FormattedMessage
                id="app.page.signup.subheader"
                values={{
                  a: text => (
                    <FormattedLink
                      className="align-baseline p-0 border-0 d-inline-block"
                      rel="noopener noreferrer"
                      href="candidateSignUp"
                    >
                      {text}
                    </FormattedLink>
                  ),
                }}
              />
            </p>
          </Fragment>
        )}
      </Container>
      <SmallSingleColumnLayout>
        <div className="bg-white rounded px-3 py-5">
          <SmallInnerFormLayout>
            {isSuccess ? (
              <div>
                <h2>
                  <FormattedMessage id="app.page.signup.created.title" />
                </h2>
                <br />
                <p>
                  <FormattedMessage id="app.page.signup.created.text" />
                </p>
                <p>
                  <FormattedMessage id="app.page.signup.created.thanks" />
                </p>
                <p>
                  <FormattedMessage id="app.page.signup.created.signature" />
                </p>
                <br />
                <div className="text-center">
                  <FormattedLink href="homePage" as={Button}>
                    <FormattedMessage id="app.page.signup.created.back" />
                  </FormattedLink>
                </div>
              </div>
            ) : (
              <Formik
                initialValues={{
                  name: "",
                  firstName: "",
                  lastName: "",
                  city: null,
                  email: "",
                  phone: "",
                  website: null,
                  size: null,
                  industry: null,
                  about: "",
                  recaptcha: "",
                }}
                onSubmit={submitAndValidate(signUp, toRequest)}
                validationSchema={companySignupSchema}
              >
                <Form>
                  <LabeledInputField
                    label={<FormattedMessage id="app.form.name.company" />}
                    name="name"
                    id="name"
                    placeholder="MoBerries"
                    isRequired
                  />
                  <LabeledInputField
                    label={<FormattedMessage id="app.form.name.first" />}
                    name="firstName"
                    id="firstName"
                    placeholder="John"
                    isRequired
                  />
                  <LabeledInputField
                    label={<FormattedMessage id="app.form.name.last" />}
                    name="lastName"
                    id="lastName"
                    placeholder="Doe"
                    isRequired
                  />

                  <LabeledSelectField
                    label={<FormattedMessage id="app.form.location" />}
                    name="city"
                    id="city"
                    async
                    loadOptions={loadLocations}
                    components={{ MenuList: MenuListPoweredByGoogle }}
                    getOptionValue={option => option.placeId}
                    getOptionLabel={option => getLocationName(option)}
                    isRequired
                  />

                  <LabeledInputField
                    label={<FormattedMessage id="app.common.email" />}
                    name="email"
                    id="email"
                    placeholder="example@mail.com"
                    isRequired
                  />
                  <LabeledInputField
                    label={<FormattedMessage id="app.form.phone" />}
                    name="phone"
                    id="phone"
                    placeholder="+49 000 000 00 00"
                    isRequired
                  />
                  <LabeledInputField
                    label={<FormattedMessage id="app.common.website" />}
                    name="website"
                    id="website"
                    placeholder="https://example.com"
                  />
                  <LabeledSelectField
                    label={<FormattedMessage id="app.form.company.size" />}
                    name="size"
                    id="size"
                    options={COMPANY_SIZES}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    isSearchable={false}
                  />
                  <LabeledSelectField
                    label={<FormattedMessage id="app.form.company.industry" />}
                    name="industry"
                    id="industry"
                    options={industries}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.description}
                  />

                  <LabeledTextareaField
                    label={
                      <FormattedMessage id="app.page.signup.form.comment" />
                    }
                    hint={
                      <FormattedMessage id="app.page.signup.form.comment.hint" />
                    }
                    name="about"
                    id="about"
                    minRows={4}
                    maxRows={15}
                  />
                  <FormGroup>
                    <RecaptchaField name="recaptcha" />
                  </FormGroup>
                  <Button
                    disabled={isSigningUp}
                    loading={isSigningUp}
                    block
                    color="primary"
                    type="submit"
                    size="lg"
                  >
                    <FormattedMessage id="app.page.signup.button.submit" />
                  </Button>
                  <p className="text-center mt-4">
                    <FormattedMessage
                      id="app.page.signup.button.hint"
                      values={{
                        a: text => (
                          <FormattedLink
                            className="align-baseline p-0 border-0 d-inline-block"
                            rel="noopener noreferrer"
                            href="candidateSignUp"
                          >
                            {text}
                          </FormattedLink>
                        ),
                      }}
                    />
                  </p>
                </Form>
              </Formik>
            )}
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

export const SignupPage = noAuthRequired(SignupPageComponent)
