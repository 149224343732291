import { createSelector } from "reselect"

export const stateSelector = state => state.atsCompanyIntegration

export const isListLoadingSelector = createSelector(
  stateSelector,
  state => state.isListLoading,
)

export const isCreatingSelector = createSelector(
  stateSelector,
  state => state.isCreating,
)

export const isDeletingSelector = createSelector(
  stateSelector,
  state => state.isDeleting,
)

export const isUpdatingSelector = createSelector(
  stateSelector,
  state => state.isUpdating,
)

export const errorSelector = createSelector(stateSelector, state => state.err)
