import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { ButtonGroup } from "reactstrap"
import { useLocation } from "react-router-dom"
import { Button } from "../common/button-component"
import qs from "qs"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"
import { updateQueryParams, getArrowClassName } from "../../lib/helpers"
import { defaultTo, includes, values, last, head } from "ramda"
import cn from "classnames"

export const ButtonsOrdering = ({
  filterOptions,
  filterKey,
  filterName,
  disabled,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const activeFilter = defaultTo(null, params[filterKey])
  const isButtonActive = includes(activeFilter)
  return (
    <div className="mb-3 mb-md-0">
      <FormattedMessage id="app.filter.label.sortBy" />
      :&nbsp;&nbsp;
      <ButtonGroup>
        {filterOptions.map(filter => {
          const filterValues = values(filter)
          const defaultValue = head(filterValues)
          const reverseValue = last(filterValues)
          return (
            <Button
              key={filter.asc || "default"}
              color="light"
              onClick={() => {
                dispatch(
                  push({
                    pathname: location.pathname,
                    search: updateQueryParams({
                      search: location.search,
                      params: {
                        [filterKey]:
                          activeFilter === defaultValue
                            ? reverseValue
                            : defaultValue,
                      },
                    }),
                  }),
                )
              }}
              active={isButtonActive(values(filter))}
              disabled={disabled}
            >
              <FormattedMessage
                id={`app.filter.${filterName}.${filterKey}.${
                  filter.asc || "default"
                }`}
              />
              {isButtonActive(values(filter)) && (
                <i
                  className={cn(
                    "ml-1",
                    getArrowClassName(activeFilter === filter.asc),
                  )}
                />
              )}
            </Button>
          )
        })}
      </ButtonGroup>
    </div>
  )
}

ButtonsOrdering.propTypes = {
  disabled: PropTypes.bool.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      asc: PropTypes.string,
      desc: PropTypes.string,
    }),
  ),
  filterKey: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
}

ButtonsOrdering.defaultProps = {
  filters: [],
  disabled: false,
}
