import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Input, FormGroup, Label, FormFeedback } from "reactstrap"
import { Element as ScrollElement } from "react-scroll"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"

export const InputField = props => {
  const [field, meta] = useFormikField(props)
  return (
    <Fragment>
      <Input
        {...props}
        {...field}
        invalid={meta.touched && Boolean(meta.error)}
      />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </Fragment>
  )
}

export const LabeledInputField = ({ id, label, isRequired, ...rest }) => {
  const [field, meta] = useFormikField(rest)
  return (
    <FormGroup>
      <ScrollElement name={`form-position-${id}`} />
      <Label for={id}>
        {label}
        {isRequired && " *"}
      </Label>
      <Input
        id={id}
        {...rest}
        {...field}
        invalid={meta.touched && Boolean(meta.error)}
      />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

LabeledInputField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
}

LabeledInputField.defaultProps = {
  isRequired: false,
}
