import React from "react"
import { Form, Formik } from "formik"
import { PaymentsPageLayout } from "../components/layouts/payments-page-layout"
import { TabPane } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { CompanyBillingFields } from "../components/subscription/company-billing-fields"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { useStore } from "../lib/store"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { companyBillingSchema } from "../schemas/company-billing-schema"
import { SubmitButtonField } from "../components/fields/submit-button-field"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api } from "../lib/moberries-api"
import { refreshCompany } from "../controllers/company-controller"
import { pick, pipe } from "ramda"
import { getChangedFields } from "../lib/helpers"
import { FirstErrorScroll } from "../components/fields/first-error-scroll"

const toRequest = companyBilling => values => {
  const getChangedValues = pipe(
    getChangedFields,
    changedFields => pick(changedFields, values),
    changedObject => pick(Object.keys(companyBilling), changedObject),
  )

  return {
    company: {
      companyBilling: getChangedValues(values, companyBilling),
    },
  }
}

export const PaymentsBillingPage = () => {
  const company = useStore(state => state.company)
  const companyBilling = useStore(state => state.company.companyBilling)

  const { mutateAsync: updateCompany } = useAxiosMutation(api.updateCompany, {
    onSuccess: async () => {
      await refreshCompany()
    },
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...companyBilling,
        isVatChecked: companyBilling.vatId !== "",
      }}
      onSubmit={submitAndValidate(updateCompany, toRequest(companyBilling))}
      validationSchema={companyBillingSchema}
    >
      <Form>
        <FirstErrorScroll />
        <PaymentsPageLayout>
          <div className="bg-white px-3 py-6">
            <SmallInnerFormLayout>
              <TabPane>
                <CompanyBillingFields
                  form="CompanyBillingFields"
                  initialValues={company.companyBilling}
                  enableReinitialize
                />
                <SubmitButtonField color="primary" block size="lg">
                  <FormattedMessage id="app.common.save" />
                </SubmitButtonField>
              </TabPane>
            </SmallInnerFormLayout>
          </div>
        </PaymentsPageLayout>
      </Form>
    </Formik>
  )
}
