import React from "react"
import PropTypes from "prop-types"
import { Media } from "reactstrap"
import cn from "classnames"

export function Card({
  controls,
  title,
  subtitle,
  titleLabels,
  additionalInfo,
  onClick,
  style,
  className,
  id,
  image,
}) {
  const cardProps = {}

  if (onClick) {
    cardProps.onClick = onClick
  }

  if (style) {
    cardProps.style = style
  }

  const css = cn("bg-white p-3 rounded", className)

  return (
    <Media className={css} id={id} {...cardProps}>
      {image && (
        <Media left className="mr-3">
          {image}
        </Media>
      )}
      <Media body>
        <small className="text-break">{subtitle}</small>
        <div className="d-flex flex-wrap">
          <Media heading className="clickable mr-1 text-break">
            {title}
          </Media>
          {titleLabels && <span className="tags-wrapper">{titleLabels}</span>}
        </div>
        {additionalInfo}
      </Media>
      {controls && <div className="pl-2 text-right">{controls}</div>}
    </Media>
  )
}

Card.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.node,
  className: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  titleLabels: PropTypes.node,
  additionalInfo: PropTypes.node,
  controls: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

Card.defaultProps = {
  title: "",
  subtitle: "",
  titleLabels: "",
  className: "",
}
