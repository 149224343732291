import { useState } from "react"
import { useOutsideEvent } from "./use-outside-event"

export function useOutsideClickCloser(opts) {
  const [isExpanded, setIsExpanded] = useState(!!opts?.initialState)
  const close = () => {
    setIsExpanded(false)
  }
  const open = () => {
    setIsExpanded(true)
  }
  const ref = useOutsideEvent(close)
  return { isExpanded, ref, close, open }
}
