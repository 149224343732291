import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import Arrow from "../../../../assets/img/chart-picker-arrow.svg"
import styles from "./value-picker.module.scss"
import { useOutsideClickCloser } from "../../../../hooks/use-outside-click-closer"
import { FormattedMessage } from "react-intl"
import { isIntlId } from "../../../../lib/helpers"

export function ValuePicker({ availableValues, selectedValue, setSelected }) {
  const { isExpanded, ref, close, open } = useOutsideClickCloser()

  const onSelectHandler = newSelected => {
    close()
    setSelected(newSelected)
  }

  return (
    <div className={styles.valuePicker} ref={ref}>
      <button
        type="button"
        onClick={() => (isExpanded ? close() : open())}
        className={styles.valuePickerChoice}
        disabled={!selectedValue}
      >
        <p className={styles.pick}>
          {isIntlId(selectedValue?.label) ? (
            <FormattedMessage id={selectedValue.label} />
          ) : (
            selectedValue?.label
          )}
        </p>
        <img src={Arrow} width="10" height="10" alt="v" />
      </button>
      {isExpanded && (
        <div className={styles.valuePicker__dropDown}>
          {availableValues?.map(option => (
            <button
              key={option.label}
              type="button"
              onClick={() => onSelectHandler(option)}
              className={cn([
                styles.valuePickerChoice,
                styles.valuePickerChoice_menu,
              ])}
              disabled={selectedValue.label === option.label}
            >
              <p>
                {isIntlId(selectedValue?.label) ? (
                  <FormattedMessage id={option.label} />
                ) : (
                  option.label
                )}
              </p>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

ValuePicker.propTypes = {
  setSelected: PropTypes.func.isRequired,
  selectedValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  availableValues: PropTypes.array.isRequired,
}
