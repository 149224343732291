import React from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { DashboardWidgetLayout } from "../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import { Doughnut } from "../../charts/lib/doughnut"
import { ChartLegend } from "../../charts/chart-legend/chart-legend"
import { legendsMap } from "./helpers"
import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { TalentsYouMissWidgetEmpty } from "./talents-you-miss-widget-empty/talents-you-miss-widget-empty"
import styles from "./talents-you-miss-widget.module.scss"

export const TalentsYouMissWidget = () => {
  const { data } = useAxiosQuery(
    ApiKeys.FeedbackSummary,
    moberriesApi.getFeedbackSummary,
  )
  const companyReasons = data?.companyReasons.reduce(
    (acc, item) => acc + item.feedbacksTotal,
    0,
  )
  const jobReasons = data?.jobReasons.reduce(
    (acc, item) => acc + item.feedbacksTotal,
    0,
  )
  const totalReasons = companyReasons + jobReasons
  const declineData = [
    Math.round((companyReasons / totalReasons) * 100 * 10) / 10,
    Math.round((jobReasons / totalReasons) * 100 * 10) / 10,
  ]
  const colors = legendsMap.map(l => ({
    color: l.color,
    mutedColor: l.mutedColor,
  }))

  const isEmpty = !totalReasons

  if (isEmpty) {
    return <TalentsYouMissWidgetEmpty />
  }

  return (
    <DashboardWidgetLayout
      title={<FormattedMessage id="app.widget.talentsYouMiss.title" />}
    >
      <div className={styles.content}>
        <div className={styles.chart}>
          <Doughnut data={declineData} colors={colors} />
        </div>
        <div className={styles.info}>
          <div className={styles.legends}>
            <h4 className={styles.legends__title}>
              <FormattedMessage id="app.page.analytics.charts.talentsYouMiss.legends.title" />
            </h4>
            <div className={styles.legends__items}>
              {legendsMap.map(legend => (
                <ChartLegend
                  key={legend.label}
                  title={`app.page.analytics.charts.talentsYouMiss.legends.${legend.label}`}
                  color={legend.color}
                />
              ))}
            </div>
          </div>
          <Link className={styles.link} to="/analytics">
            <FormattedMessage id="app.page.analytics.charts.talentsYouMiss.seeMore" />
          </Link>
        </div>
      </div>
    </DashboardWidgetLayout>
  )
}
