import { showErrorAlert } from "../lib/alerts"
import { getFullName, track } from "../lib/helpers"
import { api } from "../lib/moberries-api"
import { store } from "../lib/store"

export const refreshManager = async () => {
  try {
    const { data: manager } = await api.getManager()
    track({
      event: "fetchProfile",
      payload: {
        id: manager.id,
        email: manager.email,
        name: getFullName(manager),
        company: {
          id: manager.company.id,
          name: manager.company.name,
        },
      },
    })
    store.setState({ manager })
  } catch (err) {
    showErrorAlert({ err })
  }
}
