import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { path } from "ramda"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { NotFound } from "../components/common/not-found-component"
import errorImage from "../assets/img/error-page.svg"
import { PROBLEMS } from "../constants"
import { Button } from "../components/common/button-component"
import { BaseLayout } from "../components/layouts/base-layout"

export function ErrorPage(props) {
  const { err } = props
  let content

  switch (err.problem) {
    case PROBLEMS.SERVER_ERROR:
      content = (
        <ErrorPageBody
          title={<FormattedMessage id="app.page.error.server.title" />}
          subTitle={<FormattedMessage id="app.page.error.server.subtitle" />}
          content={
            <img
              alt={<FormattedMessage id="app.page.error.server.title" />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
      break

    case PROBLEMS.NETWORK_ERROR:
      content = (
        <ErrorPageBody
          title={<FormattedMessage id="app.page.error.network.title" />}
          subTitle={<FormattedMessage id="app.page.error.network.subtitle" />}
          content={
            <img
              alt={<FormattedMessage id="app.page.error.network.title" />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
      break

    case PROBLEMS.TIMEOUT_ERROR:
    case PROBLEMS.CONNECTION_ERROR:
    case PROBLEMS.CANCEL_ERROR:
    case PROBLEMS.UNKNOWN_ERROR:
      content = (
        <ErrorPageBody
          title={<FormattedMessage id="app.page.error.client.title" />}
          subTitle={<FormattedMessage id="app.page.error.client.subtitle" />}
          content={
            <img
              alt={<FormattedMessage id="app.page.error.client.title" />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
      break

    case PROBLEMS.CLIENT_ERROR:
      switch (path(["response", "status"], err)) {
        case 403:
          content = (
            <ErrorPageBody
              title={<FormattedMessage id="app.page.error.client.title" />}
              subTitle={
                <FormattedMessage id="app.page.error.client.subtitle" />
              }
              content={
                <img
                  alt={<FormattedMessage id="app.page.error.client.title" />}
                  height="400"
                  src={errorImage}
                />
              }
            />
          )
          break

        case 404:
          content = <NotFound />
          break

        default:
          content = (
            <ErrorPageBody
              title={<FormattedMessage id="app.page.error.client.title" />}
              subTitle={
                <FormattedMessage id="app.page.error.client.subtitle" />
              }
              content={
                <img
                  alt={<FormattedMessage id="app.page.error.client.title" />}
                  height="400"
                  src={errorImage}
                />
              }
            />
          )
      }

      break

    default:
      content = (
        <ErrorPageBody
          title={<FormattedMessage id="app.page.error.client.title" />}
          subTitle={<FormattedMessage id="app.page.error.client.subtitle" />}
          content={
            <img
              alt={<FormattedMessage id="app.page.error.client.title" />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
  }

  return (
    <BaseLayout className="py-5">
      <SingleColumnLayout>
        <div className="mb-4 text-center">{content}</div>
      </SingleColumnLayout>
    </BaseLayout>
  )
}

ErrorPage.propTypes = {
  err: PropTypes.object.isRequired,
}

ErrorPage.defaultProps = {}

function ErrorPageBody(props) {
  return (
    <Fragment>
      <h1>{props.title}</h1>
      {props.subTitle && <p className="text-muted">{props.subTitle}</p>}
      <div className="d-flex flex-row justify-content-center">
        <Button
          onClick={() => {
            window.location.reload()
          }}
          color="primary"
        >
          Reload page
        </Button>
        <Button tag="a" className="ml-2" href="/">
          Home
        </Button>
      </div>
      {props.content}
    </Fragment>
  )
}

ErrorPageBody.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
  content: PropTypes.node,
}

ErrorPageBody.defaultProps = {
  content: null,
}
