import React, { Fragment } from "react"
import { Form, Formik } from "formik"
import { Container } from "reactstrap"
import { Button } from "../components/common/button-component"
import { FormattedMessage } from "react-intl"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { ManagerEditPageSkeleton } from "../components/skeletons/manager-edit-page-skeleton"
import { ManagerFormFields } from "../components/managers/manager-form-fields"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api, ApiKeys } from "../lib/moberries-api"
import { createManagerSchema } from "../schemas/manager-schemas"
import { useHistory, useLocation } from "react-router"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { USER_GROUPS } from "../constants"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { useStore } from "../lib/store"

const toRequest = companyId => formValues => {
  const requestData = {
    ...formValues,
    groups: [USER_GROUPS.MANAGER],
    company: companyId,
  }
  if (formValues.isAdmin) {
    requestData.groups.push(USER_GROUPS.SUPER_GROUP)
  }

  return requestData
}

export const ManagerInvitePage = () => {
  const location = useLocation()
  const history = useHistory()
  const { id: companyId } = useStore(state => state.company)

  const { data: { results: teams = [] } = {}, isFetched } = useAxiosQuery(
    [ApiKeys.teams],
    api.getCompanyTeamList,
  )
  const generalTeam = teams.find(d => d.name === "General")

  const { isLoading: isCreating, mutateAsync: createManager } =
    useAxiosMutation(api.createNewManager, {
      onSuccess: () => history.push("/company/managers"),
    })

  if (!isFetched) {
    return (
      <ManagerEditPageSkeleton
        title={<FormattedMessage id="app.page.managers.invite.header" />}
        count={4}
      />
    )
  }

  return (
    <Fragment>
      <SmallSingleColumnLayout>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.page.managers.invite.header" />
        </h1>
        <Container className="bg-white py-5 px-3">
          <SmallInnerFormLayout>
            <Formik
              validationSchema={createManagerSchema}
              initialValues={{
                firstName: "",
                lastName: "",
                email: location.state?.newManagerEmail ?? "",
                teams: generalTeam ? [generalTeam.id] : [],
                isAdmin: false,
              }}
              onSubmit={submitAndValidate(createManager, toRequest(companyId))}
            >
              <Form>
                <ManagerFormFields availableTeams={teams} />
                <Button
                  type="submit"
                  size="lg"
                  color="primary"
                  block
                  disabled={isCreating}
                  loading={isCreating}
                >
                  <FormattedMessage id="app.page.company.managers.invite" />
                </Button>
              </Form>
            </Formik>
          </SmallInnerFormLayout>
        </Container>
      </SmallSingleColumnLayout>
    </Fragment>
  )
}
