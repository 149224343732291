import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"

import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { AnalyticsChartLayout } from "../../layouts/analytics/analytics-chart-layout/analytics-chart-layout"
import { VerticalBarV2 } from "../lib/vertical-bar"
import { ValuePicker } from "../lib/value-picker/value-picker"
import { ChartLegend } from "../chart-legend/chart-legend"
import { ChartEmptyStateV2 } from "../chart-empty-state/chart-empty-state"
import { ChartSkeletonState } from "../chart-skeleton-state/chart-skeleton-state"
import { roleMapper, empty, mapper } from "./helpers"

import emptyIcon from "../../../assets/img/illustration-old-speaker.svg"
import styles from "./job-category-job-role.module.scss"

export const JobCategoryJobRoleChart = ({ categories }) => {
  const availableCategories = categories
  const [categorySelection, setCategorySelection] = useState(
    availableCategories[0], // default selection: Software Development
  )
  const [availableRoles, setAvailableRoles] = useState(null)
  const [roleSelection, setRoleSelection] = useState(null)

  const { data, isLoading } = useAxiosQuery(
    [
      ApiKeys.SalaryLevelStats,
      {
        categoryId: categorySelection.value,
      },
    ],
    moberriesApi.getSalaryLevelStats,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    let availableRoles = roleMapper(data)
    setAvailableRoles(availableRoles)
    setRoleSelection(availableRoles[0])
  }, [data, isLoading])

  const isEmpty = empty(data, roleSelection)
  const chartProps = !isEmpty ? mapper(data, roleSelection) : {}

  if (isLoading) {
    return (
      <ChartSkeletonState
        title={
          <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobCategoryJobRole.header" />
        }
      />
    )
  }

  if (isEmpty && !isLoading) {
    return (
      <ChartEmptyStateV2
        title={
          <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobCategoryJobRole.header" />
        }
        info={
          roleSelection?.label ? (
            <FormattedMessage
              id="app.page.analytics.strategyBuilder.charts.jobCategoryJobRole.empty"
              values={{
                category: <FormattedMessage id={categorySelection.label} />,
                role: <FormattedMessage id={roleSelection.label} />,
              }}
            />
          ) : (
            <FormattedMessage
              id="app.page.analytics.strategyBuilder.charts.jobCategoryJobRole.empty.withoutRole"
              values={{
                category: <FormattedMessage id={categorySelection.label} />,
              }}
            />
          )
        }
        img={emptyIcon}
      >
        <div className={styles.head}>
          <ValuePicker
            availableValues={availableCategories}
            setSelected={setCategorySelection}
            selectedValue={categorySelection}
          />
          {roleSelection?.label && (
            <ValuePicker
              availableValues={availableRoles}
              setSelected={setRoleSelection}
              selectedValue={roleSelection}
            />
          )}
        </div>
      </ChartEmptyStateV2>
    )
  }

  return (
    <AnalyticsChartLayout
      title={
        <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobCategoryJobRole.header" />
      }
    >
      <div className={styles.head}>
        <ValuePicker
          availableValues={availableCategories}
          setSelected={setCategorySelection}
          selectedValue={categorySelection}
        />
        <ValuePicker
          availableValues={availableRoles}
          setSelected={setRoleSelection}
          selectedValue={roleSelection}
        />
      </div>
      <VerticalBarV2 {...chartProps} />
      <div className={styles.bottom}>
        <div className={styles.legends}>
          <ChartLegend
            title="app.page.analytics.strategyBuilder.charts.jobCategoryJobRole.legends.average"
            color="#e3e80c"
          />
        </div>
      </div>
    </AnalyticsChartLayout>
  )
}

JobCategoryJobRoleChart.propTypes = {
  categories: PropTypes.array.isRequired,
}
