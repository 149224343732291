import { getActionType } from "../lib/helpers"
import {
  GET,
  LIST,
  MESSAGE,
  CREATE,
  START,
  SUCCESS,
  FAIL,
  UPDATE,
  TEMPLATE,
  DELETE,
} from "../constants"
import { showErrorAlert } from "../lib/alerts"

export function getMessageTemplateList() {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(GET, MESSAGE, TEMPLATE, LIST, START) })

    try {
      const {
        data: { results: messageTemplates },
      } = await moApi.getMessageTemplateList()

      dispatch({
        type: getActionType(GET, MESSAGE, TEMPLATE, LIST, SUCCESS),
        payload: { messageTemplates },
      })
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(GET, MESSAGE, TEMPLATE, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export function createMessageTemplateSuccess({ messageTemplate }) {
  return {
    type: getActionType(CREATE, MESSAGE, TEMPLATE, SUCCESS),
    payload: { messageTemplate },
  }
}

export function createMessageTemplateFail({ err }) {
  return {
    type: getActionType(CREATE, MESSAGE, TEMPLATE, FAIL),
    payload: { err },
  }
}

export function updateMessageTemplateSuccess({ messageTemplate }) {
  return {
    type: getActionType(UPDATE, MESSAGE, TEMPLATE, SUCCESS),
    payload: { messageTemplate },
  }
}

export function updateMessageTemplateFail({ err }) {
  return {
    type: getActionType(UPDATE, MESSAGE, TEMPLATE, FAIL),
    payload: { err },
  }
}

export function deleteMessageTemplate({ messageTemplateId }) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({
      type: getActionType(DELETE, MESSAGE, TEMPLATE, START),
      payload: { messageTemplateId },
    })
    try {
      await moApi.deleteMessageTemplate({ messageTemplateId })

      dispatch({
        type: getActionType(DELETE, MESSAGE, TEMPLATE, SUCCESS),
        payload: { messageTemplateId },
      })
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(DELETE, MESSAGE, TEMPLATE, FAIL),
        payload: { err },
      })
    }
  }
}
