import React, { useMemo, useEffect, useState } from "react"

import styles from "./matches-chart.module.scss"
import { HorizontalBars } from "../lib/horizontal-bars"
import { useStore } from "../../../lib/store"
import { mapper } from "./helpers"
import { ChartLegend } from "../chart-legend/chart-legend"
import { FormattedMessage, useIntl } from "react-intl"
import { ACT, MATCHING_PER_JOB_JOBS_LIMIT, PAU } from "../../../constants"
import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { ValuePickerPaginated } from "../lib/value-picker-async-paginated/value-picker-paginated"
import { ChartEmptyState } from "../chart-empty-state/chart-empty-state"
import emptyIcon from "../../../assets/img/illustration-search.svg"

export function MatchesChart() {
  const statistics = useStore(({ company }) => company.statistics)
  const { formatMessage } = useIntl()
  const [page, setPage] = useState(1)

  const { data: jobs, isLoading: isLoadingJobs } = useAxiosQuery(
    [
      ApiKeys.PerJobChartJobs,
      {
        params: {
          limit: MATCHING_PER_JOB_JOBS_LIMIT,
          status__in: [ACT, PAU],
          page,
        },
      },
    ],
    moberriesApi.getCompanyJobList,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const pickerAvailableValues = useMemo(() => getAvailableValues(jobs), [jobs])
  const [availableValues, setAvailableValues] = useState(pickerAvailableValues)
  const [selection, setSelection] = useState(null)

  useEffect(() => {
    if (jobs) {
      setAvailableValues(pickerAvailableValues)
      setSelection(pickerAvailableValues?.[0])
    }
  }, [jobs, pickerAvailableValues])

  const dataAndLabels = mapper(
    [statistics, jobs?.results],
    formatMessage,
    selection,
  )
  const isEmpty = dataAndLabels.data[0].reduce((ac, v) => ac + v, 0) === 0

  if (isEmpty) {
    return (
      <ChartEmptyState
        title="app.page.analytics.charts.matching.name"
        info="app.page.analytics.charts.common.empty.text"
        img={emptyIcon}
      />
    )
  }

  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="app.page.analytics.charts.matching.name" />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.head}>
            <div className={styles.legends}>
              <ChartLegend
                title="app.page.analytics.charts.matching.overall"
                color="#0738DA"
              />
              <ChartLegend
                title="app.page.analytics.charts.matching.perJob"
                color="#00C3F9"
              />
            </div>
            <ValuePickerPaginated
              onSelect={value => setSelection(value)}
              availableValues={availableValues || []}
              isLoadingJobs={isLoadingJobs}
              jobsCount={jobs?.count || 0}
              page={page}
              setPage={setPage}
            />
          </div>
          <HorizontalBars {...dataAndLabels} />
        </div>
      </div>
    </div>
  )
}

function getAvailableValues(data) {
  return data?.results?.map(job => ({ label: job.title, value: job.id }))
}
