import React from "react"
import PropTypes from "prop-types"
import { SmallSingleColumnLayout } from "../layouts/small-single-column-layout"
import { Container } from "reactstrap"
import { SmallInnerFormLayout } from "../layouts/small-inner-form-layout"
import { FormLoader } from "../loaders/form-loader"

export const ManagerEditPageSkeleton = ({ title }) => (
  <SmallSingleColumnLayout>
    <h1 className="text-center mb-4">{title}</h1>
    <Container className="bg-white py-5">
      <SmallInnerFormLayout className="bg-white">
        <FormLoader count={4} />
      </SmallInnerFormLayout>
    </Container>
  </SmallSingleColumnLayout>
)

ManagerEditPageSkeleton.propTypes = {
  title: PropTypes.node.isRequired,
}
