import { Formik, Form } from "formik"
import React, { Fragment, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button } from "../components/common/button-component"
import { JobCreatePageLayout } from "../components/layouts/job-create-page-layout"
import { JobDetailsFormFields } from "../components/job/job-details-form-fields"
import { useStore } from "../lib/store"
import { ALL_REGIONS, CAREER_LEVELS, JOB_TYPES } from "../default-options"
import { FormattedMessage } from "react-intl"
import { ATS_JOB_SYNC_OPTIONS, JOB_CONDITION_TYPES } from "../constants"
import {
  companySubscriptionSelector,
  getCurrentAvailableFlatSlots,
  isComSubscriptionCanceled,
} from "../lib/helpers"
import { editorStateToHtml } from "../lib/helpers/text-helpers"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api as moberriesApi } from "../lib/moberries-api"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { EditorState } from "draft-js"
import { ScrollToTop } from "../components/common/scroll-to-top"
import { JobConditionField } from "../components/fields/job-condition-field"
import { LabeledInputField } from "../components/fields/input-field"
import { LabeledRichTextEditorField } from "../components/fields/rich-text-editor-field"
import { LabeledJobAtsIntegrationField } from "../components/job/job-ats-integration-field"
import { atsApi } from "../lib/ats-api"
import { jobDescriptionSchema, jobDetailsSchema } from "../schemas/job-schemas"
import { Redirect } from "react-router"
import { FirstErrorScroll } from "../components/fields/first-error-scroll"
import { SubmitButtonField } from "../components/fields/submit-button-field"

const formToJob = ({
  careerLevel,
  conditionType,
  description,
  team,
  salary,
  ...values
}) => ({
  ...values,
  careerLevel: careerLevel.id,
  condition: {
    type: conditionType,
  },
  description: editorStateToHtml(description),
  team: team.id,
  salaryMin: salary.min,
  salaryMax: salary.max,
})

export const JobCreatePage = () => {
  const [step, setStep] = useState(0)
  const history = useHistory()
  const company = useStore(state => state.company)
  const manager = useStore(state => state.manager)
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const companySubscription = useStore(companySubscriptionSelector)
  const { mutateAsync: createAtsJobIntegration } = useAxiosMutation(
    atsApi.createAtsJobIntegration,
  )
  const { mutateAsync: createJob } = useAxiosMutation(moberriesApi.createJob, {
    onSuccess: (job, { atsJob }) => {
      if (atsJob) {
        createAtsJobIntegration({
          moberriesJobId: job.id,
          providerJobId: atsJob.id,
        })
      }
      history.push("/jobs")
    },
  })

  const goNext = async (_, { setTouched }) => {
    setTouched({})
    setStep(1)
  }

  if (atsCompanyIntegration?.jobSyncType === ATS_JOB_SYNC_OPTIONS.AUTO) {
    return <Redirect to="/jobs" />
  }

  return (
    <JobCreatePageLayout step={step}>
      <Formik
        initialValues={{
          conditionType:
            getCurrentAvailableFlatSlots(companySubscription) > 0 &&
            !isComSubscriptionCanceled(companySubscription)
              ? JOB_CONDITION_TYPES.flat
              : JOB_CONDITION_TYPES.pph,
          atsJob: null,
          title: "",
          description: EditorState.createEmpty(),
          locations: [company.city],
          relocate: true,
          relocationRegions: ALL_REGIONS,
          remote: false,
          remoteRegions: [],
          salary: { min: 0, max: 0 },
          jobTypes: [JOB_TYPES[0].id],
          careerLevel: CAREER_LEVELS[2],
          jobRoles: [],
          skills: [],
          languages: [],
          team: manager.teams[0],
          private: false,
        }}
        onSubmit={[goNext, submitAndValidate(createJob, formToJob)][step]}
        validationSchema={[jobDescriptionSchema, jobDetailsSchema][step]}
      >
        <Form>
          <FirstErrorScroll />

          {step === 0 && (
            <Fragment>
              <ScrollToTop />
              <h1 className="mb-4">
                <FormattedMessage id="app.job.form.posting.type.title" />
              </h1>
              <JobConditionField name="conditionType" />
              <h1 className="mb-4">
                <FormattedMessage id="app.job.form.posting.job.info.title" />
              </h1>
              <LabeledJobAtsIntegrationField name="atsJob" />
              <LabeledInputField
                name="title"
                id="title"
                label={<FormattedMessage id="app.job.form.title.label" />}
                isRequired
              />
              <LabeledRichTextEditorField
                name="description"
                id="description"
                label={<FormattedMessage id="app.job.form.description.label" />}
                isRequired
              />
              <SubmitButtonField size="lg" block color="primary">
                <FormattedMessage id="app.common.next" /> &rarr;
              </SubmitButtonField>
            </Fragment>
          )}

          {step === 1 && (
            <Fragment>
              <ScrollToTop />
              <JobDetailsFormFields />
              <div className="d-flex justify-content-between">
                <Button
                  size="lg"
                  color="light"
                  type="button"
                  onClick={() => setStep(0)}
                >
                  &larr; <FormattedMessage id="app.common.back" />
                </Button>
                <SubmitButtonField size="lg" color="primary">
                  <FormattedMessage id="app.common.save" />
                </SubmitButtonField>
              </div>
            </Fragment>
          )}
        </Form>
      </Formik>
    </JobCreatePageLayout>
  )
}
