import React from "react"
import PropTypes from "prop-types"

export const WidgetProgressBar = ({ value }) => (
  <div className="border-bottom widget-progress-bar">
    <div
      className="bg-dark widget-progress-bar-line"
      style={{
        width: value * 100 + "%",
      }}
    />
  </div>
)

WidgetProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
}
