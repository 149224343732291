import { stopSubmit } from "redux-form"
import {
  mapAtsIntegrationApiValidationError,
  mapMoberriesApiValidationError,
} from "../lib/helpers"

export const validateForm = ({ formName, err }) =>
  stopSubmit(formName, mapMoberriesApiValidationError(err.response.data))

export const validateAtsForm = ({ formName, err }) =>
  stopSubmit(
    formName,
    mapAtsIntegrationApiValidationError(err.response.data.errors),
  )
