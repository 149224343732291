import React from "react"
import PropTypes from "prop-types"
import {
  Input as Binput,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
} from "reactstrap"
import FormFeedback from "./form-feedback"
import { Element as ScrollElement } from "react-scroll"

export function Input(props) {
  let {
    input,
    meta,
    options,
    label,
    underInput,
    underFeedback,
    underLabel,
    hint,
    className,
    inputClassName,
    labelClassName,
    disabled,
    prependAddon,
    appendAddon,
    appendAddonButton,
    isRequired,
    ...rest
  } = props
  const { error, valid, invalid, touched } = meta

  const isCheckbox = rest.type === "checkbox"

  if (isCheckbox) {
    inputClassName = inputClassName || "custom-control-input"
    labelClassName = labelClassName || "custom-control-label"
    className =
      className ||
      "custom-control-inline custom-control custom-checkbox flex-column"
  }
  return (
    <FormGroup className={className}>
      <ScrollElement name={`position-${meta.form}-${input.name}`} />
      {label && !isCheckbox && (
        <Label className={labelClassName} for={rest.id}>
          {label}
          {isRequired && "*"}
        </Label>
      )}
      {hint}
      {underLabel}
      {!(prependAddon || appendAddon || appendAddonButton) ? (
        <Binput
          {...rest}
          {...input}
          className={inputClassName}
          valid={!touched || valid ? null : valid}
          invalid={!touched || valid ? null : invalid}
          disabled={disabled}
        />
      ) : (
        <InputGroup>
          {prependAddon && (
            <InputGroupAddon addonType="prepend">
              {prependAddon}
            </InputGroupAddon>
          )}
          <Binput
            {...rest}
            {...input}
            className={inputClassName}
            valid={!touched || valid ? null : valid}
            invalid={!touched || valid ? null : invalid}
            disabled={disabled}
          />
          {appendAddon && (
            <InputGroupAddon addonType="append">{appendAddon}</InputGroupAddon>
          )}
          {appendAddonButton && (
            <InputGroupAddon addonType="append">
              {appendAddonButton}
            </InputGroupAddon>
          )}

          <FormFeedback error={error} />
        </InputGroup>
      )}

      {label && isCheckbox && (
        <Label className={labelClassName} for={rest.id}>
          {label}
          {isRequired && "*"}
        </Label>
      )}
      {underInput}
      <FormFeedback error={error} />
      {underFeedback}
    </FormGroup>
  )
}

Input.propTypes = {
  label: PropTypes.node,
  hint: PropTypes.node,
  underInput: PropTypes.node,
  underFeedback: PropTypes.node,
  underLabel: PropTypes.node,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  className: PropTypes.string.isRequired,
  inputClassName: PropTypes.string.isRequired,
  labelClassName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  appendAddon: PropTypes.node,
  prependAddon: PropTypes.node,
  appendAddonButton: PropTypes.node,
  isRequired: PropTypes.bool,
}

Input.defaultProps = {
  type: "text",
  disabled: false,
  className: "",
  inputClassName: "",
  labelClassName: "",
  isRequired: false,
}
