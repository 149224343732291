import create from "zustand"
import { persist } from "zustand/middleware"
import { getDefaultLocale } from "./helpers"

const store = create(
  persist(
    (set, get) => ({
      isInitialized: false,
      company: null,
      manager: null,
      jwt: null,
      jwtUpdatedAt: null,
      locale: getDefaultLocale(),

      // ATS
      atsCompanyIntegration: null,
      providers: [],
      connectedProvider: null,
    }),
    {
      name: "moberries-state",
      blacklist: ["isInitialized"],
    },
  ),
)

const useStore = store

export { store, useStore }
