import { map, slice, sort } from "ramda"

export const availableSelections = [
  {
    label: "app.page.analytics.charts.feedback.selection.job",
    value: "jobReasons",
  },
  {
    label: "app.page.analytics.charts.feedback.selection.company",
    value: "companyReasons",
  },
  {
    label: "app.page.analytics.charts.feedback.selection.skills",
    value: "missingSkills",
  },
]

export const selectionsConfig = {
  [availableSelections[0].value]: {
    color: "#FFB600",
    legendName: "app.page.analytics.charts.feedback.selection.job",
  },
  [availableSelections[1].value]: {
    color: "#ED594C",
    legendName: "app.page.analytics.charts.feedback.selection.company",
  },
  [availableSelections[2].value]: {
    color: "#589452",
    legendName: "app.page.analytics.charts.feedback.selection.skills",
  },
}

export const mapper = (rawData = {}, area, formatMessage) => {
  const currentData = rawData[area.value] || {}

  const reasons = slice(
    0,
    5,
    sort((a, b) => b.feedbacksTotal - a.feedbacksTotal, currentData),
  )

  const data = Object.values(reasons).map(r => r.feedbacksTotal)

  const labels = Object.values(reasons)
    .map(reason =>
      reason.valueIsLabel
        ? reason.value
        : formatMessage({
            id: `app.page.analytics.charts.feedback.labels.${reason.value}`,
          }),
    )
    .map(label => {
      const separator = "&sep"
      const newLabel = label.split(" ").reduce((ac, val, index) => {
        const lastLine = ac.split(separator).slice(-1)[0]
        if (lastLine.length + val.length <= 13) {
          return index === 0 ? val : ac + " " + val
        }
        if (val.length > 12) {
          return (
            ac + separator + val.slice(0, 12) + "-" + separator + val.slice(12)
          )
        }
        return ac + separator + val
      }, "")
      return newLabel.split(separator).filter(Boolean)
    })
  return {
    data: [data],
    labels,
  }
}

export const formatData = data =>
  map(
    reasons =>
      map(
        reason =>
          reason.value
            ? {
                ...reason,
                valueIsLabel: false,
              }
            : {
                value: reason.name,
                feedbacksTotal: reason.count,
                valueIsLabel: true,
              },
        reasons,
      ),
    data || [],
  )
