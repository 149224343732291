import {
  CAMPAIGN,
  FAIL,
  GET,
  LIST,
  RESET,
  SEARCH,
  SELECT,
  SET,
  START,
  STATUS,
  SUCCESS,
  VR_CANDIDATE,
} from "../constants"
import { showErrorAlert } from "../lib/alerts"
import { getActionType } from "../lib/helpers"
import {
  campaignSelector,
  searchSelector,
} from "../selectors/vr-candidate-selector"

export const getVrCandidateList = () => {
  return async (dispatch, getState, { moApi }) => {
    dispatch({ type: getActionType(GET, VR_CANDIDATE, LIST, START) })

    try {
      const campaign = campaignSelector(getState())
      const search = searchSelector(getState())

      const res = await moApi.getVrCandidateList({
        lastName: search || null,
        campaignId: campaign.id,
        orderByStatus: true,
        limit: 1000,
      })
      const { results: candidates, count } = res.data

      dispatch({
        type: getActionType(GET, VR_CANDIDATE, LIST, SUCCESS),
        payload: {
          candidates,
          count,
        },
      })
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(GET, VR_CANDIDATE, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export const selectCampaign =
  ({ campaign }) =>
  dispatch => {
    dispatch({
      type: getActionType(SELECT, VR_CANDIDATE, CAMPAIGN),
      payload: { campaign },
    })

    if (campaign) dispatch(getVrCandidateList())
  }

export const setVrCandidateStatus = params => {
  return async (dispatch, getState, { moApi }) => {
    dispatch({ type: getActionType(SET, VR_CANDIDATE, STATUS, START) })

    try {
      const campaign = campaignSelector(getState())
      await moApi.setVrCandidateStatus({ ...params, campaignId: campaign.id })

      dispatch({
        type: getActionType(SET, VR_CANDIDATE, STATUS, SUCCESS),
      })
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(SET, VR_CANDIDATE, STATUS, FAIL),
        payload: { err },
      })
    }

    dispatch(getVrCandidateList())
  }
}

export const setVrCandidateSearch =
  ({ search }) =>
  dispatch => {
    dispatch({
      type: getActionType(SET, VR_CANDIDATE, SEARCH),
      payload: {
        search,
      },
    })

    dispatch(getVrCandidateList())
  }

export const resetVrCandidateList = () => dispatch =>
  dispatch({ type: getActionType(RESET, VR_CANDIDATE, LIST) })
