import classNames from "classnames"
import styles from "./picker-pagination-button.module.scss"
import PropTypes from "prop-types"
import React from "react"

const cn = classNames.bind(styles)

export const PickerPaginationButton = props => {
  const { disabled, active, onPageChange, page } = props
  return (
    <button
      className={cn([styles.button, { [styles.button_active]: active }])}
      onClick={onPageChange ? () => onPageChange(page) : null}
      disabled={disabled}
    >
      {props.children}
    </button>
  )
}

PickerPaginationButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  page: PropTypes.number,
  active: PropTypes.bool,
  onPageChange: PropTypes.func,
}
