import React from "react"
import PropTypes from "prop-types"
import { DropdownToggle as BDropdownToggle } from "reactstrap"

export function DropdownToggle(props) {
  const { loading, disabled, children, ...rest } = props
  return (
    <BDropdownToggle {...rest} disabled={disabled}>
      {loading && <i className="fas fa-circle-notch fa-spin mr-1" />}
      {children}
    </BDropdownToggle>
  )
}

DropdownToggle.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onClick: PropTypes.func,
  size: PropTypes.string,
}

DropdownToggle.defaultProps = {}
