import { createSelector } from "reselect"

export const stateSelector = state => state.atsCompanyIntegrationOpenJobs

export const countSelector = createSelector(stateSelector, state => state.count)

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)

export const atsCompanyIntegrationOpenJobIdsSelector = createSelector(
  stateSelector,
  state => state.openJobs,
)

export const atsCompanyIntegrationOpenJobsSelector = createSelector(
  entitiesSelector,
  atsCompanyIntegrationOpenJobIdsSelector,
  (entities, ids) => ids.map(id => entities[id]),
)
