export const REQUEST = "REQUEST"
export const START = "START"
export const STOP = "STOP"
export const SUCCESS = "SUCCESS"
export const FAIL = "FAIL"
export const PENDING = "PENDING"
export const LAST = "LAST"
export const SUPPORTED = "SUPPORTED"

export const GET = "GET"
export const LIST = "LIST"
export const CREATE = "CREATE"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const FROM = "FROM"
export const TO = "TO"
export const INVITE = "INVITE"
export const PLAY = "PLAY"
export const CLOSE = "CLOSE"
export const FINISH = "FINISH"
export const IMPORT = "IMPORT"

export const CAMPAIGN = "CAMPAIGN"
export const CANDIDATE = "CANDIDATE"
export const VR_CANDIDATE = "VR_CANDIDATE"
export const JOB = "JOB"
export const REFERRED = "REFERRED"
export const MATCH = "MATCH"
export const AGENCY = "AGENCY"
export const USER = "USER"
export const MANAGER = "MANAGER"
export const COMPANY = "COMPANY"
export const BILLING = "BILLING"
export const BY = "BY"
export const ID = "ID"
export const MESSAGE = "MESSAGE"
export const MATCHES = "MATCHES"
export const ACCEPTED = "ACCEPTED"
export const APPROVED = "APPROVED"
export const OPEN = "OPEN"
export const TEAM = "TEAM"
export const SOUND = "SOUND"
export const SEARCH = "SEARCH"
export const ALERT = "ALERT"
export const SHOW = "SHOW"
export const HIDE = "HIDE"
export const LOCALE = "LOCALE"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const RESET = "RESET"
export const PASSWORD = "PASSWORD"
export const ACCOUNT = "ACCOUNT"
export const REFRESH = "REFRESH"
export const TOKEN = "TOKEN"
export const RESEND = "RESEND"
export const EMAIL = "EMAIL"
export const CONFIRMATION = "CONFIRMATION"
export const CONFIRM = "CONFIRM"
export const RECOVER = "RECOVER"
export const PAU = "PAU"
export const REV = "REV"
export const DEL = "DEL"
export const ACT = "ACT"
export const THREAD = "THREAD"
export const ACTIVATE = "ACTIVATE"
export const NEW = "NEW"
export const MO = "MO"
export const MOBERRIES = "MOBERRIES"
export const WS = "WS"
export const API = "API"
export const CONNECT = "CONNECT"
export const PAGE = "PAGE"
export const RELOAD = "RELOAD"
export const RELEASE = "RELEASE"
export const LOGROCKET = "LOGROCKET"
export const ON = "ON"
export const OFF = "OFF"
export const SLACK = "SLACK"
export const LEVER = "LEVER"
export const INTEGRATION = "INTEGRATION"
export const REDIRECT = "REDIRECT"
export const OAUTH = "OAUTH"
export const ATS = "ATS"
export const SR = "SR"
export const POSTING = "POSTING"
export const FEED = "FEED"
export const PREDICTION = "PREDICTION"
export const CURRENT = "CURRENT"
export const QUEUE = "QUEUE"
export const DEQUEUE = "DEQUEUE"
export const EDITOR = "EDITOR"
export const STATE = "STATE"
export const SET = "SET"
export const SELECT = "SELECT"
export const SELECTED = "SELECTED"
export const NEXT = "NEXT"
export const ACCEPT = "ACCEPT"
export const DECLINE = "DECLINE"
export const APPROVE = "APPROVE"
export const REJECT = "REJECT"
export const PRICE = "PRICE"
export const PRODUCT = "PRODUCT"
export const MARK = "MARK"
export const READ = "READ"
export const AS = "AS"
export const SUBSCRIPTION = "SUBSCRIPTION"
export const CANCEL = "CANCEL"
export const RENEW = "RENEW"
export const ORDER = "ORDER"
export const ADD = "ADD"
export const MODAL = "MODAL"
export const AUTO = "AUTO"
export const PAUSED = "PAUSED"
export const JOBS = "JOBS"
export const INVOICE = "INVOICE"
export const REFERRAL = "REFERRAL"
export const CARD = "CARD"
export const SURVEY = "SURVEY"
export const STATUS = "STATUS"
export const PERMISSION = "PERMISSION"
export const PROVIDER = "PROVIDER"
export const UNINTEGRATED = "UNINTEGRATED"
export const CONNECTION = "CONNECTION"
export const FLOW = "FLOW"
export const REJECTED = "REJECTED"
export const REASONS = "REASONS"
export const CONDITIONS = "CONDITIONS"
export const APP = "APP"
export const VERSIONS = "VERSIONS"
export const FEEDBACK = "FEEDBACK"
export const SUMMARY = "SUMMARY"
export const APPLICATIONS = "APPLICATIONS"
export const STATISTICS = "STATISTICS"
export const MIN = "MIN"
export const TEMPLATE = "TEMPLATE"
export const REWARDS = "REWARDS"
export const DRAFT = "DRAFT"
export const TIME = "TIME"
export const SERIES = "SERIES"
export const CANDIDATES = "CANDIDATES"
export const CATEGORIES = "CATEGORIES"
export const BUTTON_TAGNAME = "button"

export const LOCALES = ["en", "de"]

export const MATCH_STATUSES = {
  CANDIDATE_PENDING: 0,
  CANDIDATE_DECLINED: 1,
  CANDIDATE_ACCEPTED: 2,
  COMPANY_DECLINED: 3,
  COMPANY_ACCEPTED: 4,
  CANDIDATE_EXPIRED: 5,
  COMPANY_EXPIRED: 6,
  COMPANY_REJECTED: 7,
  COMPANY_APPROVED: 8,
  EXPORTED: 9,
}

export const FEEDBACK_SETS = {
  COMPANY_DECLINE_REASONS: 2,
  COMPANY_REJECT_REASONS: 3,
  SUBSCRIPTION_CANCELLATION_REASONS: 5,
}

export const PROBLEMS = {
  NONE: "NONE",
  CLIENT_ERROR: "CLIENT_ERROR",
  SERVER_ERROR: "SERVER_ERROR",
  TIMEOUT_ERROR: "TIMEOUT_ERROR",
  CONNECTION_ERROR: "CONNECTION_ERROR",
  NETWORK_ERROR: "NETWORK_ERROR",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  CANCEL_ERROR: "CANCEL_ERROR",
}

export const DURATIONS = {
  DEFAULT: 5000,
  MEDIUM: 10000,
}

export const TIMEOUT_ERROR_CODES = ["ECONNABORTED"]
export const NODEJS_CONNECTION_ERROR_CODES = [
  "ENOTFOUND",
  "ECONNREFUSED",
  "ECONNRESET",
]

export const MONTHLY_REFERRAL_THRESHOLD = 10

export const ACCENT_COLORS = [
  "#e4cfce",
  "#e6dccf",
  "#e1e6cf",
  "#d3e6cf",
  "#cfe6d8",
  "#cfe6e6",
  "#cfd8e6",
  "#d3cfe7",
  "#e2cfe7",
  "#e5dacf",
]

export const COLORS = {
  GRAY_100: "#f8f9fa",
  GRAY_200: "#e9ecef",
  GRAY_300: "#dee2e6",
  GRAY_500: "#adb5bd",
  GRAY_600: "#6c757d",
  GRAY_700: "#495057",
  GRAY_900: "#212529",
  MAASTRICHTBLUE: "#011627",
  MALIBU: "#99d5fa",
  ALICEBLUE: "#f5faff",
  DODGERBLUE: "#20a4f3",
  RED: "#fe5f55",
  // todo make uppercase
  primary: "#0738da",
  gunmetal: "#262d3d",
  manatee: "#969cac",
  getWhite: (opacity = 1) => `rgba(255,255,255,${opacity})`,
  getBlack: (opacity = 1) => `rgba(0,0,0,${opacity})`,
}

export const STYLES = {
  NONE: "none",
  LEFT: "left",
  DEFAULT: "default",
  POINTER: "pointer",
  SOLID: "solid",
  SHADOW_RED: "0 0 0 0.125rem rgb(254 95 85 / 25%)",
  SHADOW_BLUE: "0 0 0 0.125rem rgb(32 164 243 / 25%)",
  SHADOW_GRAY: "0 0 4px 0 #dee2e6",
}

export const USER_GROUPS = {
  MANAGER: "manager",
  SUPER_GROUP: "super_group",
}

export const KeyCodes = {
  Enter: 13,
}

export const SEARCH_DEBOUNCE_TIMEOUT = 400

export const AVAILABLE_JOB_SLOTS = [5, 10, 20, 10000]

export const AVAILABLE_DURATIONS = [12, 24]

export const ATS_JOB_SYNC_OPTIONS = {
  MANUAL: "MANUAL",
  AUTO: "AUTO",
}

export const ATS_APPLICATION_SYNC_OPTIONS = {
  ON_COM_ACCEPT: "onComAccept",
  ON_CAN_ACCEPT: "onCanAccept",
}

export const SUBSCRIPTION_STARTING_PRICES = {
  pph: 1000,
  monthly: 345,
}

export const SUBSCRIPTION_TYPES = {
  pph: "pay_hire",
  main: "main",
}

export const JOB_CONDITION_TYPES = {
  pph: "pph",
  flat: "flat",
}

export const INTEGRATION_STATE_FILTER_OPTIONS = {
  ALL: { value: "all", name: "app.page.integrations.import.filter.showAll" },
  CONNECTED: {
    value: "connected",
    name: "app.page.integrations.import.filter.showConnected",
  },
  NOT_CONNECTED: {
    value: "notConnected",
    name: "app.page.integrations.import.filter.showNotConnected",
  },
}

export const MATCH_ORDERING = {
  EXPIRES_AT: "expires_at",
}

export const DASHBOARD_MATCHES_LIMIT = 5
export const MATCH_LIST_MATCHES_LIMIT = 10
export const JOBS_PAGE_JOBS_LIMIT = 10
export const MATCHING_PER_JOB_JOBS_LIMIT = 10
export const REFERRED_CANDIDATES_LIMIT = 10

export const ATS_JOB_STATUSES_COLOR_MAP = {
  ACT: "success",
  REV: "warning",
  PAU: "primary",
  DEL: "danger",
}

export const FEEDBACK_COMPACT_VIEW_OPTIONS = [
  "wrong_industry",
  "not_qualified",
  "overqualified",
  "language_skills_mismatch",
  "high_expectations",
  "differs_from_job_location",
  "no_visa",
  "position_not_available",
]

export const ALERT_COLORS = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
}

export const PAYMENT_METHODS = {
  CARD: "card",
  INVOICE: "invoice",
}

export const MESSAGE_TEMPLATE_TAGS = {
  CANDIDATE_NAME: "#candidate_name",
  CANDIDATE_LAST_NAME: "#candidate_last_name",
  JOB_TITLE: "#job_title",
  MANAGER_NAME: "#my_first_name",
  MANAGER_LAST_NAME: "#my_last_name",
  COMPANY_NAME: "#company_name",
  COMPANY_CITY: "#company_city",
}

export const MESSAGES_PER_PAGE = 20
export const THREADS_PER_PAGE = 10

export const APPLICATION_QUESTION_TYPES = {
  SHORT_TEXT: "short_text",
  LONG_TEXT: "long_text",
  SINGLE_SELECTION: "single_selection",
  MULTIPLE_SELECTION: "multiple_selection",
  BOOLEAN: "boolean",
}
