import { createSelector } from "reselect"
import { and } from "ramda"
import { isSubscriptionUpfront } from "../lib/helpers"
import { SUBSCRIPTION_TYPES } from "../constants"

export const stateSelector = state => state.subscription

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading,
)

export const loadingErrSelector = createSelector(
  stateSelector,
  state => state.loadingErr,
)

export const countSelector = createSelector(stateSelector, state => state.count)

export const subscriptionsSelector = createSelector(
  entitiesSelector,
  entities => Object.values(entities),
)

export const subscriptionByIdSelector = createSelector(
  entitiesSelector,
  (state, { id }) => id,
  (entities, id) => entities[id],
)

export const payPerHireSubscriptionSelector = createSelector(
  subscriptionsSelector,
  subscription =>
    subscription.find(element => element.type === SUBSCRIPTION_TYPES.pph),
)

export const createSubscriptionSelector = ({ upfront, duration, jobSlots }) =>
  createSelector(subscriptionsSelector, subscriptions =>
    subscriptions.find(s =>
      and(
        and(s.type === SUBSCRIPTION_TYPES.main, s.jobsMax === jobSlots),
        and(
          s.durationIntervalCount === duration,
          isSubscriptionUpfront(s) === upfront,
        ),
      ),
    ),
  )
