import React from "react"
import defaultImage from "../../assets/img/default-empty-state.svg"

export function InvoiceListEmpty() {
  return (
    <div className="text-center">
      <img alt="Empty" width={400} className="img-fluid" src={defaultImage} />
    </div>
  )
}

InvoiceListEmpty.propTypes = {}
InvoiceListEmpty.defaultProps = {}
