import isLength from "validator/lib/isLength"
import { defineMessages } from "react-intl"

const messages = defineMessages({
  valueRequired: { id: "app.validators.value.required" },
  maxLen: { id: "app.validators.length.max" },
  minLen: { id: "app.validators.length.min" },
})

export const paymentCardValidator = values => {
  const errors = {}

  const minNameOptions = { min: 1 }
  const maxNameOptions = { max: 50 }

  if (!values.holderName) {
    errors.holderName = messages.valueRequired
  } else if (!isLength(values.holderName, minNameOptions)) {
    errors.holderName = { ...messages.minLen, values: minNameOptions }
  } else if (!isLength(values.holderName, maxNameOptions)) {
    errors.holderName = { ...messages.maxLen, values: maxNameOptions }
  }

  if (!values.cardTerms) {
    errors.cardTerms = messages.valueRequired
  }

  if (!values.isCardComplete) {
    errors.isCardComplete = messages.valueRequired
  }

  return errors
}
