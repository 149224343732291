import { createSelector } from "reselect"
import { head } from "ramda"

export const stateSelector = state => state.slackIntegration

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading,
)

export const isConnectingSelector = createSelector(
  stateSelector,
  state => state.isConnecting,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)

export const slackIntegrationsSelector = createSelector(
  entitiesSelector,
  entities => Object.values(entities),
)

export const slackIntegrationSelector = createSelector(
  slackIntegrationsSelector,
  slackIntegrations => head(slackIntegrations),
)
