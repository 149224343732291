import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { complement, pathOr } from "ramda"
import { Expires } from "../common/expires-component"
import { FormattedMessage } from "react-intl"
import { MatchLabels } from "./match-labels-component"
import defaultLogo from "../../assets/img/default-logo.png"
import { Matched } from "../common/matched-component"
import { UncontrolledPopover, PopoverBody, Media } from "reactstrap"
import {
  getFullName,
  shouldRenderExpire,
  isPendingMatch,
  shouldShowMatchLabels,
  shouldRenderMatched,
} from "../../lib/helpers"
import { CandidateLocation } from "../candidate/candidate-location-component"
import { ImageScore } from "../common/image-score-component"
import chevronRight from "../../assets/img/chevron-right.svg"
import styles from "./basic-match-card-component.module.scss"

const isNotPendingMatch = complement(isPendingMatch)

const shouldShowMatchPicture = isNotPendingMatch
const shouldShowMatchControls = isNotPendingMatch

const shouldHideMatchCity = isPendingMatch
const shouldShortenMatchFirstName = isPendingMatch

export const BasicMatchCard = ({
  match,
  readOnly,
  buttonLabel,
  popover,
  page,
}) => {
  const name = getFullName(
    match.candidate,
    readOnly,
    shouldShortenMatchFirstName(match),
  )
  const job = pathOr("", ["job", "title"], match)
  const percentage = Math.round(Number(match?.score) * 100) || 23

  return (
    <Media
      className={styles.container}
      id={`read-only-match-card-popover-${match.id}`}
    >
      <Media left className="mr-3">
        <Link
          className="text-decoration-none"
          to={{
            pathname: `/match/${match.id}`,
            state: { fromPage: page },
          }}
        >
          <ImageScore
            percentageIsVisible={false}
            score={match.score}
            picture={
              shouldShowMatchPicture(match)
                ? pathOr(defaultLogo, ["candidate", "picture"], match)
                : defaultLogo
            }
            alt={name}
          />
        </Link>
      </Media>
      <Media body>
        <small className={styles.job}>{job}</small>
        <div className="d-flex flex-wrap">
          <Media className="clickable m-0 text-break h5">
            {readOnly ? (
              <span className={styles.name}>{name}</span>
            ) : (
              <Link
                className={styles.name}
                to={{
                  pathname: `/match/${match.id}`,
                  state: { fromPage: page },
                }}
              >
                {name}
              </Link>
            )}
          </Media>
        </div>
        <CandidateLocation
          candidate={match.candidate}
          onlyCountry={shouldHideMatchCity(match)}
        />
        <div className={styles.containerMatch}>
          <small className={styles.percentage}>{`${percentage}%`}</small>
          <small className={styles.match}>
            <FormattedMessage id="app.page.dashboard.matches.match" />
          </small>
          <span className="tags-wrapper ml-1">
            {shouldShowMatchLabels(match) && <MatchLabels match={match} />}
          </span>
        </div>
      </Media>
      {shouldShowMatchControls(match) && (
        <div className={styles.containerReview}>
          {shouldRenderExpire(match) && (
            <div className="mr-1">
              <Expires
                className={styles.expires}
                small
                expiresAt={match.expiresAt}
              />
            </div>
          )}
          {shouldRenderMatched(match) && (
            <div className="mr-1">
              <Matched match={match} />
            </div>
          )}
          {!readOnly && (
            <Link
              className={styles.review}
              to={{
                pathname: `/match/${match.id}`,
                state: { fromPage: page },
              }}
            >
              <img src={chevronRight} alt="Review" />
            </Link>
          )}
          {readOnly && (
            <UncontrolledPopover
              placement="top"
              target={`read-only-match-card-popover-${match.id}`}
              trigger="legacy"
            >
              <PopoverBody>{popover}</PopoverBody>
            </UncontrolledPopover>
          )}
        </div>
      )}
    </Media>
  )
}

BasicMatchCard.propTypes = {
  match: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  buttonLabel: PropTypes.node,
  popover: PropTypes.node,
  page: PropTypes.number,
}

BasicMatchCard.defaultProps = {
  buttonLabel: (
    <FormattedMessage id="app.page.dashboard.matches.review.button" />
  ),
  popover: (
    <FormattedMessage id="app.page.dashboard.matches.review.blocked.hint" />
  ),
  match: {},
  readOnly: false,
}
