import React from "react"
import PropTypes from "prop-types"
import { Media } from "reactstrap"
import cn from "classnames"
import { Img } from "../common/img-component"
import seapig, { OPTIONALS } from "seapig"

export function IntegrationCard({ picture, className, tags, children, title }) {
  const css = cn("bg-white p-3", className)
  const { contentChildren, controlsChildren, tagsChildren } = seapig(children, {
    content: OPTIONALS,
    controls: OPTIONALS,
    tags: OPTIONALS,
  })
  return (
    <Media className={css}>
      <div className="shadow-sm p-1 rounded mr-3">
        <Img
          alt={title}
          width="80"
          height="80"
          className="user-picture"
          src={picture}
        />
      </div>
      <Media body>
        <h3>{title}</h3>
        {tagsChildren}
        {contentChildren}
        {controlsChildren}
      </Media>
    </Media>
  )
}

IntegrationCard.propTypes = {
  picture: PropTypes.string,
  className: PropTypes.string.isRequired,
  tags: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
}

IntegrationCard.defaultProps = {
  picture: "",
  title: "",
  content: "",
  className: "",
}
