import React, { Fragment } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { DetailViewAttribute } from "../detail-view/detail-view-attribute-component"
import { FormattedMessage } from "react-intl"
import {
  formatSalary,
  getCareerLevelName,
  getJobTypesDiff,
  getAvailabilityName,
} from "../../lib/helpers"
import { JOB_TYPES } from "../../default-options"

export const MatchJobClaimsComparison = ({ candidate, job }) => {
  const {
    matched: matchedJobTypes,
    missing: missingJobTypes,
    rest: otherJobTypes,
  } = getJobTypesDiff(candidate.jobTypes, job.jobTypes)

  const jobTypesWithClasses = [
    ...matchedJobTypes.map(t => ({ id: t, className: "text-success" })),
    ...otherJobTypes.map(t => ({ id: t })),
    ...missingJobTypes.map(t => ({ id: t })),
  ]
  return (
    <Fragment>
      <DetailViewAttribute
        title={<FormattedMessage id="app.page.jobDetails.salaryExpectation" />}
      >
        <span
          className={cn({
            "text-success":
              job.salaryMax > 0 && candidate.salaryMin <= job.salaryMax,
            "text-danger":
              job.salaryMax > 0 && candidate.salaryMin > job.salaryMax,
          })}
        >
          {formatSalary(candidate.salaryMin, 0)}
        </span>
      </DetailViewAttribute>
      <DetailViewAttribute
        title={
          <FormattedMessage id="app.page.jobDetails.jobClaims.availability" />
        }
      >
        <span>{getAvailabilityName(candidate.availability)}</span>
      </DetailViewAttribute>
      <DetailViewAttribute
        title={
          <FormattedMessage id="app.page.jobDetails.jobClaims.careerLevel" />
        }
      >
        <span
          className={cn({
            "text-success":
              Number(candidate.careerLevel) >= Number(job.careerLevel),
            "text-danger":
              Number(candidate.careerLevel) < Number(job.careerLevel),
          })}
        >
          {getCareerLevelName(candidate.careerLevel)}
        </span>
      </DetailViewAttribute>
      <DetailViewAttribute
        title={<FormattedMessage id="app.page.jobDetails.jobClaims.jobTypes" />}
      >
        {jobTypesWithClasses.map((j, idx) => (
          <span key={j.id} className={j.className}>
            <FormattedMessage id={JOB_TYPES.find(jt => jt.id === j.id).name} />
            {idx !== jobTypesWithClasses.length - 1 && ", "}
          </span>
        ))}
      </DetailViewAttribute>
    </Fragment>
  )
}

MatchJobClaimsComparison.propTypes = {
  candidate: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
}
