import React from "react"
import { FormattedMessage } from "react-intl"
import { MonetizationInvite } from "./monetization-invite-component"
import { FormattedLink } from "../common/formatted-link-component"

export const MonetizationInviteWidget = () => {
  return (
    <div className="bg-white p-3 rounded">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-users" />
        </div>
        <FormattedMessage id="app.widget.monetization.invite.title" />
      </h5>
      <FormattedMessage id="app.widget.monetization.text" />

      <div className="mb-2">
        <FormattedLink
          href="talentPoolGuide"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="app.widget.monetization.moreInfo" />
        </FormattedLink>
      </div>
      <MonetizationInvite />
    </div>
  )
}
