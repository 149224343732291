import * as yup from "yup"
import { requiredEmailSchema } from "./shared-schemas"

export const newEmailSchema = yup.object().shape({
  email: requiredEmailSchema.notOneOf(
    [yup.ref("currentEmail")],
    "app.validators.email.equal",
  ),
})

const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 30

const passwordSchema = yup
  .string()
  .min(PASSWORD_MIN_LENGTH, {
    id: "app.validators.length.min",
    values: {
      min: PASSWORD_MIN_LENGTH,
    },
  })
  .max(PASSWORD_MAX_LENGTH, "app.validators.length.max")
  .required("app.validators.value.required")

export const loginSchema = yup.object().shape({
  email: requiredEmailSchema.max(50, "app.validators.length.max"),
  password: passwordSchema,
})

export const emailFormSchema = yup.object().shape({
  email: requiredEmailSchema,
})

export const doublePasswordSchema = yup.object().shape({
  password: passwordSchema,
  password2: passwordSchema.when("password", (password, schema) =>
    schema.oneOf([password], "app.validators.password.equal"),
  ),
})

export const newPasswordSchema = yup.object().shape({
  password: passwordSchema,
  passwordNew: passwordSchema.when("password", (password, schema) =>
    schema.notOneOf([password], "app.validators.passwords.notEqual"),
  ),
})

export const activateSchema = yup.object().shape({
  password: passwordSchema,
  password2: passwordSchema.when("password", (password, schema) =>
    schema.oneOf([password], "app.validators.password.equal"),
  ),
  terms: yup.boolean().oneOf([true], "app.validators.value.required"),
  privacy: yup.boolean().oneOf([true], "app.validators.value.required"),
})
