import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

import { COLORS } from "../../../constants"
import PropTypes from "prop-types"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// TODO: future-improvements: Mutation: mutate VerticalBar func to support charts from Strategy Builder Page and remove VerticalBarV2

export function VerticalBar(props) {
  const { data, labels, options } = props

  const max = Math.max(...data[0])

  return (
    <Bar
      options={{
        indexAxis: "x",
        interaction: { mode: "nearest", intersect: false, axis: "xy" },
        hover: {
          mode: "nearest",
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "nearest",
            titleAlign: "center",
            padding: 14,
            backgroundColor: COLORS.getWhite(),
            titleColor: COLORS.manatee,
            titleFont: { size: 12, weight: "normal" },
            titleMarginBottom: 10,
            bodyColor: COLORS.getBlack(),
            bodyAlign: "center",
            bodyFont: { size: 18, weight: "bold", lineHeight: 1 },
            caretPadding: 16,
            caretSize: 10,
            cornerRadius: 10,
            borderColor: COLORS.getBlack(0.06),
            borderWidth: 1,
            displayColors: false,
            callbacks: {
              title(tooltipItems) {
                return tooltipItems[0].label.split(",")
              },
            },
          },
        },
        scales: {
          y: {
            min: 0,
            max: max < 10 ? 10 : Math.ceil(max / 10) * 10,
            grid: {
              tickLength: 0,
              drawBorder: false,
            },
            ticks: {
              count: max <= 10 ? undefined : 5,
              stepSize: max <= 10 ? 5 : undefined,
              precision: 0,
              crossAlign: "far",
              color: COLORS.getBlack(0.3),
            },
          },
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              color: COLORS.getBlack(0.3),
              font: {
                size: 10,
              },
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: data[0],
            maxBarThickness: 10,
            minBarLength: 9,
            borderRadius: 10,
            backgroundColor: options.backgroundColor,
          },
        ],
      }}
      height={190}
    />
  )
}

export const VerticalBarV2 = ({ configs, labels, datasets }) => {
  const options = {
    indexAxis: "x",
    interaction: { mode: "nearest", intersect: false, axis: "xy" },
    hover: {
      mode: "nearest",
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "nearest",
        titleAlign: "center",
        padding: 14,
        backgroundColor: COLORS.getWhite(),
        titleColor: COLORS.manatee,
        titleFont: { size: 12, weight: "normal" },
        titleMarginBottom: 10,
        bodyColor: COLORS.getBlack(),
        bodyAlign: "center",
        bodyFont: { size: 18, weight: "bold", lineHeight: 1 },
        caretPadding: 16,
        caretSize: 10,
        cornerRadius: 10,
        borderColor: COLORS.getBlack(0.06),
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          label: configs.callbackLabel,
        },
      },
    },
    scales: {
      y: {
        grid: {
          tickLength: 0,
          drawBorder: false,
        },
        ticks: {
          crossAlign: "far",
          color: COLORS.getBlack(0.3),
          callback: configs.callbackY,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: COLORS.getBlack(0.3),
        },
      },
    },
  }

  const data = {
    labels,
    datasets: datasets.map(dataset => {
      return {
        label: dataset.label,
        data: dataset.data,
        backgroundColor: dataset.backgroundColor,
        maxBarThickness: 10,
        minBarLength: 9,
        borderRadius: 10,
      }
    }),
  }

  return <Bar options={options} data={data} height={200} />
}

VerticalBar.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  options: PropTypes.object,
}

VerticalBarV2.propTypes = {
  configs: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.object.isRequired,
}
