import React from "react"
import PropTypes from "prop-types"
import { PaginationItem, PaginationLink } from "reactstrap"
import cn from "classnames"

import styles from "./pagination-item-dashboard.module.scss"

export const PaginationItemDashboard = ({
  key,
  disabled,
  active,
  tag,
  to,
  first,
  last,
  previous,
  next,
  children,
}) => {
  return (
    <PaginationItem
      className={cn({ [styles.pageActive]: active })}
      key={key}
      disabled={disabled}
      active={active}
    >
      <PaginationLink
        className={styles.pageLink}
        tag={tag}
        to={to}
        first={first}
        last={last}
        previous={previous}
        next={next}
      >
        {children}
      </PaginationLink>
    </PaginationItem>
  )
}

PaginationItemDashboard.propTypes = {
  key: PropTypes.number,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  tag: PropTypes.node,
  to: PropTypes.func,
  first: PropTypes.bool,
  last: PropTypes.bool,
  previous: PropTypes.bool,
  next: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

// TODO: remove-warning: add default props for key, tag and to, to remove warnings for reactstrap pagination components
PaginationItemDashboard.defaultProps = {}
