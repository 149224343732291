import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Img } from "./img-component"
import { FormattedMessage } from "react-intl"
import { min } from "ramda"

export const ImageScore = ({ score, picture, alt, percentageIsVisible }) => {
  const percentage = Math.round(Number(score) * 100) || 23
  const animationDelay = `-${min(99.99, percentage)}s`

  return (
    <Fragment>
      <div className="match-chart" style={{ animationDelay }}>
        {/* TODO: make the image 74x74 with width-height changes to "match-chart" css to make it pixel perfect */}
        <Img
          src={picture}
          alt={alt}
          width="80"
          height="80"
          className="user-picture"
        />
      </div>
      {percentageIsVisible && (
        <div className="text-center text-success text-smaller mt-1">
          <b>
            <FormattedMessage
              id="app.page.match.matchPercentage"
              values={{ percentage }}
            />
          </b>
        </div>
      )}
    </Fragment>
  )
}

ImageScore.propTypes = {
  score: PropTypes.string.isRequired,
  picture: PropTypes.string,
  alt: PropTypes.string,
  percentageIsVisible: PropTypes.bool,
}

ImageScore.defaultProps = {
  alt: "Candidate",
  percentageIsVisible: true,
}
