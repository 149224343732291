import React from "react"
import { DonutChartLoader } from "../../../loaders/chart-loaders/donut-chart-loader"
import { FormattedMessage } from "react-intl"
import styles from "./candidate-chart-skeleton.module.scss"

export const CandidateChartSkeleton = () => {
  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="app.page.analytics.charts.candidates.name" />
      </div>
      <div className={styles.loader}>
        <DonutChartLoader />
      </div>
    </div>
  )
}
