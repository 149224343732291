import { always, evolve, F, mergeLeft, T } from "ramda"
import {
  VR_CANDIDATE,
  FAIL,
  GET,
  LIST,
  START,
  SUCCESS,
  SET,
  STATUS,
  RESET,
  SEARCH,
  SELECT,
  CAMPAIGN,
} from "../constants"
import { arrayToObject, getActionType } from "../lib/helpers"

export const defaultState = {
  isLoading: false,
  statusIsUpdating: false,
  vrCandidates: [],
  entities: {},
  count: 0,
  search: "",
  campaign: null,
}

export const reducer = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case getActionType(SELECT, VR_CANDIDATE, CAMPAIGN):
      return evolve(
        {
          campaign: always(payload.campaign),
        },
        state,
      )

    case getActionType(GET, VR_CANDIDATE, LIST, START):
      return evolve(
        {
          isLoading: T,
        },
        state,
      )

    case getActionType(GET, VR_CANDIDATE, LIST, SUCCESS):
      return evolve(
        {
          isLoading: F,
          err: always(null),
          count: always(payload.count),
          vrCandidates: always(payload.candidates.map(c => c.email)),
          entities: mergeLeft(arrayToObject(payload.candidates, "email")),
        },
        state,
      )

    case getActionType(GET, VR_CANDIDATE, LIST, FAIL):
      return evolve(
        {
          isLoading: F,
          err: always(payload.err),
        },
        state,
      )

    case getActionType(SET, VR_CANDIDATE, STATUS, START):
      return evolve(
        {
          statusIsUpdating: T,
        },
        state,
      )

    case getActionType(SET, VR_CANDIDATE, STATUS, SUCCESS):
      return evolve(
        {
          statusIsUpdating: F,
          err: always(null),
        },
        state,
      )

    case getActionType(SET, VR_CANDIDATE, STATUS, FAIL):
      return evolve(
        {
          statusIsUpdating: F,
          err: always(payload.err),
        },
        state,
      )

    case getActionType(RESET, VR_CANDIDATE, LIST):
      return evolve(
        {
          isLoading: F,
          err: always(null),
          count: always(0),
          vrCandidates: always([]),
          entities: always({}),
          search: always(""),
          campaign: null,
        },
        state,
      )

    case getActionType(SET, VR_CANDIDATE, SEARCH):
      return evolve(
        {
          search: always(payload.search),
        },
        state,
      )

    default:
      return state
  }
}
