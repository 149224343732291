import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Media } from "reactstrap"
import { Img } from "../common/img-component"
import cn from "classnames"
import { Expires } from "../common/expires-component"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"
import {
  getCareerLevelName,
  getFullName,
  getLocationName,
} from "../../lib/helpers"

export const NextMatchCard = ({
  nextMatch,
  referenceMatch,
  nextCandidate,
  className,
}) => {
  if (!referenceMatch || !nextCandidate || !nextMatch) {
    return null
  }
  return (
    <div className={cn("bg-white rounded p-3", className)}>
      <h5 className="m-0 mb-3">
        <span className="icon-circled mr-2">
          <i className="fas fa-user-check" />
        </span>
        <FormattedMessage id="app.page.matchList.sidebar.nextMatch" />
      </h5>
      <Media className="mb-2 align-items-center border-bottom border-top py-2">
        <Img
          alt="User"
          width="40"
          height="40"
          className="rounded-circle mr-3"
          src={nextCandidate.picture}
        />
        <Media body>
          <b>{getFullName(nextCandidate, true)}</b>
        </Media>
      </Media>
      <ul className="list-unstyled icon-list">
        <Media tag="li" className="mb-1">
          <i className="fas fa-map-marker-alt text-muted" />
          <Media body>{getLocationName(nextCandidate.city)}</Media>
        </Media>
        <Media tag="li" className="mb-1">
          <i className="fas fa-chart-bar text-muted" />
          <Media body>{getCareerLevelName(nextCandidate.careerLevel)}</Media>
        </Media>
        <Media tag="li" className="mb-1">
          <i className="fas fa-briefcase text-muted" />
          <Media body>
            {nextCandidate.jobRoles.map((jr, idx) => (
              <div key={jr.id}>{jr.name}</div>
            ))}
          </Media>
        </Media>
        <Media tag="li" className="mb-1">
          <i className="fas fa-calendar text-muted" />
          <Media body>
            <Expires expiresAt={nextMatch.expiresAt} />
          </Media>
        </Media>
      </ul>
      <Button tag={Link} to={`/match/${nextMatch.id}`} color="primary" block>
        <FormattedMessage id="app.common.review" />
      </Button>
    </div>
  )
}

NextMatchCard.propTypes = {
  referenceMatch: PropTypes.object.isRequired,
  nextMatch: PropTypes.object,
  nextCandidate: PropTypes.object,
  className: PropTypes.string,
}
