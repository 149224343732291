import { arrayToObject, getActionType } from "../lib/helpers"
import {
  evolve,
  F,
  T,
  always,
  mergeLeft,
  lensProp,
  over,
  omit,
  dec,
  inc,
  prop,
} from "ramda"
import {
  SUCCESS,
  GET,
  LOGOUT,
  ATS,
  INTEGRATION,
  LIST,
  FAIL,
  START,
  COMPANY,
  CREATE,
  UPDATE,
  DELETE,
  PROVIDER,
} from "../constants"

export const defaultState = {
  isListLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  err: null,
  providerErr: null,
  entities: {},
  count: null,
  isProviderLoading: false,
  isProvidersListLoading: false,
  providersEntities: {},
  providersCount: null,
  providers: [],
}

export const reducer = function (state = defaultState, action) {
  const { type, payload } = action
  switch (type) {
    case getActionType(GET, ATS, PROVIDER, LIST, START):
      return evolve({ isProvidersListLoading: T }, state)

    case getActionType(GET, ATS, PROVIDER, LIST, SUCCESS):
      return evolve(
        {
          isProvidersListLoading: F,
          providers: always(payload.providers.map(prop("slug"))),
          providersEntities: mergeLeft(
            arrayToObject(payload.providers, "slug"),
          ),
          providersCount: always(payload.count),
          providerErr: always(null),
        },
        state,
      )

    case getActionType(GET, ATS, PROVIDER, LIST, FAIL):
      return evolve(
        { isProvidersListLoading: F, providerErr: always(payload.err) },
        state,
      )

    case getActionType(GET, ATS, PROVIDER, START):
      return evolve({ isProviderLoading: T }, state)

    case getActionType(GET, ATS, PROVIDER, SUCCESS):
      return evolve(
        {
          isProviderLoading: F,
          providersEntities: over(
            lensProp(payload.provider.slug),
            mergeLeft(payload.provider),
          ),
          providerErr: always(null),
        },
        state,
      )

    case getActionType(GET, ATS, PROVIDER, FAIL):
      return evolve(
        { isProviderLoading: F, providerErr: always(payload.err) },
        state,
      )

    case getActionType(GET, ATS, COMPANY, INTEGRATION, LIST, START):
      return evolve({ isListLoading: T }, state)

    case getActionType(GET, ATS, COMPANY, INTEGRATION, LIST, SUCCESS):
      return evolve(
        {
          isListLoading: F,
          err: always(null),
          entities: mergeLeft(arrayToObject(payload.integration, "id")),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, ATS, COMPANY, INTEGRATION, LIST, FAIL):
      return evolve({ isListLoading: F, err: always(payload.err) }, state)

    case getActionType(CREATE, ATS, COMPANY, INTEGRATION, START):
      return evolve({ isCreating: T }, state)

    case getActionType(CREATE, ATS, COMPANY, INTEGRATION, SUCCESS):
      return evolve(
        {
          isCreating: F,
          err: always(null),
          entities: over(
            lensProp(payload.integration.id),
            mergeLeft(payload.integration),
          ),
          count: inc,
        },
        state,
      )

    case getActionType(CREATE, ATS, COMPANY, INTEGRATION, FAIL):
      return evolve({ isCreating: F, err: always(payload.err) }, state)

    case getActionType(UPDATE, ATS, COMPANY, INTEGRATION, START):
      return evolve({ isUpdating: T }, state)

    case getActionType(UPDATE, ATS, COMPANY, INTEGRATION, SUCCESS):
      return evolve(
        {
          isUpdating: F,
          err: always(null),
          entities: over(
            lensProp(payload.integration.id),
            mergeLeft(payload.integration),
          ),
        },
        state,
      )

    case getActionType(UPDATE, ATS, COMPANY, INTEGRATION, FAIL):
      return evolve({ isUpdating: F, err: always(payload.err) }, state)

    case getActionType(DELETE, ATS, COMPANY, INTEGRATION, START):
      return evolve({ isDeleting: T }, state)

    case getActionType(DELETE, ATS, COMPANY, INTEGRATION, SUCCESS):
      return evolve(
        {
          isDeleting: F,
          entities: omit([payload.integration.id]),
          count: dec,
        },
        state,
      )

    case getActionType(DELETE, ATS, COMPANY, INTEGRATION, FAIL):
      return evolve({ isDeleting: F }, state)

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
