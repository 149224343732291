import { getActionType, capitalize, isFormValidation } from "../lib/helpers"
import { startSubmit, stopSubmit } from "redux-form"
import {
  INTEGRATION,
  FAIL,
  GET,
  LIST,
  START,
  SUCCESS,
  ATS,
  COMPANY,
  CREATE,
  PROVIDER,
  ALERT_COLORS,
} from "../constants"
import { validateAtsForm } from "./form-actions"
import { push } from "connected-react-router"
import { alertTypes, showAlert } from "../lib/alerts"

export const getAtsProvidersList = () => {
  return async (dispatch, getState, { atsIntegrationApi }) => {
    dispatch({
      type: getActionType(GET, ATS, PROVIDER, LIST, START),
    })

    try {
      const res = await atsIntegrationApi.getAtsProvidersList()
      const count = parseInt(res.headers["x-total-count"], 10)

      dispatch({
        type: getActionType(GET, ATS, PROVIDER, LIST, SUCCESS),
        payload: { providers: res.data, count },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, ATS, PROVIDER, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export const getAtsProvider =
  ({ slug }) =>
  async (dispatch, getState, { atsIntegrationApi }) => {
    dispatch({ type: getActionType(GET, ATS, PROVIDER, START) })
    try {
      const { data: provider } = await atsIntegrationApi.getAtsProvider({
        slug,
      })
      dispatch({
        type: getActionType(GET, ATS, PROVIDER, SUCCESS),
        payload: { provider },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, ATS, PROVIDER, FAIL),
        payload: { err },
      })
    }
  }

export function getAtsCompanyIntegrationList() {
  return async function (dispatch, getState, { atsIntegrationApi }) {
    dispatch({
      type: getActionType(GET, ATS, COMPANY, INTEGRATION, LIST, START),
    })

    try {
      const res = await atsIntegrationApi.getAtsCompanyIntegrationList({})

      const { data: integration } = res
      const count = parseInt(res.headers["x-total-count"], 10)

      dispatch({
        type: getActionType(GET, ATS, COMPANY, INTEGRATION, LIST, SUCCESS),
        payload: { integration, count },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, ATS, COMPANY, INTEGRATION, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export function createAtsCompanyIntegration({
  provider,
  companyId,
  data,
  redirectTo,
}) {
  return async function (dispatch, getState, { atsIntegrationApi }) {
    const formName = `Create${capitalize(provider)}AtsIntegrationForm`

    dispatch({
      type: getActionType(CREATE, ATS, COMPANY, INTEGRATION, START),
    })
    dispatch(startSubmit(formName))

    try {
      const { data: integration } =
        await atsIntegrationApi.createAtsCompanyIntegration({
          provider,
          companyId,
          data,
        })
      dispatch(stopSubmit(formName))

      dispatch({
        type: getActionType(CREATE, ATS, COMPANY, INTEGRATION, SUCCESS),
        payload: { integration },
      })

      if (redirectTo) {
        dispatch(push(redirectTo))
      }
    } catch (err) {
      if (isFormValidation(err)) {
        dispatch(validateAtsForm({ err, formName }))
      } else {
        dispatch(stopSubmit(formName))
        showAlert({
          code: alertTypes.atsCompanyCreationFailed,
          color: ALERT_COLORS.ERROR,
        })
      }

      dispatch({
        type: getActionType(CREATE, ATS, COMPANY, INTEGRATION, FAIL),
        payload: { err },
      })
    }
  }
}
