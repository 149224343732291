import React from "react"
import PropTypes from "prop-types"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { config } from "../../config"

export const stripePromise = loadStripe(config.stripe.apiKey)

export const StripeElementsProvider = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
)

StripeElementsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
