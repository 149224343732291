import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  PopoverBody,
} from "reactstrap"
import { InfoHint } from "../common/info-hint-component"
import { Button } from "../common/button-component"
import { useOpenClose } from "../../hooks/use-open-close"
import { useFormikField } from "../../hooks/use-formik-field"
import FormFeedback from "../common/form-feedback"

export const AtsInput = ({
  providerSlug,
  opts,
  isRequired,
  name,
  updateField,
  readOnly,
  editable,
  isUpdating,
  disabled,
  ...rest
}) => {
  const [field, meta] = useFormikField({ name, ...rest })

  const {
    value: isEditing,
    open,
    close,
  } = useOpenClose({
    initialValue: false,
  })

  const handleUpdateField = () => {
    updateField({ [field.name]: field.value })
    close()
  }
  let icon = null
  if (isUpdating && isEditing) {
    icon = <i className="fas fa-circle-notch fa-spin" />
  } else {
    icon = isEditing ? (
      <FormattedMessage id="app.common.save" />
    ) : (
      <i className="fas fa-pen" />
    )
  }

  return (
    <FormGroup>
      <Label for={rest.id}>
        <FormattedMessage
          id={`app.page.integrations.connect.${providerSlug}.${name}.label`}
        />
        {isRequired && "*"}
      </Label>
      <InfoHint
        id={`${providerSlug}-hint-${name}`}
        popover={
          <PopoverBody>
            <FormattedMessage
              id={`app.page.integrations.connect.${providerSlug}.${name}.hint`}
            />
          </PopoverBody>
        }
      />
      <InputGroup>
        {opts.prefix && (
          <InputGroupAddon addonType="prepend">{opts.prefix}</InputGroupAddon>
        )}
        <Input
          id={rest.id}
          {...field}
          placeholder={opts.placeholder}
          invalid={meta.touched && Boolean(meta.error)}
          readOnly={readOnly && !isEditing}
          disabled={disabled}
        />
        {opts.suffix && (
          <InputGroupAddon addonType="append">{opts.suffix}</InputGroupAddon>
        )}
        {editable && (
          <InputGroupAddon addonType="append">
            <Button
              onClick={() => (isEditing ? handleUpdateField() : open())}
              loading={isUpdating}
            >
              {icon}
            </Button>
          </InputGroupAddon>
        )}
        <FormFeedback error={meta.error} />
      </InputGroup>
      <FormFeedback error={meta.error} />
    </FormGroup>
  )
}

AtsInput.propTypes = {
  providerSlug: PropTypes.string.isRequired,
  opts: PropTypes.object,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  isUpdating: PropTypes.bool,
  updateField: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
}

AtsInput.defaultProps = {
  opts: {},
  isRequired: false,
  editable: false,
}
