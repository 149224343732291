import React from "react"
import PropTypes from "prop-types"
import { Input, Label, FormFeedback } from "reactstrap"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"

export const CheckboxField = ({ id, label, isRequired, ...rest }) => {
  const [field, meta] = useFormikField(rest)
  return (
    <div className="custom-control custom-checkbox">
      <Input
        id={id}
        className="custom-control-input"
        {...rest}
        {...field}
        type="checkbox"
        invalid={meta.touched && Boolean(meta.error)}
      />
      <Label for={id} className="custom-control-label">
        {label}
        {isRequired && " *"}
      </Label>
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </div>
  )
}

CheckboxField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
}

CheckboxField.defaultProps = {
  isRequired: false,
}
