import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalHeader } from "reactstrap"
import { FormattedMessage } from "react-intl"
import {
  updateMessageTemplateFail,
  updateMessageTemplateSuccess,
} from "../../actions/message-template-actions"
import { useDispatch } from "react-redux"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api } from "../../lib/moberries-api"
import { showErrorAlert } from "../../lib/alerts"
import { MessageTemplateForm } from "../message-template/message-template-form"

export const EditMessageTemplateModal = ({
  close,
  toggle,
  isOpen,
  initialValues,
  onSave,
}) => {
  const dispatch = useDispatch()
  const { id: messageTemplateId } = initialValues

  const { mutateAsync: updateMessageTemplate } = useAxiosMutation(
    api.updateMessageTemplate,
    {
      onSuccess: messageTemplate => {
        dispatch(updateMessageTemplateSuccess({ messageTemplate }))
        onSave && onSave(messageTemplate)
      },
      onError: err => {
        showErrorAlert({ err })
        dispatch(updateMessageTemplateFail({ err }))
      },
    },
  )

  const handleSubmit = (data, actions) => {
    updateMessageTemplate({ data, messageTemplateId })
    actions.resetForm()
    close()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="app.modal.messageTemplate.title.edit" />
      </ModalHeader>

      <MessageTemplateForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        onCancel={close}
      />
    </Modal>
  )
}
EditMessageTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSave: PropTypes.func,
}
EditMessageTemplateModal.defaultProps = {
  isOpen: false,
}
