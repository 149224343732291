import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Input as Binput, FormGroup, Label } from "reactstrap"
import FormFeedback from "./form-feedback"
import cn from "classnames"
import { head, prop } from "ramda"
import { LabelWithHint } from "./label-with-hint-component"

export class CheckboxGroup extends Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    hint: PropTypes.node,
    inline: PropTypes.bool,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    renderLabel: PropTypes.func,
    className: PropTypes.string,
    checkboxComponent: PropTypes.func,
  }

  static defaultProps = {
    inline: false,
    options: [],
  }

  assureValueType(value) {
    const firstOptionType = typeof prop("id", head(this.props.options))

    return { string: String, number: Number, undefined: String }[
      firstOptionType
    ](value)
  }

  render() {
    const {
      input,
      meta,
      label,
      id,
      inline,
      hint,
      options,
      checkboxComponent,
      className,
    } = this.props

    const { renderLabel } = this.props

    const { error, valid, touched, invalid } = meta

    const inputClassNames = cn(
      {
        "custom-control-inline": inline,
      },
      "custom-control custom-checkbox",
    )

    return (
      <FormGroup className={className}>
        <LabelWithHint label={label} hint={hint} className="mb-2" />
        <div>
          {checkboxComponent
            ? options.map(option =>
                checkboxComponent({
                  option,
                  onChange: this.handleChange,
                  onBlur: this.handleBlur,
                  onFocus: input.onFocus,
                  checked: input.value.includes(option.id),
                  value: option.id,
                  key: option.id,
                }),
              )
            : options.map(option => (
                <div key={option.id} className={inputClassNames}>
                  <Binput
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={input.onFocus}
                    type="checkbox"
                    id={`${id}-${option.id}`}
                    valid={!touched || valid ? null : valid}
                    invalid={!touched || valid ? null : invalid}
                    value={option.id}
                    checked={input.value.includes(option.id)}
                    className="custom-control-input"
                  />
                  <Label
                    className="custom-control-label pl-2"
                    for={`${id}-${option.id}`}
                  >
                    {typeof renderLabel === "function" ? (
                      renderLabel(option)
                    ) : (
                      <FormattedMessage id={option.name} />
                    )}
                  </Label>
                </div>
              ))}
        </div>
        <Binput
          type="hidden"
          valid={!touched || valid ? null : valid}
          invalid={!touched || valid ? null : invalid}
        />
        <FormFeedback error={error} />
      </FormGroup>
    )
  }

  handleChange = ({ target: { value } }) => {
    const { input } = this.props

    const val = this.assureValueType(value)

    input.onChange(
      input.value.includes(val)
        ? input.value.filter(v => v !== val)
        : input.value.concat(val),
    )
  }

  handleBlur = () => {
    const { input } = this.props
    if (input.onBlur) {
      input.onBlur(input.value)
    }
  }
}
