import React from "react"
import PropTypes from "prop-types"
import ContentLoader from "react-content-loader"

export const TextLineLoader = ({ height, width }) => (
  <ContentLoader
    height={height}
    width={width}
    style={{ height, width, display: "block" }}
  >
    <rect x="0" y="5" width={width} height={10} />
  </ContentLoader>
)

TextLineLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

TextLineLoader.defaultProps = {
  width: 40,
  height: 21,
}
