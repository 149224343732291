import React from "react"
import { times } from "ramda"
import { List } from "../../loaders/list-loader"
import styles from "./loading-talent-affiliation.module.scss"

export const LoadingTalentAffiliation = () => {
  return (
    <div className={styles.loader}>
      {times(
        i => (
          <List key={i} />
        ),
        4,
      )}
    </div>
  )
}
