import React from "react"
import { AccountPageLayout } from "../components/layouts/account-page-layout"
import { Button, TabPane } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api } from "../lib/moberries-api"
import { ManagerProfileFormFields } from "../components/forms/manager-profile-form-fields"
import { managerProfileSchema } from "../schemas/manager-schemas"
import { useStore } from "../lib/store"
import { refreshManager } from "../controllers/manager-controller"

const toValues = manager => ({
  firstName: manager.firstName,
  lastName: manager.lastName,
  notifyAlerts: manager.settings.notifyAlerts,
  locale: manager.settings.locale,
})

const toRequest = ({ firstName, lastName, ...settings }) => ({
  data: {
    firstName,
    lastName,
    settings,
  },
})

export const AccountPersonalPage = () => {
  const manager = useStore(state => state.manager)
  const { mutateAsync: updateManager, isLoading } = useAxiosMutation(
    api.updateManager,
    {
      onSuccess: () => {
        refreshManager()
      },
    },
  )

  return (
    <AccountPageLayout>
      <TabPane>
        <Formik
          enableReinitialize
          initialValues={toValues(manager)}
          onSubmit={submitAndValidate(updateManager, toRequest)}
          validationSchema={managerProfileSchema}
        >
          <Form>
            <ManagerProfileFormFields manager={manager} />
            <Button
              disabled={isLoading}
              block
              color="primary"
              type="submit"
              size="lg"
            >
              <FormattedMessage id="app.common.save" />
            </Button>
          </Form>
        </Formik>
      </TabPane>
    </AccountPageLayout>
  )
}
