import * as Sentry from "@sentry/react"
import { FAIL, PROBLEMS } from "../constants"

export const sentryFailedActionMiddleware = () => next => action => {
  if (!action.type.includes(FAIL)) {
    return next(action)
  }

  const error = action?.payload?.err
  if (!error) {
    return next(action)
  }

  const shouldErrorBeReported =
    !error.problem ||
    [
      PROBLEMS.SERVER_ERROR,
      PROBLEMS.TIMEOUT_ERROR,
      PROBLEMS.CONNECTION_ERROR,
      PROBLEMS.NETWORK_ERROR,
      PROBLEMS.UNKNOWN_ERROR,
    ].includes(error.problem)

  if (shouldErrorBeReported) {
    Sentry.captureException(error)
  }

  return next(action)
}
