import React, { Fragment } from "react"
import { Badge } from "reactstrap"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import {
  formatSalary,
  getCareerLevelName,
  getLocationName,
} from "../../lib/helpers"
import { DetailViewSection } from "../detail-view/detail-view-section-component"
import { DetailView } from "../detail-view/detail-view-component"
import { DetailViewProgressBar } from "../detail-view/detail-view-progress-bar-component"
import { JOB_LEVELS, JOB_TYPES, LANG_LEVELS } from "../../default-options"
import { DetailViewAttribute } from "../detail-view/detail-view-attribute-component"

export function JobDetailsComponent({ job }) {
  return (
    <Fragment>
      <DetailView>
        <DetailViewSection
          title={<FormattedMessage id="app.page.jobDetails.workExperience" />}
        >
          {job.jobRoles.map(jobRole => (
            <DetailViewProgressBar
              key={jobRole.id}
              total={JOB_LEVELS.length}
              completed={jobRole.level}
              title={jobRole.name}
              mustHave={jobRole.mustHave}
              value={<FormattedMessage id={JOB_LEVELS[jobRole.level].name} />}
            />
          ))}
        </DetailViewSection>
        <DetailViewSection
          title={<FormattedMessage id="app.page.jobDetails.neededSkills" />}
        >
          <div className="mt-2">
            {job.skills.map(skill => (
              <Badge key={skill.id} color="light" className="mb-1 mr-1">
                {skill.name}
              </Badge>
            ))}
          </div>
        </DetailViewSection>
        <DetailViewSection
          title={<FormattedMessage id="app.page.jobDetails.neededLanguages" />}
        >
          {job.languages.map(language => (
            <DetailViewProgressBar
              key={language.id}
              total={LANG_LEVELS.length}
              completed={language.level}
              title={language.name}
              mustHave={language.mustHave}
              value={<FormattedMessage id={LANG_LEVELS[language.level].name} />}
            />
          ))}
        </DetailViewSection>
        <DetailViewSection
          title={<FormattedMessage id="app.page.jobDetails.jobClaims" />}
        >
          <DetailViewAttribute
            title={
              <FormattedMessage id="app.page.jobDetails.salaryExpectation" />
            }
          >
            {formatSalary(job.salaryMin, job.salaryMax)}
          </DetailViewAttribute>
          <DetailViewAttribute
            title={
              <FormattedMessage id="app.page.jobDetails.jobClaims.careerLevel" />
            }
          >
            {getCareerLevelName(job.careerLevel)}
          </DetailViewAttribute>
          <DetailViewAttribute
            title={
              <FormattedMessage id="app.page.jobDetails.jobClaims.jobTypes" />
            }
          >
            {job.jobTypes.map((jobType, idx) => (
              <span key={jobType}>
                <FormattedMessage
                  id={JOB_TYPES.find(jt => jt.id === jobType).name}
                />
                {idx !== job.jobTypes.length - 1 && ", "}
              </span>
            ))}
          </DetailViewAttribute>
        </DetailViewSection>
        <DetailViewSection
          title={<FormattedMessage id="app.page.jobDetails.location" />}
        >
          <DetailViewAttribute
            title={
              <FormattedMessage id="app.page.jobDetails.jobClaims.remote" />
            }
          >
            <FormattedMessage
              id={job.remote ? "app.common.yes" : "app.common.no"}
            />
          </DetailViewAttribute>
          {job.remote && (
            <DetailViewAttribute title="">
              [
              {job.restrictRemoteToGermany ? (
                <FormattedMessage id="app.common.regions.onlyGermanBased" />
              ) : (
                job.remoteRegions.map((region, idx) => (
                  <span key={region}>
                    <FormattedMessage id={`app.common.regions.${region}`} />
                    {idx !== job.remoteRegions.length - 1 && ", "}
                  </span>
                ))
              )}
              ]
            </DetailViewAttribute>
          )}
          <DetailViewAttribute
            title={
              <FormattedMessage id="app.page.jobDetails.jobClaims.relocation" />
            }
          >
            <FormattedMessage
              id={job.relocate ? "app.common.yes" : "app.common.no"}
            />
          </DetailViewAttribute>
          {job.relocate && (
            <DetailViewAttribute title="">
              [
              {job.relocationRegions.map((region, idx) => (
                <span key={region}>
                  <FormattedMessage id={`app.common.regions.${region}`} />
                  {idx !== job.relocationRegions.length - 1 && ", "}
                </span>
              ))}
              ]
            </DetailViewAttribute>
          )}
          <DetailViewAttribute
            title={
              <FormattedMessage id="app.page.jobDetails.jobClaims.locations" />
            }
          >
            {job.locations.map((location, idx) => (
              <span key={location.placeId}>
                {getLocationName(location)}
                {idx !== job.locations.length - 1 && " "}
              </span>
            ))}
          </DetailViewAttribute>
        </DetailViewSection>
      </DetailView>
      <div className="bg-white mt-3 pt-3 border-top">
        <h5>
          <FormattedMessage id="app.page.jobDetails.description" />
        </h5>
        <p
          className="text-break"
          dangerouslySetInnerHTML={{ __html: job.description }}
        />
      </div>
    </Fragment>
  )
}

JobDetailsComponent.propTypes = {
  job: PropTypes.object.isRequired,
}
