import React from "react"
import PropTypes from "prop-types"
import { FormFeedback as BFormFeedback } from "reactstrap"
import { FormattedMessage } from "react-intl"

const FormFeedback = props => {
  const { error, ...rest } = props
  let content = null

  if (error && typeof error === "function") {
    const Error = error
    content = <Error />
  } else if (error && typeof error === "object") {
    content = <FormattedMessage {...error} />
  } else {
    content = error
  }

  return <BFormFeedback {...rest}>{content}</BFormFeedback>
}

FormFeedback.propTypes = {
  error: PropTypes.any,
}

export default FormFeedback
