import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { getFullName } from "../../lib/helpers"
import { FormattedMessage } from "react-intl"

export const OtherMatchList = ({ matches }) => {
  if (matches.length === 0) {
    return null
  }
  return (
    <div className="bg-white rounded p-3">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-user" />
        </div>
        <FormattedMessage id="app.page.matchList.sidebar.otherCandidates" />
      </h5>
      <ul className="list-unstyled m-0 border-top">
        {matches.map(m => (
          <li key={m.id} className="py-2 border-bottom">
            <Link className="d-block text-truncate" to={`/match/${m.id}`}>
              {getFullName(m.candidate)}
            </Link>
            <div className="text-muted text-truncate">{m.job.title}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

OtherMatchList.propTypes = {
  matches: PropTypes.array.isRequired,
}

OtherMatchList.defaultProps = {
  matches: [],
}
