import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./analytics-chart-layout.module.scss"

export const AnalyticsChartLayout = ({ className, title, children }) => {
  return (
    <div>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={cn(styles.container, className)}>{children}</div>
    </div>
  )
}

AnalyticsChartLayout.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
}
