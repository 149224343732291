import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { JobSlotsWidget } from "./job-slots-widget"
import { Button } from "../common/button-component"
import { AtsIntegrationWidget } from "./ats-integration-widget"
import { UncontrolledPopover, PopoverBody } from "reactstrap"
import { NoAtsIntegratedWidget } from "./no-ats-integrated-widget"
import { useStore } from "../../lib/store"

export function JobListSidebar({
  onJobCreateButtonClick,
  isAutoJobSyncEnabled,
}) {
  const connectedAtsProvider = useStore(state => state.connectedProvider)

  return (
    <Fragment>
      <Button
        className="mb-3"
        color="primary"
        block
        onClick={onJobCreateButtonClick}
        disabled={isAutoJobSyncEnabled}
      >
        <div id="create-job">
          <i className="fas fa-plus-circle pr-2" />
          <FormattedMessage id="app.page.jobList.createJob" />
        </div>
      </Button>
      {isAutoJobSyncEnabled && (
        <UncontrolledPopover
          trigger="hover"
          target="create-job"
          placement="bottom"
        >
          <PopoverBody>
            <FormattedMessage id="app.page.jobList.createJob.disabled.hint" />
          </PopoverBody>
        </UncontrolledPopover>
      )}

      <JobSlotsWidget />
      {connectedAtsProvider ? (
        <AtsIntegrationWidget
          provider={connectedAtsProvider}
          isAutoJobSyncEnabled={isAutoJobSyncEnabled}
        />
      ) : (
        <NoAtsIntegratedWidget />
      )}
    </Fragment>
  )
}

JobListSidebar.propTypes = {
  onJobCreateButtonClick: PropTypes.func.isRequired,
  isAutoJobSyncEnabled: PropTypes.bool.isRequired,
}

JobListSidebar.defaultProps = {
  isAutoJobSyncEnabled: false,
}
