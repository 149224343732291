import * as Sentry from "@sentry/react"
import { persistStore, persistReducer } from "redux-persist"
import thunk from "redux-thunk"
import { stateSanitizer, actionSanitizer } from "../lib/logrocket-sanitizers"
import { routerMiddleware, push, replace } from "connected-react-router"
import LogRocket from "logrocket"
import { FAIL, START, SUCCESS } from "../constants"

import * as candidateActions from "../actions/candidate-actions"
import * as matchActions from "../actions/match-actions"
import * as companyActions from "../actions/company-actions"
import * as userActions from "../actions/user-actions"
import * as jobActions from "../actions/job-actions"
import * as subscriptionActions from "../actions/subscription-actions"
import * as invoiceActions from "../actions/invoice-actions"
import * as cardActions from "../actions/payment-card-actions"
import * as surveyActions from "../actions/survey-actions"
import * as messageTemplateActions from "../actions/message-template-actions"
import * as vrCampaignActions from "../actions/vr-campaign-actions"
import * as vrCandidateActions from "../actions/vr-candidate-actions"

import * as slackIntegrationActions from "../actions/slack-integration-actions"
import * as atsCompanyIntegrationActions from "../actions/ats-company-integration-actions"
import * as atsCompanyIntegrationOpenJobsActions from "../actions/ats-company-integration-open-jobs-actions"
import * as atsJobIntegrationActions from "../actions/ats-job-integration-actions"

import { mapObjIndexed } from "ramda"
import { reducers } from "./reducers"
import { config } from "../config"
import { history } from "../history"
import { reduxFormFixMiddleware } from "../middleware/rf-fix-middleware"
import { errorMiddleware } from "../middleware/error-middleware"
import { api as moApi } from "../lib/moberries-api"
import { api as integrationApi } from "../lib/bots-api"
import { api as atsIntegrationApi } from "../lib/ats-api"
import { api as typeformApi } from "../lib/typeform-api"
import {
  emitter as reduxActionsEmitter,
  reduxActionsEmitterMiddleware,
} from "../lib/redux-actions-emitter"

import {
  createStore,
  combineReducers,
  applyMiddleware,
  bindActionCreators,
  compose,
} from "redux"
import { cancelRequestMiddleware } from "../middleware/cancel-request-middleware"
import { sentryUserMiddleware } from "../middleware/sentry-user-middleware"
import { sentryFailedActionMiddleware } from "../middleware/sentry-failed-action-middleware"

let composeEnhancers = compose

const extraArgument = {
  actions: {
    candidate: candidateActions,
    match: matchActions,
    company: companyActions,
    user: userActions,
    job: jobActions,
    invoice: invoiceActions,
    subscription: subscriptionActions,
    card: cardActions,
    survey: surveyActions,
    messageTemplate: messageTemplateActions,
    slackIntegration: slackIntegrationActions,
    atsCompanyIntegration: atsCompanyIntegrationActions,
    atsCompanyIntegrationOpenJobs: atsCompanyIntegrationOpenJobsActions,
    atsJobIntegration: atsJobIntegrationActions,
    router: { push, replace },
    vrCampaign: vrCampaignActions,
    vrCandidate: vrCandidateActions,
  },
  integrationApi,
  atsIntegrationApi,
  moApi,
  typeformApi,
  reduxActionsEmitter,
}

const middleware = [
  thunk.withExtraArgument(extraArgument),
  reduxActionsEmitterMiddleware,
  reduxFormFixMiddleware,
  cancelRequestMiddleware,
  sentryUserMiddleware,
  sentryFailedActionMiddleware,
]

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: stateSanitizer,
  actionTransformer: actionSanitizer,
})

if (config.env.isDev) {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionCreators: {
        candidate: candidateActions,
        match: matchActions,
        company: companyActions,
        user: userActions,
        job: jobActions,
        survey: surveyActions,
        card: cardActions,
        invoice: invoiceActions,
        subscription: subscriptionActions,
        slackIntegration: slackIntegrationActions,
        atsCompanyIntegration: atsCompanyIntegrationActions,
        atsCompanyIntegrationOpenJobs: atsCompanyIntegrationOpenJobsActions,
        atsJobIntegration: atsJobIntegrationActions,
        router: { push, replace },
      },
    })
  }
}

if (process.env.NODE_ENV === "development") {
  const logger = require("redux-logger")

  const loggerMiddleware = logger.createLogger({
    collapsed: true,
    duration: true,
    colors: {
      ...logger.defaults.colors,
      title: ({ type }) => {
        if (type.includes(SUCCESS)) return "#4CAF50"
        if (type.includes(FAIL)) return "#F20404"
        if (type.includes(START)) return "#03A9F4"
      },
    },
    predicate: (getState, action) => !action.type.includes("redux-form"),
  })

  middleware.push(loggerMiddleware)
}

middleware.push(routerMiddleware(history))

middleware.push(
  LogRocket.reduxMiddleware({
    stateSanitizer,
    actionSanitizer,
  }),
)

middleware.push(errorMiddleware)

function applyPersistors(reducers, config) {
  return mapObjIndexed((v, k) => {
    if (config[k]) {
      return persistReducer(config[k], v)
    }

    return v
  }, reducers)
}

function configureStore() {
  const store = createStore(
    persistReducer(
      config.persist.root,
      combineReducers(applyPersistors(reducers, config.persist)),
    ),
    {},
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer),
  )

  extraArgument.store = store

  const persistor = persistStore(store, {})

  if (config.env.isDev) {
    window.store = store

    window.actions = {
      candidate: bindActionCreators(candidateActions, store.dispatch),
      match: bindActionCreators(matchActions, store.dispatch),
      company: bindActionCreators(companyActions, store.dispatch),
      user: bindActionCreators(userActions, store.dispatch),
      card: bindActionCreators(cardActions, store.dispatch),
      job: bindActionCreators(jobActions, store.dispatch),
      invoice: bindActionCreators(invoiceActions, store.dispatch),
      subscription: bindActionCreators(subscriptionActions, store.dispatch),
      router: bindActionCreators({ push, replace }, store.dispatch),
      slackIntegration: bindActionCreators(
        slackIntegrationActions,
        store.dispatch,
      ),
      atsCompanyIntegration: bindActionCreators(atsCompanyIntegrationActions),
      atsCompanyIntegrationOpenJobs: bindActionCreators(
        atsCompanyIntegrationOpenJobsActions,
      ),
      atsJobIntegration: bindActionCreators(
        atsJobIntegrationActions,
        store.dispatch,
      ),
      survey: bindActionCreators(surveyActions, store.dispatch),
    }
  }

  return {
    persistor,
    store,
  }
}

const { store, persistor } = configureStore()

export { store, persistor }
