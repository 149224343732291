import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { nanoid } from "nanoid"
import { InfoHint } from "../common/info-hint-component"
import PopoverBody from "reactstrap/lib/PopoverBody"

export const DetailViewSection = ({ children, title, hint }) => {
  const [id] = useState(nanoid())

  return (
    <Fragment>
      <div className="d-flex flex-row border-bottom pb-2 mt-3 text-smaller">
        <span className="text-muted text-uppercase lh-1 flex-grow-1">
          {title}
        </span>
        {hint && (
          <InfoHint
            id={id}
            color="primary"
            popover={<PopoverBody>{hint}</PopoverBody>}
          />
        )}
      </div>
      {children}
    </Fragment>
  )
}

DetailViewSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  hint: PropTypes.node,
}

DetailViewSection.defaultProps = {
  hint: null,
}
