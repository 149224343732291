import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { ActionControlsLayout } from "../layouts/action-controls-layout"
import { Button } from "../common/button-component"
import { shouldRenderApproveRejectControls } from "../../lib/helpers"
import { MatchRejectionFeedback } from "./match-rejection-feedback-component"
import { FormattedMessage } from "react-intl"
import { ConfirmApproveModal } from "../modals/confirm-approve-modal"
import { useOpenClose } from "../../hooks/use-open-close"

export const ApproveRejectMatchControls = ({
  match,
  onApproveClick,
  onRejectClick,
  disabled,
  approveLoading,
  isRejecting,
  reject,
}) => {
  const {
    value: isApproveModalOpen,
    open: openApproveModal,
    close: closeApproveModal,
  } = useOpenClose({ initialValue: false })

  const {
    value: isRejectFeedbackOpen,
    open: openRejectFeedback,
    close: closeRejectFeedback,
  } = useOpenClose({ initialValue: reject })

  if (!shouldRenderApproveRejectControls(match)) {
    return null
  }
  if (isRejectFeedbackOpen) {
    return (
      <MatchRejectionFeedback
        confirm={(selectedReasons, comment) => {
          onRejectClick({
            id: match.id,
            companyId: match.job.company.id,
            answers: selectedReasons,
            comment,
            candidateId: match.candidate.id,
          })
        }}
        cancel={closeRejectFeedback}
        disabled={disabled}
        isLoading={isRejecting}
      />
    )
  }
  return (
    <Fragment>
      <ConfirmApproveModal
        cancel={closeApproveModal}
        confirm={async () => {
          await onApproveClick({
            id: match.id,
            companyId: match.job.company.id,
            redirectToMatchList: true,
          })
          closeApproveModal()
        }}
        isOpen={isApproveModalOpen}
        loading={approveLoading}
      />
      <ActionControlsLayout>
        <div
          left="true"
          className="d-flex align-items-center justify-content-end"
        >
          <small className="d-none d-lg-block px-2 text-right">
            <FormattedMessage id="app.page.match.reject.text" />
          </small>
          <Button
            disabled={disabled}
            loading={isRejecting}
            className="btn-pill d-flex align-items-center"
            color="secondary"
            onClick={openRejectFeedback}
          >
            <i className="fas fa-times pr-2" />
            <FormattedMessage id="app.page.match.reject.button" />
          </Button>
        </div>

        <div right="true" className="d-flex align-items-center">
          <Button
            disabled={disabled}
            loading={approveLoading}
            className="btn-pill d-flex align-items-center"
            color="success"
            onClick={openApproveModal}
          >
            <i className="fas fa-user-check pr-2" />
            <FormattedMessage id="app.page.match.approve.button" />
          </Button>
          <small className="d-none d-lg-block px-2">
            <FormattedMessage
              id="app.page.match.approve.text"
              values={{ candidate: match.candidate.firstName }}
            />
          </small>
        </div>
      </ActionControlsLayout>
    </Fragment>
  )
}

ApproveRejectMatchControls.propTypes = {
  match: PropTypes.object.isRequired,
  onApproveClick: PropTypes.func.isRequired,
  onRejectClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  approveLoading: PropTypes.bool.isRequired,
  isRejecting: PropTypes.bool.isRequired,
  reject: PropTypes.bool,
}

ApproveRejectMatchControls.defaultProps = {
  reject: false,
  disabled: false,
  approveLoading: false,
  isRejecting: false,
  rejectFeedbackOpen: false,
  approveModalOpen: true,
}
