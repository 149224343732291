import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Redirect, useLocation } from "react-router"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { prop } from "ramda"
import qs from "qs"
import cn from "classnames"

import {
  ATS_APPLICATION_SYNC_OPTIONS,
  DASHBOARD_MATCHES_LIMIT,
  MATCH_ORDERING,
  MATCH_STATUSES,
} from "../../constants"
import {
  isComSubscriptionCanceled,
  isComSubscriptionExpired,
  isComSubscriptionPaused,
  companyHasActiveJobs,
  companyHasJobs,
  resetPageQueryParam,
  isSubscriptionPph,
  companySubscriptionSelector,
} from "../../lib/helpers"
import { useStore } from "../../lib/store"
import {
  getAtsCompanyIntegrationList,
  getAtsProvider,
  getAtsProvidersList,
} from "../../actions/ats-company-integration-actions"
import { getMatchList } from "../../actions/match-actions"
import {
  matchesSelector,
  countSelector as matchesCountSelector,
  loadingSelector,
  errorSelector,
} from "../../selectors/match-selector"

import { ErrorPage } from "../error-page"
import { DashboardInstructionsPage } from "./dashboard-instructions-page/dashboard-instructions-page"
import { DashboardPageSkeleton } from "../../components/skeletons/dashboard-page-skeleton"
import { DoubleColumnLayoutDashboard } from "../../components/layouts/dashboard/double-column-layout-dashboard"
import { TripleColumnLayoutDashboard } from "../../components/layouts/dashboard/triple-column-layout-dashboard"
import { DashboardSidebar } from "../../components/sidebar/dashboard-sidebar.js"
import { AtsProviderSection } from "../../components/integration/ats-provider-section"
import { DashboardMatchList } from "../../components/dashboard/dashboard-match-list-component"
import { DashboardHeader } from "../../components/dashboard/dashboard-header-component"
import { SubscriptionStatus } from "../../components/dashboard/subscription-status-component"
import { EmptyMatchesTip } from "../../components/dashboard/empty-matches-tip-component"
import { TalentsYouMissWidget } from "../../components/widgets/talents-you-miss-widget/talents-you-miss-widget"
import { VirtualRecruiterWidget } from "../../components/widgets/virtual-recruiter-widget/virtual-recruiter-widget"
import { SharedTalentNetworkWidget } from "../../components/widgets/shared-talent-network-widget/shared-talent-network-widget"

import styles from "./dashboard-page.module.scss"

export const DashboardPageComponent = ({
  push,
  getMatchList,
  matches,
  matchesCount,
  err,
  isLoadingMatches,
}) => {
  const { company, manager, atsCompanyIntegration, connectedAtsProvider } =
    useStore(
      ({ company, manager, atsCompanyIntegration, connectedAtsProvider }) => ({
        company,
        manager,
        atsCompanyIntegration,
        connectedAtsProvider,
      }),
    )
  const location = useLocation()
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const { jobTeam, page: pageQuery, search } = query
  const page = pageQuery ? Number(pageQuery) : 1
  const companySubscription = useStore(companySubscriptionSelector)

  useEffect(() => {
    const params = {
      page,
      limit: DASHBOARD_MATCHES_LIMIT,
      status: MATCH_STATUSES.CANDIDATE_ACCEPTED,
      job__team: jobTeam,
      ordering: MATCH_ORDERING.EXPIRES_AT,
      search,
    }
    getMatchList({ params })
  }, [page, getMatchList, jobTeam, search])

  if (err) {
    // TODO: Need to adjust error page according to new designs in future
    return <ErrorPage err={err} />
  }

  if (matchesCount === null) {
    return (
      <DashboardPageSkeleton
        className={cn("container-dashboard", styles.container, styles.header)}
      />
    )
  }

  if (matches.length === 0 && page > 1) {
    return (
      <Redirect
        to={{
          pathname: "/",
          search: resetPageQueryParam(location.search),
        }}
      />
    )
  }

  if (
    !companyHasJobs(company.statistics) &&
    isSubscriptionPph(companySubscription.subscription)
  ) {
    // initial state after signup with instructions what to do
    return <DashboardInstructionsPage />
  }

  const isAtsAutoMatchExportEnabled =
    connectedAtsProvider &&
    prop("matchExportType", atsCompanyIntegration) ===
      ATS_APPLICATION_SYNC_OPTIONS.ON_CAN_ACCEPT

  const areAllFiltersDeactivated = !jobTeam && !search

  const isActualMatchesCountZero =
    matchesCount === 0 && areAllFiltersDeactivated

  return (
    <div className={styles.container}>
      <DashboardHeader
        className={cn("container-dashboard", styles.header)}
        companySubscription={companySubscription}
        isAtsAutoMatchExportEnabled={isAtsAutoMatchExportEnabled}
        isActualMatchesCountZero={isActualMatchesCountZero}
      />
      <DoubleColumnLayoutDashboard>
        <DoubleColumnLayoutDashboard.Content>
          <SubscriptionStatus companySubscription={companySubscription} />
          {isAtsAutoMatchExportEnabled && (
            <div className="mb-2">
              <AtsProviderSection
                provider={connectedAtsProvider}
                atsCompanyIntegration={atsCompanyIntegration}
              />
            </div>
          )}
          <DashboardMatchList
            teams={manager.teams}
            matches={matches}
            matchesCount={matchesCount}
            page={page}
            isReadOnly={
              isComSubscriptionExpired(companySubscription) ||
              isComSubscriptionPaused(companySubscription)
            }
            isLoadingMatches={isLoadingMatches}
            areAllFiltersDeactivated={areAllFiltersDeactivated}
            handleMatchClick={matchId => () => push(`/match/${matchId}`)}
          />
          {!isComSubscriptionExpired(companySubscription) &&
            !isComSubscriptionPaused(companySubscription) &&
            !isComSubscriptionCanceled(companySubscription) &&
            isActualMatchesCountZero && (
              <EmptyMatchesTip
                companySubscription={companySubscription}
                hasCompanyActiveJobs={companyHasActiveJobs(company)}
                companyStatistics={company.statistics}
              />
            )}
        </DoubleColumnLayoutDashboard.Content>
        <DoubleColumnLayoutDashboard.Sidebar>
          <DashboardSidebar />
        </DoubleColumnLayoutDashboard.Sidebar>
      </DoubleColumnLayoutDashboard>

      <TripleColumnLayoutDashboard
        rowGutters={styles.rowGutters}
        colGutters={styles.colGutters}
      >
        <TripleColumnLayoutDashboard.TalentsYouMissWidget>
          <TalentsYouMissWidget />
        </TripleColumnLayoutDashboard.TalentsYouMissWidget>
        <TripleColumnLayoutDashboard.VirtualRecruiterWidget>
          <VirtualRecruiterWidget />
        </TripleColumnLayoutDashboard.VirtualRecruiterWidget>
        <TripleColumnLayoutDashboard.SharedTalentNetworkWidget>
          <SharedTalentNetworkWidget companyGroups={company.groups} />
        </TripleColumnLayoutDashboard.SharedTalentNetworkWidget>
      </TripleColumnLayoutDashboard>
    </div>
  )
}

const mapDispatchToProps = {
  getMatchList,
  push,
  getAtsCompanyIntegrationList,
  getAtsProvider,
  getAtsProvidersList,
}
const mapStateToProps = function (state) {
  return {
    matches: matchesSelector(state, {
      status: MATCH_STATUSES.CANDIDATE_ACCEPTED,
    }),
    isLoadingMatches: loadingSelector(state),
    matchesCount: matchesCountSelector(state),
    err: errorSelector(state),
  }
}

DashboardPageComponent.propTypes = {
  push: PropTypes.func.isRequired,
  getMatchList: PropTypes.func.isRequired,
  matches: PropTypes.array.isRequired,
  matchesCount: PropTypes.number,
  err: PropTypes.object,
  isLoadingMatches: PropTypes.bool.isRequired,
}

export const DashboardPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPageComponent)
