import React from "react"
import { FormattedMessage } from "react-intl"

export const AtsNothingConnected = () => (
  <div className="d-flex">
    <span className="icon-circled icon-lg mr-2 flex-shrink-0">
      <span className="fa-stack fa-2x text-muted flex-shrink-0">
        <i className="fas fa-plug fa-stack-1x" />
        <i className="fas fa-slash fa-stack-1x" />
      </span>
    </span>

    <div className="ml-1">
      <h3>
        <FormattedMessage id="app.page.integrations.nothingConnected.header" />
      </h3>
      <FormattedMessage
        id="app.page.integrations.nothingConnected.text"
        values={{
          email: (
            <a href="mailto:am@moberries.com">
              <FormattedMessage id="app.common.accountManagerEmail" />
            </a>
          ),
        }}
      />
    </div>
  </div>
)
