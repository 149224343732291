import axios from "axios"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  errorInterceptor,
  decamelizeInterceptor,
} from "./axios-interceptors"
import { getAuthorization } from "./helpers/api-helpers"
import { store } from "./store"

class TypeformApi {
  constructor() {
    const { jwt } = store.getState()

    this.http = axios.create({
      baseURL: config.typeform.api.url,
      timeout: 30000,
      headers: {
        Authorization: getAuthorization(jwt),
      },
    })

    this.http.interceptors.response.use(response => response, errorInterceptor)
    this.http.interceptors.response.use(saveOriginalInterceptor)
    this.http.interceptors.response.use(camelizeInterceptor)

    this.http.interceptors.request.use(decamelizeInterceptor)
  }

  setDefaultHeader({ header, value }) {
    this.http.defaults.headers[header] = value
  }

  getSurveyStatus({ params }) {
    const { query, code } = params
    return this.http(`/forms/${code}/responses?query=${query}`)
  }
}

const api = new TypeformApi()

store.subscribe(({ jwt }) =>
  api.setDefaultHeader(
    { header: "Authorization", value: getAuthorization(jwt) },
    store => store.jwt,
  ),
)

export { api }
