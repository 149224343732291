import { config } from "../../config"

export function initGtm() {
  if (config.branch.isRelease && !window.dataLayer) {
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      })
      const f = d.getElementsByTagName(s)[0], // eslint-disable-line
        j = d.createElement(s),
        dl = l !== "dataLayer" ? `&l=${l}` : ""
      j.async = true
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
      f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", "GTM-MD64MXL")
  }
}

export const track = (function (queue) {
  return event => {
    const { dataLayer } = window

    if (dataLayer) {
      if (queue.length > 0) {
        queue.forEach(item => {
          dataLayer.push(item)
        })
        queue = []
      }

      dataLayer.push(event)
    } else {
      queue.push(event)
    }
  }
})([])
