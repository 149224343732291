import { arrayToObject, getActionType } from "../lib/helpers"
import {
  always,
  evolve,
  F,
  T,
  mergeLeft,
  over,
  lensProp,
  dec,
  reject,
  equals,
  invert,
  map,
} from "ramda"
import {
  GET,
  SUCCESS,
  MATCH,
  LIST,
  FAIL,
  START,
  RELOAD,
  NEXT,
  ACCEPT,
  DECLINE,
  APPROVE,
  REJECT,
  REJECTED,
  REASONS,
  MATCH_STATUSES,
  FEEDBACK,
  SUMMARY,
  STATISTICS,
  APPLICATIONS,
} from "../constants"

export const defaultState = {
  count: null,
  entities: {},
  loading: false,
  updating: false,
  accepting: false,
  declining: false,
  approving: false,
  rejecting: false,
  nextMatch: null,
  err: null,
  matchRejectedReasonsByMatch: {},
  foundSearchTerm: null,
  byStatus: map(always([]), invert(MATCH_STATUSES)),
  feedbackSummary: { jobReasons: [], companyReasons: [] },
  isFeedbackSummaryLoading: false,
  applicationsStatistics: [],
  isApplicationsStatisticsLoading: false,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, MATCH, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, MATCH, LIST, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          count: always(payload.count),
          entities: mergeLeft(arrayToObject(payload.matches, "id")),
          foundSearchTerm: always(payload.search),
          byStatus: {
            [payload.status]: always(payload.matches.map(j => j.id)),
          },
        },
        state,
      )

    case getActionType(GET, MATCH, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(GET, MATCH, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, MATCH, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
        },
        state,
      )

    case getActionType(GET, MATCH, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(RELOAD, MATCH, SUCCESS):
      return evolve(
        {
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
        },
        state,
      )

    case getActionType(GET, NEXT, MATCH, SUCCESS):
      return evolve(
        {
          nextMatch: Boolean(payload.match)
            ? always(payload.match.id)
            : always(null),
          entities: Boolean(payload.match)
            ? over(lensProp(payload.match.id), mergeLeft(payload.match))
            : always(state.entities),
        },
        state,
      )

    case getActionType(ACCEPT, MATCH, START):
      return evolve({ accepting: T, updating: T }, state)

    case getActionType(ACCEPT, MATCH, SUCCESS):
      return evolve(
        {
          matches: reject(equals(payload.match.id)),
          count: dec,
          accepting: F,
          updating: F,
          err: always(null),
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
        },
        state,
      )

    case getActionType(ACCEPT, MATCH, FAIL):
      return evolve(
        { accepting: F, updating: F, err: always(payload.err) },
        state,
      )

    case getActionType(DECLINE, MATCH, START):
      return evolve({ declining: T, updating: T }, state)

    case getActionType(DECLINE, MATCH, SUCCESS):
      return evolve(
        {
          matches: reject(equals(payload.match.id)),
          count: dec,
          declining: F,
          updating: F,
          err: always(null),
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
        },
        state,
      )

    case getActionType(DECLINE, MATCH, FAIL):
      return evolve(
        { declining: F, updating: F, err: always(payload.err) },
        state,
      )

    case getActionType(APPROVE, MATCH, START):
      return evolve({ approving: T, updating: T }, state)

    case getActionType(APPROVE, MATCH, SUCCESS):
      return evolve(
        {
          matches: reject(equals(payload.match.id)),
          count: dec,
          approving: F,
          updating: F,
          err: always(null),
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
        },
        state,
      )

    case getActionType(APPROVE, MATCH, FAIL):
      return evolve(
        { approving: F, updating: F, err: always(payload.err) },
        state,
      )

    case getActionType(REJECT, MATCH, START):
      return evolve({ rejecting: T, updating: T }, state)

    case getActionType(REJECT, MATCH, SUCCESS):
      return evolve(
        {
          matches: reject(equals(payload.match.id)),
          count: dec,
          rejecting: F,
          updating: F,
          err: always(null),
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
        },
        state,
      )

    case getActionType(REJECT, MATCH, FAIL):
      return evolve(
        { rejecting: F, updating: F, err: always(payload.err) },
        state,
      )
    case getActionType(GET, MATCH, REJECTED, REASONS, SUCCESS):
      return evolve(
        {
          matchRejectedReasonsByMatch: over(
            lensProp(payload.matchId),
            always(payload.matchRejectedReasons),
          ),
        },
        state,
      )
    case getActionType(GET, FEEDBACK, SUMMARY, START):
      return evolve(
        {
          isFeedbackSummaryLoading: T,
        },
        state,
      )
    case getActionType(GET, FEEDBACK, SUMMARY, SUCCESS):
      return evolve(
        {
          feedbackSummary: always(payload.feedbackSummary),
          isFeedbackSummaryLoading: F,
        },
        state,
      )
    case getActionType(GET, FEEDBACK, SUMMARY, FAIL):
      return evolve(
        {
          feedbackSummaryError: always(payload.err),
          isFeedbackSummaryLoading: F,
        },
        state,
      )
    case getActionType(GET, APPLICATIONS, STATISTICS, START):
      return evolve(
        {
          isApplicationsStatisticsLoading: T,
        },
        state,
      )
    case getActionType(GET, APPLICATIONS, STATISTICS, SUCCESS):
      return evolve(
        {
          applicationsStatistics: always(payload.applicationsStatistics),
          isApplicationsStatisticsLoading: F,
        },
        state,
      )
    case getActionType(GET, APPLICATIONS, STATISTICS, FAIL):
      return evolve(
        {
          applicationsStatisticsError: always(payload.err),
          isApplicationsStatisticsLoading: F,
        },
        state,
      )

    default:
      return state
  }
}
