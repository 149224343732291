import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Input, Label } from "reactstrap"
import { useFormikField } from "../../hooks/use-formik-field"

export const JobGermanyOnlyCheckbox = props => {
  const [field, , { setValue }] = useFormikField(props)

  return (
    <div className="custom-control custom-checkbox mb-3">
      <Input
        {...props}
        {...field}
        className="custom-control-input"
        type="checkbox"
        checked={field.value}
        onChange={e => {
          console.log(e.target.checked)
          setValue(e.target.checked)
        }}
      />
      <Label for={props.id} className="custom-control-label">
        <FormattedMessage id="app.common.regions.onlyGermanBased" />
      </Label>
    </div>
  )
}

JobGermanyOnlyCheckbox.propTypes = {
  id: PropTypes.string,
}
