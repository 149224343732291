import React from "react"
import * as Sentry from "@sentry/react"
import { FormattedMessage } from "react-intl"
import {
  atsErrorsMapping,
  moErrorsMapping,
  moErrorTypes,
} from "./server-errors"
import { toast } from "react-toastify"
import { ALERT_COLORS, PROBLEMS } from "../constants"
import { config } from "../config"
import { path } from "ramda"
import humps from "humps"

export const alertTypes = Object.freeze({
  success: "success",
  matchAcceptSuccess: "matchAcceptSuccess",
  newMessage: "newMessage",
  subscriptionUpgradeSuccess: "subscriptionUpgradeSuccess",
  subscriptionRenewSuccess: "subscriptionRenewSuccess",
  subscriptionCancelSuccess: "subscriptionCancelSuccess",
  matchDeclineSuccess: "matchDeclineSuccess",
  matchApproveSuccess: "matchApproveSuccess",
  matchRejectSuccess: "matchRejectSuccess",
  newMatch: "newMatch",
  jobCreateSuccess: "jobCreateSuccess",
  jobUpdateSuccess: "jobUpdateSuccess",
  jobDeleteSuccess: "jobDeleteSuccess",
  emailConfirmUpdate: "emailConfirmUpdate",
  emailConfirmNew: "emailConfirmNew",
  updatePasswordSuccess: "updatePasswordSuccess",
  resetPasswordSuccess: "resetPasswordSuccess",
  managerAssignmentError: "managerAssignmentError",
  unknownPictureFormat: "unknownPictureFormat",
  badPictureSize: "badPictureSize",
  badPictureResolution: "badPictureResolution",
  atsJobsSuccessfullyImported: "atsJobsSuccessfullyImported",
  atsCompanyCreationFailed: "atsCompanyCreationFailed",
  cardDeleteSuccess: "cardDeleteSuccess",
  teamCreatedSuccess: "teamCreatedSuccess",
  activateJobWarning: "activateJobWarning",
})

const alertsMapping = {
  ...atsErrorsMapping,
  ...moErrorsMapping,

  [alertTypes.success]: () => <FormattedMessage id="app.alertbar.success" />,
  [alertTypes.cardDeleteSuccess]: () => (
    <FormattedMessage id="app.alertbar.card.delete.success" />
  ),
  [alertTypes.matchAcceptSuccess]: () => (
    <FormattedMessage id="app.alertbar.match.accept.success" />
  ),
  [alertTypes.newMessage]: () => (
    <FormattedMessage id="app.alertbar.messages.new" />
  ),
  [alertTypes.subscriptionUpgradeSuccess]: () => (
    <FormattedMessage id="app.alertbar.subscription.upgrade.success" />
  ),
  [alertTypes.subscriptionRenewSuccess]: () => (
    <FormattedMessage id="app.alertbar.subscription.renew.success" />
  ),
  [alertTypes.subscriptionCancelSuccess]: () => (
    <FormattedMessage id="app.alertbar.subscription.cancel.success" />
  ),
  [alertTypes.matchDeclineSuccess]: () => (
    <FormattedMessage id="app.alertbar.match.decline.success" />
  ),
  [alertTypes.matchApproveSuccess]: () => (
    <FormattedMessage id="app.alertbar.match.approve.success" />
  ),
  [alertTypes.matchRejectSuccess]: () => (
    <FormattedMessage id="app.alertbar.match.reject.success" />
  ),
  [alertTypes.newMatch]: () => <FormattedMessage id="app.alertbar.match.new" />,
  [alertTypes.jobCreateSuccess]: () => (
    <FormattedMessage id="app.alertbar.job.create.success" />
  ),
  [alertTypes.jobUpdateSuccess]: () => (
    <FormattedMessage id="app.alertbar.job.update.success" />
  ),
  [alertTypes.jobDeleteSuccess]: () => (
    <FormattedMessage id="app.alertbar.job.delete.success" />
  ),
  [alertTypes.emailConfirmUpdate]: () => (
    <FormattedMessage id="app.alertbar.email.confirm.update" />
  ),
  [alertTypes.emailConfirmNew]: () => (
    <FormattedMessage id="app.alertbar.email.confirm.new.success" />
  ),
  [alertTypes.updatePasswordSuccess]: () => (
    <FormattedMessage id="app.alertbar.update.password.success" />
  ),
  [alertTypes.resetPasswordSuccess]: () => (
    <FormattedMessage id="app.alertbar.send.reset.password.email.success" />
  ),
  [alertTypes.unknownPictureFormat]: () => (
    <FormattedMessage id="app.alertbar.picture.unknown.format" />
  ),
  [alertTypes.badPictureSize]: () => (
    <FormattedMessage id="app.alertbar.picture.bad.size" />
  ),
  [alertTypes.badPictureResolution]: () => (
    <FormattedMessage id="app.alertbar.picture.bad.resolution" />
  ),
  [alertTypes.atsJobsSuccessfullyImported]: () => (
    <FormattedMessage id="app.alertbar.ats.jobs.imported" />
  ),
  [alertTypes.atsCompanyCreationFailed]: () => (
    <FormattedMessage id="app.alertbar.ats.company.creation.fail" />
  ),
  [alertTypes.teamCreatedSuccess]: () => (
    <FormattedMessage id="app.alertbar.company.teams.creation.success" />
  ),
  [alertTypes.activateJobWarning]: () => (
    <FormattedMessage id="app.alertbar.job.activate.warning" />
  ),
}

const getAlertText = code => {
  const alertText = alertsMapping[code]

  if (!alertText) {
    Sentry.withScope(scope => {
      scope.setExtra("alertCode", code)
      Sentry.captureException(new Error("Unknown alert code"))
    })

    if (config.env.isDev) {
      return () => `Missing alert or error type: ${code}`
    } else {
      return alertsMapping[moErrorTypes.defaultError]
    }
  }

  return alertText
}

const getClientErrorCode = err => {
  let errorCode = null

  errorCode = path(["response", "data", "code"], err)

  if (!errorCode) {
    errorCode = path(["code"], err)
  }

  if (
    errorCode &&
    errorCode === "validation_error" &&
    path(["response", "data", "fieldErrors"], err)
  ) {
    Object.values(err.response.data.fieldErrors).forEach(value => {
      if (value && value.length) {
        errorCode = value[0].code
      }
    })
  }

  if (
    path(["response", "data", "nonFieldErrors"], err) &&
    err.response.data.nonFieldErrors.length
  ) {
    errorCode = err.response.data.nonFieldErrors[0].code
  }

  return humps.camelize(errorCode)
}

const getErrorCode = err => {
  switch (path(["problem"], err)) {
    case PROBLEMS.CLIENT_ERROR:
      return getClientErrorCode(err)

    case PROBLEMS.SERVER_ERROR:
      return "serverError"

    case PROBLEMS.CONNECTION_ERROR:
    case PROBLEMS.TIMEOUT_ERROR:
    case PROBLEMS.NETWORK_ERROR:
      return "networkError"

    case PROBLEMS.CANCEL_ERROR:
    case PROBLEMS.UNKNOWN_ERROR:
    default:
      return "defaultError"
  }
}

export const showAlert = ({
  code,
  color = ALERT_COLORS.SUCCESS,
  options = {},
  values = {},
}) => {
  const Text = getAlertText(code)
  toast[color](<Text {...values} />, options)
}

export const showErrorAlert = ({ err, values = {}, options = {} }) => {
  return showAlert({
    code: getErrorCode(err),
    values,
    color: ALERT_COLORS.ERROR,
    options,
  })
}
