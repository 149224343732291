import React from "react"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import footerLogo from "../../assets/img/mb-logo-footer.svg"
import { Container, Row, Col } from "reactstrap"
import { FormattedLink } from "./formatted-link-component"
import { Img } from "./img-component"
import { config } from "../../config"

export function FooterComponent() {
  return (
    <footer className="main-footer bg-white text-center text-md-left">
      <Container>
        <Row className="py-3 py-md-5">
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="homePage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.homepage" />
                </FormattedLink>
              </li>
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="guide"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.guide" />
                </FormattedLink>
              </li>
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="blog"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.blog" />
                </FormattedLink>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="chatbot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.chatbot" />
                </FormattedLink>
              </li>
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="FAQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.faq" />
                </FormattedLink>
              </li>
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="support"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.common.support" />
                </FormattedLink>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.dataProtection" />
                </FormattedLink>
              </li>
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.terms" />
                </FormattedLink>
              </li>
              <li className="py-1">
                <FormattedLink
                  className="text-muted"
                  href="legalNotice"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="app.footer.legalNotice" />
                </FormattedLink>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3" className="text-md-right">
            <div className="py-1">
              <Img width="100" src={footerLogo} alt="Moberries Logo" />
            </div>
          </Col>
        </Row>
        <hr className="m-0 border-top" />
        <Row className="py-3 py-md-4">
          <Col xs="12" md="4">
            <span className="font-weight-light">
              &copy; MoBerries {config.buildYear}
            </span>
          </Col>
          <Col xs="12" md="8" className="text-md-right">
            <ul className="list-inline m-0">
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.facebook.com/moberries/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://twitter.com/MoBerries"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.linkedin.com/company/10009040"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.youtube.com/channel/UC22gC5eRgLqqnVg9zjSI53w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="text-muted"
                  href="https://www.instagram.com/teammoberries/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

FooterComponent.propTypes = {}

FooterComponent.defaultProps = {}

const mapDispatchToProps = {}

const mapStateToProps = function (state) {
  return {}
}

export const Footer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FooterComponent)
