import { useState, useCallback } from "react"
import { api as googleMapsApi } from "../lib/google-maps-api"
import debounce from "debounce-promise"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"

export const useGoogleLocationsLoader = () => {
  const [locations, setLocations] = useState([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadLocations = useCallback(
    debounce(async search => {
      const results = await googleMapsApi.getCities(search)
      setLocations(results)

      return results
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [],
  )

  return { locations, loadLocations }
}
