import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import {
  getFullName,
  isMatchRejected,
  formatDate,
  isMatchApproved,
} from "../../lib/helpers"
import { Link } from "react-router-dom"
import { TextLineLoader } from "../loaders/text-line-loader"
import { CandidateCVButton } from "../candidate/candidate-cv-button"
import { MATCH_STATUSES } from "../../constants"

export function threadMatchStatusMapper(match) {
  switch (match?.status) {
    case MATCH_STATUSES.COMPANY_REJECTED:
      return {
        tooltip: "app.page.matchList.rejected.tooltip",
        colorClassName: "text-danger",
      }
    case MATCH_STATUSES.COMPANY_APPROVED:
      return {
        tooltip: "app.page.matchList.approved.tooltip",
        colorClassName: "text-success",
      }
    default:
      return {}
  }
}

export function ThreadTopBar({ thread }) {
  const job = thread?.job
  const candidate = thread?.candidate
  const match = thread?.match
  const reviewedAt = thread?.reviewedAt
  const reviewedBy = thread?.reviewedBy
  const isRejected = isMatchRejected(match)
  const isApproved = isMatchApproved(match)
  const isClosedThread = isRejected || isApproved
  const threadMatchStatus = threadMatchStatusMapper(match)

  return (
    <Fragment>
      <div className="p-3 border-bottom d-flex">
        <div className="d-flex flex-grow-1 overflow-hidden">
          <Button
            tag={Link}
            to="/messages"
            color="light"
            className="mr-2 d-lg-none d-inline-block"
          >
            <i className="fas fa-chevron-left" />
          </Button>
          <div className="overflow-hidden lh-14 mr-2 flex-grow-1">
            {match ? (
              <Link
                to={`/match/${match.id}`}
                className="text-truncate d-block _lr-hide"
              >
                {getFullName(candidate)}
              </Link>
            ) : (
              <TextLineLoader width={80} height={19} />
            )}
            <div className="text-muted text-truncate _lr-hide">
              {job ? job.title : <TextLineLoader width={120} height={19} />}
            </div>
          </div>
        </div>
        {isClosedThread && (
          <Fragment>
            <div className={threadMatchStatus.colorClassName}>
              <FormattedMessage
                values={{
                  manager: reviewedBy?.name,
                }}
                id={threadMatchStatus.tooltip}
              />
            </div>
            {reviewedAt && (
              <div
                className={`${threadMatchStatus.colorClassName} text-sm-right ml-1`}
              >
                {formatDate(reviewedAt)}
              </div>
            )}
          </Fragment>
        )}

        {thread && !isClosedThread && (
          <CandidateCVButton
            className="btn-pill"
            color="primary"
            candidate={candidate}
            match={match}
          >
            <i className="fas fa-eye mr-lg-1" />
            <span className="d-none d-lg-inline">
              <FormattedMessage id="app.common.previewCv" />
            </span>
          </CandidateCVButton>
        )}
      </div>
    </Fragment>
  )
}

ThreadTopBar.propTypes = {
  thread: PropTypes.object,
}
