import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { ThreadList } from "../components/messenger/thread-list-component"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { Container, Row, Col } from "reactstrap"
import { Thread } from "../components/messenger/thread-component"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router"
import { useBreakpoint } from "../hooks/use-breakpoint"

export const MessengerPage = ({ match }) => {
  const threadId = match.params.id ? Number(match.params.id) : null
  const history = useHistory()
  const isDesktop = useBreakpoint("lg")

  return (
    <Fragment>
      {isDesktop && (
        <Container>
          <Row noGutters>
            <Col xs="5">
              <ThreadList
                activeThreadId={threadId}
                onClickThread={thread => {
                  history.push(`/messages/${thread.id}`)
                }}
              />
            </Col>
            <Col className="border-left" xs="7">
              {threadId ? (
                <Thread threadId={threadId} />
              ) : (
                <div className="bg-white messenger-height d-flex align-items-center justify-content-center flex-column text-muted">
                  <i className="far fa-comments fa-3x mb-2" />

                  <h6>
                    <FormattedMessage id="app.page.messages.selectThread" />
                  </h6>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}

      {!isDesktop && (
        <SmallSingleColumnLayout>
          {threadId ? (
            <Thread threadId={threadId} />
          ) : (
            <ThreadList
              useWindowScroll
              onClickThread={thread => {
                history.push(`/messages/${thread.id}`)
              }}
            />
          )}
        </SmallSingleColumnLayout>
      )}
    </Fragment>
  )
}

MessengerPage.propTypes = {
  match: PropTypes.object.isRequired,
}

MessengerPage.defaultProps = {
  err: null,
}
