import React from "react"
import PropTypes from "prop-types"
import { getCertificatesDiff } from "../../lib/helpers"
import { DetailViewCertificateComponent } from "../detail-view/detail-view-certificate-component"

export const MatchCertificates = ({ candidate, job }) => {
  const { matched: matchedCertificates, rest: otherCertificates } =
    getCertificatesDiff(candidate.certificates, job.jobRoles)

  return (
    <>
      {matchedCertificates.map(certificate => (
        <DetailViewCertificateComponent
          key={certificate.id}
          {...certificate}
          isMatch
        />
      ))}

      {otherCertificates.map(certificate => (
        <DetailViewCertificateComponent key={certificate.id} {...certificate} />
      ))}
    </>
  )
}

MatchCertificates.propTypes = {
  candidate: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
}
