import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import {
  JOB_STATUSES_TEXT_MAP,
  JOB_STATUSES_COLOR_MAP,
} from "../../default-options"
import { PAU } from "../../constants"

export function JobStatusTag({ job, colorMap, jobIntegration }) {
  const renderStatusTag = () => {
    if (job.status === PAU && jobIntegration?.isExcludedFromSync) {
      return (
        <FormattedMessage id="app.job.status.additional.excluded-sync.paused" />
      )
    }
    return <FormattedMessage id={JOB_STATUSES_TEXT_MAP[job.status]} />
  }

  return (
    <div>
      <span className="text-muted d-block d-md-inline">Status: </span>
      <strong className={`text-${colorMap[job.status]}`}>
        {renderStatusTag()}
      </strong>
    </div>
  )
}

JobStatusTag.propTypes = {
  job: PropTypes.object,
  colorMap: PropTypes.object,
  jobIntegration: PropTypes.object,
}

JobStatusTag.defaultProps = {
  colorMap: JOB_STATUSES_COLOR_MAP,
}
