import { config } from "../config"
import scriptjs from "scriptjs"

// scriptjs resolves even in case of failure
const loadScript = url =>
  new Promise(res => {
    scriptjs(url, res)
  })

class GoogleMapsApi {
  libUrl = `https://maps.googleapis.com/maps/api/js?key=${config.google.api.places.key}&libraries=places&language=en`

  maxTries = 5
  tries = 0

  apiService = null

  get isLibLoaded() {
    return Boolean(window.google?.maps?.places?.AutocompleteService)
  }

  loadLib = async () => {
    while (!this.isLibLoaded && this.tries < this.maxTries) {
      this.tries++
      await loadScript(this.libUrl)
    }

    if (!this.isLibLoaded) {
      throw new Error(
        `Failed to load google maps autocomplete service after ${this.maxTries} tries`,
      )
    }
  }

  initializeApiService = async () => {
    try {
      await this.loadLib()
    } catch (err) {
      this.tries = 0
    }

    this.apiService = new window.google.maps.places.AutocompleteService()
    return this.apiService
  }

  getApiService = async () => {
    if (this.apiService) {
      return this.apiService
    } else {
      return this.initializeApiService()
    }
  }

  getCities = async search => {
    let service = null
    try {
      service = await this.getApiService()
    } catch (err) {
      console.error(err)
      return Promise.resolve([
        {
          placeId: "__error__loading__google__maps__api__",
          name:
            "There was a problem loading the google maps api, please try to refresh the page. If that doesn't work, try to disable browser extensions",
        },
      ])
    }

    return new Promise(resolve => {
      service.getPlacePredictions(
        { input: search, types: ["(cities)"] },
        (result, status) => {
          if (status === "OK") {
            resolve(
              result.map(location => ({
                placeId: location.place_id,
                name: location.description,
              })),
            )
          } else {
            resolve([])
          }
        },
      )
    })
  }
}

export const api = new GoogleMapsApi()
