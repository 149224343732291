import React from "react"
import { FormattedMessage } from "react-intl"
import { DashboardWidgetLayout } from "../../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import talents from "../../../../assets/img/illustration-people.svg"
import styles from "./talents-you-miss-widget-empty.module.scss"

export const TalentsYouMissWidgetEmpty = () => {
  return (
    <DashboardWidgetLayout
      className={styles.container}
      title={<FormattedMessage id="app.widget.talentsYouMiss.title" />}
    >
      <img src={talents} height="74" width="74" alt="search" />
      <p className={styles.text}>
        <FormattedMessage id="app.page.analytics.charts.talentsYouMiss.empty.text" />
      </p>
    </DashboardWidgetLayout>
  )
}
