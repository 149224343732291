import React from "react"
import PropTypes from "prop-types"
import { ListGroupItem } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { InvoiceStatusTag } from "./invoice-status-tag-component"
import { FormattedDate } from "../common/formatted-date-component"

export const Invoice = ({ invoice }) => {
  const { id, date, paid, invoicePdf } = invoice
  return (
    <ListGroupItem className="d-flex justify-content-between align-items-center">
      <InvoiceStatusTag id={id} paid={paid} />
      <div className="text-right">
        <div className="text-muted">
          <small>
            <FormattedDate value={date} />
          </small>
        </div>
        <Button
          className="p-0"
          onClick={() => window.open(invoicePdf)}
          color="link"
        >
          <FormattedMessage id="app.common.download" />
        </Button>
      </div>
    </ListGroupItem>
  )
}

Invoice.propTypes = {
  invoice: PropTypes.object.isRequired,
}
