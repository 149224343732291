import React from "react"
import PropTypes from "prop-types"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"

export const AtsMergeSingleJob = ({
  onPause,
  onMerge,
  job,
  isDisabled,
  isLoading,
  isPausable,
}) => {
  return (
    <div
      key={job.id}
      className="d-md-flex justify-content-between py-2 border-top align-items-center"
    >
      <div>{job.title}</div>
      <div className="mt-1 mt-md-0">
        {isPausable && (
          <Button
            disabled={isDisabled}
            loading={isLoading}
            size="sm"
            onClick={onPause}
          >
            <FormattedMessage id="app.common.pause" />
          </Button>
        )}

        <Button
          disabled={isDisabled}
          size="sm"
          color="success"
          className="ml-1"
          onClick={onMerge}
        >
          <FormattedMessage id="app.common.connect" />
        </Button>
      </div>
    </div>
  )
}

AtsMergeSingleJob.propTypes = {
  onPause: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPausable: PropTypes.bool.isRequired,
}

AtsMergeSingleJob.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isPausable: true,
}
