import React from "react"
import PropTypes from "prop-types"
import { Redirect, useLocation } from "react-router"
import { isLoggedInSelector } from "../../lib/helpers"
import { useStore } from "../../lib/store"

export const LoggedInGuard = ({ children, redirect }) => {
  const isLoggedIn = useStore(isLoggedInSelector)
  const location = useLocation()

  if (isLoggedIn) {
    return children
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location.pathname },
        }}
      />
    )
  }
  return null
}

LoggedInGuard.defaultProps = {
  redirect: false,
}

LoggedInGuard.propTypes = {
  children: PropTypes.node.isRequired,
  redirect: PropTypes.bool,
}
