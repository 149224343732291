import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Badge } from "./badge-component"

export const FeedbackOption = ({ isActive, id, name, onClick }) => {
  return (
    <span className="lead">
      <Badge
        onClick={onClick}
        color={isActive ? "success-light" : "default"}
        border
        className="clickable"
        id={`feedback-option-popover-${id}`}
      >
        <span>
          <FormattedMessage id={name} />
        </span>
      </Badge>
    </span>
  )
}

FeedbackOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
}
