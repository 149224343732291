import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { IntegrationsPageLayout } from "../components/layouts/integrations-page-layout"
import { TabPane } from "reactstrap"
import { SlackIntegrationCard } from "../components/integration/slack-integration-card"
import {
  isConnectingSelector,
  slackIntegrationSelector,
} from "../selectors/slack-integration-selector"
import {
  connectWithSlackRedirect,
  getSlackIntegrationList,
} from "../actions/slack-integration-actions"
import { connect } from "react-redux"
import { useStore } from "../lib/store"

export const OtherIntegrationsPageComponent = ({
  isSlackConnecting,
  slackIntegration,
  connectWithSlackRedirect,
  getSlackIntegrationList,
}) => {
  const company = useStore(state => state.company)
  useEffect(() => {
    getSlackIntegrationList({ companyId: company.id })
  }, [getSlackIntegrationList, company.id])

  return (
    <IntegrationsPageLayout>
      <TabPane className="pt-2 bg-white rounded-bottom">
        <SlackIntegrationCard
          disabled={isSlackConnecting}
          loading={isSlackConnecting}
          slackIntegration={slackIntegration}
          onConnectClick={() => connectWithSlackRedirect({})}
        />
      </TabPane>
    </IntegrationsPageLayout>
  )
}

OtherIntegrationsPageComponent.propTypes = {
  isSlackConnecting: PropTypes.bool.isRequired,
  slackIntegration: PropTypes.object,
  connectWithSlackRedirect: PropTypes.func.isRequired,
  getSlackIntegrationList: PropTypes.func.isRequired,
}

OtherIntegrationsPageComponent.defaultProps = {
  isSlackConnecting: false,
}

const mapStateToProps = state => ({
  slackIntegration: slackIntegrationSelector(state),
  isSlackConnecting: isConnectingSelector(state),
})

const mapDispatchToProps = {
  connectWithSlackRedirect,
  getSlackIntegrationList,
}

export const OtherIntegrationsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OtherIntegrationsPageComponent)
