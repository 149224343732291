import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { Input } from "../common/input-component"
import { useDebounce } from "../../hooks/use-debounce"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"
import { useLocation } from "react-router"
import { updateQueryParams } from "../../lib/helpers"
import { usePrevious } from "react-hanger"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../constants"
import qs from "qs"
import { zipObj, times, always } from "ramda"

export const SearchFilter = ({
  filterKey,
  disabled,
  label,
  resetFilters,
  placeholderKey,
}) => {
  const location = useLocation()
  const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [search, setSearch] = useState(query.search)
  const debouncedUpdateSearch = useDebounce(setSearch, SEARCH_DEBOUNCE_TIMEOUT)
  const prevSearch = usePrevious(search)
  const [inputValue, setInputValue] = useState(search ?? "")

  useEffect(() => {
    setSearch(query.search)
    setInputValue(query.search ?? "")
  }, [query.search])

  const dispatch = useDispatch()
  const intl = useIntl()

  const resetFiltersObj = zipObj(
    resetFilters,
    times(always(null), resetFilters.length),
  )

  useEffect(() => {
    if (search !== prevSearch) {
      dispatch(
        push({
          pathname: location.pathname,
          search: updateQueryParams({
            search: location.search,
            params: { [filterKey]: search || null, ...resetFiltersObj },
          }),
        }),
      )
    }
  }, [
    search,
    dispatch,
    filterKey,
    location.pathname,
    location.search,
    prevSearch,
    resetFiltersObj,
  ])

  return (
    <Input
      className="mb-0"
      label={label}
      disabled={disabled}
      input={{
        value: inputValue,
        onChange: e => {
          const search = e.target.value
          setInputValue(search)
          debouncedUpdateSearch(search)
        },
        placeholder: intl.formatMessage({
          id: placeholderKey,
        }),
      }}
      meta={{}}
    />
  )
}

SearchFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.node,
  resetFilters: PropTypes.arrayOf(PropTypes.string),
  placeholderKey: PropTypes.string.isRequired,
}

SearchFilter.defaultProps = {
  disabled: false,
  resetFilters: [],
}
