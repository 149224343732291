import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import qs from "qs"
import { connect } from "react-redux"
import { isEmpty, isNil, pathOr } from "ramda"
import { DoubleColumnLayout } from "../components/layouts/double-column-layout"
import { ExtendedMatchCard } from "../components/match/extended-match-card-component"
import { MatchDetails } from "../components/match/match-details-component"
import { AcceptDeclineMatchControls } from "../components/match/accept-decline-match-controls"
import { ApproveRejectMatchControls } from "../components/match/approve-reject-match-controls"
import { MatchDeclineFeedback } from "../components/match/match-decline-feedback-component"
import { MatchPageSkeleton } from "../components/skeletons/match-page-skeleton"
import { ErrorPage } from "./error-page"
import {
  acceptingSelector,
  approvingSelector,
  decliningSelector,
  matchByIdSelector,
  nextMatchSelector,
  rejectingSelector,
  updatingSelector,
  errorSelector as matchErrorSelector,
  matchesForOtherPositionsSelector,
  matchRejectedReasonsByMatchSelector,
} from "../selectors/match-selector"
import {
  getMatch,
  acceptMatch,
  declineMatch,
  approveMatch,
  rejectMatch,
} from "../actions/match-actions"
import {
  jobSelector,
  errorSelector as jobErrorSelector,
} from "../selectors/job-selector"
import {
  candidateSelector,
  errorSelector as candidateErrorSelector,
} from "../selectors/candidate-selector"
import { IntegrationSuggestion } from "../components/integration/integration-suggestion-component"
import { getAtsJobIntegrationList } from "../actions/ats-job-integration-actions"
import {
  atsJobIntegrationByJobIdSelector,
  isLoadingSelector as isAtsJobIntegrationLoadingSelector,
} from "../selectors/ats-job-integration-selector"
import { MatchHiringFeeHint } from "../components/match/match-hiring-fee-component"
import {
  shouldRedirectToDashboard,
  isMatchRejected,
  isComSubscriptionPaused,
  isComSubscriptionExpired,
  isComSubscriptionCanceled,
  getFullName,
  shouldRenderMatchMessage,
  shouldRenderAcceptDeclineControls,
  companySubscriptionSelector,
} from "../lib/helpers"
import { Redirect } from "react-router-dom"
import { MatchRejectedReasons } from "../components//match/match-rejected-reasons"
import { usePrevious } from "react-hanger"
import { useOpenClose } from "../hooks/use-open-close"
import { MATCH_STATUSES } from "../constants"
import { TalentPoolWidget } from "../components/sidebar/talent-pool-widget"
import { NextMatchCard } from "../components/sidebar/next-match-card-component"
import { OtherMatchList } from "../components/sidebar/other-match-list-component"
import { MatchStatisticsWidget } from "../components/sidebar/match-statistics-widget"
import { ContactNow } from "../components/match/contact-now"
import { store, useStore } from "../lib/store"
import { MatchApplicationAnswers } from "../components/match/match-application-answers-component"
import { MatchApplicationMotivationalNote } from "../components/match/match-application-motivational-note"

export const Match = ({
  match,
  nextMatch,
  candidate,
  job,
  matches,
  err,
  acceptMatch,
  declineMatch,
  approveMatch,
  rejectMatch,
  updating,
  accepting,
  declining,
  approving,
  rejecting,
  nextCandidate,
  location,
  matchRejectedReasons,
  atsJobIntegration,
  atsJobIntegrationLoading,
  matchId,
  jobId,
  getMatch,
  getAtsJobIntegrationList,
}) => {
  const manager = useStore(state => state.manager)
  const company = useStore(state => state.company)
  const locale = useStore(state => state.locale)
  const companySubscription = useStore(companySubscriptionSelector)
  let { atsCompanyIntegration } = store.getState()
  const prevMatchId = usePrevious(matchId)

  const {
    value: isDeclineFeedbackOpen,
    open: openDeclineFeedback,
    close: closeDeclineFeedback,
  } = useOpenClose({ initialValue: false })

  const {
    value: isApproveRejectVisible,
    open: showApproveReject,
    close: hideApproveReject,
  } = useOpenClose({ initialValue: true })

  useEffect(() => {
    showApproveReject()
  }, [matchId, jobId, showApproveReject])

  useEffect(() => {
    if (prevMatchId !== matchId && isDeclineFeedbackOpen) {
      closeDeclineFeedback()
    }
  }, [prevMatchId, matchId, closeDeclineFeedback, isDeclineFeedbackOpen])

  useEffect(() => {
    getMatch({ id: matchId })
  }, [getMatch, matchId])

  useEffect(() => {
    if (jobId) {
      getAtsJobIntegrationList({ jobIds: [jobId] })
    }
  }, [getAtsJobIntegrationList, jobId])

  const matchStatus = match?.status
  const prevMatchStatus = usePrevious(matchStatus)

  useEffect(() => {
    if (
      prevMatchStatus === MATCH_STATUSES.CANDIDATE_ACCEPTED &&
      matchStatus === MATCH_STATUSES.COMPANY_ACCEPTED
    ) {
      hideApproveReject()
    }
  }, [matchStatus, prevMatchStatus, hideApproveReject])

  if (match && shouldRedirectToDashboard(match, companySubscription)) {
    return <Redirect to="/" />
  }

  if (err) {
    return <ErrorPage err={err} />
  }

  if (!match || !candidate || !job) {
    return <MatchPageSkeleton />
  }

  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const reject = params.reject === ""
  const accept = params.accept === ""
  const decline = params.decline === ""

  const isIntegrationSuggestionVisible =
    !atsJobIntegrationLoading && atsCompanyIntegration && !atsJobIntegration

  return (
    <Fragment>
      <DoubleColumnLayout>
        <DoubleColumnLayout.Content>
          <Fragment>
            <ExtendedMatchCard match={match} candidate={candidate} job={job} />
            {shouldRenderMatchMessage(match) && (
              <ContactNow matchId={match.id} key={match.id} />
            )}
            <MatchHiringFeeHint locale={locale} match={match} />

            {!isDeclineFeedbackOpen && (
              <Fragment>
                {isIntegrationSuggestionVisible && (
                  <IntegrationSuggestion jobId={match.job.id} />
                )}
                {shouldRenderAcceptDeclineControls(match) && (
                  <AcceptDeclineMatchControls
                    matchId={match.id}
                    disabled={updating}
                    acceptLoading={accepting}
                    declineLoading={declining}
                    acceptMatch={acceptMatch}
                    accept={accept}
                    decline={decline}
                    onDeclineClick={() => {
                      openDeclineFeedback()
                    }}
                  />
                )}
              </Fragment>
            )}

            {isApproveRejectVisible && (
              <ApproveRejectMatchControls
                match={match}
                disabled={updating}
                approveLoading={approving}
                isRejecting={rejecting}
                onApproveClick={approveMatch}
                onRejectClick={rejectMatch}
                reject={reject}
              />
            )}

            {isDeclineFeedbackOpen && (
              <MatchDeclineFeedback
                closeDeclineFeedback={closeDeclineFeedback}
                declineMatch={declineMatch}
                match={match}
                candidate={candidate}
                job={job}
                declining={declining}
              />
            )}

            {isMatchRejected(match) && matchRejectedReasons && (
              <MatchRejectedReasons
                matchRejectedReasons={matchRejectedReasons}
              />
            )}

            {!isNil(match.coverNote) && (
              <MatchApplicationMotivationalNote
                motivationalNote={match.coverNote}
              />
            )}

            {!isEmpty(match.applicationAnswers) && (
              <MatchApplicationAnswers
                applicationAnswers={match.applicationAnswers}
              />
            )}

            {!isDeclineFeedbackOpen && (
              <MatchDetails match={match} candidate={candidate} job={job} />
            )}
          </Fragment>
        </DoubleColumnLayout.Content>
        <DoubleColumnLayout.Sidebar>
          <Fragment>
            {isComSubscriptionPaused(companySubscription) ||
            isComSubscriptionExpired(companySubscription) ||
            isComSubscriptionCanceled(companySubscription) ||
            (!nextMatch && matches.length === 0) ? (
              <Fragment>
                <TalentPoolWidget
                  slug={company.slug}
                  statistics={company.statistics}
                  subscriptionType={pathOr(
                    "",
                    ["subscription", "type"],
                    companySubscription,
                  )}
                  companyId={company.id}
                  managerName={getFullName(manager)}
                  companyName={company.name}
                />
                <MatchStatisticsWidget statistics={company.statistics} />
              </Fragment>
            ) : (
              <Fragment>
                <NextMatchCard
                  referenceMatch={match}
                  nextMatch={nextMatch}
                  nextCandidate={nextCandidate}
                  className="mb-3"
                />
                <OtherMatchList matches={matches} />
              </Fragment>
            )}
          </Fragment>
        </DoubleColumnLayout.Sidebar>
      </DoubleColumnLayout>
    </Fragment>
  )
}

const mapDispatchToProps = {
  getMatch,
  acceptMatch,
  declineMatch,
  approveMatch,
  rejectMatch,
  getAtsJobIntegrationList,
}

const mapStateToProps = function (state, props) {
  const matchId = parseInt(props.match.params.id, 10)
  const match = matchByIdSelector(state, { id: matchId })
  const nextMatch = nextMatchSelector(state)
  const matches = matchesForOtherPositionsSelector(state, {
    id: match?.job.id,
    status: MATCH_STATUSES.CANDIDATE_ACCEPTED,
  })
  const job = jobSelector(state, { id: match && match.job.id })
  const candidate = candidateSelector(state, {
    id: match && match.candidate.id,
  })
  const nextCandidate = candidateSelector(state, {
    id: nextMatch && nextMatch.candidate.id,
  })

  const matchErr = matchErrorSelector(state)
  const jobError = jobErrorSelector(state)
  const candidateError = candidateErrorSelector(state)

  const jobId = match && match.job.id

  return {
    job,
    candidate,
    match,
    nextCandidate,
    nextMatch,
    matches,
    matchId,
    jobId,

    err: matchErr || jobError || candidateError,
    updating: updatingSelector(state),
    accepting: acceptingSelector(state),
    declining: decliningSelector(state),
    approving: approvingSelector(state),
    rejecting: rejectingSelector(state),

    atsJobIntegration: atsJobIntegrationByJobIdSelector(state, {
      id: jobId,
    }),
    atsJobIntegrationLoading: isAtsJobIntegrationLoadingSelector(state),
    matchRejectedReasons: matchRejectedReasonsByMatchSelector(state, {
      matchId,
    }),
  }
}

Match.propTypes = {
  match: PropTypes.object,
  job: PropTypes.object,
  candidate: PropTypes.object,
  nextMatch: PropTypes.object,
  nextCandidate: PropTypes.object,
  matches: PropTypes.array.isRequired,
  matchId: PropTypes.number.isRequired,
  getMatch: PropTypes.func.isRequired,
  acceptMatch: PropTypes.func.isRequired,
  declineMatch: PropTypes.func.isRequired,
  approveMatch: PropTypes.func.isRequired,
  rejectMatch: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  accepting: PropTypes.bool.isRequired,
  declining: PropTypes.bool.isRequired,
  approving: PropTypes.bool.isRequired,
  rejecting: PropTypes.bool.isRequired,
  err: PropTypes.object,
  jobId: PropTypes.number,
  matchRejectedReasons: PropTypes.object,

  getAtsJobIntegrationList: PropTypes.func.isRequired,
  atsJobIntegration: PropTypes.object,
  atsJobIntegrationLoading: PropTypes.bool.isRequired,

  location: PropTypes.object.isRequired,
}

Match.defaultProps = {
  company: null,
  match: null,
  thread: null,
  candidate: null,
  job: null,
  manager: null,
  nextMatch: null,
  nextCandidate: null,
  matches: [],
  updating: false,
  accepting: false,
  declining: false,
  approving: false,
  rejecting: false,
  matchRejectedReasons: null,

  atsJobIntegration: null,
  atsJobIntegrationLoading: false,
}

export const MatchPage = connect(mapStateToProps, mapDispatchToProps)(Match)
