import React, { forwardRef, Fragment, useState } from "react"
import PropTypes from "prop-types"
import {
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import RDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { formatISO, max, min } from "date-fns"
import { useIntl, FormattedMessage } from "react-intl"
import { Button } from "./button-component"
import { dateRangeToOption } from "../../lib/helpers"

export const getDateRangeId = ({ startDate, endDate }) =>
  `${formatISO(startDate, { representation: "date" })}to${formatISO(endDate, {
    representation: "date",
  })}`

const CustomInput = forwardRef(
  ({ label, inputId, ...datePickerProps }, ref) => {
    return (
      <Fragment>
        <Label>{label}</Label>
        <InputGroup className="border rounded">
          <Input
            {...datePickerProps}
            id={inputId}
            readOnly
            color="light"
            ref={ref}
            placeholder="DD.MM.YYYY"
            className="border-0 clickable bg-light"
          />
          <InputGroupAddon addonType="append">
            <InputGroupText className="border-0 bg-light">
              <i className="fas fa-calendar-alt text-muted" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Fragment>
    )
  },
)
CustomInput.propTypes = {
  label: PropTypes.node.isRequired,
  inputId: PropTypes.string.isRequired,
}

export const DatePicker = ({ selectRange, minDate, maxDate, id }) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const intl = useIntl()

  return (
    <Fragment>
      <div className="mb-2">
        <RDatePicker
          locale={intl.locale}
          wrapperClassName="w-100"
          closeOnScroll={true}
          dateFormat="dd.MM.yyyy"
          minDate={minDate}
          maxDate={min([maxDate, endDate || maxDate])}
          selected={startDate}
          onChange={setStartDate}
          customInput={
            <CustomInput
              label={<FormattedMessage id="app.common.from" />}
              inputId={`${id}-from`}
            />
          }
        />
      </div>

      <div className="mb-2">
        <RDatePicker
          wrapperClassName="w-100"
          closeOnScroll={true}
          dateFormat="dd.MM.yyyy"
          selected={endDate}
          onChange={setEndDate}
          maxDate={maxDate}
          minDate={max([minDate, startDate || minDate])}
          customInput={
            <CustomInput
              label={<FormattedMessage id="app.common.to" />}
              inputId={`${id}-to`}
            />
          }
        />
      </div>

      <div></div>
      <Button
        onClick={() => {
          if (!startDate || !endDate) {
            return
          }

          const selectedRange = dateRangeToOption({
            startDate,
            endDate,
            id: getDateRangeId({
              startDate,
              endDate,
            }),
          })

          selectRange(selectedRange)
        }}
        color="light"
        className="w-100"
      >
        <FormattedMessage id="app.common.select" />
      </Button>
    </Fragment>
  )
}

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  selectRange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}
