import { identity } from "ramda"
import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { MonetizationDonutChart } from "./monetization-donut-chart"

export const MonetizationChartSection = ({ data, statsKey, isLoading }) => (
  <div>
    <h5 className="border-bottom py-2 mb-0">
      <FormattedMessage
        id={`app.page.monetization.candidatesDetails.${statsKey}`}
      />
    </h5>
    <div className="position-relative">
      <MonetizationDonutChart width={600} height={400} data={data} />
      {isLoading && (
        <div className="loading-overlay">
          <i className="fas fa-circle-notch fa-2x fa-spin text-primary" />
        </div>
      )}
    </div>
  </div>
)

MonetizationChartSection.propTypes = {
  statsKey: PropTypes.oneOf(["jobCategories", "careerLevels"]),
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

MonetizationChartSection.defaultProps = { formatName: identity }
