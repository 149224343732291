export const legendsMap = [
  {
    label: "company",
    color: "#ec5134",
    mutedColor: "#f28e86",
  },
  {
    label: "job",
    color: "#fab500",
    mutedColor: "#fed366",
  },
]
