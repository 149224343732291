import PropTypes from "prop-types"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"
import { useOpenClose } from "../../hooks/use-open-close"
import { shortenText } from "../../lib/helpers/text-helpers"

export const MatchApplicationMotivationalNote = ({ motivationalNote }) => {
  const { value: isExpanded, toggle } = useOpenClose({ initialValue: false })
  const initialTextLength = 200

  return (
    <div className="bg-white p-3 border-bottom">
      <h4 className="mb-0 pb-3">
        <FormattedMessage id="app.page.match.motivationalNote.title" />
      </h4>
      <span className="text-break">
        {motivationalNote.length <= initialTextLength || isExpanded
          ? motivationalNote
          : shortenText(motivationalNote, initialTextLength)}
      </span>
      {motivationalNote.length > initialTextLength && (
        <Button
          color="link"
          onClick={toggle}
          className="m-0 p-0 shadow-none text-decoration-none"
        >
          {isExpanded ? (
            <FormattedMessage id="app.page.integrations.settings.less" />
          ) : (
            <FormattedMessage id="app.page.integrations.settings.more" />
          )}
        </Button>
      )}
    </div>
  )
}

MatchApplicationMotivationalNote.propTypes = {
  motivationalNote: PropTypes.string.isRequired,
}
