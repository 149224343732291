import React, { Fragment, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  Redirect,
  Route,
  Switch,
  NavLink as RLink,
  useRouteMatch,
} from "react-router-dom"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { FormattedMessage } from "react-intl"
import { InnerFormLayout } from "../components/layouts/inner-form-layout"
import { ErrorPage } from "./error-page"
import { JobEditPageSkeleton } from "../components/skeletons/job-edit-page-skeleton"
import { JobControls } from "../components/job/job-controls"
import { DEL, REV, ALERT_COLORS } from "../constants"
import { useStore } from "../lib/store"
import { Nav, NavItem, NavLink } from "reactstrap"
import { JobApplicationQuestions } from "../components/job/job-application-questions-component"
import { Img } from "../components/common/img-component"
import { getAtsJobIntegrationList } from "../actions/ats-job-integration-actions"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../lib/moberries-api"
import {
  editorStateToHtml,
  htmlToEditorState,
} from "../lib/helpers/text-helpers"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { Formik, Form } from "formik"
import { JobConditionField } from "../components/fields/job-condition-field"
import { FirstErrorScroll } from "../components/fields/first-error-scroll"
import { jobDescriptionSchema, jobDetailsSchema } from "../schemas/job-schemas"
import { LabeledJobAtsIntegrationField } from "../components/job/job-ats-integration-field"
import { LabeledInputField } from "../components/fields/input-field"
import { LabeledRichTextEditorField } from "../components/fields/rich-text-editor-field"
import { JobDetailsFormFields } from "../components/job/job-details-form-fields"
import { JOB_LEVELS, LANG_LEVELS, CAREER_LEVELS } from "../default-options"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { useQueryClient } from "react-query"
import { SubmitButtonField } from "../components/fields/submit-button-field"
import { alertTypes, showAlert } from "../lib/alerts"
import { refreshCompany } from "../controllers/company-controller"
import { updateAtsJob } from "../actions/job-actions"

const jobToFormValues = ({
  salaryMin,
  salaryMax,
  condition,
  description,
  jobRoles,
  languages,
  company,
  careerLevel,
  team,
  teams,
  ...rest
}) => ({
  salary: { min: salaryMin, max: salaryMax },
  conditionType: condition.type,
  description: htmlToEditorState(description),
  jobRoles: jobRoles.map(jr => ({
    ...jr,
    levelName: JOB_LEVELS.find(l => l.id === jr.level).name,
  })),
  languages: languages.map(l => ({
    ...l,
    levelName: LANG_LEVELS.find(ll => ll.id === l.level).name,
  })),
  careerLevel: CAREER_LEVELS.find(l => l.id === careerLevel),
  team: teams.find(t => t.id === team),
  ...rest,
})

const formToJob = ({
  careerLevel,
  conditionType,
  description,
  team,
  salary,
  ...values
}) => ({
  ...values,
  careerLevel: careerLevel.id,
  condition: {
    type: conditionType,
  },
  description: editorStateToHtml(description),
  team: team.id,
  salaryMin: salary.min,
  salaryMax: salary.max,
})

export const JobEditPage = () => {
  let {
    path,
    params: { id: jobId },
  } = useRouteMatch()
  jobId = Number(jobId)
  const queryClient = useQueryClient()

  const { data: job, error } = useAxiosQuery(
    [ApiKeys.Job, { id: jobId }],
    moberriesApi.getJob,
  )
  const { mutateAsync: updateJob } = useAxiosMutation(moberriesApi.updateJob, {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiKeys.Job, { id: job.id }])
      refreshCompany()
      if (job.status === REV) {
        showAlert({
          color: ALERT_COLORS.WARNING,
          code: alertTypes.activateJobWarning,
        })
      }
    },
  })
  const company = useStore(state => state.company)
  const manager = useStore(state => state.manager)
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!atsCompanyIntegration) {
      dispatch(getAtsJobIntegrationList({}))
    }
  }, [dispatch, atsCompanyIntegration])

  if (error) {
    return <ErrorPage err={error} />
  }
  if (!job) {
    return <JobEditPageSkeleton />
  }
  if (job.status === DEL) {
    return <Redirect to="/jobs" />
  }

  return (
    <Fragment>
      <SingleColumnLayout>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.job.form.edit.header" />
        </h1>
        <div className="d-flex bg-white p-3 rounded align-items-center mb-4 justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <Img
              src={company.logo}
              alt={company.name}
              width="60"
              height="60"
              className="mr-3"
            />
            <h4 className="mb-0">{company.name}</h4>
          </div>
          <JobControls job={job} />
        </div>

        <Nav tabs justified className="settings-tabs mt-2">
          <NavItem>
            <NavLink
              tag={RLink}
              to={`/job/edit/${jobId}`}
              exact
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.jobEdit.tabs.details" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              to={`/job/edit/${jobId}/application-questions`}
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.jobEdit.tabs.questions" />
            </NavLink>
          </NavItem>
        </Nav>
        <div className="bg-white rounded px-3 py-5">
          <InnerFormLayout>
            <Switch>
              <Route path={path} exact>
                <Formik
                  initialValues={jobToFormValues({
                    ...job,
                    teams: manager.teams,
                  })}
                  onSubmit={submitAndValidate(async values => {
                    await dispatch(
                      updateAtsJob({
                        id: jobId,
                        job: values,
                      }),
                    )
                    return updateJob({
                      id: jobId,
                      job: { ...values, status: job.status },
                    })
                  }, formToJob)}
                  validationSchema={jobDescriptionSchema.concat(
                    jobDetailsSchema,
                  )}
                  enableReinitialize
                >
                  <Form>
                    <FirstErrorScroll />

                    <h1 className="mb-4">
                      <FormattedMessage id="app.job.form.posting.type.title" />
                    </h1>
                    <JobConditionField name="conditionType" />
                    <h1 className="mb-4">
                      <FormattedMessage id="app.job.form.posting.job.info.title" />
                    </h1>
                    <LabeledJobAtsIntegrationField name="atsJob" />
                    <LabeledInputField
                      name="title"
                      id="title"
                      label={<FormattedMessage id="app.job.form.title.label" />}
                      isRequired
                    />
                    <LabeledRichTextEditorField
                      name="description"
                      id="description"
                      label={
                        <FormattedMessage id="app.job.form.description.label" />
                      }
                      isRequired
                    />

                    <JobDetailsFormFields />
                    <div className="d-flex justify-content-between">
                      <SubmitButtonField size="lg" color="primary" block>
                        <FormattedMessage id="app.common.save" />
                      </SubmitButtonField>
                    </div>
                  </Form>
                </Formik>
              </Route>
              <Route path={`${path}/application-questions`}>
                <JobApplicationQuestions jobId={jobId} />
              </Route>
            </Switch>
          </InnerFormLayout>
        </div>
      </SingleColumnLayout>
    </Fragment>
  )
}
