import { useEffect } from "react"
import { emitter } from "../lib/event-emitter"

export const useEvent = (event, func, options = {}) => {
  useEffect(() => {
    emitter.on(event, func)
    return () => {
      emitter.off(event, func)
    }
  }, [event, func])
}
