import { useEffect } from "react"
import { useHistory, useRouteMatch } from "react-router"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api } from "../lib/moberries-api"
import { refreshManager } from "../controllers/manager-controller"
import { alertTypes, showAlert, showErrorAlert } from "../lib/alerts"

export const ConfirmNewEmailPage = () => {
  const routeMatch = useRouteMatch()
  const history = useHistory()
  const { mutate: confirm } = useAxiosMutation(api.confirmNewEmailWithCode, {
    onSuccess: () => {
      refreshManager()
      showAlert({ code: alertTypes.emailConfirmNew })
      history.push("/account/credentials")
    },
    onError: err => {
      history.push("/account/credentials")
      showErrorAlert({ err })
    },
  })

  useEffect(() => {
    confirm({ code: routeMatch.code })
  }, [confirm, routeMatch.code])

  return null
}
