import React from "react"
import { useStore } from "../lib/store"
import { isLoggedInSelector } from "../lib/helpers"
import { Redirect, useLocation } from "react-router"

export const noAuthRequired = Component => props => {
  const location = useLocation()
  const isLoggedIn = useStore(isLoggedInSelector)

  if (isLoggedIn) {
    return <Redirect to={location.state?.from ?? "/"} />
  }

  return <Component {...props} />
}
