import { history } from "../history"
import { alertTypes, showAlert } from "../lib/alerts"
import { isDocumentVisible } from "../lib/helpers"
import { playBellSound } from "../lib/helpers/message-helpers"
import { refreshManager } from "./manager-controller"

export const notifyAboutNewMessage = ({ threadId }) => {
  if (!isDocumentVisible()) {
    playBellSound()
  }

  const pathname = history.location.pathname
  if (pathname !== "/messages") {
    showAlert({
      code: alertTypes.newMessage,
      options: {
        closeOnClick: true,
        onClick: () => {
          history.push(`/messages/${threadId}`)
        },
      },
    })
  }
  refreshManager()
}
