import React, { Fragment, useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { CompanyPageLayout } from "../components/layouts/company-page-layout"
import {
  Col,
  FormGroup,
  InputGroup,
  Row,
  TabPane,
  Input,
  Label,
  InputGroupAddon,
} from "reactstrap"
import { FormattedMessage } from "react-intl"
import { TeamCard } from "../components/teams/team-card-component"
import { TeamEditCard } from "../components/teams/team-edit-card-component"
import { Formik, Form } from "formik"
import { CompanyTabSkeleton } from "../components/skeletons/company-tab-skeleton"
import { ErrorPage } from "./error-page"
import { hasSuperManagerRights } from "../lib/helpers"
import { refreshCompany } from "../controllers/company-controller"
import { useStore } from "../lib/store"
import { Button } from "../components/common/button-component"
import FormFeedback from "../components/common/form-feedback"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { alertTypes, showAlert, showErrorAlert } from "../lib/alerts"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { ApiKeys, api } from "../lib/moberries-api"
import { useQueryClient } from "react-query"

export function CompanyTeamsPage() {
  const [editedTeamId, setEditedTeamId] = useState(null)
  const manager = useStore(state => state.manager)
  const company = useStore(state => state.company)
  const queryClient = useQueryClient()

  const {
    data: teamResults,
    isLoading: loadingProviderList,
    error: errorFetchingTeams,
  } = useAxiosQuery([ApiKeys.Teams], api.getCompanyTeamList, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const { mutateAsync: createCompanyTeam, isLoading: isCreating } =
    useAxiosMutation(api.createCompanyTeam, {
      onSuccess: () => {
        queryClient.invalidateQueries(ApiKeys.Teams)
        showAlert({ code: alertTypes.teamCreatedSuccess })
      },
      onError: err => {
        showErrorAlert({ err })
      },
    })

  useEffect(() => {
    refreshCompany()
  }, [])

  if (errorFetchingTeams) {
    return <ErrorPage err={errorFetchingTeams} />
  }

  const isSuperManager = hasSuperManagerRights(manager)

  if (loadingProviderList) {
    return (
      <CompanyTabSkeleton
        title={<FormattedMessage id="app.page.company.teams.header" />}
        isSuperManager={isSuperManager}
      />
    )
  }

  const { results: teams } = teamResults

  const isManagerBelongingToMoreThanOneTeam = managerId => {
    const teamsFound = teams.filter(team => {
      return !!team.managers.find(m => m.id === managerId)
    })
    return teamsFound.length > 1
  }

  if (!isSuperManager) {
    return <Redirect to="/company" />
  }

  return (
    <CompanyPageLayout
      title={<FormattedMessage id="app.page.company.teams.header" />}
      isSuperManager={isSuperManager}
    >
      <TabPane>
        <div className="bg-white p-3 mb-1">
          <Formik
            onSubmit={({ name }, { resetForm }) => {
              createCompanyTeam({
                companyId: manager.company.id,
                team: { name },
              }).then(() => {
                resetForm({ values: { name: "" } })
              })
            }}
            initialValues={{ name: "" }}
            validate={({ name }) =>
              !name ? { name: { id: "app.validators.value.required" } } : {}
            }
          >
            {({ values, errors, handleChange, handleBlur, touched }) => {
              return (
                <Form>
                  <Row className="align-items-end">
                    <Col>
                      <FormGroup className="m-0 _lr-hide">
                        <Label for="name">
                          <span>
                            <FormattedMessage id="app.page.company.teams.createNew" />
                          </span>
                          {" *"}
                        </Label>
                        <InputGroup>
                          <Input
                            id="name"
                            name="name"
                            placeholder="General"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={touched.name && Boolean(errors.name)}
                            disabled={isCreating}
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              color="primary"
                              type="submit"
                              className="ml-3 p-2 rounded"
                              disabled={isCreating}
                              loading={isCreating}
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroupAddon>
                          <FormFeedback error={errors.name} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </div>
        {teams.map(d => {
          return (
            <Fragment key={d.id}>
              {editedTeamId === d.id ? (
                <div className="bg-white">
                  <TeamEditCard
                    onCloseButtonClick={() => setEditedTeamId(null)}
                    team={d}
                    teamManagers={d.managers}
                    companyManagers={company.managers}
                    checkIfCanRemoveManager={
                      isManagerBelongingToMoreThanOneTeam
                    }
                  />
                </div>
              ) : (
                <TeamCard
                  team={d}
                  managers={d.managers}
                  handleClick={() => setEditedTeamId(d.id)}
                />
              )}
            </Fragment>
          )
        })}
      </TabPane>
    </CompanyPageLayout>
  )
}
