import { CAMPAIGN, FAIL, GET, LIST, START, SUCCESS } from "../constants"
import { showErrorAlert } from "../lib/alerts"
import { getActionType } from "../lib/helpers"

export const getCampaignList = params => {
  return async (dispatch, getState, { moApi }) => {
    dispatch({ type: getActionType(GET, CAMPAIGN, LIST, START) })

    try {
      const res = await moApi.getCampaignList(params)
      const { results: campaigns, count } = res.data

      dispatch({
        type: getActionType(GET, CAMPAIGN, LIST, SUCCESS),
        payload: {
          campaigns,
          count,
        },
      })
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(GET, CAMPAIGN, LIST, FAIL),
        payload: { err },
      })
    }
  }
}
