import React from "react"
import classNames from "classnames"
import { convertNameToInteger } from "../../lib/helpers"
import { ACCENT_COLORS } from "../../constants"
import PropTypes from "prop-types"

export const PersonInitials = ({
  firstName,
  lastName,
  width,
  height,
  className,
}) => (
  <div
    className={classNames(className, "text-muted font-weight-light")}
    style={{
      height,
      width,
      fontSize: "2rem",
      backgroundColor: ACCENT_COLORS[convertNameToInteger(firstName, lastName)],
    }}
  >
    <div className="d-flex align-items-center justify-content-center h-100 w-100">
      {firstName && firstName[0]}
      {lastName && lastName[0]}
    </div>
  </div>
)

PersonInitials.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
}

PersonInitials.defaultProps = {
  width: 80,
  height: 80,
}
