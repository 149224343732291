import React from "react"
import PropTypes from "prop-types"
import { Button } from "./button-component"

export const SelectFromBadges = ({
  items,
  nameKey,
  valueKey,
  value,
  onChange,
  onBlur,
  name,
}) => {
  return (
    <div className="tags-wrapper">
      {items.map(i => {
        const isChosen = value.includes(i[valueKey])
        return (
          <Button
            name={name}
            onBlur={onBlur}
            onClick={() => {
              if (isChosen) {
                onChange(value.filter(ci => ci !== i[valueKey]))
              } else {
                onChange([...value, i[valueKey]])
              }
            }}
            key={i[valueKey]}
            color={isChosen ? "success-light" : "light"}
            size="sm"
          >
            {i[nameKey]}
            {isChosen ? (
              <i className="fas fa-times pl-2 text-muted" />
            ) : (
              <i className="fas fa-plus pl-2 text-muted" />
            )}
          </Button>
        )
      })}
    </div>
  )
}

SelectFromBadges.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.array.isRequired,
  nameKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

SelectFromBadges.defaultProps = {
  value: [],
}
