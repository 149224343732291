import * as yup from "yup"
import { requiredEmailSchema } from "./shared-schemas"

export const managerProfileSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(50, "app.validators.length.max")
    .required("app.validators.value.required"),
  lastName: yup
    .string()
    .max(50, "app.validators.length.max")
    .required("app.validators.value.required"),
})

export const createManagerSchema = yup.object().shape({
  firstName: yup.string().required("app.validators.value.required"),
  lastName: yup.string().required("app.validators.value.required"),
  email: requiredEmailSchema,
  teams: yup
    .array()
    .of(yup.number())
    .min(1, "app.error.code.oneTeamRequired")
    .required("app.validators.value.required"),
})

export const updateManagerSchema = createManagerSchema
