import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { PriceComponent } from "../common/price-component"
import { getSubscriptionPrices, isUnlimitedJobSlots } from "../../lib/helpers"
import { SubscriptionOrderContext } from "./susbscription-order-context"
import { FormattedJobSlots } from "../common/formatted-job-slots"

export function SubscriptionInfo() {
  const { subscription, coupon } = useContext(SubscriptionOrderContext)
  if (!subscription) return null
  const {
    discount,
    priceWithDiscount,
    pricePerJobWithDiscount,
    totalPriceWithDiscount,
    priceWithDiscountForFirstMonths,
    pricePerJobWithDiscountForFirstMonth,
  } = getSubscriptionPrices(subscription, coupon)

  const upfront =
    subscription.durationIntervalCount === subscription.billingIntervalCount
  return (
    <div>
      <h5>
        <FormattedMessage id="app.page.subscription.order.info.title" />
      </h5>
      <ul className="list-unstyled">
        <li className="border-bottom py-2 d-flex justify-content-between">
          <span>
            <FormattedMessage id="app.page.subscription.order.info.duration" />
          </span>
          <span>
            {subscription.durationIntervalCount}{" "}
            <FormattedMessage
              id="app.common.month"
              values={{ amount: subscription.durationIntervalCount }}
            />
          </span>
        </li>
        <li className="border-bottom py-2 d-flex justify-content-between">
          <span>
            <FormattedMessage id="app.page.subscription.order.info.job.slots" />
          </span>
          <span>
            <FormattedJobSlots jobSlots={subscription.jobSlots} />
          </span>
        </li>
        <li className="border-bottom py-2 d-flex justify-content-between">
          <span>
            <FormattedMessage id="app.page.subscription.order.info.upfront" />
          </span>
          <span>
            <FormattedMessage
              id={upfront ? "app.common.yes" : "app.common.no"}
            />
          </span>
        </li>

        {!upfront &&
          coupon &&
          coupon.duration === "once" &&
          !isUnlimitedJobSlots(subscription.jobSlots) && (
            <li className="border-bottom py-2 d-flex justify-content-between">
              <span>
                <FormattedMessage id="app.page.subscription.order.info.price.per.job.first.month" />
              </span>
              <b>
                <PriceComponent
                  price={pricePerJobWithDiscountForFirstMonth / 100}
                />
              </b>
            </li>
          )}

        {!isUnlimitedJobSlots(subscription.jobSlots) && (
          <li className="border-bottom py-2 d-flex justify-content-between">
            <span>
              <FormattedMessage id="app.page.subscription.order.info.price.per.job" />
            </span>
            <b>
              <PriceComponent price={pricePerJobWithDiscount / 100} />
            </b>
          </li>
        )}

        {!upfront && coupon && coupon.duration === "once" && (
          <li className="border-bottom py-2 d-flex justify-content-between">
            <span>
              <FormattedMessage id="app.page.subscription.order.info.price.per.first.month" />
            </span>
            <b>
              <PriceComponent price={priceWithDiscountForFirstMonths / 100} />
            </b>
          </li>
        )}

        <li className="border-bottom py-2 d-flex justify-content-between">
          <span>
            <FormattedMessage id="app.page.subscription.order.info.price.per.month" />
          </span>
          <b>
            <PriceComponent price={priceWithDiscount / 100} />
          </b>
        </li>

        {upfront && (
          <li className="border-bottom py-2 d-flex justify-content-between">
            <span>
              <FormattedMessage id="app.page.subscription.order.info.price" />
            </span>
            <b>
              <PriceComponent price={totalPriceWithDiscount / 100} />
            </b>
          </li>
        )}

        {!upfront && coupon && (
          <li className="border-bottom py-2 d-flex justify-content-between">
            <span>
              {coupon.duration === "once" ? (
                <FormattedMessage id="app.page.subscription.order.info.discount.for.first.month" />
              ) : (
                <FormattedMessage id="app.page.subscription.order.info.discount.per.month" />
              )}
            </span>
            <b>
              <PriceComponent price={discount / 100} />
            </b>
          </li>
        )}

        {upfront && coupon && (
          <li className="border-bottom py-2 d-flex justify-content-between">
            <span>
              <FormattedMessage id="app.page.subscription.order.info.totalDiscount" />
            </span>
            <b>
              <PriceComponent price={discount / 100} />
            </b>
          </li>
        )}
      </ul>
    </div>
  )
}
