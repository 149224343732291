import { parseISO } from "date-fns"
import React from "react"
import PropTypes from "prop-types"
import { MonetizationBarChartSection } from "./monetization-bar-chart-section"

export const MonetizationGeneralTab = ({ company }) => {
  return (
    <div>
      <MonetizationBarChartSection
        dataKey="signups"
        companyCreatedAt={parseISO(company.createdAt)}
      />
      <MonetizationBarChartSection
        dataKey="applications"
        companyCreatedAt={parseISO(company.createdAt)}
      />
    </div>
  )
}

MonetizationGeneralTab.propTypes = {
  company: PropTypes.object.isRequired,
}

MonetizationGeneralTab.defaultProps = {}
