import React from "react"
import { FormattedMessage } from "react-intl"
import { Media } from "reactstrap"
import { FormattedLink } from "../../../common/formatted-link-component"
import { DashboardWidgetLayout } from "../../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import { Img } from "../../../common/img-component"
import successCheck from "../../../../assets/img/success-check-icon.svg"
import info from "../../../../assets/img/info.svg"
import styles from "./shared-talent-network-widget-empty.module.scss"

const checks = [
  {
    id: 1,
    value: <FormattedMessage id="app.widget.talentNetwork.guide.check1" />,
  },
  {
    id: 2,
    value: <FormattedMessage id="app.widget.talentNetwork.guide.check2" />,
  },
  {
    id: 3,
    value: <FormattedMessage id="app.widget.talentNetwork.guide.check3" />,
  },
]

export const SharedTalentNetworkWidgetEmpty = () => (
  <DashboardWidgetLayout
    title={<FormattedMessage id="app.widget.talentNetwork.title" />}
  >
    <h4 className={styles.header}>
      <FormattedMessage id="app.widget.talentNetwork.not-member" />
      <FormattedLink
        target="_blank"
        rel="noopener noreferrer"
        href="sharedTalentNetworks"
      >
        <FormattedMessage id="app.widget.talentNetwork.learnMore" />
      </FormattedLink>
    </h4>
    {checks.map(({ id, value }) => (
      <Media className={styles.media} key={id}>
        <Img src={successCheck} alt="check-icon" width="10" height="8" />
        <Media body>
          <p className="m-0">{value}</p>
        </Media>
      </Media>
    ))}
    <Media className={styles.info}>
      <Img src={info} alt="info" width="21" height="21" />
      <Media body>
        <p>
          <FormattedMessage id="app.widget.talentNetwork.info2" />
          <a className={styles.email} href="mailto:am@moberries.com">
            am@moberries.com
          </a>
        </p>
      </Media>
    </Media>
  </DashboardWidgetLayout>
)
