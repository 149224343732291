import { GET, SUBSCRIPTION, LIST, START, SUCCESS, FAIL } from "../constants"
import { getActionType } from "../lib/helpers"
import { showErrorAlert } from "../lib/alerts"

export function getSubscriptionList({ params } = {}) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, SUBSCRIPTION, LIST, START) })

    try {
      const res = await moApi.getSubscriptionList({
        params: {
          ...params,
          bookable: true,
        },
      })
      const { results: subscriptions, count } = res.data

      dispatch({
        type: getActionType(GET, SUBSCRIPTION, LIST, SUCCESS),
        payload: { subscriptions, count },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, SUBSCRIPTION, LIST, FAIL),
        payload: { err },
      })
    }
  }
}
