import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { SidebarWidgetLayout } from "../layouts/sidebar-widget-layout"
import {
  divideSafe,
  isComSubscriptionActive,
  isSubscriptionMain,
  isComSubscriptionUpgradable,
  isUnlimitedJobSlots,
  companySubscriptionSelector,
  jobSlotsLeftSelector,
} from "../../lib/helpers"
import { WidgetProgressBar } from "./widget-progress-bar"
import { max } from "ramda"
import { Button } from "../common/button-component"
import { Link } from "react-router-dom"
import { useStore } from "../../lib/store"

const getAvailableJobSlotsRatio = (subscription, slotsTotal, slotsLeft) => {
  if (!isSubscriptionMain(subscription)) {
    return 0
  }
  if (isUnlimitedJobSlots(slotsTotal)) {
    return 1
  }
  return divideSafe(slotsLeft, slotsTotal)
}

const formatAvailableJobSlotsRatio = (subscription, slotsTotal, slotsLeft) => {
  if (!isSubscriptionMain(subscription)) {
    return "0/0"
  }
  if (isUnlimitedJobSlots(slotsTotal)) {
    return <FormattedMessage id="app.job.form.posting.job.unlimited" />
  }
  return `${max(slotsLeft, 0)}/${slotsTotal}`
}
export function JobSlotsWidget() {
  const companySubscription = useStore(companySubscriptionSelector)
  const jobSlotsTotal = companySubscription.subscription.jobSlots
  const jobSlotsLeft = useStore(jobSlotsLeftSelector)

  if (!companySubscription || !isComSubscriptionActive(companySubscription)) {
    return null
  }

  return (
    <SidebarWidgetLayout
      title={
        <Fragment>
          <div className="icon-circled mr-2">
            <i className="fas fa-briefcase" />
          </div>
          <FormattedMessage id="app.widget.job.slots.title" />
        </Fragment>
      }
    >
      <div>
        <div className="d-flex justify-content-between py-2 border-top">
          <FormattedMessage id="app.widget.subscription.jobs.left" />
          <span className="text-muted">
            {formatAvailableJobSlotsRatio(
              companySubscription.subscription,
              jobSlotsTotal,
              jobSlotsLeft,
            )}
          </span>
        </div>
        <WidgetProgressBar
          value={getAvailableJobSlotsRatio(
            companySubscription.subscription,
            jobSlotsTotal,
            jobSlotsLeft,
          )}
        />
      </div>
      {isComSubscriptionUpgradable(companySubscription) && (
        <Button
          tag={Link}
          to="/subscribe"
          color="primary"
          block
          className="mt-3"
        >
          <FormattedMessage id="app.common.upgrade" />
        </Button>
      )}
    </SidebarWidgetLayout>
  )
}
