import React from "react"
import PropTypes from "prop-types"
import {
  Input,
  FormGroup,
  Label,
  FormFeedback,
  CustomInput,
  PopoverBody,
} from "reactstrap"
import { FormattedMessage } from "react-intl"
import { InfoHint } from "../common/info-hint-component"
import { useFormikField } from "../../hooks/use-formik-field"

export const SwitchField = props => {
  const [field, meta, { setValue }] = useFormikField(props)

  return (
    <div>
      <CustomInput
        {...props}
        {...field}
        checked={field.value}
        onChange={e => {
          setValue(e.target.checked)
        }}
        type="switch"
      />

      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />

      {meta.error && (
        <FormFeedback>
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </div>
  )
}

export const LabeledSwitchField = ({
  id,
  label,
  isRequired,
  hint,
  ...rest
}) => {
  const [field, meta, { setValue }] = useFormikField(rest)

  return (
    <FormGroup>
      <div>
        <Label for={id}>{label}</Label>
        {hint && (
          <InfoHint
            id={id}
            color="primary"
            popover={<PopoverBody>{hint}</PopoverBody>}
          />
        )}
      </div>
      <CustomInput
        {...field}
        {...rest}
        id={id}
        checked={field.value}
        onChange={e => {
          setValue(e.target.checked)
        }}
        type="switch"
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />

      {meta.error && (
        <FormFeedback>
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

LabeledSwitchField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.node.isRequired,
  hint: PropTypes.node,
  isRequired: PropTypes.bool,
}

LabeledSwitchField.defaultProps = {
  isRequired: false,
  hint: null,
}
