import React from "react"
import PropTypes from "prop-types"

export const SidebarWidgetLayout = ({ title, children }) => {
  return (
    <div className="bg-white rounded p-3 mb-3">
      {title && <h5 className="mb-3">{title}</h5>}
      {children}
    </div>
  )
}

SidebarWidgetLayout.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
}
