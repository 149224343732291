import React, { Fragment } from "react"
import { DoubleColumnLayout } from "../layouts/double-column-layout"
import { CardLoader } from "../loaders/card-loader"
import { List } from "../loaders/list-loader"
import { times } from "ramda"

export function MatchPageSkeleton() {
  return (
    <DoubleColumnLayout>
      <DoubleColumnLayout.Content>
        <Fragment>
          <div className="bg-white p-3">
            <CardLoader />
          </div>
          <div className="bg-white p-3 mx-auto mt-2">
            <List />
            <List />
          </div>
        </Fragment>
      </DoubleColumnLayout.Content>
      <DoubleColumnLayout.Sidebar>
        <div className="bg-white p-3">
          {times(
            i => (
              <List key={i} />
            ),
            4,
          )}
        </div>
      </DoubleColumnLayout.Sidebar>
    </DoubleColumnLayout>
  )
}
