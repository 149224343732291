import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Media } from "reactstrap"
import cn from "classnames"
import { SharedTalentNetworkWidgetEmpty } from "./shared-talent-network-widget-empty/shared-talent-network-widget-empty"
import { DashboardWidgetLayout } from "../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import { FormattedLink } from "../../common/formatted-link-component"
import { Img } from "../../common/img-component"
import defaultLogo from "../../../assets/img/talent-pool-default.png"
import megaphone from "../../../assets/img/megaphone-icon.svg"
import styles from "./shared-talent-network-widget.module.scss"

export const SharedTalentNetworkWidget = ({ companyGroups = [] }) => {
  if (!companyGroups.length) {
    return <SharedTalentNetworkWidgetEmpty />
  }

  return (
    <DashboardWidgetLayout
      title={<FormattedMessage id="app.widget.talentNetwork.title" />}
    >
      <div className={cn(styles.header, "pb-3")}>
        <Img src={megaphone} alt="Megaphone" />

        <h4>
          <FormattedMessage id="app.widget.talentNetwork.is-member" />
          <FormattedLink
            target="_blank"
            rel="noopener noreferrer"
            href="sharedTalentNetworks"
          >
            <FormattedMessage id="app.widget.talentNetwork.learnMore" />
          </FormattedLink>
        </h4>
      </div>

      <div className={styles.groups}>
        {companyGroups.slice(0, 3).map(({ logo, name }) => (
          <Media className={styles.media} key={name}>
            <Img
              src={logo || defaultLogo}
              alt="logo"
              width="32"
              height="32"
              className="rounded"
            />
            <Media body>
              <h4 className="m-0">{name}</h4>
              <p className="m-0">
                <FormattedMessage id="app.widget.talentNetwork.text" />
              </p>
              <hr />
            </Media>
          </Media>
        ))}
      </div>
    </DashboardWidgetLayout>
  )
}

SharedTalentNetworkWidget.propTypes = {
  companyGroups: PropTypes.array,
}
