import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { useFormikField } from "../../hooks/use-formik-field"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { FormFeedback, FormGroup, Input, Label } from "reactstrap"
import { FormattedErrorMessage } from "../common/formatted-error-message"
import { Element as ScrollElement } from "react-scroll"

export const LabeledRichTextEditorField = ({ label, isRequired, ...rest }) => {
  const [field, meta, actions] = useFormikField(rest)
  return (
    <FormGroup>
      <ScrollElement name={`form-position-${rest.id}`} />
      <Label>
        {label}
        {isRequired && " *"}
      </Label>
      <Editor
        stripPastedStyles
        toolbar={{
          options: ["inline", "list"],
          inline: {
            options: ["bold", "italic"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
        }}
        editorState={field.value}
        onEditorStateChange={actions.setValue}
        onBlur={() => actions.setTouched(true)}
        name={field.name}
        editorStyle={{ minHeight: 320 }}
        toolbarClassName="border rounded"
        editorClassName={cn("border rounded px-2", {
          "border-danger": meta.touched && Boolean(meta.error),
        })}
        {...rest}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

LabeledRichTextEditorField.propTypes = {
  label: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
}

LabeledRichTextEditorField.defaultProps = {
  isRequired: false,
}
