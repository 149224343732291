import React from "react"
import { TabPane } from "reactstrap"
import { ManagerCard } from "../components/managers/manager-card-component"
import { CompanyPageLayout } from "../components/layouts/company-page-layout"
import { CompanyTabSkeleton } from "../components/skeletons/company-tab-skeleton"
import { FormattedMessage } from "react-intl"
import { hasSuperManagerRights } from "../lib/helpers"
import { Redirect } from "react-router-dom"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api, ApiKeys } from "../lib/moberries-api"
import { ManagerEmailInputForm } from "../components/managers/manager-email-input-form"
import { useStore } from "../lib/store"

export const CompanyManagersPage = () => {
  const { data: { results: managers } = {} } = useAxiosQuery(
    [ApiKeys.Managers],
    api.getManagerList,
  )

  const me = useStore(state => state.manager)
  const isSuperManager = hasSuperManagerRights(me)

  if (!isSuperManager) {
    return <Redirect to="/company" />
  }

  if (!managers) {
    return (
      <CompanyTabSkeleton
        isSuperManager={isSuperManager}
        title={<FormattedMessage id="app.page.company.managers.header" />}
      />
    )
  }
  return (
    <CompanyPageLayout
      title={<FormattedMessage id="app.page.company.managers.header" />}
      isSuperManager={isSuperManager}
    >
      <TabPane>
        <ManagerEmailInputForm />

        {managers.map(m => (
          <ManagerCard
            id={m.id}
            key={m.id}
            firstName={m.firstName}
            lastName={m.lastName}
            email={m.email}
            isSuperManager={hasSuperManagerRights(m)}
            isAccountActive={m.accountActivated}
            teams={m.teams}
          />
        ))}
      </TabPane>
    </CompanyPageLayout>
  )
}
