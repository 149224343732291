import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import Arrow from "../../../../assets/img/chart-picker-arrow.svg"
import styles from "./value-picker-paginated.module.scss"
import { useOutsideClickCloser } from "../../../../hooks/use-outside-click-closer"
import { FormattedMessage } from "react-intl"
import { isIntlId } from "../../../../lib/helpers"
import { MATCHING_PER_JOB_JOBS_LIMIT } from "../../../../constants"
import { PickerPagination } from "./picker-pagination"

export function ValuePickerPaginated(props) {
  const { availableValues, isLoadingJobs, jobsCount, page, setPage } = props
  const [selected, setSelected] = useState(availableValues?.[0])
  const { isExpanded, ref, close, open } = useOutsideClickCloser()

  const isThereAvailableValues = !!availableValues.length
  useEffect(() => {
    if (isThereAvailableValues) {
      setSelected(availableValues[0])
    }
  }, [isThereAvailableValues, availableValues])

  const onSelectHandler = newSelected => {
    close()
    setSelected(newSelected)
    props.onSelect(newSelected)
  }

  return (
    <div className={styles.valuePicker} ref={ref}>
      {(isLoadingJobs || selected) && (
        <button
          type="button"
          onClick={() => (isExpanded ? close() : open())}
          className={styles.valuePickerChoice}
          disabled={!selected}
        >
          <p className={styles.pick}>
            {!isLoadingJobs ? (
              isIntlId(selected.label) ? (
                <FormattedMessage id={selected.label} />
              ) : (
                selected.label
              )
            ) : (
              <>
                <FormattedMessage id="app.common.loading" />
                ...
              </>
            )}
          </p>
          <img src={Arrow} width="10" height="10" alt="v" />
        </button>
      )}
      {isExpanded && (
        <div className={styles.valuePicker__dropDown}>
          {props.availableValues?.map(option => (
            <button
              key={option.value}
              type="button"
              onClick={
                selected.value === option.value
                  ? () => close()
                  : () => onSelectHandler(option)
              }
              className={cn(
                [styles.valuePickerChoice, styles.valuePickerChoice_menu],
                {
                  [styles.valuePickerChoice_menu_selected]:
                    selected.value === option.value,
                },
              )}
            >
              <p>
                {isIntlId(selected.label) ? (
                  <FormattedMessage id={option.label} />
                ) : (
                  option.label
                )}
              </p>
            </button>
          ))}
          <div className={styles.pagination}>
            <PickerPagination
              content
              className="mt-3"
              listClassName="justify-content-end"
              page={page}
              count={jobsCount}
              disabled={isLoadingJobs}
              limit={MATCHING_PER_JOB_JOBS_LIMIT}
              setPage={setPage}
            />
          </div>
        </div>
      )}
    </div>
  )
}

ValuePickerPaginated.propTypes = {
  onSelect: PropTypes.func.isRequired,
  availableValues: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  jobsCount: PropTypes.number.isRequired,
  isLoadingJobs: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
}
