import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { useIntl } from "react-intl"

import styles from "./candidate-status-select.module.scss"

export const CandidateStatusSelect = ({ value, options, onChange }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.selectWrapper}>
      <select
        className={cn(styles.select, styles[`select_${value}`])}
        onChange={e => onChange(e.target.value)}
        value={value}
      >
        {options.map(option => (
          <option key={option} value={option}>
            {formatMessage({
              id: `app.virtualRecruiter.candidate.status.${option.toLowerCase()}`,
            })}
          </option>
        ))}
      </select>
    </div>
  )
}

CandidateStatusSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}
