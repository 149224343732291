import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row, FormGroup, FormFeedback, Input } from "reactstrap"
import { FormattedMessage } from "react-intl"
import {
  CompositeDecorator,
  ContentState,
  convertFromHTML,
  Editor,
  EditorState,
  Modifier,
} from "draft-js"
import { Badge } from "../common/badge-component"
import { always } from "ramda"
import { MESSAGE_TEMPLATE_TAGS } from "../../constants"
import { arrayToMultiWordRegex } from "../../lib/helpers"
import { useFormikField } from "../../hooks/use-formik-field"

const TAGS_REGEX = arrayToMultiWordRegex(Object.values(MESSAGE_TEMPLATE_TAGS))

const HashTag = ({ children }) => {
  return <span className="text-primary">{children}</span>
}
HashTag.propTypes = {
  children: PropTypes.array,
}

const insertText = (textToInsert, editorState) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection()

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    textToInsert,
  )

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    "insert-characters",
  )

  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter(),
  )
}
const getInitialStateFromTemplate = existingTemplateText => {
  const html = existingTemplateText.replace(/(\n)/g, "<br>")
  const blocksFromHTML = convertFromHTML(html)
  return ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  )
}

export const MessageTemplateEditor = ({ name }) => {
  const [field, meta, helpers] = useFormikField({ name })

  const value = field.value

  const initialState = getInitialStateFromTemplate(value)
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      initialState,
      new CompositeDecorator([
        {
          strategy: (contentBlock, applyDecorator) => {
            const text = contentBlock.getText()
            let matchArr
            while ((matchArr = TAGS_REGEX.exec(text)) !== null) {
              const start = matchArr.index
              applyDecorator(start, start + matchArr[0].length)
            }
          },
          component: HashTag,
        },
      ]),
    ),
  )
  const focusEditor = () => editor.current?.focus()

  useEffect(() => {
    focusEditor()
  }, [])

  const editor = useRef(null)

  return (
    <FormGroup>
      <Row>
        <Col xs="12" sm="8">
          <div className="mb-2">
            <FormattedMessage id="app.modal.messageTemplate.text.label" />*
          </div>

          {Object.values(MESSAGE_TEMPLATE_TAGS).map(tag => (
            <Badge
              className="border mr-2 mb-2 clickable no-user-select"
              key={tag}
              onClick={() => {
                const newState = insertText(tag, editorState)
                const text = newState.getCurrentContent().getPlainText()
                helpers.setValue(text)
                helpers.setTouched(true)
                setEditorState(newState)
              }}
              color="light"
            >
              <FormattedMessage id={`app.modal.messageTemplate.tag.${tag}`} />
            </Badge>
          ))}
        </Col>
      </Row>
      <Row className="flex-row-reverse">
        <Col sm="4">
          <p className="text-smaller order-sm-2">
            <FormattedMessage id="app.modal.messageTemplate.text.description" />
          </p>
        </Col>
        <Col xs="12" sm="8" className="order-sm-1">
          <div
            onClick={focusEditor}
            className="message-template-editor border p-2"
          >
            <Editor
              stripPastedStyles
              ref={editor}
              editorState={editorState}
              onBlur={() => {
                helpers.setTouched(true)
              }}
              onChange={state => {
                setEditorState(state)
                const text = state.getCurrentContent().getPlainText()
                helpers.setValue(text)
              }}
              blockStyleFn={always("message-template-block")}
            />
          </div>
        </Col>
      </Row>
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

MessageTemplateEditor.propTypes = {
  name: PropTypes.string.isRequired,
}
