import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { times } from "ramda"

export const DetailViewProgressBar = ({
  mustHave,
  total,
  diff,
  completed,
  title,
  value,
  requiredMessage,
  requiredValue,
  type,
}) => (
  <div className="mt-3">
    <div className="d-flex flex-row text-smaller">
      <b className="flex-grow-1 pb-2">
        {title}
        {mustHave && " [must have]"}
      </b>
      {value && <div>{value}</div>}
    </div>
    <ul className="match-progress-bar">
      {times(
        i => (
          <li key={i} className={cn(type, { completed: i <= completed })} />
        ),
        diff === 0 ? total : completed + 1,
      )}
      {diff > 0 &&
        times(
          i => (
            <li
              key={i}
              className={cn("insufficient", { completed: i < diff })}
            />
          ),
          total - completed - 1,
        )}
    </ul>
    {diff > 0 && (
      <div className="d-flex flex-row text-smaller mt-1">
        <span className="flex-grow-1 pb-2 text-danger">{requiredMessage}</span>
        <span className="text-danger">{requiredValue}</span>
      </div>
    )}
  </div>
)

DetailViewProgressBar.propTypes = {
  mustHave: PropTypes.bool,
  total: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  diff: PropTypes.number,
  title: PropTypes.node.isRequired,
  value: PropTypes.node,
  requiredValue: PropTypes.node,
  requiredMessage: PropTypes.node,
  type: PropTypes.oneOf(["sufficient", "insufficient", "default"]),
}

DetailViewProgressBar.defaultProps = {
  mustHave: false,
  diff: 0,
  value: null,
  requiredValue: null,
  requiredMessage: null,
  type: "default",
}
