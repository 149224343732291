import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

export const DetailView = ({ children, colWidth, className }) => {
  return (
    <Row className={className}>
      {React.Children.toArray(children).map(child => (
        <Col key={child.key} md={colWidth}>
          {child}
        </Col>
      ))}
    </Row>
  )
}

DetailView.propTypes = {
  children: PropTypes.node.isRequired,
  colWidth: PropTypes.oneOf([6, 12]),
  className: PropTypes.string,
}

DetailView.defaultProps = {
  colWidth: 6,
  className: null,
}
