import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import seapig, { REQUIRED, REQUIREDS } from "seapig"
import { Container } from "reactstrap"
import { DoubleColumnLayout } from "./double-column-layout"

export const MatchListPageLayout = ({ children }) => {
  const { contentChildren, sidebarChildren } = seapig(children, {
    content: REQUIREDS,
    sidebar: REQUIRED,
  })

  return (
    <Fragment>
      <Container>
        <h1>
          <FormattedMessage id="app.page.matchList.header" />
        </h1>
        <p className="text-muted">
          <FormattedMessage id="app.page.matchList.subheader" />
        </p>
      </Container>
      <DoubleColumnLayout>
        <DoubleColumnLayout.Content>
          {contentChildren}
        </DoubleColumnLayout.Content>
        <DoubleColumnLayout.Sidebar>
          {sidebarChildren}
        </DoubleColumnLayout.Sidebar>
      </DoubleColumnLayout>
    </Fragment>
  )
}

MatchListPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
