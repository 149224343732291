import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"
import { ConnectedRouter as Router } from "connected-react-router"
import { store, persistor } from "./redux/store"
import { history } from "./history"
import { App } from "./app"
import { StripeElementsProvider } from "./components/payment/stripe-elements-provider"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { InitGate } from "./components/common/init-gate"
import { IntlProvider } from "./components/common/intl-provider"
import { queryClient } from "./lib/query-client"

export function Root() {
  return (
    <InitGate>
      <IntlProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <StripeElementsProvider>
              <QueryClientProvider client={queryClient}>
                <Router history={history}>
                  <App />
                </Router>
                <ReactQueryDevtools />
              </QueryClientProvider>
            </StripeElementsProvider>
          </PersistGate>
        </Provider>
      </IntlProvider>
    </InitGate>
  )
}
