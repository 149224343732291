import {
  getActionType,
  isProblem,
  isStatus,
  isMatchRejected,
} from "../lib/helpers"
import { store } from "../lib/store"
import qs from "qs"
import { head } from "ramda"
import {
  nextMatchSelector,
  matchByIdSelector,
} from "../selectors/match-selector"
import { push, createMatchSelector, getLocation } from "connected-react-router"
import {
  GET,
  MATCH,
  LIST,
  START,
  SUCCESS,
  FAIL,
  MATCH_STATUSES,
  ACT,
  NEXT,
  RELOAD,
  ACCEPT,
  DECLINE,
  APPROVE,
  REJECT,
  PAU,
  PROBLEMS,
  ATS_APPLICATION_SYNC_OPTIONS,
  REJECTED,
  REASONS,
  MATCH_ORDERING,
  FEEDBACK,
  SUMMARY,
  APPLICATIONS,
  STATISTICS,
  DASHBOARD_MATCHES_LIMIT,
} from "../constants"
import { alertTypes, showAlert, showErrorAlert } from "../lib/alerts"
import { CancelToken } from "axios"
import { history } from "../history"

export const getMatchList = (() => {
  let cancelTokenSource = null
  return function ({ params }) {
    return async function (dispatch, getState, { moApi }) {
      dispatch({ type: getActionType(GET, MATCH, LIST, START) })
      try {
        if (cancelTokenSource) {
          cancelTokenSource.cancel()
        }
        cancelTokenSource = CancelToken.source()

        const res = await moApi.getMatchList({
          params,
          cancelToken: cancelTokenSource.token,
        })

        const { results: matches, count } = res.data
        dispatch({
          type: getActionType(GET, MATCH, LIST, SUCCESS),
          payload: {
            matches,
            count,
            search: params.search,
            status: params.status,
          },
        })
      } catch (err) {
        dispatch({
          type: getActionType(GET, MATCH, LIST, FAIL),
          payload: { err },
        })
      }
    }
  }
})()

export function getMatch({ id }) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(GET, MATCH, START) })

    try {
      const { data: match } = await moApi.getMatch({ id })
      const matchActions = [
        dispatch(actions.job.getJob({ id: match.job.id })),
        dispatch(actions.candidate.getCandidate({ id: match.candidate.id })),
        dispatch(actions.match.getNextMatch({ match })),
        dispatch(
          actions.match.getMatchList({
            params: {
              limit: 6,
              status: MATCH_STATUSES.CANDIDATE_ACCEPTED,
              ordering: MATCH_ORDERING.EXPIRES_AT,
            },
          }),
        ),
      ]

      if (isMatchRejected(match)) {
        matchActions.push(dispatch(actions.match.getMatchFeedbacks({ id })))
      }

      await Promise.all(matchActions)

      const nextMatch = nextMatchSelector(getState())

      if (nextMatch) {
        await dispatch(
          actions.candidate.getCandidate({ id: nextMatch.candidate.id }),
        )
      }

      dispatch({
        type: getActionType(GET, MATCH, SUCCESS),
        payload: { match },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, MATCH, FAIL),
        payload: { err },
      })
    }
  }
}

export function acceptMatch({ matchId }) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(ACCEPT, MATCH, START) })

    try {
      const { data: match } = await moApi.updateMatch({
        id: matchId,
        match: { status: MATCH_STATUSES.COMPANY_ACCEPTED },
      })

      dispatch({
        type: getActionType(ACCEPT, MATCH, SUCCESS),
        payload: { match },
      })
      showAlert({ code: alertTypes.matchAcceptSuccess })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(ACCEPT, MATCH, FAIL),
        payload: { err },
      })
    }
  }
}

export function declineMatch({ id, companyId, skills, answers, comment }) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(DECLINE, MATCH, START) })
    try {
      const { data: feedback } = await moApi.createFeedback({
        answers: answers,
        comment,
      })

      const { data: matchFeedback } = await moApi.linkFeedbackWithMatch({
        companyId,
        id,
        feedback: feedback.id,
      })

      await moApi.linkSkillsWithFeedback({
        matchFeedbackId: matchFeedback.id,
        id,
        companyId,
        skills: skills,
      })

      const { data: match } = await moApi.updateMatch({
        id,
        companyId,
        match: { status: MATCH_STATUSES.COMPANY_DECLINED },
      })

      const { state: locationState } = getLocation(getState())

      if (locationState?.fromPage) {
        dispatch(
          push({
            pathname: "/",
            search: qs.stringify({ page: locationState.fromPage }),
          }),
        )
      } else {
        dispatch(push("/"))
      }
      showAlert({ code: alertTypes.matchDeclineSuccess })

      dispatch({
        type: getActionType(DECLINE, MATCH, SUCCESS),
        payload: { match },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(DECLINE, MATCH, FAIL),
        payload: { err },
      })
    }
  }
}

export function approveMatch({ id, companyId, redirectToMatchList = false }) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(APPROVE, MATCH, START) })

    try {
      const { data: match } = await moApi.updateMatch({
        id,
        companyId,
        match: { status: MATCH_STATUSES.COMPANY_APPROVED, hireConfirmed: true },
      })

      dispatch({
        type: getActionType(APPROVE, MATCH, SUCCESS),
        payload: { match },
      })

      if (redirectToMatchList) {
        const { state: locationState } = getLocation(getState())
        dispatch(
          push({
            pathname: "/candidates",
            search: qs.stringify({ page: locationState?.fromPage }),
          }),
        )
      }

      showAlert({ code: alertTypes.matchApproveSuccess })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(APPROVE, MATCH, FAIL),
        payload: { err },
      })
    }
  }
}

export function rejectMatch({ id, companyId, answers, comment }) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(REJECT, MATCH, START) })

    try {
      const { data: feedback } = await moApi.createFeedback({
        answers: answers,
        comment,
      })

      await moApi.linkFeedbackWithMatch({
        companyId,
        id,
        feedback: feedback.id,
      })

      const { data: match } = await moApi.updateMatch({
        id,
        companyId,
        match: { status: MATCH_STATUSES.COMPANY_REJECTED },
      })

      const { state: locationState } = getLocation(getState())
      dispatch(
        push({
          pathname: "/candidates",
          search: qs.stringify({ page: locationState?.fromPage }),
        }),
      )
      showAlert({ code: alertTypes.matchRejectSuccess })
      dispatch({
        type: getActionType(REJECT, MATCH, SUCCESS),
        payload: { match },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(REJECT, MATCH, FAIL),
        payload: { err },
      })
    }
  }
}

export function getNextMatch({ match: referenceMatch }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, NEXT, MATCH, START) })

    try {
      // We get 2 first matches and if the first one of them is the same as the reference match, we take the second one.
      // Implemented this way to patch up the absence of id exclusion filter on the backend
      // https://github.com/moberries/backend/issues/3842

      const {
        data: { results: matches },
      } = await moApi.getMatchList({
        params: {
          ordering: MATCH_ORDERING.EXPIRES_AT,
          limit: 2,
          status: MATCH_STATUSES.CANDIDATE_ACCEPTED,
          job: referenceMatch.job.id,
          job__status__in: [ACT, PAU],
          candidate__deletion_status__isnull: true,
        },
      })
      let nextMatch = null
      if (head(matches) && head(matches).id !== referenceMatch.id) {
        nextMatch = head(matches)
      } else if (matches[1]) {
        nextMatch = matches[1]
      }

      dispatch({
        type: getActionType(GET, NEXT, MATCH, SUCCESS),
        payload: { match: nextMatch },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, NEXT, MATCH, FAIL),
        payload: { err },
      })
    }
  }
}

export function newMatch(action) {
  return async function (dispatch, getState, { actions, moApi }) {
    if (!action.payload.matchId) return

    const { company } = store.getState()

    if (!company) return
    let { atsCompanyIntegration } = store.getState()

    // We don't want to react to this event, because if the company has matchExportType == ON_CAN_ACCEPT
    // This match will be exported by ats -> its status will be set to EXPORTED
    // So it won't appear on the dashborad
    if (
      atsCompanyIntegration?.matchExportType ===
      ATS_APPLICATION_SYNC_OPTIONS.ON_CAN_ACCEPT
    ) {
      return
    }

    const dashboardPageMatch = createMatchSelector({
      path: "/",
      exact: true,
    })(getState())

    const matchPageMatch = createMatchSelector("/match/:id")(getState())

    if (dashboardPageMatch) {
      const location = getLocation(getState())
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
      dispatch(
        actions.match.getMatchList({
          params: {
            status: MATCH_STATUSES.CANDIDATE_ACCEPTED,
            ordering: MATCH_ORDERING.EXPIRES_AT,
            limit: DASHBOARD_MATCHES_LIMIT,
            page: queryParams.page,
            job__team: queryParams.jobTeam,
            search: queryParams.search,
          },
        }),
      )
    }

    try {
      const { data: match } = await moApi.getMatch({
        companyId: company.id,
        id: action.payload.matchId,
      })

      dispatch({
        type: getActionType(RELOAD, MATCH, SUCCESS),
        payload: { match },
      })
    } catch (err) {
      if (isProblem(PROBLEMS.CLIENT_ERROR, err) && isStatus(404, err)) {
        if (
          matchPageMatch &&
          parseInt(matchPageMatch.params.id, 10) === action.payload.matchId
        ) {
          dispatch(push("/"))
        }
        return
      } else {
        dispatch({
          type: getActionType(RELOAD, MATCH, FAIL),
          payload: { err },
        })
      }
    }

    const match = matchByIdSelector(getState(), { id: action.payload.matchId })

    if (match && match.status === MATCH_STATUSES.CANDIDATE_ACCEPTED) {
      showAlert({
        code: alertTypes.newMatch,
        options: {
          closeOnClick: true,
          onClick: () => {
            history.push(`/match/${action.payload.matchId}`)
          },
        },
      })
    }
  }
}

export function getMatchFeedbacks({ id }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, MATCH, REJECTED, REASONS, START) })

    try {
      const {
        data: { results: feedbacks },
      } = await moApi.getMatchFeedbacks({ id })

      const matchRejectedReasons = head(feedbacks)?.feedback
      dispatch({
        type: getActionType(GET, MATCH, REJECTED, REASONS, SUCCESS),
        payload: { matchRejectedReasons, matchId: id },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, MATCH, REJECTED, REASONS, FAIL),
        payload: { err },
      })
    }
  }
}

export function getFeedbackSummary() {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, FEEDBACK, SUMMARY, START) })
    try {
      const { data: feedbackSummary } = await moApi.getFeedbackSummary()

      dispatch({
        type: getActionType(GET, FEEDBACK, SUMMARY, SUCCESS),
        payload: { feedbackSummary },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, FEEDBACK, SUMMARY, FAIL),
        payload: { err },
      })
    }
  }
}

export function getApplicationsStatistics({ params }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, APPLICATIONS, STATISTICS, START) })
    try {
      const { data: applicationsStatistics } =
        await moApi.getApplicationsStatistics({ params })

      dispatch({
        type: getActionType(GET, APPLICATIONS, STATISTICS, SUCCESS),
        payload: { applicationsStatistics },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, APPLICATIONS, STATISTICS, FAIL),
        payload: { err },
      })
    }
  }
}
