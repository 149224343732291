import React from "react"
import {
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Button,
  InputGroupAddon,
  InputGroup,
} from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Formik, Form } from "formik"
import { useHistory } from "react-router"
import { emailFormSchema } from "../../schemas/account-schemas"

export const ManagerEmailInputForm = () => {
  const history = useHistory()
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={emailFormSchema}
      onSubmit={({ email }) => {
        history.push({
          pathname: "/managers/invite",
          state: { newManagerEmail: email },
        })
      }}
    >
      {({ values, errors, handleChange, handleBlur, touched }) => (
        <Form className="bg-white p-3">
          <FormGroup className="mb-0">
            <Label for="new-manager-label-input">
              <FormattedMessage id="app.page.company.managers.invite" />
            </Label>
            <InputGroup>
              <Input
                name="email"
                id="new-manager-label-input"
                invalid={touched.email && Boolean(errors.email)}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="email@example.com"
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  className="ml-3 p-2 rounded"
                  type="submit"
                >
                  <i className="fas fa-plus" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <Input
              type="hidden"
              invalid={touched.email && Boolean(errors.email)}
            />
            {errors.email && (
              <FormFeedback>
                <FormattedMessage id={errors.email} />
              </FormFeedback>
            )}
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
