import React from "react"
import { Container, Row, Col } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { DualChartVerticalLayout } from "../../../../components/layouts/analytics/dual-chart-layout"
import { ChartSkeletonState } from "../../../../components/charts/chart-skeleton-state/chart-skeleton-state"
import styles from "./strategy-builder-page-skeleton.module.scss"

export const StrategyBuilderPageSkeleton = () => {
  return (
    <Container className="container-analytics">
      <Row className={styles.header}>
        <Col lg="12">
          <h1>
            <FormattedMessage id="app.page.analytics.strategyBuilder.header" />
          </h1>
          <h6>
            <FormattedMessage id="app.page.analytics.strategyBuilder.subheader" />
          </h6>
        </Col>
      </Row>

      <Row className={styles.content}>
        <Col lg="6">
          <DualChartVerticalLayout
            header={
              <FormattedMessage id="app.page.analytics.strategyBuilder.content.salary.header" />
            }
            separator={styles.separator}
          >
            <DualChartVerticalLayout.Top>
              <ChartSkeletonState />
            </DualChartVerticalLayout.Top>
            <DualChartVerticalLayout.Bottom>
              <ChartSkeletonState />
            </DualChartVerticalLayout.Bottom>
          </DualChartVerticalLayout>
        </Col>

        <Col lg="6">
          <DualChartVerticalLayout
            header={
              <FormattedMessage id="app.page.analytics.strategyBuilder.content.trends.header" />
            }
            separator={styles.separator}
          >
            <DualChartVerticalLayout.Top>
              <ChartSkeletonState />
            </DualChartVerticalLayout.Top>
            <DualChartVerticalLayout.Bottom>
              <ChartSkeletonState />
            </DualChartVerticalLayout.Bottom>
          </DualChartVerticalLayout>
        </Col>
      </Row>
    </Container>
  )
}
