import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "react-intl"
import { pathOr, path } from "ramda"
import { linksJoined } from "../../lib/external-links"

export function FormattedLink({ className, target, rel, children, href, as }) {
  const Element = as
  const intl = useIntl()
  const locale = intl.locale
  const localizedHref = pathOr(
    path([href, "en"], linksJoined),
    [href, locale],
    linksJoined,
  )
  return (
    <Element
      className={className}
      href={localizedHref}
      target={target}
      rel={rel}
    >
      {children || href}
    </Element>
  )
}

FormattedLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

FormattedLink.defaultProps = {
  as: "a",
  locale: "en",
  className: "",
}
