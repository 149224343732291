import React from "react"
import { NavLink as RLink } from "react-router-dom"
import {
  Navbar,
  NavbarBrand,
  Container,
  Collapse,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap"
import { Link } from "react-scroll"
import MBLogo from "../../assets/img/mb-logo.svg"
import { MobileNav } from "./mobile-nav"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import enFlag from "../../assets/img/en.svg"
import deFlag from "../../assets/img/de.svg"
import { setLocale } from "../../controllers/user-controller"
import { useStore } from "../../lib/store"

const flags = {
  en: enFlag,
  de: deFlag,
}

export const HeaderLoggedOut = () => {
  const locale = useStore(state => state.locale)
  const handleLocaleChange = () => {
    const nextLocale = locale === "de" ? "en" : "de"
    setLocale(nextLocale)
  }

  return (
    <Navbar color="dark" dark expand="sm" className="sticky-top">
      <Container>
        <NavbarBrand tag={Link} to="/">
          <img src={MBLogo} width="32" height="32" alt="Moberries" />
        </NavbarBrand>

        <MobileNav>
          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/login"
            >
              <i className="fas fa-sign-in-alt mr-1" />
              <FormattedMessage id="app.navbar.login" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/signup"
            >
              <i className="fas fa-user-plus mr-1" />
              <FormattedMessage id="app.navbar.signup" />
            </NavLink>
          </NavItem>
          <NavItem>
            <Button
              color="link"
              onClick={handleLocaleChange}
              className="text-body font-weight-bold text-decoration-none"
            >
              <img
                src={flags[locale]}
                width="16"
                height="16"
                alt={`${locale} flag`}
                className="mr-1"
              />
              <span className="align-middle text-smaller text-uppercase">
                {locale}
              </span>
            </Button>
          </NavItem>
        </MobileNav>
        <Collapse navbar>
          <Nav className="ml-auto font-weight-bold" navbar>
            <NavItem>
              <NavLink tag={RLink} exact to="/login">
                <FormattedMessage id="app.navbar.login" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RLink} exact to="/signup">
                <FormattedMessage id="app.navbar.signup" />
              </NavLink>
            </NavItem>
            <NavItem>
              <Button color="dark" onClick={handleLocaleChange}>
                <img
                  src={flags[locale]}
                  width="16"
                  height="16"
                  alt={`${locale} flag`}
                  className="mr-1"
                />
                <span className="align-middle text-uppercase">{locale}</span>
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}
