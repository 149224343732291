import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Container, FormGroup, FormText, Label } from "reactstrap"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { noAuthRequired } from "../decorators/no-auth-required"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import { InputField, LabeledInputField } from "../components/fields/input-field"
import { Button } from "../components/common/button-component"
import { loginSchema } from "../schemas/account-schemas"
import { loginWithEmailAndPassword } from "../controllers/user-controller"
import { useMutation } from "react-query"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { BaseLayout } from "../components/layouts/base-layout"
import { showErrorAlert } from "../lib/alerts"

export const LoginPageComponent = () => {
  const location = useLocation()
  const { mutateAsync: login } = useMutation(loginWithEmailAndPassword, {
    onError: err => {
      showErrorAlert({ err })
    },
  })

  return (
    <BaseLayout className="py-5">
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.page.login.header" />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <div className="bg-white rounded px-3 py-5">
          <SmallInnerFormLayout>
            <Formik
              validationSchema={loginSchema}
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={submitAndValidate(login)}
            >
              {({ isSubmitting }) => (
                <Form>
                  <LabeledInputField
                    label={<FormattedMessage id="app.common.email" />}
                    name="email"
                    type="email"
                    id="email"
                    placeholder="email@example.com"
                    isRequired
                  />
                  <FormGroup>
                    <Label for="password">
                      <FormattedMessage id="app.common.password" /> *
                    </Label>
                    <InputField
                      name="password"
                      type="password"
                      id="password"
                      placeholder="********"
                    />
                    <FormText>
                      <Link to="/password-recovery">
                        <FormattedMessage id="app.page.login.form.password.recovery" />
                      </Link>
                    </FormText>
                  </FormGroup>

                  <Button
                    block
                    type="submit"
                    size="lg"
                    color="primary"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="pt-3 text-center text-secondary">
              <FormattedMessage id="app.page.login.form.no.account" />{" "}
              <Link
                to={{
                  pathname: "/signup",
                  search: location.search,
                }}
              >
                <FormattedMessage id="app.page.login.form.sign.up" />
              </Link>
            </div>
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

export const LoginPage = noAuthRequired(LoginPageComponent)
