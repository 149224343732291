import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Media } from "reactstrap"
import timeIcon from "../../assets/img/time-icon.svg"
import { isComSubscriptionPaid } from "../../lib/helpers"
import { Button } from "../common/button-component"

export function SubscriptionCanceled({ companySubscription }) {
  return (
    <div className="mb-2 text-center">
      <Media className="bg-white border-bottom p-3">
        <Media body>
          <Media heading>
            <FormattedMessage id="app.page.dashboard.matches.subscription.state.canceled.hint.header" />
          </Media>
          <p>
            {isComSubscriptionPaid(companySubscription) ? (
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.canceled.paid.hint.text" />
            ) : (
              <FormattedMessage
                id="app.page.dashboard.matches.subscription.state.canceled.pastDue.hint.text"
                values={{
                  link: (
                    <FormattedMessage id="app.page.dashboard.matches.subscription.state.canceled.hint.link">
                      {txt => <a href="mailto:am@moberries.com">{txt}</a>}
                    </FormattedMessage>
                  ),
                }}
              />
            )}
          </p>
          <Media className="mt-3 mb-3" body>
            <img
              className="img-fluid"
              src={timeIcon}
              width="200"
              alt="times-up"
            />
          </Media>
          {isComSubscriptionPaid(companySubscription) && (
            <Button tag={Link} to="/subscribe" color="danger">
              <FormattedMessage id="app.common.upgrade.now" />
            </Button>
          )}
        </Media>
      </Media>
    </div>
  )
}

SubscriptionCanceled.propTypes = {
  companySubscription: PropTypes.object.isRequired,
}

SubscriptionCanceled.defaultProps = {
  companySubscription: null,
}
