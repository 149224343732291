import { ALERT, SHOW, HIDE } from "../constants"
import { getActionType } from "../lib/helpers"

export const initialState = {
  entity: null,
}

export function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(SHOW, ALERT):
      return {
        ...state,
        entity: payload,
      }

    case getActionType(HIDE, ALERT):
      return {
        ...state,
        entity: null,
      }

    default:
      return state
  }
}
