import { isEmpty } from "ramda"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap"
import { CAREER_LEVELS } from "../../default-options"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { ApiKeys, api as moApi } from "../../lib/moberries-api"
import { MonetizationChartSection } from "./monetization-chart-section"

export const MonetizationDetailsTab = () => {
  const intl = useIntl()

  const {
    data: companyRewardsCandidates,
    isLoading,
    isFetched,
  } = useAxiosQuery(
    [ApiKeys.CompanyRewardsCandidates],
    moApi.getCompanyRewardsCandidates,
  )

  if (!isFetched) {
    return null
  }

  const jobCategories = companyRewardsCandidates.jobCategories.map(item => ({
    name: item.name,
    value: item.count,
  }))

  const careerLevels = companyRewardsCandidates.careerLevels.map(item => ({
    name: intl.formatMessage({
      id: CAREER_LEVELS.find(({ id }) => id === item.level)?.name,
    }),
    value: item.count,
  }))

  return (
    <div>
      {[
        {
          statsKey: "jobCategories",
          data: jobCategories,
        },
        {
          statsKey: "careerLevels",
          data: careerLevels,
        },
      ].map(({ statsKey, data }) => (
        <MonetizationChartSection
          key={statsKey}
          statsKey={statsKey}
          data={data}
          isLoading={isLoading}
        />
      ))}

      <Row>
        {["locationCountries", "desiredRelocationCities"].map(statsKey => {
          if (isEmpty(companyRewardsCandidates[statsKey])) {
            return null
          }
          return (
            <Col xs={6} key={statsKey}>
              <h5 className="border-bottom py-2 mb-0">
                <FormattedMessage
                  id={`app.page.monetization.candidatesDetails.${statsKey}`}
                />
              </h5>
              <ListGroup flush>
                {companyRewardsCandidates[statsKey].map(
                  ({ name, count }, i) => (
                    <ListGroupItem
                      key={name}
                      className="justify-content-between d-flex border-0 border-bottom px-0 pt-2"
                    >
                      <div>
                        <span className="mr-2 font-weight-bold">{i + 1}</span>
                        {name}
                      </div>
                      <span>{count}</span>
                    </ListGroupItem>
                  ),
                )}
              </ListGroup>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
