import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"

const Content = ({ children }) => children

const Sidebar = ({ children }) => children

export const DoubleColumnLayout = ({ children, noGutters }) => {
  return (
    <Container>
      <Row noGutters={noGutters}>
        <Col sm="12" lg="8">
          {React.Children.toArray(children).find(c => c.type === Content)}
        </Col>
        <Col sm="12" lg="4" className="pt-4 pt-lg-0">
          {React.Children.toArray(children).find(c => c.type === Sidebar)}
        </Col>
      </Row>
    </Container>
  )
}
DoubleColumnLayout.Content = Content
DoubleColumnLayout.Sidebar = Sidebar

DoubleColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
  noGutters: PropTypes.bool,
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}
Content.propTypes = {
  children: PropTypes.node.isRequired,
}
