import { useEffect } from "react"
import PropTypes from "prop-types"
import { useStore } from "../../lib/store"
import { init } from "../../controllers/system-controller"

export const InitGate = ({ children }) => {
  const isInitialized = useStore(state => state.isInitialized)
  useEffect(() => {
    init()
  }, [])

  if (!isInitialized) {
    return null
  }
  return children
}

InitGate.propTypes = {
  children: PropTypes.node,
}
