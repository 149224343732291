import { useQuery } from "react-query"

export const useAxiosQuery = (queryKey, fn, options = {}) => {
  const res = useQuery(
    queryKey,
    async () => {
      const res = await fn(queryKey[1])
      return {
        data: res.data,
        headers: res.headers,
      }
    },
    {
      ...options,
    },
  )

  return {
    ...res,
    data: res.data?.data,
    headers: res.data?.headers,
  }
}
