import React from "react"
import { Container, FormGroup } from "reactstrap"
import { FormattedLink } from "../components/common/formatted-link-component"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { noAuthRequired } from "../decorators/no-auth-required"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { LabeledInputField } from "../components/fields/input-field"
import { Button } from "../components/common/button-component"
import { CheckboxField } from "../components/fields/checkbox-field"
import { useRouteMatch } from "react-router"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api } from "../lib/moberries-api"
import { activateSchema } from "../schemas/account-schemas"
import { activateUser } from "../controllers/user-controller"
import { BaseLayout } from "../components/layouts/base-layout"

const toRequest =
  ({ code }) =>
  ({ password }) => ({
    code,
    password,
  })

export const UserActivationPageComponent = () => {
  const routeMatch = useRouteMatch()
  const { mutateAsync: activate, isLoading: isActivating } = useAxiosMutation(
    api.activateUser,
    {
      onSuccess: ({ token }) => {
        activateUser({ token })
      },
    },
  )
  return (
    <BaseLayout className="py-5">
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.page.user.activation.header" />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <div className="bg-white px-3 py-5">
          <SmallInnerFormLayout>
            <Formik
              initialValues={{
                password: "",
                password2: "",
                privacy: false,
                terms: false,
              }}
              onSubmit={submitAndValidate(
                activate,
                toRequest({ code: routeMatch.params.code }),
              )}
              validationSchema={activateSchema}
            >
              <Form>
                <LabeledInputField
                  type="password"
                  name="password"
                  label={<FormattedMessage id="app.common.password" />}
                  placeholder="********"
                  isRequired
                  id="password"
                />
                <LabeledInputField
                  type="password"
                  name="password2"
                  label={
                    <FormattedMessage id="app.page.user.activation.repeatPassword" />
                  }
                  placeholder="********"
                  isRequired
                  id="password2"
                />
                <FormGroup>
                  {[
                    {
                      messageId: "app.page.user.activation.agreements.privacy",
                      name: "privacy",
                    },
                    {
                      messageId: "app.page.user.activation.agreements.terms",
                      name: "terms",
                    },
                  ].map(({ name, messageId }) => (
                    <CheckboxField
                      key={name}
                      name={name}
                      id={name}
                      label={
                        <FormattedMessage
                          id={messageId}
                          values={{
                            agreement_link: (...children) => (
                              <FormattedLink
                                href={name}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {children}
                              </FormattedLink>
                            ),
                          }}
                        />
                      }
                    />
                  ))}
                </FormGroup>

                <Button
                  block
                  color="primary"
                  size="lg"
                  loading={isActivating}
                  disabled={isActivating}
                  type="submit"
                >
                  <FormattedMessage id="app.common.activate" />
                </Button>
              </Form>
            </Formik>
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

export const UserActivationPage = noAuthRequired(UserActivationPageComponent)
