import { useFormikContext } from "formik"
import { useEffect } from "react"
import { usePrevious } from "react-hanger"
import { scrollToFirstInvalidField } from "../../lib/helpers/form-helpers"

export const FirstErrorScroll = () => {
  const { submitCount, errors } = useFormikContext()
  const prevSubmitCount = usePrevious(submitCount)
  useEffect(() => {
    if (submitCount > prevSubmitCount) {
      scrollToFirstInvalidField(errors)
    }
  }, [submitCount, prevSubmitCount, errors])
  return null
}
