import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Link } from "react-router-dom"

export const ContactNow = ({ matchId }) => (
  <div className="bg-light p-2 text-center border-top">
    <h5>
      <FormattedMessage id="app.page.match.feedback.title" />
    </h5>
    <p>
      <FormattedMessage id="app.page.match.feedback.text" />
    </p>
    <div>
      <Button tag={Link} color="primary" to={`/messages/new/${matchId}`}>
        <i className="fab fa-telegram-plane mr-1" />
        <FormattedMessage id="app.common.contactNow" />
      </Button>
    </div>
  </div>
)

ContactNow.propTypes = {
  matchId: PropTypes.number.isRequired,
}
