import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { PopoverBody } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { FormattedLink } from "../common/formatted-link-component"
import { InfoHint } from "../common/info-hint-component"
import { PriceComponent } from "../common/price-component"
import { shouldRenderMatchHiringFeeHint } from "../../lib/helpers"

export const MatchHiringFeeHint = ({ match }) => {
  if (!shouldRenderMatchHiringFeeHint(match)) {
    return null
  }

  const price = <PriceComponent price={parseFloat(match.price)} />

  return (
    <div className="bg-warning-light border-top p-2 text-center">
      <small>
        <FormattedMessage
          id="app.page.match.hiringFee.text"
          values={{
            note: (
              <strong>
                <FormattedMessage id="app.page.match.hiringFee.text.note" />
              </strong>
            ),
          }}
        />
      </small>
      <InfoHint
        id="pphHint"
        popover={
          <PopoverBody>
            <FormattedMessage
              id="app.page.match.hiringFee.hint.pph"
              values={{
                price,
                job_edit_link: (...children) => (
                  <Link to={`/job/edit/${match.job.id}`}>{children}</Link>
                ),
                pph_link: (...children) => (
                  <FormattedLink
                    href="payHire"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {children}
                  </FormattedLink>
                ),
              }}
            />
          </PopoverBody>
        }
      />
    </div>
  )
}

MatchHiringFeeHint.propTypes = {
  match: PropTypes.object.isRequired,
}
