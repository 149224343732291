import React from "react"
import { SmallSingleColumnLayout } from "../layouts/small-single-column-layout"
import { FormattedMessage } from "react-intl"
import { List } from "../loaders/list-loader"

export function SubscriptionOrderPageSkeleton(props) {
  return (
    <SmallSingleColumnLayout>
      <h1>
        <FormattedMessage id="app.page.subscription.order.header" />
      </h1>
      <p className="text-muted">
        <FormattedMessage id="app.page.subscription.order.subheader" />
      </p>
      <div className="bg-white p-3 mx-auto mt-2">
        <List />
      </div>
    </SmallSingleColumnLayout>
  )
}
