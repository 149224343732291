import React from "react"
import PropTypes from "prop-types"
import { Input as Binput, FormGroup, CustomInput } from "reactstrap"
import FormFeedback from "./form-feedback"
import { LabelWithHint } from "./label-with-hint-component"

export function Switch({
  input,
  meta,
  label,
  hint,
  disabled,
  className,
  ...rest
}) {
  const { error, valid, invalid, touched } = meta

  return (
    <FormGroup className={className}>
      <LabelWithHint label={label} hint={hint} for={rest.id} className="mb-2" />
      <CustomInput
        {...rest}
        {...input}
        disabled={disabled}
        checked={Boolean(input.value || input.checked)}
        type="switch"
      />

      <Binput
        type="hidden"
        valid={!touched || valid ? null : valid}
        invalid={!touched || valid ? null : invalid}
      />
      <FormFeedback error={error} />
    </FormGroup>
  )
}

Switch.propTypes = {
  label: PropTypes.node,
  hint: PropTypes.node,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Switch.defaultProps = {
  disabled: false,
}
