import { history } from "../history"
import { connectRouter } from "connected-react-router"
import { reducer as formReducer } from "redux-form"
import { reducer as alertReducer } from "../reducers/alert-reducer"
import { reducer as candidateReducer } from "../reducers/candidate-reducer"
import { reducer as matchReducer } from "../reducers/match-reducer"
import { reducer as messageReducer } from "../reducers/message-reducer"
import { reducer as companyReducer } from "../reducers/company-reducer"
import { reducer as jobReducer } from "../reducers/job-reducer"
import { reducer as invoiceReducer } from "../reducers/invoice-reducer"
import { reducer as systemReducer } from "../reducers/system-reducer"
import { reducer as paymentCardReducer } from "../reducers/payment-card-reducer"
import { reducer as surveyReducer } from "../reducers/survey-reducer"
import { reducer as messageTemplateReducer } from "../reducers/message-template-reducer"

import { reducer as slackIntegrationReducer } from "../reducers/slack-integration-reducer"
import { reducer as atsCompanyIntegrationReducer } from "../reducers/ats-company-integration-reducer"
import { reducer as atsCompanyIntegrationOpenJobsReducer } from "../reducers/ats-company-integration-open-jobs-reducer"
import { reducer as atsJobIntegrationReducer } from "../reducers/ats-job-integration-reducer"
import { reducer as subscriptionReducer } from "../reducers/subscription-reducer"
import { reducer as vrCampaignReducer } from "../reducers/vr-campaign-reducer"
import { reducer as vrCandidateReducer } from "../reducers/vr-candidate-reducer"

export const reducers = {
  form: formReducer,
  vrCampaign: vrCampaignReducer,
  vrCandidate: vrCandidateReducer,
  router: connectRouter(history),
  alert: alertReducer,
  candidate: candidateReducer,
  match: matchReducer,
  message: messageReducer,
  company: companyReducer,
  job: jobReducer,
  system: systemReducer,
  subscription: subscriptionReducer,
  invoice: invoiceReducer,
  card: paymentCardReducer,
  survey: surveyReducer,
  messageTemplate: messageTemplateReducer,

  slackIntegration: slackIntegrationReducer,

  atsJobIntegration: atsJobIntegrationReducer,
  atsCompanyIntegration: atsCompanyIntegrationReducer,
  atsCompanyIntegrationOpenJobs: atsCompanyIntegrationOpenJobsReducer,
}
