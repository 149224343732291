import React from "react"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout.js"
import { noAuthRequired } from "../decorators/no-auth-required"
import { Container } from "reactstrap"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { FormattedMessage } from "react-intl"
import { useHistory, useRouteMatch } from "react-router"
import { Form, Formik } from "formik"
import { LabeledInputField } from "../components/fields/input-field"
import { Button } from "../components/common/button-component"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api } from "../lib/moberries-api"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { doublePasswordSchema } from "../schemas/account-schemas"
import { alertTypes, showAlert } from "../lib/alerts"
import { BaseLayout } from "../components/layouts/base-layout.js"

const toRequest =
  ({ code }) =>
  ({ password }) => ({
    code,
    password,
  })

const PasswordResetPageComponent = () => {
  const history = useHistory()
  const routeMatch = useRouteMatch()
  const code = routeMatch.params.code
  const { mutateAsync: resetPassword, isLoading: isResetting } =
    useAxiosMutation(api.resetPassword, {
      onSuccess: () => {
        showAlert({ code: alertTypes.resetPasswordSuccess })
        history.push("/")
      },
    })

  return (
    <BaseLayout className="py-5">
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.page.password.change.header" />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <div className="bg-white px-3 py-5">
          <SmallInnerFormLayout>
            <Formik
              initialValues={{
                password: "",
                password2: "",
              }}
              onSubmit={submitAndValidate(resetPassword, toRequest({ code }))}
              validationSchema={doublePasswordSchema}
            >
              <Form>
                <LabeledInputField
                  type="password"
                  name="password"
                  label={
                    <FormattedMessage id="app.page.password.change.label" />
                  }
                  placeholder="********"
                  isRequired
                  id="password"
                />
                <LabeledInputField
                  type="password"
                  name="password2"
                  label={
                    <FormattedMessage id="app.page.password.change.confirmation.label" />
                  }
                  placeholder="********"
                  isRequired
                  id="password2"
                />
                <Button
                  block
                  color="primary"
                  size="lg"
                  loading={isResetting}
                  disabled={isResetting}
                  type="submit"
                >
                  <FormattedMessage id="app.common.send" />
                </Button>
              </Form>
            </Formik>
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

export const PasswordResetPage = noAuthRequired(PasswordResetPageComponent)
