import { isNil } from "ramda"
import * as yup from "yup"

export const jobDescriptionSchema = yup.object().shape({
  title: yup
    .string()
    .max(255, "app.validators.length.max")
    .required("app.validators.value.required"),
  description: yup
    .mixed()
    .test(
      "min-length",
      { id: "app.validators.length.min", values: { min: 250 } },
      editorState =>
        editorState.getCurrentContent().getPlainText().length >= 250,
    )
    .test(
      "max-length",
      "app.validators.length.max",
      editorState =>
        editorState.getCurrentContent().getPlainText().length <= 15000,
    )
    .required("app.validators.value.required"),
})

const selectFieldSchema = yup
  .object()
  .shape({
    id: yup.number().required("app.validators.value.required"),
    name: yup.string().required("app.validators.value.required"),
  })
  .nullable()
  .required("app.validators.value.required")

const languagesFieldSchema = yup
  .array()
  .min(1, "app.validators.languages.required")
  .test(
    "has-mustHave",
    "app.validators.languages.must-have.required",
    value =>
      value.find(({ mustHave }) => mustHave) &&
      !value.find(({ mustHave }) => isNil(mustHave)),
  )

export const jobDetailsSchema = yup.object().shape({
  locations: yup.array().min(1, "app.validators.value.required"),
  jobTypes: yup.array().min(1, "app.validators.value.required"),
  careerLevel: selectFieldSchema,
  jobRoles: yup.array().min(1, "app.validators.value.required"),
  languages: languagesFieldSchema,
  team: selectFieldSchema,
})
