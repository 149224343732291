import { max, min } from "ramda"
import { divideSafe } from "./helpers"

const getTotalApplications = stats =>
  stats.applicationsAccepted +
  stats.applicationsDeclined +
  stats.applicationsExpired +
  stats.applicationsPending +
  stats.applicationsAutoExported

const getCandidatesInReview = ({
  applicationsAutoExported,
  applicationsAccepted,
  applicationsRejected,
  applicationsApproved,
}) => {
  return max(
    applicationsAutoExported +
      applicationsAccepted -
      (applicationsApproved + applicationsRejected),
    0,
  )
}

export const metricsGetters = {
  applicationsReceived: stats => ({
    value: getTotalApplications(stats),
  }),
  applicationsAccepted: stats => ({
    value: stats.applicationsAccepted,
    ratio: min(
      divideSafe(stats.applicationsAccepted, getTotalApplications(stats)),
      1,
    ),
  }),
  applicationsDeclined: stats => ({
    value: stats.applicationsDeclined,
    ratio: min(
      divideSafe(stats.applicationsDeclined, getTotalApplications(stats)),
      1,
    ),
  }),
  applicationsExpired: stats => ({
    value: stats.applicationsExpired,
    ratio: min(
      divideSafe(stats.applicationsExpired, getTotalApplications(stats)),
      1,
    ),
  }),
  applicationsExported: stats => ({
    value: stats.applicationsAutoExported,
  }),
  jobsImported: stats => ({
    value: stats.jobsImported,
  }),
  candidatesInReview: stats => ({
    value: getCandidatesInReview(stats),
    ratio: min(
      divideSafe(getCandidatesInReview(stats), getTotalApplications(stats)),
      1,
    ),
  }),
  candidatesHired: stats => ({
    value: stats.applicationsApproved,
    ratio: min(
      divideSafe(stats.applicationsApproved, getTotalApplications(stats)),
      1,
    ),
    color: "success",
  }),
  candidatesRejected: stats => ({
    value: stats.applicationsRejected,
    ratio: min(
      divideSafe(stats.applicationsRejected, getTotalApplications(stats)),
      1,
    ),
    color: "danger",
  }),
  referralsTotal: stats => ({ value: stats.referralsTotal }),
  referralsMonth: stats => ({ value: stats.referralsMonth }),
}
