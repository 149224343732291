export const isLoggedInSelector = ({ jwt, manager, company }) =>
  [jwt, manager, company].every(Boolean)

export const companySubscriptionSelector = ({ company }) =>
  company.subscriptions.find(s => s.active)

export const jobSlotsLeftSelector = state => {
  const companySubscription = companySubscriptionSelector(state)
  const jobSlotsTotal = companySubscription.subscription.jobSlots
  const jobSlotsUsed = companySubscription.flatSlotsUsed
  const jobSlotsLeft = jobSlotsTotal - jobSlotsUsed

  return jobSlotsLeft
}
