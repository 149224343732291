import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { ErrorPage } from "../pages/error-page"
import { MatchOrJobFilter } from "../components/filters/match-or-job-filter-component"
import { approveMatch } from "../actions/match-actions"
import { rejectingSelector } from "../selectors/match-selector"
import { MatchListPageSkeleton } from "../components/skeletons/match-list-page-skeleton"
import { Pagination } from "../components/common/pagination-component"
import {
  orderingToMatchListQueryTransformer,
  matchStatusToQueryTransformer,
  resetPageQueryParam,
} from "../lib/helpers"
import qs from "qs"
import { MatchListSidebar } from "../components/sidebar/match-list-sidebar"
import { MatchListPageLayout } from "../components/layouts/match-list-page-layout"
import { MatchCard } from "../components/match/match-card-component"
import { AtsProviderSection } from "../components/integration/ats-provider-section"
import {
  ATS_APPLICATION_SYNC_OPTIONS,
  MATCH_LIST_MATCHES_LIMIT,
  MATCH_STATUSES,
} from "../constants"
import { FormattedMessage } from "react-intl"
import defaultImage from "../assets/img/default-empty-state.svg"
import { TabsFilter } from "../components/filters/tabs-filter"
import { Redirect } from "react-router"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api, ApiKeys } from "../lib/moberries-api"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { useQueryClient } from "react-query"
import { alertTypes, showAlert } from "../lib/alerts"
import { useStore } from "../lib/store"

const MatchList = ({
  page,
  rejecting,
  push,
  job,
  jobTeam,
  status,
  ordering,
  search,
  location,
}) => {
  const { company, manager, atsCompanyIntegration, connectedProvider } =
    useStore(
      ({ company, manager, atsCompanyIntegration, connectedProvider }) => ({
        company,
        manager,
        atsCompanyIntegration,
        connectedProvider,
      }),
    )

  const currentQueryParams = {
    companyId: company.id,
    params: {
      page,
      limit: MATCH_LIST_MATCHES_LIMIT,
      ordering: orderingToMatchListQueryTransformer(ordering),
      status__in: matchStatusToQueryTransformer(status),
      job,
      job__team: jobTeam,
      search,
    },
  }
  const {
    data: { results: matches = [], count } = {},
    isLoading,
    isFetching,
    error,
  } = useAxiosQuery([ApiKeys.Matches, currentQueryParams], api.getMatchList, {
    keepPreviousData: true,
  })

  const queryClient = useQueryClient()
  const { mutateAsync: approveMatch, isLoading: isApproving } =
    useAxiosMutation(
      ({ id, companyId }) =>
        api.updateMatch({
          id,
          companyId,
          match: {
            status: MATCH_STATUSES.COMPANY_APPROVED,
            hireConfirmed: true,
          },
        }),
      {
        onSuccess: () => {
          showAlert({ code: alertTypes.matchApproveSuccess })
          queryClient.invalidateQueries([ApiKeys.Matches, currentQueryParams])
        },
      },
    )

  if (error) {
    return <ErrorPage err={error} />
  }
  if (matches.length === 0 && page > 1) {
    return (
      <Redirect
        to={{
          pathname: "/candidates",
          search: resetPageQueryParam(location.search),
        }}
      />
    )
  }

  const isAtsAutoMatchExportEnabled =
    connectedProvider &&
    atsCompanyIntegration?.matchExportType ===
      ATS_APPLICATION_SYNC_OPTIONS.ON_CAN_ACCEPT

  const isDefaultTabActive = !status
  const shouldShowAtsCard = isAtsAutoMatchExportEnabled && isDefaultTabActive
  const hasFilterApplied = search || jobTeam

  if (isLoading) {
    return <MatchListPageSkeleton />
  }

  return (
    <MatchListPageLayout>
      <TabsFilter
        content="true"
        filterOptions={[null, "approved"]}
        filterKey="status"
        filterName="match"
        resetFilters={["page", "jobTeam", "search"]}
      />
      <MatchOrJobFilter
        content="true"
        teams={manager.teams}
        orderingFilterOptions={[
          { desc: null, asc: "date" },
          { asc: "title", desc: "-title" },
        ]}
        filterName="match"
      />
      {shouldShowAtsCard && (
        <div content="true" className="mb-2">
          <AtsProviderSection
            provider={connectedProvider}
            atsCompanyIntegration={atsCompanyIntegration}
          />
        </div>
      )}
      {matches.map(match => (
        <div content="true" key={match.id} className="mb-2">
          <MatchCard
            page={page}
            match={match}
            disabled={isApproving || rejecting}
            onApprove={() =>
              approveMatch({
                id: match.id,
                companyId: match.job.company.id,
              })
            }
            onReject={() => {
              push(`/match/${match.id}?reject`, { fromPage: page })
            }}
          />
        </div>
      ))}
      {count === 0 && hasFilterApplied && (
        <div className="p-2" content="true">
          <FormattedMessage id="app.page.dashboard.matches.empty.filtering" />
        </div>
      )}
      {count === 0 && (
        <img
          content="true"
          alt="Empty"
          width={600}
          className="img-fluid"
          src={defaultImage}
        />
      )}
      {count > MATCH_LIST_MATCHES_LIMIT && (
        <Pagination
          content="true"
          className="mt-3"
          listClassName="justify-content-end"
          page={page}
          count={count}
          disabled={isFetching}
          limit={MATCH_LIST_MATCHES_LIMIT}
          basePath="/candidates"
        />
      )}
      <MatchListSidebar sidebar statistics={company.statistics} />
    </MatchListPageLayout>
  )
}

MatchList.propTypes = {
  push: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rejecting: PropTypes.bool.isRequired,
  job: PropTypes.number,
  jobTeam: PropTypes.number,
  status: PropTypes.string,
  ordering: PropTypes.string,
  search: PropTypes.string,
  location: PropTypes.object,
}

MatchList.defaultProps = {
  matches: [],
  jobTitles: [],
  page: 1,
}
const mapDispatchToProps = {
  push,
  approveMatch,
}

const mapStateToProps = function (state, ownProps) {
  const query = qs.parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  })
  const { status, ordering, job, jobTeam, search, page } = query

  return {
    rejecting: rejectingSelector(state),
    job,
    jobTeam: jobTeam && Number(jobTeam),
    status,
    ordering,
    search,
    page: page ? Number(page) : 1,
  }
}

export const MatchListPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchList)
