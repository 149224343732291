import { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { connectOauthProvider } from "../actions/user-actions"

export function AuthCallback({ connectOauthProvider }) {
  useEffect(() => {
    connectOauthProvider()
  }, [connectOauthProvider])
  return <div className="callback-loader" />
}

AuthCallback.propTypes = {
  connectOauthProvider: PropTypes.func.isRequired,
}

const mapDispatchToProps = { connectOauthProvider }

const mapStateToProps = function (state) {
  return {}
}

export const AuthCallbackPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthCallback)
