import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"

export const SmallSingleColumnLayout = props => {
  return (
    <Container>
      <Row>
        <Col lg={{ size: 10, offset: 1 }} md={12}>
          {props.children}
        </Col>
      </Row>
    </Container>
  )
}

SmallSingleColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
