import React from "react"
import { FormattedMessage } from "react-intl"
import { Media, Button } from "reactstrap"
import { format } from "date-fns"

import { FormattedLink } from "../../../common/formatted-link-component"
import { Img } from "../../../common/img-component"
import { DashboardWidgetLayout } from "../../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"

import successCheck from "../../../../assets/img/success-check-icon.svg"
import styles from "./vr-widget-no-campaigns.module.scss"

const checks = [
  {
    id: 1,
    value: <FormattedMessage id="app.widget.virtualRecruiter.check1" />,
  },
  {
    id: 2,
    value: <FormattedMessage id="app.widget.virtualRecruiter.check2" />,
  },
  {
    id: 3,
    value: <FormattedMessage id="app.widget.virtualRecruiter.check3" />,
  },
]

export const VRWidgetNoCampaigns = () => {
  return (
    <DashboardWidgetLayout
      title={<FormattedMessage id="app.widget.virtualRecruiter.title" />}
    >
      <h4 className={styles.header}>
        <FormattedMessage id="app.widget.virtualRecruiter.header" />
        <br />
        <FormattedLink
          target="_blank"
          rel="noopener noreferrer"
          href="virtualRecruiter"
        >
          <FormattedMessage id="app.widget.virtualRecruiter.learnMore" />
        </FormattedLink>
      </h4>
      {checks.map(({ id, value }) => (
        <Media className={styles.media} key={id}>
          <Img src={successCheck} alt="check-icon" width="10" height="8" />
          <Media body>
            <p className="m-0">{value}</p>
          </Media>
        </Media>
      ))}
      <Button
        className={styles.button}
        href={`https://calendly.com/moberries-dustin-hillemacher/30min?month=${format(
          new Date(),
          "yyyy-MM",
        )}`}
        target="_blank"
      >
        <FormattedMessage id="app.widget.virtualRecruiter.bookConsultation" />
      </Button>
    </DashboardWidgetLayout>
  )
}
