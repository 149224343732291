import React from "react"
import styles from "./levels-categories.module.scss"
import { SlicedDoughnuts } from "../lib/sliced-doughnuts/sliced-doughnuts"
import PropTypes from "prop-types"

export function LevelsCategories(props) {
  const { categories, levels } = props.data
  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <div className={styles.col}>
          {!!levels.length && (
            <SlicedDoughnuts
              title="app.page.analytics.charts.activeJobs.career.name"
              data={levels}
            />
          )}
        </div>
        <div className={styles.col}>
          {!!categories.length && (
            <SlicedDoughnuts
              title="app.page.analytics.charts.activeJobs.category.name"
              data={categories}
            />
          )}
        </div>
      </div>
    </div>
  )
}

LevelsCategories.propTypes = {
  data: PropTypes.object,
}
