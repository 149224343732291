import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { PredefinedAnswersList } from "../job/predefined-answers-list"
import { useOpenClose } from "../../hooks/use-open-close"
import { APPLICATION_QUESTION_TYPES } from "../../constants"
import { LabeledSelectField } from "../fields/select-field"
import { LabeledTextareaField } from "../fields/textarea-field"
import { Formik, FieldArray, Form } from "formik"
import { CheckboxField } from "../fields/checkbox-field"
import { applicationQuestionTypeToOption } from "../../lib/helpers"
import { JobApplicationQuestionDeleteModal } from "../modals/job-application-question-delete-modal"
import { jobApplicationQuestionSchema } from "../../schemas/job-application-question-schemas"

export const JobApplicationQuestionsForm = ({
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const isEditView = Boolean(initialValues.id)
  const {
    open: openModal,
    value: isModalOpen,
    close: closeModal,
  } = useOpenClose({ initialValue: false })

  const applicationQuestionsTypesOptions = Object.values(
    APPLICATION_QUESTION_TYPES,
  ).map(applicationQuestionTypeToOption)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={jobApplicationQuestionSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <LabeledSelectField
              label={<FormattedMessage id="app.job.questions.type.label" />}
              name="questionType"
              id="questionType"
              options={applicationQuestionsTypesOptions}
              getOptionLabel={option => <FormattedMessage id={option.name} />}
              getOptionValue={option => option.value}
              isSearchable={false}
              backspaceRemovesValue={false}
              withCaret
              isRequired
              isDisabled={isEditView}
            />

            <LabeledTextareaField
              label={<FormattedMessage id="app.job.questions.question.label" />}
              name="title"
              id="title"
              isRequired
              minRows={2}
              maxRows={15}
            />

            <FieldArray
              component={PredefinedAnswersList}
              label={
                <FormattedMessage id="app.job.questions.predefinedAnswers.label" />
              }
              id="predefinedAnswers"
              name="predefinedAnswers"
            />

            <CheckboxField
              label={
                <FormattedMessage id="app.job.questions.isRequired.label" />
              }
              id="isRequired"
              name="isRequired"
              defaultChecked={initialValues?.isRequired}
            />

            <div className="d-flex justify-content-between mt-3">
              <div>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  color="primary"
                  type="submit"
                  size="lg"
                  className="mr-2"
                >
                  <FormattedMessage id="app.common.save" />
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="light"
                  size="lg"
                  onClick={onCancel}
                >
                  <FormattedMessage id="app.common.cancel" />
                </Button>
              </div>
              {isEditView && (
                <Fragment>
                  <JobApplicationQuestionDeleteModal
                    isOpen={isModalOpen}
                    close={closeModal}
                    applicationQuestionId={initialValues.id}
                    onDeleteSuccess={onCancel}
                  />
                  <div
                    className="text-danger clickable d-flex align-items-center"
                    onClick={openModal}
                  >
                    <i className="fas fa-lg fa-times-circle mr-2" />
                    <FormattedMessage id="app.job.questions.delete.label" />
                  </div>
                </Fragment>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

JobApplicationQuestionsForm.propTypes = {
  initialValues: PropTypes.shape({
    job: PropTypes.number,
    company: PropTypes.number,
    id: PropTypes.number,
    questionType: PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    title: PropTypes.string,
    predefinedAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string,
        order: PropTypes.number,
        id: PropTypes.number,
      }),
    ),
    isRequired: PropTypes.bool,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
