import React, { useEffect } from "react"
import { prop } from "ramda"
import PropTypes from "prop-types"
import { Modal, ModalHeader } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { UncontrolledCarousel } from "../common/carousel-component"
import { Button } from "../common/button-component"
import { FormattedLink } from "../common/formatted-link-component"
import {
  hasImagesCv,
  canDownloadCv,
  downloadCvFromPdfService,
  isLinkedinProfileConnected,
  getLinkedinProfileLink,
  popupCenter,
} from "../../lib/helpers"
import { usePrevious } from "react-hanger"
import { useStore } from "../../lib/store"

export function CandidateCVGallery({ candidate, match, onClose, isOpen }) {
  const token = useStore(state => state.jwt)
  const prevIsOpen = usePrevious(isOpen)
  const hasImages = hasImagesCv(prop("candidateCv", candidate))

  useEffect(() => {
    const didOpen = isOpen && !prevIsOpen
    if (
      candidate &&
      didOpen &&
      !hasImages &&
      isLinkedinProfileConnected(candidate.socialAccounts)
    ) {
      const linkedinProfileLink = getLinkedinProfileLink(
        candidate.socialAccounts,
      )

      popupCenter(linkedinProfileLink, "linkedinRef", 840, 600)
    }
  }, [candidate, isOpen, prevIsOpen, hasImages])

  if (!candidate) {
    return null
  }

  if (!hasImagesCv(candidate.candidateCv)) {
    return null
  }

  const { images } = candidate.candidateCv.urls

  const areActiveControls = Boolean(images && images.length > 1)

  return (
    <Modal
      contentClassName="cv-preview-modal"
      isOpen={isOpen}
      toggle={onClose}
      size="lg"
      centered
    >
      <ModalHeader toggle={onClose} className="text-white px-0">
        <FormattedMessage id="app.candidate.cv.preview.title" />
        {canDownloadCv(match) && (
          <Button
            size="md"
            className="ml-2 "
            onClick={() => downloadCvFromPdfService(match, token)}
          >
            <i className="fas fa-file-download mr-md-2" />
            <span className="d-none d-md-inline">
              <FormattedMessage id="app.common.downloadCv" />
            </span>
          </Button>
        )}
        {!canDownloadCv(match) && (
          <small className="d-block mt-1 font-weight-light">
            <FormattedMessage
              id="app.candidate.cv.preview.subtitle"
              values={{
                link: (
                  <FormattedLink
                    className="default-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="terms"
                  >
                    <FormattedMessage id="app.candidate.cv.preview.subtitle.link" />
                  </FormattedLink>
                ),
              }}
            />
          </small>
        )}
      </ModalHeader>
      <UncontrolledCarousel
        className="_lr-hide"
        items={images.map(u => ({ src: u, caption: "" }))}
        autoPlay={false}
        interval={86400000}
        indicators={areActiveControls}
        controls={areActiveControls}
      />
    </Modal>
  )
}

CandidateCVGallery.propTypes = {
  candidate: PropTypes.object,
  match: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

CandidateCVGallery.defaultProps = {
  isCVPreviewOpen: false,
}
