import React from "react"
import PropTypes from "prop-types"
import { getLocationName } from "../../lib/helpers"
import { propOr } from "ramda"
import styles from "./candidate-location-component.module.scss"

export const CandidateLocation = ({ candidate, onlyCountry }) => {
  const city = propOr("", "city", candidate)
  return (
    <span className={styles.text}>{getLocationName(city, onlyCountry)}</span>
  )
}

CandidateLocation.propTypes = {
  candidate: PropTypes.object.isRequired,
  onlyCountry: PropTypes.bool.isRequired,
}

CandidateLocation.defaultProps = {
  onlyCountry: false,
}
