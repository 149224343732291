import React from "react"
import PropTypes from "prop-types"
import { SmallSingleColumnLayout } from "./small-single-column-layout"
import { AtsIntegrationProviderCard } from "../integration/ats-integration-provider-card-component"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"
import { FormattedLink } from "../common/formatted-link-component"

export const AtsIntegrationJobListLayout = ({ children, provider }) => {
  return (
    <SmallSingleColumnLayout>
      <AtsIntegrationProviderCard
        isConnected
        provider={provider}
        className="mb-2"
        controls={
          <Button
            className="btn-pill"
            outline
            color="primary"
            tag={FormattedLink}
            href="atsIntegrationsGuide"
            target="_blank"
          >
            <i className="fas fa-book-open mr-md-1" />
            <span className="d-none d-md-inline">
              <FormattedMessage id="app.page.integrations.guide" />
            </span>
          </Button>
        }
      />
      {children}
    </SmallSingleColumnLayout>
  )
}

AtsIntegrationJobListLayout.propTypes = {
  provider: PropTypes.object.isRequired,
  children: PropTypes.node,
}
