import React from "react"
import { SingleColumnLayout } from "../layouts/single-column-layout"
import { CardLoader } from "../loaders/card-loader"
import { List } from "../loaders/list-loader"
import { FormattedMessage } from "react-intl"

export function JobEditPageSkeleton(props) {
  return (
    <SingleColumnLayout>
      <h1 className="text-center mb-4">
        <FormattedMessage id="app.job.form.edit.header" />
      </h1>

      <div className="bg-white rounded p-3 mb-4">
        <CardLoader />
      </div>
      <div className="bg-white p-3">
        <div className="w-75 mx-auto mt-5">
          <List />
          <List />
        </div>
      </div>
    </SingleColumnLayout>
  )
}
