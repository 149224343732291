import React from "react"
import PropTypes from "prop-types"
import { List } from "../loaders/list-loader"
import { FormattedMessage } from "react-intl"
import { CardLoader } from "../loaders/card-loader"
import { times } from "ramda"
import { DoubleColumnLayoutDashboard } from "../../components/layouts/dashboard/double-column-layout-dashboard"
import { TripleColumnLayoutDashboard } from "../../components/layouts/dashboard/triple-column-layout-dashboard"
import { Container, Row, Col } from "reactstrap"
import cn from "classnames"
import styles from "./dashboard-page-skeleton.module.scss"

export const DashboardPageSkeleton = ({ className }) => (
  <div>
    <Container className={cn(className, styles.skeleton)}>
      <Row>
        <Col lg="8">
          <h1>
            <FormattedMessage id="app.page.dashboard.matches.header.loading" />
          </h1>
          <p>
            <FormattedMessage id="app.page.dashboard.matches.subheader.loading" />
          </p>
        </Col>
      </Row>
    </Container>

    <DoubleColumnLayoutDashboard>
      <DoubleColumnLayoutDashboard.Content>
        {times(
          i => (
            <div key={i} className={styles.cardLoader}>
              <CardLoader />
            </div>
          ),
          4,
        )}
      </DoubleColumnLayoutDashboard.Content>
      <DoubleColumnLayoutDashboard.Sidebar>
        <div className={styles.listLoader}>
          {times(
            i => (
              <List key={i} />
            ),
            3,
          )}
        </div>
      </DoubleColumnLayoutDashboard.Sidebar>
    </DoubleColumnLayoutDashboard>

    <TripleColumnLayoutDashboard rowGutters="mt-3" colGutters="p-3">
      <TripleColumnLayoutDashboard.TalentsYouMissWidget>
        <div className={styles.listLoader}>
          <List />
        </div>
      </TripleColumnLayoutDashboard.TalentsYouMissWidget>
      <TripleColumnLayoutDashboard.VirtualRecruiterWidget>
        <div className={styles.listLoader}>
          <List />
        </div>
      </TripleColumnLayoutDashboard.VirtualRecruiterWidget>
      <TripleColumnLayoutDashboard.SharedTalentNetworkWidget>
        <div className={styles.listLoader}>
          <List />
        </div>
      </TripleColumnLayoutDashboard.SharedTalentNetworkWidget>
    </TripleColumnLayoutDashboard>
  </div>
)

DashboardPageSkeleton.propTypes = {
  className: PropTypes.string,
}
