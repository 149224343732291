import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { UncontrolledPopover } from "reactstrap"
import cn from "classnames"

export const InfoHint = ({ id, popover, color }) => {
  return (
    <Fragment>
      <i
        id={`info-hint-popover-${id}`}
        className={cn("fas fa-question-circle clickable ml-2", {
          [`text-${color}`]: Boolean(color),
        })}
      />
      <UncontrolledPopover
        placement="top"
        trigger="legacy"
        target={`info-hint-popover-${id}`}
      >
        {popover}
      </UncontrolledPopover>
    </Fragment>
  )
}

InfoHint.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  popover: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
}

InfoHint.defaultProps = {
  color: "primary",
}
