import React, { Fragment } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { DashboardPage } from "./pages/dashboard/dashboard-page"
import { JobListPage } from "./pages/job-list-page"
import { JobDetailsPage } from "./pages/job-details-page"
import { JobCreatePage } from "./pages/job-create-page"
import { JobEditPage } from "./pages/job-edit-page"
import { MatchListPage } from "./pages/match-list-page"
import { MatchPage } from "./pages/match-page"
import { ReferredCandidatesListPage } from "./pages/referred-candidate-list-page"
import { ReferredCandidatePage } from "./pages/referred-candidate-page"
import { AccountCredentialsPage } from "./pages/account-credentials-page"
import { AccountPersonalPage } from "./pages/account-personal-page"
import { CompanyProfilePage } from "./pages/company-profile-page"
import { CompanyTeamsPage } from "./pages/company-teams-page"
import { CompanyInvoicesPage } from "./pages/company-invoices-page"
import { SubscriptionOrderPage } from "./pages/subscription-order-page"
import { SubscriptionPage } from "./pages/subscription-page"
import { LoginPage } from "./pages/login-page"
import { SignupPage } from "./pages/signup-page"
import { ResendPage } from "./pages/resend-page"
import { ConfirmNewEmailPage } from "./pages/confirm-new-email-page"
import { UserActivationPage } from "./pages/user-activation-page"
import { PasswordRecoveryPage } from "./pages/password-recovery-page"
import { PasswordResetPage } from "./pages/password-reset-page"
import { AuthCallbackPage } from "./pages/auth-callback-page"
import { NotFoundPage } from "./pages/not-found-page"
import { PaymentsBillingPage } from "./pages/payments-billing-page"
import { PaymentsCardPage } from "./pages/payment-card-page"
import { CompanyManagersPage } from "./pages/company-managers-page"
import { ManagerInvitePage } from "./pages/manager-invite-page"
import { ManagerEditPage } from "./pages/manager-edit-page"
import { MessengerPage } from "./pages/messenger-page"
import { AtsIntegrationsPage } from "./pages/ats-integrations-page"
import { AtsIntegrationProviderPage } from "./pages/ats-integration-provider-page"
import { OtherIntegrationsPage } from "./pages/other-integrations-page"
import { AtsIntegrationConnectionPage } from "./pages/ats-integration-connection-page"
import { AtsIntegrationSettingsPage } from "./pages/ats-integration-settings-page"
import { AtsMergeJobsPage } from "./pages/ats-merge-jobs-page"
import { AtsIntegrationImportJobsPage } from "./pages/ats-integration-import-jobs-page"
import { JobsConditionsPage } from "./components/subscription/jobs-conditions-page"
import { FeedbackOverviewPage } from "./pages/feedback-overview-page"
import { MessageTemplatesPage } from "./pages/message-templates-page"
import { MonetizationDashboardPage } from "./pages/monetization-dashboard-page"
import { MessengerNewThreadPage } from "./pages/messenger-new-thread-page"
import { AnalyticsPage } from "./pages/analytics/analytics-page"
import { StrategyBuilderPage } from "./pages/analytics/strategy-builder-page/strategy-builder-page"
import { LoggedInGuard } from "./components/common/logged-in-guard"
import { BaseLayout } from "./components/layouts/base-layout"

export function Routes() {
  return (
    <Fragment>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SignupPage} />
        <Route path="/resend" component={ResendPage} />
        <Route path="/password-recovery" component={PasswordRecoveryPage} />
        <Route path="/reset/:code" component={PasswordResetPage} />
        <Route path="/activate/:slug/:code" component={UserActivationPage} />

        <Route>
          <LoggedInGuard redirect={true}>
            <BaseLayout className="py-5">
              <Switch>
                <Route path="/" exact component={DashboardPage} />

                <Route path="/jobs" exact component={JobListPage} />
                <Route path="/job/create" exact component={JobCreatePage} />
                <Route path="/job/edit/:id" component={JobEditPage} />
                <Route path="/job/:id" component={JobDetailsPage} />

                <Route
                  path="/integrations/ats"
                  exact
                  component={AtsIntegrationsPage}
                />

                <Route
                  path="/integrations/other"
                  exact
                  component={OtherIntegrationsPage}
                />
                <Route
                  path="/integrations/ats/options"
                  exact
                  component={AtsIntegrationSettingsPage}
                />
                <Route
                  path="/integrations/ats/merge"
                  exact
                  component={AtsMergeJobsPage}
                />
                <Route
                  path="/integrations/ats/import"
                  exact
                  component={AtsIntegrationImportJobsPage}
                />
                <Route
                  path="/integrations/ats/:slug/connect"
                  exact
                  component={AtsIntegrationConnectionPage}
                />
                <Route
                  path="/integrations/ats/:slug"
                  exact
                  component={AtsIntegrationProviderPage}
                />

                <Route path="/candidates" exact component={MatchListPage} />
                <Route path="/match/:id" component={MatchPage} />

                <Route path="/analytics" exact component={AnalyticsPage} />
                <Route
                  path="/analytics/industry-benchmarks"
                  exact
                  component={StrategyBuilderPage}
                />

                <Route
                  path="/referrals"
                  exact
                  component={ReferredCandidatesListPage}
                />
                <Route path="/referral/:id" component={ReferredCandidatePage} />

                <Route path="/company" exact component={CompanyProfilePage} />
                <Route path="/company/teams" component={CompanyTeamsPage} />
                <Route
                  path="/company/managers"
                  exact
                  component={CompanyManagersPage}
                />

                <Route path="/managers/invite" component={ManagerInvitePage} />
                <Route path="/managers/:id/edit" component={ManagerEditPage} />

                <Route
                  path="/payments/invoices"
                  component={CompanyInvoicesPage}
                />
                <Route
                  path="/payments/billing"
                  component={PaymentsBillingPage}
                />
                <Route path="/payments/card" component={PaymentsCardPage} />

                <Route path="/account" exact component={AccountPersonalPage} />
                <Route
                  path="/account/credentials"
                  component={AccountCredentialsPage}
                />

                <Route path="/subscribe" component={SubscriptionOrderPage} />
                <Route
                  path="/subscription/jobs-conditions"
                  component={JobsConditionsPage}
                />
                <Route path="/subscription" component={SubscriptionPage} />

                <Route
                  path="/confirm/email/:code"
                  component={ConfirmNewEmailPage}
                />

                <Route path="/auth/callback" component={AuthCallbackPage} />
                <Route path="/messages" exact component={MessengerPage} />
                <Route path="/messages/:id" exact component={MessengerPage} />
                <Route
                  path="/messages/new/:matchId"
                  exact
                  component={MessengerNewThreadPage}
                />
                <Route
                  path="/candidates-feedback"
                  exact
                  component={FeedbackOverviewPage}
                />
                <Route
                  path="/message-templates"
                  exact
                  component={MessageTemplatesPage}
                />
                <Route
                  path="/monetization-dashboard"
                  component={MonetizationDashboardPage}
                />

                <Redirect from="/integrations" exact to="/integrations/ats" />

                <Route component={NotFoundPage} />
              </Switch>
            </BaseLayout>
          </LoggedInGuard>
        </Route>
      </Switch>
    </Fragment>
  )
}
