import React from "react"
import PropTypes from "prop-types"
import { times } from "ramda"

import { AnalyticsChartLayout } from "../../layouts/analytics/analytics-chart-layout/analytics-chart-layout"
import { List } from "../../loaders/list-loader"

import styles from "./chart-skeleton-state.module.scss"

export const ChartSkeletonState = ({ title, type }) => {
  let loader

  switch (type) {
    // TODO: future-improvements: Add cases for HorizontalBarCharts, VerticalBarCharts, LineCharts, HorizontalRangeBarCharts

    default:
      loader = (
        <div className={styles.loader}>
          {times(
            i => (
              <List key={i} />
            ),
            2,
          )}
        </div>
      )
      break
  }

  return <AnalyticsChartLayout title={title}>{loader}</AnalyticsChartLayout>
}

ChartSkeletonState.propTypes = {
  title: PropTypes.node,
  type: PropTypes.string,
}
