import React from "react"
import PropTypes from "prop-types"

export const MatchApplicationListAnswer = ({ applicationAnswer }) => {
  return (
    <ul className="px-4 m-0">
      {applicationAnswer.answer.map(answer => (
        <li key={answer.id}>{answer.answer}</li>
      ))}
    </ul>
  )
}

MatchApplicationListAnswer.propTypes = {
  applicationAnswer: PropTypes.shape({
    answer: PropTypes.array.isRequired,
    question: PropTypes.object.isRequired,
  }),
}
