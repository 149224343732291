import { isProblem } from "../lib/helpers"
import { PROBLEMS } from "../constants"

export const cancelRequestMiddleware = () => next => action => {
  const { type, payload } = action
  if (!payload || !payload.err) return next(action)
  if (isProblem(PROBLEMS.CANCEL_ERROR, payload.err)) {
    return next({
      ...action,
      type: type.replace(/_FAIL$/, "_CANCEL"),
    })
  }
  return next(action)
}
