import { useInfiniteQuery } from "react-query"
import { flatten, pluck, prop, uniqBy } from "ramda"

export const useInfiniteAxiosQuery = (
  queryKey,
  fn,
  { pageSize = 10, ...options } = {},
) => {
  const query = useInfiniteQuery(
    queryKey,
    queryContext => {
      const queryParams = queryContext.queryKey[1] ?? {}
      return fn({
        ...queryParams,
        offset: queryContext.pageParam ?? 0,
        limit: pageSize,
      })
    },
    {
      getNextPageParam: lastPage => {
        if (
          lastPage.config.params.offset + lastPage.data.results.length >=
          lastPage.data.count
        ) {
          // Returning undefined signifies that there's no more pages
          return undefined
        }
        return lastPage.config.params.offset + pageSize
      },
      ...options,
    },
  )

  if (query.data?.pages) {
    query.axiosResponse = query.data
    const pagesData = query.data.pages.map(page => ({
      count: page.data.count,
      results: page.data.results,
    }))

    query.pages = query.data.pages

    query.data = {
      // We want to get the max of the counts, in case a new entity is created in between page fetches.
      // For the same reason we need to use `uniqBy` - because a new entity would shift pages
      count: Math.max(...pluck("count", pagesData)),
      results: uniqBy(prop("id"), flatten(pluck("results", pagesData))),
    }
  }

  return query
}
