import PropTypes from "prop-types"
import cn from "classnames"
import { format } from "date-fns"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"

import { useOpenClose } from "../../../../hooks/use-open-close"
import { campaignsSelector } from "../../../../selectors/vr-campaign-selector"
import { Img } from "../../../common/img-component"
import { DashboardWidgetLayout } from "../../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import { RoundChart } from "../../../charts/round-chart/round-chart"
import { VirtualRecruiterModal } from "../../../modals/virtual-recruiter-modal/virtual-recruiter-modal"

import TargetIcon from "../../../../assets/img/target.png"
import styles from "./vr-widget-best-campaign.module.scss"

const calendarLink = `https://calendly.com/moberries-dustin-hillemacher/30min?month=${format(
  new Date(),
  "yyyy-MM",
)}`

const getBestCampaign = ({ campaigns }) => {
  return [...campaigns]
    .filter(c => c.statistics)
    .sort(
      (a, b) =>
        parseFloat(b.statistics.successRate) -
        parseFloat(a.statistics.successRate),
    )[0]
}

export const VRWidgetBestCampaignComponent = ({ campaigns }) => {
  const {
    value: isModalVisible,
    open: showModal,
    close: hideModal,
  } = useOpenClose({ initialValue: false })

  const {
    jobTitle,
    statistics: { clicked, connected, invited, responded, successRate },
  } = getBestCampaign({ campaigns })

  return (
    <DashboardWidgetLayout
      title={<FormattedMessage id="app.widget.virtualRecruiter.title" />}
    >
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>
          <FormattedMessage id="app.widget.virtualRecruiter.yourMostSuccessfulCampaign" />
          <Img src={TargetIcon} width="20" height="20" />
        </p>
        <p className={styles.title}>{jobTitle}</p>

        <div className={styles.chartWrapper}>
          <RoundChart value={parseInt(successRate)} />
        </div>

        <div className={styles.stats}>
          <div className={cn([styles.statItem, styles.statItem_invited])}>
            <p className={styles.statValue}>{invited}</p>
            <p className={styles.statLabel}>
              <FormattedMessage id="app.modal.virtualRecruiter.invited" />
            </p>
          </div>
          <div className={cn([styles.statItem, styles.statItem_connected])}>
            <p className={styles.statValue}>{connected}</p>
            <p className={styles.statLabel}>
              <FormattedMessage id="app.modal.virtualRecruiter.connected" />
            </p>
          </div>
          <div className={cn([styles.statItem, styles.statItem_responded])}>
            <p className={styles.statValue}>{responded}</p>
            <p className={styles.statLabel}>
              <FormattedMessage id="app.modal.virtualRecruiter.responded" />
            </p>
          </div>
          <div className={cn([styles.statItem, styles.statItem_clicked])}>
            <p className={styles.statValue}>{clicked}</p>
            <p className={styles.statLabel}>
              <FormattedMessage id="app.modal.virtualRecruiter.clicked" />
            </p>
          </div>
        </div>

        <div className={styles.actions}>
          <button className={styles.actionsButton} onClick={showModal}>
            <FormattedMessage id="app.widget.virtualRecruiter.myCampaigns" />
          </button>
          <span className={styles.actionsDivider}></span>
          <a
            href={calendarLink}
            target="_blank"
            rel="noreferrer"
            className={styles.actionsButton}
          >
            <FormattedMessage id="app.widget.virtualRecruiter.addCampaign" />
          </a>
        </div>
      </div>

      <VirtualRecruiterModal isOpen={isModalVisible} toggle={hideModal} />
    </DashboardWidgetLayout>
  )
}

VRWidgetBestCampaignComponent.propTypes = {
  campaigns: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  campaigns: campaignsSelector(state),
})

export const VRWidgetBestCampaign = connect(mapStateToProps)(
  VRWidgetBestCampaignComponent,
)
