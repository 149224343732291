import React from "react"
import PropTypes from "prop-types"
import styles from "./dual-chart-layout.module.scss"

const Top = ({ children }) => children
const Bottom = ({ children }) => children

export const DualChartVerticalLayout = ({ children, header, separator }) => {
  return (
    <div>
      {header && <h3 className={styles.header}>{header}</h3>}

      <div className={styles.container}>
        {React.Children.toArray(children).find(c => c.type === Top)}

        {separator && <div className={separator} />}

        {React.Children.toArray(children).find(c => c.type === Bottom)}
      </div>
    </div>
  )
}

DualChartVerticalLayout.Top = Top
DualChartVerticalLayout.Bottom = Bottom

DualChartVerticalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  separator: PropTypes.string,
}

Top.propTypes = {
  children: PropTypes.node.isRequired,
}

Bottom.propTypes = {
  children: PropTypes.node.isRequired,
}
