import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { ACT, ATS_JOB_SYNC_OPTIONS, DEL, PAU, REV } from "../../constants"
import {
  companySubscriptionSelector,
  isComSubscriptionPaused,
  isJobPph,
  isSubscriptionMain,
  jobSlotsLeftSelector,
} from "../../lib/helpers"
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap"
import { Input } from "../common/input-component"
import { JobActivationInPphModal } from "../modals/job-activation-in-pph-modal"
import { JobActivateSubscriptionPausedModal } from "../modals/subscription-paused-modal"
import { useOpenClose } from "../../hooks/use-open-close"
import { JobActivationModal } from "../modals/job-activation-modal"
import { useStore } from "../../lib/store"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api as moberriesApi, ApiKeys } from "../../lib/moberries-api"
import { useQueryClient } from "react-query"
import { atsApiV2, AtsApiV2Keys } from "../../lib/ats-api-v2"
import { useAxiosQuery } from "../../hooks/use-axios-query"

export const JobControls = ({ job, isLoading }) => {
  const queryClient = useQueryClient()
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)

  const { data: jobIntegrations } = useAxiosQuery(
    [
      AtsApiV2Keys.JobIntegrations,
      {
        moberriesJob: job.id,
      },
    ],
    atsApiV2.getJobIntegrations,
    { enabled: !!atsCompanyIntegration },
  )

  const jobIntegration = jobIntegrations?.[0]

  const excludeJobsMutation = useAxiosMutation(atsApiV2.excludeJobsFromSync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AtsApiV2Keys.JobIntegrations])
    },
  })

  const { mutateAsync: updateJob, isLoading: isUpdating } = useAxiosMutation(
    moberriesApi.updateJob,
    {
      onSuccess: async job => {
        const toExcludeFromSync = job.status === PAU

        if (
          jobIntegration &&
          jobIntegration?.isExcludedFromSync !== toExcludeFromSync
        ) {
          await excludeJobsMutation.mutate({
            moJobIds: [job.id],
            excludeFromSync: toExcludeFromSync,
          })
        }

        await queryClient.invalidateQueries([ApiKeys.Job, { id: job.id }])
      },
    },
  )
  const jobSlotsLeft = useStore(jobSlotsLeftSelector)
  const companySubscription = useStore(companySubscriptionSelector)
  const isAutoJobSyncEnabled =
    atsCompanyIntegration?.jobSyncType === ATS_JOB_SYNC_OPTIONS.AUTO
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("")

  const {
    value: isDeleteJobModalOpen,
    open: openDeleteJobModal,
    close: closeDeleteJobModal,
  } = useOpenClose({ initialValue: false })

  const {
    value: isActivateJobInPphModalOpen,
    open: openActivateJobInPphModal,
    close: closeActivateJobInPphModal,
  } = useOpenClose({ initialValue: false })
  const {
    value: isActivateJobModalOpen,
    open: openActivateJobModal,
    close: closeActivateJobModal,
  } = useOpenClose({ initialValue: false })

  const {
    value: isPausedSubscriptionJobModalOpen,
    open: openPausedSubscriptionJobModal,
    close: closePausedSubscriptionJobModal,
  } = useOpenClose({ initialValue: false })

  const isPausedAndAutoJobSyncEnabled =
    job.status === PAU && isAutoJobSyncEnabled
  return (
    <Fragment>
      <JobActivationInPphModal
        isOpen={isActivateJobInPphModalOpen}
        toggle={closeActivateJobInPphModal}
        jobId={job.id}
      />
      <JobActivationModal
        isOpen={isActivateJobModalOpen}
        toggle={closeActivateJobModal}
        companySubscription={companySubscription}
        jobId={job.id}
      />
      <JobActivateSubscriptionPausedModal
        isOpen={isPausedSubscriptionJobModalOpen}
        toggle={closePausedSubscriptionJobModal}
      />
      <Modal
        isOpen={isDeleteJobModalOpen}
        toggle={closeDeleteJobModal}
        centered
      >
        <ModalHeader toggle={closeDeleteJobModal}>
          <FormattedMessage id="app.common.confirm" />
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <FormattedMessage id="app.page.jobEdit.delete.confirmation.content" />
          </div>
          <Input
            input={{
              onChange: ({ target: { value } }) =>
                setDeleteConfirmationText(value),
              value: deleteConfirmationText,
            }}
            meta={{}}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            disabled={isUpdating}
            onClick={closeDeleteJobModal}
          >
            <FormattedMessage id="app.common.cancel" />
          </Button>
          <Button
            color="primary"
            disabled={isUpdating || deleteConfirmationText !== "DELETE"}
            loading={isUpdating}
            onClick={() => {
              updateJob({ id: job.id, job: { status: DEL } })
            }}
          >
            <FormattedMessage id="app.common.confirm" />
          </Button>
        </ModalFooter>
      </Modal>

      <div className="d-md-flex text-right card-controls">
        {job.status === ACT && (
          <Fragment>
            <Button
              disabled={isLoading || isUpdating || isAutoJobSyncEnabled}
              loading={isLoading}
              color="light"
              onClick={() => {
                updateJob({ id: job.id, job: { status: PAU } })
              }}
            >
              <div id="pause-job" className="d-inline-block">
                <i className="fas fa-pause-circle d-md-none" />
                <span className="d-none d-md-inline">
                  <FormattedMessage id="app.common.pause" />
                </span>
              </div>
            </Button>
            {isAutoJobSyncEnabled && (
              <UncontrolledPopover
                trigger="hover"
                target="pause-job"
                placement="bottom"
              >
                <PopoverBody>
                  <FormattedMessage id="app.page.jobEdit.integration.hint" />
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </Fragment>
        )}

        {(job.status === PAU || job.status === REV) && (
          <Fragment>
            <Button
              disabled={isUpdating || isPausedAndAutoJobSyncEnabled}
              loading={isUpdating}
              outline
              color="success"
              onClick={() => {
                if (isComSubscriptionPaused(companySubscription)) {
                  return openPausedSubscriptionJobModal()
                }

                if (
                  jobSlotsLeft <= 0 &&
                  isSubscriptionMain(companySubscription.subscription)
                ) {
                  return openActivateJobInPphModal()
                }

                if (
                  jobSlotsLeft > 0 &&
                  isJobPph(job) &&
                  isSubscriptionMain(companySubscription.subscription)
                ) {
                  return openActivateJobModal()
                }

                return updateJob({ id: job.id, job: { status: ACT } })
              }}
            >
              <div id="job-activation-button" className="d-inline-block">
                <i className="fas fa-play d-md-none" />
                <span className="d-none d-md-inline">
                  <FormattedMessage id="app.common.activate" />
                </span>
              </div>
            </Button>
          </Fragment>
        )}
        <Button
          disabled={isUpdating || isAutoJobSyncEnabled}
          loading={isUpdating}
          className="ml-2"
          outline
          color="danger"
          onClick={openDeleteJobModal}
        >
          <div id="delete-job">
            <i className="fas fa-times d-md-none" />
            <span className="d-none d-md-inline">
              <FormattedMessage id="app.common.delete" />
            </span>
          </div>
        </Button>
        {isAutoJobSyncEnabled && (
          <UncontrolledPopover
            trigger="hover"
            target="delete-job"
            placement="bottom"
          >
            <PopoverBody>
              <FormattedMessage id="app.page.jobEdit.integration.hint" />
            </PopoverBody>
          </UncontrolledPopover>
        )}
      </div>
    </Fragment>
  )
}

JobControls.propTypes = {
  job: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
}

JobControls.defaultProps = {
  isLoading: false,
}
