import PropTypes from "prop-types"
import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FormattedMessage } from "react-intl"
import { createReferralLink } from "../../lib/helpers"
import { Button } from "../common/button-component"
import { FormattedLink } from "../common/formatted-link-component"
import { Input } from "../common/input-component"
import { EmailTemplateModal } from "../modals/email-template-modal"
import { useOpenClose } from "../../hooks/use-open-close"
import { useTemporaryState } from "../../hooks/use-temporary-state"
import { StatisticsData } from "./statistics-data-component"
import { StatisticsDataRow } from "./statistics-data-row-component"
import { metricsGetters } from "../../lib/statistics-metrics"
import { Link } from "react-router-dom"

export function TalentPoolWidget({
  statistics,
  slug,
  subscriptionType,
  managerName,
  companyName,
  companyId,
}) {
  const refLink = createReferralLink(companyId)
  const {
    value: templateIsOpen,
    open: openTemplate,
    close: closeTemplate,
    toggle: toggleTemplate,
  } = useOpenClose({ initialValue: false })

  const [isCopied, setIsCopied] = useTemporaryState(false, 2000)

  return (
    <div className="bg-white p-3 rounded mb-3">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-users" />
        </div>
        <FormattedMessage id="app.widget.talentPool.title" />
      </h5>
      <StatisticsData>
        <Link to="/referrals">
          <StatisticsDataRow
            labelKey="app.common.total"
            {...metricsGetters.referralsTotal(statistics)}
          />
        </Link>
      </StatisticsData>
      <div className="pt-3">
        <p>
          <FormattedMessage
            id="app.widget.talentPool.text"
            values={{
              b: text => <b>{text}</b>,
              a: text => (
                <FormattedLink
                  className="align-baseline p-0 border-0 d-inline-block"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="talentPoolGuide"
                >
                  {text}
                </FormattedLink>
              ),
            }}
          />
        </p>
        <Input
          className="mb-3"
          type="text"
          label={
            <strong>
              <FormattedMessage id="app.widget.talentPool.url" />:
            </strong>
          }
          input={{ defaultValue: refLink }}
          meta={{}}
          readOnly
        />
      </div>
      <div className="d-flex justify-content-between">
        <CopyToClipboard text={refLink}>
          <Button
            color="secondary"
            size="sm"
            onClick={() => setIsCopied(true)}
            disabled={isCopied}
          >
            {isCopied ? (
              <FormattedMessage id="app.common.copied" />
            ) : (
              <FormattedMessage id="app.common.copy" />
            )}
          </Button>
        </CopyToClipboard>
        <Button color="secondary" size="sm" onClick={openTemplate}>
          <FormattedMessage id="app.widget.talentPool.email.template.title" />
        </Button>
      </div>
      <EmailTemplateModal
        isOpen={templateIsOpen}
        close={closeTemplate}
        toggle={toggleTemplate}
        inviteUrl={refLink}
        managerName={managerName}
        companyName={companyName}
      />
    </div>
  )
}

TalentPoolWidget.propTypes = {
  statistics: PropTypes.object,
  slug: PropTypes.string.isRequired,
  subscriptionType: PropTypes.string.isRequired,
  managerName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
}
