import storage from "redux-persist/lib/storage"
const NODE_ENV = process.env.NODE_ENV // test, development, production
const isTest = NODE_ENV === "test"
const isDev = NODE_ENV === "development"
const isProd = NODE_ENV === "production"

const BRANCH = process.env.REACT_APP_BRANCH || "unknown" // master, release, demo
const isMaster = BRANCH === "master"
const isRelease = BRANCH === "release"
const isDemo = BRANCH === "demo"
const isStaging = BRANCH === "staging"
const isDemo1 = BRANCH === "demo1"

const version = process.env.REACT_APP_VERSION || "unknown"
const packageName = process.env.REACT_APP_PACKAGE_NAME

const buildYear =
  parseInt(process.env.REACT_APP_BUILD_YEAR) || new Date().getFullYear()

let moberriesApiUrl = "https://api.dev.moberries.com"
let moberriesBotUrl = "https://bot.dev.moberries.com"
let moberriesWsUrl = "https://ws.dev.moberries.com"
let moberriesAtsUrl = "https://ats.dev.moberries.com"
let slackClientId = "74781962032.341811318502"
let stripeApiKey = "pk_test_ZMiPPakXpVSmjKbxYCgbVaX8"
let typeformApiUrl = "https://proxy.dev.moberries.com/typeform"
let moberriesPdfUrl = "https://pdf.dev.moberries.com"

let moberriesCandidateAppUrl = "https://app.dev.moberries.com"
let moberriesAffiliateAppUrl = "https://affiliate.dev.moberries.com"
let moberriesHomePageUrl = "https://dev.moberries.com"

let slackOauthURL = "https://slack.com/oauth/v2/authorize"

const host = window.location.host || window.location.hostname || "unknown"

if (isStaging) {
  moberriesApiUrl = "https://api.staging.moberries.com"
  moberriesWsUrl = "https://ws.staging.moberries.com"
  moberriesBotUrl = "https://bot.staging.moberries.com"
  moberriesAtsUrl = "https://ats.staging.moberries.com"
  moberriesCandidateAppUrl = "https://app.staging.moberries.com"
  moberriesAffiliateAppUrl = "https://affiliate.staging.moberries.com"
  moberriesHomePageUrl = "https://staging.moberries.com"
  slackClientId = "74781962032.359916478292"
  stripeApiKey =
    "pk_test_51KDnIcIMluviEKif4hYgqm8vapxBPv4MJNmVwEmgg2GHvhkZZnun9mQZrI5yoymHMBqXtV8CrwP20JX4hX6SbhUx00fRbnLSB9" // updated
  typeformApiUrl = "https://proxy.staging.moberries.com/typeform"
  moberriesPdfUrl = "https://pdf.staging.moberries.com"
}

if (isDemo1) {
  moberriesApiUrl = "https://api.demo1.moberries.com"
  moberriesWsUrl = "https://ws.demo1.moberries.com"
  moberriesBotUrl = "https://bot.demo1.moberries.com"
  moberriesAtsUrl = "https://ats.demo1.moberries.com"
  moberriesCandidateAppUrl = "https://app.demo1.moberries.com"
  moberriesAffiliateAppUrl = "https://affiliate.demo1.moberries.com"
  moberriesHomePageUrl = "https://demo1.moberries.com"
  slackClientId = "74781962032.359916478292"
  stripeApiKey =
    "pk_test_51KOiXQEauuHYUNFQOL0bJFWztSCIjGulk1AIxIppu2UzsKoe1xcIChXHDAnhvQeVm27KPNKRVqJPs5dS5Gfmb99v00N4Oo0nr7" // updated
  typeformApiUrl = "https://proxy.demo1.moberries.com/typeform"
  moberriesPdfUrl = "https://pdf.demo1.moberries.com"
}

if (isRelease) {
  moberriesApiUrl = "https://api.moberries.com"
  moberriesWsUrl = "https://ws.moberries.com"
  moberriesBotUrl = "https://bot.moberries.com"
  moberriesAtsUrl = "https://ats.moberries.com"
  moberriesCandidateAppUrl = "https://app.moberries.com"
  moberriesAffiliateAppUrl = "https://affiliate.moberries.com"
  moberriesHomePageUrl = "https://www.moberries.com"
  slackClientId = "74781962032.359916478292"
  stripeApiKey = "pk_live_XsCOLwkbETKBMInIPd3k1guN"
  typeformApiUrl = "https://proxy.moberries.com/typeform"
  moberriesPdfUrl = "https://pdf.moberries.com"
}

if (process.env.REACT_APP_MOBERRIES_API_URL) {
  moberriesApiUrl = process.env.REACT_APP_MOBERRIES_API_URL
}

if (process.env.REACT_APP_MOBERRIES_BOT_URL) {
  moberriesBotUrl = process.env.REACT_APP_MOBERRIES_BOT_URL
}

if (process.env.REACT_APP_MOBERRIES_WS_URL) {
  moberriesWsUrl = process.env.REACT_APP_MOBERRIES_WS_URL
}

if (process.env.REACT_APP_MOBERRIES_ATS_URL) {
  moberriesAtsUrl = process.env.REACT_APP_MOBERRIES_ATS_URL
}

if (process.env.REACT_APP_SLACK_CLIENT_ID) {
  slackClientId = process.env.REACT_APP_SLACK_CLIENT_ID
}

if (process.env.REACT_APP_TYPEFORM_API_URL) {
  typeformApiUrl = process.env.REACT_APP_TYPEFORM_API_URL
}

if (process.env.REACT_APP_MOBERRIES_PDF_URL) {
  moberriesPdfUrl = process.env.REACT_APP_MOBERRIES_PDF_URL
}

const baseUrl =
  window.location.protocol + "//" + window.location.host ||
  window.location.hostname

export const config = {
  package: { version, name: packageName },

  baseUrl,

  env: { isTest, isDev, isProd },

  branch: { isMaster, isRelease, isDemo },

  buildYear,

  persist: {
    root: {
      key: "root",
      storage,
      version: 1,
      whitelist: [],
    },
    system: {
      key: "system",
      storage,
      version: 1,
      whitelist: ["release"],
    },
    survey: {
      key: "survey",
      storage,
      version: 1,
      whitelist: ["closingTimestamp"],
    },
    atsCompanyIntegration: {
      key: "atsCompanyIntegration",
      storage,
      version: 1,
    },
  },

  cookie: {
    locale: "user_lang",
    utmSource: "utm_source",
    utmMedium: "utm_medium",
    utmCampaign: "utm_campaign",
    utmTerm: "utm_term",
    utmContent: "utm_content",
  },

  moberries: {
    api: {
      url: moberriesApiUrl,
    },
    bot: {
      url: moberriesBotUrl,
    },
    ws: {
      url: moberriesWsUrl,
      options: {
        path: "/ws",
      },
    },
    ats: {
      url: moberriesAtsUrl,
    },
    candidate: {
      url: moberriesCandidateAppUrl,
    },
    affiliate: {
      url: moberriesAffiliateAppUrl,
    },
    homepage: {
      url: moberriesHomePageUrl,
    },
    pdf: {
      url: moberriesPdfUrl,
    },
  },
  typeform: {
    api: {
      url: typeformApiUrl,
    },
    codes: { en: "cMcfBp", de: "cAsjam" },
  },
  oauth: {
    redirectUrl: `${window.location.protocol}//${host}/auth/callback`,
  },

  slack: {
    oauthUrl: slackOauthURL,
    id: slackClientId,
    scope: ["incoming-webhook"],
  },

  profilePicture: {
    limit: 1000000,
    acceptedTypes: "image/*",
  },

  google: {
    api: {
      places: {
        key: "AIzaSyDeZ5L1C1WEpUgsvQbibYEqAHJ7d3NHbwM",
      },
    },
    recaptcha: {
      key: "6LezVpsfAAAAADRupErqRt6AfOjMmYVnxw1nmLmp",
    },
  },

  logrocket: {
    url: "xeb6fu/company-app",
    options: {
      release: version,
      shouldCaptureIP: false,
    },
  },

  stripe: {
    apiKey: stripeApiKey,
    libUrl: "https://js.stripe.com/v3/",
    allowedCards: ["visa", "mastercard", "amex"],
  },

  hotjar: {
    hjsv: 6,
    hjid: 2975484,
  },
}
