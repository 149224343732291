import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { PaymentsPageLayout } from "../components/layouts/payments-page-layout"
import { getCompanyInvoiceList } from "../actions/invoice-actions"
import { ErrorPage } from "./error-page"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { InvoiceList } from "../components/invoices/invoice-list-component"
import { TabPane } from "reactstrap"
import {
  invoicesSelector,
  loadingSelector,
  countSelector,
  errorSelector,
} from "../selectors/invoice-selector"

export const CompanyInvoicesPageComponent = ({
  invoices,
  count,
  err,
  getCompanyInvoiceList,
}) => {
  useEffect(() => {
    getCompanyInvoiceList()
  }, [getCompanyInvoiceList])
  if (err) {
    return <ErrorPage err={err} />
  }
  return (
    <PaymentsPageLayout>
      <div className="bg-white px-3 py-6">
        <SmallInnerFormLayout>
          <TabPane>
            <InvoiceList invoices={invoices} count={count} />
          </TabPane>
        </SmallInnerFormLayout>
      </div>
    </PaymentsPageLayout>
  )
}

CompanyInvoicesPageComponent.propTypes = {
  invoices: PropTypes.array.isRequired,
  count: PropTypes.number,
  err: PropTypes.object,
  getCompanyInvoiceList: PropTypes.func.isRequired,
}
CompanyInvoicesPageComponent.defaultProps = {
  count: null,
  invoices: [],
}

const mapDispatchToProps = { getCompanyInvoiceList }

const mapStateToProps = function (state) {
  return {
    err: errorSelector(state),
    count: countSelector(state),
    invoices: invoicesSelector(state),
    loading: loadingSelector(state),
  }
}

export const CompanyInvoicesPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyInvoicesPageComponent)
