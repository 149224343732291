import { startSubmit, stopSubmit } from "redux-form"
import {
  UPDATE,
  COMPANY,
  GET,
  CANCEL,
  RENEW,
  ORDER,
  SUCCESS,
  FAIL,
  START,
  BILLING,
  SUBSCRIPTION,
  PROBLEMS,
  CREATE,
  CARD,
  REWARDS,
  TIME,
  SERIES,
  CANDIDATES,
  DURATIONS,
} from "../constants"
import {
  getActionType,
  isFormValidation,
  isStatus,
  isComSubscriptionOpenPaid,
  companySubscriptionSelector,
} from "../lib/helpers"
import { validateForm } from "./form-actions"
import { moErrorTypes, customErrors } from "../lib/server-errors"
import { defaultCardSelector } from "../selectors/payment-card-selector"
import { push } from "connected-react-router"
import { alertTypes, showAlert, showErrorAlert } from "../lib/alerts"
import { store } from "../lib/store"
import { refreshCompany } from "../controllers/company-controller"

export function updateCompanyBilling({ billing }) {
  return async function (dispatch, getState, { moApi }) {
    const formName = "CompanyBillingForm"
    dispatch({ type: getActionType(UPDATE, COMPANY, BILLING, START) })
    dispatch(startSubmit(formName))

    try {
      const { data: company } = await moApi.updateCompany({
        company: { companyBilling: billing },
      })

      dispatch(stopSubmit(formName))

      dispatch({
        type: getActionType(UPDATE, COMPANY, BILLING, SUCCESS),
        payload: { company },
      })
    } catch (err) {
      if (isFormValidation(err)) {
        dispatch(validateForm({ formName, err }))
      } else {
        showErrorAlert({ err })
        dispatch(stopSubmit(formName))
      }

      dispatch({
        type: getActionType(UPDATE, COMPANY, BILLING, FAIL),
        payload: { err },
      })
    }
  }
}

export function requestCompany({ id }) {
  return async function (dispatch, getState, { moApi }) {
    try {
      const { data: company } = await moApi.getCompany({ id })

      dispatch({
        type: getActionType(GET, COMPANY, SUCCESS),
        payload: { company },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({ type: getActionType(GET, COMPANY, FAIL), payload: { err } })
    }
  }
}

export function cancelCompanySubscription({
  companyId,
  subscriptionId,
  answers,
  comment,
}) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(CANCEL, COMPANY, SUBSCRIPTION, START) })

    try {
      const { data: feedback } = await moApi.createFeedback({
        answers,
        comment,
      })

      await moApi.linkFeedbackWithSubscription({
        companyId,
        subscriptionId,
        feedbackId: feedback.id,
      })

      await moApi.updateCompanySubscription({
        event: "cancel",
        companyId,
        subscriptionId,
      })

      refreshCompany()
      dispatch({
        type: getActionType(CANCEL, COMPANY, SUBSCRIPTION, SUCCESS),
      })
      showAlert({ code: alertTypes.subscriptionCancelSuccess })
    } catch (err) {
      if (isStatus(400, err)) {
        const cancelSubscriptionError = new Error("Cancel subscription failed")
        cancelSubscriptionError.problem = PROBLEMS.CLIENT_ERROR
        cancelSubscriptionError.code = moErrorTypes.cancelSubscriptionFailed
        showErrorAlert({ err: cancelSubscriptionError })
      } else {
        showErrorAlert({ err })
      }

      dispatch({
        type: getActionType(CANCEL, COMPANY, SUBSCRIPTION, FAIL),
        payload: { err },
      })
    }
  }
}

export function renewCompanySubscription({ companyId, subscriptionId }) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({ type: getActionType(RENEW, COMPANY, SUBSCRIPTION, START) })

    try {
      await moApi.updateCompanySubscription({
        event: "renew",
        companyId,
        subscriptionId,
      })

      refreshCompany()

      dispatch({
        type: getActionType(RENEW, COMPANY, SUBSCRIPTION, SUCCESS),
      })
      showAlert({ code: alertTypes.subscriptionRenewSuccess })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(RENEW, COMPANY, SUBSCRIPTION, FAIL),
        payload: { err },
      })
    }
  }
}

export function orderCompanySubscriptionWithInvoice({
  subscriptionId,
  coupon,
  redirectTo,
}) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({
      type: getActionType(ORDER, COMPANY, SUBSCRIPTION, START),
    })

    try {
      await moApi.orderCompanySubscription({
        subscription: subscriptionId,
        mainStripeCouponId: coupon?.stripeCouponId,
      })

      refreshCompany()
      showAlert({ code: alertTypes.subscriptionUpgradeSuccess })
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, SUCCESS),
      })
      if (redirectTo) {
        dispatch(push(redirectTo))
      }
    } catch (err) {
      showErrorAlert({ err })
      refreshCompany()
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, FAIL),
        payload: { err },
      })
    }
  }
}

export function orderCompanySubscriptionWithDefaultCard({
  subscriptionId,
  coupon,
  stripe,
  redirectTo,
}) {
  return async function (dispatch, getState, { moApi, actions }) {
    dispatch({
      type: getActionType(ORDER, COMPANY, SUBSCRIPTION, START),
    })
    try {
      const defaultCard = defaultCardSelector(getState())

      const { data: companySubscription } =
        await moApi.orderCompanySubscription({
          subscription: subscriptionId,
          mainStripeCouponId: coupon?.stripeCouponId,
        })

      if (!isComSubscriptionOpenPaid(companySubscription)) {
        const { error } = await stripe.handleCardPayment(
          companySubscription.clientSecret,
          {
            payment_method: defaultCard.id,
          },
        )
        if (error) {
          await refreshCompany()
          throw new customErrors.CardError()
        }
      }
      showAlert({ code: alertTypes.subscriptionUpgradeSuccess })
      await refreshCompany()
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, SUCCESS),
      })
      if (redirectTo) {
        dispatch(push(redirectTo))
      }
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, FAIL),
        payload: { err },
      })
    }
  }
}

export function orderCompanySubscriptionWithNewCard({
  elements,
  coupon,
  stripe,
  subscriptionId,
  holderName,
  redirectTo,
}) {
  return async function (dispatch, getState, { moApi, actions }) {
    const formName = "PaymentCardForm"
    dispatch({
      type: getActionType(ORDER, COMPANY, SUBSCRIPTION, START),
    })
    dispatch(startSubmit(formName))
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
        billing_details: {
          name: holderName,
        },
      })
      if (error) {
        throw new customErrors.CardError()
      }
      const { data: defaultCard } = await moApi.setDefaultPaymentMethod({
        paymentMethodId: paymentMethod.id,
      })
      dispatch({
        type: getActionType(CREATE, CARD, SUCCESS),
        payload: { card: defaultCard },
      })

      const { data: companySubscription } =
        await moApi.orderCompanySubscription({
          subscription: subscriptionId,
          mainStripeCouponId: coupon?.stripeCouponId,
        })

      if (!isComSubscriptionOpenPaid(companySubscription)) {
        const { error } = await stripe.handleCardPayment(
          companySubscription.clientSecret,
          {
            payment_method: paymentMethod.id,
          },
        )
        if (error) {
          await refreshCompany()

          throw new customErrors.CardError()
        }
      }

      await refreshCompany()
      showAlert({ code: alertTypes.subscriptionUpgradeSuccess })
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, SUCCESS),
      })
      dispatch(stopSubmit(formName))
      if (redirectTo) {
        dispatch(push(redirectTo))
      }
    } catch (err) {
      dispatch(stopSubmit(formName))
      showErrorAlert({ err, options: { autoClose: DURATIONS.MEDIUM } })
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, FAIL),
        payload: { err },
      })
    }
  }
}

export function retrySubscriptionOrder({
  stripe,
  elements,
  holderName,
  redirectTo,
}) {
  return async function (dispatch, getState, { moApi, actions }) {
    const formName = "PaymentCardForm"
    dispatch({
      type: getActionType(ORDER, COMPANY, SUBSCRIPTION, START),
    })
    dispatch(startSubmit(formName))
    const companySubscriptionId = companySubscriptionSelector(
      store.getState(),
    ).id

    try {
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement("card"),
          billing_details: {
            name: holderName,
          },
        })

      if (paymentMethodError) {
        throw new customErrors.CardError()
      }

      const { data: defaultCard } = await moApi.setDefaultPaymentMethod({
        paymentMethodId: paymentMethod.id,
      })

      dispatch({
        type: getActionType(CREATE, CARD, SUCCESS),
        payload: { card: defaultCard },
      })

      const { data: companySubscription } = await moApi.getCompanySubscription({
        id: companySubscriptionId,
      })

      const { error: paymentError } = await stripe.handleCardPayment(
        companySubscription.clientSecret,
        {
          payment_method: paymentMethod.id,
        },
      )

      if (paymentError) {
        throw new customErrors.CardError()
      }

      showAlert({ code: alertTypes.subscriptionUpgradeSuccess })
      dispatch(stopSubmit(formName))
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, SUCCESS),
      })
      if (redirectTo) {
        dispatch(push(redirectTo))
      }
    } catch (err) {
      dispatch({
        type: getActionType(ORDER, COMPANY, SUBSCRIPTION, FAIL),
        payload: { err },
      })
      dispatch(stopSubmit(formName))
      showErrorAlert({ err })
    }
  }
}

export function getCompanyRewardsTimeSeries({
  params = {},
  dataKey,
  rangeId,
} = {}) {
  return async (dispatch, getState, { moApi }) => {
    dispatch({
      type: getActionType(GET, COMPANY, REWARDS, TIME, SERIES, START),
      payload: { dataKey },
    })
    try {
      const { data } = await moApi.getCompanyRewardsTimeSeries({
        params,
      })

      dispatch({
        type: getActionType(GET, COMPANY, REWARDS, TIME, SERIES, SUCCESS),
        payload: { companyRewardsTimeSeries: data, rangeId, dataKey },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, COMPANY, REWARDS, TIME, SERIES, FAIL),
        payload: { err, dataKey },
      })
    }
  }
}

export function getCompanyRewardsCandidates({ params = {} } = {}) {
  return async (dispatch, getState, { moApi }) => {
    dispatch({
      type: getActionType(GET, COMPANY, REWARDS, CANDIDATES, START),
    })
    try {
      const { data } = await moApi.getCompanyRewardsCandidates({
        params,
      })

      dispatch({
        type: getActionType(GET, COMPANY, REWARDS, CANDIDATES, SUCCESS),
        payload: {
          companyRewardsCandidates: data,
        },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, COMPANY, REWARDS, CANDIDATES, FAIL),
        payload: { err },
      })
    }
  }
}
