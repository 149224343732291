import React from "react"
import { FormattedMessage } from "react-intl"
import { VerticalBarChartLoader } from "../../../loaders/chart-loaders/bar-chart-loader"
import styles from "./feedback-chart-skeleton.module.scss"

export const FeedbackInsightsChartSkeleton = () => {
  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="app.page.analytics.charts.feedback.name" />
      </div>
      <div className={styles.loader}>
        <VerticalBarChartLoader />
      </div>
    </div>
  )
}
