import React from "react"
import PropTypes from "prop-types"
import seapig, { REQUIRED } from "seapig"

export const ActionControlsLayout = ({ children }) => {
  const { leftChildren, rightChildren } = seapig(children, {
    left: REQUIRED,
    right: REQUIRED,
  })

  return (
    <div className="d-flex bg-light border border-left-0 border-right-0">
      <div className="col py-2 border-right">{leftChildren}</div>
      <div className="col py-2">{rightChildren}</div>
    </div>
  )
}

ActionControlsLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

ActionControlsLayout.defaultProps = {}
