import React from "react"
import { InvoicesSkeleton } from "../skeletons/invoices-skeleton"
import { InvoiceListEmpty } from "../empty-states/invoice-list-empty"
import { Invoice } from "./invoice-component"
import PropTypes from "prop-types"
import { ListGroup } from "reactstrap"

export const InvoiceList = ({ invoices, count }) => {
  if (count === null) {
    return <InvoicesSkeleton />
  }

  if (count === 0) {
    return <InvoiceListEmpty />
  }
  return (
    <ListGroup>
      {invoices.map(i => (
        <Invoice key={i.id} invoice={i} />
      ))}
    </ListGroup>
  )
}

InvoiceList.propTypes = {
  invoices: PropTypes.array.isRequired,
  count: PropTypes.number,
}

InvoiceList.defaultProps = {
  invoices: [],
}
