import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { useDebouncedCallback } from "use-debounce"
import { FormattedMessage } from "react-intl"
import ReactSelect from "react-select"
import AsyncSelect from "react-select/async"
import { getSelectCustomStyles } from "../../lib/custom-styles"

export const Select = ({
  async,
  className,
  components,
  loadOptions,
  ...rest
}) => {
  const debouncedLoadOptions = useDebouncedCallback((input, cb) => {
    loadOptions(input).then(cb)
  }, 600)
  const Component = async ? AsyncSelect : ReactSelect
  return (
    <Component
      noOptionsMessage={() => (
        <FormattedMessage id="app.common.type.to.search" />
      )}
      className={cn("Select", className)}
      classNamePrefix="Select"
      components={{
        IndicatorSeparator: null,
        ...components,
      }}
      backspaceRemovesValue={false}
      loadOptions={debouncedLoadOptions}
      {...rest}
      styles={getSelectCustomStyles(rest)}
    />
  )
}

Select.propTypes = {
  async: PropTypes.bool,
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  loadOptions: PropTypes.func,
}

Select.defaultProps = {
  async: false,
  className: null,
  components: {},
  loadOptions: null,
}
