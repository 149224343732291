import React, { Fragment } from "react"
import { DoubleColumnLayout } from "../layouts/double-column-layout"
import { FormattedMessage } from "react-intl"
import { CardLoader } from "../loaders/card-loader"
import { List } from "../loaders/list-loader"
import { times } from "ramda"
import { Container } from "reactstrap"

export function JobListPageSkeleton() {
  return (
    <Fragment>
      <Container>
        <h1>
          <FormattedMessage id="app.page.jobList.header" />
        </h1>
        <p className="text-muted">
          <FormattedMessage id="app.page.jobList.subheader" />
        </p>
      </Container>
      <DoubleColumnLayout>
        <DoubleColumnLayout.Content>
          <Fragment>
            {times(
              i => (
                <div key={i} className="bg-white p-3 mb-2">
                  <CardLoader />
                </div>
              ),
              4,
            )}
          </Fragment>
        </DoubleColumnLayout.Content>
        <DoubleColumnLayout.Sidebar>
          <div className="bg-white p-3">
            {times(
              i => (
                <List key={i} />
              ),
              4,
            )}
          </div>
        </DoubleColumnLayout.Sidebar>
      </DoubleColumnLayout>
    </Fragment>
  )
}
