import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { WebsiteIntegrationWidget } from "./website-integration-widget"
import { JobDetailsStatsWidget } from "./job-details-stats-widget"

export function JobDetailsSidebar({ jobId, statistics }) {
  return (
    <Fragment>
      <JobDetailsStatsWidget stats={statistics} />
      <WebsiteIntegrationWidget jobId={jobId} />
    </Fragment>
  )
}

JobDetailsSidebar.propTypes = {
  jobId: PropTypes.number.isRequired,
  statistics: PropTypes.object.isRequired,
}
