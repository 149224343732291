import React from "react"
import { Button } from "../common/button-component"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

export const SubmitButtonField = ({ children, ...rest }) => {
  const { isSubmitting } = useFormikContext()

  return (
    <Button
      color="success"
      type="submit"
      disabled={isSubmitting}
      loading={isSubmitting}
      {...rest}
    >
      {children}
    </Button>
  )
}

SubmitButtonField.propTypes = {
  children: PropTypes.node.isRequired,
}
