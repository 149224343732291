import React from "react"
import PropTypes from "prop-types"
import { prop } from "ramda"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import { Button } from "../common/button-component"
import { SelectField } from "../fields/select-field"
import { useAtsJobsLoader } from "../../hooks/ats/use-ats-jobs-loader"
import { atsApi, AtsApiKeys } from "../../lib/ats-api"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"

import { SubmitButtonField } from "../fields/submit-button-field"
import { useQueryClient } from "react-query"
import { useStore } from "../../lib/store"

export const AtsMergeJobModal = ({ job, onClose }) => {
  const queryClient = useQueryClient()

  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)

  const { loadJobs } = useAtsJobsLoader({
    atsCompanyIntegrationId: prop("id", atsCompanyIntegration),
    excludeImported: true,
    moberriesJobId: job?.id,
  })

  const { mutateAsync: createAtsJobIntegration } = useAxiosMutation(
    atsApi.createAtsJobIntegration,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(AtsApiKeys.UnintegratedMoJobs)
        onClose()
      },
    },
  )

  const onSubmit = async ({ providerJob }, formikActions) => {
    await createAtsJobIntegration({
      moberriesJobId: job?.id,
      providerJobId: providerJob.id,
    })

    formikActions.resetForm()
  }

  return (
    <Formik initialValues={{ providerJob: null }} onSubmit={onSubmit}>
      <Modal isOpen={Boolean(job?.id)} toggle={onClose} size="lg" centered>
        <ModalHeader toggle={onClose}>
          <FormattedMessage id="app.page.integrations.merge.modal.title" />
        </ModalHeader>
        <ModalBody>
          <h5>{prop("title", job)}</h5>
          <p>
            <FormattedMessage id="app.page.integrations.merge.modal.description" />
          </p>
          <SelectField
            name="providerJob"
            id="ats-merge-job"
            async
            loadOptions={loadJobs}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.title}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={e => {
              onClose(e)
            }}
          >
            <FormattedMessage id="app.common.cancel" />
          </Button>
          <Form>
            <SubmitButtonField>
              <FormattedMessage id="app.common.confirm" />
            </SubmitButtonField>
          </Form>
        </ModalFooter>
      </Modal>
    </Formik>
  )
}

AtsMergeJobModal.propTypes = {
  job: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}
