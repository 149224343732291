import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { StatisticsData } from "./statistics-data-component"
import { StatisticsDataRow } from "./statistics-data-row-component"
import { metricsGetters } from "../../lib/statistics-metrics"

export const JobDetailsStatsWidget = ({ stats }) => (
  <div className="bg-white p-3 rounded mb-3">
    <h5>
      <div className="icon-circled mr-2">
        <i className="fas fa-chart-bar" />
      </div>
      <FormattedMessage id="app.common.statistics" />
    </h5>

    <StatisticsData>
      {[
        "applicationsReceived",
        "applicationsDeclined",
        "applicationsExpired",
        "candidatesInReview",
        "candidatesHired",
        "candidatesRejected",
      ].map(metricType => (
        <StatisticsDataRow
          key={metricType}
          labelKey={`app.widget.statistics.${metricType}`}
          {...metricsGetters[metricType](stats)}
        />
      ))}
    </StatisticsData>
  </div>
)

JobDetailsStatsWidget.propTypes = {
  stats: PropTypes.object.isRequired,
}
