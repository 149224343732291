import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import TextareaAutosize from "react-textarea-autosize"
import { FormFeedback, FormGroup, Input, Label, PopoverBody } from "reactstrap"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"
import { InfoHint } from "../common/info-hint-component"

export const LabeledTextareaField = ({
  label,
  hint,
  id,
  isRequired,
  className,
  ...rest
}) => {
  const [field, meta] = useFormikField(rest)
  return (
    <FormGroup>
      <div>
        <Label for={id}>
          {label}
          {isRequired && " *"}
        </Label>
        {hint && (
          <InfoHint
            id={id}
            color="primary"
            popover={<PopoverBody>{hint}</PopoverBody>}
          />
        )}
      </div>
      <TextareaAutosize
        className={cn("form-control", className, {
          "border-danger": meta.touched && Boolean(meta.error),
        })}
        {...field}
        {...rest}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

LabeledTextareaField.propTypes = {
  label: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  hint: PropTypes.node,
}

LabeledTextareaField.defaultProps = {
  isRequired: false,
  className: null,
  hint: null,
}
