import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Media } from "reactstrap"
import { Img } from "../common/img-component"

export const AtsIntegrationProviderCard = ({
  provider = {},
  className,
  isConnected,
  tag,
  controls,
  ...rest
}) => {
  const { name, logoUrl } = provider

  return (
    <Media
      tag={tag}
      className={classNames(
        "text-body align-items-stretch rounded overflow-hidden",
        { "bg-white": !isConnected, "bg-success-100": isConnected },
        className,
      )}
      {...rest}
    >
      <Media
        left
        className="d-flex align-items-center bg-white border border-light rounded-left p-2"
      >
        <Img width="52" height="52" src={logoUrl} />
      </Media>
      <Media className="align-items-center flex-grow-1 text-truncate">
        <h4 className="pl-3 mb-0 text-truncate">{name}</h4>
        {isConnected && <i className="fas fa-plug text-success h4 mb-0 mx-1" />}
      </Media>
      <Media right className="align-items-center d-flex pr-3">
        {controls}
      </Media>
    </Media>
  )
}

AtsIntegrationProviderCard.propTypes = {
  provider: PropTypes.object.isRequired,
  className: PropTypes.string,
  isConnected: PropTypes.bool,
  tag: PropTypes.elementType,
  controls: PropTypes.node,
}

AtsIntegrationProviderCard.defaultProps = {
  isConnected: false,
  tag: Media,
  controls: <i className="fas fa-chevron-right text-muted" />,
}
