import React from "react"
import PropTypes from "prop-types"
import { APPLICATION_QUESTION_TYPES } from "../../constants"
import { MatchApplicationListAnswer } from "./match-application-list-answer"
import { MatchApplicationBooleanAnswer } from "./match-application-boolean-answer"
import { MatchApplicationTextAnswer } from "./match-application-text-answer"

const MAP_ANSWER_TYPE_TO_COMPONENT = {
  [APPLICATION_QUESTION_TYPES.BOOLEAN]: MatchApplicationBooleanAnswer,
  [APPLICATION_QUESTION_TYPES.MULTIPLE_SELECTION]: MatchApplicationListAnswer,
}

export const MatchApplicationAnswer = ({ applicationAnswer }) => {
  const Component =
    MAP_ANSWER_TYPE_TO_COMPONENT[applicationAnswer.question.questionType] ||
    MatchApplicationTextAnswer
  return (
    <div className="bg-white">
      <div className="border border-primary border-top-0 border-right-0 border-bottom-0 border-4 bg-light p-2">
        <span className="font-weight-bold">
          {applicationAnswer.question.title}
        </span>
      </div>
      <div className="border border-secondary-light border-top-0 border-right-0 border-bottom-0 border-4 p-2">
        <Component applicationAnswer={applicationAnswer} />
      </div>
    </div>
  )
}

MatchApplicationAnswer.propTypes = {
  applicationAnswer: PropTypes.object.isRequired,
}
