import { Formik, Form } from "formik"
import { ModalBody, ModalFooter, Row, Col } from "reactstrap"
import PropTypes from "prop-types"
import { messageTemplateSchema } from "../../schemas/message-template-schemas"
import { FormattedMessage, useIntl } from "react-intl"
import { LabeledInputField } from "../fields/input-field"
import { Button } from "../common/button-component"
import { MessageTemplateEditor } from "./message-template-editor"

export const MessageTemplateForm = ({
  initialValues,
  handleSubmit,
  onCancel,
}) => {
  const intl = useIntl()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={messageTemplateSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <ModalBody>
          <div className="mb-4">
            <Row>
              <Col xs="8">
                <LabeledInputField
                  label={
                    <FormattedMessage id="app.modal.messageTemplate.input.name.label" />
                  }
                  name="name"
                  id="name"
                  placeholder={intl.formatMessage({
                    id: "app.modal.messageTemplate.input.name.placeholder",
                  })}
                  isRequired
                />
              </Col>
            </Row>

            <MessageTemplateEditor name="text" />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={onCancel}>
            <FormattedMessage id="app.common.cancel" />
          </Button>
          <Button color="primary" type="submit">
            <FormattedMessage id="app.common.save" />
          </Button>
        </ModalFooter>
      </Form>
    </Formik>
  )
}

MessageTemplateForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
