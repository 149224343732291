import React from "react"
import { Link } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { DashboardWidgetLayout } from "../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import { HorizontalBars } from "../../charts/lib/horizontal-bars"
import { useStore } from "../../../lib/store"
import { mapper } from "../../charts/matches-chart/helpers"
import { ChartLegend } from "../../charts/chart-legend/chart-legend"
import { MatchingWidgetEmpty } from "./matching-widget-empty/matching-widget-empty"
import styles from "./matching-widget.module.scss"

export const MatchingWidget = () => {
  const { formatMessage } = useIntl()
  const statistics = useStore(({ company }) => company.statistics)
  const dataAndLabels = mapper([statistics], formatMessage)

  const isEmpty = dataAndLabels.data[0].reduce((ac, v) => ac + v, 0) === 0

  if (isEmpty) {
    return <MatchingWidgetEmpty />
  }

  return (
    <DashboardWidgetLayout
      title={<FormattedMessage id="app.page.analytics.charts.matching.name" />}
    >
      <div className={styles.head}>
        <div className={styles.legends}>
          <ChartLegend
            title="app.page.analytics.charts.matching.overall"
            color="#0738DA"
          />
        </div>
      </div>
      <HorizontalBars {...dataAndLabels} />
      <div className={styles.bottom}>
        <Link to="/analytics">
          <FormattedMessage id="app.page.analytics.charts.matching.seeMore" />
        </Link>
      </div>
    </DashboardWidgetLayout>
  )
}
