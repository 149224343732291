import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { path } from "ramda"
import { Button } from "../common/button-component"
import { PopoverBody, Media, UncontrolledPopover } from "reactstrap"
import {
  getFullName,
  getLocationName,
  shouldRenderApproveRejectControls,
  formatDate,
  shouldRenderMatched,
} from "../../lib/helpers"
import { ImageScore } from "../common/image-score-component"
import { ConfirmApproveModal } from "../modals/confirm-approve-modal"
import { useOpenClose } from "../../hooks/use-open-close"
import { FormattedMessage } from "react-intl"
import { Matched } from "../common/matched-component"
import { Link } from "react-router-dom"

export const MatchCard = ({ page, match, disabled, onApprove, onReject }) => {
  const name = getFullName(match.candidate)
  const city = path(["candidate", "city"], match)

  const lastMessageAt = path(["thread", "lastMessageAt"], match)
  const threadId = path(["thread", "id"], match)

  const {
    open: showApproveModal,
    close: closeApproveModal,
    value: isApproveModalOpen,
  } = useOpenClose({ initialValue: false })

  return (
    <Fragment>
      <ConfirmApproveModal
        cancel={closeApproveModal}
        confirm={async () => {
          await onApprove()
          closeApproveModal()
        }}
        isOpen={isApproveModalOpen}
        loading={disabled}
      />
      <Media className="bg-white p-3 rounded">
        <Media left className="mr-3">
          <Link
            to={{
              pathname: `/match/${match.id}`,
              state: { fromPage: page },
            }}
            className="text-decoration-none"
          >
            <ImageScore
              score={match.score}
              picture={match.candidate.picture}
              alt={name}
            />
          </Link>
        </Media>
        <Media body>
          <small>{match.job.title}</small>
          <div className="d-flex align-items-center">
            <Media heading className="h4 clickable">
              <Link
                to={{
                  pathname: `/match/${match.id}`,
                  state: { fromPage: page },
                }}
                className="text-decoration-none text-body"
              >
                {name}
              </Link>
            </Media>
          </div>
          <div className="text-muted">
            <i className="fas fa-map-marker-alt text-smaller mr-1" />
            <span className="text-smaller">{getLocationName(city)} </span>
          </div>
        </Media>
        <Media right>
          {shouldRenderMatched(match) && <Matched match={match} />}
          <div className="text-right text-smaller text-muted">
            <i className="fas fa-envelope mr-1" />
            {lastMessageAt ? (
              formatDate(lastMessageAt)
            ) : (
              <FormattedMessage id="app.page.matchList.not.contacted" />
            )}
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button
              tag={Link}
              to={
                threadId ? `/messages/${threadId}` : `/messages/new/${match.id}`
              }
              color="light"
              className="btn-circle mr-2"
            >
              <i className="fas fa-paper-plane" />
            </Button>
            {shouldRenderApproveRejectControls(match) && (
              <Fragment>
                <Button
                  className="btn-circle mr-2"
                  disabled={disabled}
                  onClick={onReject}
                  id={`uncontrolled-popover-match-reject-${match.id}`}
                >
                  <i className="fas fa-times" />
                </Button>
                <UncontrolledPopover
                  trigger="hover"
                  target={`uncontrolled-popover-match-reject-${match.id}`}
                  placement="bottom"
                >
                  <PopoverBody>
                    <FormattedMessage id="app.page.matchList.button.tooltip.reject" />
                  </PopoverBody>
                </UncontrolledPopover>
                <Button
                  color="success"
                  className="btn-circle"
                  disabled={disabled}
                  onClick={showApproveModal}
                  id={`uncontrolled-popover-match-approve-${match.id}`}
                >
                  <i className="fas fa-user-check" />
                </Button>
                <UncontrolledPopover
                  trigger="hover"
                  target={`uncontrolled-popover-match-approve-${match.id}`}
                  placement="bottom"
                >
                  <PopoverBody>
                    <FormattedMessage id="app.page.matchList.button.tooltip.hired" />
                  </PopoverBody>
                </UncontrolledPopover>
              </Fragment>
            )}
          </div>
        </Media>
      </Media>
    </Fragment>
  )
}

MatchCard.propTypes = {
  page: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
}
