import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api, ApiKeys } from "../../lib/moberries-api"
import { useQueryClient } from "react-query"

export const JobApplicationQuestionDeleteModal = ({
  applicationQuestionId,
  onDeleteSuccess,
  isOpen,
  close,
}) => {
  const queryClient = useQueryClient()
  const { mutate: deleteApplicationQuestion, isDeletingApplicationQuestion } =
    useAxiosMutation(api.deleteApplicationQuestion, {
      onSuccess: () => {
        queryClient.invalidateQueries(ApiKeys.ApplicationQuestions)
        onDeleteSuccess()
      },
    })

  return (
    <Modal isOpen={isOpen} toggle={close} centered={true}>
      <ModalHeader toggle={close}>
        <FormattedMessage id="app.common.confirm" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="app.job.questions.modal.body" />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            deleteApplicationQuestion({
              applicationQuestionId,
            })
          }
          disabled={isDeletingApplicationQuestion}
          loading={isDeletingApplicationQuestion}
        >
          <FormattedMessage id="app.common.confirm" />
        </Button>
        <Button
          color="light"
          onClick={close}
          disabled={isDeletingApplicationQuestion}
        >
          <FormattedMessage id="app.common.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

JobApplicationQuestionDeleteModal.propTypes = {
  applicationQuestionId: PropTypes.number.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}
