import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"

const Content = ({ children }) => children

const Sidebar = ({ children }) => children

export const DoubleColumnLayoutDashboard = ({ children, noGutters }) => {
  return (
    <Container className="container-dashboard">
      <Row noGutters={noGutters}>
        <Col md="12" lg="7" xl="8">
          {React.Children.toArray(children).find(c => c.type === Content)}
        </Col>
        <Col md="12" lg="5" xl="4" className="pt-4 pt-lg-0">
          {React.Children.toArray(children).find(c => c.type === Sidebar)}
        </Col>
      </Row>
    </Container>
  )
}
DoubleColumnLayoutDashboard.Content = Content
DoubleColumnLayoutDashboard.Sidebar = Sidebar

DoubleColumnLayoutDashboard.propTypes = {
  children: PropTypes.node.isRequired,
  noGutters: PropTypes.bool,
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}
Content.propTypes = {
  children: PropTypes.node.isRequired,
}
