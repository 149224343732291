import React from "react"
import PropTypes from "prop-types"
import { range } from "ramda"
import ContentLoader from "react-content-loader"

export const FormLoader = props => {
  const { count, ...rest } = props
  const WIDTH = 400
  const HEIGHT = 40
  return (
    <div className="d-flex justify-content-center">
      <ContentLoader
        style={{ width: 400 }}
        {...rest}
        viewBox={`0 0 ${WIDTH} ${count * HEIGHT * 1.5}`}
      >
        {range(0, count).map(i => (
          <rect
            key={i}
            x="0"
            y={i * 60}
            rx="4"
            ry="4"
            width={WIDTH}
            height={HEIGHT}
          />
        ))}
      </ContentLoader>
    </div>
  )
}

FormLoader.propTypes = {
  count: PropTypes.number,
}

FormLoader.defaultProps = {
  count: 3,
}
