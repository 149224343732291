import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap"

export const JobActivateSubscriptionPausedModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="app.common.confirm" />
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <FormattedMessage id="app.page.jobEdit.subscription.paused.modal.content" />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          <FormattedMessage id="app.common.ok" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

JobActivateSubscriptionPausedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

JobActivateSubscriptionPausedModal.defaultProps = {
  isOpen: false,
}
