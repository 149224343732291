export const linksJoined = {
  payHire: {
    en: "https://www.moberries.com/page/payperhire",
    de: "https://www.moberries.com/de/page/payperhire-de",
  },
  pricing: {
    de: "https://www.moberries.com/de/page/subscription-de",
    en: "https://www.moberries.com/page/subscription-model/",
  },
  homePage: {
    en: "https://www.moberries.com/",
    de: "https://www.moberries.com/de",
  },
  guide: {
    en: "https://www.moberries.com/page/company-guide",
    de: "https://www.moberries.com/de/page/unternehmens-anleitung/",
  },
  blog: {
    en: "https://www.moberries.com/blog/",
    de: "https://www.moberries.com/de/blog/",
  },
  legalNotice: {
    en: "https://www.moberries.com/legal",
    de: "https://www.moberries.com/de/legal",
  },
  support: {
    en: "mailto:support@moberries.com",
  },
  chatbot: {
    en: "https://m.me/moberries",
  },
  FAQ: {
    en: "https://help.moberries.com/en/collections/2099568-for-companies",
    de: "https://help.moberries.com/de/collections/2099568-fur-unternehmen",
  },
  howItWorks: {
    en: "https://www.moberries.com/info/companyguide",
    de: "https://www.moberries.com/info/unternehmensanleitung",
  },
  terms: {
    en: "https://www.moberries.com/companies/terms",
    de: "https://www.moberries.com/de/companies/terms",
  },
  termsPdf: {
    en: "https://app.moberries.com/MoBerries_Terms%26Conditions_en.pdf",
    de: "https://app.moberries.com/Moberries_Terms%26Conditions_de.pdf",
  },
  privacy: {
    en: "https://www.moberries.com/companies/privacy",
    de: "https://www.moberries.com/de/companies/privacy",
  },
  agreement: {
    en: "https://www.moberries.com/companies/agreement",
    de: "https://www.moberries.com/de/companies/agreement",
  },
  "atsIntegrations.slack": {
    en: "https://www.moberries.com/page/slack",
    de: "https://www.moberries.com/de/page/slack",
  },
  atsIntegrationsGuide: {
    en: "https://www.moberries.com/page/integrations",
    de: "https://www.moberries.com/de/page/integrations",
  },
  howWeMatch: {
    en: "https://www.moberries.com/page/how-our-matching-works",
    de: "https://www.moberries.com/de/page/so-funktioniert-unser-matching",
  },
  talentPoolGuide: {
    en: "https://www.moberries.com/page/talent-pool-feature",
    de: "https://www.moberries.com/de/page/talent-pool-feature-de/",
  },
  candidateSignUp: {
    en: "https://app.moberries.com/signup",
  },
  sharedTalentNetworks: {
    en: "https://www.moberries.com/page/shared-talent-networks",
    de: "https://www.moberries.com/de/page/shared-talent-networks/",
  },
  recruiters: {
    en: "https://www.moberries.com/recruiters",
    de: "https://www.moberries.com/de/recruiters/",
  },
  virtualRecruiter: {
    en: "https://www.moberries.com/page/virtual-recruiter",
    de: "https://www.moberries.com/de/page/virtual-recruiter",
  },
  jobReasonsCandidateFeedback: {
    en: "https://www.moberries.com/page/candidate-feedback#jobreasons ",
    de: "https://www.moberries.com/de/page/candidate-feedback#jobreasons ",
  },
  companyReasonsCandidateFeedback: {
    en: "https://www.moberries.com/page/candidate-feedback#companyreasons ",
    de: "https://www.moberries.com/de/page/candidate-feedback#companyreasons ",
  },
}
