import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Media } from "reactstrap"
import TimeUp from "../../assets/img/time-icon.svg"
import { Button } from "../common/button-component"

export function SubscriptionExpired({ companySubscription }) {
  return (
    <div className="mb-2 text-center">
      <Media className="bg-white border-bottom p-3 mb-4">
        <Media body>
          <Media heading>
            <FormattedMessage id="app.page.dashboard.matches.subscription.state.expired.hint.header" />
          </Media>
          <p>
            <FormattedMessage id="app.page.dashboard.matches.subscription.state.expired.hint.text" />
          </p>
          <Media className="mt-3 mb-3" body>
            <img
              className="img-fluid"
              src={TimeUp}
              width="200"
              alt="times-up"
            />
          </Media>
          <Button tag={Link} to="/subscribe" color="danger">
            <FormattedMessage id="app.common.upgrade.now" />
          </Button>
        </Media>
      </Media>
    </div>
  )
}

SubscriptionExpired.propTypes = {
  companySubscription: PropTypes.object.isRequired,
}

SubscriptionExpired.defaultProps = {}
