import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FormattedMessage, useIntl } from "react-intl"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Button } from "../common/button-component"
import { Select } from "../common/select-component"
import { Textarea } from "../common/textarea-component"
import { TEMPLATE_LANGS } from "../../default-options"
import { getInviteTemplate } from "../../templates/invite-email-template"

const templateOptions = TEMPLATE_LANGS.map(l => ({
  ...l,
  name: <FormattedMessage id={l.name} />,
}))

export const EmailTemplateModal = ({
  isOpen,
  close,
  toggle,
  inviteUrl,
  managerName,
  companyName,
}) => {
  const intl = useIntl()
  const [selectedLang, selectLang] = useState(
    templateOptions.find(to => to.id === intl.locale),
  )

  const template = getInviteTemplate(
    inviteUrl,
    selectedLang.id,
    managerName,
    companyName,
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="app.widget.talentPool.email.template.title" />
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <p>
            <FormattedMessage id="app.widget.talentPool.email.template.hint" />
          </p>
          <Select
            input={{
              value: selectedLang,
              onChange: selectLang,
            }}
            options={templateOptions}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            isSearchable={false}
            withCaret
            meta={{}}
          />
          <Textarea
            type="textarea"
            meta={{}}
            rows={13}
            readOnly
            input={{
              value: template,
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={close}>
          <FormattedMessage id="app.common.close" />
        </Button>
        <CopyToClipboard text={template}>
          <Button color="primary" onClick={close}>
            <FormattedMessage id="app.common.copy" />
          </Button>
        </CopyToClipboard>
      </ModalFooter>
    </Modal>
  )
}

EmailTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  inviteUrl: PropTypes.string.isRequired,
  managerName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
}

EmailTemplateModal.defaultProps = {
  isOpen: false,
}
