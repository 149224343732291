import { always, evolve, F, mergeLeft, T } from "ramda"
import { CAMPAIGN, FAIL, GET, LIST, START, SUCCESS } from "../constants"
import { arrayToObject, getActionType } from "../lib/helpers"

export const defaultState = {
  isLoading: false,
  campaigns: [],
  entities: {},
  count: 0,
}

export const reducer = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, CAMPAIGN, LIST, START):
      return evolve(
        {
          isLoading: T,
        },
        state,
      )

    case getActionType(GET, CAMPAIGN, LIST, SUCCESS):
      return evolve(
        {
          isLoading: F,
          err: always(null),
          count: always(payload.count),
          campaigns: always(payload.campaigns.map(c => c.id)),
          entities: mergeLeft(arrayToObject(payload.campaigns, "id")),
        },
        state,
      )

    case getActionType(GET, CAMPAIGN, LIST, FAIL):
      return evolve(
        {
          isLoading: F,
          err: always(payload.err),
        },
        state,
      )

    default:
      return state
  }
}
