import React from "react"

const defaultValue = {
  subscription: null,
  setSubscription: () => {},
  coupon: null,
  setCoupon: () => {},
}

export const SubscriptionOrderContext = React.createContext(defaultValue)
