import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { AnalyticsChartLayout } from "../../layouts/analytics/analytics-chart-layout/analytics-chart-layout"

import styles from "./chart-empty-state.module.scss"

// TODO: future-improvements: Mutation: mutate ChartEmptyState to suppprt structures of ChartEmptyStateV2, and remove ChartEmptyStateV2 component

export const ChartEmptyState = ({ title, img, info }) => (
  <div>
    <div className={styles.title}>
      <FormattedMessage id={title} />
    </div>
    <div className={styles.container}>
      <img className={styles.icon} src={img} alt="EmptyIcon" />
      <span className={styles.info}>
        <FormattedMessage id={info} />
      </span>
    </div>
  </div>
)

export const ChartEmptyStateV2 = ({ title, img, info, children }) => (
  <AnalyticsChartLayout title={title}>
    {children && <div>{children}</div>}
    <div className={styles.containerEmptyStates}>
      {img && <img className={styles.icon} src={img} alt="Empty Icon" />}
      {info && <span className={styles.info}>{info}</span>}
    </div>
  </AnalyticsChartLayout>
)

ChartEmptyState.propTypes = {
  title: PropTypes.node.isRequired,
  img: PropTypes.string.isRequired,
  info: PropTypes.node.isRequired,
}

ChartEmptyStateV2.propTypes = {
  title: PropTypes.node,
  img: PropTypes.string,
  info: PropTypes.node,
  children: PropTypes.node,
}
