import React from "react"
import { FormattedMessage } from "react-intl"
import { Container } from "reactstrap"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { noAuthRequired } from "../decorators/no-auth-required"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { Form, Formik } from "formik"
import { LabeledInputField } from "../components/fields/input-field"
import { Button } from "../components/common/button-component"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api } from "../lib/moberries-api"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { emailFormSchema } from "../schemas/account-schemas"
import { BaseLayout } from "../components/layouts/base-layout"

export const ResendPageComponent = () => {
  const {
    mutateAsync: resend,
    isLoading: isResending,
    isSuccess: isResent,
  } = useAxiosMutation(api.resendConfirmationEmail)

  if (isResent) {
    return (
      <BaseLayout className="py-5">
        <Container>
          <h1 className="text-center mb-4">
            <FormattedMessage id="app.page.resend.header" />
          </h1>
        </Container>
        <SmallSingleColumnLayout>
          <div className="bg-white rounded px-3 py-5">
            <SmallInnerFormLayout>
              <h3 className="text-center mb-4">
                <FormattedMessage id="app.page.resend.email.sent.title" />
              </h3>
              <h6 className="text-center">
                <FormattedMessage id="app.page.resend.email.sent.subtitle" />
              </h6>
              <img
                className="img-fluid"
                src="//assets.moberries.com/images/mail.gif"
                alt="Mail"
              />
            </SmallInnerFormLayout>
          </div>
        </SmallSingleColumnLayout>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout className="py-5">
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.page.resend.header" />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <div className="bg-white rounded px-3 py-5">
          <SmallInnerFormLayout>
            <Formik
              initialValues={{ email: "" }}
              onSubmit={submitAndValidate(resend)}
              validationSchema={emailFormSchema}
            >
              <Form>
                <LabeledInputField
                  label={<FormattedMessage id="app.common.email" />}
                  name="email"
                  type="email"
                  id="email"
                  placeholder="email@example.com"
                  isRequired
                />
                <Button
                  disabled={isResending}
                  loading={isResending}
                  block
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  <FormattedMessage id="app.common.send" />
                </Button>
              </Form>
            </Formik>
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

export const ResendPage = noAuthRequired(ResendPageComponent)
