import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { getCampaignList } from "../../../actions/vr-campaign-actions"
import { campaignsSelector } from "../../../selectors/vr-campaign-selector"
import { VRWidgetActiveCampaign } from "./vr-widget-active-campaign/vr-widget-active-campaign"
import { VRWidgetNoCampaigns } from "./vr-widget-no-campaigns/vr-widget-no-campaigns"
import { VRWidgetBestCampaign } from "./vr-widget-best-campaign/vr-widget-best-campaign"

const VirtualRecruiterWidgetComponent = ({ getCampaignList, campaigns }) => {
  useEffect(() => {
    getCampaignList({
      limit: 100,
    })
  }, [getCampaignList])

  const activeCampaign = campaigns.find(c => c.isActive)
  const campaignHasStats = campaigns.filter(c => c.statistics)

  if (!campaigns.length) {
    return <VRWidgetNoCampaigns />
  }

  if (!activeCampaign && campaignHasStats.length) {
    return <VRWidgetBestCampaign />
  }

  return <VRWidgetActiveCampaign />
}

VirtualRecruiterWidgetComponent.propTypes = {
  getCampaignList: PropTypes.func,
  campaigns: PropTypes.array,
}

const mapStateToProps = state => ({
  campaigns: campaignsSelector(state),
})

const mapDispatchToProps = {
  getCampaignList,
}

export const VirtualRecruiterWidget = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualRecruiterWidgetComponent)
