import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  decamelizeInterceptor,
  errorInterceptor,
  pageToOffsetInterceptor,
} from "./axios-interceptors"
import { getAuthorization } from "./helpers/api-helpers"
import { store } from "./store"

export const ApiKeys = {
  AssociatedSkills: "associatedSkills",
  ApplicationQuestions: "applicationQuestions",
  CompanyRewards: "companyRewards",
  CompanyRewardsCandidates: "companyRewardsCandidates",
  Industries: "industries",
  Job: "job",
  JobCategories: "jobCategories",
  JobRoles: "jobRoles",
  Manager: "manager",
  Managers: "managers",
  Match: "match",
  Matches: "matches",
  MatchFeedbacks: "matchFeedbacks",
  Messages: "messages",
  ProductUpdates: "productUpdates",
  ReferredCandidates: "referredCandidates",
  TeamManagers: "teamManagers",
  Teams: "teams",
  Thread: "thread",
  Threads: "threads",
  FeedbackSummary: "feedbackSummary",
  PerJobChartJobs: "perJobChartJobs",
  CandidatesJobSourceStats: "candidatesJobSourceStats",
  ActiveJobsStats: "activeJobsStats",
  TalentRewardsTimeSeries: "talentRewardsTimeSeries",
  TalentRewardsReferrals: "talentRewardsReferrals",
  TalentKpis: "talentKpis",
  SalaryRangeStats: "salaryRangeStats",
  RemoteWorkStats: "remoteWorkStats",
  JobOfferStats: "jobOfferStats",
  SalaryLevelStats: "salaryLevelStats",
}

class MoberriesApi {
  constructor() {
    const { jwt } = store.getState()
    this.http = axios.create({
      baseURL: config.moberries.api.url,
      timeout: 30000,
      headers: {
        Authorization: getAuthorization(jwt),
      },
    })

    axiosRetry(this.http, { retries: 3 })

    this.http.interceptors.response.use(response => response, errorInterceptor)
    this.http.interceptors.response.use(saveOriginalInterceptor)
    this.http.interceptors.response.use(camelizeInterceptor)

    this.http.interceptors.request.use(pageToOffsetInterceptor)
    this.http.interceptors.request.use(decamelizeInterceptor)
  }

  setDefaultHeader = ({ header, value }) => {
    this.http.defaults.headers[header] = value
  }

  get companyId() {
    const { company } = store.getState()
    const { id: companyId } = company ?? {}

    if (!companyId) {
      throw new Error("There's no companyId in the store!")
    }

    return companyId
  }

  loginWithEmailAndPassword = ({ email, password }) =>
    this.http.post("/api/v2/obtain_token/", {
      email,
      password,
    })

  updatePassword = ({ password, passwordNew }) =>
    this.http.put("/api/v2/users/password_change/", { password, passwordNew })

  resendConfirmationEmail = ({ email }) =>
    this.http.post("/api/v2/users/resend_activation/", { email })

  confirmNewEmailWithCode = ({ code }) =>
    this.http.put(`/api/v2/users/reset_email/activate/${code}/`, { code })

  recoverPassword = ({ email }) =>
    this.http.post("/api/v2/users/password_reset/", { email })

  resetPassword = ({ password, code }) =>
    this.http.put(`/api/v2/users/password_reset/activate/${code}/`, {
      password,
      code,
    })

  refreshToken = ({ token }) =>
    this.http.post("/api/v2/refresh_token/", { token })

  getCandidate = ({ id }) => this.http.get(`/api/v2/candidates/${id}/`)

  getManager = ({ id = "me" } = {}) => this.http.get(`/api/v2/managers/${id}/`)

  createCompany = data =>
    this.http({
      url: "/api/v2/companies/",
      method: "POST",
      data,
    })

  getManagerList = params =>
    this.http({
      url: "/api/v2/managers/",
      params: {
        limit: 100,
        ordering: "id",
        ...params,
      },
    })

  createNewManager = data => this.http.post("/api/v2/managers/", data)

  updateManager = ({ id = "me", data }) =>
    this.http({
      url: `/api/v2/managers/${id}/`,
      method: "PATCH",
      data,
    })

  deleteManager = ({ id }) =>
    this.http({
      url: `/api/v2/managers/${id}/`,
      method: "DELETE",
    })

  inviteManager = ({ id }) => this.http.post(`/api/v2/managers/${id}/invite/`)

  activateUser = ({ code, password }) =>
    this.http({
      url: `/api/v2/users/activate/${code}/`,
      method: "PUT",
      data: { password },
    })

  getCompany = async ({ id }) => this.http(`/api/v2/companies/${id}/`)

  updateCompany = ({ company }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/`,
      method: "PATCH",
      data: company,
    })

  getCompanyJobList = async ({ params }) => {
    params = {
      limit: 10,
      page: 1,
      ordering: "-created_at",
      status__in: ["ACT", "REV", "PAU"],
      ...params,
    }

    params.status__in = params.status__in.join(",")

    if (params.fields) {
      params.fields = params.fields.join(",")
    }

    if (params.id__in) {
      params.id__in = params.id__in.join(",")
    }

    return this.http(`/api/v2/companies/${this.companyId}/jobs/`, {
      params,
    })
  }

  createCompanyTeam = ({ team }) =>
    this.http({
      url: `/api/v2/teams/`,
      method: "POST",
      data: { ...team, company: this.companyId },
    })

  getCompanyTeamList = ({ params } = {}) =>
    this.http(`/api/v2/companies/${this.companyId}/teams/`, {
      params: { limit: 100, offset: 0, ...params },
    })

  updateCompanyTeam = ({ teamId, team }) =>
    this.http({
      url: `/api/v2/teams/${teamId}/`,
      method: "PATCH",
      data: team,
    })

  createTeamManager = ({ teamId, managerId }) =>
    this.http({
      url: `/api/v2/team-managers/`,
      method: "POST",
      data: { team: teamId, manager: managerId },
    })

  deleteCompanyTeam = ({ teamId }) =>
    this.http({
      url: `/api/v2/teams/${teamId}/`,
      method: "DELETE",
    })

  deleteManagerFromTeam = ({ teamManagerId }) =>
    this.http({
      url: `/api/v2/team-managers/${teamManagerId}/`,
      method: "DELETE",
    })

  getTeamManagers = ({ params }) =>
    this.http({
      url: `/api/v2/team-managers/`,
      params: { limit: 100, offset: 0, ...params },
    })

  getJob = async ({ id, params }) =>
    this.http({ url: `/api/v2/jobs/${id}/`, params })

  createJob = job =>
    this.http({
      url: "/api/v2/jobs/",
      method: "POST",
      data: job,
    })

  updateJob = ({ id, job }) =>
    this.http({
      url: `/api/v2/jobs/${id}/`,
      method: "PATCH",
      data: job,
    })

  getCandidateList = ({ params }) =>
    this.http(`/api/v2/companies/${this.companyId}/candidates/`, {
      params: { limit: 10, offset: 0, ...params },
    })

  getReferredCandidates = ({ params }) =>
    this.http(`/api/v2/companies/${this.companyId}/referrals/`, {
      params: { limit: 10, offset: 0, ...params },
    })

  getThreadList = (params, cancelToken) => {
    if (params.match__status__in) {
      params.match__status__in = params.match__status__in.join(",")
    }
    if (params.search === "") {
      delete params.search
    }

    return this.http.get(`/api/v2/threads/`, {
      params: {
        limit: 20,
        ordering: "last_message_timestamp",
        ...params,
      },
      cancelToken,
    })
  }

  getThread = ({ id }) =>
    this.http({
      url: `/api/v2/threads/${id}/`,
    })

  createThread = ({ candidateId, jobId, message }) =>
    this.http({
      url: "/api/v2/threads/",
      method: "POST",
      data: { candidateId, jobId, message },
    })

  getMessageList = ({ threadId, ...params }) =>
    this.http(`/api/v2/threads/${threadId}/messages/`, {
      params: { limit: 10, ordering: "-created", ...params },
    })

  getMessage = async ({ threadId, messageId }) =>
    this.http(`/api/v2/threads/${threadId}/messages/${messageId}/`)

  getMatchList = async ({ params, cancelToken }) => {
    params = {
      ordering: "-created_at",
      ...params,
    }

    if (Array.isArray(params.job__status__in)) {
      params.job__status__in = params.job__status__in.join(",")
    }

    if (Array.isArray(params.status__in)) {
      params.status__in = params.status__in.join(",")
    }

    return this.http(`/api/v2/companies/${this.companyId}/matches/`, {
      params,
      cancelToken,
    })
  }

  getMatch = async ({ id }) =>
    this.http(`/api/v2/companies/${this.companyId}/matches/${id}/`)

  updateMatch = ({ id, match }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/matches/${id}/`,
      method: "PATCH",
      data: match,
    })

  createFeedback = ({ answers, comment }) =>
    this.http({
      method: "POST",
      url: "/api/v2/feedbacks/",
      data: { answers, comment },
    })

  linkFeedbackWithMatch = ({ id, feedback }) =>
    this.http({
      method: "POST",
      url: `/api/v2/companies/${this.companyId}/matches/${id}/feedbacks/`,
      data: { feedback },
    })

  getMatchFeedbacks = ({ id }) =>
    this.http(`/api/v2/companies/${this.companyId}/matches/${id}/feedbacks/`)

  linkSkillsWithFeedback = ({ matchFeedbackId, id, skills }) =>
    this.http({
      method: "POST",
      url: `/api/v2/companies/${this.companyId}/matches/${id}/feedbacks/${matchFeedbackId}/skills/`,
      data: { skills },
    })

  getFeedbackChoices = ({ type, params }) =>
    this.http(`/api/v2/feedback-sets/${type}/choices/`, {
      params: { limit: 100, offset: 0, ...params },
    })

  createMessage = ({ threadId, text }) =>
    this.http({
      method: "POST",
      url: `/api/v2/threads/${threadId}/messages/`,
      data: { text },
    })

  getSlackIntegrationList = () =>
    this.http(`/api/v2/companies/${this.companyId}/slack-integrations/`)

  getJobCategories = ({ params = {} } = {}) =>
    this.http("/api/v2/jobrolecategories/", {
      params: { limit: 100, offset: 0, ...params },
    })

  getJobCategory = ({ categoryId }) =>
    this.http(`/api/v2/jobrolecategories/${categoryId}`)

  getSkills = ({ params }) =>
    this.http("/api/v2/skills/", {
      params: { limit: 100, offset: 0, ...params },
    })

  getAssociatedSkills = ({ jobRoleId, params }) =>
    this.http(`/api/v2/jobroles/${jobRoleId}/associated_skills/`, {
      params: { limit: 50, offset: 0, ...params },
    })

  getJobRoles = ({ params }) =>
    this.http("/api/v2/jobroles/", {
      params: { limit: 10, offset: 0, ...params },
    })

  getLanguages = ({ params }) =>
    this.http("/api/v2/languages/", {
      params: { limit: 10, offset: 0, ...params },
    })

  getCardList = ({ params }) =>
    this.http(`/api/v2/cards/`, {
      params: { limit: 10, offset: 0, ...params },
    })

  deleteCard = ({ cardId }) =>
    this.http({
      url: `/api/v2/cards/${cardId}/`,
      method: "DELETE",
    })

  createSetupIntent = () =>
    this.http({
      url: "/api/v2/setup-intents/",
      method: "POST",
    })

  setDefaultPaymentMethod = ({ paymentMethodId }) =>
    this.http({
      url: `/api/v2/customer-payment-methods/`,
      data: { paymentMethodId },
      method: "POST",
    })

  getProductUpdates = () =>
    this.http.get("/api/v2/product_updates/", {
      params: { seen: false, appType: "company_app" },
    })

  markProductUpdateAsRead = ({ id }) =>
    this.http.post("/api/v2/product_updates/read/", { productUpdate: id })

  getCompanySubscription = ({ id }) =>
    this.http(`/api/v2/companies/${this.companyId}/subscriptions/${id}/`)

  getSubscriptionList = ({ params }) => {
    params = {
      types: "all",
      limit: 999,
      ...params,
    }

    if (Array.isArray(params.types)) {
      params.types = params.types.join(",")
    }

    return this.http("/api/v2/subscriptions/", {
      params,
    })
  }

  updateCompanySubscription = ({ event, subscriptionId }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/subscriptions/${subscriptionId}/events/`,
      method: "POST",
      data: { event },
    })

  linkFeedbackWithSubscription = ({ subscriptionId, feedbackId }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/subscriptions/${subscriptionId}/feedback/`,
      method: "POST",
      data: { feedback: feedbackId },
    })

  orderCompanySubscription = ({ subscription, mainStripeCouponId }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/subscriptions/`,
      method: "POST",
      data: { subscription, mainStripeCouponId },
    })

  getCompanyInvoiceList = ({ params }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/invoices/`,
      method: "GET",
      params: { limit: 50, offset: 0, ...params },
    })

  getCouponList = ({ params }) =>
    this.http({
      url: `/api/v2/coupons/`,
      method: "GET",
      params: { limit: 50, offset: 0, ...params },
    })

  getIndustriesList = ({ params }) =>
    this.http({
      url: "/api/v2/industries/",
      method: "GET",
      params: { limit: 200, ...params },
    })

  updateJobsConditions = ({ data }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/jobs/conditions/`,
      method: "PATCH",
      data,
    })

  getJobFeedback = ({ jobId }) =>
    this.http({ url: `/api/v2/jobs/${jobId}/feedback_summary/` })

  getApplicationsStatistics = ({ params }) =>
    this.http(`/api/v2/companies/${this.companyId}/match-statistics/`, {
      params,
    })

  getFeedbackSummary = () =>
    this.http(`/api/v2/companies/${this.companyId}/feedback_summary/`)

  getActiveJobsStats = () => this.http(`/api/v2/companies/active_job_stats/`)

  getCandidatesJobSourceStats = () =>
    this.http(`/api/v2/companies/candidate_job_source_stats/`)

  getRewardsTimeSeries = ({ start, end }) => {
    return this.http.get(
      `/api/v2/companies/${this.companyId}/rewards/time_series/?start_date=${start}&end_date=${end}`,
    )
  }

  getRewardsReferrals = ({ start, end }) => {
    return this.http.get(
      `/api/v2/companies/${this.companyId}/rewards/referrals/?start_date=${start}&end_date=${end}`,
    )
  }

  getRewardsKpis = () => {
    return this.http.get(`/api/v2/companies/${this.companyId}/rewards/kpis/`)
  }

  getMessageTemplateList = () =>
    this.http({
      url: `/api/v2/message-templates/`,
      params: { limit: 99999 },
    })

  createMessageTemplate = ({ data }) =>
    this.http({ url: `/api/v2/message-templates/`, method: "POST", data })

  updateMessageTemplate = ({ data, messageTemplateId }) =>
    this.http({
      url: `/api/v2/message-templates/${messageTemplateId}/`,
      method: "PATCH",
      data,
    })

  deleteMessageTemplate = ({ messageTemplateId }) =>
    this.http({
      url: `/api/v2/message-templates/${messageTemplateId}/`,
      method: "DELETE",
    })

  getCompanyRewards = ({ params } = {}) =>
    this.http(`/api/v2/companies/${this.companyId}/rewards/`, { params })

  getCompanyRewardsTimeSeries = ({ params }) =>
    this.http(`/api/v2/companies/${this.companyId}/rewards/time_series/`, {
      params,
    })

  getCompanyRewardsCandidates = ({ params = {} } = {}) =>
    this.http(`/api/v2/companies/${this.companyId}/rewards/candidates/`, {
      params,
    })

  getCity = ({ cityId }) => this.http(`/api/v2/cities/${cityId}/`)

  getCountry = ({ countryId }) => this.http(`/api/v2/countries/${countryId}/`)

  getCompanyRewardsCandidates = ({ params } = {}) =>
    this.http(`/api/v2/companies/${this.companyId}/rewards/candidates/`, {
      params,
    })

  getCity = ({ cityId }) => this.http(`/api/v2/cities/${cityId}/`)

  getCountry = ({ countryId }) => this.http(`/api/v2/countries/${countryId}/`)

  getApplicationQuestions = ({ params }) =>
    this.http("/api/v2/application_questions/", {
      params: { ordering: "position", limit: 9999, isActive: true, ...params },
    })

  createApplicationQuestion = data =>
    this.http({ url: "/api/v2/application_questions/", method: "POST", data })

  updateApplicationQuestion = data =>
    this.http({
      url: `/api/v2/application_questions/${data.id}/`,
      method: "PATCH",
      data,
    })

  deleteApplicationQuestion = ({ applicationQuestionId }) =>
    this.http({
      url: `/api/v2/application_questions/${applicationQuestionId}/`,
      method: "DELETE",
    })

  getSalaryRangeStats = ({ level }) =>
    this.http(`/api/v2/insights/salary-range-stats/?level=${level}`)

  getRemoteWorkStats = ({ level }) =>
    this.http(`/api/v2/insights/remote-work-stats/?level=${level}`)

  getJobOfferStats = ({ id, startDate, endDate }) =>
    this.http(
      `/api/v2/insights/job-offer-popularity-stats/?id=${id}&start_date=${startDate}&end_date=${endDate}`,
    )

  getSalaryLevelStats = ({ categoryId }) =>
    this.http(`/api/v2/insights/salary-level-stats/?category_id=${categoryId}`)

  getCampaignList = params =>
    this.http(`/api/v2/companies/${this.companyId}/campaigns/`, {
      params,
    })

  getVrCandidateList = ({ campaignId, ...params }) =>
    this.http(
      `/api/v2/companies/${this.companyId}/campaigns/${campaignId}/vr-candidates/`,
      {
        params,
      },
    )

  setVrCandidateStatus = ({ campaignId, candidateId, data }) =>
    this.http({
      url: `/api/v2/companies/${this.companyId}/campaigns/${campaignId}/vr-candidates/${candidateId}/`,
      method: "PATCH",
      data,
    })
}

const api = new MoberriesApi()

store.subscribe(({ jwt }) =>
  api.setDefaultHeader(
    { header: "Authorization", value: getAuthorization(jwt) },
    store => store.jwt,
  ),
)

export { api }
