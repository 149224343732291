import React from "react"
import PropTypes from "prop-types"
import { Input } from "reactstrap"
import { Draggable } from "react-beautiful-dnd"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"

export const PredefinedAnswerField = ({
  remove,
  addAnswer,
  name,
  id,
  index,
  predefinedAnswers,
}) => {
  const [field, meta] = useFormikField({ name })
  const isInvalid = meta.touched && Boolean(meta.error)

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="mb-1 d-flex"
          >
            <div className="align-items-center d-flex">
              <i
                {...provided.dragHandleProps}
                className="fas fa-lg fa-bars pr-2 text-muted"
              />
            </div>
            <Input
              {...field}
              name={name}
              id={id}
              invalid={isInvalid}
              className="mr-2"
            />

            <div className="align-items-center d-flex">
              <i
                className="fas fa-lg fa-times-circle text-muted clickable"
                onClick={() => remove(index)}
              />
              {index === predefinedAnswers.length - 1 &&
              !snapshot.isDragging ? (
                <i
                  className="fas fa-lg fa-plus-circle text-success clickable ml-2"
                  onClick={addAnswer}
                />
              ) : (
                <i className="fas fa-lg fa-plus-circle text-white ml-2" />
              )}
            </div>
          </div>
          {isInvalid && !snapshot.isDragging && (
            <div className="text-danger ml-4">
              <FormattedErrorMessage error={meta.error} />
            </div>
          )}
        </div>
      )}
    </Draggable>
  )
}

PredefinedAnswerField.propTypes = {
  remove: PropTypes.func.isRequired,
  addAnswer: PropTypes.func.isRequired,
  predefinedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      order: PropTypes.number,
      id: PropTypes.number,
    }),
  ),
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
