import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { differenceInMilliseconds, parseISO } from "date-fns"
import { FormattedRelative } from "./formatted-relative"

export function Expires({ className, expiresAt, small }) {
  const message = (
    <FormattedMessage
      id="app.common.expires"
      values={{
        isFuture: differenceInMilliseconds(parseISO(expiresAt), Date.now()) > 0,
        time: <FormattedRelative timestamp={expiresAt} />,
      }}
    />
  )

  if (!small) {
    return message
  }

  return <small className={className}>{message}</small>
}

Expires.propTypes = {
  className: PropTypes.string,
  expiresAt: PropTypes.string.isRequired,
  small: PropTypes.bool.isRequired,
}

Expires.defaultProps = {
  small: false,
}
