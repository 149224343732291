import { useMediaQuery } from "react-responsive"

const sizeToPixels = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export const useBreakpoint = (min, max) => {
  if (min === max) {
    throw new Error("Parameters min and max should be different")
  }
  const minWidth = sizeToPixels[min]
  const maxWidth = sizeToPixels[max] ? sizeToPixels[max] - 1 : 999999
  return useMediaQuery({ minWidth, maxWidth })
}
