import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { canSeeContactDataSection, trimWebReference } from "../../lib/helpers"
import { DetailView } from "../detail-view/detail-view-component"
import { DetailViewSection } from "../detail-view/detail-view-section-component"
import { MatchRolesComparison } from "./match-roles-comparison-component"
import { MatchSkillsComparison } from "./match-skills-comparison-component"
import { MatchLanguagesComparison } from "./match-languages-comparison-component"
import { MatchJobClaimsComparison } from "./match-job-claims-comparison-component"
import { MatchLocationComparison } from "./match-location-comparison-component"
import { MatchCertificates } from "./match-certificates-component"

export const MatchDetails = ({ job, candidate, match }) => {
  if (!match || !job || !candidate) {
    return null
  }

  return (
    <div className="bg-white px-3 pb-4 rounded-bottom">
      <DetailView>
        <DetailViewSection
          title={<FormattedMessage id="app.page.match.workExperience" />}
          hint={<FormattedMessage id="app.page.match.workExperience.popover" />}
        >
          <MatchRolesComparison candidate={candidate} job={job} />
        </DetailViewSection>

        <DetailViewSection
          title={<FormattedMessage id="app.page.match.skills" />}
          hint={<FormattedMessage id="app.page.match.skills.popover" />}
        >
          <div className="mt-3">
            <MatchSkillsComparison candidate={candidate} job={job} />
          </div>
        </DetailViewSection>

        <DetailViewSection
          title={<FormattedMessage id="app.page.jobDetails.neededLanguages" />}
          hint={
            <FormattedMessage id="app.page.match.neededLanguages.popover" />
          }
        >
          <MatchLanguagesComparison candidate={candidate} job={job} />
        </DetailViewSection>

        <DetailViewSection
          title={<FormattedMessage id="app.page.match.jobClaims" />}
          hint={<FormattedMessage id="app.page.match.jobClaims.popover" />}
        >
          <MatchJobClaimsComparison candidate={candidate} job={job} />
        </DetailViewSection>

        {candidate.certificates?.length > 0 && (
          <DetailViewSection
            title={<FormattedMessage id="app.page.match.certificates" />}
            hint={<FormattedMessage id="app.page.match.certificates.popover" />}
          >
            <MatchCertificates candidate={candidate} job={job} />
          </DetailViewSection>
        )}

        <DetailViewSection
          title={<FormattedMessage id="app.page.match.location" />}
          hint={<FormattedMessage id="app.page.match.location.popover" />}
        >
          <MatchLocationComparison candidate={candidate} job={job} />
        </DetailViewSection>

        {candidate.references?.length > 0 && (
          <DetailViewSection
            title="Web references"
            hint={
              <FormattedMessage id="app.page.match.webreferences.popover" />
            }
          >
            <ul className="m-0 mt-2 list-unstyled text-smaller">
              {candidate.references.map(r => (
                <li key={r}>
                  <a href={r} target="_blank" rel="noopener noreferrer">
                    {trimWebReference(r)}
                  </a>
                </li>
              ))}
            </ul>
          </DetailViewSection>
        )}

        {canSeeContactDataSection(match) && (
          <DetailViewSection
            title={<FormattedMessage id="app.page.match.contactData" />}
          >
            <div className="mt-2">
              <ul className="m-0 list-unstyled">
                <li>
                  <i className="fas fa-envelope mr-2" />
                  <a
                    href={"mailto:" + candidate.email}
                    className="text-smaller"
                  >
                    {candidate.email}
                  </a>
                </li>
              </ul>
            </div>
          </DetailViewSection>
        )}
      </DetailView>
    </div>
  )
}

MatchDetails.propTypes = {
  job: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}
