import { showErrorAlert } from "../lib/alerts"
import { api } from "../lib/moberries-api"
import { store } from "../lib/store"

export const refreshCompany = async () => {
  try {
    const {
      company: { id },
    } = store.getState()
    const { data: company } = await api.getCompany({ id })
    store.setState({ company })
  } catch (err) {
    showErrorAlert({ err })
  }
}
