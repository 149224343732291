import { createSelector } from "reselect"
import { head, isEmpty } from "ramda"
import {
  atsJobIntegrationByJobIdSelector,
  unintegratedJobIdsSelector,
} from "./ats-job-integration-selector"
import { entitiesSelector as providerJobsEntitiesSelector } from "./ats-company-integration-open-jobs-selector"
import {
  companySubscriptionSelector,
  getCurrentAvailableFlatSlots,
  isComSubscriptionCanceled,
} from "../lib/helpers"
import { JOB_CONDITION_TYPES } from "../constants"
import { ALL_REGIONS } from "../default-options"
import { store } from "../lib/store"

export const stateSelector = state => state.job

export const countSelector = createSelector(stateSelector, state => state.count)

export const isLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading,
)

export const isUpdatingSelector = createSelector(
  stateSelector,
  state => state.isUpdating,
)

export const isUpdatingJobsConditionsSelector = createSelector(
  stateSelector,
  state => state.isUpdatingJobsConditions,
)

export const updatingErrSelector = createSelector(
  stateSelector,
  state => state.updatingErr,
)

export const deletingSelector = createSelector(
  stateSelector,
  state => state.deleting,
)

export const errorSelector = createSelector(stateSelector, state => state.err)
export const integrationSelectedSelector = createSelector(
  stateSelector,
  state => state.integrationSelected,
)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)
export const idsSelector = createSelector(stateSelector, state => state.jobs)

export const jobsSelector = createSelector(
  entitiesSelector,
  idsSelector,
  (entities, ids) => ids.map(id => entities[id]),
)

export const jobSelector = createSelector(
  entitiesSelector,
  (state, { id }) => id,
  (entities, id) => entities[id],
)

export const jobWithIntegrationPostingSelector = createSelector(
  jobSelector,
  atsJobIntegrationByJobIdSelector,
  providerJobsEntitiesSelector,
  (job, atsJobIntegration, providerJobsEntities) => {
    if (!job) {
      return undefined
    }

    const remoteRegions =
      job.remote && isEmpty(job.remoteRegions) ? ALL_REGIONS : job.remoteRegions
    const relocationRegions =
      job.relocate && isEmpty(job.relocationRegions)
        ? ALL_REGIONS
        : job.relocationRegions

    if (atsJobIntegration) {
      const providerJob = providerJobsEntities[atsJobIntegration.providerJob]
      return {
        ...job,
        atsJob: providerJob || null,
        remoteRegions,
        relocationRegions,
      }
    }

    return { ...job, remoteRegions, relocationRegions }
  },
)

export const newJobTemplateSelector = state => {
  const { manager, company } = store.getState()
  const companySubscription = companySubscriptionSelector(store.getState())
  return {
    team: head(manager.teams).id,
    locations: [company.city],
    skills: [],
    jobRoles: [],
    jobTypes: [1],
    languages: [],
    salaryMin: 0,
    salaryMax: 0,
    condition: {
      type:
        getCurrentAvailableFlatSlots(companySubscription) > 0 &&
        !isComSubscriptionCanceled(companySubscription)
          ? JOB_CONDITION_TYPES.flat
          : JOB_CONDITION_TYPES.pph,
    },
    remote: false,
    remoteRegions: [],
    relocate: true,
    relocationRegions: ALL_REGIONS,
  }
}
export const atsUnintegratedJobsSelector = createSelector(
  unintegratedJobIdsSelector,
  entitiesSelector,
  (ids, jobEntities) => ids.map(id => jobEntities[id]),
)

export const jobFeedbackByJobIdSelector = createSelector(
  stateSelector,
  (state, { jobId }) => jobId,
  (state, jobId) => state.jobFeedbackByJobId[jobId],
)

export const isJobFeedbackLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoadingJobFeedback,
)
