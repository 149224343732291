import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { usePrevious } from "react-hanger"
import { Input } from "../common/input-component"
import { Button } from "../common/button-component"
import { FormattedMessage, useIntl } from "react-intl"
import { KeyCodes } from "../../constants"
import { useOpenClose } from "../../hooks/use-open-close"
import { CreateMessageTemplateModal } from "../modals/create-message-template-modal"
import { MessageTemplateSelect } from "../message-template/message-template-select"
import { useStore } from "../../lib/store"

const drafts = {}

export const NewMessage = ({
  candidate,
  job,
  onSend,
  threadId,
  creating,
  err,
  defaultTemplate,
}) => {
  const manager = useStore(state => state.manager)
  const intl = useIntl()
  const [message, setMessage] = useState(drafts[threadId] ?? "")
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const {
    value: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useOpenClose({ initialValue: false })

  const candidateId = candidate?.id
  const jobId = job?.id

  const prevCreating = usePrevious(creating)
  useEffect(() => {
    if (!creating && prevCreating && !err) {
      setMessage("")
      setSelectedTemplate(null)
      drafts[threadId] = ""
    }
  }, [creating, err, prevCreating, threadId])

  useEffect(() => {
    if (threadId) {
      setSelectedTemplate(null)
      setMessage(drafts[threadId] ?? "")
    }
  }, [threadId])

  return (
    <Fragment>
      {isModalOpen && (
        <CreateMessageTemplateModal
          isOpen={isModalOpen}
          close={closeModal}
          toggle={closeModal}
          initialValues={{
            text: message,
            name: "",
          }}
        />
      )}

      <MessageTemplateSelect
        setMessage={setMessage}
        currentMessage={message}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        defaultTemplate={!threadId && defaultTemplate}
        candidateFirstName={candidate?.firstName}
        candidateLastName={candidate?.lastName}
        jobTitle={job?.title}
        companyCity={job?.company.city.name}
        companyName={job?.company.name}
        managerName={manager.firstName}
        managerLastName={manager.lastName}
      />

      <Input
        type="textarea"
        name="message"
        id="message"
        className="m-0 p-half bg-white border-top _lr-hide"
        inputClassName={classNames("border-0 p-2 rounded-0", {
          "bg-white": !creating,
          "bg-light": creating,
        })}
        style={{
          resize: "none",
        }}
        meta={{}}
        input={{
          readOnly: creating,
          value: message,
          onChange: e => {
            drafts[threadId] = e.target.value
            setMessage(e.target.value)
          },
          onKeyDown: e => {
            if (e.which === KeyCodes.Enter && e.ctrlKey && message.length > 0) {
              onSend({ text: message, threadId, candidateId, jobId })
            }
          },
        }}
        rows={4}
        placeholder={intl.formatMessage({
          id: "app.page.messages.textarea.placeholder",
        })}
      />
      <div className="d-flex justify-content-between bg-light py-2 px-3 border-top">
        <Button
          onClick={openModal}
          disabled={message.length === 0 || Boolean(selectedTemplate)}
        >
          <i className="fas fa-bookmark d-lg-none" />
          <span className="d-none d-lg-inline">
            <FormattedMessage id="app.page.messages.button.saveAsTemplate" />
          </span>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (message.length > 0) {
              onSend({ text: message, threadId, candidateId, jobId })
            }
          }}
          disabled={creating || message.length === 0}
          loading={creating}
        >
          <FormattedMessage id="app.common.send" />
        </Button>
      </div>
    </Fragment>
  )
}

NewMessage.propTypes = {
  candidate: PropTypes.object,
  job: PropTypes.object,
  onSend: PropTypes.func.isRequired,
  threadId: PropTypes.number,
  creating: PropTypes.bool.isRequired,
  err: PropTypes.object,
  defaultTemplate: PropTypes.object,
}
