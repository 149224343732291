import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import {
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap"
import { Textarea } from "../common/textarea-component"
import { CopyToClipboard } from "react-copy-to-clipboard"

export const IntegrationModal = ({ isOpen, close, jobId }) => {
  const container = `<div id="moberries-widget" data-mb-apply-job-id='${jobId}'></div>`
  const script =
    '<script async defer src="https://assets.moberries.com/scripts/widget.js"></script>'

  const code = container + "\n" + script

  const refLink = `https://app.moberries.com/apply/${jobId}`
  return (
    <Modal isOpen={isOpen} toggle={close} centered>
      <ModalHeader toggle={close}>
        <FormattedMessage id="app.page.jobDetails.modal.integration.title" />
      </ModalHeader>
      <ModalBody>
        <Fragment>
          <div className="mb">
            <h5>
              <FormattedMessage id="app.widget.apply.modal.code.title" />
            </h5>
            <FormattedMessage id="app.widget.apply.modal.code.text" />
            <div>
              <Textarea
                type="textarea"
                name="message"
                id="message"
                meta={{}}
                className="bg-white w-100 mt-3 _lr-hide"
                minRows={3}
                maxRows={15}
                readOnly
                input={{
                  value: code,
                }}
              />
            </div>
          </div>
          <div>
            <h5>
              <FormattedMessage id="app.widget.apply.modal.referrals.title" />
            </h5>
            <div className="mt-3 mb-3">
              <InputGroup>
                <Input type="text" defaultValue={refLink} readOnly />
                <InputGroupAddon addonType="append">
                  <CopyToClipboard text={refLink}>
                    <Button color="secondary" size="small">
                      <FormattedMessage id="app.common.copy" />
                    </Button>
                  </CopyToClipboard>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={close}>
          <FormattedMessage id="app.common.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

IntegrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
}

IntegrationModal.defaultProps = {
  isOpen: false,
}
