import qs from "qs"
import { differenceInDays, parseISO } from "date-fns"
import { api } from "../lib/moberries-api"
import { store } from "../lib/store"
import { history } from "../history"
import { PROBLEMS } from "../constants"
import { getProviderBySlug, track } from "../lib/helpers"
import { queryClient } from "../lib/query-client"
import { atsApi } from "../lib/ats-api"
import { refreshAtsConnection } from "./ats-controller"

const initJwtFromQuery = async () => {
  const { jwt: currentJwt } = store.getState()

  if (currentJwt) {
    return
  }

  const { jwt, ...nextQuery } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })

  if (!jwt) {
    return
  }

  store.setState({ jwt })

  history.replace({
    ...history.location,
    search: qs.stringify(nextQuery, { addQueryPrefix: true }),
  })
}

export const initUser = async () => {
  await initJwtFromQuery()

  const { jwt, jwtUpdatedAt } = store.getState()

  if (!jwt) {
    return store.setState({ isInitialized: true })
  }

  try {
    const { data: manager } = await api.getManager()
    store.setState({ manager })
    const { data: company } = await api.getCompany({ id: manager.company.id })
    store.setState({ company, isInitialized: true })
  } catch (e) {
    return store.setState({ jwt: null, isInitialized: true })
  }

  if (differenceInDays(new Date(), parseISO(jwtUpdatedAt)) < 1) {
    return
  }

  const {
    data: { token: newJwt },
  } = await api.refreshToken({ token: jwt })

  store.setState({ jwt: newJwt, jwtUpdatedAt: new Date().toISOString() })
}

export const initAts = async () => {
  try {
    const { data: atsCompanyIntegrationList } =
      await atsApi.getAtsCompanyIntegrationList()

    const atsCompanyIntegration = atsCompanyIntegrationList?.[0]
    store.setState({ atsCompanyIntegration })

    const { data: providers } = await atsApi.getAtsProvidersList()
    const connectedProvider = getProviderBySlug(
      providers,
      atsCompanyIntegration?.provider,
    )

    store.setState({
      providers,
      connectedProvider,
    })
  } catch (err) {
    return store.setState({ isInitialized: true })
  }
}

export const loginWithEmailAndPassword = async ({ email, password }) => {
  const {
    data: { token, accountType },
  } = await api.loginWithEmailAndPassword({
    email,
    password,
  })

  if (accountType !== "manager") {
    const err = new Error("Invalid account type for company application")
    err.problem = PROBLEMS.CLIENT_ERROR // TODO: get rid of "PROBLEMS"
    err.code = `wrong_account_type_${accountType}`
    throw err
  }

  store.setState({ jwt: token, jwtUpdatedAt: new Date().toISOString() })
  const { data: manager } = await api.getManager()
  const { data: company } = await api.getCompany({ id: manager.company.id })
  store.setState({ manager, company })

  await refreshAtsConnection()
}

export const activateUser = async ({ token }) => {
  store.setState({ jwt: token })
  const { data: manager } = await api.getManager()
  const { data: company } = await api.getCompany({ id: manager.company.id })
  store.setState({ manager, company })
}

export const logout = () => {
  store.setState({
    jwt: null,
    manager: null,
    company: null,
    jwtUpdatedAt: new Date(0),

    atsCompanyIntegration: null,
    connectedProvider: null,
  })
  queryClient.clear()

  track({
    event: "logout",
  })
}

export const setLocale = locale => {
  store.setState({ locale })
}
