import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Input, Label } from "reactstrap"

export const CheckboxGroup = ({
  options,
  name,
  value,
  onChange,
  getOptionLabel,
  getOptionValue,
  onBlur,
  min,
}) => {
  return (
    <Fragment>
      {options.map(option => {
        const optionValue = getOptionValue(option)
        const optionLabel = getOptionLabel(option)
        return (
          <div className="custom-control custom-checkbox" key={optionValue}>
            <Input
              id={`${name}-${optionValue}`}
              name={name}
              className="custom-control-input"
              type="checkbox"
              disabled={value.includes(optionValue) && value.length <= min}
              checked={value.includes(optionValue)}
              onChange={e => {
                if (e.target.checked) {
                  onChange([...value, optionValue])
                } else {
                  onChange(value.filter(v => v !== optionValue))
                }
              }}
              onBlur={e => {
                if (onBlur) {
                  onBlur(e)
                }
              }}
            />
            <Label
              for={`${name}-${optionValue}`}
              className="custom-control-label"
            >
              {optionLabel}
            </Label>
          </div>
        )
      })}
    </Fragment>
  )
}

CheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  min: PropTypes.number,
}

CheckboxGroup.defaultProps = {
  min: 0,
}
