import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { NavLink, NavItem, Nav } from "reactstrap"
import { NavLink as RLink } from "react-router-dom"
import { useFilter } from "../../hooks/use-filter"

export const TabsFilter = ({
  filterOptions,
  filterKey,
  filterName,
  disabled,
  resetFilters,
}) => {
  const { filters } = useFilter({
    filterOptions,
    filterKey,
    filterName,
    resetFilters,
  })
  return (
    <Nav tabs justified className="settings-tabs">
      {filters.map(filter => (
        <NavItem key={filter.value}>
          <NavLink
            className="font-weight-bold px-0"
            tag={RLink}
            to={filter.to}
            isActive={filter.isActive}
            exact
            disabled={disabled}
          >
            <FormattedMessage
              id={`app.filter.${filter.name}.${filter.key}.${
                filter.value || "default"
              }`}
            />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

TabsFilter.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  filterKey: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  resetFilters: PropTypes.arrayOf(PropTypes.string),
}

TabsFilter.defaultProps = {
  filters: [],
  disabled: false,
}
