import React, { useState, Fragment } from "react"
import { Redirect, useHistory } from "react-router"
import { Button } from "../components/common/button-component"
import { FormattedMessage } from "react-intl"
import { BulletListLoader } from "../components/loaders/bullet-list-loader"
import { TextLineLoader } from "../components/loaders/text-line-loader"
import { PAU, ATS_JOB_SYNC_OPTIONS } from "../constants"
import { AtsMergeJobModal } from "../components/integration/ats-merge-job-modal"
import { AtsMergeSingleJob } from "../components/integration/ats-merge-single-job-component"
import { TextLoader } from "../components/loaders/text-loader"
import { AtsIntegrationJobListLayout } from "../components/layouts/ats-integration-job-list-layout"
import { useStore } from "../lib/store"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { atsApi, AtsApiKeys } from "../lib/ats-api"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { showErrorAlert } from "../lib/alerts"
import { useQueryClient } from "react-query"
import { api } from "../lib/moberries-api"
import { refreshAtsConnection } from "../controllers/ats-controller"

export const AtsMergeJobsPage = () => {
  const [selectedJobId, selectJobId] = useState(null)

  const { jobSyncType, matchExportType } = useStore(
    state => state.atsSettingsFormValues,
  )
  const isConnectionFlowInProgress = useStore(
    state => state.isAtsConnectionFlowInProgress,
  )

  const queryClient = useQueryClient()
  const history = useHistory()

  const isJobSyncManual = jobSyncType === ATS_JOB_SYNC_OPTIONS.MANUAL
  const isJobSyncAuto = jobSyncType === ATS_JOB_SYNC_OPTIONS.AUTO

  const nextStepUrl =
    isConnectionFlowInProgress && isJobSyncManual
      ? "/integrations/ats/import"
      : "/jobs"

  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const provider = useStore(state => state.connectedProvider)

  const {
    data: unintegratedMoJobs,
    isLoading: isLoadingUnintegratedMoJobs,
    headers: unintegratedMoJobHeaders,
  } = useAxiosQuery([AtsApiKeys.UnintegratedMoJobs], atsApi.getUnintegratedJobs)

  const { isLoading: isLoadingJobInts, headers: jobIntegrationsHeaders } =
    useAxiosQuery(
      [
        AtsApiKeys.JobIntegrations,
        {
          companyIntegrationId: atsCompanyIntegration?.id,
        },
      ],
      atsApi.getAtsJobIntegrationList,
    )

  const {
    mutateAsync: updateCompanyIntegration,
    isLoading: updatingCompanySettings,
  } = useAxiosMutation(atsApi.updateAtsCompanyIntegration, {
    onSuccess: async () => {
      await refreshAtsConnection()
      history.push(nextStepUrl)
      finishConnectionFlow()
    },
    onError: err => {
      showErrorAlert({ err })
    },
  })

  const { mutateAsync: updateJob, isLoading: isUpdatingJob } = useAxiosMutation(
    api.updateJob,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AtsApiKeys.UnintegratedMoJobs])
        queryClient.invalidateQueries([AtsApiKeys.JobIntegrations])
      },
      onError: err => {
        showErrorAlert({ err })
      },
    },
  )

  if (!atsCompanyIntegration || !provider || !jobSyncType || !matchExportType) {
    return <Redirect to="/integrations/ats" />
  }

  let description = <TextLoader />

  if (!isLoadingUnintegratedMoJobs && unintegratedMoJobs.length > 0) {
    description = isJobSyncManual ? (
      <FormattedMessage id="app.page.integrations.merge.description.forManualJobSync" />
    ) : (
      <FormattedMessage id="app.page.integrations.merge.description.forAutoJobSync" />
    )
  } else if (!isLoadingUnintegratedMoJobs && unintegratedMoJobs.length === 0) {
    description = (
      <FormattedMessage id="app.page.integrations.merge.complete.confirmation" />
    )
  }

  const submitMergePage = async () => {
    if (isJobSyncAuto) {
      await updateCompanyIntegration({
        id: atsCompanyIntegration.id,
        jobSyncType,
        matchExportType,
      })
    } else {
      await refreshAtsConnection()
      history.push(nextStepUrl)
    }
  }

  const isLoadingCounters = isLoadingUnintegratedMoJobs || isLoadingJobInts

  const isNextButtonDisabled =
    (!isLoadingUnintegratedMoJobs &&
      unintegratedMoJobs.length > 0 &&
      isJobSyncAuto) ||
    updatingCompanySettings

  const finishConnectionFlow = () => {
    useStore.setState({
      isAtsConnectionFlowInProgress: false,
    })
  }

  return (
    <AtsIntegrationJobListLayout provider={provider}>
      <AtsMergeJobModal
        job={unintegratedMoJobs?.find(job => job.id === selectedJobId)}
        selectedJobId={selectedJobId}
        onClose={() => selectJobId(null)}
      />

      <div className="rounded bg-white ats-jobs-page-container d-flex flex-column">
        <div className="px-3 pt-3">
          {isLoadingCounters ? (
            <TextLineLoader width={200} />
          ) : (
            <Fragment>
              <FormattedMessage
                id="app.page.integrations.import.imported"
                values={{ count: jobIntegrationsHeaders?.["x-total-count"] }}
              />
              <span className="mx-1">|</span>
              <FormattedMessage
                id="app.page.integrations.merge.notConnectedYet"
                values={{ count: unintegratedMoJobHeaders?.["x-total-count"] }}
              />
            </Fragment>
          )}
        </div>
        <h5 className="mt-4 px-3">
          <FormattedMessage id="app.page.integrations.merge.header" />
        </h5>
        <div className="px-3">{description}</div>
        <div className="pt-2 px-3 overflow-auto flex-grow-1 h-25">
          {isLoadingUnintegratedMoJobs ? (
            <BulletListLoader count={6} />
          ) : (
            (unintegratedMoJobs || []).map(job => (
              <AtsMergeSingleJob
                key={job.id}
                job={job}
                onPause={async () => {
                  await updateJob({ id: job.id, job: { status: PAU } })
                }}
                onMerge={() => selectJobId(job.id)}
                isDisabled={isUpdatingJob || Boolean(selectedJobId)}
                isLoading={isUpdatingJob}
                isPausable={isJobSyncAuto}
              />
            ))
          )}
        </div>
        <div className="p-3 border-top d-flex justify-content-end flex-shrink-0">
          <Button
            color="primary"
            disabled={isNextButtonDisabled}
            loading={updatingCompanySettings}
            onClick={submitMergePage}
          >
            <FormattedMessage id="app.common.next" />
          </Button>
        </div>
      </div>
    </AtsIntegrationJobListLayout>
  )
}
