import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { FormattedMessage } from "react-intl"
import { TabContent, Col } from "reactstrap"
import { SingleColumnLayout } from "./single-column-layout"
import { InnerFormLayout } from "./inner-form-layout"
import { Img } from "../common/img-component"
import { useStore } from "../../lib/store"

export const JobCreatePageLayout = ({ step, children }) => {
  const company = useStore(state => state.company)
  return (
    <SingleColumnLayout>
      <h1 className="text-center mb-4">
        <FormattedMessage id="app.job.form.create.header" />
      </h1>
      <div className="d-flex bg-white p-3 rounded align-items-center mb-4">
        <Img
          src={company.logo}
          alt={company.name}
          width="60"
          height="60"
          className="mr-3"
        />
        <div className="flex-grow-1">
          <h4 className="mb-0">{company.name}</h4>
        </div>
      </div>
      <div className="d-flex text-center font-weight-bold text-muted mx-0 reg-steps">
        <Col className={cn("py-2 px-0", { active: step === 0 })}>
          <FormattedMessage id="app.job.form.create.tab.description" />
        </Col>
        <Col className={cn("py-2 px-0", { active: step === 1 })}>
          <FormattedMessage id="app.job.form.create.tab.details" />
        </Col>
      </div>
      <div className="bg-white px-3 py-5">
        <InnerFormLayout>
          <TabContent>{children}</TabContent>
        </InnerFormLayout>
      </div>
    </SingleColumnLayout>
  )
}

JobCreatePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
}
