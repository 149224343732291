import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { EmailTemplateModal } from "../modals/email-template-modal"
import { createReferralLink, getFullName } from "../../lib/helpers"
import { useStore } from "../../lib/store"
import { useOpenClose } from "../../hooks/use-open-close"
import { useTemporaryState } from "../../hooks/use-temporary-state"
import { Input } from "../common/input-component"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Button } from "../common/button-component"

export const MonetizationInvite = () => {
  const manager = useStore(state => state.manager)
  const refLink = createReferralLink(manager?.company?.id)
  const {
    value: templateIsOpen,
    open: openTemplate,
    close: closeTemplate,
    toggle: toggleTemplate,
  } = useOpenClose({ initialValue: false })

  const [isCopied, setIsCopied] = useTemporaryState(false, 2000)

  return (
    <Fragment>
      <div className="pt-3">
        <Input
          className="mb-3"
          type="text"
          label={
            <strong>
              <FormattedMessage id="app.widget.talentPool.url" />:
            </strong>
          }
          input={{ defaultValue: refLink }}
          meta={{}}
          readOnly
        />
      </div>
      <div className="d-flex justify-content-between">
        <CopyToClipboard text={refLink}>
          <Button
            color="secondary"
            onClick={() => setIsCopied(true)}
            disabled={isCopied}
          >
            {isCopied ? (
              <FormattedMessage id="app.common.copied" />
            ) : (
              <FormattedMessage id="app.common.copy" />
            )}
          </Button>
        </CopyToClipboard>
        <Button color="secondary" onClick={openTemplate}>
          <FormattedMessage id="app.widget.talentPool.email.template.title" />
        </Button>
      </div>
      <EmailTemplateModal
        isOpen={templateIsOpen}
        close={closeTemplate}
        toggle={toggleTemplate}
        inviteUrl={refLink}
        managerName={getFullName(manager)}
        companyName={manager?.company?.name}
      />
    </Fragment>
  )
}
