import React, { Fragment } from "react"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { FormattedMessage } from "react-intl"
import { ManagerEditPageSkeleton } from "../components/skeletons/manager-edit-page-skeleton"
import { useOpenClose } from "../hooks/use-open-close"
import { Button } from "../components/common/button-component"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { api, ApiKeys } from "../lib/moberries-api"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { useHistory, useRouteMatch } from "react-router"
import { Formik, Form } from "formik"
import { ManagerFormFields } from "../components/managers/manager-form-fields"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { USER_GROUPS } from "../constants"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { useQueryClient } from "react-query"
import { updateManagerSchema } from "../schemas/manager-schemas"
import { useStore } from "../lib/store"

const toValues = manager => ({
  firstName: manager.firstName,
  lastName: manager.lastName,
  email: manager.email,
  isAdmin: manager.groups.includes(USER_GROUPS.SUPER_GROUP),
  teams: manager.teams.map(d => d.id),
})

const toRequest = managerId => formValues => {
  const requestData = {
    ...formValues,
    groups: [USER_GROUPS.MANAGER],
  }
  if (formValues.isAdmin) {
    requestData.groups.push(USER_GROUPS.SUPER_GROUP)
  }

  return {
    id: managerId,
    data: requestData,
  }
}

export const ManagerEditPage = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const me = useStore(state => state.manager)

  const {
    toggle: toggleManagerRemovingModal,
    open: openManagerRemovingModal,
    close: closeManagerRemovingModal,
    value: isManagerRemovingModalOpen,
  } = useOpenClose({ initialValue: false })

  const { params } = useRouteMatch()

  const { data: { results: teams = [] } = {}, isFetched: areTeamsFetched } =
    useAxiosQuery([ApiKeys.Teams], api.getCompanyTeamList)

  const { data: manager, isFetched: isManagerFetched } = useAxiosQuery(
    [ApiKeys.Manager, { id: params.id }],
    api.getManager,
  )

  const { mutateAsync: updateManager, isLoading: isUpdating } =
    useAxiosMutation(api.updateManager, {
      onSuccess: () => {
        history.push("/company/managers")
        queryClient.refetchQueries([ApiKeys.Managers, { id: params.id }])
      },
    })

  const { mutateAsync: deleteManager, isLoading: isDeleting } =
    useAxiosMutation(api.deleteManager, {
      onSuccess: () => {
        history.push("/company/managers")
      },
    })

  if (!isManagerFetched || !areTeamsFetched) {
    return (
      <ManagerEditPageSkeleton
        title={<FormattedMessage id="app.page.managers.edit.header" />}
        count={4}
      />
    )
  }

  const readOnlyFields = ["firstName", "lastName", "email"]
  if (manager.id === me.id) {
    readOnlyFields.push("isAdmin")
  }

  return (
    <Fragment>
      <Modal
        isOpen={isManagerRemovingModalOpen}
        toggle={toggleManagerRemovingModal}
        centered
      >
        <ModalHeader toggle={toggleManagerRemovingModal}>
          <FormattedMessage id="app.common.confirm" />
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <FormattedMessage id="app.page.managers.remove.confirmation" />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={isDeleting}
            loading={isDeleting}
            onClick={() =>
              deleteManager({
                id: manager.id,
                redirectTo: "/company/managers",
              })
            }
          >
            <FormattedMessage id="app.common.confirm" />
          </Button>
          <Button
            color="light"
            disabled={isDeleting}
            onClick={closeManagerRemovingModal}
          >
            <FormattedMessage id="app.common.cancel" />
          </Button>
        </ModalFooter>
      </Modal>

      <Fragment>
        <SmallSingleColumnLayout>
          <h1 className="text-center mb-4">
            <FormattedMessage id="app.page.managers.edit.header" />
          </h1>
          <div className="bg-white py-5 px-3">
            <SmallInnerFormLayout>
              <Formik
                validationSchema={updateManagerSchema}
                initialValues={toValues(manager)}
                onSubmit={submitAndValidate(
                  updateManager,
                  toRequest(manager.id),
                )}
                enableReinitialize
              >
                <Form>
                  <ManagerFormFields
                    readOnlyFields={readOnlyFields}
                    availableTeams={teams}
                  />
                  <div className="d-flex flex-column flex-sm-row align-items-stretch mt-5">
                    <Button
                      disabled={isUpdating}
                      loading={isUpdating}
                      className="mb-2 mb-sm-0"
                      color="primary"
                      type="submit"
                      size="lg"
                      block
                    >
                      <FormattedMessage id="app.common.save" />
                    </Button>
                    {me.id !== manager.id && (
                      <Button
                        disabled={isDeleting}
                        loading={isDeleting}
                        className="ml-sm-4 mt-0"
                        color="danger"
                        type="button"
                        size="lg"
                        block
                        onClick={openManagerRemovingModal}
                      >
                        <FormattedMessage id="app.common.remove" />
                      </Button>
                    )}
                  </div>
                </Form>
              </Formik>
            </SmallInnerFormLayout>
          </div>
        </SmallSingleColumnLayout>
      </Fragment>
    </Fragment>
  )
}
