import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  errorInterceptor,
} from "./axios-interceptors"
import { getAuthorization } from "./helpers/api-helpers"
import { store } from "./store"

class BotsApi {
  constructor() {
    const { jwt } = store.getState()

    this.http = axios.create({
      baseURL: config.moberries.bot.url,
      timeout: 30000,
      headers: {
        Authorization: getAuthorization(jwt),
      },
    })
    axiosRetry(this.http, { retries: 3 })

    this.http.interceptors.response.use(response => response, errorInterceptor)
    this.http.interceptors.response.use(saveOriginalInterceptor)
    this.http.interceptors.response.use(camelizeInterceptor)
  }

  setDefaultHeader({ header, value }) {
    this.http.defaults.headers[header] = value
  }

  createSlackIntegration = ({ code, state, token }) =>
    this.http.post("/webhook/slack/oauth", { code, state, token })
}

const api = new BotsApi()

store.subscribe(({ jwt }) =>
  api.setDefaultHeader(
    { header: "Authorization", value: getAuthorization(jwt) },
    store => store.jwt,
  ),
)

export { api }
