import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { PopoverBody, FormGroup, Input } from "reactstrap"
import { InfoHint } from "../common/info-hint-component"
import { Button } from "../common/button-component"
import { useOpenClose } from "../../hooks/use-open-close"
import { LabelWithHint } from "../common/label-with-hint-component"
import { useFormikField } from "../../hooks/use-formik-field"
import { components as defaultComponents } from "react-select"
import FormFeedback from "../common/form-feedback"
import { Select } from "../common/select-component-new"

const AtsSelectWrapper = ({ children }) => (
  <FormGroup className="flex-grow-1">{children}</FormGroup>
)

AtsSelectWrapper.propTypes = {
  children: PropTypes.node,
}

export const AtsSelect = ({
  providerSlug,
  opts,
  name,
  isUpdating,
  updateField,
  readOnly,
  editable,
  isRequired,
  ...rest
}) => {
  const [field, meta, actions] = useFormikField({ name, ...rest })

  const {
    value: isEditing,
    open,
    close,
  } = useOpenClose({
    initialValue: false,
  })
  const handleUpdateField = data => {
    updateField(data)
    close()
  }
  let icon = null
  if (isUpdating && isEditing) {
    icon = <i className="fas fa-circle-notch fa-spin" />
  } else {
    icon = isEditing ? (
      <FormattedMessage id="app.common.save" />
    ) : (
      <i className="fas fa-pen" />
    )
  }

  return (
    <Fragment>
      <LabelWithHint
        label={
          <FormattedMessage
            id={`app.page.integrations.connect.${providerSlug}.${name}.label`}
          />
        }
        hint={
          <InfoHint
            id={`${providerSlug}-hint-${name}`}
            popover={
              <PopoverBody>
                <FormattedMessage
                  id={`app.page.integrations.connect.${providerSlug}.${name}.hint`}
                />
              </PopoverBody>
            }
          />
        }
        className="mb-2"
      />
      <div className="d-flex align-items-end">
        <FormGroup className="flex-grow-1">
          <Select
            noOptionsMessage={() => (
              <FormattedMessage id="app.common.type.to.search" />
            )}
            name={name}
            className={"flex-grow-1"}
            classNamePrefix="Select"
            components={{
              DropdownIndicator: defaultComponents.DropdownIndicator,
              MenuList: defaultComponents.MenuList,
              IndicatorSeparator: null,
            }}
            invalid={meta.touched && Boolean(meta.error)}
            touched={meta.touched}
            isDisabled={readOnly && !isEditing}
            readOnly={readOnly && !isEditing}
            isSearchable={false}
            options={opts.enum.map(value => ({ value }))}
            backspaceRemovesValue={false}
            getOptionLabel={option => option.value}
            getOptionValue={option => option.value}
            placeholder={
              opts.placeholder || <FormattedMessage id="app.common.select" />
            }
            {...rest}
            {...field}
            onBlur={actions.setTouched}
            onChange={actions.setValue}
          />
          <Input
            type="hidden"
            valid={meta.touched && !Boolean(meta.error)}
            invalid={meta.touched && Boolean(meta.error)}
          />
          <FormFeedback error={meta.error} />
        </FormGroup>
        {editable && (
          <Button
            className="align-self-start"
            onClick={() => {
              if (isEditing) {
                handleUpdateField({ [name]: field.value })
              } else {
                open()
              }
            }}
          >
            {icon}
          </Button>
        )}
      </div>
    </Fragment>
  )
}
AtsSelect.propTypes = {
  providerSlug: PropTypes.string.isRequired,
  opts: PropTypes.object,
  name: PropTypes.string.isRequired,
  updateField: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
  isUpdating: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
}
AtsSelect.defaultProps = {
  opts: {},
  editable: false,
  isRequired: false,
}
