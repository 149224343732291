import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  TabPane,
  UncontrolledPopover,
  PopoverBody,
  Container,
  Row,
} from "reactstrap"
import { ErrorPage } from "./error-page"
import { PaymentsPageLayout } from "../components/layouts/payments-page-layout"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { PaymentCardsSkeleton } from "../components/skeletons/payment-cards-skeleton-component"
import {
  getCardList,
  createCard,
  deleteCard,
} from "../actions/payment-card-actions"
import {
  countSelector,
  errorSelector,
  defaultCardSelector,
  creatingSelector,
  deletingSelector,
} from "../selectors/payment-card-selector"
import { connect } from "react-redux"
import { Button } from "../components/common/button-component"
import { FormattedMessage } from "react-intl"
import { useOpenClose } from "../hooks/use-open-close"
import { CardInfo } from "../components/payment/payment-card-info-component"
import { PaymentCardForm } from "../components/payment/payment-card-form"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { submit, isValid } from "redux-form"
import {
  isSubscriptionMain,
  isComSubscriptionCanceledPaid,
  companySubscriptionSelector,
} from "../lib/helpers"
import { usePrevious } from "react-hanger"
import { useStore } from "../lib/store"

const formName = "PaymentCardForm"

export const PaymentsCardPageComponent = ({
  defaultCard,
  count,

  getCardList,
  createCard,
  deleteCard,
  submit,

  creating,
  isValid,
  deleting,

  err,
}) => {
  useEffect(() => {
    getCardList()
  }, [getCardList])

  const {
    value: isEditing,
    close: closeEditing,
    open: openEditing,
  } = useOpenClose({
    initialValue: !defaultCard,
    enableReinitialize: true,
  })

  const prevCreating = usePrevious(creating)
  const prevCount = usePrevious(count)
  useEffect(() => {
    if (count > prevCount && !creating && prevCreating) {
      closeEditing()
    }
  }, [count, creating, prevCount, prevCreating, closeEditing])

  const stripe = useStripe()
  const elements = useElements()

  const companySubscription = useStore(companySubscriptionSelector)

  const canDelete =
    isComSubscriptionCanceledPaid(companySubscription) ||
    !isSubscriptionMain(companySubscription.subscription)

  if (err) {
    return <ErrorPage err={err} />
  }

  return (
    <PaymentsPageLayout>
      <div className="bg-white px-3 py-6">
        <SmallInnerFormLayout>
          <TabPane>
            {count === null ? (
              <PaymentCardsSkeleton />
            ) : (
              <Fragment>
                {isEditing && (
                  <Fragment>
                    <PaymentCardForm
                      form={formName}
                      onSubmit={data => {
                        createCard({ stripe, elements, ...data })
                      }}
                    />

                    <Button
                      block
                      onClick={() => {
                        submit(formName)
                      }}
                      loading={creating}
                      disabled={creating}
                      color="primary"
                    >
                      <FormattedMessage id="app.page.payments.button.submit" />
                    </Button>
                    {defaultCard && (
                      <Button
                        block
                        className="mt-2"
                        color="light"
                        type="button"
                        onClick={closeEditing}
                        disabled={creating}
                      >
                        <FormattedMessage id="app.common.cancel" />
                      </Button>
                    )}
                  </Fragment>
                )}
                {!isEditing && defaultCard && (
                  <CardInfo
                    cardType={defaultCard.brand}
                    key={defaultCard.id}
                    ownerName={defaultCard.ownerName}
                    lastNumbers={defaultCard.lastNumbers}
                    expiryDate={`${defaultCard.expMonth}/${defaultCard.expYear}`}
                  />
                )}

                {!isEditing && (
                  <Container>
                    <Row className="mb-2">
                      <Button
                        color="primary"
                        block
                        size="lg"
                        onClick={openEditing}
                      >
                        <FormattedMessage id="app.page.payments.button.replace" />
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        size="lg"
                        block
                        color="danger"
                        disabled={!canDelete || deleting}
                        onClick={() => deleteCard({ cardId: defaultCard.id })}
                        loading={deleting}
                      >
                        <span className="d-inline-block" id="deleteButton">
                          <FormattedMessage id="app.page.payments.button.delete" />
                        </span>
                      </Button>
                      {!canDelete && (
                        <UncontrolledPopover
                          placement="bottom"
                          target="deleteButton"
                          trigger="hover"
                        >
                          <PopoverBody>
                            <FormattedMessage id="app.page.payments.button.delete.hint" />
                          </PopoverBody>
                        </UncontrolledPopover>
                      )}
                    </Row>
                  </Container>
                )}
              </Fragment>
            )}
          </TabPane>
        </SmallInnerFormLayout>
      </div>
    </PaymentsPageLayout>
  )
}

PaymentsCardPageComponent.propTypes = {
  defaultCard: PropTypes.object,
  count: PropTypes.number,

  getCardList: PropTypes.func.isRequired,
  createCard: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,

  submit: PropTypes.func.isRequired,

  creating: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,

  err: PropTypes.object,
}

PaymentsCardPageComponent.defaultProps = {
  creating: false,
  isValid: false,
}

const mapDispatchToProps = { getCardList, createCard, deleteCard, submit }

const mapStateToProps = state => ({
  creating: creatingSelector(state),
  deleting: deletingSelector(state),
  defaultCard: defaultCardSelector(state),
  count: countSelector(state),
  err: errorSelector(state),
  isValid: isValid(formName)(state),
})

export const PaymentsCardPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentsCardPageComponent)
