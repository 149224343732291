import { createSelector } from "reselect"
import { differenceInDays, parseISO } from "date-fns"

export const stateSelector = state => state.survey

export const completedSelector = createSelector(
  stateSelector,
  state => state.completed,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const isClosedSelector = createSelector(
  stateSelector,
  state =>
    Boolean(state.closingTimestamp) &&
    differenceInDays(new Date(), parseISO(state.closingTimestamp)) < 30,
)
