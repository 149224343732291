import React from "react"
import ContentLoader from "react-content-loader"

export const VerticalBarChartLoader = props => (
  <ContentLoader viewBox="0 0 380 270" className="py-2" {...props}>
    <rect x="30" y="50" rx="3" ry="3" width="15" height="200" />
    <rect x="80" y="100" rx="3" ry="3" width="15" height="150" />
    <rect x="130" y="20" rx="3" ry="3" width="15" height="230" />
    <rect x="180" y="150" rx="3" ry="3" width="15" height="100" />
    <rect x="230" y="80" rx="3" ry="3" width="15" height="170" />
    <rect x="280" y="0" rx="3" ry="3" width="15" height="250" />
    <rect x="330" y="60" rx="3" ry="3" width="15" height="190" />
  </ContentLoader>
)
