import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Media } from "reactstrap"
import TimeUp from "../../assets/img/time-icon.svg"

export function SubscriptionPausedComponent() {
  return (
    <Fragment>
      <div className="mb-2 text-center">
        <Media className="bg-white border-bottom p-3 mb-4">
          <Media body>
            <Media heading>
              <FormattedMessage id="app.page.dashboard.matches.subscription.state.paused.hint.header" />
            </Media>
            <p>
              <FormattedMessage
                id="app.page.dashboard.matches.subscription.state.paused.hint.text"
                values={{
                  link: (
                    <FormattedMessage id="app.page.dashboard.matches.subscription.state.paused.hint.link">
                      {txt => <a href="mailto:am@moberries.com">{txt}</a>}
                    </FormattedMessage>
                  ),
                }}
              />
            </p>
            <Media className="mt-3 mb-3" body>
              <img
                className="img-fluid"
                src={TimeUp}
                width="200"
                alt="times-up"
              />
            </Media>
          </Media>
        </Media>
      </div>
    </Fragment>
  )
}
