import React, { Fragment, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { LabeledMultiSelectField } from "../fields/multi-select-field"
import { getLocationName } from "../../lib/helpers"
import { api as googleMapsApi } from "../../lib/google-maps-api"
import { api as moberriesApi } from "../../lib/moberries-api"

import { LabeledSwitchField, SwitchField } from "../fields/switch-field"
import { SkillsMultiSelectField } from "../fields/skills-multi-select-field"
import { useFormikContext } from "formik"
import { LabeledSelectField } from "../fields/select-field"
import { ALL_REGIONS, CAREER_LEVELS, JOB_TYPES } from "../../default-options"
import {
  CheckboxGroupField,
  LabeledCheckboxGroupField,
} from "../fields/checkbox-group-field"
import { identity } from "ramda"
import { CustomInput, FormGroup, Label, PopoverBody } from "reactstrap"
import { LabeledRangeField } from "../fields/range-field"
import { InfoHint } from "../common/info-hint-component"
import { JobRolesSelectField } from "../fields/job-roles-select-field"
import { useStore } from "../../lib/store"
import { MenuListPoweredByGoogle } from "../common/menu-list-powered-by-google"
import { JobLanguagesSelectField } from "../fields/job-languages-select-field"
import { JobGermanyOnlyCheckbox } from "./job-germany-only-checkbox"

export const JobDetailsFormFields = () => {
  const manager = useStore(state => state.manager)
  const {
    setFieldValue,
    values: {
      relocate,
      remote,
      relocationRegions,
      remoteRegions,
      salary,
      restrictRemoteToGermany,
    },
  } = useFormikContext()

  useEffect(() => {
    if (relocate) {
      setFieldValue(
        "relocationRegions",
        relocationRegions.length ? relocationRegions : ALL_REGIONS,
      )
    }
  }, [relocate, setFieldValue, relocationRegions])

  useEffect(() => {
    if (remote) {
      setFieldValue(
        "remoteRegions",
        !remoteRegions.length || restrictRemoteToGermany
          ? ALL_REGIONS
          : remoteRegions,
      )
    }
  }, [remote, setFieldValue, restrictRemoteToGermany, remoteRegions])

  useEffect(() => {
    if (!relocationRegions.length) {
      setFieldValue("relocate", false)
    }
  }, [relocationRegions.length, setFieldValue])

  useEffect(() => {
    if (!remoteRegions.length) {
      setFieldValue("remote", false)
    }
  }, [remoteRegions.length, setFieldValue])

  return (
    <Fragment>
      <LabeledMultiSelectField
        name="locations"
        id="locations"
        label={<FormattedMessage id="app.job.form.locations.label" />}
        hint={<FormattedMessage id="app.job.form.locations.hint" />}
        isRequired
        getOptionValue={option => option.placeId}
        getOptionLabel={getLocationName}
        async
        loadOptions={googleMapsApi.getCities}
        components={{ MenuList: MenuListPoweredByGoogle }}
      />
      <FormGroup>
        <Label>
          <FormattedMessage id="app.job.form.relocate.label" />
          <InfoHint
            id="relocate-hint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.job.form.relocate.hint" />
              </PopoverBody>
            }
          />
        </Label>
        <div className="mb-3">
          <SwitchField name="relocate" id="relocate" />
        </div>

        {relocate && (
          <CheckboxGroupField
            name="relocationRegions"
            options={ALL_REGIONS}
            getOptionLabel={option => (
              <FormattedMessage id={`app.common.regions.${option}`} />
            )}
            getOptionValue={identity}
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label>
          <FormattedMessage id="app.job.form.remote.label" />
          <InfoHint
            id="remote-hint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.job.form.remote.hint" />
              </PopoverBody>
            }
          />
        </Label>
        <div className="mb-3">
          <SwitchField name="remote" id="remote" />
        </div>

        {remote && (
          <>
            <JobGermanyOnlyCheckbox
              name="restrictRemoteToGermany"
              id="restrictRemoteToGermany"
            />
            {!restrictRemoteToGermany && (
              <CheckboxGroupField
                name="remoteRegions"
                options={ALL_REGIONS}
                getOptionLabel={option => (
                  <FormattedMessage id={`app.common.regions.${option}`} />
                )}
                getOptionValue={identity}
              />
            )}
          </>
        )}
      </FormGroup>

      <FormGroup>
        <Label>
          <FormattedMessage id="app.job.form.salary.specify.label" />
          <InfoHint
            id="specify-salary-hint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.job.form.salary.hint" />
              </PopoverBody>
            }
          />
        </Label>

        <CustomInput
          id="specifySalary"
          checked={salary.min > 0 && salary.max > 0}
          onChange={e => {
            if (e.target.checked) {
              setFieldValue("salary", { min: 10000, max: 250000 })
            } else {
              setFieldValue("salary", { min: 0, max: 0 })
            }
          }}
          type="switch"
        />
      </FormGroup>

      {salary.min > 0 && salary.max > 0 && (
        <LabeledRangeField
          name="salary"
          id="salary"
          label={<FormattedMessage id="app.job.form.salary.range.label" />}
          hint={<FormattedMessage id="app.job.form.salary.range.hint" />}
          minValue={10000}
          maxValue={250000}
          step={1000}
          formatLabel={val => `${val / 1000}K${val === 250000 ? "+" : ""}`}
        />
      )}

      <LabeledCheckboxGroupField
        name="jobTypes"
        label={<FormattedMessage id="app.job.form.job.types.label" />}
        hint={<FormattedMessage id="app.job.form.job.types.hint" />}
        options={JOB_TYPES}
        getOptionLabel={option => <FormattedMessage id={option.name} />}
        getOptionValue={option => option.id}
        min={1}
      />
      <LabeledSelectField
        name="careerLevel"
        id="careerLevel"
        label={<FormattedMessage id="app.job.form.experience.label" />}
        hint={
          <Fragment>
            <FormattedMessage id="app.job.form.experience.hint.title" />
            <ul>
              {[
                "app.job.form.experience.hint.student",
                "app.job.form.experience.hint.entry",
                "app.job.form.experience.hint.intermediate",
                "app.job.form.experience.hint.senior",
                "app.job.form.experience.hint.lead",
                "app.job.form.experience.hint.executive",
              ].map(id => (
                <li key={id}>
                  <FormattedMessage id={id} />
                </li>
              ))}
            </ul>
          </Fragment>
        }
        isRequired
        options={CAREER_LEVELS}
        getOptionValue={option => option.id}
        getOptionLabel={option => <FormattedMessage id={option.name} />}
      />

      <JobRolesSelectField
        name="jobRoles"
        label={<FormattedMessage id="app.job.form.job.roles.label" />}
        hint={
          <Fragment>
            <FormattedMessage id="app.job.form.job.roles.hint" />
            <br />
            <FormattedMessage id="app.job.form.job.roles.hint.missing" />{" "}
            <a
              href="https://moberries.typeform.com/to/hXBGOH"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="app.job.form.job.roles.hint.link" />
            </a>
          </Fragment>
        }
        isRequired
      />

      <SkillsMultiSelectField
        name="skills"
        id="skills"
        label={<FormattedMessage id="app.job.form.skills.label" />}
        hint={<FormattedMessage id="app.job.form.skills.hint" />}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        async
        loadOptions={async search => {
          const {
            data: { results: skills },
          } = await moberriesApi.getSkills({ params: { search } })
          return skills
        }}
      />

      <JobLanguagesSelectField name="languages" />

      <hr />

      <LabeledSelectField
        name="team"
        id="team"
        label={<FormattedMessage id="app.job.form.teams.label" />}
        isRequired
        options={manager.teams}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
      />

      <LabeledSwitchField
        name="private"
        id="private"
        label={<FormattedMessage id="app.job.form.private.label" />}
        hint={<FormattedMessage id="app.job.form.private.hint" />}
      />
    </Fragment>
  )
}
