import { stateToHTML } from "draft-js-export-html"
import htmlToDraft from "html-to-draftjs"
import { EditorState, ContentState } from "draft-js"

export function shortenText(txt, maxLength) {
  if (typeof txt !== "string") {
    return ""
  }
  if (txt.length <= maxLength || !maxLength) {
    return txt
  }

  const words = txt.substr(0, maxLength - 3).split(" ")

  if (words.length === 1) {
    return words[0] + "..."
  } else {
    return words.slice(0, words.length - 1).join(" ") + "..."
  }
}

export const editorStateToHtml = editorState =>
  stateToHTML(editorState.getCurrentContent()).replace(/<br>\n/g, "<br>")

export const htmlToEditorState = html =>
  EditorState.createWithContent(
    ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks),
  )
