import React from "react"
import { Container, Row, Col } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { MatchesChart } from "../../components/charts/matches-chart/matches-chart"
import { ActiveJobs } from "../../components/charts/active-jobs/active-jobs"
import { FeedbackInsightsChart } from "../../components/charts/feedback-chart/feedback-chart"
import { TalentAffiliation } from "../../components/charts/talent-affiliation/talent-affiliation"
import { CandidatesChart } from "../../components/charts/candidates/candidates-chart"

import styles from "./analytics-page.module.scss"

export const AnalyticsPage = () => {
  return (
    <Container className="container-analytics">
      <Row className={styles.header}>
        <Col lg="12">
          <h1>
            <FormattedMessage id="app.page.analytics.header" />
          </h1>
          <h6>
            <FormattedMessage id="app.page.analytics.subheader" />
          </h6>
        </Col>
      </Row>

      <Row className={styles.content}>
        <Col md="12" lg="6" xl="4">
          <div>
            <MatchesChart />
          </div>
          <div className="mt-3">
            <CandidatesChart />
          </div>
        </Col>

        <Col md="12" lg="6" xl="4">
          <div>
            <ActiveJobs />
          </div>
          <div className="mt-3">
            <FeedbackInsightsChart />
          </div>
        </Col>

        <Col md="12" lg="6" xl="4">
          <TalentAffiliation />
        </Col>
      </Row>
    </Container>
  )
}
