import React, { useState } from "react"

import styles from "./talent-affiliation.module.scss"
import { Lines } from "../lib/lines"
import { ChartLegend } from "../chart-legend/chart-legend"
import { ValuePicker } from "../lib/value-picker/value-picker"
import { formatMoney } from "../../../lib/helpers"
import { EmptyTalentAffiliation } from "./empty-talent-affiliation"
import {
  availableSelections,
  getLegends,
  getTalentStatsDataAndLabels,
  categoriesMapper,
  levelsMapper,
} from "./helpers"
import { LevelsCategories } from "./levels-categories"
import { FormattedMessage } from "react-intl"
import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { LoadingTalentAffiliation } from "./loading-talent-affiliation"

export const TalentAffiliation = () => {
  const [selection, setSelection] = useState(availableSelections[0])

  const { data: kpisData, isLoading: isLoadingKpisData } = useAxiosQuery(
    ApiKeys.TalentKpis,
    moberriesApi.getRewardsKpis,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  const isEmpty = kpisData?.active + kpisData?.invited <= 0

  const { data: talentRewards, isLoading: isLoadingTalentRewards } =
    useAxiosQuery(
      [
        ApiKeys.TalentRewardsTimeSeries,
        {
          end: selection.value.end.split("T")[0],
          start: selection.value.start.split("T")[0],
        },
      ],
      moberriesApi.getRewardsTimeSeries,
      {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    )
  const {
    data: talentRewardsReferrals,
    isLoading: isLoadingTalentRewardsReferrals,
  } = useAxiosQuery(
    [
      ApiKeys.TalentRewardsReferrals,
      {
        end: selection.value.end.split("T")[0],
        start: selection.value.start.split("T")[0],
      },
    ],
    moberriesApi.getRewardsReferrals,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const { data: candidatesLevelsCategories, isLoading: isLoadingCandidates } =
    useAxiosQuery(
      ApiKeys.CompanyRewardsCandidates,
      moberriesApi.getCompanyRewardsCandidates,
      {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    )

  const isLoading =
    isLoadingKpisData ||
    isLoadingTalentRewards ||
    isLoadingTalentRewardsReferrals ||
    isLoadingCandidates

  return (
    <>
      <div className={styles.title}>
        <FormattedMessage id="app.page.analytics.charts.talentAffiliation.name" />
      </div>
      {isLoading && <LoadingTalentAffiliation />}
      {isEmpty && !isLoading && <EmptyTalentAffiliation />}
      {!isEmpty && !isLoading && (
        <div className={styles.container}>
          <div>
            <div className={styles.head}>
              <h4>
                <FormattedMessage id="app.page.analytics.charts.talentAffiliation.totalRevenue" />
                :{" "}
                {formatMoney(
                  kpisData?.totalRevenue ? kpisData.totalRevenue / 100 : 0,
                )}
              </h4>
              <ValuePicker
                availableValues={availableSelections}
                selectedValue={selection}
                setSelected={setSelection}
              />
            </div>
            <p className={styles.subTitle}>
              <FormattedMessage id="app.page.analytics.charts.talentAffiliation.candidates" />
            </p>
          </div>
          <Lines
            {...getTalentStatsDataAndLabels(
              talentRewards,
              talentRewardsReferrals,
              selection,
            )}
          />
          <div className={styles.legends}>
            {getLegends(kpisData).map(legendProps => (
              <ChartLegend {...legendProps} key={legendProps.title} />
            ))}
          </div>

          <div className={styles.separator} />

          <LevelsCategories
            data={{
              categories: categoriesMapper(
                candidatesLevelsCategories?.jobCategories || [],
              ),
              levels: levelsMapper(
                candidatesLevelsCategories?.careerLevels || [],
              ),
            }}
          />
        </div>
      )}
    </>
  )
}
