import React from "react"
import { FormattedMessage } from "react-intl"
import { Link, NavLink as RLink } from "react-router-dom"
import {
  Navbar,
  Container,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"
import MBLogo from "../../assets/img/mb-logo.svg"
import defaultLogo from "../../assets/img/default-logo.png"
import { MobileNav } from "./mobile-nav"
import { FormattedLink } from "../common/formatted-link-component"
import { formatUnreadCount } from "../../lib/helpers"
import { Img } from "../common/img-component"
import { DropdownToggle } from "../common/dropdown-toggle-component"
import { useStore } from "../../lib/store"
import { logout } from "../../controllers/user-controller"

export const HeaderLoggedIn = () => {
  const companyLogo = useStore(state => state.company.logo)
  const unreadCount = useStore(state => state.manager.unreadMessages)
  return (
    <Navbar color="dark" dark expand="md" className="sticky-top">
      <Container>
        <NavbarBrand tag={Link} to="/">
          <img src={MBLogo} width="32" height="32" alt="Moberries" />
        </NavbarBrand>

        <MobileNav>
          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/"
            >
              <i className="fas fa-home mr-1" />
              <FormattedMessage id="app.navbar.dashboard" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/jobs"
            >
              <i className="fas fa-briefcase mr-1" />
              <FormattedMessage id="app.navbar.jobs" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/candidates"
            >
              <i className="fas fa-users mr-1" />
              <FormattedMessage id="app.navbar.candidates" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/messages"
            >
              <i className="fas fa-bell mr-1" />
              <FormattedMessage id="app.navbar.messages" />
              {unreadCount > 0 && (
                <span className="messenger-badge">
                  {formatUnreadCount(unreadCount)}
                </span>
              )}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/referrals"
            >
              <i className="fas fa-thumbs-up mr-1" />
              <FormattedMessage id="app.navbar.more.talentPool" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/analytics"
            >
              <i className="fas fa-analytics mr-1" />
              <FormattedMessage id="app.navbar.more.analytics.performanceSummary" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/analytics/industry-benchmarks"
            >
              <i className="fas fa-analytics mr-1" />
              <FormattedMessage id="app.navbar.more.analytics.industryBenchmarks" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/company"
            >
              <i className="fas fa-building mr-1" />
              <FormattedMessage id="app.navbar.account.company" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              to="/subscription"
            >
              <i className="fas fa-envelope mr-1" />
              <FormattedMessage id="app.common.subscription" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/account"
            >
              <i className="fas fa-cog mr-1" />
              <FormattedMessage id="app.navbar.account" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/account/credentials"
            >
              <i className="fas fa-user mr-1" />
              <FormattedMessage id="app.navbar.account.credentials" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/payments/billing"
            >
              <i className="fas fa-credit-card mr-1" />
              <FormattedMessage id="app.navbar.payments" />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={RLink}
              className="text-dark font-weight-bold"
              exact
              to="/integrations/ats"
            >
              <i className="fas fa-puzzle-piece mr-1" />
              <FormattedMessage id="app.navbar.more.integrations" />
            </NavLink>
          </NavItem>

          <NavItem>
            <FormattedLink
              className="text-dark font-weight-bold"
              href="howItWorks"
              target="_blank"
              rel="noopener noreferrer"
              as={NavLink}
            >
              <i className="fas fa-question-circle mr-1" />
              <FormattedMessage id="app.navbar.howto" />
            </FormattedLink>
          </NavItem>

          <NavItem>
            <FormattedLink
              className="text-dark font-weight-bold"
              href="support"
              target="_blank"
              rel="noopener noreferrer"
              as={NavLink}
            >
              <i className="fas fa-life-ring mr-1" />
              <FormattedMessage id="app.common.support" />
            </FormattedLink>
          </NavItem>

          <Button
            color="link"
            className="text-dark font-weight-bold nav-link align-self-start mt-auto"
            onClick={logout}
          >
            <i className="fas fa-sign-out-alt mr-1" />
            <FormattedMessage id="app.navbar.logout" />
          </Button>
        </MobileNav>

        <Collapse navbar>
          <Nav className="ml-auto font-weight-bold" navbar>
            <NavItem>
              <NavLink tag={RLink} exact to="/">
                <FormattedMessage id="app.navbar.dashboard" />
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={RLink} exact to="/jobs">
                <FormattedMessage id="app.navbar.jobs" />
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={RLink} exact to="/candidates">
                <FormattedMessage id="app.navbar.candidates" />
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                tag={RLink}
                to="/messages"
                className="d-flex align-items-center"
              >
                <FormattedMessage id="app.navbar.messages" />
                {unreadCount > 0 && (
                  <span className="messenger-badge">
                    {formatUnreadCount(unreadCount)}
                  </span>
                )}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={RLink} to="/referrals">
                <FormattedMessage id="app.navbar.more.talentPool" />
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav>
              <DropdownToggle nav caret>
                <FormattedMessage id="app.navbar.more.analytics" />
              </DropdownToggle>

              <DropdownMenu right className="bg-dark border-0">
                <DropdownItem tag={RLink} exact to="/analytics">
                  <FormattedMessage id="app.navbar.more.analytics.performanceSummary" />
                </DropdownItem>
                <DropdownItem
                  tag={RLink}
                  exact
                  to="/analytics/industry-benchmarks"
                >
                  <FormattedMessage id="app.navbar.more.analytics.industryBenchmarks" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav>
              <DropdownToggle nav caret>
                <FormattedMessage id="app.navbar.more" />
              </DropdownToggle>

              <DropdownMenu right className="bg-dark border-0">
                <DropdownItem tag={RLink} exact to="/integrations/ats">
                  <FormattedMessage id="app.navbar.more.integrations" />
                </DropdownItem>
                <DropdownItem tag={RLink} exact to="/message-templates">
                  <FormattedMessage id="app.navbar.more.messageTemplate" />
                </DropdownItem>
                <FormattedLink
                  href="guide"
                  target="_blank"
                  rel="noopener noreferrer"
                  as={DropdownItem}
                >
                  <FormattedMessage id="app.navbar.more.guide" />
                </FormattedLink>
                <FormattedLink
                  href="FAQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  as={DropdownItem}
                >
                  <FormattedMessage id="app.navbar.more.faq" />
                </FormattedLink>
                <FormattedLink
                  href="terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  as={DropdownItem}
                >
                  <FormattedMessage id="app.navbar.more.terms" />
                </FormattedLink>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav>
              <DropdownToggle nav className="p-0">
                <Img
                  className="rounded-circle _lr-hide"
                  alt="User"
                  width="40"
                  height="40"
                  defaultImage={defaultLogo}
                  src={companyLogo}
                />
              </DropdownToggle>

              <DropdownMenu right className="bg-dark border-0">
                <DropdownItem tag={RLink} exact to="/account">
                  <FormattedMessage id="app.navbar.account" />
                </DropdownItem>
                <DropdownItem tag={RLink} exact to="/subscription">
                  <FormattedMessage id="app.common.subscription" />
                </DropdownItem>
                <DropdownItem tag={RLink} exact to="/company">
                  <FormattedMessage id="app.navbar.company" />
                </DropdownItem>
                <DropdownItem tag={RLink} exact to="/payments/billing">
                  <FormattedMessage id="app.navbar.payments" />
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem className="btn-link" onClick={logout}>
                  <FormattedMessage id="app.navbar.logout" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}
