import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { Row } from "reactstrap"
import { getMessageTemplateList } from "../actions/message-template-actions"
import {
  isLoadingSelector,
  messageTemplatesSelector,
} from "../selectors/message-template-selector"
import { Button } from "../components/common/button-component"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { useOpenClose } from "../hooks/use-open-close"
import { CardListLoader } from "../components/loaders/card-list-loader"
import { MessageTemplateCard } from "../components/message-template/message-template-card"
import { isEmpty } from "ramda"
import { Img } from "../components/common/img-component"
import emptyStateImage from "../assets/img/default-empty-state.svg"
import { CreateMessageTemplateModal } from "../components/modals/create-message-template-modal"

export const MessageTemplatesPage = () => {
  const dispatch = useDispatch()
  const messageTemplates = useSelector(messageTemplatesSelector)
  const isLoading = useSelector(isLoadingSelector)
  useEffect(() => {
    dispatch(getMessageTemplateList())
  }, [dispatch])
  const {
    value: isCreateTemplateModalOpen,
    close: closeCreateTemplateModal,
    open: openCreateTemplateModal,
  } = useOpenClose({
    initialValue: false,
  })

  return (
    <Fragment>
      <SmallSingleColumnLayout>
        <Row className="justify-content-between m-0">
          <h1>
            <FormattedMessage id="app.page.messageTemplates.header" />
          </h1>
          <Button
            className="mb-3"
            color="primary"
            onClick={openCreateTemplateModal}
          >
            <div className="text-center">
              <i className="fas fa-plus-circle" />
              <span className="d-none d-sm-inline ml-sm-2">
                <FormattedMessage id="app.page.messageTemplates.button.create" />
              </span>
            </div>
          </Button>
        </Row>
        <CreateMessageTemplateModal
          isOpen={isCreateTemplateModalOpen}
          close={closeCreateTemplateModal}
          toggle={closeCreateTemplateModal}
          initialValues={{ name: "", text: "" }}
        />

        {isLoading ? (
          <CardListLoader count={5} />
        ) : (
          messageTemplates.map(template => (
            <MessageTemplateCard key={template.id} template={template} />
          ))
        )}
        {!isLoading && isEmpty(messageTemplates) && (
          <div className="pt-3">
            <p className="text-muted">
              <FormattedMessage id="app.page.messageTemplates.emptyState" />
            </p>
            <Img
              alt="Empty"
              width="600"
              className="img-fluid"
              src={emptyStateImage}
            />
          </div>
        )}
      </SmallSingleColumnLayout>
    </Fragment>
  )
}
