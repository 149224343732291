import React from "react"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { api, ApiKeys } from "../../lib/moberries-api"
import { FormattedMessage } from "react-intl"
import { SelectFilter } from "./select-filter"

export const JobRoleFilter = () => {
  const { data: { results: jobRoles = [] } = {} } = useAxiosQuery(
    [
      ApiKeys.JobRoles,
      {
        params: {
          limit: 9999,
        },
      },
    ],
    api.getJobRoles,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  return (
    <SelectFilter
      placeholder={<FormattedMessage id="app.filter.jobRole.placeholder" />}
      filterOptions={jobRoles}
      filterKey="jobRole"
      isClearable
      isSearchable
      resetFilters={["page"]}
    />
  )
}
