import React, { Fragment, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import { getCompanyJobList } from "../actions/job-actions"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import { ErrorPage } from "../pages/error-page"
import { push } from "connected-react-router"
import { DoubleColumnLayout } from "../components/layouts/double-column-layout"
import { JobCard } from "../components/job/job-card-component"
import { Pagination } from "../components/common/pagination-component"
import {
  countSelector,
  errorSelector,
  jobsSelector,
  isLoadingSelector,
} from "../selectors/job-selector"
import { Container } from "reactstrap"
import { JobListSidebar } from "../components/sidebar/job-list-sidebar.js"
import { JobListPageSkeleton } from "../components/skeletons/job-list-page-skeleton"
import qs from "qs"
import {
  orderingToJobListQueryTransformer,
  statusToQueryTransformer,
  resetPageQueryParam,
  companySubscriptionSelector,
} from "../lib/helpers"
import { CardListLoader } from "../components/loaders/card-list-loader"
import {
  getAtsCompanyIntegrationList,
  getAtsProvidersList,
} from "../actions/ats-company-integration-actions"
import { ATS_JOB_SYNC_OPTIONS, JOBS_PAGE_JOBS_LIMIT } from "../constants"
import { getAtsJobIntegrationList } from "../actions/ats-job-integration-actions"
import { atsJobIntegrationsSelector } from "../selectors/ats-job-integration-selector"
import { MatchOrJobFilter } from "../components/filters/match-or-job-filter-component"
import { TabsFilter } from "../components/filters/tabs-filter"
import emptyStateImage from "../assets/img/default-empty-state.svg"
import { useStore } from "../lib/store"

export const JobList = ({
  jobs,
  count,
  err,
  page,
  isLoading,
  location,
  push,
  atsJobIntegrations,
  getAtsJobIntegrationList,
  getAtsCompanyIntegrationList,
  getAtsProvidersList,
  getCompanyJobList,
  search,
  status,
  ordering,
  team,
}) => {
  const company = useStore(state => state.company)
  const manager = useStore(state => state.manager)
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const companySubscription = useStore(companySubscriptionSelector)

  const isAutoJobSyncEnabled =
    atsCompanyIntegration?.jobSyncType === ATS_JOB_SYNC_OPTIONS.AUTO

  const loadJobs = useCallback(() => {
    getCompanyJobList({
      companyId: company.id,
      params: {
        page,
        limit: JOBS_PAGE_JOBS_LIMIT,
        ordering: orderingToJobListQueryTransformer(ordering),
        status__in: statusToQueryTransformer(status),
        team,
        search,
      },
    })
    if (!!atsCompanyIntegration) {
      getAtsJobIntegrationList({})
    }
  }, [
    getAtsJobIntegrationList,
    company.id,
    status,
    ordering,
    team,
    page,
    getCompanyJobList,
    search,
    atsCompanyIntegration,
  ])

  useEffect(() => {
    if (!!atsCompanyIntegration) {
      getAtsCompanyIntegrationList()
      getAtsProvidersList()
    }
    loadJobs()
  }, [
    getAtsCompanyIntegrationList,
    getAtsProvidersList,
    loadJobs,
    atsCompanyIntegration,
  ])

  if (err) {
    return <ErrorPage err={err} />
  }

  if (count === null) {
    return <JobListPageSkeleton />
  }

  if (jobs.length === 0 && page > 1) {
    return (
      <Redirect
        to={{
          pathname: "/jobs",
          search: resetPageQueryParam(location.search),
        }}
      />
    )
  }

  return (
    <Fragment>
      <Container>
        <h1>
          <FormattedMessage id="app.page.jobList.header" />
        </h1>
        <p className="text-muted">
          <FormattedMessage id="app.page.jobList.subheader" />
        </p>
      </Container>
      <DoubleColumnLayout>
        <DoubleColumnLayout.Content>
          <Fragment>
            <TabsFilter
              filterOptions={[null, "paused", "review", "all"]}
              filterKey="status"
              filterName="job"
              disabled={isLoading}
              resetFilters={["page", "jobTeam", "search"]}
            />
            <MatchOrJobFilter
              isDisabled={isLoading}
              teams={manager.teams}
              orderingFilterOptions={[
                { desc: null, asc: "date" },
                { asc: "title", desc: "-title" },
              ]}
              filterName="job"
            />
            {isLoading && <CardListLoader />}
            {!isLoading &&
              count > 0 &&
              jobs.map(j => (
                <div key={j.id} className="mb-2">
                  <JobCard
                    job={j}
                    statistics={j.statistics}
                    hasAtsCompanyIntegration={Boolean(atsCompanyIntegration)}
                    isAtsJobConnected={atsJobIntegrations.some(
                      integration => integration.moberriesJob === j.id,
                    )}
                    jobIntegration={atsJobIntegrations.find(
                      jobInt => jobInt.moberriesJob === j.id,
                    )}
                  />
                </div>
              ))}
            {!isLoading && count === 0 && (
              <div className="p-3 text-center">
                <img
                  alt="Empty"
                  width={600}
                  className="img-fluid"
                  src={emptyStateImage}
                />
              </div>
            )}
            {count > JOBS_PAGE_JOBS_LIMIT && (
              <Pagination
                className="mt-3"
                listClassName="justify-content-end"
                page={page}
                count={count}
                disabled={isLoading}
                limit={JOBS_PAGE_JOBS_LIMIT}
                basePath="/jobs"
              />
            )}
          </Fragment>
        </DoubleColumnLayout.Content>
        <DoubleColumnLayout.Sidebar>
          <JobListSidebar
            company={company}
            companySubscription={companySubscription}
            onJobCreateButtonClick={() => push("/job/create")}
            isAutoJobSyncEnabled={isAutoJobSyncEnabled}
          />
        </DoubleColumnLayout.Sidebar>
      </DoubleColumnLayout>
    </Fragment>
  )
}

JobList.propTypes = {
  getCompanyJobList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number,
  jobs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.object,
  push: PropTypes.func.isRequired,
  getAtsCompanyIntegrationList: PropTypes.func.isRequired,
  getAtsProvidersList: PropTypes.func.isRequired,
  getAtsJobIntegrationList: PropTypes.func.isRequired,
  atsJobIntegrations: PropTypes.array.isRequired,
  search: PropTypes.string,
  status: PropTypes.string,
  ordering: PropTypes.string,
  team: PropTypes.number,
}

JobList.defaultProps = {
  jobs: [],
  page: 1,
  atsJobIntegrations: [],
}

const mapDispatchToProps = {
  getCompanyJobList,
  push,
  getAtsCompanyIntegrationList,
  getAtsProvidersList,
  getAtsJobIntegrationList,
}

const mapStateToProps = function (state, ownProps) {
  const {
    status,
    ordering,
    jobTeam: team,
    page,
    search,
  } = qs.parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  })
  return {
    jobs: jobsSelector(state),
    count: countSelector(state),
    isLoading: isLoadingSelector(state),
    err: errorSelector(state),
    atsJobIntegrations: atsJobIntegrationsSelector(state),
    page: page ? Number(page) : 1,
    status,
    ordering,
    team: team && Number(team),
    search,
  }
}

export const JobListPage = connect(mapStateToProps, mapDispatchToProps)(JobList)
