import React, { useEffect, useState } from "react"
import { Form, FormGroup, Label, FormText } from "reactstrap"
import { CardElement } from "@stripe/react-stripe-js"
import {
  reduxForm,
  Field,
  registerField,
  propTypes,
  change,
  formValueSelector,
} from "redux-form"
import { Input } from "../common/input-component"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { camelize } from "humps"
import { config } from "../../config"
import { paymentCardValidator } from "../../validators/payment-card-validator"

export const PaymentCardFormComponent = ({
  handleSubmit,
  form,
  submitting,
  submitFailed,
}) => {
  const dispatch = useDispatch()
  const [validationError, setValidationError] = useState(null)
  const isCardComplete = useSelector(state =>
    formValueSelector(form)(state, "isCardComplete"),
  )
  useEffect(() => {
    dispatch(registerField(form, "isCardComplete", "Field"))
  }, [dispatch, form])

  const errorCode = validationError?.code
  useEffect(() => {
    if (submitFailed && !isCardComplete && !errorCode) {
      setValidationError({ code: "incompleteNumber" })
    } else if (isCardComplete) {
      setValidationError(null)
    }
  }, [submitFailed, isCardComplete, errorCode])
  const handleStripeInputChange = e => {
    if (e?.error) {
      e.error.code = camelize(e.error.code)
      e.compelete = false
      setValidationError(e.error)
    } else {
      if (
        e.brand !== "unknown" &&
        !config.stripe.allowedCards.includes(e.brand)
      ) {
        const err = new Error("Card is not supported.")
        err.code = "notSupportedCard"
        err.type = "validation_error"

        setValidationError(err)
        e.complete = false
      } else {
        setValidationError(null)
      }
    }
    dispatch(change(form, "isCardComplete", e.complete))
  }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <h6 className="text-center">
        <FormattedMessage id="app.page.payments.card.title" />
      </h6>

      <div className="h2 text-center text-muted">
        <i className="fab fa-cc-visa px-1" />
        <i className="fab fa-cc-mastercard px-1" />
        <i className="fab fa-cc-amex px-1" />
      </div>
      <Field
        component={Input}
        readOnly={submitting}
        label={
          <span>
            <FormattedMessage id="app.page.payments.card.ownerName" />
          </span>
        }
        className="_lr-hide"
        name="holderName"
        type="text"
        id="holderName"
        placeholder="John Doe"
        isRequired
      />
      <FormGroup>
        <Label>
          <FormattedMessage id="app.page.payments.card.cardDetails" />
        </Label>
        {"*"}
        <CardElement
          options={{ disabled: submitting, hidePostalCode: true }}
          onChange={handleStripeInputChange}
        />
        {validationError && (
          <FormText color="danger">
            <FormattedMessage
              id={`app.page.payments.error.${validationError.code}`}
            />
          </FormText>
        )}
      </FormGroup>
      <Field
        disabled={submitting}
        component={Input}
        type="checkbox"
        label={<FormattedMessage id="app.page.payments.terms" />}
        id="cardTerms"
        name="cardTerms"
        isRequired
      />
    </Form>
  )
}

PaymentCardFormComponent.propTypes = {
  ...propTypes,
}

export const PaymentCardForm = reduxForm({
  validate: paymentCardValidator,
})(PaymentCardFormComponent)
