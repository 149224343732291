import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { parseISO } from "date-fns"
import { FormattedDate } from "../common/formatted-date-component"

export const DetailViewCertificateComponent = ({
  certificateName,
  roleName,
  issuingOrganization,
  issueDate,
  isMatch,
}) => (
  <div>
    <p key={certificateName} className="mt-2 text-smaller mb-3">
      <b className={cn({ "text-success": isMatch })}>
        {`${certificateName} / ${roleName}`}
      </b>
      <br />
      {issuingOrganization}
      <br />
      <FormattedDate value={parseISO(issueDate)} />
    </p>
  </div>
)

DetailViewCertificateComponent.propTypes = {
  certificateName: PropTypes.string.isRequired,
  roleName: PropTypes.string.isRequired,
  issuingOrganization: PropTypes.string.isRequired,
  issueDate: PropTypes.string.isRequired,
  isMatch: PropTypes.bool,
}
