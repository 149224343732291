import React from "react"
import { CompanyPageLayout } from "../components/layouts/company-page-layout"
import { TabPane } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import {
  getChangedFields,
  getLocationName,
  hasSuperManagerRights,
} from "../lib/helpers"
import { Form, Formik } from "formik"
import { LabeledInputField } from "../components/fields/input-field"
import { LabeledSelectField } from "../components/fields/select-field"
import { COMPANY_SIZES } from "../default-options"
import { LabeledTextareaField } from "../components/fields/textarea-field"
import { useGoogleLocationsLoader } from "../hooks/use-google-maps-places-loader"
import { MenuListPoweredByGoogle } from "../components/common/menu-list-powered-by-google"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api, ApiKeys } from "../lib/moberries-api"
import { Button } from "../components/common/button-component"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { alertTypes, showAlert } from "../lib/alerts"
import { companyProfileSchema } from "../schemas/company-schemas"
import { PictureInputField } from "../components/fields/picture-input-field"
import { pick } from "ramda"
import { useStore } from "../lib/store"
import { refreshCompany } from "../controllers/company-controller"

const toValues = company => ({
  logo: company.logo,
  name: company.name,
  city: company.city,
  street: company.street,
  postcode: company.postcode,
  phone: company.phone,
  website: company.website,
  size: COMPANY_SIZES.find(s => s.id === company.size),
  industry: company.industry,
  about: company.about,
})

const toRequest = company => values => {
  const changedFields = getChangedFields(values, company)

  return {
    company: pick(changedFields, {
      ...values,
      size: values.size.id,
    }),
  }
}

export const CompanyProfilePage = () => {
  const manager = useStore(state => state.manager)
  const company = useStore(state => state.company)
  const { loadLocations } = useGoogleLocationsLoader() // TODO: Make this better with react-query
  const isSuperManager = hasSuperManagerRights(manager)

  const { data: { results: industries = [] } = {} } = useAxiosQuery(
    ApiKeys.Industries,
    api.getIndustriesList,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const {
    mutateAsync: updateCompany,
    isLoading: isUpdating,
  } = useAxiosMutation(api.updateCompany, {
    onSuccess: () => {
      refreshCompany()
      showAlert({ code: alertTypes.success })
    },
  })

  return (
    <CompanyPageLayout isSuperManager={isSuperManager}>
      <div className="bg-white px-3 py-6">
        <SmallInnerFormLayout>
          <TabPane>
            <Formik
              initialValues={toValues(company)}
              onSubmit={submitAndValidate(updateCompany, toRequest(company))}
              validationSchema={companyProfileSchema}
            >
              <Form>
                <PictureInputField id="companyLogo" name="logo" />
                <LabeledInputField
                  label={<FormattedMessage id="app.form.name.company" />}
                  name="name"
                  id="name"
                  placeholder="MoBerries"
                  isRequired
                />

                <LabeledSelectField
                  label={<FormattedMessage id="app.form.location" />}
                  name="city"
                  id="city"
                  async
                  loadOptions={loadLocations}
                  components={{ MenuList: MenuListPoweredByGoogle }}
                  getOptionValue={option => option.placeId}
                  getOptionLabel={option => getLocationName(option)}
                  isRequired
                />

                <LabeledInputField
                  label={<FormattedMessage id="app.form.street" />}
                  name="street"
                  id="street"
                />

                <LabeledInputField
                  label={<FormattedMessage id="app.form.postcode" />}
                  name="postcode"
                  id="postcode"
                  placeholder="00000"
                />

                <LabeledInputField
                  label={<FormattedMessage id="app.form.phone" />}
                  name="phone"
                  id="phone"
                  placeholder="+49 000 000 00 00"
                  isRequired
                />

                <LabeledInputField
                  label={<FormattedMessage id="app.common.website" />}
                  name="website"
                  id="website"
                  placeholder="https://example.com"
                  isRequired
                />
                <LabeledSelectField
                  label={<FormattedMessage id="app.form.company.size" />}
                  name="size"
                  id="size"
                  options={COMPANY_SIZES}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  isSearchable={false}
                  isRequired
                />
                <LabeledSelectField
                  label={<FormattedMessage id="app.form.company.industry" />}
                  name="industry"
                  id="industry"
                  options={industries}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.description}
                />

                <LabeledTextareaField
                  label={<FormattedMessage id="app.form.about" />}
                  name="about"
                  id="about"
                  minRows={4}
                  maxRows={15}
                />
                <Button
                  disabled={isUpdating}
                  loading={isUpdating}
                  block
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  <FormattedMessage id="app.common.save" />
                </Button>
              </Form>
            </Formik>
          </TabPane>
        </SmallInnerFormLayout>
      </div>
    </CompanyPageLayout>
  )
}
