import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Input } from "../common/input-component"

export const AtsDisconnectModal = ({ isOpen, onConfirm, onClose }) => {
  const [confirmationText, setConfirmationText] = useState("")
  const REQUIRED_CONFIRMATION_TEXT = "DISCONNECT"
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        <FormattedMessage id="app.common.confirm" />
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <FormattedMessage id="app.page.integrations.settings.disconnect.modal.confirmation" />
        </div>
        <Input
          input={{
            value: confirmationText,
            onChange: e => setConfirmationText(e.target.value),
          }}
          meta={{}}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          <FormattedMessage id="app.common.cancel" />
        </Button>
        <Button
          color="danger"
          onClick={onConfirm}
          disabled={confirmationText !== REQUIRED_CONFIRMATION_TEXT}
        >
          <FormattedMessage id="app.common.confirm" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

AtsDisconnectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

AtsDisconnectModal.defaultProps = {
  isOpen: false,
}
