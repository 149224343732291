import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ErrorPage } from "./error-page"
import { DoubleColumnLayout } from "../components/layouts/double-column-layout"
import {
  getApplicationsStatistics,
  getFeedbackSummary,
} from "../actions/match-actions"
import { List } from "../components/loaders/list-loader"
import {
  pipe,
  prop,
  map,
  sum,
  sort,
  prepend,
  isEmpty,
  always,
  pluck,
} from "ramda"

import qs from "qs"
import { useLocation } from "react-router"
import { FormattedMessage, FormattedDate } from "react-intl"
import { Container, Row, Col } from "reactstrap"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { SelectFilter } from "../components/filters/select-filter"
import {
  subMonths,
  eachMonthOfInterval,
  isThisMonth,
  parseISO,
  format,
  max,
  endOfMonth,
  compareDesc,
  min,
} from "date-fns"
import { StatisticsData } from "../components/sidebar/statistics-data-component"
import { StatisticsDataRow } from "../components/sidebar/statistics-data-row-component"
import {
  feedbackSummarySelector,
  isFeedbackSummaryLoadingSelector,
  isApplicationsStatisticsLoadingSelector,
  errorSelector,
  applicationsStatisticsSelector,
} from "../selectors/match-selector"
import { divideSafe, isGreaterThanZero } from "../lib/helpers"
import { useStore } from "../lib/store"

const defaultOption = {
  id: null,
  name: <FormattedMessage id="app.filter.applicationsStatistics.default" />,
}

const STATISTICS_COLOR_MAP = {
  applicationCount: "text-primary",
  declinationCount: "text-gray",
}

const getTotal = pipe(map(prop("feedbacksTotal")), sum)
const getSkillsTotal = pipe(map(prop("count")), sum)
const dateToParam = date => format(date, "yyyy-MM-dd")
const toOption = startOfMonth => ({
  id: dateToParam(startOfMonth),
  name: isThisMonth(startOfMonth) ? (
    <FormattedMessage id="app.filter.applicationsStatistics.current" />
  ) : (
    <FormattedDate value={startOfMonth} month="long" />
  ),
})

export const FeedbackOverviewPageComponent = ({
  err,
  getApplicationsStatistics,
  getFeedbackSummary,
  feedbackSummary,
  isFeedbackSummaryLoading,
  isApplicationsStatisticsLoading,
  applicationsStatistics,
}) => {
  const company = useStore(state => state.company)
  const now = new Date()
  const filterOptions = pipe(
    eachMonthOfInterval,
    sort(compareDesc),
    map(toOption),
    prepend(defaultOption),
  )({ start: max([subMonths(now, 6), parseISO(company.createdAt)]), end: now })

  const location = useLocation()
  const { startDate } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const endDate =
    startDate && dateToParam(min([now, endOfMonth(parseISO(startDate))]))

  useEffect(() => {
    getApplicationsStatistics({ params: { startDate, endDate } })
  }, [startDate, endDate, getApplicationsStatistics])

  useEffect(() => {
    getFeedbackSummary()
  }, [getFeedbackSummary])

  if (err) {
    return <ErrorPage err={err} />
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <Col lg="8">
            <h1>
              <FormattedMessage id="app.page.candidatesFeedback.header" />
            </h1>
            <p className="text-muted">
              <FormattedMessage id="app.page.candidatesFeedback.subheader" />
            </p>
          </Col>
        </Row>
      </Container>
      <DoubleColumnLayout>
        <DoubleColumnLayout.Content>
          <Container>
            <Row className="bg-white pt-3 pb-2 rounded">
              <Col>
                <h5>
                  <div className="icon-circled mr-2">
                    <i className="fas fa-chart-bar" />
                  </div>
                  <FormattedMessage id="app.page.candidatesFeedback.statistics.timeline.title" />
                </h5>
              </Col>
              <Col xs={4}>
                <SelectFilter
                  filterKey="startDate"
                  filterName="applicationsStatistics"
                  disabled={isApplicationsStatisticsLoading}
                  filterOptions={filterOptions}
                  isClearable={false}
                  isSearchable={false}
                />
              </Col>
            </Row>
          </Container>
          <div className="bg-white text-muted position-relative pt-3">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={applicationsStatistics?.map((statistics, i) => ({
                  ...statistics,
                  nth: i + 1,
                }))}
                margin={{
                  top: 20,
                  right: 15,
                  left: -26,
                  bottom: 20,
                }}
              >
                <CartesianGrid horizontal={false} vertical={false} />
                <XAxis
                  axisLine={false}
                  interval={0}
                  tickLine={false}
                  dataKey="nth"
                  padding={{ left: 11 }}
                />
                <YAxis
                  axisLine={false}
                  allowDecimals={false}
                  tickSize={18}
                  tick={{ dy: -10, dx: 2, textAnchor: "start" }}
                />
                <Tooltip
                  content={({ payload }) => {
                    if (
                      payload &&
                      pipe(pluck("value"), sum, isGreaterThanZero)(payload)
                    ) {
                      return (
                        <div className="bg-white border p-3">
                          {payload.map(({ name, value }) => (
                            <div
                              key={name}
                              className={STATISTICS_COLOR_MAP[name]}
                            >
                              <FormattedMessage
                                id={`app.page.candidatesFeedback.statistics.timeline.tooltip.${name}`}
                                values={{ count: value }}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    }
                    return null
                  }}
                  labelFormatter={always(null)}
                  cursor={false}
                />
                <Bar dataKey="applicationCount" stackId="a" fill="#20a4f3" />
                <Bar dataKey="declinationCount" stackId="a" fill="#6c757d" />
              </BarChart>
            </ResponsiveContainer>
            {isApplicationsStatisticsLoading && (
              <div className="loading-overlay">
                <i className="fas fa-circle-notch fa-2x fa-spin text-primary" />
              </div>
            )}
          </div>
          <div className="bg-white p-3 rounded mt-3">
            <h5 className="mb-3">
              <div className="icon-circled mr-2">
                <i className="fas fa-building" />
              </div>
              <FormattedMessage id="app.page.candidatesFeedback.statistics.companyRelated.title" />
            </h5>

            {isFeedbackSummaryLoading ? (
              <div className="bg-white p-3 rounded">
                <List />
              </div>
            ) : (
              feedbackSummary.companyReasons?.map(reason => (
                <div key={reason.value} className="bg-white">
                  <div className="d-flex justify-content-between py-2 border-top">
                    <div className="font-weight-bold">
                      <FormattedMessage
                        id={`app.page.candidatesFeedback.statistics.companyRelated.${reason.value}.title`}
                      />
                    </div>
                    <div>
                      <span className="text-muted text-smaller mr-2">
                        {Math.round(
                          divideSafe(
                            reason.feedbacksTotal,
                            getTotal(feedbackSummary.companyReasons),
                          ) * 100,
                        )}
                        %
                      </span>
                      <b>{reason.feedbacksTotal}</b>
                    </div>
                  </div>
                  <div className="pb-3">
                    <FormattedMessage
                      id={`app.page.candidatesFeedback.statistics.companyRelated.${reason.value}.description`}
                    />
                  </div>
                </div>
              ))
            )}
            {!isFeedbackSummaryLoading &&
              isEmpty(feedbackSummary.companyReasons) && (
                <div className="border-top pt-3">
                  <FormattedMessage id="app.page.candidatesFeedback.statistics.emptyState" />
                </div>
              )}
          </div>
        </DoubleColumnLayout.Content>
        <DoubleColumnLayout.Sidebar>
          <div className="bg-white p-3 rounded mb-3">
            <h5 className="mb-3">
              <div className="icon-circled mr-2">
                <i className="fas fa-briefcase" />
              </div>
              <FormattedMessage id="app.page.candidatesFeedback.statistics.jobRelated.title" />
            </h5>
            {isFeedbackSummaryLoading ? (
              <div className="bg-white p-3 rounded">
                <List />
              </div>
            ) : (
              <StatisticsData>
                {feedbackSummary.jobReasons.map(reason => (
                  <StatisticsDataRow
                    key={reason.value}
                    labelKey={`app.page.candidatesFeedback.statistics.jobRelated.${reason.value}`}
                    value={reason.feedbacksTotal}
                    ratio={divideSafe(
                      reason.feedbacksTotal,
                      getTotal(feedbackSummary.jobReasons),
                    )}
                  />
                ))}
              </StatisticsData>
            )}
            {!isFeedbackSummaryLoading && isEmpty(feedbackSummary.jobReasons) && (
              <div className="bg-white pt-3">
                <FormattedMessage id="app.page.candidatesFeedback.statistics.emptyState" />
              </div>
            )}
          </div>

          <div className="bg-white p-3 rounded mb-3">
            <h5 className="mb-3">
              <div className="icon-circled mr-2">
                <i className="fas fa-briefcase" />
              </div>
              <FormattedMessage id="app.page.candidatesFeedback.statistics.skillsRelated.title" />
            </h5>
            {isFeedbackSummaryLoading ? (
              <div className="bg-white p-3 rounded">
                <List />
              </div>
            ) : (
              <StatisticsData>
                {feedbackSummary.missingSkills?.map(reason => (
                  <StatisticsDataRow
                    key={reason.name}
                    labelKey={reason.name}
                    value={reason.count}
                    ratio={divideSafe(
                      reason.count,
                      getSkillsTotal(feedbackSummary.missingSkills),
                    )}
                  />
                ))}
              </StatisticsData>
            )}
            {!isFeedbackSummaryLoading &&
              isEmpty(feedbackSummary.missingSkills) && (
                <div className="bg-white pt-3">
                  <FormattedMessage id="app.page.candidatesFeedback.statistics.emptyState" />
                </div>
              )}
          </div>
        </DoubleColumnLayout.Sidebar>
      </DoubleColumnLayout>
    </Fragment>
  )
}

const mapDispatchToProps = {
  getApplicationsStatistics,
  getFeedbackSummary,
}

const mapStateToProps = state => ({
  feedbackSummary: feedbackSummarySelector(state),
  isFeedbackSummaryLoading: isFeedbackSummaryLoadingSelector(state),
  isApplicationsStatisticsLoading:
    isApplicationsStatisticsLoadingSelector(state),
  err: errorSelector(state),
  applicationsStatistics: applicationsStatisticsSelector(state),
})

FeedbackOverviewPageComponent.propTypes = {
  getApplicationsStatistics: PropTypes.func.isRequired,
  getFeedbackSummary: PropTypes.func.isRequired,
  feedbackSummary: PropTypes.object.isRequired,
  isFeedbackSummaryLoading: PropTypes.bool.isRequired,
  applicationsStatistics: PropTypes.array.isRequired,
  isApplicationsStatisticsLoading: PropTypes.bool.isRequired,
  err: PropTypes.object,
}
FeedbackOverviewPageComponent.defaultProps = {
  availableJobSlots: 0,
}

export const FeedbackOverviewPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeedbackOverviewPageComponent)
