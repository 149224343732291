import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { NavLink as RLink } from "react-router-dom"
import { Container, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import { SmallSingleColumnLayout } from "./small-single-column-layout"

export const PaymentsPageLayout = props => {
  return (
    <Fragment>
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage id="app.page.payments.layout.header" />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <Nav tabs justified className="settings-tabs">
          <NavItem>
            <NavLink
              tag={RLink}
              to="/payments/billing"
              exact
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.payments.layout.tabs.billing" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              to="/payments/card"
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.payments.layout.tabs.cards" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              to="/payments/invoices"
              className="font-weight-bold px-0"
            >
              <FormattedMessage id="app.page.company.layout.tabs.invoices" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent>{props.children}</TabContent>
      </SmallSingleColumnLayout>
    </Fragment>
  )
}

PaymentsPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
