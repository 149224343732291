import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useOpenClose } from "../../hooks/use-open-close"
import { Button } from "../common/button-component"
import { JobApplicationQuestionsForm } from "../forms/job-application-questions-form"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { api, ApiKeys } from "../../lib/moberries-api"
import { assocPath } from "ramda"
import Proptypes from "prop-types"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { submitAndValidate } from "../../lib/helpers/form-helpers"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { useQueryClient } from "react-query"
import {
  applicationQuestionTypeToOption,
  arePredefinedAnswersRequired,
  reorder,
} from "../../lib/helpers"
import { CardListLoader } from "../loaders/card-list-loader"

const questionToFormValues = ({
  questionType,
  title,
  predefinedAnswers,
  id,
  isRequired,
}) => ({
  questionType: applicationQuestionTypeToOption(questionType),
  title,
  predefinedAnswers,
  id,
  isRequired,
})

const formToCreateRequest =
  ({ jobId }) =>
  ({ questionType, predefinedAnswers, title, isRequired }) => ({
    job: jobId,
    questionType: questionType.value,
    predefinedAnswers: arePredefinedAnswersRequired(questionType.value)
      ? predefinedAnswers.map(({ answer }, index) => ({
          answer,
          order: index,
        }))
      : [],
    title,
    isRequired,
  })

const formToEditRequest =
  ({ jobId }) =>
  ({ questionType, predefinedAnswers, title, id, isRequired }) => ({
    job: jobId,
    questionType: questionType.value,
    title,
    id,
    predefinedAnswers: predefinedAnswers.map(({ id, answer }, index) => ({
      id,
      answer,
      order: index,
    })),
    isRequired,
  })

export const JobApplicationQuestions = ({ jobId }) => {
  const [editedQuestion, setEditedQuestion] = useState(null)
  const closeQuestionEdit = () => setEditedQuestion(null)
  const {
    value: isQuestionCreationOpen,
    open: openQuestionCreation,
    close: closeQuestionCreation,
  } = useOpenClose({ initialValue: false })

  const queryClient = useQueryClient()
  const applicationQuestionsQueryKey = [
    ApiKeys.ApplicationQuestions,
    { params: { job: jobId } },
  ]
  const {
    data: { results: applicationQuestions = [] } = {},
    isFetched: areApplicationQuestionsFetched,
  } = useAxiosQuery(applicationQuestionsQueryKey, api.getApplicationQuestions)

  const { mutateAsync: createApplicationQuestion } = useAxiosMutation(
    api.createApplicationQuestion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(applicationQuestionsQueryKey)
        closeQuestionCreation()
      },
    },
  )

  const { mutateAsync: updateApplicationQuestion } = useAxiosMutation(
    api.updateApplicationQuestion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(applicationQuestionsQueryKey)
        closeQuestionEdit()
      },
    },
  )

  const { mutate: reorderApplicationQuestionsMutation } = useAxiosMutation(
    api.updateApplicationQuestion,
    {
      onMutate: async ({ position, id }) => {
        // https://react-query.tanstack.com/guides/optimistic-updates
        await queryClient.cancelQueries(applicationQuestionsQueryKey)
        const applicationQuestionsData = queryClient.getQueryData(
          applicationQuestionsQueryKey,
        )
        const applicationQuestions = applicationQuestionsData.data.results

        const nextApplicationQuestions = reorder({
          array: applicationQuestions,
          startIndex: applicationQuestions.find(q => q.id === id).position,
          endIndex: position,
        })

        queryClient.setQueryData(
          applicationQuestionsQueryKey,
          assocPath(
            ["data", "results"],
            nextApplicationQuestions,
            applicationQuestionsData,
          ),
        )
        return {
          previousApplicationQuestionsData: applicationQuestionsData,
        }
      },
      onSuccess: () => {
        queryClient.refetchQueries(applicationQuestionsQueryKey)
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          applicationQuestionsQueryKey,
          context.previousApplicationQuestionsData,
        )
      },
    },
  )

  if (editedQuestion) {
    return (
      <JobApplicationQuestionsForm
        initialValues={questionToFormValues(editedQuestion)}
        onSubmit={submitAndValidate(
          updateApplicationQuestion,
          formToEditRequest({ jobId }),
        )}
        onCancel={closeQuestionEdit}
      />
    )
  }
  if (isQuestionCreationOpen) {
    return (
      <JobApplicationQuestionsForm
        initialValues={{ job: jobId, predefinedAnswers: [] }}
        onSubmit={submitAndValidate(
          createApplicationQuestion,
          formToCreateRequest({ jobId }),
        )}
        onCancel={closeQuestionCreation}
      />
    )
  }
  return (
    <div>
      <h3>
        <FormattedMessage id="app.page.jobEdit.tabs.questions" />
      </h3>
      {!areApplicationQuestionsFetched && <CardListLoader count={5} />}
      {applicationQuestions.length === 0 && areApplicationQuestionsFetched && (
        <FormattedMessage id="app.job.questions.empty.text" />
      )}
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          if (!destination || destination.index === source.index) {
            return
          }
          reorderApplicationQuestionsMutation({
            position: destination.index,
            id: applicationQuestions[source.index].id,
          })
        }}
      >
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {applicationQuestions?.map((question, index) => {
                return (
                  <Draggable
                    key={question.id}
                    draggableId={String(question.id)}
                    index={index}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={question.id}
                        className="border p-3 d-flex justify-content-between bg-white mb-2 rounded"
                      >
                        <i
                          {...provided.dragHandleProps}
                          className="fas fa-bars text-muted align-self-center"
                        />
                        <div className="px-2"> {question.title}</div>

                        <i
                          className="fas fa-pen clickable text-muted align-self-center"
                          onClick={() => setEditedQuestion(question)}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        onClick={openQuestionCreation}
        color="primary"
        block
        className="mt-3"
      >
        <FormattedMessage id="app.job.questions.button.newQuestion" />
        <i className="fas fa-plus ml-2" />
      </Button>
    </div>
  )
}

JobApplicationQuestions.propTypes = { jobId: Proptypes.number.isRequired }
