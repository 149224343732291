export function anonymizeCandidate(candidate) {
  if (!candidate) return
  return {
    id: candidate.id,
  }
}

export function anonymizeJob(job) {
  if (!job) return
  return {
    id: job.id,
    company: anonymizeCompany(job.company),
  }
}

export function anonymizeMatch(match) {
  if (!match) return
  return {
    id: match.id,
    job: anonymizeJob(match.job),
    candidate: anonymizeCandidate(match.candidate),
  }
}

export function anonymizeCompany(company) {
  if (!company) return
  return {
    id: company.id,
  }
}

export function anonymizeManager(manager) {
  if (!manager) return
  return {
    id: manager.id,
  }
}
