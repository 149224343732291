import { getActionType } from "../lib/helpers"
import { GET, CANDIDATE, LIST, START, SUCCESS, FAIL } from "../constants"
import { showErrorAlert } from "../lib/alerts"

export function getCandidate({ id }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, CANDIDATE, START) })

    try {
      const { data: candidate } = await moApi.getCandidate({ id })

      dispatch({
        type: getActionType(GET, CANDIDATE, SUCCESS),
        payload: { candidate },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, CANDIDATE, FAIL),
        payload: { err },
      })
    }
  }
}

export function getCandidateList({ companyId, params }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, CANDIDATE, LIST, START) })

    try {
      const res = await moApi.getCandidateList({
        companyId,
        params: { ordering: "-completed_at", ...params },
      })

      const { results: candidates, count } = res.data

      dispatch({
        type: getActionType(GET, CANDIDATE, LIST, SUCCESS),
        payload: { candidates, count },
      })
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(GET, CANDIDATE, LIST, FAIL),
        payload: { err },
      })
    }
  }
}
