import React, { Fragment } from "react"
import { DoubleSwitchField } from "../fields/double-switch-field"
import { FormattedMessage } from "react-intl"
import {
  ATS_APPLICATION_SYNC_OPTIONS,
  ATS_JOB_SYNC_OPTIONS,
} from "../../constants"

export const AtsOptionsFields = () => {
  return (
    <Fragment>
      <div className="p-3 border-bottom">
        <DoubleSwitchField
          name="jobSyncType"
          label={
            <FormattedMessage id="app.page.integrations.settings.jobSyncType.header" />
          }
          subLabel={
            <FormattedMessage id="app.page.integrations.settings.jobSyncType.subheader" />
          }
          leftSwitch={{
            value: ATS_JOB_SYNC_OPTIONS.MANUAL,
            label: (
              <FormattedMessage id="app.page.integrations.settings.jobsImport.manual" />
            ),
            hint: (
              <FormattedMessage id="app.page.integrations.settings.jobSyncType.manual.hint" />
            ),
          }}
          rightSwitch={{
            value: ATS_JOB_SYNC_OPTIONS.AUTO,
            label: (
              <FormattedMessage id="app.page.integrations.settings.jobsImport.auto" />
            ),
            hint: (
              <FormattedMessage id="app.page.integrations.settings.jobSyncType.auto.hint" />
            ),
          }}
        />
        <DoubleSwitchField
          name={"matchExportType"}
          label={
            <FormattedMessage id="app.page.integrations.settings.matchExportType.header" />
          }
          subLabel={
            <FormattedMessage id="app.page.integrations.settings.matchExportType.subheader" />
          }
          leftSwitch={{
            value: ATS_APPLICATION_SYNC_OPTIONS.ON_COM_ACCEPT,
            label: (
              <FormattedMessage id="app.page.integrations.settings.matchExportType.onComAccept.label" />
            ),
            hint: (
              <FormattedMessage id="app.page.integrations.settings.matchExportType.onComAccept.hint" />
            ),
          }}
          rightSwitch={{
            value: ATS_APPLICATION_SYNC_OPTIONS.ON_CAN_ACCEPT,
            label: (
              <FormattedMessage id="app.page.integrations.settings.matchExportType.onCanAccept.label" />
            ),
            hint: (
              <FormattedMessage id="app.page.integrations.settings.matchExportType.onCanAccept.hint" />
            ),
          }}
        />
      </div>
    </Fragment>
  )
}
