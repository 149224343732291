import { timeRangeMap } from "../lib/helpers"
import { datasetTimeRangeMapper } from "../lib/mappers"
import { clone } from "ramda"

export const categoryColors = ["#E6413C", "#FC7808", "#EACB17", "#589452"]

export const getLegends = data => [
  {
    color: "#615A97",
    title: "app.page.analytics.charts.talentAffiliation.legends.invited",
    value: data?.invited || 0,
  },
  {
    color: "#4C7D90",
    title: "app.page.analytics.charts.talentAffiliation.legends.active",
    value: data?.active || 0,
  },
  {
    color: "#FF8C00",
    title: "app.page.analytics.charts.talentAffiliation.legends.inProcess",
    value: data?.inProcess || 0,
  },
  {
    color: "#997765",
    title: "app.page.analytics.charts.talentAffiliation.legends.hired",
    value: data?.hired || 0,
  },
]

const careerLevelMap = {
  entry: {
    title: "app.common.career.level.entry",
    color: "#FC7808",
  },
  intermediate: {
    title: "app.common.career.level.intermediate",
    color: "#4E4584",
  },
  senior: {
    title: "app.common.career.level.senior",
    color: "#E6413C",
  },
}

export const availableSelections = timeRangeMap

export const getTalentStatsDataAndLabels = (
  talentRewards = [],
  talentRewardsReferrals = [],
  selectedTimeRange,
) => {
  const invited = datasetTimeRangeMapper(
    talentRewardsReferrals,
    selectedTimeRange,
    "invited",
  )
  const active = datasetTimeRangeMapper(
    talentRewardsReferrals,
    selectedTimeRange,
    "active",
  )
  const inProcess = datasetTimeRangeMapper(
    talentRewards,
    selectedTimeRange,
    "leads",
  )
  const hired = []

  return {
    data: [invited.data, active.data, inProcess.data, hired],
    labels: invited.labels,
  }
}

export const categoriesMapper = (data = []) => {
  const total = data.reduce((ac, cat) => cat.count + ac, 0)
  const categories = data
    .map(cat => ({
      title: cat.name,
      value: (cat.count * 100) / total,
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 4)
    .map((cat, i) => ({ ...cat, color: categoryColors[i] }))
  return categories
}

export const levelsMapper = (careerLevels = []) => {
  const levels = clone(careerLevelMap)
  careerLevels.forEach(c => {
    switch (c.level) {
      case 0:
      case 1:
        levels.entry.count = (levels.entry.count || 0) + c.count
        break
      case 2:
        levels.intermediate.count = (levels.intermediate.count || 0) + c.count
        break
      case 3:
      case 4:
      case 5:
        levels.senior.count = (levels.senior.count || 0) + c.count
        break
      default:
    }
  })
  const levelsArrWithCount = Object.values(levels)
  const totalCount = levelsArrWithCount.reduce(
    (ac, l) => ac + (l.count || 0),
    0,
  )
  const levelsArrWithPercentage = levelsArrWithCount.map(
    ({ count, ...level }) => ({ ...level, value: (count * 100) / totalCount }),
  )
  return levelsArrWithPercentage.filter(l => l.value)
}
