import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { groupBy, isEmpty, map } from "ramda"
import humps from "humps"
import { FormattedMessage } from "react-intl"
import { getSkillsDiff, shouldRenderDeclineFeedback } from "../../lib/helpers"
import { CheckboxGroup } from "../common/checkbox-group-component"
import { FEEDBACK_SETS, FEEDBACK_COMPACT_VIEW_OPTIONS } from "../../constants"
import { MatchRolesComparison } from "./match-roles-comparison-component"
import { MatchLanguagesComparison } from "./match-languages-comparison-component"
import { MatchSkillsComparison } from "./match-skills-comparison-component"
import { MatchJobClaimsComparison } from "./match-job-claims-comparison-component"
import { MatchLocationComparison } from "./match-location-comparison-component"
import { Textarea } from "../common/textarea-component"
import { Button } from "../common/button-component"
import { useFeedbackChoices } from "../../hooks/use-feedback-choices"
import { useList } from "../../hooks/use-list"
import { useOpenClose } from "../../hooks/use-open-close"
import { DetailView } from "../detail-view/detail-view-component"
import { DetailViewSection } from "../detail-view/detail-view-section-component"

export const MatchDeclineFeedback = ({
  match,
  candidate,
  job,
  closeDeclineFeedback,
  declineMatch,
  declining,
}) => {
  const { value: isCompactViewOpen, toggle: toggleCompactView } = useOpenClose({
    initialValue: true,
  })

  let feedbackChoices = useFeedbackChoices(
    FEEDBACK_SETS.COMPANY_DECLINE_REASONS,
  )

  feedbackChoices = mapFeedbackChoices(feedbackChoices)

  const otherReasonId = feedbackChoices.find(r => r.value === "other")?.id

  if (isCompactViewOpen) {
    feedbackChoices = feedbackChoices.filter(item =>
      FEEDBACK_COMPACT_VIEW_OPTIONS.includes(item.value),
    )
  }

  const feedbackChoicesGroups = groupBy(fc => fc.category, feedbackChoices)

  const { missing: missingSkillsOptions } = getSkillsDiff(
    candidate.skills,
    job.skills,
  )

  const [comment, setComment] = useState("")

  const {
    value: selectedFeedbackChoices,
    set: setSelectedFeedbackChoices,
    push: addSelectedFeedbackChoices,
  } = useList([])

  const { value: selectedMissingSkills, set: setSelectedMissingSkills } =
    useList([])

  if (!shouldRenderDeclineFeedback(match)) {
    return null
  }

  return (
    <div className="p-3 bg-light border border-left-0 border-right-0">
      <h4>
        <FormattedMessage id="app.feedback.match.decline.header" />
      </h4>

      {isCompactViewOpen ? (
        <Fragment>
          <DetailView colWidth={12}>
            <Row>
              <Col md={6}>
                <CheckboxGroup
                  id="feedbackLeft"
                  input={{
                    value: selectedFeedbackChoices,
                    onChange: setSelectedFeedbackChoices,
                  }}
                  meta={{}}
                  options={feedbackChoices.slice(0, 4)}
                  className="mb-0"
                />
              </Col>
              <Col md={6}>
                <CheckboxGroup
                  id="feedbackRight"
                  input={{
                    value: selectedFeedbackChoices,
                    onChange: setSelectedFeedbackChoices,
                  }}
                  meta={{}}
                  options={feedbackChoices.slice(4, 8)}
                  className="mb-0"
                />
              </Col>
            </Row>
            {!isEmpty(missingSkillsOptions) && (
              <DetailViewSection
                title={
                  <FormattedMessage id="app.feedback.match.decline.skills" />
                }
                hint={
                  <FormattedMessage id="app.feedback.match.decline.skills.popover" />
                }
              >
                <div className="mt-2">
                  <CheckboxGroup
                    id="skills"
                    input={{
                      value: selectedMissingSkills,
                      onChange: setSelectedMissingSkills,
                    }}
                    meta={{}}
                    renderLabel={({ name }) => name}
                    options={missingSkillsOptions}
                  />
                </div>
              </DetailViewSection>
            )}
          </DetailView>

          <Row className="my-3 border-top border-bottom">
            <Button
              color="link"
              className="p-3 m-auto"
              onClick={toggleCompactView}
            >
              <FormattedMessage id="app.feedback.match.decline.allReasons.button" />
            </Button>
          </Row>
        </Fragment>
      ) : (
        <Fragment>
          <DetailView colWidth={12}>
            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.workExperience" />
              }
              hint={
                <FormattedMessage id="app.feedback.match.decline.workExperience.popover" />
              }
            >
              <Row>
                <Col md={6}>
                  <MatchRolesComparison candidate={candidate} job={job} />
                </Col>
                <Col md={6} className="pt-3">
                  <CheckboxGroup
                    id="experience"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.experience}
                  />
                </Col>
              </Row>
            </DetailViewSection>
            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.skills" />
              }
              hint={
                <FormattedMessage id="app.feedback.match.decline.skills.popover" />
              }
            >
              <Row>
                <Col md={6} className="pt-3">
                  <MatchSkillsComparison candidate={candidate} job={job} />
                </Col>
                <Col md={6} className="pt-3">
                  <CheckboxGroup
                    id="skills"
                    input={{
                      value: selectedMissingSkills,
                      onChange: setSelectedMissingSkills,
                    }}
                    meta={{}}
                    renderLabel={({ name }) => name}
                    options={missingSkillsOptions}
                  />
                </Col>
              </Row>
            </DetailViewSection>
            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.neededLanguages" />
              }
              hint={
                <FormattedMessage id="app.feedback.match.decline.neededLanguages.popover" />
              }
            >
              <Row>
                <Col md={6}>
                  <MatchLanguagesComparison candidate={candidate} job={job} />
                </Col>
                <Col md={6} className="pt-3">
                  <CheckboxGroup
                    id="language"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.language}
                  />
                </Col>
              </Row>
            </DetailViewSection>
            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.jobClaims" />
              }
              hint={
                <FormattedMessage id="app.feedback.match.decline.jobClaims.popover" />
              }
            >
              <Row>
                <Col md={6}>
                  <MatchJobClaimsComparison candidate={candidate} job={job} />
                </Col>
                <Col md={6} className="pt-2">
                  <CheckboxGroup
                    id="job-claims"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.jobClaims}
                  />
                </Col>
              </Row>
            </DetailViewSection>
            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.location" />
              }
              hint={
                <FormattedMessage id="app.feedback.match.decline.location.popover" />
              }
            >
              <Row>
                <Col md={6}>
                  <MatchLocationComparison candidate={candidate} job={job} />
                </Col>
                <Col md={6} className="pt-2">
                  <CheckboxGroup
                    id="location"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.location}
                  />
                </Col>
              </Row>
            </DetailViewSection>
            <DetailViewSection
              title={<FormattedMessage id="app.feedback.match.decline.cv" />}
            >
              <Row>
                <Col md={6} className="text-smaller text-muted pt-2">
                  <FormattedMessage id="app.feedback.match.decline.cv.text" />
                </Col>
                <Col md={6} className="pt-2">
                  <CheckboxGroup
                    id="cv"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.cv}
                  />
                </Col>
              </Row>
            </DetailViewSection>
            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.education" />
              }
            >
              <Row>
                <Col md={6} className="text-smaller text-muted pt-2">
                  <FormattedMessage id="app.feedback.match.decline.education.text" />
                </Col>
                <Col md={6} className="pt-2">
                  <CheckboxGroup
                    id="education"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.education}
                  />
                </Col>
              </Row>
            </DetailViewSection>

            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.availability" />
              }
            >
              <Row>
                <Col md={6} className="text-smaller text-muted pt-2">
                  <FormattedMessage id="app.feedback.match.decline.availability.text" />
                </Col>
                <Col md={6} className="pt-2">
                  <CheckboxGroup
                    id="can_availability"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.canAvailability}
                  />
                </Col>
              </Row>
            </DetailViewSection>

            <DetailViewSection
              title={
                <FormattedMessage id="app.feedback.match.decline.otherReasons" />
              }
            >
              <Row>
                <Col md={6} className="text-smaller text-muted pt-2">
                  <FormattedMessage id="app.feedback.match.decline.otherReasons.text" />
                </Col>
                <Col md={6} className="pt-2">
                  <CheckboxGroup
                    id="can_availability"
                    input={{
                      value: selectedFeedbackChoices,
                      onChange: setSelectedFeedbackChoices,
                    }}
                    meta={{}}
                    options={feedbackChoicesGroups.otherReasons}
                  />
                </Col>
              </Row>
            </DetailViewSection>
          </DetailView>

          <Row className="mb-3 border-top border-bottom">
            <Button
              color="link"
              className="p-3 m-auto"
              onClick={toggleCompactView}
            >
              <FormattedMessage id="app.feedback.match.decline.fewerReasons.button" />
            </Button>
          </Row>
        </Fragment>
      )}

      <Textarea
        label={<FormattedMessage id="app.feedback.match.decline.comment" />}
        className="bg-white"
        type="text"
        meta={{}}
        minRows={3}
        input={{
          value: comment,
          onChange: e => {
            setComment(e.target.value)
            if (
              e.target.value.length >= 3 &&
              !selectedFeedbackChoices.includes(otherReasonId)
            ) {
              addSelectedFeedbackChoices(otherReasonId)
            }
          },
        }}
      />

      <Button
        className="mr-1"
        color="secondary"
        disabled={declining}
        outline
        onClick={closeDeclineFeedback}
      >
        <FormattedMessage id="app.common.cancel" />
      </Button>
      <Button
        color="success"
        onClick={() =>
          declineMatch({
            id: match.id,
            companyId: match.job.company.id,
            answers: selectedFeedbackChoices,
            skills: selectedMissingSkills,
            comment: comment,
          })
        }
        loading={declining}
        disabled={
          declining ||
          (selectedFeedbackChoices.length < 1 &&
            selectedMissingSkills.length < 1) ||
          isCommentMissing({
            selectedFeedbackChoices,
            selectedMissingSkills,
            feedbackChoicesGroups,
            comment,
            otherReasonId,
          })
        }
      >
        <FormattedMessage id="app.common.confirm" />
      </Button>
    </div>
  )
}

MatchDeclineFeedback.propTypes = {
  match: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
  closeDeclineFeedback: PropTypes.func.isRequired,
  declineMatch: PropTypes.func.isRequired,
  declining: PropTypes.bool.isRequired,
}

MatchDeclineFeedback.defaultProps = {
  declining: false,
}

function isCommentMissing({
  selectedFeedbackChoices,
  selectedMissingSkills,
  feedbackChoicesGroups,
  comment,
  otherReasonId,
}) {
  if (!feedbackChoicesGroups || !feedbackChoicesGroups.otherReasons) {
    return true
  }

  if (selectedFeedbackChoices.length > 1) {
    return false
  }

  if (
    selectedFeedbackChoices.length === 1 &&
    selectedMissingSkills.length > 0
  ) {
    return false
  }

  const otherSelected = selectedFeedbackChoices.includes(otherReasonId)

  if (otherSelected && comment === "") {
    return true
  }
  return false
}

function mapFeedbackChoices(feedbackChoices) {
  return map(
    fc => ({
      id: fc.id,
      value: fc.value,
      name: `app.feedback.match.decline.${humps.camelize(fc.value)}`,
      category: humps.camelize(fc?.category?.value),
    }),
    feedbackChoices,
  )
}
