import React from "react"
import PropTypes from "prop-types"
import {
  isComSubscriptionPaused,
  isComSubscriptionCanceled,
  isComSubscriptionExpired,
} from "../../lib/helpers"
import { SubscriptionPausedComponent } from "./subscription-paused-component"
import { SubscriptionCanceled } from "./subscription-canceled-component"
import { SubscriptionExpired } from "./subscription-expired-component"

export const SubscriptionStatus = ({ companySubscription }) => {
  if (isComSubscriptionPaused(companySubscription)) {
    return <SubscriptionPausedComponent />
  }
  if (isComSubscriptionCanceled(companySubscription)) {
    return <SubscriptionCanceled companySubscription={companySubscription} />
  }
  if (isComSubscriptionExpired(companySubscription)) {
    return <SubscriptionExpired companySubscription={companySubscription} />
  }
  return null
}

SubscriptionStatus.propTypes = {
  companySubscription: PropTypes.object.isRequired,
}
