import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Select } from "./select-component-new"
import { Button } from "./button-component"

export const MultiSelect = ({
  isDisabled,
  value,
  onChange,
  getOptionLabel,
  getOptionValue,
  limit,
  ...rest
}) => {
  return (
    <Fragment>
      <Select
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled || (limit && value.length >= limit)}
        controlShouldRenderValue={false}
        onChange={option => onChange([...value, option])}
        filterOption={option =>
          !value.map(getOptionValue).includes(option.value)
        }
        {...rest}
      />
      {value.length > 0 && (
        <div className="tags-wrapper mt-2">
          {value.map(option => (
            <Button
              onClick={() => {
                const newValue = value.filter(
                  v => getOptionValue(v) !== getOptionValue(option),
                )
                onChange(newValue)
              }}
              key={getOptionValue(option)}
              disabled={isDisabled}
              color="success-light"
              size="sm"
            >
              {getOptionLabel(option)}
              <i className="fas fa-times pl-2 text-muted" />
            </Button>
          ))}
        </div>
      )}
    </Fragment>
  )
}

MultiSelect.propTypes = {
  limit: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
}

MultiSelect.defaultProps = {
  limit: null,
  isDisabled: false,
}
