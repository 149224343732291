import React, { memo } from "react"
import PropTypes from "prop-types"
import ContentLoader from "react-content-loader"
import { times } from "ramda"

export const TextLoader = memo(({ rows, ...other }) => {
  const WIDTH = 400
  const LINE_HEIGHT = 6

  return (
    <div className="w-100">
      <ContentLoader
        viewBox={`0 0 ${WIDTH} ${rows * 2 * LINE_HEIGHT}`}
        {...other}
      >
        {times(
          i => (
            <rect
              key={i}
              x="0"
              y={i * LINE_HEIGHT * 2}
              rx="3"
              ry="3"
              width={WIDTH / 2 + (WIDTH / 2) * Math.random()}
              height={LINE_HEIGHT}
            />
          ),
          rows,
        )}
      </ContentLoader>
    </div>
  )
})

TextLoader.propTypes = {
  rows: PropTypes.number,
}

TextLoader.defaultProps = {
  rows: 6,
}
