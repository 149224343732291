import React, { Fragment, useEffect } from "react"
import { InputField } from "../fields/input-field"
import { FormattedMessage } from "react-intl"
import { FormGroup, Label } from "reactstrap"
import { useField } from "formik"
import { usePrevious } from "react-hanger"

export const VatInputField = props => {
  const [, , { setValue }] = useField(props)
  const [{ value }] = useField("isVatChecked")

  const prevValue = usePrevious(value)

  useEffect(() => {
    if (value !== prevValue) {
      setValue("")
    }
  }, [value, prevValue, setValue])

  return (
    <Fragment>
      <FormGroup>
        <div className="custom-control custom-checkbox">
          <InputField type="checkbox" id="isVatChecked" name="isVatChecked" />
          <Label for={"isVatAvailable"}>
            {<FormattedMessage id="app.form.vat" />}
          </Label>
        </div>
      </FormGroup>
      <FormGroup>
        <InputField
          disabled={!value}
          type="text"
          name="vatId"
          id="vatId"
          placeholder="DE123456789"
        />
      </FormGroup>
    </Fragment>
  )
}
