import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
import cn from "classnames"

const TalentsYouMissWidget = ({ children }) => children
const VirtualRecruiterWidget = ({ children }) => children
const SharedTalentNetworkWidget = ({ children }) => children

export const TripleColumnLayoutDashboard = ({
  children,
  noGutters,
  rowGutters,
  colGutters,
}) => {
  return (
    <Container className="container-dashboard">
      <Row className={rowGutters} noGutters={noGutters}>
        <Col className={cn(colGutters, "pl-0")} md="12" lg="4">
          {React.Children.toArray(children).find(
            c => c.type === TalentsYouMissWidget,
          )}
        </Col>
        <Col className={colGutters} md="12" lg="4">
          {React.Children.toArray(children).find(
            c => c.type === VirtualRecruiterWidget,
          )}
        </Col>
        <Col className={cn(colGutters, "pr-0")} md="12" lg="4">
          {React.Children.toArray(children).find(
            c => c.type === SharedTalentNetworkWidget,
          )}
        </Col>
      </Row>
    </Container>
  )
}

TripleColumnLayoutDashboard.TalentsYouMissWidget = TalentsYouMissWidget
TripleColumnLayoutDashboard.VirtualRecruiterWidget = VirtualRecruiterWidget
TripleColumnLayoutDashboard.SharedTalentNetworkWidget =
  SharedTalentNetworkWidget

TripleColumnLayoutDashboard.propTypes = {
  children: PropTypes.node.isRequired,
  noGutters: PropTypes.bool,
  rowGutters: PropTypes.string,
  colGutters: PropTypes.string,
}

TalentsYouMissWidget.propTypes = {
  children: PropTypes.node.isRequired,
}
VirtualRecruiterWidget.propTypes = {
  children: PropTypes.node.isRequired,
}
SharedTalentNetworkWidget.propTypes = {
  children: PropTypes.node.isRequired,
}
