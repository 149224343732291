import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import cn from "classnames"
import {
  Input,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardDeck,
  CardLink,
} from "reactstrap"
import { Button } from "../common/button-component"
import { FormattedLink } from "../common/formatted-link-component"
import { JOB_CONDITION_TYPES } from "../../constants"
import {
  getTotalFlatSlots,
  getCurrentAvailableFlatSlots,
  isComSubscriptionCanceled,
  companySubscriptionSelector,
} from "../../lib/helpers"
import { useStore } from "../../lib/store"
import { useFormikField } from "../../hooks/use-formik-field"

export const JobConditionField = ({ name }) => {
  const [field, , actions] = useFormikField({ name })

  const companySubscription = useStore(companySubscriptionSelector)
  const totalFlatSlots = getTotalFlatSlots(companySubscription)
  const flatSlotsAvailable = getCurrentAvailableFlatSlots(companySubscription)
  return (
    <FormGroup>
      <CardDeck>
        <Card
          tag="label"
          htmlFor={`${field.name}-job-condition-pph`}
          className={cn("clickable", {
            "border-primary": field.value === JOB_CONDITION_TYPES.pph,
          })}
        >
          <CardBody className="p-3">
            <CardTitle className="mb-3">
              <FormattedMessage id="app.job.form.posting.job.unlimited" />
            </CardTitle>
            <FormGroup check>
              <Input
                type="radio"
                id={`${field.name}-job-condition-pph`}
                name="pph"
                onChange={() => actions.setValue(JOB_CONDITION_TYPES.pph)}
                checked={field.value === JOB_CONDITION_TYPES.pph}
              />
              <strong className="h5">
                <FormattedMessage id="app.job.form.create.pph" />
              </strong>
            </FormGroup>
          </CardBody>
          <div className="mt-auto px-3 pb-3">
            <FormattedLink
              className="d-inline-block py-1"
              href="payHire"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="app.job.form.posting.job.readMore" />
            </FormattedLink>
          </div>
        </Card>

        <Card
          className={cn("clickable", {
            "border-primary": field.value === JOB_CONDITION_TYPES.flat,
          })}
          tag="label"
          htmlFor={`${field.name}-job-condition-flat`}
        >
          <CardBody className="p-3">
            <CardTitle
              className={cn("mb-3", {
                "text-muted": flatSlotsAvailable <= 0,
              })}
            >
              <FormattedMessage
                id="app.job.form.create.slotsAvailable"
                values={{
                  availableSlots: isComSubscriptionCanceled(companySubscription)
                    ? 0
                    : flatSlotsAvailable,
                  totalSlots: totalFlatSlots,
                }}
              />
            </CardTitle>
            <FormGroup check>
              <Input
                disabled={
                  flatSlotsAvailable <= 0 ||
                  isComSubscriptionCanceled(companySubscription)
                }
                onChange={() => actions.setValue(JOB_CONDITION_TYPES.flat)}
                type="radio"
                name="flat"
                id={`${field.name}-job-condition-flat`}
                checked={field.value === JOB_CONDITION_TYPES.flat}
              />
              <strong
                className={cn("h5", {
                  "text-muted": flatSlotsAvailable <= 0,
                })}
              >
                <FormattedMessage id="app.job.form.create.flatSubscription" />
              </strong>
            </FormGroup>
          </CardBody>
          <div className="d-flex px-3 pb-3 justify-content-between align-items-center">
            <div className="pr-md-2">
              <FormattedLink
                className="card-link"
                href="pricing"
                as={CardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  className="card-link"
                  id="app.job.form.posting.job.readMore"
                />
              </FormattedLink>
            </div>
            <div>
              {flatSlotsAvailable <= 0 && (
                <Button
                  size="sm"
                  color="primary"
                  className="text-nowrap"
                  tag={Link}
                  to="/subscribe"
                >
                  <FormattedMessage id="app.common.upgrade.now" />
                </Button>
              )}
            </div>
          </div>
        </Card>
      </CardDeck>
    </FormGroup>
  )
}

JobConditionField.propTypes = {
  name: PropTypes.string.isRequired,
}
