import React, { Fragment } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { FormFeedback, Input } from "reactstrap"
import { config } from "../../config"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"

export const RecaptchaField = props => {
  const [, meta, actions] = useFormikField(props)
  return (
    <Fragment>
      <ReCAPTCHA
        sitekey={config.google.recaptcha.key}
        onChange={actions.setValue}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </Fragment>
  )
}
