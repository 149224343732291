import React, { Fragment, useRef, useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { FormattedMessage } from "react-intl"
import { Input } from "reactstrap"
import browserImageSize from "browser-image-size"
import AvatarEditor from "react-avatar-editor"
import { useDropzone } from "react-dropzone"
import defaultLogo from "../../assets/img/default-logo.png"
import { Button } from "../common/button-component"
import { config } from "../../config"
import { Img } from "./img-component"
import { alertTypes, showAlert } from "../../lib/alerts"
import { moErrorTypes } from "../../lib/server-errors"
import { ALERT_COLORS } from "../../constants"

const getAlertCode = rejection => {
  if (rejection.size >= config.profilePicture.limit) {
    return alertTypes.badPictureSize
  }

  if (!rejection.type.includes("image")) {
    return alertTypes.unknownPictureFormat
  }

  return moErrorTypes.defaultError
}

export const PictureInput = ({ value, onChange, id }) => {
  const [picture, setPicture] = useState(null)
  const [scale, setScale] = useState(1)

  const editorRef = useRef()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async ([file], [rejection]) => {
      if (rejection) {
        return showAlert({
          color: ALERT_COLORS.ERROR,
          code: getAlertCode(rejection),
        })
      }

      if (file) {
        const size = await browserImageSize(file)

        if (size.width < 200 || size.height < 200) {
          return showAlert({
            color: ALERT_COLORS.ERROR,
            code: alertTypes.badPictureResolution,
          })
        }
        setPicture(file)
      }
    },
    maxSize: config.profilePicture.limit,
    accept: config.profilePicture.acceptedTypes,
  })

  if (picture) {
    return (
      <Fragment>
        <div className="mb-2 d-flex flex-row justify-content-center">
          <div className="border border-succes rounded">
            <AvatarEditor
              ref={editorRef}
              image={picture}
              width={200}
              height={200}
              scale={Number(scale)}
              border={0}
              color={[255, 255, 255, 1]}
              rotate={0}
              onLoadFailure={() => {
                setPicture(null)
              }}
              id={id}
            />
          </div>
        </div>
        <div className="mb-2 d-flex justify-content-center">
          <Input
            type="range"
            min={1}
            max={3}
            step={0.1}
            style={{ width: 200 }}
            value={scale}
            onChange={({ target: { value } }) => setScale(value)}
          />
        </div>
        <div className="mb-2 d-flex justify-content-center">
          <Button
            color="danger"
            size="sm"
            type="button"
            className="mr-1"
            onClick={() => {
              setPicture(null)
              setScale(1)
            }}
          >
            <FormattedMessage id="app.common.cancel" />
          </Button>
          <Button
            onClick={() => {
              if (editorRef.current) {
                const canvas = editorRef.current.getImage()
                onChange(canvas.toDataURL("image/jpeg"))
              }

              setPicture(null)
            }}
            size="sm"
            type="button"
            color="primary"
          >
            <FormattedMessage id="app.common.crop" />
          </Button>
        </div>
      </Fragment>
    )
  } else {
    return (
      <div {...getRootProps()}>
        <div className="mb-2 d-flex flex-column align-items-center">
          <input {...getInputProps()} />
          <Img
            id={id}
            alt="Logo"
            width="200"
            height="200"
            defaultImage={defaultLogo}
            className={cn("border rounded", {
              "border-light": !isDragActive,
              "border-success": isDragActive,
            })}
            src={value}
          />
          <Button size="sm" type="button" color="primary" className="mt-2">
            <FormattedMessage id="app.common.select" />
          </Button>
        </div>
      </div>
    )
  }
}

PictureInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
}

PictureInput.defaultProps = {
  id: null,
}
