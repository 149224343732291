import { GET, COMPANY, LIST, INVOICE, START, SUCCESS, FAIL } from "../constants"
import { getActionType } from "../lib/helpers"
import { showErrorAlert } from "../lib/alerts"

export function getCompanyInvoiceList({ params } = {}) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, COMPANY, INVOICE, LIST, START) })

    try {
      const res = await moApi.getCompanyInvoiceList({
        params,
      })

      const { results: invoices, count } = res.data

      dispatch({
        type: getActionType(GET, COMPANY, INVOICE, LIST, SUCCESS),
        payload: { invoices, count },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, COMPANY, INVOICE, LIST, FAIL),
        payload: { err },
      })
    }
  }
}
