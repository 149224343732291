import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { components } from "react-select"
import { Img } from "./img-component"
import poweredByGoogle from "../../assets/img/powered-by-google-on-white-hdpi.png"

export const MenuListPoweredByGoogle = props => (
  <Fragment>
    <components.MenuList {...props} />
    {props.options.length > 0 && (
      <div className="d-flex flex-row-reverse px-2 py-2">
        <Img height="18" src={poweredByGoogle} />
      </div>
    )}
  </Fragment>
)

MenuListPoweredByGoogle.propTypes = {
  options: PropTypes.array.isRequired,
}

MenuListPoweredByGoogle.defaultProps = {
  options: [],
}
