import { getActionType } from "../lib/helpers"
import {
  GET,
  CREATE,
  DELETE,
  ATS,
  JOB,
  INTEGRATION,
  LIST,
  START,
  SUCCESS,
  FAIL,
  UPDATE,
  COMPANY,
  JOBS,
  UNINTEGRATED,
  PROBLEMS,
  FROM,
  IMPORT,
} from "../constants"
import { startSubmit, stopSubmit } from "redux-form"
import { push } from "connected-react-router"
import { moErrorTypes } from "../lib/server-errors"
import { alertTypes, showAlert, showErrorAlert } from "../lib/alerts"
import { store } from "../lib/store"

export function getAtsJobIntegrationList({ jobIds, refreshSRJobIds }) {
  return async function (dispatch, getState, { atsIntegrationApi, actions }) {
    let { atsCompanyIntegration } = store.getState()

    if (!atsCompanyIntegration) return

    dispatch({ type: getActionType(GET, ATS, JOB, INTEGRATION, LIST, START) })

    try {
      const { headers, data: integrations } =
        await atsIntegrationApi.getAtsJobIntegrationList({
          companyIntegrationId: atsCompanyIntegration.id,
          jobIds,
          refreshSRJobIds,
        })

      const count = parseInt(headers["x-total-count"], 10)

      dispatch({
        type: getActionType(GET, ATS, JOB, INTEGRATION, LIST, SUCCESS),
        payload: { integrations, count },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, ATS, JOB, INTEGRATION, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export function createAtsJobIntegration({ moberriesJobId, providerJobId }) {
  return async function (dispatch, getState, { atsIntegrationApi }) {
    dispatch({ type: getActionType(CREATE, ATS, JOB, INTEGRATION, START) })
    dispatch(startSubmit("CreateAtsJobIntegrationForm"))

    try {
      const { data } = await atsIntegrationApi.createAtsJobIntegration({
        moberriesJobId,
        providerJobId,
      })

      dispatch({
        type: getActionType(CREATE, ATS, JOB, INTEGRATION, SUCCESS),
        payload: { integration: data },
      })
      dispatch(stopSubmit("CreateAtsJobIntegrationForm"))
    } catch (err) {
      dispatch({
        type: getActionType(CREATE, ATS, JOB, INTEGRATION, FAIL),
        payload: { err },
      })
    }
  }
}

export function deleteAtsJobIntegration({ integrationId }) {
  return async function (dispatch, getState, { atsIntegrationApi }) {
    dispatch({ type: getActionType(DELETE, ATS, JOB, INTEGRATION, START) })

    try {
      const { data: integration } =
        await atsIntegrationApi.deleteAtsJobIntegration({
          integrationId,
        })

      dispatch({
        type: getActionType(DELETE, ATS, JOB, INTEGRATION, SUCCESS),
        payload: { integration },
      })
    } catch (err) {
      dispatch({
        type: getActionType(DELETE, ATS, JOB, INTEGRATION, FAIL),
        payload: { err },
      })
    }
  }
}

export function getUnintegratedJobs() {
  return async function (dispatch, getState, { atsIntegrationApi }) {
    try {
      dispatch({
        type: getActionType(ATS, GET, UNINTEGRATED, JOBS, START),
      })
      const res = await atsIntegrationApi.getUnintegratedJobs()
      const jobs = res.data
      const count = parseInt(res.headers["x-total-count"])
      dispatch({
        type: getActionType(UPDATE, COMPANY, JOBS, SUCCESS),
        payload: {
          jobs,
        },
      })
      dispatch({
        type: getActionType(ATS, GET, UNINTEGRATED, JOBS, SUCCESS),
        payload: { jobs, count },
      })
    } catch (err) {
      dispatch({
        type: getActionType(UPDATE, COMPANY, JOBS, FAIL),
        payload: { err },
      })
    }
  }
}

export function importJobsFromAts(providerJobIds) {
  return async function (dispatch, getState, { atsIntegrationApi, actions }) {
    dispatch({
      type: getActionType(IMPORT, JOBS, FROM, ATS, START),
    })

    try {
      const { data: jobs } = await atsIntegrationApi.importJobsFromAts(
        providerJobIds,
      )

      const isAtLeastOneJobNotImported = jobs.some(j => j.status !== 200)

      if (isAtLeastOneJobNotImported) {
        const err = new Error("One or more jobs haven't been imported")
        err.problem = PROBLEMS.CLIENT_ERROR
        err.code = moErrorTypes.atsJobsWereNotImported

        showErrorAlert({ err })
      } else {
        showAlert({
          code: alertTypes.atsJobsSuccessfullyImported,
        })
      }
      dispatch({
        type: getActionType(IMPORT, JOBS, FROM, ATS, SUCCESS),
        payload: { jobs },
      })

      dispatch(actions.atsJobIntegration.getAtsJobIntegrationList({}))

      dispatch(push("/jobs?status=review"))
    } catch (err) {
      dispatch({
        type: getActionType(IMPORT, JOBS, FROM, ATS, FAIL),
        payload: { err },
      })
    }
  }
}
