import React from "react"
import { FormattedMessage } from "react-intl"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout.js"
import { noAuthRequired } from "../decorators/no-auth-required"
import { Container } from "reactstrap"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { Form, Formik } from "formik"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { useLocation } from "react-router"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { api } from "../lib/moberries-api"
import { Button } from "../components/common/button-component"
import { LabeledInputField } from "../components/fields/input-field"
import { showAlert } from "../lib/alerts"
import { moErrorTypes } from "../lib/server-errors"
import { ALERT_COLORS } from "../constants"
import { emailFormSchema } from "../schemas/account-schemas"
import { BaseLayout } from "../components/layouts/base-layout.js"

export const PasswordRecovery = () => {
  const {
    mutateAsync: send,
    isSuccess: isSent,
    isLoading: isSending,
  } = useAxiosMutation(api.recoverPassword, {
    onError: err => {
      // TODO: Should be changed after the backend starts returning 200
      if (err.response?.status === 404) {
        showAlert({
          code: moErrorTypes.emailNotFound,
          color: ALERT_COLORS.ERROR,
        })
      }
    },
  })

  const location = useLocation()
  const header = (
    <Container>
      <h1 className="text-center mb-4">
        <FormattedMessage id="app.page.password.recovery.header" />
      </h1>
      <p className="text-center text-muted">
        <FormattedMessage id="app.page.password.recovery.subheader" />
      </p>
    </Container>
  )

  if (isSent) {
    return (
      <BaseLayout className="py-5">
        {header}
        <SmallSingleColumnLayout>
          <div className="bg-white px-3 py-5">
            <SmallInnerFormLayout>
              <h3 className="text-center mb-4">
                <FormattedMessage id="app.page.password.recovery.email.sent.header" />
              </h3>
              <h6 className="text-center">
                <FormattedMessage id="app.page.password.recovery.email.sent.subheader" />
              </h6>
              <img
                className="img-fluid"
                src="//assets.moberries.com/images/mail.gif"
                alt="Mail"
              />
            </SmallInnerFormLayout>
          </div>
        </SmallSingleColumnLayout>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout className="py-5">
      {header}
      <SmallSingleColumnLayout>
        <div className="bg-white px-3 py-5">
          <SmallInnerFormLayout>
            <Formik
              initialValues={{ email: location.state?.email ?? "" }}
              onSubmit={submitAndValidate(send)}
              validationSchema={emailFormSchema}
            >
              <Form>
                <LabeledInputField
                  label={<FormattedMessage id="app.common.email" />}
                  name="email"
                  type="email"
                  id="email"
                  placeholder="email@example.com"
                  isRequired
                />
                <Button
                  disabled={isSending}
                  loading={isSending}
                  block
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  <FormattedMessage id="app.common.send" />
                </Button>
              </Form>
            </Formik>
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

export const PasswordRecoveryPage = noAuthRequired(PasswordRecovery)
