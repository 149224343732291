import React, { useContext, Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { SubscriptionOrderLayout } from "../layouts/subscription-order-layout"
import { SubscriptionInfo } from "./subscription-info"
import { SubscriptionOrderContext } from "./susbscription-order-context"
import { Button } from "../common/button-component"
import {
  isComSubscriptionMainAndOpenPaid,
  isComSubscriptionCancelingPaid,
} from "../../lib/helpers"
import { AVAILABLE_DURATIONS, AVAILABLE_JOB_SLOTS } from "../../constants"
import { FormattedMessage } from "react-intl"
import { Row, Col, PopoverBody } from "reactstrap"
import { InfoHint } from "../common/info-hint-component"
import { Select } from "../common/select-component"
import { head, last } from "ramda"
import { SubscriptionCoupon } from "./subscription-coupon"
import { useScrollReset } from "../../hooks/use-scroll-reset"
import { FormattedJobSlots } from "../common/formatted-job-slots"

export const SubscriptionSelectStep = ({
  pricingRules,
  currentCompanySubscription,
}) => {
  useScrollReset()
  const { subscription, setSubscription, nextStep } = useContext(
    SubscriptionOrderContext,
  )

  let availableDurations = AVAILABLE_DURATIONS.filter(
    d => d >= pricingRules?.minDurationMonths,
  )

  let availableJobSlots = AVAILABLE_JOB_SLOTS.filter(
    js => js >= pricingRules?.minJobslotsCount,
  )

  if (
    isComSubscriptionMainAndOpenPaid(currentCompanySubscription) ||
    isComSubscriptionCancelingPaid(currentCompanySubscription)
  ) {
    availableDurations = availableDurations.filter(d => {
      return d >= currentCompanySubscription.subscription.durationIntervalCount
    })

    availableJobSlots = availableJobSlots.filter(js => {
      return js >= currentCompanySubscription.subscription.jobSlots
    })
  }

  return (
    <SubscriptionOrderLayout noGutters>
      <SubscriptionOrderLayout.Content>
        <h6 className="text-uppercase text-muted text-smaller">
          <FormattedMessage id="app.page.subscription.order.calculator.duration.label" />
        </h6>
        <Row noGutters className="mb-5">
          {availableDurations.map((d, i) => (
            <Col
              key={d}
              xs={12}
              md={6}
              className={classNames("mb-3", {
                "pr-md-3": i % 2 === 0,
                "pl-md-3": i % 2 !== 0,
              })}
            >
              <Button
                size="lg"
                block
                onClick={() => setSubscription({ duration: d })}
                className="py-3"
                color={classNames({
                  "success-light": subscription.durationIntervalCount !== d,
                  success: subscription.durationIntervalCount === d,
                })}
              >
                <span className="h5">{d}</span>
                <span className="h5 ml-1">
                  <FormattedMessage
                    id="app.common.month"
                    values={{ amount: subscription.durationIntervalCount }}
                  />
                </span>
              </Button>
            </Col>
          ))}
        </Row>
        <Row noGutters>
          <h6 className="text-uppercase text-muted text-smaller">
            <FormattedMessage id="app.page.subscription.order.calculator.job.slots.label" />
            <InfoHint
              id="jobSlotsHint"
              popover={
                <PopoverBody>
                  <FormattedMessage id="app.page.subscription.order.calculator.job.slots.hint" />
                </PopoverBody>
              }
            />
          </h6>
        </Row>
        <Row noGutters className="mb-5">
          <Col xs={12} md={6} className="d-flex flex-row pr-3">
            <Button
              className="mr-1"
              disabled={head(availableJobSlots) === subscription.jobSlots}
              onClick={() => {
                setSubscription({
                  jobSlots:
                    availableJobSlots[
                      availableJobSlots.indexOf(subscription.jobSlots) - 1
                    ],
                })
              }}
            >
              -
            </Button>
            <div className="flex-grow-1">
              <Select
                wrapper={Fragment}
                options={availableJobSlots.map(js => ({
                  value: js,
                }))}
                input={{
                  onChange: js => setSubscription({ jobSlots: js.value }),
                  value: {
                    value: subscription.jobSlots,
                  },
                }}
                format
                getOptionValue={option => option.value}
                getOptionLabel={option => (
                  <FormattedJobSlots jobSlots={option.value} />
                )}
                isSearchable={false}
                withCaret
                meta={{}}
              />
            </div>
            <Button
              className="ml-1"
              disabled={last(availableJobSlots) === subscription.jobSlots}
              onClick={() => {
                setSubscription({
                  jobSlots:
                    availableJobSlots[
                      availableJobSlots.indexOf(subscription.jobSlots) + 1
                    ],
                })
              }}
            >
              +
            </Button>
          </Col>
        </Row>
        <Row noGutters>
          <Col xs={12} md={6} className="pr-3">
            <SubscriptionCoupon />
          </Col>
        </Row>
      </SubscriptionOrderLayout.Content>
      <SubscriptionOrderLayout.Sidebar>
        <SubscriptionInfo />
        <Button onClick={nextStep} color="primary">
          <FormattedMessage id="app.common.next" />
        </Button>
      </SubscriptionOrderLayout.Sidebar>
    </SubscriptionOrderLayout>
  )
}

SubscriptionSelectStep.propTypes = {
  currentCompanySubscription: PropTypes.object,
  pricingRules: PropTypes.object,
}
