import { merge } from "ramda"
import { tint } from "tint-shade-color"
import { COLORS, STYLES } from "../constants"

export const getSelectCustomStyles = ({ invalid }) => ({
  container: (provided, state) => {
    const { isDisabled } = state

    let custom = {
      borderRadius: 2,
      transition: "box-shadow 0.15s ease-in-out",
    }

    if (isDisabled) {
      custom = merge(custom, {
        backgroundColor: COLORS.GRAY_200,
        border: STYLES.NONE,
      })
    }

    return merge(provided, custom)
  },

  control: (provided, state) => {
    const { isDisabled, isFocused, menuIsOpen } = state

    let custom = {
      borderRadius: 3,
      color: COLORS.GRAY_700,
      cursor: STYLES.POINTER,
      backgroundColor: tint(COLORS.MAASTRICHTBLUE, 0.97),
      borderColor: invalid ? COLORS.RED : tint(COLORS.MAASTRICHTBLUE, 0.9),
    }

    if (isDisabled) {
      custom = merge(custom, {
        backgroundColor: COLORS.GRAY_200,
        color: COLORS.GRAY_100,
      })
    }

    if (isFocused) {
      custom = merge(custom, {
        borderColor: invalid ? COLORS.RED : COLORS.MALIBU,
        outline: 0,
        boxShadow: invalid ? STYLES.SHADOW_RED : STYLES.SHADOW_BLUE,
      })
    }

    if (isDisabled && isFocused) {
      custom = merge(custom, {
        backgroundColor: COLORS.GRAY_200,
      })
    }

    if (menuIsOpen) {
      custom = merge(custom, {
        cursor: STYLES.DEFAULT,
      })
    }

    custom = merge(custom, {
      ":hover": {
        backgroundColor: tint(COLORS.MAASTRICHTBLUE, 0.97),
        borderColor: invalid
          ? COLORS.RED
          : isFocused
          ? COLORS.MALIBU
          : tint(COLORS.MAASTRICHTBLUE, 0.9),
      },
    })

    return merge(provided, custom)
  },
  placeholder: provided =>
    merge(provided, {
      color: COLORS.GRAY_500,
    }),
  menu: provided =>
    merge(provided, {
      borderRadius: 2,
      borderWidth: 1,
      borderStyle: STYLES.SOLID,
      borderColor: tint(COLORS.MAASTRICHTBLUE, 0.8),
      boxShadow: STYLES.SHADOW_GRAY,
      marginTop: 3,
      zIndex: 3,
      backgroundColor: tint(COLORS.MAASTRICHTBLUE, 0.97),
    }),
  menuList: provided =>
    merge(provided, {
      padding: 0,
      color: COLORS.GRAY_500,
      textAlign: STYLES.LEFT,
    }),
  option: (provided, state) => {
    const { isFocused, isSelected } = state

    let custom = {
      color: COLORS.GRAY_600,
      cursor: STYLES.POINTER,
      backgroundColor: tint(COLORS.MAASTRICHTBLUE, 0.97),
    }

    if (isFocused) {
      custom = merge(custom, {
        backgroundColor: tint(COLORS.DODGERBLUE, 0.85),
      })
    }

    if (isSelected) {
      custom = merge(custom, {
        backgroundColor: COLORS.ALICEBLUE,
        color: COLORS.GRAY_900,
      })
    }

    return merge(provided, custom)
  },
  valueContainer: provided => merge(provided, { paddingLeft: 12 }),
  indicatorsContainer: provided => merge(provided, { cursor: STYLES.POINTER }),
})
