import React, { useState } from "react"
import PropTypes from "prop-types"
import { camelize } from "humps"
import { Select } from "../common/select-component"
import { FormattedMessage } from "react-intl"
import { FEEDBACK_SETS } from "../../constants"
import { Textarea } from "../common/textarea-component"
import { ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap"
import { Button } from "../common/button-component"
import { useFeedbackChoices } from "../../hooks/use-feedback-choices"

// TODO: rewrite as a form
export const SubscriptionCancellationFeedbackModal = ({
  updateSubscription,
  updating,
  onClose,
  isOpen,
}) => {
  const feedbackChoices = useFeedbackChoices(
    FEEDBACK_SETS.SUBSCRIPTION_CANCELLATION_REASONS,
  )

  const [selectedFeedback, selectFeedback] = useState(null)
  const [comment, setComment] = useState("")

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        <FormattedMessage id="app.feedback.subscription.cancellation.confirm" />
      </ModalHeader>
      <ModalBody>
        <div>
          <p>
            <FormattedMessage
              id="app.page.subscription.cancel.confirmation.suggestion"
              values={{
                a: children => (
                  <a href="mailto:partners@moberries.com">{children}</a>
                ),
              }}
            />
          </p>
          <Select
            input={{
              onChange: selectFeedback,
              value: selectedFeedback,
            }}
            isSearchable={false}
            options={feedbackChoices}
            withCaret
            getOptionValue={option => option.id}
            getOptionLabel={option => (
              <FormattedMessage
                id={`app.feedback.subscription.cancellation.${camelize(
                  option.value,
                )}`}
              />
            )}
            placeholder={<FormattedMessage id="app.common.select" />}
            label={
              <FormattedMessage id="app.page.subscription.cancel.confirmation.reasons.title" />
            }
            meta={{}}
          />
          <Textarea
            label={
              <FormattedMessage id="app.page.subscription.cancel.confirmation.comment.title" />
            }
            type="textarea"
            meta={{}}
            input={{
              value: comment,
              onChange: ({ target: { value } }) => setComment(value),
            }}
            className="bg-white w-100"
            minRows={3}
            maxRows={15}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={updating} color="light" onClick={onClose}>
          <FormattedMessage id="app.common.cancel" />
        </Button>
        <Button
          loading={updating}
          disabled={
            updating ||
            !selectedFeedback ||
            (selectedFeedback?.value === "other" && !comment)
          }
          color="primary"
          onClick={() =>
            updateSubscription({
              answers: [selectedFeedback],
              comment,
            })
          }
        >
          <FormattedMessage id="app.common.confirm" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

SubscriptionCancellationFeedbackModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
}
SubscriptionCancellationFeedbackModal.defaultProps = {
  updating: false,
}
