import React, { useCallback, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { FormGroup, Label } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { arePredefinedAnswersRequired } from "../../lib/helpers"
import { PredefinedAnswerField } from "./predefined-answer-field"

export const PredefinedAnswersList = ({ move, push, remove, form }) => {
  const id = useRef(0)
  const addAnswer = useCallback(() => {
    push({ answer: "", tempId: `temp-${id.current++}` }) //tempId is needed to set an unique and stable key for newly added predefined answers
  }, [push])
  const questionType = form.values.questionType
  const predefinedAnswers = form.values.predefinedAnswers
  const setFieldValue = form.setFieldValue
  useEffect(() => {
    if (
      predefinedAnswers.length === 0 &&
      arePredefinedAnswersRequired(questionType?.value)
    ) {
      addAnswer()
    }
  }, [predefinedAnswers, addAnswer, questionType, setFieldValue])

  if (!arePredefinedAnswersRequired(questionType?.value)) {
    return null
  }
  return (
    <FormGroup>
      <Label>
        <FormattedMessage id="app.job.questions.predefinedAnswers.label" />
      </Label>
      <div>
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            if (!destination || destination.index === source.index) {
              return
            }

            move(source.index, destination.index)
          }}
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {predefinedAnswers?.map((answer, index) => {
                  const answerId = answer.id || answer.tempId
                  return (
                    <PredefinedAnswerField
                      id={String(answerId)}
                      key={String(answerId)}
                      name={`predefinedAnswers[${index}].answer`}
                      index={index}
                      addAnswer={addAnswer}
                      remove={remove}
                      predefinedAnswers={predefinedAnswers}
                    />
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </FormGroup>
  )
}

PredefinedAnswersList.propTypes = {
  move: PropTypes.func.isRequired,
  swap: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  insert: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
}
