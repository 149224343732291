import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut as D } from "react-chartjs-2"
import { COLORS } from "../../../constants"
import PropTypes from "prop-types"

ChartJS.register(ArcElement, Tooltip, Legend)

export const Doughnut = props => {
  const { data, colors } = props
  return (
    <D
      data={{
        datasets: [
          {
            data,
            backgroundColor: colors.map(c => c.color),
            borderColor: colors.map(c => c.color),
          },
          {
            data,
            weight: 0.4,
            backgroundColor: colors.map(c => c.mutedColor),
            borderColor: colors.map(c => c.mutedColor),
          },
        ],
      }}
      options={{
        cutout: 50,
        plugins: {
          tooltip: {
            titleAlign: "center",
            padding: 10,
            backgroundColor: COLORS.getWhite(),
            titleColor: COLORS.manatee,
            titleFont: { size: 12, weight: "normal" },
            titleMarginBottom: 10,
            bodyColor: COLORS.getBlack(0.6),
            bodyAlign: "center",
            bodyFont: { size: 14, lineHeight: 1, weight: "bold" },
            caretPadding: 0,
            caretSize: 0,
            cornerRadius: 16,
            borderColor: COLORS.getBlack(0.1),
            borderWidth: 2,
            displayColors: false,
            callbacks: {
              label: ctx => {
                return String(ctx.parsed) + "%"
              },
            },
          },
        },
      }}
    />
  )
}

Doughnut.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
}
