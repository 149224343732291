const currentYear = new Date().getFullYear()

// TODO: improvements: Carry out the localization for below availableSelections
export const availableSelections = [
  {
    value: 1,
    label: "January",
    start: `${currentYear}-01-01`,
    end: `${currentYear}-01-31`,
  },
  {
    value: 2,
    label: "February",
    start: `${currentYear}-02-01`,
    end: `${currentYear}-02-28`,
  },
  {
    value: 3,
    label: "March",
    start: `${currentYear}-03-01`,
    end: `${currentYear}-03-31`,
  },
  {
    value: 4,
    label: "April",
    start: `${currentYear}-04-01`,
    end: `${currentYear}-04-30`,
  },
  {
    value: 5,
    label: "May",
    start: `${currentYear}-05-01`,
    end: `${currentYear}-05-31`,
  },
  {
    value: 6,
    label: "Jun",
    start: `${currentYear}-06-01`,
    end: `${currentYear}-06-30`,
  },
  {
    value: 7,
    label: "July",
    start: `${currentYear}-07-01`,
    end: `${currentYear}-07-31`,
  },
  {
    value: 8,
    label: "August",
    start: `${currentYear}-08-01`,
    end: `${currentYear}-08-31`,
  },
  {
    value: 9,
    label: "September",
    start: `${currentYear}-09-01`,
    end: `${currentYear}-09-30`,
  },
  {
    value: 10,
    label: "October",
    start: `${currentYear}-10-01`,
    end: `${currentYear}-10-31`,
  },
  {
    value: 11,
    label: "November",
    start: `${currentYear}-11-01`,
    end: `${currentYear}-11-30`,
  },
  {
    value: 12,
    label: "December",
    start: `${currentYear}-12-01`,
    end: `${currentYear}-12-31`,
  },
]

const configs = {
  callbackLabel: function (value) {
    return `${value.dataset.label}: ${value.formattedValue}%`
  },
  callbackY: function (value) {
    return value + "%"
  },
}

const isZero = f => f === 0

const formatCompany = e => e.map(f => f.percentage)

const formatIndustry = e => e.map(f => f.industryPercentage)

const formatLabel = e => e.map((f, i) => `Week ${i + 1}`)

export const empty = (data = []) =>
  formatCompany(data).every(isZero) && formatIndustry(data).every(isZero)

export const mapper = (data = []) => {
  return {
    configs: configs,
    labels: formatLabel(data),
    datasets: [
      {
        label: "Your Company",
        borderColor: "#2a63da",
        data: formatCompany(data),
      },
      {
        label: "Industry",
        borderColor: "#e7503b",
        data: formatIndustry(data),
      },
    ],
  }
}
