import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

export const MatchApplicationBooleanAnswer = ({ applicationAnswer }) => {
  return (
    <p>
      <FormattedMessage
        id={applicationAnswer.answer ? "app.common.yes" : "app.common.no"}
      />
    </p>
  )
}

MatchApplicationBooleanAnswer.propTypes = {
  applicationAnswer: PropTypes.shape({
    answer: PropTypes.bool.isRequired,
    question: PropTypes.object.isRequired,
  }),
}
