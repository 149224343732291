import React, { Fragment } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { FormattedMessage } from "react-intl"
import { DetailViewAttribute } from "../detail-view/detail-view-attribute-component"
import { getLocationName } from "../../lib/helpers"

export const MatchLocationComparison = ({ candidate, job }) => (
  <Fragment>
    <DetailViewAttribute
      title={<FormattedMessage id="app.page.match.location" />}
    >
      <span>{getLocationName(candidate.city)}</span>
    </DetailViewAttribute>
    <DetailViewAttribute
      title={<FormattedMessage id="app.page.jobDetails.jobClaims.remote" />}
    >
      <span
        className={cn({
          "text-success": candidate.remote === job.remote,
        })}
      >
        <FormattedMessage
          id={candidate.remote ? "app.common.yes" : "app.common.no"}
        />
      </span>
      <span>{getLocationName(candidate.location)}</span>
    </DetailViewAttribute>
    <DetailViewAttribute
      title={<FormattedMessage id="app.page.match.jobClaims.relocation" />}
    >
      <span
        className={cn({
          "text-success": candidate.relocate === job.relocate,
        })}
      >
        <FormattedMessage
          id={candidate.relocate ? "app.common.yes" : "app.common.no"}
        />
      </span>
    </DetailViewAttribute>
    {candidate.locations.length > 0 && (
      <DetailViewAttribute
        title={
          <FormattedMessage id="app.page.match.jobClaims.relocation.preferredCities" />
        }
      >
        {candidate.locations.map((l, idx) => (
          <span key={l.placeId}>
            {getLocationName(l)}
            {idx !== candidate.locations.length - 1 && "; "}
          </span>
        ))}
      </DetailViewAttribute>
    )}

    {candidate.relocationCountries.length > 0 && (
      <DetailViewAttribute
        title={
          <FormattedMessage id="app.page.match.jobClaims.relocation.preferredCountries" />
        }
      >
        {candidate.relocationCountries.map((l, idx) => (
          <span key={l.placeId}>
            {getLocationName(l)}
            {idx !== candidate.relocationCountries.length - 1 && "; "}
          </span>
        ))}
      </DetailViewAttribute>
    )}
  </Fragment>
)

MatchLocationComparison.propTypes = {
  candidate: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
}
