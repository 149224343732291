import { initGtm, initLogrocket } from "../lib/helpers"
import { emitter } from "../lib/event-emitter"
import { connectWs, subscribeToWsActions } from "./ws-controller"
import { initAts, initUser } from "./user-controller"
import { notifyAboutNewMessage } from "./message-controller"
import { store as reduxStore } from "../redux/store"
import { newMatch } from "../actions/match-actions"

export const setupGlobalEvents = () => {
  emitter.on("NEW_MESSAGE", notifyAboutNewMessage)
  emitter.on("RELOAD_PAGE", () => {
    window.location.reload()
  })
  // TODO: change this, so it doesn't use redux
  emitter.on("NEW_MATCH", payload =>
    reduxStore.dispatch(newMatch({ type: "NEW_MATCH", payload })),
  )
}

export const init = async () => {
  initGtm()
  initLogrocket()
  connectWs()
  subscribeToWsActions()
  setupGlobalEvents()
  await initUser()
  await initAts()
}
