import React, { Component } from "react"
import { connect } from "react-redux"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import baloonPic from "../assets/img/bear.png"
import { Img } from "../components/common/img-component"

export class Release extends Component {
  static propTypes = {}
  static defaultProps = {}

  render() {
    return (
      <SingleColumnLayout>
        <div className="mt-6 text-center">
          <div>
            <Img alt="User" width="400" src={baloonPic} />
          </div>
          <h1 className="text-uppercase font-weight-light my-5">
            We will be back soon!
          </h1>
          <p className="mb-5">
            We are busy upgrading your experience and will be back soon with
            amazing new features. Stay tuned!
          </p>
        </div>
      </SingleColumnLayout>
    )
  }
}

const mapDispatchToProps = {}

const mapStateToProps = function (state) {
  return {}
}

export const ReleasePage = connect(mapStateToProps, mapDispatchToProps)(Release)
