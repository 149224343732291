import PropTypes from "prop-types"
import * as d3 from "d3"
import { useEffect, useRef } from "react"

const COLORS = [
  "#a1cd48",
  "#efd318",
  "#f4ae1b",
  "#f16b28",
  "#f24b23",
  "#ee3248",
  "#ec468b",
  "#b757a0",
  "#905ba6",
  "#508ac9",
  "#5ab660",
]

const MARGIN = 30

const CHART_INNER_COEF = 0.3
const CHART_OUTER_COEF = 0.6
const LABELS_COEF = 0.7

const LABELS_MARGIN_X = 10
const LABELS_MARGIN_Y = 15

const PERCENT_TO_HIDE_LABEL = 7

const getArc = ({ innerRadius, outerRadius }) =>
  d3.arc().innerRadius(innerRadius).outerRadius(outerRadius)

const getCircleQuarter = ({ data: { startAngle, endAngle } }) => {
  const midangle = startAngle + (endAngle - startAngle) / 2

  if (midangle > 1.5 * Math.PI) {
    return 2
  }

  if (midangle > Math.PI) {
    return 3
  }

  if (midangle > Math.PI / 2) {
    return 4
  }

  return 1
}

const getPercent = ({ value, total }) => Math.floor((value * 100) / total)

const getOutlinePoints = ({ data, chartArc, labelsArc }) => {
  const posA = chartArc.centroid(data)
  const posB = labelsArc.centroid(data)
  const posC = labelsArc.centroid(data)
  const quarter = getCircleQuarter({ data })

  posC[0] = posC[0] + ([1, 4].includes(quarter) ? 1 : -1) * 10
  posC[1] = posC[1] + ([1, 2].includes(quarter) ? -1 : 1) * 20

  return [posA, posB, posC]
}

const drawChart = ({ element, arc, data, color, innerRadius }) => {
  const chart = element.append("g").attr("class", "chart")

  const sections = chart
    .selectAll(".section")
    .data(data)
    .join("g")
    .attr("class", "section")

  sections
    .append("path")
    .attr("d", arc)
    .attr("fill", d => color(d.data.name))

  chart
    .append("circle")
    .attr("cx", 0)
    .attr("cy", 0)
    .attr("r", innerRadius * 1.2)
    .style("fill", "#fff")
    .style("opacity", 0.3)
}

const drawOutlines = ({ element, chartArc, labelsArc, color, total }) => {
  element
    .selectAll(".section")
    .append("polyline")
    .attr("stroke", d => color(d.data.name))
    .attr("stroke-width", "1px")
    .attr("class", "donut-chart__outlabel donut-chart__outlabel_line")
    .attr("points", d => getOutlinePoints({ data: d, chartArc, labelsArc }))
    .style("fill", "none")
    .style("visibility", d =>
      getPercent({ value: d.data.value, total }) < PERCENT_TO_HIDE_LABEL
        ? "hidden"
        : "visible",
    )

  element
    .selectAll(".section")
    .append("circle")
    .attr("cx", d => {
      const [, , posC] = getOutlinePoints({ data: d, chartArc, labelsArc })
      return posC[0]
    })
    .attr("cy", d => {
      const [, , posC] = getOutlinePoints({ data: d, chartArc, labelsArc })
      return posC[1]
    })
    .attr("r", "3px")
    .attr("class", "donut-chart__outlabel donut-chart__outlabel_marker")
    .style("fill", d => color(d.data.name))
    .style("visibility", d =>
      getPercent({ value: d.data.value, total }) < PERCENT_TO_HIDE_LABEL
        ? "hidden"
        : "visible",
    )
}

const drawLabels = ({ element, chartArc, labelsArc, total }) => {
  element
    .selectAll(".section")
    .append("text")
    .text(d => `${getPercent({ value: d.data.value, total })}%`)
    .attr("class", "donut-chart__outlabel donut-chart__outlabel_percent")
    .attr("transform", d => {
      const [, , posC] = getOutlinePoints({ data: d, chartArc, labelsArc })
      const quarter = getCircleQuarter({ data: d })

      posC[0] = posC[0] + ([1, 4].includes(quarter) ? 1 : -1) * LABELS_MARGIN_X
      return `translate(${posC})`
    })
    .style("text-anchor", function (d) {
      const quarter = getCircleQuarter({ data: d })
      return [1, 4].includes(quarter) ? "start" : "end"
    })
    .style("visibility", d =>
      getPercent({ value: d.data.value, total }) < PERCENT_TO_HIDE_LABEL
        ? "hidden"
        : "visible",
    )

  element
    .selectAll(".section")
    .append("text")
    .text(d => d.data.name)
    .attr("class", "donut-chart__outlabel donut-chart__outlabel_name")
    .attr("transform", d => {
      const [, , posC] = getOutlinePoints({ data: d, chartArc, labelsArc })
      const quarter = getCircleQuarter({ data: d })

      posC[0] = posC[0] + ([1, 4].includes(quarter) ? 1 : -1) * LABELS_MARGIN_X
      posC[1] = posC[1] + LABELS_MARGIN_Y
      return `translate(${posC})`
    })
    .style("text-anchor", function (d) {
      const quarter = getCircleQuarter({ data: d })
      return [1, 4].includes(quarter) ? "start" : "end"
    })
    .style("visibility", d =>
      getPercent({ value: d.data.value, total }) < PERCENT_TO_HIDE_LABEL
        ? "hidden"
        : "visible",
    )
}

const addListeners = ({ element, total }) => {
  element
    .selectAll(".section")
    .filter(
      d => getPercent({ value: d.data.value, total }) < PERCENT_TO_HIDE_LABEL,
    )
    .on("mouseover", function () {
      d3.select(this)
        .selectAll(".donut-chart__outlabel")
        .style("visibility", "visible")
    })
    .on("mouseout", function () {
      d3.select(this)
        .selectAll(".donut-chart__outlabel")
        .style("visibility", "hidden")
    })
}

export const MonetizationDonutChart = ({ width, height, data }) => {
  const svgRef = useRef(null)
  const total = data.reduce((acc, item) => (acc += item.value), 0)
  const radius = Math.min(width, height) / 2 - MARGIN
  const innerRadius = radius * CHART_INNER_COEF
  const outerRadius = radius * CHART_OUTER_COEF
  const labelRadius = radius * LABELS_COEF

  useEffect(() => {
    const svgEl = d3.select(svgRef.current)

    const svg = svgEl
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`)
    const color = d3.scaleOrdinal().domain(data).range(COLORS)
    const pie = d3.pie().value(d => d.value)
    const chartArc = getArc({ innerRadius, outerRadius })
    const labelsArc = getArc({
      innerRadius: labelRadius,
      outerRadius: labelRadius,
    })

    drawChart({
      element: svg,
      arc: chartArc,
      data: pie(data),
      color,
      innerRadius,
    })
    drawOutlines({
      element: svg,
      data: pie(data),
      color,
      chartArc,
      labelsArc,
      total,
    })
    drawLabels({ element: svg, data: pie(data), chartArc, labelsArc, total })
    addListeners({ element: svg, total })

    return () => {
      svgEl.selectAll("*").remove()
    }
  })

  return (
    <div className="donut-chart__wrapper">
      <svg ref={svgRef} width={width} height={height} />
    </div>
  )
}

MonetizationDonutChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
}
