import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import humps from "humps"
import { Textarea } from "../common/textarea-component"
import { FormattedMessage } from "react-intl"
import { Col } from "reactstrap"
import { Button } from "../common/button-component"
import { FeedbackOption } from "../common/feedback-option-component"
import { RejectCandidateForReasonsModal } from "../modals/reject-candidate-modal"
import { useFeedbackChoices } from "../../hooks/use-feedback-choices"
import { FEEDBACK_SETS } from "../../constants"
import { useList } from "../../hooks/use-list"
import { useOpenClose } from "../../hooks/use-open-close"
import { arrayToObject } from "../../lib/helpers"

export const MatchRejectionFeedback = ({
  confirm,
  cancel,
  isLoading,
  disabled,
}) => {
  const feedbackChoices = useFeedbackChoices(
    FEEDBACK_SETS.COMPANY_REJECT_REASONS,
  )
  const feedbackChoicesMap = arrayToObject(feedbackChoices, "id")
  const otherReasonId = feedbackChoices.find(r => r.value === "other")?.id

  const {
    value: selectedReasons,
    toggle: toggleReason,
    has: isReasonSelected,
    push: addReason,
  } = useList([])

  const {
    value: isConfirmationModalOpen,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useOpenClose({ initialValue: false })

  const [comment, setComment] = useState("")

  return (
    <Fragment>
      <RejectCandidateForReasonsModal
        isOpen={isConfirmationModalOpen}
        confirm={() => confirm(selectedReasons, comment)}
        cancel={closeConfirmationModal}
        loading={isLoading}
      />
      <div className="d-flex bg-light border border-left-0 border-right-0 justify-content-center py-4">
        <Col lg="10">
          <h5 className="mb-4">
            <FormattedMessage id="app.feedback.match.reject.title" />
          </h5>
          <div className="mb-4 lead">
            <Fragment>
              <label>
                <FormattedMessage
                  className="text-muted mb-2"
                  id="app.feedback.match.reject.text"
                />
              </label>
              <div className="tags-wrapper mb-5">
                {feedbackChoices.map(o => {
                  return (
                    <FeedbackOption
                      key={o.id}
                      id={o.value}
                      name={`app.feedback.match.reject.${humps.camelize(
                        o.value,
                      )}`}
                      isActive={isReasonSelected(o.id)}
                      onClick={() => toggleReason(o.id)}
                    />
                  )
                })}
              </div>
            </Fragment>
          </div>
          <div className="mb-2 text-muted">
            <Textarea
              className="bg-white"
              label={<FormattedMessage id="app.feedback.match.reject.label" />}
              meta={{}}
              minRows={4}
              input={{
                value: comment,
                onChange: ({ target: { value } }) => {
                  if (value.length > 3 && !isReasonSelected(otherReasonId)) {
                    addReason(otherReasonId)
                  }
                  setComment(value)
                },
              }}
            />
            <Button
              className="mr-1"
              color="secondary"
              disabled={disabled || isLoading}
              outline
              onClick={cancel}
            >
              <FormattedMessage id="app.common.cancel" />
            </Button>
            <Button
              color="success"
              onClick={() => {
                const isNotRespondingSelected = selectedReasons.some(
                  sr => feedbackChoicesMap[sr].value === "not_responding",
                )
                if (isNotRespondingSelected) {
                  openConfirmationModal()
                } else {
                  confirm(selectedReasons, comment)
                }
              }}
              disabled={isConfirmationButtonDisabled({
                disabled,
                selectedReasons,
                comment,
                isLoading,
                otherReasonId,
              })}
              loading={isLoading}
            >
              <FormattedMessage id="app.common.confirm" />
            </Button>
          </div>
        </Col>
      </div>
    </Fragment>
  )
}

MatchRejectionFeedback.propTypes = {
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

MatchRejectionFeedback.defaultProps = {
  disabled: false,
  isLoading: false,
}

function isConfirmationButtonDisabled({
  selectedReasons,
  disabled,
  comment,
  isLoading,
  otherReasonId,
}) {
  const isOtherSelected = selectedReasons.includes(otherReasonId)

  let isConfirmButtonDisabled = false
  if (selectedReasons.length === 1 && isOtherSelected && comment.length < 4) {
    isConfirmButtonDisabled = true
  }
  if (!selectedReasons.length) {
    isConfirmButtonDisabled = true
  }
  if (disabled || isLoading) {
    isConfirmButtonDisabled = true
  }
  return isConfirmButtonDisabled
}
