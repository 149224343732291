import React from "react"
import { FormattedMessage } from "react-intl"
import { ListGroup, ListGroupItem } from "reactstrap"
import classNames from "classnames"
import PropTypes from "prop-types"

export const MonetizationList = ({ companyRewards, dataKeys }) => {
  return (
    <ListGroup className="border-top" flush>
      {dataKeys.map(key => {
        let value = companyRewards[key]
        if (key === "revenueSum") {
          value /= 100
        }
        return (
          <ListGroupItem
            key={key}
            className={classNames(
              "justify-content-between d-flex border-0 border-bottom px-0 py-2",
              { "font-weight-bold": key === "revenueSum" },
            )}
          >
            <span className="mr-2">
              <FormattedMessage id={`app.widget.monetization.${key}`} />
            </span>

            <span>{value}</span>
          </ListGroupItem>
        )
      })}
    </ListGroup>
  )
}

MonetizationList.propTypes = {
  companyRewards: PropTypes.shape({
    candidates: PropTypes.number.isRequired,
    leads: PropTypes.number.isRequired,
    applications: PropTypes.number.isRequired,
    referredIntros: PropTypes.number.isRequired,
    hires: PropTypes.number.isRequired,
    revenueSum: PropTypes.number.isRequired,
  }),
  dataKeys: PropTypes.arrayOf(PropTypes.string),
}
