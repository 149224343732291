import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { StatisticsData } from "./statistics-data-component"
import { StatisticsDataRow } from "./statistics-data-row-component"
import { metricsGetters } from "../../lib/statistics-metrics"

export function MatchStatisticsWidget({ statistics }) {
  return (
    <div className="bg-white p-3 rounded mb-3">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-chart-bar" />
        </div>
        <FormattedMessage id="app.common.statistics" />
      </h5>

      <StatisticsData>
        {[
          "applicationsReceived",
          "applicationsAccepted",
          "applicationsDeclined",
          "applicationsExpired",
          "applicationsExported",
          "jobsImported",
        ].map(metricType => (
          <StatisticsDataRow
            key={metricType}
            labelKey={`app.widget.statistics.${metricType}`}
            {...metricsGetters[metricType](statistics)}
          />
        ))}
      </StatisticsData>
    </div>
  )
}

MatchStatisticsWidget.propTypes = {
  statistics: PropTypes.object.isRequired,
}
