import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Link } from "react-router-dom"
import { FormattedLink } from "../common/formatted-link-component"
import { SidebarWidgetLayout } from "../layouts/sidebar-widget-layout"

export const NoAtsIntegratedWidget = () => (
  <SidebarWidgetLayout
    title={
      <Fragment>
        <span className="icon-circled mr-2">
          <i className="fas fa-plug" />
        </span>
        <FormattedMessage id="app.widget.atsIntegration.noneConnected.title" />
      </Fragment>
    }
  >
    <div className="d-flex border-top pt-2 flex-column">
      <Button tag={Link} to="/integrations/ats">
        <FormattedMessage id="app.page.integrations.availableIntegrations" />
      </Button>
      <Button
        tag={FormattedLink}
        className="mt-1"
        href="atsIntegrationsGuide"
        target="_blank"
        color="light"
      >
        <FormattedMessage id="app.page.integrations.guide" />
      </Button>
    </div>
  </SidebarWidgetLayout>
)
