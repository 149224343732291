import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ThreadCard } from "./thread-card-component"
import { InfiniteScroll } from "../common/infinite-scroll-component"
import { CardLoader } from "../loaders/card-loader"
import { Input } from "../common/input-component"
import { useDebouncedState } from "../../hooks/use-debounced-state"
import { useIntl, FormattedMessage } from "react-intl"
import { Nav, NavItem, NavLink } from "reactstrap"
import { ThreadListLoader } from "../loaders/thread-list-loader"
import { MATCH_STATUSES, THREADS_PER_PAGE } from "../../constants"
import { useEvent } from "../../hooks/use-event"
import { api, ApiKeys } from "../../lib/moberries-api"
import { useInfiniteAxiosQuery } from "../../hooks/use-infinite-axios-query"
import { useQueryClient } from "react-query"

const ThreadTabs = {
  Open: "open",
  Closed: "closed",
}

const matchStatusesByTab = {
  [ThreadTabs.Open]: [MATCH_STATUSES.COMPANY_ACCEPTED, MATCH_STATUSES.EXPORTED],
  [ThreadTabs.Closed]: [
    MATCH_STATUSES.COMPANY_REJECTED,
    MATCH_STATUSES.COMPANY_APPROVED,
  ],
}

export const ThreadList = ({
  activeThreadId,
  useWindowScroll,
  onClickThread,
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useState(ThreadTabs.Open)
  const {
    state: search,
    debouncedState: debouncedSearch,
    setState: setSearch,
  } = useDebouncedState("")

  const queryKey = [
    ApiKeys.Threads,
    {
      search: debouncedSearch,
      match__status__in: matchStatusesByTab[activeTab],
    },
  ]
  const {
    isLoading,
    data: { results: threads = [] } = {},
    hasNextPage,
    fetchNextPage,
  } = useInfiniteAxiosQuery(queryKey, api.getThreadList, {
    pageSize: THREADS_PER_PAGE,
  })

  useEvent(
    "NEW_MESSAGE", // TODO: rename this event so it doesn't follow redux schema anymore
    () => {
      queryClient.invalidateQueries(ApiKeys.Threads)
    },
  )

  return (
    <div
      className={classNames("bg-white d-flex flex-column", {
        "messenger-height": !useWindowScroll,
      })}
    >
      <Nav tabs justified className="settings-tabs">
        <NavItem>
          <NavLink
            className={classNames("px-0 clickable", {
              "font-weight-bold": activeTab === ThreadTabs.Open,
            })}
            active={activeTab === ThreadTabs.Open}
            onClick={() => setActiveTab(ThreadTabs.Open)}
          >
            <FormattedMessage id="app.page.messages.openThreads" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames("px-0 clickable", {
              "font-weight-bold": activeTab === ThreadTabs.Closed,
            })}
            active={activeTab === ThreadTabs.Closed}
            onClick={() => setActiveTab(ThreadTabs.Closed)}
          >
            <FormattedMessage id="app.page.messages.closedThreads" />
          </NavLink>
        </NavItem>
      </Nav>
      <div className="p-3 border-bottom">
        <Input
          className="mb-0"
          input={{
            value: search,
            onChange: e => setSearch(e.target.value),
            placeholder: intl.formatMessage({ id: "app.page.messages.search" }),
          }}
          meta={{}}
        />
      </div>
      <div
        className={classNames({
          "flex-grow-1": !useWindowScroll,
          "overflow-auto": !useWindowScroll,
        })}
      >
        {isLoading && <ThreadListLoader />}
        {!isLoading && (
          <InfiniteScroll
            onLoadMore={fetchNextPage}
            hasMore={hasNextPage}
            rootMargin={200}
            loader={
              <div className="p-3 bg-white">
                <CardLoader />
              </div>
            }
            useWindowScroll={useWindowScroll}
          >
            {threads.map(t => (
              <ThreadCard
                key={t.id}
                thread={t}
                isSelected={t.id === activeThreadId}
                onClick={() => {
                  if (onClickThread) {
                    onClickThread(t)
                  }
                }}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

ThreadList.propTypes = {
  useWindowScroll: PropTypes.bool,
  onClickThread: PropTypes.func,
  activeThreadId: PropTypes.number,
}

ThreadList.defaultProps = {
  useWindowScroll: false,
  onClickThread: null,
  activeThreadId: null,
}
