import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import {
  Pagination as BPagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import { updateQueryParams } from "../../lib/helpers"

const getPage = (page, basePath, location) => {
  const search = updateQueryParams({
    search: location.search,
    params: { page: page === 1 ? null : page },
  })
  return basePath + search
}

export const Pagination = ({
  disabled,
  count,
  page,
  limit,
  basePath,
  className,
  listClassName,
}) => {
  const location = useLocation()
  if (count === null || count === 0) return null

  const totalPages = Math.floor((count - 1) / limit) + 1
  const pages = []

  if (totalPages < 6) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <PaginationItem key={i} disabled={disabled} active={page === i}>
          <PaginationLink tag={Link} to={getPage(i, basePath, location)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      )
    }
  }

  if (page <= 3 && totalPages >= 6) {
    for (let i = 1; i < 6; i++) {
      pages.push(
        <PaginationItem key={i} disabled={disabled} active={page === i}>
          <PaginationLink tag={Link} to={getPage(i, basePath, location)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      )
    }
    pages.push(
      <PaginationItem key={totalPages + 1} disabled>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>,
    )
  }

  if (page > 3 && page <= totalPages - 3 && totalPages >= 6) {
    pages.push(
      <PaginationItem key={totalPages + 1} disabled>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>,
    )

    for (let i = page - 2; i <= page + 2; i++) {
      pages.push(
        <PaginationItem key={i} disabled={disabled} active={page === i}>
          <PaginationLink tag={Link} to={getPage(i, basePath, location)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      )
    }

    pages.push(
      <PaginationItem key={totalPages + 2} disabled>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>,
    )
  }

  if (page > totalPages - 3 && totalPages >= 6) {
    pages.push(
      <PaginationItem key={totalPages + 1} disabled>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>,
    )

    for (let i = totalPages - 6 + 2; i <= totalPages; i++) {
      pages.push(
        <PaginationItem key={i} disabled={disabled} active={page === i}>
          <PaginationLink tag={Link} to={getPage(i, basePath, location)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      )
    }
  }

  return (
    <BPagination className={className} listClassName={listClassName}>
      <PaginationItem disabled={page === 1 || disabled}>
        <PaginationLink tag={Link} to={getPage(1, basePath, location)} first />
      </PaginationItem>

      <PaginationItem disabled={page === 1 || disabled}>
        <PaginationLink
          tag={Link}
          to={getPage(page - 1, basePath, location)}
          previous
        />
      </PaginationItem>
      {pages}

      <PaginationItem disabled={page === totalPages || disabled}>
        <PaginationLink
          tag={Link}
          to={getPage(page + 1, basePath, location)}
          next
        />
      </PaginationItem>

      <PaginationItem disabled={page === totalPages || disabled}>
        <PaginationLink
          tag={Link}
          to={getPage(totalPages, basePath, location)}
          last
        />
      </PaginationItem>
    </BPagination>
  )
}

Pagination.propTypes = {
  disabled: PropTypes.bool.isRequired,
  count: PropTypes.number,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  listClassName: PropTypes.string,
}

Pagination.defaultProps = {
  disabled: false,
}
