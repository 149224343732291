import { createSelector } from "reselect"

export const stateSelector = state => state.company

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading,
)

export const updatingSelector = createSelector(
  stateSelector,
  state => state.updating,
)
export const updatingErrSelector = createSelector(
  stateSelector,
  state => state.updatingErr,
)

export const subscriptionCancelingSelector = createSelector(
  stateSelector,
  state => state.subscriptionCanceling,
)
export const subscriptionRenewingSelector = createSelector(
  stateSelector,
  state => state.subscriptionRenewing,
)
export const subscriptionOrderingSelector = createSelector(
  stateSelector,
  state => state.subscriptionOrdering,
)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)
export const errorSelector = createSelector(stateSelector, state => state.err)

export const companySelector = createSelector(
  entitiesSelector,
  (state, { id }) => id,
  (entities, id) => entities[id],
)

export const companyRewardsSelector = createSelector(
  stateSelector,
  state => state.companyRewards,
)

export const companyRewardsTimeSeriesSelector = createSelector(
  stateSelector,
  state => state.companyRewardsTimeSeriesByRangeId,
)

export const companyRewardsTimeSeriesByRangeIdSelector = createSelector(
  companyRewardsTimeSeriesSelector,
  (state, { rangeId }) => rangeId,
  (companyRewardsTimeSeries, rangeId) => companyRewardsTimeSeries[rangeId],
)

export const areCompanyRewardsTimeSeriesByDataKeyLoading = createSelector(
  stateSelector,
  (state, { dataKey }) => dataKey,
  (state, dataKey) =>
    state.areCompanyRewardsTimeSeriesByDataKeyLoading[dataKey],
)

export const companyRewardsCandidatesSelector = createSelector(
  stateSelector,
  state => state.companyRewardsCandidates,
)

export const areCompanyRewardsCandidatesLoadingSelector = createSelector(
  stateSelector,
  state => state.areCompanyRewardsCandidatesLoading,
)
