import React, { Fragment, memo } from "react"
import PropTypes from "prop-types"
import ContentLoader from "react-content-loader"
import { times } from "ramda"

export const BulletListLoader = memo(({ count }) => {
  const LINE_HEIGHT = 20
  const WIDTH = 300

  return (
    <ContentLoader viewBox={`0 0 ${WIDTH} ${LINE_HEIGHT * count}`}>
      {times(
        i => (
          <Fragment key={i}>
            <circle cx="10" cy={8 + LINE_HEIGHT * i} r="8" />
            <rect
              x="25"
              y={3 + LINE_HEIGHT * i}
              rx="5"
              ry="5"
              width={WIDTH * 0.7 + WIDTH * Math.random() * 0.2}
              height="10"
            />
          </Fragment>
        ),
        count,
      )}
    </ContentLoader>
  )
})

BulletListLoader.propTypes = {
  count: PropTypes.number,
}

BulletListLoader.defaultProps = {
  count: 4,
}
