import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

export const DetailViewAttribute = ({ title, children }) => (
  <div
    className={cn(
      "d-flex flex-row text-smaller",
      { "mt-2": title },
      { "mt-1": !title },
    )}
  >
    {title && <b className="flex-grow-1 mr-2">{title}</b>}
    <div>{children}</div>
  </div>
)

DetailViewAttribute.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}
