import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { VatInputField } from "../payment/vat-input-component"
import { LabeledInputField } from "../fields/input-field"

export const CompanyBillingFields = () => (
  <Fragment>
    <LabeledInputField
      label={<FormattedMessage id="app.form.name.company" />}
      name="companyName"
      type="text"
      id="companyName"
      placeholder="MoBerries"
      isRequired
    />

    <LabeledInputField
      label={<FormattedMessage id="app.form.name.first" />}
      name="firstName"
      type="text"
      id="firstName"
      placeholder="John"
      isRequired
    />

    <LabeledInputField
      label={<FormattedMessage id="app.form.name.last" />}
      name="lastName"
      type="text"
      id="lastName"
      placeholder="Doe"
      isRequired
    />

    <LabeledInputField
      label={<FormattedMessage id="app.form.email" />}
      name="email"
      type="email"
      id="email"
      placeholder="email@example.com"
      isRequired
    />

    <LabeledInputField
      label={<FormattedMessage id="app.form.country" />}
      name="countryName"
      type="text"
      id="countryName"
      placeholder="Germany"
      isRequired
    />

    <LabeledInputField
      label={<FormattedMessage id="app.form.city" />}
      name="cityName"
      type="text"
      id="cityName"
      placeholder="Berlin"
      isRequired
    />

    <LabeledInputField
      label={<FormattedMessage id="app.form.postcode" />}
      name="postcode"
      type="text"
      id="postcode"
      placeholder="10117"
      isRequired
    />

    <LabeledInputField
      label={<FormattedMessage id="app.form.street" />}
      name="street"
      type="text"
      id="street"
      placeholder="Sophienstr. 16"
      isRequired
    />

    <VatInputField
      label={<FormattedMessage id="app.form.vat" />}
      name="vatId"
      id="vatId"
    />
  </Fragment>
)
