import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { ActionControlsLayout } from "../layouts/action-controls-layout"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"
import { useLocation } from "react-router"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"

export const AcceptDeclineMatchControls = ({
  matchId,
  onDeclineClick,
  acceptMatch,
  disabled,
  acceptLoading,
  declineLoading,
  accept,
  decline,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    if (accept) {
      acceptMatch({ matchId })
      dispatch(push(location.pathname)) // reset query params
    }
    if (decline) {
      onDeclineClick()
      dispatch(push(location.pathname))
    }
  }, [
    accept,
    matchId,
    decline,
    onDeclineClick,
    acceptMatch,
    dispatch,
    location.pathname,
  ])
  return (
    <ActionControlsLayout>
      <div
        left="true"
        className="d-flex align-items-center justify-content-end"
      >
        <small className="d-none d-lg-block px-2 text-right">
          <FormattedMessage id="app.page.match.decline.text" />
        </small>
        <Button
          disabled={disabled}
          loading={declineLoading}
          className="btn-pill"
          color="secondary"
          onClick={onDeclineClick}
        >
          <i className="fas fa-times pr-1" />
          <FormattedMessage id="app.page.match.decline.button" />
        </Button>
      </div>

      <div right="true" className="d-flex align-items-center">
        <Button
          disabled={disabled}
          loading={acceptLoading}
          className="btn-pill"
          color="success"
          onClick={() => acceptMatch({ matchId })}
        >
          <i className="fas fa-heart pr-1" />
          <FormattedMessage id="app.page.match.accept.button" />
        </Button>
        <small className="d-none d-lg-block px-2">
          <FormattedMessage id="app.page.match.accept.text" />
        </small>
      </div>
    </ActionControlsLayout>
  )
}

AcceptDeclineMatchControls.propTypes = {
  matchId: PropTypes.number.isRequired,
  acceptMatch: PropTypes.func.isRequired,
  onDeclineClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  acceptLoading: PropTypes.bool.isRequired,
  declineLoading: PropTypes.bool.isRequired,
  accept: PropTypes.bool.isRequired,
  decline: PropTypes.bool.isRequired,
}

AcceptDeclineMatchControls.defaultProps = {
  disabled: false,
  acceptLoading: false,
  declineLoading: false,
}
