import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FormFeedback, FormGroup, Label, PopoverBody } from "reactstrap"
import { prop } from "ramda"
import { useFormikField } from "../../hooks/use-formik-field"
import { randomInteger } from "../../lib/helpers"
import { FormattedErrorMessage } from "../common/formatted-error-message"
import { MultiSelect } from "../common/multi-select-new"
import { FormattedMessage } from "react-intl"
import { useFormikContext } from "formik"
import { Button } from "../common/button-component"
import { api as moberriesApi, ApiKeys } from "../../lib/moberries-api"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { InfoHint } from "../common/info-hint-component"
import { nanoid } from "nanoid"

const getRandomJobRoleId = jobRoles =>
  prop("id", jobRoles[randomInteger(0, jobRoles.length - 1)])

export const SkillsMultiSelectField = ({
  label,
  hint,
  id,
  isRequired,
  ...rest
}) => {
  const [field, meta, actions] = useFormikField(rest)
  const {
    values: { jobRoles },
  } = useFormikContext()

  const [jobRoleId, setJobRoleId] = useState(getRandomJobRoleId(jobRoles))

  useEffect(() => {
    setJobRoleId(getRandomJobRoleId(jobRoles))
  }, [jobRoles])

  const { data: { results: suggestions = [] } = {} } = useAxiosQuery(
    [ApiKeys.AssociatedSkills, { jobRoleId }],
    moberriesApi.getAssociatedSkills,
    {
      enabled: Boolean(jobRoleId),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  const [hintId] = useState(nanoid())
  return (
    <FormGroup>
      <Label for={id}>
        {label}
        {isRequired && " *"}
        {hint && (
          <InfoHint id={hintId} popover={<PopoverBody>{hint}</PopoverBody>} />
        )}
      </Label>

      {suggestions.length > 0 && field.value.length < 30 && (
        <Fragment>
          <small className="d-block text-muted mb-1">
            <FormattedMessage id="app.common.suggestions" />
          </small>
          <div className="tags-wrapper mb-2">
            {suggestions
              .filter(s => !field.value.some(v => v.id === s.id))
              .slice(0, 8)
              .map(s => (
                <Button
                  key={s.id}
                  color="light"
                  onClick={() => actions.setValue([...field.value, s])}
                  size="sm"
                >
                  {s.name}
                  <i className="fas fa-plus pl-2 text-muted" />
                </Button>
              ))}
          </div>
        </Fragment>
      )}

      <MultiSelect
        {...field}
        onChange={actions.setValue}
        {...rest}
        limit={30}
      />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

SkillsMultiSelectField.propTypes = {
  label: PropTypes.node.isRequired,
  hint: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isRequired: PropTypes.bool,
}

SkillsMultiSelectField.defaultProps = {
  isRequired: false,
}
