import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import { Img } from "../../common/img-component"

import CloseIcon from "../../../assets/img/close.svg"
import styles from "./virtual-recruiter-modal.module.scss"
import { CampaignsList } from "./campaigns-list/campaigns-list"
import { useState } from "react"
import { CampaignsHeader } from "./campaigns-header/campaigns-header"
import { CandidatesList } from "./candidates-list/candidates-list"
import { CandidatesHeader } from "./candidates-header/candidates-header"
import { connect } from "react-redux"
import { selectCampaign } from "../../../actions/vr-candidate-actions"

export const VirtualRecruiterModalComponent = ({
  isOpen,
  toggle,
  selectCampaign,
}) => {
  const [view, setView] = useState("campaigns")

  const resetModal = () => {
    setView("campaigns")
    selectCampaign({ campaign: null })
  }

  const candidatesOpenClickHandler = campaign => {
    setView("candidates")
    selectCampaign({ campaign })
  }

  const closeModalHandler = () => {
    resetModal()
    toggle()
  }

  return (
    <Modal
      className={styles.vrModal}
      isOpen={isOpen}
      toggle={closeModalHandler}
      centered
    >
      <div className={styles.header}>
        <div className={styles.header__content}>
          {view === "campaigns" ? (
            <CampaignsHeader />
          ) : (
            <CandidatesHeader campaignsOpenClickHandler={resetModal} />
          )}
        </div>
        <button className={styles.header__closeBtn} onClick={closeModalHandler}>
          <Img src={CloseIcon} width="16" height="16" />
        </button>
      </div>
      <div className={styles.content}>
        {view === "campaigns" ? (
          <CampaignsList
            candidatesOpenClickHandler={candidatesOpenClickHandler}
          />
        ) : (
          <CandidatesList />
        )}
      </div>
    </Modal>
  )
}

VirtualRecruiterModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  selectCampaign: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  selectCampaign,
}

export const VirtualRecruiterModal = connect(
  null,
  mapDispatchToProps,
)(VirtualRecruiterModalComponent)
