import React from "react"
import PropTypes from "prop-types"
import { pathOr } from "ramda"
import { FormattedMessage } from "react-intl"
import { PopoverBody } from "reactstrap"
import { CardStatusTag } from "../common/card-status-tag"
import styles from "./match-labels-component.module.scss"

export const MatchLabels = ({ match }) => {
  const acceptedMatches = pathOr(
    0,
    ["candidate", "statistics", "applicationsAccepted"],
    match,
  )

  const label = {}

  // overriding priority is important
  if (acceptedMatches > 2) {
    label.text = "app.page.dashboard.matches.text.hot"
    label.tooltip = "app.page.dashboard.matches.tooltip.hot"
    label.color = "#E77979"
    label.background = "#FDF2ED"
  }

  if (acceptedMatches === 2) {
    label.text = "app.page.dashboard.matches.text.popular"
    label.tooltip = "app.page.dashboard.matches.tooltip.popular"
    label.color = "warning-light" // TODO: modify colors according to new design in future
  }

  if (match.source === 3 && !match.hasHiringFee) {
    label.text = "app.page.dashboard.matches.text.exclusive"
    label.tooltip = "app.page.dashboard.matches.tooltip.exclusive"
    label.color = "success-light" // TODO: modify colors according to new design in future
  }

  return (
    <div className={styles.container}>
      {Object.keys(label).length > 0 && (
        <CardStatusTag
          id={match.id}
          text={<FormattedMessage id={label.text} />}
          popover={
            <PopoverBody>
              <FormattedMessage id={label.tooltip} />
            </PopoverBody>
          }
          color={label.color}
          background={label.background}
        />
      )}

      {match.isCandidateShared && (
        <CardStatusTag
          id={`${match.id}-shared`}
          text={
            <FormattedMessage id="app.page.dashboard.matches.text.shared" />
          }
          popover={
            <PopoverBody>
              <FormattedMessage id="app.page.dashboard.matches.tooltip.shared" />
            </PopoverBody>
          }
          color="#615A97"
          background="#F5F7FD"
        />
      )}
    </div>
  )
}

MatchLabels.propTypes = {
  match: PropTypes.object.isRequired,
}

MatchLabels.defaultProps = {
  match: {},
}
