import React from "react"
import { CardLoader } from "./card-loader"
import { times } from "ramda"
import PropTypes from "prop-types"

export const CardListLoader = ({ className, count }) =>
  times(
    i => (
      <div key={i} className={className}>
        <CardLoader />
      </div>
    ),
    count,
  )

CardListLoader.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
}

CardListLoader.defaultProps = {
  className: "bg-white p-3 mb-2 rounded",
  count: 7,
}
