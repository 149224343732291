import PropTypes from "prop-types"
import cn from "classnames"
import { nanoid } from "nanoid"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Row,
} from "reactstrap"
import { api as moberriesApi } from "../../lib/moberries-api"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"
import { InfoHint } from "../common/info-hint-component"
import { Select } from "../common/select-component-new"
import { LANG_LEVELS } from "../../default-options"
import { Button } from "../common/button-component"
import { useBreakpoint } from "../../hooks/use-breakpoint"
import { isNil } from "ramda"

export const JobLanguagesSelectField = ({ ...rest }) => {
  const [leftSelectValue, setLeftSelectValue] = useState(null)
  const [field, meta, actions] = useFormikField(rest)
  const [hintId] = useState(nanoid())
  const isXsOrSmScreen = useBreakpoint("xs", "md")

  const languageSelect = {
    async: true,
    loadOptions: async search => {
      const {
        data: { results: languages },
      } = await moberriesApi.getLanguages({ params: { search } })
      return languages
    },
    defaultOptions: true,
    getOptionValue: option => option.id,
    getOptionLabel: option => option.name,
  }

  const levelSelect = {
    options: LANG_LEVELS.map(l => ({ levelName: l.name, level: l.id })),
    defaultOptions: true,
    getOptionValue: option => option.level,
    getOptionLabel: option => <FormattedMessage id={option.levelName} />,
    isSearchable: false,
  }

  const mustHaveSelect = {
    options: [{ mustHave: true }, { mustHave: false }],
    getOptionValue: option => option.mustHave,
    getOptionLabel: option => (option.mustHave ? "Must Have" : "Optional"),
    isSearchable: false,
    defaultOptions: true,
  }

  const selectChangeHandler = ({ value, item }) => {
    const newItem = { ...item, ...value }
    const newItems = field.value.map(i => (i.id === item.id ? newItem : i))
    actions.setTouched()
    actions.setValue(newItems)
  }

  const languageRemoveHandler = item => {
    const newItems = field.value.filter(i => i.id !== item.id)
    actions.setTouched()
    actions.setValue(newItems)
  }

  return (
    <FormGroup>
      <Label>
        <FormattedMessage id="app.job.form.languages.label" />
        {" *"}
        <InfoHint
          id={hintId}
          popover={
            <PopoverBody>
              <FormattedMessage id="app.job.form.languages.hint" />
            </PopoverBody>
          }
        />
      </Label>

      <Row noGutters>
        <Col xs={6} className="pr-1">
          <small>
            <FormattedMessage id="app.common.language" />
          </small>
          <Select
            {...languageSelect}
            invalid={meta.touched && Boolean(meta.error)}
            value={leftSelectValue}
            onChange={value => {
              const newItem = {
                ...value,
                mustHave: field.value.length === 0 ? true : null,
              }
              setLeftSelectValue(newItem)
            }}
            filterOption={option =>
              !field.value.some(
                i => languageSelect.getOptionValue(i) === option.value,
              )
            }
          />
        </Col>
        <Col xs={6} className="pl-1">
          <small>
            <FormattedMessage id="app.job.form.languages.level" />
          </small>
          <Select
            {...levelSelect}
            invalid={meta.touched && Boolean(meta.error)}
            isDisabled={!leftSelectValue}
            controlShouldRenderValue={false}
            onChange={levelValue => {
              actions.setTouched()
              actions.setValue([
                ...field.value,
                { ...leftSelectValue, ...levelValue },
              ])
              setLeftSelectValue(null)
            }}
          />
        </Col>
        <Col xs={12} className={cn({ "mt-2": field.value.length > 0 })}>
          {field.value.map((item, i) => (
            <Row
              noGutters
              key={item.id}
              className="d-flex border border-success rounded my-1 p-2 bg-success-light"
            >
              <Col xs="12" md="4" className="rounded">
                <Select
                  {...languageSelect}
                  value={item}
                  onChange={value => selectChangeHandler({ value, item })}
                />
              </Col>
              <Col xs="12" md="4" className="mt-1 mt-md-0 pl-md-2 rounded">
                <Select
                  {...levelSelect}
                  value={item}
                  onChange={value => selectChangeHandler({ value, item })}
                />
              </Col>
              <Col xs="12" md="3" className="mt-1 mt-md-0 pl-md-2 rounded">
                <Select
                  {...mustHaveSelect}
                  value={item}
                  controlShouldRenderValue={!isNil(item.mustHave)}
                  onChange={value => selectChangeHandler({ value, item })}
                />
              </Col>
              <Col
                xs="12"
                md="1"
                className="d-flex justify-content-md-center justify-content-end align-content-center"
              >
                <Button
                  type="button"
                  color="success-light"
                  size="sm"
                  className="ml-md-2 mt-2 mt-md-0"
                  onClick={() => {
                    languageRemoveHandler(item)
                  }}
                  block={isXsOrSmScreen}
                >
                  <i className="fas fa-times align-middle mr-2 mr-md-0" />
                  <span className="d-md-none">
                    <FormattedMessage id="app.common.delete" />
                  </span>
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>

      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

JobLanguagesSelectField.propTypes = {
  leftSelect: PropTypes.object,
  rightSelect: PropTypes.object,
}
