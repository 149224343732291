import { times } from "ramda"
import { MessageLoader } from "./message-loader"
import React from "react"

export const MessageListLoader = () =>
  times(
    i => (
      <div key={i} className="bg-white">
        <MessageLoader onTheRight={i % 2 === 0} />
      </div>
    ),
    6,
  )
