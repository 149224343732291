import { startSubmit, stopSubmit } from "redux-form"
import { pick } from "ramda"
import { push } from "connected-react-router"
import { jobWithIntegrationPostingSelector } from "../selectors/job-selector"
import { atsJobIntegrationByJobIdSelector } from "../selectors/ats-job-integration-selector"
import {
  getActionType,
  getChangedFields,
  isFormValidation,
} from "../lib/helpers"
import {
  GET,
  JOB,
  LIST,
  START,
  FAIL,
  COMPANY,
  SUCCESS,
  UPDATE,
  JOBS,
  CONDITIONS,
  FEEDBACK,
} from "../constants"
import { validateForm } from "./form-actions"
import { alertTypes, showAlert, showErrorAlert } from "../lib/alerts"
import { refreshCompany } from "../controllers/company-controller"

export function getCompanyJobList({ companyId, params }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, COMPANY, JOB, LIST, START) })

    try {
      const res = await moApi.getCompanyJobList({
        companyId,
        params,
      })
      const { results: jobs, count } = res.data

      dispatch({
        type: getActionType(GET, COMPANY, JOB, LIST, SUCCESS),
        payload: { jobs, count },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, COMPANY, JOB, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export function getJob({ id }) {
  return async (dispatch, getState, { moApi }) => {
    dispatch({ type: getActionType(GET, JOB, START) })

    try {
      const { data } = await moApi.getJob({ id })

      dispatch({
        type: getActionType(GET, JOB, SUCCESS),
        payload: { job: data },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, JOB, FAIL),
        payload: { err },
      })
    }
  }
}

export function updateJob({ id, job }) {
  return async (dispatch, getState, { moApi, actions }) => {
    const formName = "JobEditForm"
    dispatch({ type: getActionType(UPDATE, JOB, START) })
    dispatch(startSubmit(formName))

    job = pick(
      [
        "condition",
        "title",
        "description",
        "status",
        "careerLevel",
        "team",
        "salaryMax",
        "salaryMin",
        "remote",
        "relocate",
        "skills",
        "locations",
        "languages",
        "atsJob",
        "jobTypes",
        "jobRoles",
        "private",
        "remoteRegions",
        "relocationRegions",
      ],
      job,
    )

    const prevJob = jobWithIntegrationPostingSelector(getState(), { id })

    const fields = getChangedFields(job, prevJob)

    const data = pick(fields, job)

    const { atsJob } = job

    const prevAtsJobIntegration = atsJobIntegrationByJobIdSelector(getState(), {
      id,
    })
    try {
      const { data: job } = await moApi.updateJob({ id, job: data })

      if (atsJob && !prevAtsJobIntegration) {
        await dispatch(
          actions.atsJobIntegration.createAtsJobIntegration({
            moberriesJobId: id,
            providerJobId: atsJob.id,
          }),
        )
      }

      if (
        atsJob &&
        prevAtsJobIntegration &&
        (!prevAtsJobIntegration.providerJob ||
          atsJob.id !== prevAtsJobIntegration.providerJob)
      ) {
        await dispatch(
          actions.atsJobIntegration.deleteAtsJobIntegration({
            integrationId: prevAtsJobIntegration.id,
          }),
        )

        await dispatch(
          actions.atsJobIntegration.createAtsJobIntegration({
            moberriesJobId: id,
            providerJobId: atsJob.id,
          }),
        )
      }

      dispatch(stopSubmit(formName))
      dispatch({
        type: getActionType(UPDATE, JOB, SUCCESS),
        payload: { job },
      })
      showAlert({ code: alertTypes.jobUpdateSuccess })
      refreshCompany()
    } catch (err) {
      if (isFormValidation(err)) {
        dispatch(validateForm({ formName, err }))
      } else {
        showErrorAlert({ err })
        dispatch(stopSubmit(formName))
      }

      dispatch({
        type: getActionType(UPDATE, JOB, FAIL),
        payload: { err },
      })
    }
  }
}

/*
 * This method made as a simple version of updateJob method above, to use the ATS part only
 * */
export function updateAtsJob({ id, job }) {
  return async (dispatch, getState, { moApi, actions }) => {
    dispatch({ type: getActionType(UPDATE, JOB, START) })

    const { atsJob } = job

    const prevAtsJobIntegration = atsJobIntegrationByJobIdSelector(getState(), {
      id,
    })
    try {
      if (atsJob && !prevAtsJobIntegration) {
        await dispatch(
          actions.atsJobIntegration.createAtsJobIntegration({
            moberriesJobId: id,
            providerJobId: atsJob.id,
          }),
        )
      }

      if (
        atsJob &&
        prevAtsJobIntegration &&
        (!prevAtsJobIntegration.providerJob ||
          atsJob.id !== prevAtsJobIntegration.providerJob)
      ) {
        await dispatch(
          actions.atsJobIntegration.deleteAtsJobIntegration({
            integrationId: prevAtsJobIntegration.id,
          }),
        )

        await dispatch(
          actions.atsJobIntegration.createAtsJobIntegration({
            moberriesJobId: id,
            providerJobId: atsJob.id,
          }),
        )
      }

      dispatch({
        type: getActionType(UPDATE, JOB, SUCCESS),
        payload: { job },
      })
      showAlert({ code: alertTypes.jobUpdateSuccess })
      refreshCompany()
    } catch (err) {
      dispatch({
        type: getActionType(UPDATE, JOB, FAIL),
        payload: { err },
      })
    }
  }
}

export function updateJobsConditions({ data, companyId }) {
  return async (dispatch, getState, { moApi, actions }) => {
    dispatch({ type: getActionType(UPDATE, JOBS, CONDITIONS, START) })

    try {
      await moApi.updateJobsConditions({ data, companyId })

      dispatch({
        type: getActionType(UPDATE, JOBS, CONDITIONS, SUCCESS),
      })
      refreshCompany()
      dispatch(push("/"))
    } catch (err) {
      dispatch({
        type: getActionType(UPDATE, JOBS, CONDITIONS, FAIL),
        payload: { err },
      })
    }
  }
}

export function getJobFeedback({ jobId }) {
  return async (dispatch, getState, { moApi }) => {
    dispatch({ type: getActionType(GET, JOB, FEEDBACK, START) })

    try {
      const { data } = await moApi.getJobFeedback({ jobId })

      dispatch({
        type: getActionType(GET, JOB, FEEDBACK, SUCCESS),
        payload: { jobFeedback: data, jobId },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, JOB, FEEDBACK, FAIL),
        payload: { err },
      })
    }
  }
}
