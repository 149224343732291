import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Badge } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { getSkillsDiff } from "../../lib/helpers"

export const MatchSkillsComparison = ({ candidate, job }) => {
  const {
    matched: matchedSkills,
    missing: missingSkills,
    rest: otherSkills,
  } = getSkillsDiff(candidate.skills, job.skills)
  return (
    <Fragment>
      {matchedSkills.map(skill => (
        <Badge color="success-light" className="mb-1 mr-1" key={skill.id}>
          {skill.name}
        </Badge>
      ))}
      {otherSkills.map(skill => (
        <Badge color="light" className="mb-1 mr-1" key={skill.id}>
          {skill.name}
        </Badge>
      ))}
      {missingSkills.length > 0 && (
        <div className="text-muted text-uppercase text-smaller mt-3">
          <FormattedMessage id="app.page.match.missingSkills" />
        </div>
      )}

      <div className="mt-3">
        {missingSkills.map(skill => (
          <Badge color="danger-light" className="mb-1 mr-1" key={skill.id}>
            {skill.name}
          </Badge>
        ))}
      </div>
    </Fragment>
  )
}

MatchSkillsComparison.propTypes = {
  candidate: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
}
