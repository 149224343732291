import { getActionType } from "../lib/helpers"
import {
  evolve,
  F,
  always,
  over,
  lensProp,
  mergeLeft,
  T,
  lensIndex,
  assoc,
  pipe,
  filter,
  propSatisfies,
  prop,
  slice,
  sort,
  descend,
} from "ramda"
import {
  SUCCESS,
  LOGIN,
  LOGOUT,
  ACTIVATE,
  USER,
  GET,
  COMPANY,
  FAIL,
  UPDATE,
  START,
  BILLING,
  CANCEL,
  RENEW,
  ORDER,
  SUBSCRIPTION,
  REWARDS,
  SERIES,
  TIME,
  CANDIDATES,
} from "../constants"

export const defaultState = {
  loading: false,
  updating: false,
  updatingErr: null,
  subscriptionCanceling: false,
  subscriptionRenewing: false,
  subscriptionOrdering: false,
  err: null,
  entities: {},
  id: null,
  companyRewards: null,
  isLoadingCompanyRewards: false,
  companyRewardsTimeSeriesByRangeId: { last30Days: [] },
  areCompanyRewardsTimeSeriesByDataKeyLoading: {
    applications: false,
    signups: false,
  },
  companyRewardsCandidates: {
    careerLevels: [],
    jobCategories: [],
    locationCountries: [],
    desiredRelocationCities: [],
  },
  areCompanyRewardsCandidatesLoading: false,
}

export const reducer = function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, COMPANY, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: over(
            lensProp(payload.company.id),
            mergeLeft(payload.company),
          ),
          id: always(payload.company.id),
        },
        state,
      )

    case getActionType(ACTIVATE, USER, SUCCESS):
    case getActionType(LOGIN, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: over(
            lensProp(payload.company.id),
            mergeLeft(payload.company),
          ),
          id: always(payload.company.id),
        },
        state,
      )

    case getActionType(UPDATE, COMPANY, BILLING, START):
      return evolve({ updating: T }, state)

    case getActionType(UPDATE, COMPANY, BILLING, SUCCESS):
      return evolve(
        {
          updating: F,
          updatingErr: always(null),
          entities: over(
            lensProp(payload.company.id),
            mergeLeft(payload.company),
          ),
        },
        state,
      )

    case getActionType(UPDATE, COMPANY, BILLING, FAIL):
      return evolve({ updating: F, updatingErr: always(payload.err) }, state)

    case getActionType(CANCEL, COMPANY, SUBSCRIPTION, START):
      return evolve({ subscriptionCanceling: T }, state)

    case getActionType(CANCEL, COMPANY, SUBSCRIPTION, SUCCESS):
      return evolve(
        {
          subscriptionCanceling: F,
          updatingErr: always(null),
        },
        state,
      )

    case getActionType(CANCEL, COMPANY, SUBSCRIPTION, FAIL):
      return evolve(
        { subscriptionCanceling: F, updatingErr: always(payload.err) },
        state,
      )

    case getActionType(RENEW, COMPANY, SUBSCRIPTION, START):
      return evolve({ subscriptionRenewing: T }, state)

    case getActionType(RENEW, COMPANY, SUBSCRIPTION, SUCCESS):
      return evolve(
        {
          subscriptionRenewing: F,
          updatingErr: always(null),
        },
        state,
      )

    case getActionType(RENEW, COMPANY, SUBSCRIPTION, FAIL):
      return evolve(
        { subscriptionRenewing: F, updatingErr: always(payload.err) },
        state,
      )

    case getActionType(ORDER, COMPANY, SUBSCRIPTION, START):
      return evolve({ subscriptionOrdering: T }, state)

    case getActionType(ORDER, COMPANY, SUBSCRIPTION, SUCCESS):
      return evolve(
        {
          entities: {
            [state.id]: {
              subscriptions: over(lensIndex(0), assoc("status", "opened_paid")),
            },
          },
          subscriptionOrdering: F,
          updatingErr: always(null),
        },
        state,
      )

    case getActionType(ORDER, COMPANY, SUBSCRIPTION, FAIL):
      return evolve(
        { subscriptionOrdering: F, updatingErr: always(payload.err) },
        state,
      )

    case getActionType(GET, COMPANY, REWARDS, START):
      return evolve(
        {
          isLoadingCompanyRewards: T,
        },
        state,
      )
    case getActionType(GET, COMPANY, REWARDS, SUCCESS):
      return evolve(
        {
          isLoadingCompanyRewards: F,
          companyRewards: always(payload.companyRewards),
        },
        state,
      )
    case getActionType(GET, COMPANY, REWARDS, FAIL):
      return evolve(
        {
          isLoadingCompanyRewards: F,
        },
        state,
      )

    case getActionType(GET, COMPANY, REWARDS, TIME, SERIES, START):
      return evolve(
        {
          areCompanyRewardsTimeSeriesByDataKeyLoading: evolve({
            [payload.dataKey]: T,
          }),
        },
        state,
      )
    case getActionType(GET, COMPANY, REWARDS, TIME, SERIES, SUCCESS):
      return evolve(
        {
          areCompanyRewardsTimeSeriesByDataKeyLoading: evolve({
            [payload.dataKey]: F,
          }),
          companyRewardsTimeSeriesByRangeId: over(
            lensProp(payload.rangeId),
            always(payload.companyRewardsTimeSeries),
          ),
        },
        state,
      )
    case getActionType(GET, COMPANY, REWARDS, TIME, SERIES, FAIL):
      return evolve(
        {
          areCompanyRewardsTimeSeriesByDataKeyLoading: evolve({
            [payload.dataKey]: F,
          }),
        },
        state,
      )

    case getActionType(GET, COMPANY, REWARDS, CANDIDATES, START):
      return evolve(
        {
          areCompanyRewardsCandidatesLoading: T,
        },
        state,
      )
    case getActionType(GET, COMPANY, REWARDS, CANDIDATES, SUCCESS):
      return evolve(
        {
          areCompanyRewardsCandidatesLoading: F,
          companyRewardsCandidates: always(
            evolve(
              {
                locationCountries: pipe(
                  filter(propSatisfies(Boolean, "country")),
                  sort(descend(prop("count"))),
                  slice(0, 15),
                ),
                desiredRelocationCities: pipe(
                  filter(propSatisfies(Boolean, "city")),
                  sort(descend(prop("count"))),
                  slice(0, 15),
                ),
              },
              payload.companyRewardsCandidates,
            ),
          ),
        },
        state,
      )
    case getActionType(GET, COMPANY, REWARDS, CANDIDATES, FAIL):
      return evolve(
        {
          areCompanyRewardsCandidatesLoading: F,
        },
        state,
      )

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
