import { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import { PopoverBody } from "reactstrap"
import { format, parse } from "date-fns"
import { prop } from "ramda"

import { getCampaignList } from "../../../../actions/vr-campaign-actions"
import { campaignsSelector } from "../../../../selectors/vr-campaign-selector"
import { InfoHint } from "../../../common/info-hint-component"

import styles from "./campaigns-list.module.scss"

const getDuration = ({ startDate, endDate }) =>
  [startDate, endDate]
    .map(d => parse(d, "yyyy-MM-dd", new Date()))
    .map(d => format(d, "dd.MM.yyyy", new Date()))
    .join(" – ")

export const CampaignsListComponent = ({
  getCampaignList,
  campaigns,
  candidatesOpenClickHandler,
}) => {
  useEffect(() => {
    getCampaignList()
  }, [getCampaignList])

  return (
    <div className={styles.table}>
      <div className={styles.head}>
        <p className={styles.nr}>
          <FormattedMessage id="app.modal.virtualRecruiter.nr" />
        </p>
        <p className={styles.jobTitle}>
          <FormattedMessage id="app.modal.virtualRecruiter.jobTitle" />
        </p>
        <p className={styles.duration}>
          <FormattedMessage id="app.modal.virtualRecruiter.duration" />
        </p>
        <p className={styles.invited}>
          <FormattedMessage id="app.modal.virtualRecruiter.invited" />
        </p>
        <p className={styles.connected}>
          <FormattedMessage id="app.modal.virtualRecruiter.connected" />
        </p>
        <p className={styles.responded}>
          <FormattedMessage id="app.modal.virtualRecruiter.responded" />
        </p>
        <p className={styles.applicants}>
          <FormattedMessage id="app.modal.virtualRecruiter.applicants" />
          <InfoHint
            id="campaignStatApplicants"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.modal.virtualRecruiter.applicants.hint" />
              </PopoverBody>
            }
          />
        </p>
      </div>

      {campaigns.map((campaign, i) => {
        const { id, jobTitle, startDate, endDate, statistics } = campaign

        return (
          <div className={styles.item} key={id}>
            <p className={styles.nr}>{i + 1}</p>
            <p className={styles.jobTitle}>{jobTitle}</p>
            <p className={styles.duration}>
              {getDuration({ startDate, endDate })}
            </p>
            <p className={styles.invited}>
              {prop("invited", statistics) && (
                <>
                  <span className={styles.mobileStatLabel}>
                    <FormattedMessage id="app.modal.virtualRecruiter.invited" />
                    {` – `}
                  </span>
                  {prop("invited", statistics)}
                </>
              )}
            </p>
            <p className={styles.connected}>
              {prop("connected", statistics) && (
                <>
                  <span className={styles.mobileStatLabel}>
                    <FormattedMessage id="app.modal.virtualRecruiter.connected" />
                    {` – `}
                  </span>
                  {prop("connected", statistics)}
                </>
              )}
            </p>
            <p className={styles.responded}>
              {prop("responded", statistics) && (
                <>
                  <span className={styles.mobileStatLabel}>
                    <FormattedMessage id="app.modal.virtualRecruiter.responded" />
                    {` – `}
                  </span>
                  {prop("responded", statistics)}
                </>
              )}
            </p>
            <p className={styles.applicants}>
              {!!prop("success", statistics) && (
                <button
                  className={styles.openBtn}
                  onClick={() => candidatesOpenClickHandler(campaign)}
                >
                  <FormattedMessage id="app.modal.virtualRecruiter.open" />
                </button>
              )}
            </p>
          </div>
        )
      })}
    </div>
  )
}

CampaignsListComponent.propTypes = {
  getCampaignList: PropTypes.func,
  candidatesOpenClickHandler: PropTypes.func,
  campaigns: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    campaigns: campaignsSelector(state),
  }
}

const mapDispatchToProps = {
  getCampaignList,
}

export const CampaignsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignsListComponent)
