import { useMutation } from "react-query"

export const useAxiosMutation = (fn, options = {}) =>
  useMutation(
    async (...args) => {
      const res = await fn(...args)
      return res.data
    },
    {
      ...options,
    },
  )
