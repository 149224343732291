import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { getLanguagesDiff } from "../../lib/helpers"
import { DetailViewProgressBar } from "../detail-view/detail-view-progress-bar-component"
import { LANG_LEVELS } from "../../default-options"
import { FormattedMessage } from "react-intl"

export const MatchLanguagesComparison = ({ candidate, job }) => {
  const {
    matched: matchedLanguages,
    missing: missingLanguages,
    rest: otherLanguages,
  } = getLanguagesDiff(candidate.languages, job.languages)

  return (
    <Fragment>
      {matchedLanguages.map(language => (
        <DetailViewProgressBar
          type="sufficient"
          key={language.id}
          diff={language.diff}
          total={LANG_LEVELS.length}
          completed={language.level}
          title={language.name}
          value={<FormattedMessage id={LANG_LEVELS[language.level].name} />}
          requiredValue={
            <>
              <FormattedMessage id="app.page.match.languages.required" />
              <FormattedMessage
                id={LANG_LEVELS[language.level + language.diff].name}
              />
            </>
          }
          requiredMessage={
            <FormattedMessage id="app.page.match.languages.requiredLevelInsufficient" />
          }
        />
      ))}
      {otherLanguages.map(language => (
        <DetailViewProgressBar
          key={language.id}
          total={LANG_LEVELS.length}
          completed={language.level}
          title={language.name}
          value={<FormattedMessage id={LANG_LEVELS[language.level].name} />}
        />
      ))}
      {missingLanguages.length > 0 && (
        <div className="text-muted text-uppercase text-smaller mt-3">
          <FormattedMessage id="app.page.match.missingLanguages" />
        </div>
      )}
      {missingLanguages.map(language => (
        <DetailViewProgressBar
          type="insufficient"
          key={language.id}
          color="danger-light"
          total={LANG_LEVELS.length}
          completed={language.level}
          title={language.name}
          value={<FormattedMessage id={LANG_LEVELS[language.level].name} />}
        />
      ))}
    </Fragment>
  )
}

MatchLanguagesComparison.propTypes = {
  candidate: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
}
