import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormFeedback, FormGroup, Input, Label, PopoverBody } from "reactstrap"
import { Element as ScrollElement } from "react-scroll"
import { useFormikField } from "../../hooks/use-formik-field"
import { FormattedErrorMessage } from "../common/formatted-error-message"
import { Select } from "../common/select-component-new"
import { InfoHint } from "../common/info-hint-component"

export const LabeledSelectField = ({
  label,
  hint,
  id,
  isRequired,
  ...rest
}) => {
  const [field, meta, actions] = useFormikField(rest)

  return (
    <FormGroup>
      <ScrollElement name={`form-position-${rest.name}`} />
      <Label for={id}>
        {label}
        {isRequired && " *"}
        {hint && (
          <InfoHint
            id={`${id}-hint`}
            color="primary"
            popover={<PopoverBody>{hint}</PopoverBody>}
          />
        )}
      </Label>
      <Select
        {...field}
        onBlur={actions.setTouched}
        onChange={actions.setValue}
        invalid={meta.touched && Boolean(meta.error)}
        touched={meta.touched}
        {...rest}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

export const SelectField = props => {
  const [field, meta, actions] = useFormikField(props)

  return (
    <Fragment>
      <Select
        {...field}
        {...meta}
        {...actions}
        onBlur={actions.setTouched}
        onChange={actions.setValue}
        invalid={meta.touched && Boolean(meta.error)}
        touched={meta.touched}
        {...props}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </Fragment>
  )
}

LabeledSelectField.propTypes = {
  label: PropTypes.node.isRequired,
  hint: PropTypes.node,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
}

LabeledSelectField.defaultProps = {
  hint: null,
}
