import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { LabeledInputField } from "../fields/input-field"
import { LabeledSwitchField } from "../fields/switch-field"
import { LabeledSelectFromBadgesField } from "../fields/select-from-badges-field"
import { FormattedMessage } from "react-intl"

export const ManagerFormFields = ({ availableTeams, readOnlyFields }) => {
  return (
    <Fragment>
      <LabeledInputField
        name="firstName"
        id="managerFirstName"
        label={<FormattedMessage id="app.form.name.first" />}
        isRequired
        placeholder="John"
        readOnly={readOnlyFields.includes("firstName")}
      />
      <LabeledInputField
        name="lastName"
        id="managerLastName"
        label={<FormattedMessage id="app.form.name.last" />}
        isRequired
        placeholder="Doe"
        readOnly={readOnlyFields.includes("lastName")}
      />
      <LabeledInputField
        name="email"
        id="managerEmail"
        label={<FormattedMessage id="app.form.email" />}
        isRequired
        placeholder="email@example.com"
        readOnly={readOnlyFields.includes("email")}
      />
      <LabeledSwitchField
        name="isAdmin"
        id="managerIsAdmin"
        label={<FormattedMessage id="app.form.rights.admin" />}
        hint={<FormattedMessage id="app.form.rights.admin.hint" />}
        disabled={readOnlyFields.includes("isAdmin")}
      />
      <LabeledSelectFromBadgesField
        name="teams"
        id="managerTeams"
        label={<FormattedMessage id="app.form.teams" />}
        items={availableTeams}
        nameKey="name"
        valueKey="id"
      />
    </Fragment>
  )
}

ManagerFormFields.propTypes = {
  availableTeams: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
}

ManagerFormFields.defaultProps = {
  readOnlyFields: [],
}
