import React from "react"
import styles from "./active-jobs.module.scss"
import { SlicedDoughnuts } from "../lib/sliced-doughnuts/sliced-doughnuts"
import { careerLevelMapper, categoryMapper } from "./helpers"
import { FormattedMessage } from "react-intl"
import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { ChartEmptyState } from "../chart-empty-state/chart-empty-state"
import emptyIcon from "../../../assets/img/illustration-bag.svg"

export function ActiveJobs() {
  const { data } = useAxiosQuery(
    ApiKeys.ActiveJobsStats,
    moberriesApi.getActiveJobsStats,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const levelData = careerLevelMapper(data?.careerLevel || {})
  const categoryData = categoryMapper(
    data?.categories && Array.isArray(data.categories) ? data?.categories : [],
  )

  const isEmpty = !levelData.length && !categoryData.length

  if (isEmpty) {
    return (
      <ChartEmptyState
        title="app.page.analytics.charts.activeJobs.name"
        info="app.page.analytics.charts.common.empty.text"
        img={emptyIcon}
      />
    )
  }

  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="app.page.analytics.charts.activeJobs.name" />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.col}>
              <SlicedDoughnuts
                title="app.page.analytics.charts.activeJobs.career.name"
                data={levelData}
              />
            </div>
            <div className={styles.col}>
              <SlicedDoughnuts
                title="app.page.analytics.charts.activeJobs.category.name"
                data={categoryData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
