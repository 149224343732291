import React, { Fragment, useEffect } from "react"
import { connect } from "react-redux"
import { prop, pathEq } from "ramda"
import { FormattedMessage } from "react-intl"
import {
  getCompanyJobList,
  updateJobsConditions,
} from "../../actions/job-actions"
import PropTypes from "prop-types"
import {
  countSelector,
  jobsSelector,
  isLoadingSelector,
  isUpdatingJobsConditionsSelector,
} from "../../selectors/job-selector"
import { CustomInput, Label, Container } from "reactstrap"
import { useList } from "../../hooks/use-list"
import { Button } from "../common/button-component"
import { BulletListLoader } from "../loaders/bullet-list-loader"
import { usePrevious } from "react-hanger"
import cn from "classnames"
import { ACT, JOB_CONDITION_TYPES } from "../../constants"
import { compose } from "redux"
import { push } from "connected-react-router"
import { FormattedJobSlots } from "../common/formatted-job-slots"
import { useStore } from "../../lib/store"
import { jobSlotsLeftSelector } from "../../lib/helpers"

export const JobsConditionsPageComponent = ({
  jobs,
  count,
  isLoading,
  getCompanyJobList,
  updateJobsConditions,
  isUpdatingJobsConditions,
  push,
}) => {
  const company = useStore(state => state.company)
  const jobSlotsLeft = useStore(jobSlotsLeftSelector)
  const { value: selectedJobs, toggle, has: isJobSelected, set } = useList()
  const prevIsLoading = usePrevious(isLoading)

  useEffect(() => {
    getCompanyJobList({
      companyId: company.id,
      params: {
        limit: 999,
        status__in: [ACT],
      },
    })
  }, [company.id, getCompanyJobList])

  useEffect(() => {
    if (!isLoading && prevIsLoading) {
      set(
        jobs
          .filter(pathEq(["condition", "type"], JOB_CONDITION_TYPES.flat))
          .map(prop("id")),
      )
      if (!jobs.length) {
        push("/")
      }
    }
  }, [set, jobs, isLoading, prevIsLoading, push])

  return (
    <Fragment>
      <Container className="job-conditions-page-container d-flex flex-column">
        <h1>
          <FormattedMessage id="app.page.subscription.jobsConditions.header" />
        </h1>
        <div className="p-3 bg-white rounded">
          <h4>
            <FormattedMessage id="app.page.subscription.jobsConditions.blockTitle" />
          </h4>
          <FormattedMessage id="app.page.subscription.jobsConditions.blockDescription" />
        </div>

        <div className="d-flex justify-content-between bg-white px-3">
          <h5 className="mb-0 font-weight-normal">
            <FormattedMessage id="app.page.subscription.jobsConditions.listTitle" />
          </h5>
          <span
            className={cn({
              "text-danger": selectedJobs.length > jobSlotsLeft,
              "text-success": selectedJobs.length <= jobSlotsLeft,
            })}
          >
            {selectedJobs.length}
            <span className="text-muted">
              /
              <FormattedJobSlots jobSlots={jobSlotsLeft} />
            </span>
          </span>
        </div>
        {isLoading && (
          <div className="bg-white p-3 h-25 flex-grow-1 flex-shrink-1">
            <BulletListLoader count={6} />
          </div>
        )}
        {!isLoading && count > 0 && (
          <div className="bg-white flex-grow-1 flex-shrink-1 overflow-auto p-3">
            {jobs.map(j => (
              <div
                key={j.id}
                className="py-2 border-top align-items-center d-flex justify-content-between"
              >
                <span>{j.title}</span>
                <div className="d-flex align-items-center justify-content-around">
                  <Label className="mb-0" for={`job-${j.id}`}>
                    <FormattedMessage id="app.common.flat" />
                  </Label>
                  <CustomInput
                    id={`job-${j.id}`}
                    className="pr-3 ml-2 d-inline"
                    checked={isJobSelected(j.id)}
                    onChange={() => toggle(j.id)}
                    disabled={
                      (!isJobSelected(j.id) &&
                        selectedJobs.length >= jobSlotsLeft) ||
                      isUpdatingJobsConditions
                    }
                    type="switch"
                    name={j.title}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="p-3 border-top d-flex justify-content-end flex-shrink-0 bg-white">
          <Button
            color="primary"
            disabled={
              selectedJobs.length > jobSlotsLeft ||
              isLoading ||
              isUpdatingJobsConditions
            }
            loading={isUpdatingJobsConditions}
            onClick={() =>
              updateJobsConditions({
                data: jobs.map(job => ({
                  id: job.id,
                  type: isJobSelected(job.id)
                    ? JOB_CONDITION_TYPES.flat
                    : JOB_CONDITION_TYPES.pph,
                })),
                companyId: company.id,
              })
            }
          >
            <FormattedMessage id="app.common.confirm" />
          </Button>
        </div>
      </Container>
    </Fragment>
  )
}

JobsConditionsPageComponent.propTypes = {
  getCompanyJobList: PropTypes.func.isRequired,
  count: PropTypes.number,
  jobs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateJobsConditions: PropTypes.func.isRequired,
  isUpdatingJobsConditions: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  getCompanyJobList,
  updateJobsConditions,
  push,
}

const mapStateToProps = function (state, o) {
  return {
    jobs: jobsSelector(state),
    count: countSelector(state),
    isLoading: isLoadingSelector(state),
    isUpdatingJobsConditions: isUpdatingJobsConditionsSelector(state),
  }
}

export const JobsConditionsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(JobsConditionsPageComponent)
