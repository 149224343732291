import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { useAxiosQuery } from "../../../hooks/use-axios-query"
import { api as moberriesApi, ApiKeys } from "../../../lib/moberries-api"
import { LinesV2 } from "../../charts/lib/lines"
import { ValuePicker } from "../../charts/lib/value-picker/value-picker"
import { ChartLegend } from "../../charts/chart-legend/chart-legend"
import { AnalyticsChartLayout } from "../../layouts/analytics/analytics-chart-layout/analytics-chart-layout"
import { ChartSkeletonState } from "../chart-skeleton-state/chart-skeleton-state"
import { ChartEmptyStateV2 } from "../chart-empty-state/chart-empty-state"
import { availableSelections, mapper, empty } from "./helpers"

import emptyIcon from "../../../assets/img/illustration-search.svg"
import styles from "./job-offer-popularity-rate.module.scss"

export const JobOfferPopularityRateChart = ({ categories }) => {
  const availableCategories = categories
  const [categorySelection, setCategorySelection] = useState(
    availableCategories[0], // default selection: Software Development
  )
  const [monthSelection, setMonthSelection] = useState(
    availableSelections[new Date().getMonth()], // default selection: Current Month
  )
  const { data, isLoading } = useAxiosQuery(
    [
      ApiKeys.JobOfferStats,
      {
        id: categorySelection.value,
        startDate: monthSelection.start,
        endDate: monthSelection.end,
      },
    ],
    moberriesApi.getJobOfferStats,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  const isEmpty = empty(data)
  const chartProps = !isEmpty ? mapper(data) : {}

  if (isLoading) {
    return (
      <ChartSkeletonState
        title={
          <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobOfferPopularityRate.header" />
        }
      />
    )
  }

  if (isEmpty && !isLoading) {
    return (
      <ChartEmptyStateV2
        title={
          <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobOfferPopularityRate.header" />
        }
        info={
          <FormattedMessage
            id="app.page.analytics.strategyBuilder.charts.jobOfferPopularityRate.empty"
            values={{
              category: <FormattedMessage id={categorySelection.label} />,
              month: <FormattedMessage id={monthSelection.label} />,
            }}
          />
        }
        img={emptyIcon}
      >
        <div className={styles.head}>
          <ValuePicker
            availableValues={availableCategories}
            setSelected={setCategorySelection}
            selectedValue={categorySelection}
          />
          <ValuePicker
            availableValues={availableSelections}
            setSelected={setMonthSelection}
            selectedValue={monthSelection}
          />
        </div>
      </ChartEmptyStateV2>
    )
  }

  return (
    <AnalyticsChartLayout
      title={
        <FormattedMessage id="app.page.analytics.strategyBuilder.charts.jobOfferPopularityRate.header" />
      }
    >
      <div className={styles.head}>
        <ValuePicker
          availableValues={availableCategories}
          setSelected={setCategorySelection}
          selectedValue={categorySelection}
        />
        <ValuePicker
          availableValues={availableSelections}
          setSelected={setMonthSelection}
          selectedValue={monthSelection}
        />
      </div>
      <LinesV2 {...chartProps} />
      <div className={styles.bottom}>
        <div className={styles.legends}>
          <ChartLegend
            title="app.page.analytics.strategyBuilder.charts.jobOfferPopularityRate.legends.yourCompany"
            color="#2a63da"
          />
          <ChartLegend
            title="app.page.analytics.strategyBuilder.charts.jobOfferPopularityRate.legends.industry"
            color="#e7503b"
          />
        </div>
      </div>
    </AnalyticsChartLayout>
  )
}

JobOfferPopularityRateChart.propTypes = {
  categories: PropTypes.array.isRequired,
}
