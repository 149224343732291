import React from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Container, Col, Row } from "reactstrap"

import { config } from "../../../config"
import { store } from "../../../lib/store"
import { SingleColumnLayout } from "../../../components/layouts/single-column-layout"
import { Card } from "../../../components/common/card-component/card-component-aboard"

import acceptDecline from "../../../assets/img/accept_decline.svg"
import analytics from "../../../assets/img/analytics.svg"
import connectAts from "../../../assets/img/connect_your_ats.svg"
import monetize from "../../../assets/img/monetize.svg"

import styles from "./dashboard-instructions-page.module.scss"

const { jwt } = store.getState()
const affiliateRedirectUrl = `${config.moberries.affiliate.url}?token=${jwt}&type=redirect&purpose=0`

const INSTRUCTIONS = [
  {
    img: connectAts,
    title: "app.page.dashboard.instructions.jobPosting.title",
    description: "app.page.dashboard.instructions.jobPosting.description",
    footer: {
      text: "app.page.dashboard.instructions.jobPosting.moreInfo",
      url: `${config.moberries.homepage.url}/page/company-guide/`,
    },
  },
  {
    img: acceptDecline,
    title: "app.page.dashboard.instructions.candidates.title",
    description: "app.page.dashboard.instructions.candidates.description",
    footer: {
      text: "app.page.dashboard.instructions.candidates.moreInfo",
      url: `${config.moberries.homepage.url}/page/extended-feedback/`,
    },
  },
  {
    img: analytics,
    title: "app.page.dashboard.instructions.analytics.title",
    description: "app.page.dashboard.instructions.analytics.description",
    footer: {
      text: "app.page.dashboard.instructions.analytics.moreInfo",
      url: `${config.baseUrl}/analytics`,
    },
  },
  {
    img: monetize,
    title: "app.page.dashboard.instructions.monetization.title",
    description: "app.page.dashboard.instructions.monetization.description",
    footer: {
      text: "app.page.dashboard.instructions.monetization.moreInfo",
      url: affiliateRedirectUrl,
    },
  },
]

export const DashboardInstructionsPage = () => (
  <>
    <Container className="container-dashboard">
      <div>
        <div className={styles.header}>
          <FormattedMessage id="app.page.dashboard.instructions.header" />
        </div>
        <div className={styles.subheader}>
          <FormattedMessage id="app.page.dashboard.instructions.subheader" />
        </div>
      </div>
    </Container>

    <SingleColumnLayout>
      <Row>
        {INSTRUCTIONS.map(({ img, title, description, footer }) => (
          <Col key={title} sm={12} md={6} lg={6} xl={3}>
            <Card
              img={img}
              title={title}
              description={description}
              footer={footer}
            />
          </Col>
        ))}
      </Row>

      <Container className="container-dashboard">
        <div className={styles.footer}>
          <Link to="/job/create">
            <FormattedMessage id="app.page.dashboard.instructions.footer" />
          </Link>
        </div>
      </Container>
    </SingleColumnLayout>
  </>
)
