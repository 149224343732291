import React from "react"
import { TabPane } from "reactstrap"
import { AtsIntegrationSettingsLayout } from "../components/layouts/ats-integration-settings-layout"
import { useDispatch } from "react-redux"
import { is, propOr } from "ramda"
import { Button } from "../components/common/button-component"
import { FormattedMessage } from "react-intl"
import { Redirect, useHistory, useRouteMatch } from "react-router"
import { useOpenClose } from "../hooks/use-open-close"
import { AtsDisconnectModal } from "../components/modals/ats-disconnect-modal"
import { useStore } from "../lib/store"
import { atsApi } from "../lib/ats-api"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { alertTypes, showAlert } from "../lib/alerts"
import {
  ALERT_COLORS,
  ATS,
  COMPANY,
  CREATE,
  DELETE,
  INTEGRATION,
  SUCCESS,
} from "../constants"
import { config } from "../config"
import { decamelizeKeys } from "humps"
import qs from "qs"
import { Form, Formik } from "formik"
import { requiredValidator } from "../validators/required-validator"
import { AtsInput } from "../components/integration/ats-input-component"
import { AtsSelect } from "../components/integration/ats-select-component"
import { setATSFormikErrorField } from "../lib/helpers/form-helpers"
import { getActionType, getProviderBySlug } from "../lib/helpers"
import { refreshAtsConnection } from "../controllers/ats-controller"

const atsToCreateRequest = data => {
  const transformed = {}
  Object.entries(data).forEach(([key, value]) => {
    typeof value === "object" && value !== null && value.hasOwnProperty("value")
      ? (transformed[key] = value.value)
      : (transformed[key] = value)
  })
  return transformed
}

export const AtsIntegrationConnectionPage = () => {
  const routeMatch = useRouteMatch()

  const locale = useStore(state => state.locale)
  const company = useStore(state => state.company)
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const providers = useStore(state => state.providers)
  const selectedProvider = getProviderBySlug(providers, routeMatch.params.slug)

  const { goBack, push } = useHistory()
  const dispatch = useDispatch()

  const { mutateAsync: createAtsCompanyIntegration, isLoading: isCreating } =
    useAxiosMutation(atsApi.createAtsCompanyIntegration, {
      onSuccess: async data => {
        await refreshAtsConnection()
        useStore.setState({
          isAtsConnectionFlowInProgress: true,
        })
        push("/integrations/ats/options")
        dispatch({
          type: getActionType(CREATE, ATS, COMPANY, INTEGRATION, SUCCESS),
          payload: {
            integration: data,
          },
        })
      },
      onError: () => {
        showAlert({
          code: alertTypes.atsCompanyCreationFailed,
          color: ALERT_COLORS.ERROR,
        })
      },
    })

  const { mutateAsync: updateAtsCompanyIntegration, isLoading: isUpdating } =
    useAxiosMutation(atsApi.updateAtsCompanyIntegration, {
      onSuccess: async (res, variables) => {
        await refreshAtsConnection()
        if (variables.redirectTo) {
          push(variables.redirectTo)
        }
      },
    })

  const { mutateAsync: deleteAtsCompanyIntegration, isLoading: isDeleting } =
    useAxiosMutation(atsApi.deleteAtsCompanyIntegration, {
      onSuccess: async () => {
        push("/integrations/ats")
        await refreshAtsConnection()
        dispatch({
          type: getActionType(DELETE, ATS, COMPANY, INTEGRATION, SUCCESS),
          payload: {
            integration: {
              id: atsCompanyIntegration.id,
            },
          },
        })
      },
    })

  const initiateOAuthAtsCompanyIntegration = data => {
    const isProd = config.branch.isRelease
    const oauthProviderConfig =
      !isProd && selectedProvider.oauth.authorizationSandbox
        ? selectedProvider.oauth.authorizationSandbox
        : selectedProvider.oauth.authorization

    const state = {
      provider: selectedProvider.slug,
      data: {
        ...data,
        redirectUri: config.oauth.redirectUrl,
      },
      companyId: company.id,
      from: "/integrations/ats/options",
    }

    const sanitizedQueryObject = {}
    Object.entries(decamelizeKeys(oauthProviderConfig.params)).forEach(
      ([key, value]) => {
        // By a convention when we receive { key: <key> } property, it's a placeholder and we must replace it
        if (value === `<${key}>`) {
          sanitizedQueryObject[key] = decamelizeKeys(state.data)[key]
        } else {
          sanitizedQueryObject[key] = value
        }
      },
    )
    const url = oauthProviderConfig.url
    const query = qs.stringify(sanitizedQueryObject, { addQueryPrefix: true })
    const stateQuery = `&state=${window.btoa(JSON.stringify(state))}`
    window.location = `${url}${query}${stateQuery}`
  }

  const {
    value: isDisconnectModalOpen,
    close,
    open,
  } = useOpenClose({
    initialValue: false,
  })

  if (
    atsCompanyIntegration &&
    atsCompanyIntegration.provider !== selectedProvider.slug
  ) {
    return <Redirect to="/integrations/ats" />
  }

  const isProviderConnected =
    selectedProvider &&
    propOr("", "provider", atsCompanyIntegration) === selectedProvider.slug

  const providerCompany = propOr("", "providerCompany", atsCompanyIntegration)

  const validate = values => {
    const errors = {}
    selectedProvider?.connectionParams.forEach(field => {
      if (field.required && !values[field.name]) {
        errors[field.name] = { id: "app.validators.value.required" }
      }
    })
    return errors
  }

  const initialValues = is(Object, providerCompany)
    ? providerCompany
    : Object.fromEntries(
        selectedProvider?.connectionParams.map(f => [f.name, ""]),
      )

  return (
    <AtsIntegrationSettingsLayout
      provider={selectedProvider}
      isProviderConnected={isProviderConnected}
    >
      <AtsDisconnectModal
        onConfirm={() => {
          deleteAtsCompanyIntegration({
            id: atsCompanyIntegration.id,
          })
          close()
        }}
        onClose={close}
        isOpen={isDisconnectModalOpen}
      />

      <TabPane className="bg-white rounded">
        <Formik
          onSubmit={(data, { setFieldError }) => {
            if (selectedProvider.oauth) {
              initiateOAuthAtsCompanyIntegration(atsToCreateRequest(data))
            } else {
              createAtsCompanyIntegration({
                provider: selectedProvider.slug,
                companyId: company.id,
                data: atsToCreateRequest(data),
              }).catch(e => setATSFormikErrorField(setFieldError, e))
            }
          }}
          initialValues={initialValues}
          validate={validate}
        >
          <Form>
            <div className="pt-3 px-3 border-bottom">
              {selectedProvider.connectionParams.map(f => {
                let Field = AtsInput
                if (f.type === "select") {
                  Field = AtsSelect
                }
                return (
                  <Field
                    key={f.name}
                    id={f.id}
                    name={f.name}
                    opts={f.opts}
                    providerSlug={selectedProvider.slug}
                    validate={f.required ? [requiredValidator] : []}
                    readOnly={isProviderConnected}
                    updateField={data =>
                      updateAtsCompanyIntegration({
                        provider: selectedProvider.slug,
                        id: atsCompanyIntegration.id,
                        data,
                      })
                    }
                    editable={f.editable && isProviderConnected}
                    isUpdating={isUpdating}
                    isRequired={f.required}
                  />
                )
              })}
              <p>
                {(!selectedProvider.connectionParams ||
                  !selectedProvider.connectionParams.length) && (
                  <FormattedMessage id="app.page.integrations.connect.noSettingsInformation" />
                )}
                <FormattedMessage
                  id="app.page.integrations.connect.descriptionPostText"
                  values={{
                    here: (
                      <a
                        href={propOr("", locale, selectedProvider.guideUrl)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="app.page.integrations.connect.descriptionPostText.here" />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
            <div className="p-3 d-flex justify-content-between">
              <div className="d-flex align-items-center font-weight-bolder">
                {atsCompanyIntegration && (
                  <FormattedMessage id="app.page.integrations.settings.disconnect.warning" />
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  color="light"
                  className="mr-2 border-0"
                  onClick={goBack}
                >
                  <FormattedMessage id="app.common.back" />
                </Button>
                {isProviderConnected ? (
                  <Button
                    color="danger"
                    type="button"
                    onClick={open}
                    disabled={isDeleting}
                    loading={isDeleting}
                  >
                    <FormattedMessage id="app.common.disconnect" />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={isCreating}
                    loading={isCreating}
                  >
                    <FormattedMessage id="app.common.connect" />
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </Formik>
      </TabPane>
    </AtsIntegrationSettingsLayout>
  )
}
