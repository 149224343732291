import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { Button } from "../common/button-component"
import { PAYMENT_METHODS } from "../../constants"
import { FormattedMessage } from "react-intl"

export const SubscriptionPaymentMethodSelect = ({
  isLoading,
  onSetPaymentMethod,
  paymentMethod,
  isInvoiceDisabled,
}) => {
  return (
    <Row noGutters className="mb-3">
      <Col xs={12} md={6} className="pr-md-2">
        <Button
          disabled={isLoading}
          onClick={() => onSetPaymentMethod(PAYMENT_METHODS.CARD)}
          size="lg"
          block
          color={
            paymentMethod === PAYMENT_METHODS.CARD ? "success-light" : "light"
          }
          className="py-4"
        >
          <i className="fad h2 fa-credit-card text-muted" />
          <h6 className="mb-0 mt-1">
            <FormattedMessage id="app.page.subscription.order.payment.method.card" />
          </h6>
        </Button>
      </Col>
      <Col xs={12} md={6} className="pl-md-2">
        <Button
          onClick={() => onSetPaymentMethod(PAYMENT_METHODS.INVOICE)}
          disabled={isInvoiceDisabled || isLoading}
          size="lg"
          block
          color={
            paymentMethod === PAYMENT_METHODS.INVOICE
              ? "success-light"
              : "light"
          }
          className="py-4"
        >
          <i className="fas h2 fa-file-invoice text-muted" />
          <h6 className="mb-0 mt-1">
            <FormattedMessage id="app.page.subscription.order.payment.method.invoice" />
          </h6>
        </Button>
      </Col>
    </Row>
  )
}

SubscriptionPaymentMethodSelect.propTypes = {
  isLoading: PropTypes.bool,
  onSetPaymentMethod: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  isInvoiceDisabled: PropTypes.bool,
}
