import React, { useEffect } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { useIntl } from "react-intl"
import { connect } from "react-redux"

import { setVrCandidateSearch } from "../../../../actions/vr-candidate-actions"
import { useDebouncedState } from "../../../../hooks/use-debounced-state"

import styles from "./candidates-search.module.scss"

export const CandidatesSearchComponent = ({ setVrCandidateSearch }) => {
  const { formatMessage } = useIntl()

  const {
    state: search,
    debouncedState: debouncedSearch,
    setState: setSearch,
  } = useDebouncedState("")

  useEffect(() => {
    setVrCandidateSearch({ search: debouncedSearch })
  }, [debouncedSearch, setVrCandidateSearch])

  return (
    <div className={styles.wrapper}>
      <input
        value={search}
        className={styles.input}
        placeholder={formatMessage({
          id: "app.virtualRecruiter.candidate.search.placeholder",
        })}
        onChange={e => setSearch(e.target.value)}
      />
      <i className={cn("fas fa-search", styles.icon)}></i>
    </div>
  )
}

CandidatesSearchComponent.propTypes = {
  setVrCandidateSearch: PropTypes.func,
}

const mapDispatchToProps = {
  setVrCandidateSearch,
}

export const CandidatesSearch = connect(
  null,
  mapDispatchToProps,
)(CandidatesSearchComponent)
