import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  errorInterceptor,
} from "./axios-interceptors"
import { getAuthorization } from "./helpers/api-helpers"
import { store } from "./store"

export const AtsApiV2Keys = {
  JobIntegrations: "JobIntegrations",
}

class AtsApiV2 {
  constructor() {
    const { jwt } = store.getState()

    this.http = axios.create({
      baseURL: config.moberries.ats.url,
      timeout: 90000,
      headers: {
        Authorization: getAuthorization(jwt),
      },
    })
    axiosRetry(this.http, { retries: 3 })

    this.http.interceptors.response.use(response => response, errorInterceptor)
    this.http.interceptors.response.use(saveOriginalInterceptor)
    this.http.interceptors.response.use(camelizeInterceptor)
  }

  get companyId() {
    const { company } = store.getState()
    const { id: companyId } = company ?? {}

    if (!companyId) {
      throw new Error("There's no companyId in the store!")
    }

    return companyId
  }

  setDefaultHeader = ({ header, value }) => {
    this.http.defaults.headers[header] = value
  }

  getJobIntegrations = params =>
    this.http.get(`/api/v2/job-integrations`, { params })

  excludeJobsFromSync = ({ moJobIds, excludeFromSync }) =>
    this.http.post(`/api/v2/exclude-jobs-from-sync`, {
      moJobIds,
      excludeFromSync,
    })
}

const atsApiV2 = new AtsApiV2()

store.subscribe(({ jwt }) => {
  atsApiV2.setDefaultHeader(
    { header: "Authorization", value: getAuthorization(jwt) },
    store => store.jwt,
  )
})

export { atsApiV2 }
