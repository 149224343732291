import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { differenceInDays, addMonths, parseISO } from "date-fns"
import { TabPane, PopoverBody } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { SubscriptionPageLayout } from "../components/layouts/subscription-page-layout"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { Button } from "../components/common/button-component"
import { InfoHint } from "../components/common/info-hint-component"
import { camelize } from "humps"
import { PriceComponent } from "../components/common/price-component"
import {
  SUBSCRIPTION_TYPE_TEXT_MAP,
  SUBSCRIPTION_STATUSES_TEXT_MAP,
  SUBSCRIPTION_STATUSES_COLOR_MAP,
} from "../default-options"
import {
  cancelCompanySubscription,
  renewCompanySubscription,
} from "../actions/company-actions"
import {
  subscriptionCancelingSelector,
  subscriptionRenewingSelector,
  updatingErrSelector,
} from "../selectors/company-selector"
import {
  isComSubscriptionCanceled,
  isComSubscriptionCancelable,
  isComSubscriptionRenewable,
  isSubscriptionMain,
  isComSubscriptionCanceling,
  getCompanySubscriptionAvailableJobSlots,
  isSubscriptionUpfront,
  preventDefault,
  isComSubscriptionUpgradable,
  isComSubscriptionIncomplete,
  companySubscriptionSelector,
} from "../lib/helpers"
import { SubscriptionCancellationFeedbackModal } from "../components/subscription/subscription-cancellation-feedback-component"
import { FormattedDate } from "../components/common/formatted-date-component"
import { useOpenClose } from "../hooks/use-open-close"
import { usePrevious } from "react-hanger"
import { FormattedJobSlots } from "../components/common/formatted-job-slots"
import { useStore } from "../lib/store"

export const SubscriptionPageComponent = ({
  canceling,
  renewing,
  cancelCompanySubscription,
  renewCompanySubscription,
}) => {
  const company = useStore(state => state.company)
  const companySubscription = useStore(companySubscriptionSelector)
  const updating = canceling || renewing
  const cancelable = isComSubscriptionCancelable(companySubscription)
  const renewable = isComSubscriptionRenewable(companySubscription)
  const upgradable = isComSubscriptionUpgradable(companySubscription)

  const cancellationDate =
    differenceInDays(parseISO(companySubscription.validTo), new Date()) > 30
      ? companySubscription.validTo
      : addMonths(
          parseISO(companySubscription.validTo),
          companySubscription.subscription.durationIntervalCount,
        ).toISOString()

  const updateSubscription = ({ answers, comment }) => {
    if (cancelable) {
      cancelCompanySubscription({
        subscriptionId: companySubscription.id,
        companyId: company.id,
        answers: answers.map(a => a.id),
        comment,
      })
    } else if (renewable) {
      renewCompanySubscription({
        subscriptionId: companySubscription.id,
        companyId: company.id,
      })
    }
  }

  const {
    value: isCancellationModalOpen,
    open: openCancellationModal,
    close: closeCancellationModal,
  } = useOpenClose({ initialValue: false })

  const prevCanceling = usePrevious(canceling)

  useEffect(() => {
    if (!canceling && prevCanceling) {
      closeCancellationModal()
    }
  }, [canceling, prevCanceling, closeCancellationModal])

  const { subscription } = companySubscription

  return (
    <SubscriptionPageLayout>
      <div className="bg-white px-3 py-6">
        <SmallInnerFormLayout>
          <TabPane>
            <ul className="list-unstyled border-top">
              <li className="border-bottom py-2 d-flex justify-content-between">
                <span>
                  <FormattedMessage id="app.page.subscription.type" />
                </span>
                <div>
                  <b>
                    {/* the check can be removed after the trial subscirptions are removed from the backend */}
                    {SUBSCRIPTION_TYPE_TEXT_MAP[
                      camelize(subscription.type)
                    ] && (
                      <FormattedMessage
                        id={
                          SUBSCRIPTION_TYPE_TEXT_MAP[
                            camelize(subscription.type)
                          ]
                        }
                      />
                    )}
                  </b>
                </div>
              </li>
              <li className="border-bottom py-2 d-flex justify-content-between">
                <span>
                  <FormattedMessage id="app.page.subscription.status" />
                </span>
                <b
                  className={`text-${
                    SUBSCRIPTION_STATUSES_COLOR_MAP[companySubscription.status]
                  }`}
                >
                  <FormattedMessage
                    id={
                      SUBSCRIPTION_STATUSES_TEXT_MAP[
                        camelize(companySubscription.status)
                      ]
                    }
                  />
                </b>
              </li>
              {!isComSubscriptionCanceled(companySubscription) && (
                <li className="border-bottom py-2 d-flex justify-content-between">
                  <span>
                    <FormattedMessage
                      id={
                        isComSubscriptionCanceling(companySubscription)
                          ? "app.page.subscription.end"
                          : "app.page.subscription.renewal"
                      }
                    />
                  </span>
                  <div>
                    <b>
                      <FormattedDate value={companySubscription.validTo} />
                    </b>
                  </div>
                </li>
              )}
              {isSubscriptionMain(companySubscription.subscription) && (
                <li className="border-bottom py-2 d-flex justify-content-between">
                  <span>
                    <FormattedMessage id="app.page.subscription.job.slots" />
                  </span>
                  <div>
                    <b>
                      <FormattedJobSlots
                        jobSlots={getCompanySubscriptionAvailableJobSlots(
                          companySubscription,
                        )}
                      />
                    </b>
                    <InfoHint
                      id="jobSlotsHint"
                      popover={
                        <PopoverBody>
                          <FormattedMessage id="app.page.subscription.job.slots.hint" />
                        </PopoverBody>
                      }
                    />
                  </div>
                </li>
              )}
              {isSubscriptionMain(companySubscription.subscription) && (
                <li className="border-bottom py-2 d-flex justify-content-between">
                  {isSubscriptionUpfront(companySubscription.subscription) ? (
                    <Fragment>
                      <span>
                        {companySubscription.coupon ? (
                          <FormattedMessage id="app.page.subscription.order.info.totalPrice.withDiscount" />
                        ) : (
                          <FormattedMessage id="app.page.subscription.order.info.price" />
                        )}
                      </span>
                      <div>
                        <b>
                          <PriceComponent
                            price={
                              companySubscription.billingCycleTotalAmount / 100
                            }
                          />
                        </b>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span>
                        {companySubscription.coupon ? (
                          <FormattedMessage id="app.page.subscription.order.info.price.per.month.withDiscount" />
                        ) : (
                          <FormattedMessage id="app.page.subscription.order.info.price.per.month" />
                        )}
                      </span>
                      <div>
                        <b>
                          <PriceComponent
                            price={
                              companySubscription.billingCycleTotalAmount / 100
                            }
                          />
                        </b>
                      </div>
                    </Fragment>
                  )}
                </li>
              )}

              {companySubscription.coupon && (
                <li className="border-bottom py-2 d-flex justify-content-between">
                  <span>
                    <FormattedMessage
                      id={
                        isSubscriptionUpfront(companySubscription.subscription)
                          ? "app.page.subscription.order.info.totalDiscount"
                          : "app.page.subscription.order.info.discount.per.month"
                      }
                    />
                  </span>
                  <div>
                    <b>
                      <PriceComponent
                        price={
                          (companySubscription.billingCycleRawAmount -
                            companySubscription.billingCycleTotalAmount) /
                          100
                        }
                      />
                    </b>
                  </div>
                </li>
              )}

              {isSubscriptionMain(subscription) && (
                <li className="border-bottom py-2 d-flex justify-content-between">
                  <span>
                    <FormattedMessage id="app.page.subscription.payment.period" />
                  </span>
                  <div>
                    <b>
                      <FormattedMessage
                        id={
                          isSubscriptionUpfront(
                            companySubscription.subscription,
                          )
                            ? "app.common.yes"
                            : "app.common.no"
                        }
                      />
                    </b>
                    <InfoHint
                      id="paymentPeriodHint"
                      popover={
                        <PopoverBody>
                          <FormattedMessage id="app.page.subscription.payment.period.hint" />
                        </PopoverBody>
                      }
                    />
                  </div>
                </li>
              )}
              <li className="border-bottom py-2 d-flex justify-content-between">
                <span>
                  <FormattedMessage id="app.page.subscription.duration" />
                </span>
                <div>
                  <b>
                    {subscription.durationIntervalCount}{" "}
                    <FormattedMessage
                      id={`app.common.${subscription.billingInterval}`}
                      values={{
                        amount: subscription.durationIntervalCount,
                      }}
                    />
                  </b>
                </div>
              </li>
            </ul>

            {upgradable && (
              <Button
                tag={Link}
                to="/subscribe"
                color="primary"
                className="d-block"
              >
                <FormattedMessage id="app.page.subscription.upgrade.button" />
              </Button>
            )}

            {renewable && (
              <Fragment>
                <h5 className="mt-5">
                  <FormattedMessage id="app.page.subscription.renew.title" />
                </h5>
                <p>
                  <FormattedMessage id="app.page.subscription.renew.description" />
                </p>
                <Button
                  block
                  color="primary"
                  onClick={updateSubscription}
                  loading={canceling || renewing}
                  disabled={canceling || renewing}
                >
                  <i className="mr-1 fa fa-check" />
                  <FormattedMessage id="app.page.subscription.renew.button" />
                </Button>
              </Fragment>
            )}
            {cancelable && (
              <Fragment>
                <h5 className="mt-5">
                  <FormattedMessage id="app.page.subscription.cancel.title" />
                </h5>
                <FormattedMessage
                  id="app.page.subscription.cancel.text"
                  values={{
                    link: (
                      <span
                        className="span-link"
                        onClick={preventDefault(openCancellationModal)}
                      >
                        <FormattedMessage id="app.page.subscription.cancel.link" />
                      </span>
                    ),
                  }}
                />
                <p>
                  <FormattedMessage
                    id="app.page.subscription.cancel.description"
                    values={{
                      date: (
                        <b>
                          <FormattedDate value={cancellationDate} />
                        </b>
                      ),
                    }}
                  />
                </p>
              </Fragment>
            )}
            {isComSubscriptionIncomplete(companySubscription) && (
              <Button tag={Link} to="/subscribe" block color="primary">
                <i className="mr-1 fas fa-wallet" />
                <FormattedMessage id="app.page.subscription.finalize" />
              </Button>
            )}
          </TabPane>
        </SmallInnerFormLayout>
      </div>

      {cancelable && (
        <SubscriptionCancellationFeedbackModal
          updateSubscription={updateSubscription}
          updating={updating}
          isOpen={isCancellationModalOpen}
          onClose={closeCancellationModal}
        />
      )}
    </SubscriptionPageLayout>
  )
}

SubscriptionPageComponent.propTypes = {
  canceling: PropTypes.bool.isRequired,
  renewing: PropTypes.bool.isRequired,
  cancelCompanySubscription: PropTypes.func.isRequired,
  renewCompanySubscription: PropTypes.func.isRequired,
}

SubscriptionPageComponent.defaultProps = {
  companySubscription: null,
  canceling: false,
  renewing: false,
}

const mapDispatchToProps = {
  cancelCompanySubscription,
  renewCompanySubscription,
}

const mapStateToProps = function (state) {
  return {
    canceling: subscriptionCancelingSelector(state),
    renewing: subscriptionRenewingSelector(state),
    updatingErr: updatingErrSelector(state),
  }
}

export const SubscriptionPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionPageComponent)
