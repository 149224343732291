import * as yup from "yup"
import isURL from "validator/lib/isURL"

const NAME_MAX_LENGTH = 50
const PHONE_MAX_LENGTH = 50
const EMAIL_MAX_LENGTH = 50

export const companyProfileSchema = yup.object().shape({
  name: yup
    .string()
    .required("app.validators.value.required")
    .max(NAME_MAX_LENGTH, "app.validators.length.max"),
  city: yup.mixed().required("app.validators.value.required"),
  street: yup.string(),
  postcode: yup.string(),
  phone: yup
    .string()
    .required("app.validators.value.required")
    .max(PHONE_MAX_LENGTH, "app.validators.length.max"),
  website: yup
    .string()
    .default("") // This default is here because formik returns undefined instead of default value. isURL doesn't accept anything but strings. https://github.com/formium/formik/issues/2047
    .test("url", "app.validators.url.required", isURL)
    .required("app.validators.value.required"),
  size: yup.mixed().required("app.validators.value.required"),
})

export const companySignupSchema = yup.object().shape({
  name: yup
    .string()
    .required("app.validators.value.required")
    .max(NAME_MAX_LENGTH, "app.validators.length.max"),

  phone: yup
    .string()
    .required("app.validators.value.required")
    .max(PHONE_MAX_LENGTH, "app.validators.length.max"),

  firstName: yup.string().required("app.validators.value.required"),

  lastName: yup.string().required("app.validators.value.required"),

  city: yup.mixed().required("app.validators.value.required"),

  email: yup
    .string()
    .email("app.validators.email.required")
    .max(EMAIL_MAX_LENGTH, "app.validators.length.max")
    .required("app.validators.value.required"),

  website: yup.string().url("app.validators.url.required").nullable(),

  size: yup.mixed(),

  recaptcha: yup.string().required("app.validators.value.required"),
})
