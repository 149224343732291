import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Card, CardBody, CardTitle } from "reactstrap"

export const CardInfo = ({ lastNumbers, expiryDate, ownerName, cardType }) => {
  return (
    <Fragment>
      <Card className="mb-3">
        <CardBody>
          <CardTitle>
            <FormattedMessage id="app.page.payments.card.creditCard" />
          </CardTitle>
          <ul className="list-unstyled border-top mb-0">
            <li className="border-bottom py-2 d-flex justify-content-between">
              <b>
                <FormattedMessage id="app.page.payments.card.name" />
              </b>
              {cardType}
            </li>
            <li className="border-bottom py-2 d-flex justify-content-between">
              <b>
                <FormattedMessage id="app.page.payments.card.ownerName" />
              </b>
              {ownerName}
            </li>
            <li className="border-bottom py-2 d-flex justify-content-between">
              <b>
                <FormattedMessage id="app.page.payments.card.cardNumber" />
              </b>
              **** **** **** {lastNumbers}
            </li>
            <li className="pt-2 d-flex justify-content-between">
              <b>
                <FormattedMessage id="app.page.payments.card.expiry" />
              </b>
              {expiryDate}
            </li>
          </ul>
        </CardBody>
      </Card>
    </Fragment>
  )
}

CardInfo.propTypes = {
  ownerName: PropTypes.string,
  lastNumbers: PropTypes.string,
  expiryDate: PropTypes.string,
  cardType: PropTypes.string,
}

CardInfo.defaultProps = {}
