import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { without } from "ramda"
import { DropdownToggle } from "./dropdown-toggle-component"
import { DropdownMenu, DropdownItem, Label } from "reactstrap"
import { UncontrolledButtonDropdown } from "./button-dropdown-component"

export const SelectionControl = ({
  visibleOptionIds,
  selectableOptionIds,
  selectedOptionIds,
  onChange,
  className,
  isDisabled,
}) => {
  const handleSelectAllClick = () => {
    if (isDisabled) {
      return
    }
    onChange(selectableOptionIds)
  }
  const handleDeselectAllClick = () => {
    if (isDisabled) {
      return
    }
    onChange(without(visibleOptionIds, selectedOptionIds))
  }
  const handleInverseSelectionClick = () => {
    if (isDisabled) {
      return
    }
    onChange(without(selectedOptionIds, selectableOptionIds))
  }
  return (
    <Fragment>
      <div
        className={classNames(
          "d-none d-md-flex align-items-center justify-content-start",
          className,
        )}
      >
        <span className="clickable" onClick={handleSelectAllClick}>
          <FormattedMessage id="app.common.selectAll" />
        </span>
        <span className="mx-2">|</span>
        <span className="clickable" onClick={handleDeselectAllClick}>
          <FormattedMessage id="app.common.deselectAll" />
        </span>
        <span className="mx-2">|</span>
        <span className="clickable" onClick={handleInverseSelectionClick}>
          <FormattedMessage id="app.common.inverseSelection" />
        </span>
      </div>
      <UncontrolledButtonDropdown className="d-md-none">
        <DropdownToggle
          color="primary"
          className={classNames(
            "bg-transparent border-0 shadow-none p-0",
            className,
          )}
        >
          <div className="custom-control custom-checkbox">
            <Label className="custom-control-label clickable">
              <i className="fas fa-angle-down fa-lg text-dark" />
            </Label>
          </div>
        </DropdownToggle>
        <DropdownMenu className="rounded">
          <DropdownItem onClick={handleSelectAllClick} className="outline-none">
            <FormattedMessage id="app.common.selectAll" />
          </DropdownItem>
          <DropdownItem
            onClick={handleDeselectAllClick}
            className="outline-none"
          >
            <FormattedMessage id="app.common.deselectAll" />
          </DropdownItem>
          <DropdownItem
            onClick={handleInverseSelectionClick}
            className="outline-none"
          >
            <FormattedMessage id="app.common.inverseSelection" />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </Fragment>
  )
}

SelectionControl.propTypes = {
  visibleOptionIds: PropTypes.array.isRequired,
  selectableOptionIds: PropTypes.array.isRequired,
  selectedOptionIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
}
