import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { goBack } from "connected-react-router"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button } from "./button-component"
import { FormattedMessage } from "react-intl"

export function NotFoundComponent(props) {
  return (
    <Fragment>
      <h1>
        <FormattedMessage id="app.common.notfound.header" />
      </h1>
      <p className="text-muted">
        <FormattedMessage
          id="app.common.notfound.text"
          values={{
            link: (
              <Link to="/">
                <FormattedMessage id="app.common.link.home" />
              </Link>
            ),
            back: (
              <Button onClick={props.goBack} color="link">
                ← <FormattedMessage id="app.common.back" />
              </Button>
            ),
          }}
        />
      </p>
      <img
        className="img-fluid"
        src="//assets.moberries.com/images/404.svg"
        width="600"
        alt="Not Found"
      />
    </Fragment>
  )
}

NotFoundComponent.propTypes = {
  goBack: PropTypes.func.isRequired,
}

NotFoundComponent.defaultProps = {}

const mapDispatchToProps = { goBack }

const mapStateToProps = function (state) {
  return {}
}

export const NotFound = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFoundComponent)
