import { getActionType } from "../lib/helpers"
import { config } from "../config"
import qs from "qs"
import { decamelizeKeys } from "humps"
import {
  SLACK,
  INTEGRATION,
  FAIL,
  GET,
  LIST,
  START,
  SUCCESS,
  CONNECT,
  REDIRECT,
} from "../constants"
import { showErrorAlert } from "../lib/alerts"
import { store } from "../lib/store"

export function getSlackIntegrationList({ companyId }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, SLACK, INTEGRATION, LIST, START) })

    try {
      const res = await moApi.getSlackIntegrationList({ companyId })

      const { results: integrations, count } = res.data

      dispatch({
        type: getActionType(GET, SLACK, INTEGRATION, LIST, SUCCESS),
        payload: { integrations, count },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, SLACK, INTEGRATION, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export function connectWithSlackRedirect({ state }) {
  return function (dispatch, getState) {
    dispatch({ type: getActionType(CONNECT, SLACK, REDIRECT, START) })

    const { location } = getState().router

    try {
      const stateEncoded = window.btoa(
        JSON.stringify({
          from: location.pathname + location.search,
          originalFrom: location.href,
          redirectUri: config.oauth.redirectUrl,
          clientId: config.slack.id,
          provider: SLACK,
          ...state,
        }),
      )

      const query = qs.stringify(
        decamelizeKeys({
          clientId: config.slack.id,
          redirectUri: config.oauth.redirectUrl,
          state: stateEncoded,
          scope: config.slack.scope.join(","),
        }),
      )

      window.location.assign(`${config.slack.oauthUrl}?${query}`)
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(CONNECT, SLACK, REDIRECT, FAIL),
        payload: { err },
      })
    }
  }
}

export function createSlackIntegration({ code, state }) {
  return async function (dispatch, getState, { integrationApi }) {
    const { jwt: token } = store.getState()

    const { data: integration } = await integrationApi.createSlackIntegration({
      token,
      code,
      state,
    })

    dispatch({
      type: getActionType(CONNECT, SLACK, SUCCESS),
      payload: { integration },
    })
  }
}
