import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { ErrorPage } from "../pages/error-page"
import { DoubleColumnLayout } from "../components/layouts/double-column-layout"
import { jobSelector, errorSelector } from "../selectors/job-selector"
import { getJob } from "../actions/job-actions"
import { getAtsJobIntegrationList } from "../actions/ats-job-integration-actions"
import { atsJobIntegrationByJobIdSelector } from "../selectors/ats-job-integration-selector"
import { JobDetailsPageSkeleton } from "../components/skeletons/job-details-page-skeleton"
import { JobCard } from "../components/job/job-card-component"
import { JobDetailsComponent } from "../components/job/job-details-component"
import { JobFeedbackComponent } from "../components/job/job-feedback-component"
import { JobDetailsSidebar } from "../components/sidebar/job-details-sidebar"
import { Nav, NavItem, NavLink } from "reactstrap"
import { Switch, Route, NavLink as RLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useStore } from "../lib/store"

export const JobDetails = ({
  job,
  atsJobIntegration,
  err,
  match: { path, params },
  getAtsJobIntegrationList,
  getJob,
}) => {
  const jobId = params.id
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)

  useEffect(() => {
    getAtsJobIntegrationList({ jobIds: [jobId] })
    getJob({ id: jobId })
  }, [jobId, getAtsJobIntegrationList, getJob])

  if (err) {
    return <ErrorPage err={err} />
  }

  if (!job) {
    return <JobDetailsPageSkeleton />
  }
  return (
    <Fragment>
      <DoubleColumnLayout>
        <DoubleColumnLayout.Content>
          <Fragment>
            <JobCard
              job={job}
              className="bg-white p-3 mb-1"
              isStandalone
              hasAtsCompanyIntegration={Boolean(atsCompanyIntegration)}
              jobIntegration={atsJobIntegration}
            />
            <Nav tabs justified className="settings-tabs mt-2">
              <NavItem>
                <NavLink
                  tag={RLink}
                  to={`/job/${job.id}`}
                  exact
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage id="app.page.jobDetails.tabs.details" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RLink}
                  to={`/job/${job.id}/feedback`}
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage id="app.page.jobDetails.tabs.feedback" />
                </NavLink>
              </NavItem>
            </Nav>
            <div className="bg-white p-3 rounded">
              <Switch>
                <Route path={path} exact>
                  <JobDetailsComponent job={job} />
                </Route>
                <Route path={`${path}/feedback`}>
                  <JobFeedbackComponent jobId={jobId} />
                </Route>
              </Switch>
            </div>
          </Fragment>
        </DoubleColumnLayout.Content>
        <DoubleColumnLayout.Sidebar>
          <JobDetailsSidebar statistics={job.statistics} jobId={job.id} />
        </DoubleColumnLayout.Sidebar>
      </DoubleColumnLayout>
    </Fragment>
  )
}

JobDetails.propTypes = {
  match: PropTypes.object.isRequired,
  getAtsJobIntegrationList: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  job: PropTypes.object,
  atsJobIntegration: PropTypes.object,
  err: PropTypes.object,
}
const mapDispatchToProps = {
  getAtsJobIntegrationList,
  push,
  getJob,
}

const mapStateToProps = function (state, props) {
  const job = jobSelector(state, { id: props.match.params.id })
  const atsJobIntegration = atsJobIntegrationByJobIdSelector(state, {
    id: props.match.params.id,
  })

  return {
    job,
    atsJobIntegration,
    err: errorSelector(state),
  }
}

export const JobDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobDetails)
