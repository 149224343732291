import { getActionType, arrayToObject } from "../lib/helpers"
import { always, evolve, F, mergeLeft, T } from "ramda"
import { GET, INVOICE, LIST, START, FAIL, COMPANY, SUCCESS } from "../constants"

export const defaultState = {
  count: null,
  invoices: [],
  entities: {},
  loading: false,
  err: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, COMPANY, INVOICE, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, COMPANY, INVOICE, LIST, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: mergeLeft(arrayToObject(payload.invoices, "id")),
          invoices: always(payload.invoices.map(j => j.id)),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, COMPANY, INVOICE, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    default:
      return state
  }
}
