import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { updateJob } from "../../actions/job-actions"
import { ACT, JOB_CONDITION_TYPES } from "../../constants"

export const JobActivationInPphModal = ({ isOpen, toggle, jobId }) => {
  const dispatch = useDispatch()
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="app.common.confirm" />
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <FormattedMessage
            id="app.page.jobEdit.limit.reached.confirmation.content"
            values={{
              a: (...children) => <Link to="/subscribe">{children}</Link>,
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={async () => {
            await dispatch(
              updateJob({
                id: jobId,
                job: {
                  status: ACT,
                  condition: { type: JOB_CONDITION_TYPES.pph },
                },
              }),
            )
            toggle()
          }}
        >
          <FormattedMessage id="app.common.confirm" />
        </Button>
        <Button color="light" onClick={toggle}>
          <FormattedMessage id="app.common.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

JobActivationInPphModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
}

JobActivationInPphModal.defaultProps = {
  isOpen: false,
}
