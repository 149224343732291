import React, { Fragment } from "react"
import cn from "classnames"
import { PopoverBody } from "reactstrap"
import PropTypes from "prop-types"
import { Button } from "../common/button-component"
import { FormattedMessage } from "react-intl"
import { JobStatusTag } from "./job-status-tag-component"
import { ACT, PAU, REV } from "../../constants"
import { CardStatusTag } from "../common/card-status-tag"
import { JOB_CONDITION_TYPES_MAP } from "../../default-options"
import { Link } from "react-router-dom"

export function JobCard({
  job,
  className,
  style,
  statistics,
  isStandalone,
  hasAtsCompanyIntegration,
  jobIntegration,
}) {
  const isAtsJobConnected = Boolean(jobIntegration)

  const cardProps = {}

  if (style) {
    cardProps.style = style
  }

  const css = cn("bg-white p-3 rounded", className)

  const isJobEditable = [ACT, PAU, REV].includes(job.status)

  return (
    <div className={css} {...cardProps}>
      <div className="d-flex justify-content-between border-bottom border-light pb-2">
        <h4 className="clickable text-break m-0">
          <Link
            to={`/job/${job.id}`}
            className="text-decoration-none text-body"
          >
            {job.title}
          </Link>
        </h4>
        {isJobEditable && (
          <Button
            tag={Link}
            to={`/job/edit/${job.id}`}
            color="light"
            className="ml-1 btn-circle"
          >
            <i className="fas fa-pen" />
          </Button>
        )}
      </div>
      <div className="d-flex justify-content-between pt-2 text-smaller">
        <JobStatusTag job={job} jobIntegration={jobIntegration} />
        <div>
          <span className="text-muted d-block d-md-inline">
            <FormattedMessage id="app.job.type" />:{" "}
          </span>
          <b>
            <FormattedMessage
              id={JOB_CONDITION_TYPES_MAP[job.condition.type]}
            />
          </b>
        </div>
        {hasAtsCompanyIntegration && (
          <div className="d-none d-md-block">
            <span className="text-muted ">ATS: </span>
            <span
              className={cn({
                "font-weight-bold": isAtsJobConnected,
                "text-muted d-block d-md-inline": !isAtsJobConnected,
              })}
            >
              <FormattedMessage
                id={
                  isAtsJobConnected
                    ? "app.common.connected"
                    : "app.common.notConnected"
                }
              />
            </span>
          </div>
        )}
        {statistics && (
          <Fragment>
            <div>
              <span className="text-muted d-block d-md-inline">
                <FormattedMessage id="app.page.jobList.applications" />:
              </span>
              <b className="font-weight-bold">
                {" " + statistics.applicationsTotal}
              </b>
            </div>
            <div>
              <span className="text-muted d-block d-md-inline">
                <FormattedMessage id="app.page.jobList.accepted" />:
              </span>
              <b className="font-weight-bold">
                {" " + statistics.applicationsAccepted}
              </b>
            </div>
          </Fragment>
        )}
        {isStandalone && job.private && (
          <CardStatusTag
            id={job.id}
            text={<FormattedMessage id="app.page.jobList.private" />}
            color="danger-light"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.page.jobList.private.hint" />
              </PopoverBody>
            }
          />
        )}
      </div>
    </div>
  )
}

JobCard.propTypes = {
  job: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  statistics: PropTypes.object,
  isStandalone: PropTypes.bool,
  hasAtsCompanyIntegration: PropTypes.bool.isRequired,
  jobIntegration: PropTypes.object,
}
