export function getInviteTemplate(
  inviteUrl,
  lang = "en",
  managerName,
  companyName,
) {
  const templates = {
    en: `Hi, \n\nWe appreciate your interest in ${companyName} & the time you have invested in applying for our role. We ended up moving forward with another candidate, thank you for talking to our team and giving us the opportunity to learn about your skills and accomplishments. \n\nThat said, we would like to consider you for potential future roles while helping you on your current search. Our talent pool is powered by MoBerries. They specialise in connecting actively looking candidates to urgently to be filled opportunities. Simply click the link below & upload your CV you begin receiving job matches right away. \n\n${inviteUrl} \n\nWe wish you good luck with your job search and professional future endeavors. \n\nBest, \n${managerName}`,
    de: `Hi, \n\nWir freuen uns über Ihr Interesse an ${companyName} und die Zeit, die Sie in die Bewerbung für unsere Stelle investiert haben. \nAm Ende haben wir uns für eine(n) andere(n) Bewerber(in) entschieden. Vielen Dank, dass Sie mit unserem Team gesprochen und uns die Gelegenheit gegeben haben, Ihre Fähigkeiten und Erfolge kennenzulernen.  \n\nWir möchten Sie jedoch für mögliche zukünftige Rollen in Betracht ziehen und Ihnen bei Ihrer aktuellen Suche helfen. Unser Talentpool wird von MoBerries betrieben. Sie sind darauf spezialisiert, aktiv suchende Kandidaten mit dringend zu besetzenden Stellen zu vernetzen. Bitte klicken Sie auf den folgenden Link und laden Sie Ihren Lebenslauf hoch. Sie werden automatisch Job Matches für Ihr Profil erhalten. \n\n${inviteUrl}\n\nWir wünschen Ihnen viel Erfolg bei Ihrer Arbeitssuche und beruflichen Werdegang. \n\nBeste Grüße, \n${managerName}`,
  }

  return templates[lang]
}
