import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useOpenClose } from "../../hooks/use-open-close"
import { CandidateCVGallery } from "./candidate-cv-gallery"
import { Button } from "../common/button-component"
import {
  downloadCv,
  downloadCvFromPdfService,
  hasImagesCv,
} from "../../lib/helpers"
import { useStore } from "../../lib/store"

export const CandidateCVButton = ({ children, candidate, match, ...rest }) => {
  const { value: isOpen, open, close } = useOpenClose({ initialValue: false })
  const token = useStore(state => state.jwt)

  return (
    <Fragment>
      <Button
        {...rest}
        onClick={() => {
          if (hasImagesCv(candidate?.candidateCv)) {
            open()
          } else {
            if (match) {
              downloadCvFromPdfService(match, token)
            } else {
              downloadCv(candidate)
            }
          }
        }}
      >
        {children}
      </Button>
      <CandidateCVGallery
        isOpen={isOpen}
        onClose={close}
        candidate={candidate}
        match={match}
      />
    </Fragment>
  )
}

CandidateCVButton.propTypes = {
  children: PropTypes.node.isRequired,
  candidate: PropTypes.object.isRequired,
  match: PropTypes.object,
}

CandidateCVButton.defaultProps = {
  match: null,
}
