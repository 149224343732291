import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { FormFeedback, FormGroup, Input, Label } from "reactstrap"
import { SelectFromBadges } from "../common/select-from-badges"
import { useFormikField } from "../../hooks/use-formik-field"

export const LabeledSelectFromBadgesField = ({
  label,
  isRequired,
  ...rest
}) => {
  const [field, meta, helpers] = useFormikField(rest)

  return (
    <FormGroup>
      {label && (
        <Label>
          {label}
          {isRequired && " *"}
        </Label>
      )}
      <SelectFromBadges
        {...rest}
        {...field}
        onChange={value => {
          helpers.setValue(value)
        }}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

LabeledSelectFromBadgesField.propTypes = {
  label: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
}
