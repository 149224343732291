import { useEffect } from "react"
import { hotjar } from "react-hotjar"
import { config } from "../../../config"

export const Hotjar = () => {
  useEffect(() => {
    hotjar.initialize(config.hotjar.hjid, config.hotjar.hjsv)
  }, [])

  return null
}
