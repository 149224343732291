import React, { useRef } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

import { COLORS } from "../../../constants"
import PropTypes from "prop-types"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// TODO: future-improvements: Mutation: mutate HorizontalBars func to support charts from Strategy Builder Page and remove HorizontalBarsV2

export function HorizontalBars(props) {
  const barRef = useRef(null)
  const { labels, data } = props

  return (
    <Bar
      ref={barRef}
      options={{
        indexAxis: "y",
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            display: false,
            align: "start",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              padding: 20,
            },
          },
          tooltip: {
            mode: "nearest",
            titleAlign: "left",
            padding: 14,
            backgroundColor: COLORS.getWhite(),
            titleColor: COLORS.manatee,
            titleFont: { size: 12, weight: "normal" },
            titleMarginBottom: 10,
            bodyColor: COLORS.getBlack(),
            bodyFont: { size: 18, weight: "bold", lineHeight: 1 },
            caretPadding: 16,
            caretSize: 10,
            cornerRadius: 10,
            borderColor: COLORS.getBlack(0.06),
            borderWidth: 1,
            displayColors: false,
          },
        },
        interaction: { mode: "nearest", intersect: false, axis: "xy" },
        hover: {
          mode: "nearest",
        },
        scales: {
          y: {
            // TODO remove this in the future
            // min: 0,
            // max: 100,
            ticks: {
              // stepSize: 25,
              color: COLORS.getBlack(0.4),
            },
            grid: {
              drawOnChartArea: false,
              display: false,
              borderColor: COLORS.getBlack(0.01),
              tickColor: COLORS.getBlack(),
            },
          },
          x: {
            grid: {
              borderColor: COLORS.getBlack(0.08),
              drawTicks: false,
              color: COLORS.getBlack(0.08),
            },
            ticks: {
              color: COLORS.getBlack(0.4),
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            label: "Overall",
            data: data[0],
            maxBarThickness: 100,
            minBarLength: 9,
            borderRadius: 10,
            barPercentage: 0.8,
            backgroundColor: "#0738DA",
          },
          {
            label: "Per job",
            data: data[1],
            backgroundColor: "#00C3F9",
            maxBarThickness: 100,
            minBarLength: 9,
            borderRadius: 10,
            barPercentage: 0.8,
          },
        ],
      }}
      height={200}
    />
  )
}

export const HorizontalBarsV2 = ({ configs, labels, datasets }) => {
  const barRef = useRef(null)

  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 20,
        },
      },
      tooltip: {
        mode: "nearest",
        titleAlign: "left",
        padding: 14,
        backgroundColor: COLORS.getWhite(),
        titleColor: COLORS.manatee,
        titleFont: { size: 12, weight: "normal" },
        titleMarginBottom: 10,
        bodyColor: COLORS.getBlack(),
        bodyFont: { size: 18, weight: "bold", lineHeight: 1 },
        caretPadding: 16,
        caretSize: 10,
        cornerRadius: 10,
        borderColor: COLORS.getBlack(0.06),
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          label: configs.callbackLabel,
        },
      },
    },
    interaction: { mode: "nearest", intersect: false, axis: "xy" },
    hover: {
      mode: "nearest",
    },
    scales: {
      y: {
        grid: {
          drawOnChartArea: false,
          display: false,
          borderColor: COLORS.getBlack(0.01),
          tickColor: COLORS.getBlack(),
        },
        ticks: {
          color: COLORS.getBlack(0.4),
        },
      },
      x: {
        grid: {
          borderColor: COLORS.getBlack(0.08),
          drawTicks: false,
          color: COLORS.getBlack(0.08),
        },
        ticks: {
          color: COLORS.getBlack(0.4),
          callback: configs.callbackX,
        },
      },
    },
  }

  const data = {
    labels,
    datasets: datasets.map(dataset => {
      return {
        label: dataset.label,
        backgroundColor: dataset.backgroundColor,
        data: dataset.data,
        maxBarThickness: 100,
        minBarLength: 9,
        borderRadius: 10,
        borderSkipped: configs.borderSkipped ?? false,
        barPercentage: 0.5,
      }
    }),
  }

  return <Bar ref={barRef} options={options} data={data} height={200} />
}

HorizontalBars.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

HorizontalBarsV2.propTypes = {
  configs: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.object.isRequired,
}
