const configs = {
  callbackLabel: function (value) {
    return `${value.dataset.label}: €${value.formattedValue}`
  },
  callbackY: function (value) {
    return `€${value / 1000}K`
  },
}

const labels = ["Junior", "Middle", "Senior", "Team Lead"]

const isZero = f => f.value === 0
const isDataExists = f => f.value !== 0

export const roleMapper = (data = []) =>
  data.map(f => ({ value: f.id, label: f.name }))

const format = (e, r) =>
  Object.values(e.find(f => f.name === r?.label)?.salary ?? {})
    .map((f, idx) => ({ label: labels[idx], value: f ?? 0 }))
    .filter(isDataExists)

export const empty = (data = [], role) => format(data, role).every(isZero)

export const mapper = (data = [], role) => {
  return {
    configs: configs,
    labels: format(data, role).map(f => f.label),
    datasets: [
      {
        label: "Average Salary",
        backgroundColor: "#e3e80c",
        data: format(data, role).map(f => f.value),
      },
    ],
  }
}
