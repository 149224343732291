import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Img } from "../common/img-component"
import { Link } from "react-router-dom"
import { SidebarWidgetLayout } from "../layouts/sidebar-widget-layout"

export const AtsIntegrationWidget = ({ provider, isAutoJobSyncEnabled }) => (
  <SidebarWidgetLayout
    title={
      <Fragment>
        <span className="icon-circled mr-2">
          <i className="fas fa-plug" />
        </span>
        <FormattedMessage id="app.widget.atsIntegration.title" />
      </Fragment>
    }
  >
    <div className="d-flex border-top pt-2">
      <div className="border rounded">
        <Img width="72" height="72" src={provider.logoUrl} />
      </div>
      <div className="ml-2 d-flex flex-column justify-content-between">
        <h4>{provider.name}</h4>
        <div>
          {!isAutoJobSyncEnabled && (
            <Button
              color="primary"
              className="mr-2"
              tag={Link}
              to="/integrations/ats/import"
            >
              <FormattedMessage id="app.page.integrations.importJobs" />
            </Button>
          )}

          <Button
            outline
            color="secondary"
            tag={Link}
            to={`/integrations/ats/${provider.slug}/connect`}
          >
            <i className="fas fa-cog" />
          </Button>
        </div>
      </div>
    </div>
  </SidebarWidgetLayout>
)

AtsIntegrationWidget.propTypes = {
  provider: PropTypes.object.isRequired,
  isAutoJobSyncEnabled: PropTypes.bool.isRequired,
}
