import { createSelector } from "reselect"

export const stateSelector = state => state.atsJobIntegration

export const isLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading,
)

export const isCreatingSelector = createSelector(
  stateSelector,
  state => state.isCreating,
)

export const isDeletingSelector = createSelector(
  stateSelector,
  state => state.isDeleting,
)

export const isLoadingUnintegratedJobsSelector = createSelector(
  stateSelector,
  state => state.isLoadingUnintegratedJobs,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const countSelector = createSelector(stateSelector, state => state.count)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)

export const atsJobIntegrationsSelector = createSelector(
  entitiesSelector,
  entities => Object.values(entities),
)

export const atsJobIntegrationSelector = createSelector(
  entitiesSelector,
  (_, { id }) => id,
  (entities, id) => entities[id],
)

export const atsJobIntegrationByJobIdSelector = createSelector(
  atsJobIntegrationsSelector,
  (_, { id }) => parseInt(id, 10),
  (integrations, id) =>
    integrations.find(i => parseInt(i.moberriesJob, 10) === id),
)

export const atsJobIntegrationByTupleIdSelector = createSelector(
  atsJobIntegrationsSelector,
  (_, { moberriesJobId }) => parseInt(moberriesJobId, 10),
  (_, { providerJobId }) => parseInt(providerJobId, 10),
  (integrations, moberriesJobId, providerJobId) =>
    integrations.find(
      i =>
        parseInt(i.moberriesJob, 10) === moberriesJobId &&
        (parseInt(i.providerJob, 10) === providerJobId ||
          parseInt(i.providerJob.id, 10) === providerJobId),
    ),
)

export const unintegratedJobIdsSelector = createSelector(
  stateSelector,
  state => state.unintegratedJobIds,
)

export const unintegratedJobsCountSelector = createSelector(
  stateSelector,
  state => state.unintegratedJobsCount,
)
