import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { BasicMatchCard } from "../match/basic-match-card-component"
import { DASHBOARD_MATCHES_LIMIT } from "../../constants"
import { CardListLoader } from "../loaders/card-list-loader"
import { MatchOrJobFilter } from "../filters/match-or-job-filter-component"
import { PaginationDashboard } from "../common/pagination-component-dashboard/pagination-component-dashboard"
import styles from "./dashboard-match-list-component.module.scss"

export const DashboardMatchList = ({
  isLoadingMatches,
  isReadOnly,
  areAllFiltersDeactivated,
  page,
  matches,
  matchesCount,
  teams,
}) => {
  return (
    <Fragment>
      <div className={styles.filter}>
        <MatchOrJobFilter
          isDisabled={isLoadingMatches}
          teams={teams}
          filterName="dashboardMatch"
        />
      </div>
      {isLoadingMatches ? (
        <CardListLoader
          className={styles.cardLoader}
          count={DASHBOARD_MATCHES_LIMIT}
        />
      ) : (
        matches?.map(match => (
          <div key={match.id} className={styles.match}>
            <BasicMatchCard
              match={match}
              readOnly={isReadOnly}
              popover={
                <FormattedMessage id="app.page.dashboard.matches.review.blocked.upgrade" />
              }
              page={page}
            />
          </div>
        ))
      )}
      {matchesCount === 0 && (
        <div className="bg-white p-2 mb-2">
          {areAllFiltersDeactivated ? (
            <FormattedMessage id="app.page.dashboard.matches.empty.list" />
          ) : (
            <FormattedMessage id="app.page.dashboard.matches.empty.filtering" />
          )}
        </div>
      )}
      {matchesCount > DASHBOARD_MATCHES_LIMIT && (
        <PaginationDashboard
          content
          className="mt-3"
          listClassName="justify-content-end"
          page={page}
          count={matchesCount}
          disabled={isLoadingMatches}
          limit={DASHBOARD_MATCHES_LIMIT}
          basePath="/"
          withQueryPagination
        />
      )}
    </Fragment>
  )
}

DashboardMatchList.propTypes = {
  page: PropTypes.number.isRequired,
  teams: PropTypes.array.isRequired,
  matchesCount: PropTypes.number,
  matches: PropTypes.array.isRequired,

  isLoadingMatches: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  areAllFiltersDeactivated: PropTypes.bool.isRequired,
}
DashboardMatchList.defaultProps = {
  page: 1,
}
