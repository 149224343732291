import { createSelector } from "reselect"

export const stateSelector = state => state.system

export const releaseSelector = createSelector(
  stateSelector,
  state => state.release,
)
export const logrocketSelector = createSelector(
  stateSelector,
  state => state.logrocket,
)
export const lastSupportedVersionSelector = createSelector(
  stateSelector,
  state => state.lastSupportedVersion,
)
