import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Media, Button } from "reactstrap"
import moreMatches from "../../assets/img/moreMatches.png"
import { Img } from "../common/img-component"

export function NoMatches() {
  return (
    <Fragment>
      <div className="mb-2">
        <Media className="bg-white border-bottom p-3">
          <Media left>
            <Img
              src={moreMatches}
              alt="Icon"
              width="80"
              height="80"
              className="mr-3 d-none d-md-inline"
            />
          </Media>
          <Media body>
            <Media heading>
              <FormattedMessage id="app.page.dashboard.matches.empty.matching.header" />
            </Media>
            <p>
              <FormattedMessage id="app.page.dashboard.matches.empty.matching.subheader" />
            </p>
          </Media>
        </Media>
      </div>
      <div className="mb-2">
        <Media className="bg-white p-3 justify-content-end">
          <Button disabled>
            <FormattedMessage id="app.page.dashboard.matches.empty.button.wait" />
          </Button>
        </Media>
      </div>
    </Fragment>
  )
}
