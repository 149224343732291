import React from "react"
import PropTypes from "prop-types"
import { Col } from "reactstrap"
import { MatchApplicationAnswer } from "./match-application-answer-component"
import { FormattedMessage } from "react-intl"

export const MatchApplicationAnswers = ({ applicationAnswers }) => {
  return (
    <div className="bg-white p-3 border-bottom">
      <h4 className="mb-0 pb-3">
        <FormattedMessage id="app.page.match.applicationAnswers.title" />
      </h4>
      <div className="d-md-flex">
        <Col xs="12" md="6" className="px-0 pr-md-2">
          {applicationAnswers
            .slice(0, Math.ceil(applicationAnswers.length / 2))
            .map(answer => (
              <MatchApplicationAnswer
                key={answer.id}
                applicationAnswer={answer}
              />
            ))}
        </Col>
        <Col xs="12" md="6" className="pl-0">
          {applicationAnswers
            .slice(Math.ceil(applicationAnswers.length / 2))
            .map(answer => (
              <MatchApplicationAnswer
                key={answer.id}
                applicationAnswer={answer}
              />
            ))}
        </Col>
      </div>
    </div>
  )
}

MatchApplicationAnswers.propTypes = {
  applicationAnswers: PropTypes.array.isRequired,
}
