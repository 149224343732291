import { createSelector } from "reselect"

export const stateSelector = state => state.card

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading,
)

export const creatingSelector = createSelector(
  stateSelector,
  state => state.creating,
)

export const deletingSelector = createSelector(
  stateSelector,
  state => state.deleting,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const countSelector = createSelector(stateSelector, state => state.count)
export const idsSelector = createSelector(stateSelector, state => state.cards)

export const cardsSelector = createSelector(
  entitiesSelector,
  idsSelector,
  (entities, ids) => ids.map(id => entities[id]),
)

export const defaultCardSelector = createSelector(cardsSelector, cards =>
  cards.find(c => c.isDefault),
)
