import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { differenceWith, eqProps } from "ramda"
import { Button } from "../common/button-component"
import { Col, Row, Input } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Formik, Form } from "formik"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api, ApiKeys } from "../../lib/moberries-api"
import { showAlert, showErrorAlert } from "../../lib/alerts"
import FormFeedback from "../common/form-feedback"
import { useQueryClient } from "react-query"
import { moErrorTypes } from "../../lib/server-errors"
import { ALERT_COLORS } from "../../constants"

export const TeamEditCard = ({
  onCloseButtonClick,
  team,
  teamManagers,
  companyManagers,
  checkIfCanRemoveManager,
}) => {
  const otherManagers = differenceWith(
    eqProps("id"),
    companyManagers,
    teamManagers,
  )
  const queryClient = useQueryClient()

  const { mutateAsync: updateCompanyTeam, isLoading: isUpdating } =
    useAxiosMutation(api.updateCompanyTeam, {
      onSuccess: () => {
        queryClient.invalidateQueries(ApiKeys.Teams)
      },
      onError: err => {
        showErrorAlert({ err })
      },
    })
  const { mutateAsync: deleteTeamManager } = useAxiosMutation(
    api.deleteManagerFromTeam,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ApiKeys.Teams)
      },
      onError: err => {
        showErrorAlert({ err })
      },
    },
  )
  const handleDeleteTeamManager = async managerId => {
    if (!checkIfCanRemoveManager(managerId)) {
      showAlert({
        code: moErrorTypes.managerAssignmentError,
        color: ALERT_COLORS.ERROR,
      })
      throw new Error(moErrorTypes.managerAssignmentError)
    }
    const {
      data: {
        results: [teamManager],
      },
    } = await api.getTeamManagers({
      params: { team: team.id, manager: managerId },
    })
    await deleteTeamManager({
      teamManagerId: teamManager.id,
    })
  }
  const { mutateAsync: addManagerToTeam } = useAxiosMutation(
    api.createTeamManager,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ApiKeys.Teams)
      },
      onError: err => {
        showErrorAlert({ err })
      },
    },
  )

  return (
    <Fragment>
      <div className="p-3">
        {otherManagers.map(c => {
          return (
            <Button
              size="sm"
              color="light"
              border="true"
              className="mr-2"
              key={c.id}
              onClick={() =>
                addManagerToTeam({
                  managerId: c.id,
                  teamId: team.id,
                })
              }
            >
              {c.firstName} {c.lastName}
              <i className="fas fa-plus pl-2 text-muted" />
            </Button>
          )
        })}
      </div>
      <div className="p-3 bg-light border-top border-bottom">
        <Formik
          onSubmit={values => {
            updateCompanyTeam({
              teamId: team.id,
              team: { name: values.name },
            }).then(() => onCloseButtonClick())
          }}
          initialValues={team}
          validate={name =>
            !name ? { name: { id: "app.validators.value.required" } } : {}
          }
        >
          {({ values, errors, handleChange, handleBlur, touched }) => {
            return (
              <Form>
                <Row className="align-items-end">
                  <Col>
                    <Input
                      id="name"
                      name="name"
                      placeholder="General"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.name && Boolean(errors.name)}
                      disabled={false}
                    />
                    <FormFeedback error={errors.name} />
                  </Col>
                  <Col xs="auto" className="pl-0">
                    <Button
                      outline
                      type="button"
                      color="secondary"
                      onClick={onCloseButtonClick}
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </Col>
                  <Col xs="auto" className="pl-0">
                    <Button
                      color="primary"
                      type="submit"
                      className="p-2"
                      disabled={isUpdating}
                      loading={isUpdating}
                    >
                      <FormattedMessage id="app.common.save" />
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div>
      <div className="p-3 mb-2">
        {teamManagers.map(m => {
          return (
            <Button
              size="sm"
              color="success-light"
              className="mr-2"
              key={m.id}
              onClick={() => handleDeleteTeamManager(m.id)}
            >
              {m.firstName} {m.lastName}
              <i className="fas fa-times pl-2 text-muted" />
            </Button>
          )
        })}
      </div>
    </Fragment>
  )
}

TeamEditCard.propTypes = {
  onCloseButtonClick: PropTypes.func.isRequired,
  team: PropTypes.object,
  teamManagers: PropTypes.array.isRequired,
  companyManagers: PropTypes.array.isRequired,
  checkIfCanRemoveManager: PropTypes.func.isRequired,
}

TeamEditCard.defaultProps = {
  team: null,
  teamManagers: [],
  companyManagers: [],
}
