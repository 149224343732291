import React from "react"
import PropTypes from "prop-types"
import { isUnlimitedJobSlots } from "../../lib/helpers"
import { FormattedMessage } from "react-intl"

export const FormattedJobSlots = ({ jobSlots }) =>
  isUnlimitedJobSlots(jobSlots) ? (
    <FormattedMessage id="app.job.form.posting.job.unlimited" />
  ) : (
    jobSlots
  )

FormattedJobSlots.propTypes = {
  jobSlots: PropTypes.number.isRequired,
}
