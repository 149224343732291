import React from "react"
import PropTypes from "prop-types"
import { Message } from "./message-component"
import {
  isDocumentVisible,
  isMatchApproved,
  isMatchRejected,
} from "../../lib/helpers"
import { MessageListLoader } from "../loaders/message-list-loader"
import { InfiniteScroll } from "../common/infinite-scroll-component"
import { ThreadTopBar } from "./thread-top-bar-component"
import classNames from "classnames"
import { MatchRejectedReasons } from "../match/match-rejected-reasons"
import { NewMessage } from "./new-message-component"
import { MESSAGES_PER_PAGE } from "../../constants"
import { useEvent } from "../../hooks/use-event"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { api, ApiKeys } from "../../lib/moberries-api"
import { head, reverse } from "ramda"
import { useInfiniteAxiosQuery } from "../../hooks/use-infinite-axios-query"
import { useQueryClient } from "react-query"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { FormattedMessage } from "react-intl"

export const Thread = ({ threadId }) => {
  const queryClient = useQueryClient()
  const {
    mutateAsync: createMessage,
    isLoading: isCreatingMessage,
    error: creatingMessageErr,
  } = useAxiosMutation(api.createMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(ApiKeys.Messages)
    },
  })

  const { data: thread } = useAxiosQuery(
    [ApiKeys.Thread, { id: threadId }],
    api.getThread,
  )

  const {
    data: { results: messages = [] } = {},
    hasNextPage,
    fetchNextPage,
  } = useInfiniteAxiosQuery(
    [ApiKeys.Messages, { threadId }],
    api.getMessageList,
    {
      pageSize: MESSAGES_PER_PAGE,
      onSuccess: () => {
        queryClient.invalidateQueries(ApiKeys.Threads)
      },
    },
  )

  const match = thread?.match
  const isRejected = isMatchRejected(match)
  const isApproved = isMatchApproved(match)
  const isClosedThread = isRejected || isApproved

  const { data: { results: matchFeedbacks } = {} } = useAxiosQuery(
    [
      ApiKeys.MatchFeedbacks,
      {
        id: match?.id,
      },
    ],
    api.getMatchFeedbacks,
    { enabled: isRejected },
  )

  const matchRejectionReasons = matchFeedbacks && head(matchFeedbacks)?.feedback
  // TODO: Create a separate api method that only returns this slice. Should be done when we don't need to pass companyId anymore

  useEvent("NEW_MESSAGE", ({ threadId: newMessageThreadId }) => {
    // TODO: rename event so it's not tied to redux TODO: Move event types into a map
    if (newMessageThreadId !== threadId) {
      return
    }

    if (isDocumentVisible()) {
      queryClient.invalidateQueries(ApiKeys.Messages)
    }
  })

  useEvent("READ_MESSAGE", ({ threadId: readMessageThreadId }) => {
    // TODO: rename event so it's not tied to redux TODO: Move event types into a map
    if (readMessageThreadId !== threadId) {
      return
    }
    queryClient.invalidateQueries(ApiKeys.Messages)
  })

  return (
    <div className="bg-white messenger-height d-flex flex-column">
      <ThreadTopBar thread={thread} />
      <div
        className={classNames("flex-grow-1 overflow-auto h-15", {
          "loading-thread": !messages.length,
        })}
      >
        {messages.length ? (
          <InfiniteScroll
            id={threadId}
            onLoadMore={fetchNextPage}
            hasMore={hasNextPage}
            rootMargin={200}
            upsideDown
            loader={
              <div className="text-center display-4 text-light h-100">
                <i className="fas fa-circle-notch fa-spin" />
              </div>
            }
          >
            {reverse(messages).map(m => (
              <Message message={m} isArchived={isRejected} key={m.id} />
            ))}
          </InfiniteScroll>
        ) : (
          <MessageListLoader />
        )}
      </div>
      <div
        className={classNames({
          "loading-thread": !messages.length,
        })}
      >
        {isClosedThread ? (
          (isRejected && matchRejectionReasons && (
            <MatchRejectedReasons
              matchRejectedReasons={matchRejectionReasons}
            />
          )) ||
          (isApproved && (
            <div className="bg-light container p-3 border-top border-bottom">
              <FormattedMessage
                tagName="h5"
                id="app.page.match.approved.candidateWasHired"
              />
            </div>
          ))
        ) : (
          <NewMessage
            onSend={({ threadId, text }) => createMessage({ threadId, text })}
            threadId={threadId}
            candidate={thread?.candidate}
            job={thread?.job}
            creating={isCreatingMessage}
            err={creatingMessageErr}
          />
        )}
      </div>
    </div>
  )
}

Thread.propTypes = {
  threadId: PropTypes.number,
}

Thread.defaultProps = {
  messages: [],
  hasMore: false,
  creatingMessage: false,
}
