import React, { Fragment } from "react"
import { SingleColumnLayout } from "../layouts/single-column-layout"
import { CardLoader } from "../loaders/card-loader"
import { times } from "ramda"
import { Container } from "reactstrap"
import { FormattedMessage } from "react-intl"

export function ReferredCandidateListPageSkeleton(props) {
  return (
    <Fragment>
      <Container>
        <h1 className="text-center">
          <FormattedMessage id="app.talentPool.title" />
        </h1>
        <p className="text-center">
          <FormattedMessage id="app.talentPool.text" />
        </p>
      </Container>
      <SingleColumnLayout>
        <Fragment>
          {times(
            i => (
              <div key={i} className="bg-white p-3 mb-2">
                <CardLoader />
              </div>
            ),
            4,
          )}
        </Fragment>
      </SingleColumnLayout>
    </Fragment>
  )
}
