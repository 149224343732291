import React from "react"
import { FormattedMessage } from "react-intl"
import { AccountPageLayout } from "../components/layouts/account-page-layout"
import { FormGroup, TabPane } from "reactstrap"
import { Form, Formik } from "formik"
import { InputField, LabeledInputField } from "../components/fields/input-field"
import { Button } from "../components/common/button-component"
import { api } from "../lib/moberries-api"
import { alertTypes, showAlert, showErrorAlert } from "../lib/alerts"
import { submitAndValidate } from "../lib/helpers/form-helpers"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { newEmailSchema, newPasswordSchema } from "../schemas/account-schemas"
import { useStore } from "../lib/store"

const toRequestUpdateEmail = ({ email }) => ({ data: { email } })

export const AccountCredentialsPage = () => {
  const email = useStore(state => state.manager.email)
  const {
    mutateAsync: updateEmail,
    isLoading: isUpdatingEmail,
  } = useAxiosMutation(api.updateManager, {
    onSuccess: () => {
      showAlert({ code: alertTypes.emailConfirmUpdate })
    },
    onError: err => {
      showErrorAlert({ err })
    },
  })

  const {
    mutateAsync: updatePassword,
    isLoading: isUpdatingPassword,
  } = useAxiosMutation(api.updatePassword, {
    onSuccess: () => {
      showAlert({ code: alertTypes.updatePasswordSuccess })
    },
  })

  return (
    <AccountPageLayout>
      <TabPane>
        <Formik
          initialValues={{
            currentEmail: email,
            email: "",
          }}
          validationSchema={newEmailSchema}
          onSubmit={(values, actions) => {
            actions.resetForm()
            return submitAndValidate(updateEmail, toRequestUpdateEmail)(
              values,
              actions,
            )
          }}
        >
          <Form>
            <legend>
              <FormattedMessage id="app.common.email" />
            </legend>
            <FormGroup>
              <InputField
                name="currentEmail"
                id="currentEmail"
                type="email"
                readOnly
              />
            </FormGroup>

            <LabeledInputField
              label={
                <FormattedMessage id="app.page.account.tab.credentials.email.new" />
              }
              name="email"
              type="email"
              id="email"
              placeholder="email@example.com"
              isRequired
            />
            <Button
              disabled={isUpdatingEmail}
              loading={isUpdatingEmail}
              block
              color="primary"
              type="submit"
              size="lg"
            >
              <FormattedMessage id="app.common.send" />
            </Button>
          </Form>
        </Formik>
        <div className="mt-3">
          <Formik
            initialValues={{
              password: "",
              passwordNew: "",
            }}
            onSubmit={async (values, actions) => {
              // TODO: Create a formik wrapper and put submitAndValidate and reset into it
              await submitAndValidate(updatePassword)(values, actions)
              actions.resetForm()
            }}
            validationSchema={newPasswordSchema}
          >
            <Form>
              <legend>
                <FormattedMessage id="app.page.account.password.title" />
              </legend>
              <LabeledInputField
                type="password"
                name="password"
                label={<FormattedMessage id="app.page.account.password.old" />}
                placeholder="********"
                isRequired
                id="password"
              />
              <LabeledInputField
                type="password"
                name="passwordNew"
                label={<FormattedMessage id="app.page.account.password.new" />}
                placeholder="********"
                isRequired
                id="passwordNew"
              />
              <Button
                block
                color="primary"
                size="lg"
                loading={isUpdatingPassword}
                disabled={isUpdatingPassword}
                type="submit"
              >
                <FormattedMessage id="app.common.save" />
              </Button>
            </Form>
          </Formik>
        </div>
      </TabPane>
    </AccountPageLayout>
  )
}
