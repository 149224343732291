import { getActionType } from "../lib/helpers"
import { always, evolve, F, T } from "ramda"
import { GET, SUCCESS, SURVEY, STATUS, FAIL, START, CLOSE } from "../constants"

export const defaultState = {
  completed: true,
  loading: false,
  closingTimestamp: null,
  err: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, SURVEY, STATUS, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, SURVEY, STATUS, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          completed: always(payload.completed),
        },
        state,
      )

    case getActionType(GET, SURVEY, STATUS, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(CLOSE, SURVEY):
      return evolve(
        {
          closingTimestamp: always(payload.closingTimestamp),
        },
        state,
      )

    default:
      return state
  }
}
