import { getActionType, arrayToObject } from "../lib/helpers"
import { always, evolve, F, lensProp, mergeLeft, over, T } from "ramda"
import { GET, SUCCESS, CANDIDATE, LIST, FAIL, START } from "../constants"

export const defaultState = {
  candidatesCount: null,
  entities: {},
  candidates: [],
  loading: false,
  err: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, CANDIDATE, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, CANDIDATE, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: over(
            lensProp(payload.candidate.id),
            mergeLeft(payload.candidate),
          ),
        },
        state,
      )

    case getActionType(GET, CANDIDATE, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(GET, CANDIDATE, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, CANDIDATE, LIST, SUCCESS):
      return evolve(
        {
          err: always(null),
          loading: F,
          entities: mergeLeft(arrayToObject(payload.candidates, "id")),
          candidatesCount: always(payload.count),
          candidates: always(payload.candidates.map(c => c.id)),
        },
        state,
      )

    case getActionType(GET, CANDIDATE, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    default:
      return state
  }
}
