import React, { Fragment, useState, useEffect } from "react"
import { SubscriptionOrderContext } from "../components/subscription/susbscription-order-context"
import { SubscriptionSelectStep } from "../components/subscription/subscription-select-step"
import { SubscriptionBillingStep } from "../components/subscription/subscription-billing-step"
import { SubscriptionPaymentStep } from "../components/subscription/subscription-payment-step"
import { useDispatch, useSelector } from "react-redux"
import { getSubscriptionList } from "../actions/subscription-actions"
import { createSubscriptionSelector } from "../selectors/subscription-selector"
import { SubscriptionOrderPageSkeleton } from "../components/skeletons/subscription-order-page-skeleton"
import { FormattedMessage } from "react-intl"
import { Container } from "reactstrap"
import {
  isComSubscriptionMainAndOpenPaid,
  isComSubscriptionCancelingPaid,
  isComSubscriptionIncomplete,
  isComSubscriptionUpgradable,
  companySubscriptionSelector,
} from "../lib/helpers"
import { Redirect } from "react-router"
import { AVAILABLE_JOB_SLOTS } from "../constants"
import { useStore } from "../lib/store"

const getDefaultSubscriptionParams = ({
  companySubscription,
  pricingRules,
}) => {
  const shouldConsiderCurrentSubscription =
    isComSubscriptionMainAndOpenPaid(companySubscription) ||
    isComSubscriptionCancelingPaid(companySubscription)
  let duration = Math.max(pricingRules.minDurationMonths, 12)
  let jobSlots = Math.max(pricingRules.minJobslotsCount, 10)

  if (shouldConsiderCurrentSubscription) {
    duration = Math.max(
      duration,
      companySubscription.subscription.durationIntervalCount,
    )
    jobSlots = Math.max(jobSlots, companySubscription.subscription.jobSlots)

    // Some customers have custom subscriptions that have an arbitrary number of jobSlots
    // In that case we offer them the nearest greater number of job slots
    for (const slots of AVAILABLE_JOB_SLOTS) {
      if (slots >= jobSlots) {
        jobSlots = slots
        break
      }
    }
  }

  return { duration, jobSlots, upfront: false }
}

export const SubscriptionOrderPage = () => {
  const dispatch = useDispatch()
  const [coupon, setCoupon] = useState(null)
  const companySubscription = useStore(companySubscriptionSelector)
  const [step, setStep] = useState(
    isComSubscriptionIncomplete(companySubscription) ? 3 : 1,
  )
  const pricingRules = useStore(state => state.company.pricingRules)
  const [subscriptionParams, setSubscriptionParams] = useState(
    getDefaultSubscriptionParams({ companySubscription, pricingRules }),
  )
  const nextStep = () => setStep(step => step + 1)
  const prevStep = () => setStep(step => step - 1)
  const setSubscription = newParams => {
    setSubscriptionParams(currentParams => ({ ...currentParams, ...newParams }))
  }

  useEffect(() => {
    dispatch(getSubscriptionList())
  }, [dispatch])
  let subscription = useSelector(createSubscriptionSelector(subscriptionParams))

  // if company subscription is incomplete, you can't choose a different subscription
  if (isComSubscriptionIncomplete(companySubscription)) {
    subscription = companySubscription.subscription
  }

  if (
    !isComSubscriptionUpgradable(companySubscription) &&
    !isComSubscriptionIncomplete(companySubscription)
  ) {
    return <Redirect to="/subscription" />
  }

  if (!subscription) {
    return <SubscriptionOrderPageSkeleton />
  }

  return (
    <Fragment>
      <SubscriptionOrderContext.Provider
        value={{
          subscription,
          setSubscription,
          coupon,
          setCoupon,
          nextStep,
          prevStep,
        }}
      >
        <Container>
          <h1>
            <FormattedMessage id="app.page.subscription.order.header" />
          </h1>
          <p className="text-muted">
            <FormattedMessage id="app.page.subscription.order.subheader" />
          </p>
        </Container>

        {step === 1 && (
          <SubscriptionSelectStep
            currentCompanySubscription={companySubscription}
            pricingRules={pricingRules}
          />
        )}
        {step === 2 && <SubscriptionBillingStep />}
        {step === 3 && <SubscriptionPaymentStep />}
      </SubscriptionOrderContext.Provider>
    </Fragment>
  )
}
