import React, { useEffect } from "react"
import { Row, Col, PopoverBody } from "reactstrap"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import cn from "classnames"
import { InfoHint } from "../common/info-hint-component"
import { camelize } from "humps"
import { List } from "../loaders/list-loader"
import { Badge } from "../common/badge-component"
import { isEmpty } from "ramda"
import { FormattedLink } from "../common/formatted-link-component"
import { useDispatch, useSelector } from "react-redux"
import { jobFeedbackByJobIdSelector } from "../../selectors/job-selector"
import { getJobFeedback } from "../../actions/job-actions"

export const JobFeedbackComponent = ({ jobId }) => {
  const dispatch = useDispatch()
  const feedback = useSelector(state =>
    jobFeedbackByJobIdSelector(state, { jobId }),
  )

  useEffect(() => {
    dispatch(getJobFeedback({ jobId }))
  }, [dispatch, jobId])

  if (!feedback) {
    return <List />
  }
  return (
    <Row>
      {["jobReasons", "companyReasons"].map(category => (
        <Col xs="12" sm="6" className="pr-sm-3 mt-3" key={category}>
          <div className="text-smaller text-muted text-uppercase border-bottom pb-2 mb-2 d-flex justify-content-between">
            <FormattedMessage
              id={`app.page.jobDetails.feedback.${category}.title`}
            />
            <InfoHint
              className="ml-2"
              id={category}
              popover={
                <PopoverBody>
                  <FormattedMessage
                    id={`app.page.jobDetails.feedback.${category}.hint`}
                    values={{
                      a: (...children) => (
                        <FormattedLink
                          href={`${category}CandidateFeedback`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {children}
                        </FormattedLink>
                      ),
                    }}
                  />
                </PopoverBody>
              }
            />
          </div>
          <div>
            {feedback[category].map(reason => (
              <div
                key={reason.value}
                className={cn(
                  "d-flex justify-content-between text-smaller mb-1",
                  {
                    "font-weight-bold": reason.feedbacksTotal > 0,
                    "text-muted": reason.feedbacksTotal === 0,
                  },
                )}
              >
                <span className="mr-1">
                  <FormattedMessage
                    id={`app.page.jobDetails.feedback.${category}.${camelize(
                      reason.value,
                    )}`}
                  />
                </span>
                {reason.feedbacksTotal}
              </div>
            ))}
          </div>
        </Col>
      ))}
      {!isEmpty(feedback.missingSkills) && (
        <Col xs="12" className="pr-sm-3 mt-3">
          <div className="text-smaller text-muted text-uppercase border-bottom pb-2 mb-2 d-flex justify-content-between">
            <FormattedMessage id="app.page.jobDetails.feedback.skills.title" />
          </div>
          <FormattedMessage id="app.page.jobDetails.feedback.skills.subTitle" />

          <div className="mt-2">
            {feedback.missingSkills.map(skill => (
              <Badge
                key={skill.name}
                color="danger-light"
                className="mr-2 mt-2"
              >
                {skill.name}
                <span className="font-weight-bold ml-1">{skill.count}</span>
              </Badge>
            ))}
          </div>
        </Col>
      )}
    </Row>
  )
}

JobFeedbackComponent.propTypes = {
  jobId: PropTypes.string.isRequired,
}
