import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { shortenText } from "../../lib/helpers/text-helpers"
import { useList } from "../../hooks/use-list"

export const AtsAutoSyncWarningModal = ({
  isOpen,
  onClose,
  onConfirm,
  isJobSyncAuto,
  isMatchExportAuto,
  isUpdatingIntegration,
}) => {
  const { has, toggle } = useList([])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose} className="px-4">
        <FormattedMessage id="app.page.integrations.settings.modalWarning.areYouSure" />
      </ModalHeader>
      <ModalBody className="px-4 pb-1">
        {isJobSyncAuto && (
          <div>
            <p className="mb-1">
              <b>
                <FormattedMessage id="app.page.integrations.settings.jobSyncType.header" />
              </b>
            </p>
            <FormattedMessage id="app.page.integrations.settings.modalWarning.jobSyncType.auto">
              {txt => (
                <p className="text-muted">
                  {has("jobSyncType") ? txt : shortenText(txt, 128)}
                  <Button
                    color="link"
                    className="py-0 px-1 m-0"
                    onClick={() => toggle("jobSyncType")}
                  >
                    <b className="mb-1">
                      <FormattedMessage
                        id={
                          has("jobSyncType")
                            ? "app.page.integrations.settings.less"
                            : "app.page.integrations.settings.more"
                        }
                      />
                    </b>
                  </Button>
                </p>
              )}
            </FormattedMessage>
          </div>
        )}
        {isMatchExportAuto && (
          <div className={classNames({ "border-top mt-3": isJobSyncAuto })}>
            <p className={classNames("mb-1", { "mt-3": isJobSyncAuto })}>
              <b>
                <FormattedMessage id="app.page.integrations.settings.matchExportType.header" />
              </b>
            </p>
            <FormattedMessage id="app.page.integrations.settings.modalWarning.matchExportType.auto">
              {txt => (
                <p className="text-muted">
                  {has("matchExportType") ? txt : shortenText(txt, 128)}
                  <Button
                    color="link"
                    className="py-0 px-1 m-0"
                    onClick={() => toggle("matchExportType")}
                  >
                    <b className="mb-1">
                      <FormattedMessage
                        id={
                          has("matchExportType")
                            ? "app.page.integrations.settings.less"
                            : "app.page.integrations.settings.more"
                        }
                      />
                    </b>
                  </Button>
                </p>
              )}
            </FormattedMessage>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="px-4">
        <Button color="light" onClick={onClose}>
          <FormattedMessage id="app.common.cancel" />
        </Button>
        <Button
          color="success"
          onClick={onConfirm}
          loading={isUpdatingIntegration}
          disabled={isUpdatingIntegration}
        >
          <FormattedMessage id="app.common.confirm" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

AtsAutoSyncWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isJobSyncAuto: PropTypes.bool.isRequired,
  isMatchExportAuto: PropTypes.bool.isRequired,
  isUpdatingIntegration: PropTypes.bool.isRequired,
}

AtsAutoSyncWarningModal.defaultProps = {
  isOpen: false,
  isJobSyncAuto: false,
  isMatchExportAuto: false,
  isUpdatingIntegration: false,
}
