import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const OnOffSign = ({ value, className }) =>
  value ? (
    <i className={classNames("fas fa-check-circle text-success", className)} />
  ) : (
    <i className={classNames("fas fa-times-circle text-danger", className)} />
  )

OnOffSign.propTypes = {
  value: PropTypes.bool.isRequired,
  className: PropTypes.string,
}
