const overAllAndLabelsMap = [
  {
    label: "offer",
    path: "matchesSent",
  },
  {
    label: "accepted",
    path: "applicationsAccepted",
  },
  {
    label: "declined",
    path: "applicationsDeclined",
  },
  {
    label: "expired",
    path: "applicationsExpired",
  },
  {
    label: "intros",
    path: "applicationsAccepted",
  },
  {
    label: "rejected",
    path: "applicationsRejected",
  },
  {
    label: "exported",
    path: "applicationsAutoExported",
  },
  {
    label: "hired",
    path: "applicationsApproved",
  },
]

export const mapper = (data = [], formatMessage, selectedJob) => {
  const [overall = {}, jobs = []] = data
  return {
    data: [
      overAllAndLabelsMap.map(f => overall[f.path] || 0),
      !!selectedJob?.value
        ? overAllAndLabelsMap.map(
            f =>
              jobs.find(job => job.id === selectedJob.value)?.statistics[
                f.path
              ] || 0,
          )
        : [],
    ],
    labels: overAllAndLabelsMap.map(f =>
      formatMessage({
        id: `app.page.analytics.charts.matching.labels.${f.label}`,
      }),
    ),
  }
}
