import { createSelector } from "reselect"

export const stateSelector = state => state.messageTemplate

export const messageTemplateEntitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)

export const messageTemplatesIdsSelector = createSelector(
  stateSelector,
  state => state.messageTemplates,
)

export const messageTemplatesSelector = createSelector(
  messageTemplateEntitiesSelector,
  messageTemplatesIdsSelector,
  (entities, ids) => ids.map(id => entities[id]),
)

export const isLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading,
)

export const isUpdatingSelector = createSelector(
  stateSelector,
  state => state.isUpdating,
)

export const templateDeletingIdSelector = createSelector(
  stateSelector,
  state => state.templateDeletingId,
)

export const isDeletingSelector = createSelector(
  templateDeletingIdSelector,
  (_, { id }) => parseInt(id, 10),
  (templateDeletingId, templateId) => templateDeletingId === templateId,
)

export const isCreatingSelector = createSelector(
  stateSelector,
  state => state.isCreating,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const messageTemplatesCountSelector = createSelector(
  stateSelector,
  state => state.count,
)
