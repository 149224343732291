import { SUBSCRIPTION_TYPES } from "../../constants"
import { isNumeric } from "./index"
import { anyPass } from "ramda"

export function isSubscriptionUpfront(subscription) {
  return (
    subscription.durationIntervalCount === subscription.billingIntervalCount
  )
}

export function isComSubscriptionActive(companySubscription) {
  return (
    companySubscription.status.startsWith("opened_") ||
    companySubscription.status.startsWith("canceling_")
  )
}

export function isComSubscriptionCanceled(companySubscription) {
  return companySubscription.status.startsWith("canceled_")
}

export function isComSubscriptionCanceling(companySubscription) {
  return companySubscription.status.startsWith("canceling_")
}

export function isComSubscriptionCanceledPaid(companySubscription) {
  return companySubscription.status === "canceled_paid"
}

export function isComSubscriptionCancelingPaid(companySubscription) {
  return companySubscription === "canceling_paid"
}

export function isComSubscriptionIncomplete(companySubscription) {
  return companySubscription.status === "incomplete"
}

export function isComSubscriptionIncompleteExpired(companySubscription) {
  return companySubscription.status === "incomplete_expired"
}

export function isComSubscriptionPaused(companySubscription) {
  return companySubscription.status === "paused"
}

export function isComSubscriptionExpired(companySubscription) {
  return companySubscription.status === "expired_paid"
}

export function isComSubscriptionPaid(companySubscription) {
  return companySubscription.status.endsWith("_paid")
}

export function isComSubscriptionOpenPaid(companySubscription) {
  return companySubscription.status === "opened_paid"
}

export function isComSubscriptionPastDue(companySubscription) {
  return companySubscription.status.endsWith("_past_due")
}

export function isSubscriptionMain(subscription) {
  return subscription.type === SUBSCRIPTION_TYPES.main
}

export function isSubscriptionPph(subscription) {
  return subscription.type === SUBSCRIPTION_TYPES.pph
}

export function isComSubscriptionMainAndOpenPaid(companySubscription) {
  return (
    isSubscriptionMain(companySubscription.subscription) &&
    isComSubscriptionOpenPaid(companySubscription)
  )
}

export function isComSubscriptionMainAndPaid(companySubscription) {
  return (
    isSubscriptionMain(companySubscription.subscription) &&
    isComSubscriptionPaid(companySubscription)
  )
}

export function isComSubscriptionCancelable(companySubscription) {
  return (
    isSubscriptionMain(companySubscription.subscription) &&
    (companySubscription.status === "opened_paid" ||
      companySubscription.status === "opened_past_due")
  )
}

export function isComSubscriptionRenewable(companySubscription) {
  return (
    isSubscriptionMain(companySubscription.subscription) &&
    (companySubscription.status === "canceling_paid" ||
      companySubscription.status === "canceling_past_due")
  )
}

export function isComSubscriptionLongTerm(companySubscription) {
  return (
    companySubscription.subscription.billingInterval === "month" &&
    companySubscription.subscription.durationIntervalCount >= 12
  )
}

export function isComSubscriptionPausable(companySubscription) {
  return (
    isComSubscriptionLongTerm(companySubscription) &&
    isSubscriptionMain(companySubscription.subscription) &&
    (companySubscription.status === "opened_paid" ||
      companySubscription.status === "canceling_paid")
  )
}

export function isComSubscriptionResumable(companySubscription) {
  return (
    isSubscriptionMain(companySubscription.subscription) &&
    isComSubscriptionPaused(companySubscription)
  )
}

export function isComSubscriptionUpgradable(companySubscription) {
  // There are 2 types of subscriptions: main and pph

  // PPH subscriptions are always upgradable
  if (isSubscriptionPph(companySubscription.subscription)) {
    return true
  }

  // Main subscriptions are only upgradable in certain statuses:
  // opened_paid, canceled_paid, canceling_paid, incomplete_expired
  if (isSubscriptionMain(companySubscription.subscription)) {
    if (
      anyPass([
        isComSubscriptionOpenPaid,
        isComSubscriptionCanceledPaid,
        isComSubscriptionCancelingPaid,
        isComSubscriptionIncompleteExpired,
      ])(companySubscription)
    ) {
      return true
    }
  }

  return false
}

export function getCompanySubscriptionAvailableJobSlots(companySubscription) {
  if (!companySubscription || !companySubscription.subscription) return 0

  return (
    companySubscription.quantity * companySubscription.subscription.jobSlots
  )
}

export function getSubscriptionPrices(subscription, coupon) {
  const duration = subscription.durationIntervalCount
  const upfront =
    subscription.billingIntervalCount === subscription.durationIntervalCount
  const discount = getDiscountValue(subscription, coupon)

  const price = subscription.price
  const pricePerJob = price / subscription.jobsMax
  const totalPrice = price * duration

  let priceWithDiscount = price
  let priceWithDiscountForFirstMonths = price
  let totalPriceWithDiscount = totalPrice

  if (discount && !upfront) {
    if (coupon.duration === "forever") {
      priceWithDiscount = price - discount
      totalPriceWithDiscount = priceWithDiscount * duration
    } else {
      priceWithDiscountForFirstMonths = price - discount
      totalPriceWithDiscount =
        priceWithDiscount * duration -
        priceWithDiscount +
        priceWithDiscountForFirstMonths
    }
  }

  if (discount && upfront) {
    totalPriceWithDiscount = totalPrice - discount
    priceWithDiscount = totalPriceWithDiscount / duration
  }

  const pricePerJobWithDiscount = priceWithDiscount / subscription.jobsMax
  const pricePerJobWithDiscountForFirstMonth =
    priceWithDiscountForFirstMonths / subscription.jobsMax

  return {
    discount,
    price,
    pricePerJob,
    totalPrice,
    priceWithDiscount,
    totalPriceWithDiscount,
    pricePerJobWithDiscount,
    priceWithDiscountForFirstMonths,
    pricePerJobWithDiscountForFirstMonth,
  }
}

export function getDiscountValue(subscription, coupon) {
  if (!coupon) {
    return 0
  }

  const upfront = isSubscriptionUpfront(subscription)

  if (coupon.percentOff && isNumeric(coupon.percentOff)) {
    const percentOff = parseFloat(coupon.percentOff)

    if (upfront) {
      return (
        (subscription.price * subscription.durationIntervalCount * percentOff) /
        100
      )
    }

    return (subscription.price * percentOff) / 100
  }

  return 0
}

export const getTotalFlatSlots = companySubscription => {
  const {
    subscription: { type, jobSlots },
  } = companySubscription
  return type === SUBSCRIPTION_TYPES.main ? jobSlots : 0
}

export const getCurrentAvailableFlatSlots = companySubscription => {
  const { flatSlotsUsed } = companySubscription
  const totalFlatSlots = getTotalFlatSlots(companySubscription)
  return totalFlatSlots - flatSlotsUsed
}

export const isUnlimitedJobSlots = jobSlots => jobSlots === 10000
