import React from "react"
import { SingleColumnLayout } from "../layouts/single-column-layout"
import { CardLoader } from "../loaders/card-loader"
import { List } from "../loaders/list-loader"

export function ReferredCandidatePageSkeleton(props) {
  return (
    <SingleColumnLayout>
      <div className="bg-white p-3">
        <CardLoader />
      </div>
      <div className="bg-white p-3 mx-auto mt-2">
        <List />
        <List />
      </div>
    </SingleColumnLayout>
  )
}
