import { arrayToObject, getActionType } from "../lib/helpers"
import { evolve, F, T, always, mergeLeft, over, lensProp } from "ramda"
import {
  SUCCESS,
  GET,
  LOGOUT,
  SLACK,
  INTEGRATION,
  LIST,
  FAIL,
  START,
  CONNECT,
  REDIRECT,
  OAUTH,
} from "../constants"

export const defaultState = {
  loading: false,
  isConnecting: false,
  err: null,
  entities: {},
}

export const reducer = function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, SLACK, INTEGRATION, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, SLACK, INTEGRATION, LIST, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: mergeLeft(arrayToObject(payload.integrations, "id")),
        },
        state,
      )

    case getActionType(GET, SLACK, INTEGRATION, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(CONNECT, SLACK, START):
    case getActionType(CONNECT, SLACK, REDIRECT, START):
      return evolve({ isConnecting: T }, state)

    case getActionType(CONNECT, SLACK, SUCCESS):
      return evolve(
        {
          isConnecting: F,
          err: always(null),
          entities: over(
            lensProp(payload.integration.id),
            mergeLeft(payload.integration),
          ),
        },
        state,
      )

    case getActionType(CONNECT, OAUTH, FAIL):
    case getActionType(CONNECT, SLACK, FAIL):
    case getActionType(CONNECT, SLACK, REDIRECT, FAIL):
      return evolve({ isConnecting: F, err: always(payload.err) }, state)

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
