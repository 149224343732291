import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import cn from "classnames"
import {
  getVrCandidateList,
  resetVrCandidateList,
  setVrCandidateStatus,
} from "../../../../actions/vr-candidate-actions"
import { formatDate } from "../../../../lib/helpers"
import {
  isLoadingSelector,
  statusUpdatingSelector,
  vrCandidatesSelector,
} from "../../../../selectors/vr-candidate-selector"
import { Img } from "../../../common/img-component"

import ChatBubble from "../../../../assets/img/chat-bubble.svg"
import CloseIcon from "../../../../assets/img/close.svg"
import styles from "./candidates-list.module.scss"
import { Modal } from "reactstrap"
import { TextLoader } from "../../../loaders/text-loader"
import { FormattedMessage } from "react-intl"
import { VR_CANDIDATE_STATUSES } from "../../../../default-options"
import { CandidateStatusSelect } from "./candidate-status-select/candidate-status-select"

const getFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`

const getLinkedInSlug = url => {
  const urlParts = url.split("/")
  const lastIndex = urlParts.length - 1

  if (!urlParts.length) {
    return ""
  }

  return urlParts[lastIndex] || urlParts[lastIndex - 1]
}

export const CandidatesListComponent = ({
  setVrCandidateStatus,
  candidates,
  campaign,
  resetVrCandidateList,
}) => {
  const [chat, setChat] = useState(null)

  const openChat = ({ messengerEntries }) => {
    setChat(messengerEntries)
  }

  const closeChat = () => {
    setChat(null)
  }

  useEffect(() => {
    return resetVrCandidateList
  }, [resetVrCandidateList])

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.head}>
          <p className={styles.fullname}>
            <FormattedMessage id="app.modal.virtualRecruiter.fullName" />
          </p>
          <p className={styles.closingDate}>
            <FormattedMessage id="app.modal.virtualRecruiter.closingDate" />
          </p>
          <p className={styles.location}>
            <FormattedMessage id="app.modal.virtualRecruiter.location" />
          </p>
          <p className={styles.linkedinId}>
            <FormattedMessage id="app.modal.virtualRecruiter.linkedinId" />
          </p>
          <p className={styles.email}>
            <FormattedMessage id="app.modal.virtualRecruiter.email" />
          </p>
          <p className={styles.phone}>
            <FormattedMessage id="app.modal.virtualRecruiter.phone" />
          </p>
          <p className={styles.chat}>
            <FormattedMessage id="app.modal.virtualRecruiter.chatHistory" />
          </p>
          <p className={styles.chat}>
            <FormattedMessage id="app.modal.virtualRecruiter.action" />
          </p>
        </div>

        {!candidates || !candidates.length ? (
          <TextLoader />
        ) : (
          candidates.map(
            ({
              id: candidateId,
              firstName,
              lastName,
              closingDate,
              location,
              linkedinId,
              phoneNumber,
              email,
              messengerEntries,
              status,
            }) => (
              <div
                className={cn(
                  styles.item,
                  styles[`item_${status.toLowerCase()}`],
                )}
                key={candidateId}
              >
                <p className={styles.fullname}>
                  {getFullName({ firstName, lastName })}
                </p>
                <p className={styles.closingDate}>
                  <span className={styles.mOnly}>
                    <FormattedMessage id="app.modal.virtualRecruiter.closingDate" />
                    {` – `}
                  </span>
                  {closingDate && formatDate(closingDate)}
                </p>
                <p className={styles.location}>
                  <span className={cn([styles.shortable, styles.dOnly])}>
                    {location.split(", ")[0]}
                  </span>
                  <span className={cn([styles.shortable, styles.mOnly])}>
                    {location}
                  </span>
                </p>
                <p className={styles.linkedinId}>
                  <a
                    className={styles.shortable}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={linkedinId}
                  >
                    {getLinkedInSlug(linkedinId)}
                  </a>
                </p>
                <p className={styles.email}>
                  <a
                    className={styles.shortable}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${email}`}
                  >
                    {email}
                  </a>
                </p>
                <p className={styles.phone}>{phoneNumber}</p>
                <div className={styles.chat}>
                  <button
                    className={styles.chatBtn}
                    onClick={() => {
                      openChat({ messengerEntries })
                    }}
                  >
                    <span className={styles.mOnly}>Show chat</span>
                    <Img src={ChatBubble} width="15" height="15" />
                  </button>
                </div>
                <div className={styles.status}>
                  <CandidateStatusSelect
                    value={status}
                    options={VR_CANDIDATE_STATUSES}
                    onChange={status => {
                      setVrCandidateStatus({
                        data: { status },
                        candidateId,
                      })
                    }}
                  />
                </div>
              </div>
            ),
          )
        )}
      </div>

      <Modal isOpen={!!chat} toggle={closeChat} centered>
        <>
          <button className={styles.closeChat} onClick={closeChat}>
            <Img src={CloseIcon} width="12" height="12" />
          </button>
          <div className={styles.chatContent}>
            {chat &&
              chat.map(({ content }, i) => (
                <p key={i} style={{ whiteSpace: "pre-line" }}>
                  {content}
                </p>
              ))}
          </div>
        </>
      </Modal>
    </div>
  )
}

CandidatesListComponent.propTypes = {
  setVrCandidateStatus: PropTypes.func,
  resetVrCandidateList: PropTypes.func,
  candidates: PropTypes.array,
  campaign: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    candidates: vrCandidatesSelector(state),
    isCandidatesLoading: isLoadingSelector(state),
    statusIsUpdating: statusUpdatingSelector(state),
  }
}

const mapDispatchToProps = {
  getVrCandidateList,
  setVrCandidateStatus,
  resetVrCandidateList,
}

export const CandidatesList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CandidatesListComponent)
