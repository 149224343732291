import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  errorInterceptor,
} from "./axios-interceptors"
import { getAuthorization } from "./helpers/api-helpers"
import { store } from "./store"

export const AtsApiKeys = {
  AtsJobs: "atsJobs",
  CompanyIntegrations: "companyIntegrations",
  JobIntegrations: "jobIntegrations",
  Providers: "providers",
  Provider: "provider",
  OpenAtsJobs: "openAtsJobs",
  UnintegratedMoJobs: "unintegratedJobs",
}

class AtsApi {
  constructor() {
    const { jwt } = store.getState()

    this.http = axios.create({
      baseURL: config.moberries.ats.url,
      timeout: 90000,
      headers: {
        Authorization: getAuthorization(jwt),
      },
    })
    axiosRetry(this.http, { retries: 3 })

    this.http.interceptors.response.use(response => response, errorInterceptor)
    this.http.interceptors.response.use(saveOriginalInterceptor)
    this.http.interceptors.response.use(camelizeInterceptor)
  }

  get companyId() {
    const { company } = store.getState()
    const { id: companyId } = company ?? {}

    if (!companyId) {
      throw new Error("There's no companyId in the store!")
    }

    return companyId
  }

  setDefaultHeader = ({ header, value }) => {
    this.http.defaults.headers[header] = value
  }

  getAtsJobIntegrationList = ({
    companyIntegrationId,
    jobIds,
    refreshSRJobIds,
  }) =>
    this.http.get("/api/v1/ats-job-integrations", {
      params: {
        companyIntegration: companyIntegrationId,
        moberriesJob: jobIds,
        _limit: 999,
        refreshSRJobIds: refreshSRJobIds,
      },
    })

  getAtsJobIntegration = ({ id }) =>
    this.http.get(`/api/v1/ats-job-integrations/${id}`)

  createAtsJobIntegration = ({ moberriesJobId, providerJobId }) =>
    this.http.post("/api/v1/ats-job-integrations", {
      moberriesJobId,
      providerJobId,
    })

  deleteAtsJobIntegration = ({ integrationId }) =>
    this.http.delete(`/api/v1/ats-job-integrations/${integrationId}`)

  getAtsCompanyIntegrationList = () =>
    this.http.get("/api/v1/ats-company-integrations", {
      params: { moberriesCompany: this.companyId },
    })

  createAtsCompanyIntegration = ({ provider, data }) =>
    this.http.post("/api/v1/ats-company-integrations", {
      moberriesCompanyId: this.companyId,
      provider,
      data,
    })

  updateAtsCompanyIntegration = ({ id, data, ...rest }) => {
    return this.http.patch(`/api/v1/ats-company-integrations/${id}`, {
      data,
      ...rest,
    })
  }

  deleteAtsCompanyIntegration = ({ id }) =>
    this.http.delete(`/api/v1/ats-company-integrations/${id}`)

  getAtsCompanyIntegrationOpenJobList = ({ atsCompanyIntegrationId, params }) =>
    this.http.get(
      `/api/v1/ats-company-integrations/${atsCompanyIntegrationId}/jobs`,
      {
        params: { _limit: 999, ...params },
      },
    )

  getAtsCompanyIntegrationOpenJob = ({ atsCompanyIntegrationId, atsJobId }) =>
    this.http.get(
      `/api/v1/ats-company-integrations/${atsCompanyIntegrationId}/jobs/${atsJobId}`,
    )

  importJobsFromAts = providerJobIds =>
    this.http.post("/api/v1/ats-job-integrations/bulk-import", providerJobIds)

  getAtsProvidersList = () => this.http.get("/api/v1/ats-providers")
  getAtsProvider = ({ slug }) => this.http.get(`/api/v1/ats-providers/${slug}`)
  getUnintegratedJobs = () => this.http.get("/api/v1/ats-unintegrated-jobs")
}

const atsApi = new AtsApi()
const api = atsApi // TODO: delete

store.subscribe(({ jwt }) => {
  api.setDefaultHeader(
    { header: "Authorization", value: getAuthorization(jwt) },
    store => store.jwt,
  )
})

export { api, atsApi }
