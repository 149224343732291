import React from "react"
import PropTypes from "prop-types"
import { Media, Badge } from "reactstrap"
import { FormattedMessage } from "react-intl"
import defaultImage from "../../assets/img/default-logo.png"
import { Button } from "../common/button-component"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api, ApiKeys } from "../../lib/moberries-api"
import { showErrorAlert } from "../../lib/alerts"
import { useQueryClient } from "react-query"

export const TeamCard = ({ team, handleClick, managers }) => {
  const queryClient = useQueryClient()
  const { mutateAsync: deleteCompanyTeam, isLoading: isDeleting } =
    useAxiosMutation(api.deleteCompanyTeam, {
      onSuccess: () => {
        queryClient.invalidateQueries(ApiKeys.Teams)
      },
      onError: err => {
        showErrorAlert({ err })
      },
    })
  return (
    <Media className="bg-white p-3 mb-1 align-items-center">
      <img src={defaultImage} height="48" alt="blah" className="mr-3" />
      <Media body>
        <h5>{team.name} </h5>
        <div className="tags-wrapper">
          {managers.map(m => {
            return (
              <Badge border="true" color="light" key={m.id}>
                {m.firstName} {m.lastName}
              </Badge>
            )
          })}
        </div>
      </Media>
      {managers.length === 0 && (
        <Button
          color="danger"
          className="p-2 mr-2"
          onClick={() => deleteCompanyTeam({ teamId: team.id })}
          loading={isDeleting}
          disabled={isDeleting}
        >
          <i className="fas fa-minus-circle pr-2" />
          <FormattedMessage id="app.common.remove" />
        </Button>
      )}

      <Button color="light" className="ml-1 btn-circle" onClick={handleClick}>
        <i className="fas fa-pen" />
      </Button>
    </Media>
  )
}

TeamCard.propTypes = {
  team: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  managers: PropTypes.array.isRequired,
}
