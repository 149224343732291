import React from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Modal, ModalHeader } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { api } from "../../lib/moberries-api"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import {
  createMessageTemplateFail,
  createMessageTemplateSuccess,
} from "../../actions/message-template-actions"
import { showErrorAlert } from "../../lib/alerts"
import { MessageTemplateForm } from "../message-template/message-template-form"

export const CreateMessageTemplateModal = ({
  close,
  toggle,
  isOpen,
  initialValues,
}) => {
  const dispatch = useDispatch()

  const { mutateAsync: createMessageTemplate } = useAxiosMutation(
    api.createMessageTemplate,
    {
      onSuccess: messageTemplate => {
        dispatch(createMessageTemplateSuccess({ messageTemplate }))
      },
      onError: err => {
        showErrorAlert({ err })
        dispatch(createMessageTemplateFail({ err }))
      },
    },
  )

  const handleSubmit = (data, actions) => {
    createMessageTemplate({ data })
    close()
    actions.resetForm()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="app.modal.messageTemplate.title.create" />
      </ModalHeader>

      <MessageTemplateForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        onCancel={close}
      />
    </Modal>
  )
}
CreateMessageTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
}
CreateMessageTemplateModal.defaultProps = {
  isOpen: false,
}
