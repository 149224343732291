import PropTypes from "prop-types"
import React from "react"
import styles from "./picker-pagination.module.scss"
import { PickerPaginationButton } from "./picker-pagination-button"

export const PickerPagination = ({ disabled, count, page, limit, setPage }) => {
  if (count === null || count === 0) return null

  const totalPages = Math.floor((count - 1) / limit) + 1
  const pages = []

  if (totalPages < 6) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <PickerPaginationButton
          onPageChange={setPage}
          page={i}
          disabled={disabled}
          active={page === i}
          key={i}
        >
          <p>{i}</p>
        </PickerPaginationButton>,
      )
    }
  }

  if (page <= 3 && totalPages >= 6) {
    for (let i = 1; i < 6; i++) {
      pages.push(
        <PickerPaginationButton
          onPageChange={setPage}
          page={i}
          disabled={disabled}
          active={page === i}
          key={i}
        >
          <p>{i}</p>
        </PickerPaginationButton>,
      )
    }
    pages.push(
      <PickerPaginationButton key={totalPages + 1} disabled>
        <p>...</p>
      </PickerPaginationButton>,
    )
  }

  if (page > 3 && page <= totalPages - 3 && totalPages >= 6) {
    pages.push(
      <PickerPaginationButton key={totalPages + 1} disabled>
        <p>...</p>
      </PickerPaginationButton>,
    )

    for (let i = page - 2; i <= page + 2; i++) {
      pages.push(
        <PickerPaginationButton
          onPageChange={setPage}
          page={i}
          disabled={disabled}
          active={page === i}
          key={i}
        >
          <p>{i}</p>
        </PickerPaginationButton>,
      )
    }

    pages.push(
      <PickerPaginationButton key={totalPages + 2} disabled>
        <p>...</p>
      </PickerPaginationButton>,
    )
  }

  if (page > totalPages - 3 && totalPages >= 6) {
    pages.push(
      <PickerPaginationButton key={totalPages + 1} disabled>
        <p>...</p>
      </PickerPaginationButton>,
    )

    for (let i = totalPages - 6 + 2; i <= totalPages; i++) {
      pages.push(
        <PickerPaginationButton
          onPageChange={setPage}
          page={i}
          disabled={disabled}
          active={page === i}
          key={i}
        >
          <p>{i}</p>
        </PickerPaginationButton>,
      )
    }
  }

  return (
    <div className={styles.pagination}>
      <PickerPaginationButton
        onPageChange={setPage}
        page={1}
        disabled={page === 1 || disabled}
      >
        <p>«</p>
      </PickerPaginationButton>

      <PickerPaginationButton
        onPageChange={setPage}
        page={page - 1}
        disabled={page === 1 || disabled}
      >
        <p>‹</p>
      </PickerPaginationButton>

      {pages}

      <PickerPaginationButton
        onPageChange={setPage}
        page={page + 1}
        disabled={page === totalPages || disabled}
      >
        <p>›</p>
      </PickerPaginationButton>

      <PickerPaginationButton
        onPageChange={setPage}
        page={totalPages}
        disabled={page === totalPages || disabled}
      >
        <p>»</p>
      </PickerPaginationButton>
    </div>
  )
}

PickerPagination.propTypes = {
  disabled: PropTypes.bool.isRequired,
  count: PropTypes.number,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
}

PickerPagination.defaultProps = {
  disabled: false,
}
