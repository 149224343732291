import { getActionType } from "../lib/helpers"
import { FAIL, GET, START, STATUS, SUCCESS, SURVEY, CLOSE } from "../constants"
import { config } from "../config"

export function getSurveyStatus({ companyId }) {
  return async function (dispatch, getState, { typeformApi }) {
    dispatch({ type: getActionType(GET, SURVEY, STATUS, START) })
    try {
      const [{ data: en }, { data: de }] = await Promise.all([
        typeformApi.getSurveyStatus({
          params: { query: companyId, code: config.typeform.codes.en },
        }),
        typeformApi.getSurveyStatus({
          params: { query: companyId, code: config.typeform.codes.de },
        }),
      ])
      const isFormCompleted = data =>
        data.items.some(i => Number(i.hidden.company) === companyId)
      dispatch({
        type: getActionType(GET, SURVEY, STATUS, SUCCESS),
        payload: { completed: isFormCompleted(en) || isFormCompleted(de) },
      })
    } catch (err) {
      dispatch({
        type: getActionType(GET, SURVEY, STATUS, FAIL),
        payload: { err },
      })
    }
  }
}

export function closeSurvey() {
  return {
    type: getActionType(CLOSE, SURVEY),
    payload: { closingTimestamp: new Date().toISOString() },
  }
}
