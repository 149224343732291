import React from "react"
import PropTypes from "prop-types"
import { IntegrationCard } from "./integration-card"
import { Badge } from "../common/badge-component"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import slackLogo from "../../assets/img/slack-logo.svg"
import { FormattedLink } from "../common/formatted-link-component"

export function SlackIntegrationCard({
  slackIntegration,
  onConnectClick,
  disabled,
  loading,
  className,
  ...rest
}) {
  return (
    <IntegrationCard
      {...rest}
      title="Slack"
      picture={slackLogo}
      className={className}
    >
      {slackIntegration ? (
        <dl tags="true" className="row mb-2">
          <dt className="col-md-2 text-muted text-uppercase">Workspace</dt>
          <dd className="col-md-10">
            <Badge color="default">{slackIntegration.teamName}</Badge>
          </dd>
          <dt className="col-md-2 text-muted text-uppercase">Channel</dt>
          <dd className="col-md-10">
            <Badge color="default">{slackIntegration.channelName}</Badge>
          </dd>
        </dl>
      ) : null}
      <p content="true">
        <FormattedMessage
          id="app.page.integrations.slack.instruction"
          values={{
            link: (
              <FormattedLink
                target="_blank"
                href="atsIntegrations.slack"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="app.page.integrations.slack.link" />
              </FormattedLink>
            ),
          }}
        />
      </p>
      <Button
        content
        color="success"
        onClick={onConnectClick}
        disabled={disabled}
        loading={loading}
      >
        <i className="fas fa-plus-circle pr-2" />
        {slackIntegration ? (
          <FormattedMessage id="app.common.reconnect" />
        ) : (
          <FormattedMessage id="app.common.connect" />
        )}
      </Button>
    </IntegrationCard>
  )
}

SlackIntegrationCard.propTypes = {
  onConnectClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  slackIntegration: PropTypes.shape({
    channelName: PropTypes.string.isRequired,
    teamName: PropTypes.string.isRequired,
  }),
}

SlackIntegrationCard.defaultProps = {
  disabled: false,
  loading: false,
}
