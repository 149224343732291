import humps from "humps"
import {
  GET,
  CREATE,
  DELETE,
  CARD,
  LIST,
  START,
  SUCCESS,
  FAIL,
  PROBLEMS,
} from "../constants"
import { getActionType } from "../lib/helpers"
import { startSubmit, stopSubmit } from "redux-form"
import { moErrorTypes } from "../lib/server-errors"
import { showErrorAlert, showAlert, alertTypes } from "../lib/alerts"

export function getCardList({ params } = {}) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(GET, CARD, LIST, START) })

    try {
      const res = await moApi.getCardList({
        params,
      })
      const { results: cards, count } = res.data

      dispatch({
        type: getActionType(GET, CARD, LIST, SUCCESS),
        payload: { cards, count },
      })
    } catch (err) {
      showErrorAlert({ err })

      dispatch({
        type: getActionType(GET, CARD, LIST, FAIL),
        payload: { err },
      })
    }
  }
}

export function createCard({ stripe, elements, holderName }) {
  return async function (dispatch, getState, { moApi }) {
    const formName = "PaymentCardForm"
    dispatch({ type: getActionType(CREATE, CARD, START) })
    dispatch(startSubmit(formName))

    try {
      const {
        data: { clientSecret },
      } = await moApi.createSetupIntent()

      let { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement("card"),
          billing_details: {
            name: holderName,
          },
        },
      })

      if (error) {
        error.problem = PROBLEMS.CLIENT_ERROR
        error.code = moErrorTypes.cardError
        throw error
      }

      setupIntent = humps.camelizeKeys(setupIntent)

      const { data: card } = await moApi.setDefaultPaymentMethod({
        paymentMethodId: setupIntent.paymentMethod,
      })

      dispatch({
        type: getActionType(CREATE, CARD, SUCCESS),
        payload: { card },
      })
      dispatch(stopSubmit(formName))
    } catch (err) {
      dispatch(stopSubmit(formName))
      showErrorAlert({ err })
      dispatch({
        type: getActionType(CREATE, CARD, FAIL),
        payload: {
          err: err.code === moErrorTypes.cardError ? null : err,
        },
      })
    }
  }
}

export function deleteCard({ cardId }) {
  return async function (dispatch, getState, { moApi }) {
    dispatch({ type: getActionType(DELETE, CARD, START) })

    try {
      await moApi.deleteCard({ cardId })

      dispatch({
        type: getActionType(DELETE, CARD, SUCCESS),
        payload: { cardId },
      })
      showAlert({ code: alertTypes.cardDeleteSuccess })
    } catch (err) {
      showErrorAlert({ err })
      dispatch({
        type: getActionType(DELETE, CARD, FAIL),
        payload: { err },
      })
    }
  }
}
