import { getActionType } from "../lib/helpers"
import {
  countSelector as integrationsCountSelector,
  atsJobIntegrationsSelector,
} from "../selectors/ats-job-integration-selector"
import {
  INTEGRATION,
  FAIL,
  GET,
  LIST,
  START,
  SUCCESS,
  ATS,
  COMPANY,
  OPEN,
  JOB,
} from "../constants"
import { store } from "../lib/store"

export function getAtsOpenJobsAndJobIntegrations({ search }) {
  return async function (dispatch, getState, { atsIntegrationApi, actions }) {
    dispatch({
      type: getActionType(
        GET,
        ATS,
        COMPANY,
        INTEGRATION,
        OPEN,
        JOB,
        LIST,
        START,
      ),
    })
    const { atsCompanyIntegration } = store.getState()

    if (!atsCompanyIntegration) return

    try {
      let [{ headers, data: openJobs }] = await Promise.all([
        atsIntegrationApi.getAtsCompanyIntegrationOpenJobList({
          atsCompanyIntegrationId: atsCompanyIntegration.id,
          params: { _q: search },
        }),
        dispatch(
          actions.atsJobIntegration.getAtsJobIntegrationList({
            refreshSRJobIds: true,
          }),
        ),
      ])
      const openJobsCount = parseInt(headers["x-total-count"], 10)
      const integrationsCount = integrationsCountSelector(getState())
      const integrations = atsJobIntegrationsSelector(getState())

      if (integrationsCount > 0) {
        openJobs = openJobs.map(openJob => {
          const connectedJob = integrations.find(
            integration =>
              String(integration.providerJob) === String(openJob.id),
          )
          if (!connectedJob) {
            return openJob
          }
          const moJobStatus = connectedJob.moJobStatus
          openJob.moberriesStatus = moJobStatus
          openJob.moberriesJobId = connectedJob.moberriesJob
          openJob.isExcludedFromSync = connectedJob.isExcludedFromSync
          return openJob
        })
      }

      dispatch({
        type: getActionType(
          GET,
          ATS,
          COMPANY,
          INTEGRATION,
          OPEN,
          JOB,
          LIST,
          SUCCESS,
        ),
        payload: { openJobs, count: openJobsCount },
      })
    } catch (err) {
      dispatch({
        type: getActionType(
          GET,
          ATS,
          COMPANY,
          INTEGRATION,
          OPEN,
          JOB,
          LIST,
          FAIL,
        ),
        payload: { err },
      })
    }
  }
}
