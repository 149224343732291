import io from "socket.io-client"
import * as Sentry from "@sentry/react"
import { config } from "../config"

export const socket = io(config.moberries.ws.url, config.moberries.ws.options)

const errors = [
  "xhr poll error",
  "xhr post error",
  "timeout",
  "websocket error",
]

function shouldErrorBeIgnored(err) {
  if (!err) return false
  if (errors.includes(err)) return true
  if (errors.includes(err.message)) return true
  return false
}

const logIfDev = err => {
  if (config.env.isDev || config.env.isMaster) {
    console.error(err)
  }
}

socket.on("error", err => {
  Sentry.captureException(err)
  if (!shouldErrorBeIgnored(err)) {
    logIfDev(err)
  }
})

socket.on("connect_error", err => {
  Sentry.captureException(err)
  if (!shouldErrorBeIgnored(err)) {
    logIfDev(err)
  }
})

socket.on("reconnect_error", err => {
  if (!shouldErrorBeIgnored(err)) {
    logIfDev(err)
  }
})

socket.on("reconnect_failed", () => {
  logIfDev("reconnect_failed")
})
