import React from "react"
import PropTypes from "prop-types"
import { camelize } from "humps"
import { FormattedMessage } from "react-intl"
import { Badge } from "../common/badge-component"

export const MatchRejectedReasons = ({ matchRejectedReasons }) => {
  return (
    <div className="bg-light container p-3 border-top border-bottom">
      <FormattedMessage tagName="h4" id="app.page.match.reject.reasons.title" />
      <div className="pt-2">
        <FormattedMessage
          tagName="h5"
          id="app.page.match.reject.reasons.tags.label"
        />
        {matchRejectedReasons.answers.map(answer => {
          return (
            <Badge key={answer.value} className="mr-1" color="danger-light">
              <FormattedMessage
                id={`app.feedback.match.reject.${camelize(answer.value)}`}
              />
            </Badge>
          )
        })}
      </div>
      {matchRejectedReasons.comment && (
        <div className="pt-3">
          <FormattedMessage tagName="h5" id="app.page.signup.form.comment" />
          {matchRejectedReasons.comment}
        </div>
      )}
    </div>
  )
}

MatchRejectedReasons.propTypes = {
  matchRejectedReasons: PropTypes.object.isRequired,
}
