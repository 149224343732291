import React from "react"
import PropTypes from "prop-types"

export const MatchApplicationTextAnswer = ({ applicationAnswer }) => {
  return (
    <div className="overflow-auto long-text-answer-max-height">
      {applicationAnswer.answer.answer ?? applicationAnswer.answer}
    </div>
  )
}

MatchApplicationTextAnswer.propTypes = {
  applicationAnswer: PropTypes.shape({
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    question: PropTypes.object.isRequired,
  }),
}
