import PropTypes from "prop-types"
import styles from "./round-chart.module.scss"

const getBeforeStyle = ({ value }) => ({
  background: `
    radial-gradient(farthest-side, #69a265 98%,#0000) top/16px 16px no-repeat,
    conic-gradient(#69a265 calc(${value}%), #0000 0)
  `,
})

const getAfterStyle = ({ width, value }) => ({
  transform: `rotate(calc(${value}*3.6deg)) translateY(calc(50% - ${width}px/2))`,
})

export const RoundChart = ({ width = 80, height = 80, value = 15 }) => {
  return (
    <div className={styles.wrapper} style={{ width, height }}>
      <div className={styles.bgCircle}></div>
      <div className={styles.pie}>
        <div
          className={styles.pieBefore}
          style={getBeforeStyle({ value })}
        ></div>
        <div
          className={styles.pieAfter}
          style={getAfterStyle({ width, value })}
        ></div>
      </div>
      <p className={styles.label}>{value}%</p>
    </div>
  )
}

RoundChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.number,
}
