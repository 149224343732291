import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { ButtonsOrdering } from "./buttons-ordering"
import { SelectFilter } from "./select-filter"
import { SearchFilter } from "./search-filter"

const SEARCH_PLACEHOLDER_KEY_MAP = {
  job: "app.page.jobList.search.placeholder",
  match: "app.page.messages.search",
  dashboardMatch: "app.page.messages.search",
}

export const MatchOrJobFilter = ({
  isDisabled,
  teams,
  orderingFilterOptions,
  filterName,
}) => {
  return (
    <Row>
      <Col xs="12" lg="6">
        <SelectFilter
          placeholder={<FormattedMessage id="app.filter.job.placeholder" />}
          filterKey="jobTeam"
          filterName={filterName}
          disabled={isDisabled}
          filterOptions={teams}
          resetFilters={["page"]}
        />
      </Col>
      <Col xs="12" lg="6" className="mt-2 mt-lg-0">
        <SearchFilter
          filterKey="search"
          resetFilters={["page"]}
          placeholderKey={SEARCH_PLACEHOLDER_KEY_MAP[filterName]}
        />
      </Col>
      {orderingFilterOptions && (
        <Col xs="12" className="mt-2">
          <ButtonsOrdering
            filterOptions={orderingFilterOptions}
            filterKey="ordering"
            filterName={filterName}
            disabled={isDisabled}
          />
        </Col>
      )}
    </Row>
  )
}

MatchOrJobFilter.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  teams: PropTypes.array.isRequired,
  orderingFilterOptions: PropTypes.array,
  filterName: PropTypes.string.isRequired,
}

MatchOrJobFilter.defaultProps = {
  isDisabled: false,
}
