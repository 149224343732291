import { always, pipe, map, filter, anyPass, pathSatisfies } from "ramda"
import React, { useEffect, useState, Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedDate, FormattedMessage } from "react-intl"
import { Col } from "reactstrap"
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useDispatch, useSelector } from "react-redux"
import { getCompanyRewardsTimeSeries } from "../../actions/company-actions"
import {
  companyRewardsTimeSeriesByRangeIdSelector,
  areCompanyRewardsTimeSeriesByDataKeyLoading,
} from "../../selectors/company-selector"
import { DateRangeDropdown } from "../common/date-range-dropdown"
import {
  format,
  isAfter,
  parseISO,
  startOfMonth,
  startOfYear,
  subDays,
} from "date-fns"
import { dateRangeToOption } from "../../lib/helpers"

const isDefaultRange = ({ id }) => id === "last30Days"
const getPredefinedRanges = ({ companyCreatedAt, now }) => {
  const thisMonth = {
    id: "thisMonth",
    startDate: startOfMonth(now),
    endDate: now,
  }
  const lastWeek = { id: "lastWeek", startDate: subDays(now, 6), endDate: now }
  const last30Days = {
    id: "last30Days",
    startDate: subDays(now, 29),
    endDate: now,
  }
  const last90Days = {
    id: "last90Days",
    startDate: subDays(now, 89),
    endDate: now,
  }
  const last6Months = {
    id: "last6Months",
    startDate: subDays(now, 179),
    endDate: now,
  }
  const thisYear = { id: "thisYear", startDate: startOfYear(now), endDate: now }

  const predefinedRanges = [
    lastWeek,
    thisMonth,
    last30Days,
    last90Days,
    last6Months,
    thisYear,
  ]
  const isAfterCompanyCreation = ({ startDate }) =>
    isAfter(startDate, companyCreatedAt)

  return pipe(
    filter(anyPass([isDefaultRange, isAfterCompanyCreation])),
    map(dateRangeToOption),
  )(predefinedRanges)
}

export const MonetizationBarChartSection = ({ dataKey, companyCreatedAt }) => {
  const now = new Date()
  const predefinedRanges = getPredefinedRanges({ companyCreatedAt, now })
  const [range, setRange] = useState(predefinedRanges.find(isDefaultRange))
  const dispatch = useDispatch()
  const { startDate, endDate, id: rangeId } = range

  useEffect(() => {
    dispatch(
      getCompanyRewardsTimeSeries({
        params: { startDate, endDate },
        dataKey,
        rangeId,
      }),
    )
  }, [dispatch, startDate, endDate, dataKey, rangeId])

  const timeSeries = useSelector(state =>
    companyRewardsTimeSeriesByRangeIdSelector(state, {
      rangeId,
    }),
  )
  const isLoading = useSelector(state =>
    areCompanyRewardsTimeSeriesByDataKeyLoading(state, { dataKey }),
  )

  const total = timeSeries?.reduce((acc, data) => acc + data[dataKey], 0)

  return (
    <div className="px-3">
      <h5 className="border-bottom pb-2">
        <FormattedMessage id={`app.page.monetization.general.${dataKey}`} />
      </h5>
      <div className="d-flex justify-content-between">
        <h2 className="text-primary">{total}</h2>
        <Col xs={7} sm={4} className="pr-0 pl-0 pl-md-4">
          <DateRangeDropdown
            predefinedRanges={predefinedRanges}
            maxDate={now}
            setRange={setRange}
            selectedRange={range}
            id={dataKey}
            minDate={companyCreatedAt}
          />
        </Col>
      </div>
      <div className="bg-white text-muted position-relative pt-3">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={timeSeries?.map((statistics, i) => ({
              ...statistics,
              tickLabel:
                timeSeries?.length <= 31
                  ? i + 1
                  : format(parseISO(statistics.date), "d LLL yy"),
            }))}
            margin={{
              top: 20,
              right: 0,
              left: -38,
              bottom: 20,
            }}
          >
            <CartesianGrid horizontal={false} vertical={false} />
            <XAxis
              axisLine={false}
              tickLine={false}
              minTickGap={timeSeries?.length <= 31 ? 5 : 50}
              interval={timeSeries?.length <= 31 ? 0 : "preserveEnd"}
              dataKey="tickLabel"
              padding={{ left: 11 }}
            />
            <YAxis
              axisLine={false}
              allowDecimals={false}
              tickSize={18}
              tick={{ dy: -10, dx: 2, textAnchor: "start" }}
            />
            <Tooltip
              content={({ payload }) => {
                if (
                  pathSatisfies(value => value > 0, ["0", "value"], payload)
                ) {
                  return (
                    <div className="bg-white border p-3">
                      {payload.map(({ name, value, payload: { date } }) => (
                        <Fragment key={name}>
                          <div className="text-primary">
                            <FormattedMessage
                              id={`app.page.monetization.statistics.timeline.tooltip.${name}`}
                              values={{ count: value }}
                            />
                          </div>
                          <div>
                            <FormattedDate
                              value={parseISO(date)}
                              year="numeric"
                              month="long"
                              day="numeric"
                            />
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  )
                }
                return null
              }}
              labelFormatter={always(null)}
              cursor={false}
            />
            <Bar dataKey={dataKey} stackId="a" fill="#20a4f3" />
          </BarChart>
        </ResponsiveContainer>
        {isLoading && (
          <div className="loading-overlay">
            <i className="fas fa-circle-notch fa-2x fa-spin text-primary" />
          </div>
        )}
      </div>
    </div>
  )
}

MonetizationBarChartSection.propTypes = {
  companyCreatedAt: PropTypes.object.isRequired,
  dataKey: PropTypes.oneOf(["applications", "signups"]),
}

MonetizationBarChartSection.defaultProps = {}
