import React from "react"
import PropTypes from "prop-types"
import { NoActiveJobs } from "./no-active-jobs-component"
import { EmptyBoost } from "./empty-boost-component"
import { NoMatches } from "./no-matches-component"

export const EmptyMatchesTip = ({
  hasCompanyActiveJobs,
  companyStatistics,
}) => {
  if (!hasCompanyActiveJobs) {
    return <NoActiveJobs />
  } else if (companyStatistics.applicationsTotal >= 30) {
    return <EmptyBoost />
  } else {
    return <NoMatches />
  }
}

EmptyMatchesTip.propTypes = {
  hasCompanyActiveJobs: PropTypes.bool.isRequired,
  companyStatistics: PropTypes.object.isRequired,
}
