import { createSelector } from "reselect"

export const stateSelector = state => state.vrCampaign

export const countSelector = createSelector(stateSelector, state => state.count)

export const isLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)
export const idsSelector = createSelector(
  stateSelector,
  state => state.campaigns,
)

export const campaignsSelector = createSelector(
  entitiesSelector,
  idsSelector,
  (entities, ids) => ids.map(id => entities[id]),
)
