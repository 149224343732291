import React from "react"
import { isComSubscriptionPastDue } from "../../lib/helpers"
import { Container, Alert } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { useStore } from "../../lib/store"
import { pathOr } from "ramda"

export function PastDueSubscriptionAlert() {
  const companySubscription = useStore(
    pathOr(null, ["company", "subscriptions", "0"]),
  )
  if (!companySubscription || !isComSubscriptionPastDue(companySubscription)) {
    return null
  }

  return (
    <Alert
      className="mb-3 alert-floating"
      color="danger"
      isOpen={true}
      transition={{
        appear: false,
        enter: false,
        exit: false,
        timeout: 0,
      }}
    >
      <Container className="position-relative px-3">
        <div className="mr-3">
          <FormattedMessage id="app.alertbar.subscription.passedDue" />
        </div>
      </Container>
    </Alert>
  )
}
