import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

export const IntegrationSuggestion = ({ jobId }) => {
  return (
    <div className="bg-warning-light border-top p-3 text-center">
      <Link to={`/job/edit/${jobId}`}>
        <i className="fas fa-plus-circle pr-2" />
        <FormattedMessage id="app.common.connect" />
      </Link>{" "}
      <FormattedMessage id="app.page.match.integrationsBlock" />
    </div>
  )
}

IntegrationSuggestion.propTypes = {
  jobId: PropTypes.number.isRequired,
}

IntegrationSuggestion.defaultProps = {}
