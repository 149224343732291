import styles from "./empty-talent-affiliation.module.scss"
import leagueBg from "../../../assets/img/league-bg.svg"
import LeagueIcon from "../../../assets/img/league-icon.svg"
import InfoIcon from "../../../assets/img/info-icon.svg"
import React from "react"
import { FormattedMessage } from "react-intl"
import { store } from "../../../lib/store"
import { config } from "../../../config"
import { FormattedLink } from "../../common/formatted-link-component"

export const EmptyTalentAffiliation = () => {
  const { jwt } = store.getState()
  const affiliateRedirectUrl = `${config.moberries.affiliate.url}?token=${jwt}&type=redirect&purpose=0`
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.illustration}>
          <img src={leagueBg} width="170" height="117" alt="Moberries" />
          <img src={LeagueIcon} width="93" height="120" alt="Moberries" />
        </div>
        <div className={styles.text}>
          <p>
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.title" />
          </p>
          <p>
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.subTitle" />
          </p>
        </div>
        <div>
          <a
            href={affiliateRedirectUrl}
            className={styles.button}
            target="_blank"
            rel="noreferrer noopener"
          >
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.button" />
          </a>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.icon}>
          <img src={InfoIcon} width="22" height="22" alt="Moberries" />
        </div>
        <div className={styles.info}>
          <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.info1" />{" "}
          <FormattedLink
            target="_blank"
            rel="noopener noreferrer"
            href="recruiters"
          >
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.info1.here" />
          </FormattedLink>
          <p>
            <FormattedMessage id="app.page.analytics.charts.talentAffiliation.empty.info2" />{" "}
            <a href="mailto: am@moberries.com">am@moberries.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}
