import humps from "humps"
import { identity, isEmpty } from "ramda"
import { scroller } from "react-scroll"
import { atsErrorsMapping } from "../server-errors"

export const scrollToFirstInvalidField = errors => {
  const errorFields = Object.keys(errors)
  for (const name of errorFields) {
    const fieldName = `form-position-${name}`
    if (document.querySelector(`[name="${fieldName}"]`)) {
      scroller.scrollTo(fieldName, { offset: -200, smooth: true })
      return
    }
  }
}

export const submitAndValidate =
  (submitFunc, transformFunc = identity) =>
  async (values, actions) => {
    try {
      await submitFunc(transformFunc(values))
    } catch (err) {
      const errors = {}
      Object.keys(values).forEach(name => {
        const error = err.response?.data?.fieldErrors?.[name]?.[0]?.code
        if (error) {
          errors[name] = `app.error.code.${humps.camelize(error)}`
        }
      })
      if (!isEmpty(errors)) {
        actions.setStatus({ serverErrors: errors })
      }
      // We're purposefully not rethrowing the error, because the error is already returned by the `useMutation` hook, which is enough
    }
  }

export function setATSFormikErrorField(setFieldErrorFn, error) {
  Object.entries(error.response.data.errors).forEach(([name, value]) => {
    setFieldErrorFn(name, atsErrorsMapping[value.code])
  })
}
