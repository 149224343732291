import { pathSatisfies } from "ramda"
import { FAIL, PROBLEMS } from "../constants"
import { logout } from "../controllers/user-controller"
import { isProblem, isStatus } from "../lib/helpers"
import { config } from "../config"

export function errorMiddleware({ dispatch, getState }) {
  return next => action => {
    const { type, payload } = action

    if (!type.includes(FAIL)) return next(action)
    if (!payload || !payload.err) return next(action)

    if (isProblem(PROBLEMS.CLIENT_ERROR, payload.err)) {
      const moApi = pathSatisfies(
        url => url === config.moberries.api.url,
        ["config", "baseURL"],
        payload.err,
      )

      if (moApi && isStatus(401, payload.err)) {
        logout()
      }
    }

    next(action)
  }
}
