import { createSelector } from "reselect"

export const stateSelector = state => state.candidate

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading,
)
export const errorSelector = createSelector(stateSelector, state => state.err)
export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)
export const candidateIdsSelector = createSelector(
  stateSelector,
  state => state.candidates,
)

export const candidatesCountSelector = createSelector(
  stateSelector,
  state => state.candidatesCount,
)

export const candidateSelector = createSelector(
  entitiesSelector,
  (_, { id }) => parseInt(id, 10),
  (entities, id) => entities[id],
)

export const candidatesSelector = createSelector(
  entitiesSelector,
  candidateIdsSelector,
  (entities, ids) => ids.map(id => entities[id]),
)
