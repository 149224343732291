import { getActionType, arrayToObject } from "../lib/helpers"
import {
  GET,
  SUCCESS,
  MESSAGE,
  LIST,
  LOGOUT,
  CREATE,
  TEMPLATE,
  UPDATE,
  DELETE,
  START,
  FAIL,
} from "../constants"
import {
  always,
  evolve,
  mergeLeft,
  over,
  lensProp,
  prepend,
  without,
  F,
  T,
  pluck,
  pipe,
  inc,
  dec,
} from "ramda"

export const defaultState = {
  count: null,
  entities: {},
  messageTemplates: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  templateDeletingId: null,
  err: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, MESSAGE, TEMPLATE, LIST, START):
      return evolve(
        {
          isLoading: T,
        },
        state,
      )
    case getActionType(GET, MESSAGE, TEMPLATE, LIST, SUCCESS):
      return evolve(
        {
          isLoading: F,
          entities: mergeLeft(arrayToObject(payload.messageTemplates, "id")),
          messageTemplates: always(pluck("id", payload.messageTemplates)),
          count: always(payload.messageTemplates.length),
        },
        state,
      )
    case getActionType(CREATE, MESSAGE, TEMPLATE, START):
      return evolve(
        {
          isCreating: T,
        },
        state,
      )
    case getActionType(CREATE, MESSAGE, TEMPLATE, SUCCESS):
      return evolve(
        {
          isCreating: F,
          entities: over(
            lensProp(payload.messageTemplate.id),
            always(payload.messageTemplate),
          ),
          messageTemplates: prepend(payload.messageTemplate.id),
          count: inc,
        },
        state,
      )
    case getActionType(CREATE, MESSAGE, TEMPLATE, FAIL):
      return evolve(
        {
          isCreating: F,
          err: always(payload.err),
        },
        state,
      )
    case getActionType(UPDATE, MESSAGE, TEMPLATE, START):
      return evolve(
        {
          isUpdating: T,
        },
        state,
      )
    case getActionType(UPDATE, MESSAGE, TEMPLATE, SUCCESS):
      return evolve(
        {
          isUpdating: F,
          entities: evolve({
            [payload.messageTemplate.id]: always(payload.messageTemplate),
          }),
          messageTemplates: pipe(
            without([payload.messageTemplate.id]),
            prepend(payload.messageTemplate.id),
          ),
        },
        state,
      )
    case getActionType(UPDATE, MESSAGE, TEMPLATE, FAIL):
      return evolve(
        {
          isUpdating: F,
          err: always(payload.err),
        },
        state,
      )
    case getActionType(DELETE, MESSAGE, TEMPLATE, START):
      return evolve(
        {
          templateDeletingId: always(payload.messageTemplateId),
        },
        state,
      )
    case getActionType(DELETE, MESSAGE, TEMPLATE, SUCCESS):
      return evolve(
        {
          templateDeletingId: always(null),
          messageTemplates: without([payload.messageTemplateId]),
          count: dec,
        },
        state,
      )
    case getActionType(DELETE, MESSAGE, TEMPLATE, FAIL):
      return evolve(
        {
          templateDeletingId: always(null),
          err: always(payload.err),
        },
        state,
      )

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
