import React from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap"
import { compose } from "ramda"
import { useOpenClose } from "../../hooks/use-open-close"
import { DatePicker } from "./date-picker"
import { FormattedMessage } from "react-intl"

export const DateRangeDropdown = ({
  predefinedRanges,
  setRange,
  selectedRange,
  id,
  maxDate,
  minDate,
}) => {
  const { value: isOpen, toggle } = useOpenClose({
    initialValue: false,
  })

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle
        color="light"
        className="border w-100 d-flex justify-content-between align-items-center p-2"
      >
        <FormattedMessage
          id={`app.common.${selectedRange.id}`}
          defaultMessage={selectedRange.formattedDate}
        />
        {isOpen ? (
          <i className="fas fa-angle-up ml-2" />
        ) : (
          <i className="fas fa-angle-down ml-2" />
        )}
      </DropdownToggle>
      <DropdownMenu
        persist
        right
        className="p-0 m-0"
        style={{ minWidth: "200%", right: "0" }}
      >
        <Row className="mx-0">
          <Col xs={12} sm={6} className="p-2 border">
            <DatePicker
              minDate={minDate}
              selectRange={compose(toggle, setRange)}
              maxDate={maxDate}
              id={id}
            />
          </Col>
          <Col xs={12} sm={6} className="px-0 border">
            {predefinedRanges.map(range => (
              <DropdownItem
                className="p-2 text-dark"
                onClick={() => setRange(range)}
                key={range.id}
                active={range.id === selectedRange.id}
              >
                <FormattedMessage
                  id={`app.common.${range.id}`}
                  defaultMessage={range.formattedDate}
                />
              </DropdownItem>
            ))}
          </Col>
        </Row>
      </DropdownMenu>
    </Dropdown>
  )
}

DateRangeDropdown.propTypes = {
  predefinedRanges: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  setRange: PropTypes.func.isRequired,
  selectedRange: PropTypes.object,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  id: PropTypes.string.isRequired,
}

DateRangeDropdown.defaultProps = {
  predefinedRanges: [],
}
