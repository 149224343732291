import React, { Fragment } from "react"
import { MatchingWidget } from "../widgets/matching-widget/matching-widget"
import { TalentAffiliationWidget } from "../widgets/talent-affiliation-widget/talent-affiliation-widget"

export function DashboardSidebar() {
  return (
    <Fragment>
      <div className="mb-4">
        <TalentAffiliationWidget />
      </div>
      <div className="mb-0">
        <MatchingWidget />
      </div>
    </Fragment>
  )
}
