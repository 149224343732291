import * as Sentry from "@sentry/react"
import { LOGOUT, SUCCESS } from "../constants"
import { getActionType, getAnonymousEmail } from "../lib/helpers"
import { store } from "../lib/store"

let isUserSet = false
export const sentryUserMiddleware = ({ getState }) => next => action => {
  if (action.type === getActionType(LOGOUT, SUCCESS)) {
    Sentry.configureScope(scope => scope.setUser(null))
    isUserSet = false
    return next(action)
  }

  const managerId = store.getState().manager?.id
  if (managerId && !isUserSet) {
    Sentry.setUser({ email: getAnonymousEmail(managerId), id: managerId })
    isUserSet = true
  }

  next(action)
}
