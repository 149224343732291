import React from "react"
import styles from "./circular-progress.module.scss"
import PropTypes from "prop-types"

export const CircularProgress = props => {
  const { percentage, color } = props

  return (
    <div className={`${styles.c100} ${styles[`p${Math.round(percentage)}`]}`}>
      <div className={styles.slice}>
        <div className={styles.bar} style={{ borderColor: color }} />
        <div className={styles.fill} style={{ borderColor: color }} />
      </div>
      <span className={styles.central} />
    </div>
  )
}

CircularProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}
