import React, { useEffect, Fragment, useContext, useState } from "react"
import { SubscriptionOrderLayout } from "../layouts/subscription-order-layout"
import { SubscriptionInfo } from "./subscription-info"
import { Input } from "../common/input-component"
import { FormattedMessage } from "react-intl"
import { FormattedLink } from "../common/formatted-link-component"
import { useOpenClose } from "../../hooks/use-open-close"
import { Button } from "../common/button-component"
import { PaymentCardForm } from "../payment/payment-card-form"
import { Row, Col } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getCardList } from "../../actions/payment-card-actions"
import {
  defaultCardSelector,
  countSelector as cardsCountSelector,
} from "../../selectors/payment-card-selector"
import { CardInfo } from "../payment/payment-card-info-component"
import { SubscriptionOrderContext } from "./susbscription-order-context"
import { Switch } from "../common/switch-component"
import {
  isSubscriptionUpfront,
  isComSubscriptionIncomplete,
  companySubscriptionSelector,
} from "../../lib/helpers"
import { submit } from "redux-form"
import { List as ListLoader } from "../loaders/list-loader"
import {
  orderCompanySubscriptionWithNewCard,
  orderCompanySubscriptionWithInvoice,
  orderCompanySubscriptionWithDefaultCard,
  retrySubscriptionOrder,
} from "../../actions/company-actions"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { subscriptionOrderingSelector } from "../../selectors/company-selector"
import { useScrollReset } from "../../hooks/use-scroll-reset"
import { PAYMENT_METHODS } from "../../constants"
import { SubscriptionPaymentMethodSelect } from "./subscription-payment-method-select"
import { useStore } from "../../lib/store"

const formName = "PaymentCardForm"

export const SubscriptionPaymentStep = () => {
  useScrollReset()
  let { subscription, setSubscription, prevStep, coupon } = useContext(
    SubscriptionOrderContext,
  )
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.CARD)

  const {
    value: isEditingCard,
    open: openEditing,
    close: closeEditing,
  } = useOpenClose({ initialValue: false })

  const { value: areTermsTouched, open: touchTerms } = useOpenClose({
    initialValue: false,
  })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCardList())
  }, [dispatch])
  const defaultCard = useSelector(defaultCardSelector)
  const cardsCount = useSelector(cardsCountSelector)
  const isOrderingSubscription = useSelector(subscriptionOrderingSelector)
  const companySubscription = useStore(companySubscriptionSelector)

  const { value: areTermsAccepted, toggle: toggleTermsAccepted } = useOpenClose(
    {
      initialValue: false,
    },
  )

  const stripe = useStripe()
  const elements = useElements()

  const isDefaultCardAllowed =
    Boolean(defaultCard) && !isComSubscriptionIncomplete(companySubscription)

  return (
    <SubscriptionOrderLayout>
      <SubscriptionOrderLayout.Content>
        <h6 className="text-uppercase text-muted text-smaller">
          <FormattedMessage id="app.page.subscription.order.payment.select.payment.method" />
        </h6>
        <SubscriptionPaymentMethodSelect
          onSetPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
          isInvoiceDisabled={
            Boolean(defaultCard) ||
            cardsCount === null ||
            isComSubscriptionIncomplete(companySubscription)
          }
          isLoading={isOrderingSubscription}
        />

        {cardsCount === null ? (
          <Col xs={{ size: 8, offset: 2 }}>
            <ListLoader />
          </Col>
        ) : (
          <Fragment>
            {paymentMethod === PAYMENT_METHODS.CARD && (
              <Fragment>
                {!isDefaultCardAllowed || isEditingCard ? (
                  <PaymentCardForm
                    onSubmit={data => {
                      if (!areTermsAccepted) {
                        return
                      }
                      if (isComSubscriptionIncomplete(companySubscription)) {
                        dispatch(
                          retrySubscriptionOrder({
                            ...data,
                            stripe,
                            elements,
                            redirectTo: "/subscription/jobs-conditions",
                          }),
                        )
                      } else {
                        dispatch(
                          orderCompanySubscriptionWithNewCard({
                            ...data,
                            coupon,
                            stripe,
                            elements,
                            subscriptionId: subscription.id,
                            redirectTo: "/subscription/jobs-conditions",
                          }),
                        )
                      }
                    }}
                    form={formName}
                  />
                ) : (
                  <Fragment>
                    <h6 className="text-center">
                      <FormattedMessage id="app.page.subscription.order.payment.card.charged" />
                    </h6>
                    <CardInfo
                      cardType={defaultCard.brand}
                      key={defaultCard.id}
                      ownerName={defaultCard.ownerName}
                      lastNumbers={defaultCard.lastNumbers}
                      expiryDate={`${defaultCard.expMonth}/${defaultCard.expYear}`}
                    />
                    <Button
                      onClick={openEditing}
                      color="light"
                      disabled={isOrderingSubscription}
                    >
                      <FormattedMessage id="app.page.payments.button.replace" />
                    </Button>
                  </Fragment>
                )}

                {isEditingCard && isDefaultCardAllowed && (
                  <Button
                    color="light"
                    onClick={closeEditing}
                    disabled={isOrderingSubscription}
                  >
                    <FormattedMessage id="app.common.cancel" />
                  </Button>
                )}
              </Fragment>
            )}
          </Fragment>
        )}

        {paymentMethod === PAYMENT_METHODS.INVOICE && (
          <div className="py-3">
            <FormattedMessage id="app.page.subscription.order.payment.method.invoice.description" />
          </div>
        )}
        <hr />
        <Row noGutters className="mt-3">
          <h6 className="text-uppercase text-muted text-smaller">
            <FormattedMessage id="app.page.subscription.order.calculator.upfront.label" />
          </h6>
        </Row>
        <Row noGutters>
          <Switch
            id="upfront"
            disabled={
              isOrderingSubscription ||
              isComSubscriptionIncomplete(companySubscription)
            }
            input={{
              value: isSubscriptionUpfront(subscription),
              checked: isSubscriptionUpfront(subscription),
              onChange: ({ target: { checked } }) =>
                setSubscription({ upfront: checked }),
            }}
            meta={{}}
          />
        </Row>
        {!isComSubscriptionIncomplete(companySubscription) && (
          <Row noGutters>
            <Button
              onClick={prevStep}
              color="secondary"
              outline
              disabled={isOrderingSubscription}
            >
              &larr; <FormattedMessage id="app.common.back" />
            </Button>
          </Row>
        )}
      </SubscriptionOrderLayout.Content>
      <SubscriptionOrderLayout.Sidebar>
        <SubscriptionInfo />
        <div className="d-flex flex-column">
          <Input
            id="terms"
            name="terms"
            disabled={isOrderingSubscription}
            label={
              <FormattedMessage
                id="app.page.subscription.order.confirm.terms"
                values={{
                  a: (...children) => (
                    <FormattedLink
                      href="termsPdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {children}
                    </FormattedLink>
                  ),
                  downloadIcon: <i className="fas fa-download" />,
                }}
              />
            }
            type="checkbox"
            input={{
              checked: areTermsAccepted,
              onChange: toggleTermsAccepted,
            }}
            meta={{
              error: { id: "app.validators.value.required" },
              invalid: !areTermsAccepted,
              touched: areTermsTouched,
            }}
            isRequired
          />
          <Button
            color="primary"
            disabled={isOrderingSubscription}
            loading={isOrderingSubscription}
            onClick={() => {
              touchTerms()
              if (
                paymentMethod === PAYMENT_METHODS.INVOICE &&
                areTermsAccepted
              ) {
                dispatch(
                  orderCompanySubscriptionWithInvoice({
                    subscriptionId: subscription.id,
                    coupon,
                    redirectTo: "/subscription/jobs-conditions",
                  }),
                )
              } else if (isEditingCard || !isDefaultCardAllowed) {
                dispatch(submit(formName))
              } else if (areTermsAccepted) {
                dispatch(
                  orderCompanySubscriptionWithDefaultCard({
                    subscriptionId: subscription.id,
                    coupon,
                    stripe,
                    redirectTo: "/subscription/jobs-conditions",
                  }),
                )
              }
            }}
          >
            <FormattedMessage id="app.common.confirm" />
          </Button>
        </div>
      </SubscriptionOrderLayout.Sidebar>
    </SubscriptionOrderLayout>
  )
}
