import { FormattedMessage } from "react-intl"
import { format } from "date-fns"

import { useOpenClose } from "../../../../hooks/use-open-close"
import { Img } from "../../../common/img-component"
import { FormattedLink } from "../../../common/formatted-link-component"
import { DashboardWidgetLayout } from "../../../layouts/dashboard/dashboard-widget-layout/dashboard-widget-layout"
import { VirtualRecruiterModal } from "../../../modals/virtual-recruiter-modal/virtual-recruiter-modal"

import clapsIcon from "../../../../assets/img/clap-icon.png"
import styles from "./vr-widget-active-campaign.module.scss"

const calendarLink = `https://calendly.com/moberries-dustin-hillemacher/30min?month=${format(
  new Date(),
  "yyyy-MM",
)}`

export const VRWidgetActiveCampaign = () => {
  const {
    value: isModalVisible,
    open: showModal,
    close: hideModal,
  } = useOpenClose({ initialValue: false })

  return (
    <DashboardWidgetLayout
      title={<FormattedMessage id="app.widget.virtualRecruiter.title" />}
    >
      <div className={styles.wrapper}>
        <Img className={styles.icon} src={clapsIcon} width="33" height="33" />
        <p className={styles.title}>
          <FormattedMessage id="app.widget.virtualRecruiter.congratulations" />
        </p>
        <p className={styles.subtitle}>
          <FormattedMessage id="app.widget.virtualRecruiter.campaignLaunched" />
        </p>
        <div className={styles.actions}>
          <button className={styles.actionsButton} onClick={showModal}>
            <FormattedMessage id="app.widget.virtualRecruiter.myCampaigns" />
          </button>
          <span className={styles.actionsDivider}></span>
          <a
            href={calendarLink}
            target="_blank"
            rel="noreferrer"
            className={styles.actionsButton}
          >
            <FormattedMessage id="app.widget.virtualRecruiter.addCampaign" />
          </a>
        </div>
        <p className={styles.more}>
          <FormattedMessage
            id="app.widget.virtualRecruiter.moreInfo"
            values={{
              a: text => (
                <FormattedLink
                  className="align-baseline p-0 border-0 d-inline-block"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="virtualRecruiter"
                >
                  {text}
                </FormattedLink>
              ),
            }}
          />
        </p>
      </div>

      <VirtualRecruiterModal isOpen={isModalVisible} toggle={hideModal} />
    </DashboardWidgetLayout>
  )
}
