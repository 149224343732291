import React from "react"
import { times } from "ramda"
import { List } from "../loaders/list-loader"

export function InvoicesSkeleton() {
  return (
    <div className="mt-2">
      {times(
        i => (
          <List key={i} />
        ),
        4,
      )}
    </div>
  )
}
