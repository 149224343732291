import { createSelector } from "reselect"

export const stateSelector = state => state.match

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading,
)

export const errorSelector = createSelector(stateSelector, state => state.err)

export const countSelector = createSelector(stateSelector, state => state.count)

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities,
)

export const byStatusSelector = createSelector(
  stateSelector,
  state => state.byStatus,
)

export const matchesIdsByStatusSelector = createSelector(
  byStatusSelector,
  (_, { status }) => status,
  (byStatus, status) => byStatus[status],
)

export const updatingSelector = createSelector(
  stateSelector,
  state => state.updating,
)

export const acceptingSelector = createSelector(
  stateSelector,
  state => state.accepting,
)

export const decliningSelector = createSelector(
  stateSelector,
  state => state.declining,
)

export const approvingSelector = createSelector(
  stateSelector,
  state => state.approving,
)

export const rejectingSelector = createSelector(
  stateSelector,
  state => state.rejecting,
)

export const nextMatchIdSelector = createSelector(
  stateSelector,
  state => state.nextMatch,
)

export const matchByIdSelector = createSelector(
  entitiesSelector,
  (_, { id }) => parseInt(id, 10),
  (entities, id) => entities[id],
)

export const nextMatchSelector = createSelector(
  entitiesSelector,
  nextMatchIdSelector,
  (entities, id) => entities[id],
)

export const matchesSelector = createSelector(
  entitiesSelector,
  (_, { status }) => matchesIdsByStatusSelector(_, { status }),
  (entities, ids) => ids.map(id => entities[id]),
)

export const matchRejectedReasonsByMatchSelector = createSelector(
  stateSelector,
  (_, { matchId }) => matchId,
  (state, matchId) => state.matchRejectedReasonsByMatch[matchId],
)

export const foundSearchTermSelector = createSelector(
  stateSelector,
  state => state.foundSearchTerm,
)

export const matchesForOtherPositionsSelector = createSelector(
  matchesSelector,
  (_, { id }) => id,
  (matches, jobId) => matches.filter(match => match.job.id !== jobId),
)

export const feedbackSummarySelector = createSelector(
  stateSelector,
  state => state.feedbackSummary,
)

export const isFeedbackSummaryLoadingSelector = createSelector(
  stateSelector,
  state => state.isFeedbackSummaryLoading,
)

export const applicationsStatisticsSelector = createSelector(
  stateSelector,
  state => state.applicationsStatistics,
)

export const isApplicationsStatisticsLoadingSelector = createSelector(
  stateSelector,
  state => state.isApplicationsStatisticsLoading,
)
