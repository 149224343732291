import { reject } from "ramda"
import { INTEGRATION_STATE_FILTER_OPTIONS } from "../../constants"

export const isJobConnected = job => Boolean(job.moberriesStatus)

export const filterJobsByStatus = (status, openJobs) => {
  if (status === INTEGRATION_STATE_FILTER_OPTIONS.CONNECTED.value) {
    return openJobs.filter(isJobConnected)
  } else if (status === INTEGRATION_STATE_FILTER_OPTIONS.NOT_CONNECTED.value) {
    return reject(isJobConnected, openJobs)
  } else {
    return openJobs
  }
}

export const normalizeAtsIntegrationJob = atsIntegrationJob => {
  if (
    atsIntegrationJob.providerJob &&
    typeof atsIntegrationJob.providerJob === "object"
  ) {
    return {
      ...atsIntegrationJob,
      providerJob: atsIntegrationJob.providerJob.id,
    }
  }
  return atsIntegrationJob
}

export const getProviderBySlug = (providerList = [], slug) => {
  return providerList.find(provider => provider.slug === slug)
}
