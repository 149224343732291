import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { NavLink as RLink, Route, Switch } from "react-router-dom"
import { Nav, NavItem, NavLink } from "reactstrap"
import { DoubleColumnLayout } from "../components/layouts/double-column-layout"
import { MonetizationDetailsTab } from "../components/talent-monetization/monetization-details-tab"
import { MonetizationGeneralTab } from "../components/talent-monetization/monetization-general-tab"
import PropTypes from "prop-types"
import { MonetizationOverviewWidget } from "../components/talent-monetization/monetization-overview-widget"
import { MonetizationInviteWidget } from "../components/talent-monetization/monetization-invite-widget"
import { useStore } from "../lib/store"

export const MonetizationDashboardPage = ({ match: { path } }) => {
  const company = useStore(state => state.company)
  return (
    <Fragment>
      <DoubleColumnLayout>
        <DoubleColumnLayout.Content>
          <Fragment>
            <Nav tabs justified className="settings-tabs mt-2">
              <NavItem>
                <NavLink
                  tag={RLink}
                  to="/monetization-dashboard"
                  exact
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage id="app.page.monetization.tabs.general" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RLink}
                  to="/monetization-dashboard/details"
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage id="app.page.monetization.tabs.details" />
                </NavLink>
              </NavItem>
            </Nav>
            <div className="bg-white p-3 rounded">
              <Switch>
                <Route
                  path={path}
                  exact
                  component={props => (
                    <MonetizationGeneralTab {...props} company={company} />
                  )}
                />
                <Route
                  path={`${path}/details`}
                  component={props => <MonetizationDetailsTab {...props} />}
                />
              </Switch>
            </div>
          </Fragment>
        </DoubleColumnLayout.Content>
        <DoubleColumnLayout.Sidebar>
          <MonetizationOverviewWidget />
          <MonetizationInviteWidget />
        </DoubleColumnLayout.Sidebar>
      </DoubleColumnLayout>
    </Fragment>
  )
}

MonetizationDashboardPage.propTypes = {
  match: PropTypes.object.isRequired,
}
