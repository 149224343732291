import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap"

export const RejectCandidateForReasonsModal = ({
  isOpen,
  confirm,
  cancel,
  loading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={cancel} centered>
      <ModalHeader toggle={cancel}>
        <FormattedMessage id="app.common.confirm" />
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <FormattedMessage id="app.modal.confirm.reject.candidate" />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" disabled={loading} onClick={cancel}>
          <FormattedMessage id="app.common.cancel" />
        </Button>
        <Button
          color="primary"
          onClick={confirm}
          disabled={loading}
          loading={loading}
        >
          <FormattedMessage id="app.common.confirm" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

RejectCandidateForReasonsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

RejectCandidateForReasonsModal.defaultProps = {
  isOpen: false,
  loading: false,
}
