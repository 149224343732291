import React from "react"
import { useStore } from "../lib/store"
import { pathOr, propOr } from "ramda"
import { Container, Row, Col, PopoverBody } from "reactstrap"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { AtsIntegrationProviderCard } from "../components/integration/ats-integration-provider-card-component"
import { FormattedMessage } from "react-intl"
import { OnOffSign } from "../components/common/on-off-sign-component"
import { InfoHint } from "../components/common/info-hint-component"
import { Button } from "../components/common/button-component"
import { Link } from "react-router-dom"
import { FormattedLink } from "../components/common/formatted-link-component"
import { useRouteMatch } from "react-router"
import { getProviderBySlug } from "../lib/helpers"

export const AtsIntegrationProviderPage = () => {
  const locale = useStore(state => state.locale)
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const providers = useStore(state => state.providers)

  const routeMatch = useRouteMatch()

  const selectedProvider = getProviderBySlug(providers, routeMatch.params.slug)

  const jobImportOptions = [
    {
      value: pathOr(
        false,
        ["options", "jobSyncType", "auto"],
        selectedProvider,
      ),
      titleKey: "app.page.integrations.settings.jobsImport.auto",
    },
    {
      value: pathOr(
        false,
        ["options", "jobSyncType", "manual"],
        selectedProvider,
      ),
      titleKey: "app.page.integrations.settings.jobsImport.manual",
    },
  ]

  const matchExportOptions = [
    {
      value: pathOr(
        false,
        ["options", "matchExportType", "onComAccept"],
        selectedProvider,
      ),
      titleKey: "app.page.integrations.settings.applicationExport.onAccept",
    },
    {
      value: pathOr(
        false,
        ["options", "matchExportType", "onCanAccept"],
        selectedProvider,
      ),
      titleKey:
        "app.page.integrations.settings.applicationExport.onApplication",
    },
  ]

  return (
    <SmallSingleColumnLayout>
      <AtsIntegrationProviderCard
        provider={selectedProvider}
        className="mb-2"
        isConnected={
          propOr("", "provider", atsCompanyIntegration) ===
          selectedProvider.slug
        }
        controls={
          <Button
            className="btn-pill"
            outline
            color="primary"
            tag={FormattedLink}
            href="atsIntegrationsGuide"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-book-open mr-md-1" />
            <span className="d-none d-md-inline">
              <FormattedMessage id="app.page.integrations.guide" />
            </span>
          </Button>
        }
      />
      <div className="bg-white rounded">
        <Container className="pt-3">
          <p>{pathOr("", ["description", locale], selectedProvider)}</p>
          <p>
            <FormattedMessage
              id="app.page.integrations.connect.descriptionPostText"
              values={{
                here: (
                  <a
                    href={propOr("", locale, selectedProvider.guideUrl)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="app.page.integrations.connect.descriptionPostText.here" />
                  </a>
                ),
              }}
            />
          </p>

          <h4 className="mt-5 mb-0">
            <FormattedMessage id="app.page.integrations.settings.availableOptions" />
          </h4>
          <Row className="p-3">
            <Col className="px-0" xs="12" md="6">
              <h6 className="mb-3">
                <FormattedMessage id="app.page.integrations.settings.jobsImport.header" />
                <InfoHint
                  id="ats-jobs-import-setting"
                  color="primary"
                  popover={
                    <PopoverBody>
                      <FormattedMessage id="app.page.integrations.settings.jobsImport.hint" />
                    </PopoverBody>
                  }
                />
              </h6>
              {jobImportOptions.map(option => (
                <div
                  className="mb-2 d-flex align-items-center"
                  key={option.titleKey}
                >
                  <OnOffSign className="mr-2" value={option.value} />
                  <span className="text-smaller">
                    <FormattedMessage id={option.titleKey} />
                  </span>
                </div>
              ))}
            </Col>
            <Col className="px-0 mt-3 mt-md-0" xs="12" md="6">
              <h6 className="mb-3">
                <FormattedMessage id="app.page.integrations.settings.applicationExport.header" />
                <InfoHint
                  id="ats-application-export-setting"
                  color="primary"
                  popover={
                    <PopoverBody>
                      <FormattedMessage id="app.page.integrations.settings.applicationExport.hint" />
                    </PopoverBody>
                  }
                />
              </h6>
              {matchExportOptions.map(option => (
                <div
                  className="mb-2 d-flex align-items-center"
                  key={option.titleKey}
                >
                  <OnOffSign className="mr-2" value={option.value} />
                  <span className="text-smaller">
                    <FormattedMessage id={option.titleKey} />
                  </span>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
        <Container className="border-top">
          <Row className="p-3">
            <Col className="p-0">
              <Button tag={Link} color="light" to="/integrations/ats">
                <FormattedMessage id="app.page.integrations.settings.buttons.showAllIntegrations" />
              </Button>
            </Col>
            <Col className="p-0 d-flex justify-content-end">
              {!atsCompanyIntegration && (
                <Button
                  color="primary"
                  tag={Link}
                  to={`/integrations/ats/${selectedProvider.slug}/connect`}
                >
                  <FormattedMessage id="app.page.integrations.settings.buttons.connectNow" />
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </SmallSingleColumnLayout>
  )
}
