import React from "react"
import PropTypes from "prop-types"
import { FormGroup, Label } from "reactstrap"
import BTextarea from "react-textarea-autosize"
import FormFeedback from "./form-feedback"
import cn from "classnames"

export function Textarea(props) {
  const {
    input,
    meta,
    options,
    label,
    underInput,
    underFeedback,
    underLabel,
    hint,
    className,
    isRequired,
    ...rest
  } = props

  const { error, valid, invalid, touched } = meta

  const css = cn(
    {
      "is-invalid": !touched || valid ? null : invalid,
      "is-valid": !touched || valid ? null : valid,
    },
    className,
    "form-control",
  )

  return (
    <FormGroup>
      {label && (
        <Label for={rest.id}>
          {label}
          {isRequired && "*"}
        </Label>
      )}
      {hint}
      {underLabel}
      <BTextarea {...rest} {...input} className={css} />
      {underInput}
      <FormFeedback error={error} />
      {underFeedback}
    </FormGroup>
  )
}

Textarea.propTypes = {
  label: PropTypes.node,
  hint: PropTypes.node,
  underInput: PropTypes.node,
  underFeedback: PropTypes.node,
  underLabel: PropTypes.node,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
}

Textarea.defaultProps = {
  type: "textarea",
  isRequired: false,
}
