import React from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/react"
import { connect } from "react-redux"
import { releaseSelector } from "./selectors/system-selector"
import { ReleasePage } from "./pages/release-page"
import { Routes } from "./routes"
import { ScrollToTop } from "./components/common/scroll-to-top"
import { ProductUpdates } from "./components/common/product-updates-component"
import { Head } from "./components/common/head-component"
import { TypeFormWidget } from "./components/forms/typeform-widget"
import { VersionUpdatePrompt } from "./components/modals/version-update-prompt"
import { ToastContainer, Slide } from "react-toastify"
import { ErrorPage } from "./pages/error-page"
import { Hotjar } from "./components/common/hotjar/hotjar"

function AppComponent({ release }) {
  if (release) {
    return <ReleasePage />
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <ErrorPage err={error} />
      }}
    >
      <ToastContainer
        autoClose={5000}
        closeOnClick={false}
        hideProgressBar={true}
        transition={Slide}
      />
      <Head />
      <Hotjar />
      <ProductUpdates />
      <ScrollToTop />
      <Routes />
      <TypeFormWidget />
      <VersionUpdatePrompt />
    </Sentry.ErrorBoundary>
  )
}

AppComponent.propTypes = {
  release: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    release: releaseSelector(state),
  }
}

export const App = connect(mapStateToProps)(AppComponent)
