import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormGroup, Input } from "reactstrap"
import ReactSelect, { components as defaultComponents } from "react-select"
import AsyncSelect from "react-select/async"
import FormFeedback from "./form-feedback"
import { FormattedMessage } from "react-intl"
import { MenuListPoweredByGoogle } from "./menu-list-powered-by-google"
import { LabelWithHint } from "./label-with-hint-component"
import cn from "classnames"
import { getSelectCustomStyles } from "../../lib/custom-styles"

export class Select extends Component {
  static propTypes = {
    label: PropTypes.node,
    hint: PropTypes.node,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    async: PropTypes.bool.isRequired,
    underInput: PropTypes.node,
    wrapper: PropTypes.elementType,
    underLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    components: PropTypes.object.isRequired,
    withCaret: PropTypes.bool.isRequired,
    withGoogleLogo: PropTypes.bool.isRequired,
    className: PropTypes.string,
    isRequired: PropTypes.bool,
  }

  static defaultProps = {
    async: false,
    components: {},
    withCaret: false,
    withGoogleLogo: false,
    placeholder: <FormattedMessage id="app.common.select" />,
    isRequired: false,
  }

  render() {
    const {
      input,
      meta,
      label,
      hint,
      async,
      underInput,
      underLabel,
      wrapper,
      components,
      withCaret,
      withGoogleLogo,
      className,
      isRequired,
      ...rest
    } = this.props
    const { error, touched, valid, invalid } = meta

    const Component = async ? AsyncSelect : ReactSelect

    const WrapperComponent = wrapper || FormGroup

    return (
      <WrapperComponent>
        <LabelWithHint
          label={label}
          hint={hint}
          for={rest.id}
          className="mb-2"
          isRequired={isRequired}
        />
        {typeof underLabel === "function" ? underLabel(this.props) : underLabel}
        <Component
          noOptionsMessage={() => (
            <FormattedMessage id="app.common.type.to.search" />
          )}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={input.onFocus}
          name={input.name}
          value={input.value}
          className={cn("Select", className)}
          classNamePrefix="Select"
          components={{
            DropdownIndicator: withCaret
              ? defaultComponents.DropdownIndicator
              : null,
            MenuList: withGoogleLogo
              ? MenuListPoweredByGoogle
              : defaultComponents.MenuList,
            IndicatorSeparator: null,
            ...components,
          }}
          styles={getSelectCustomStyles({ invalid, touched })}
          {...rest}
        />
        {underInput}
        <Input
          type="hidden"
          valid={!touched || valid ? null : valid}
          invalid={!touched || valid ? null : invalid}
        />
        <FormFeedback error={error} />
      </WrapperComponent>
    )
  }

  handleChange = value => {
    this.props.input.onChange(value)
  }

  handleBlur = () => {
    // https://www.firehydrant.io/blog/using-react-select-with-redux-form/
    if (this.props.input.onBlur) {
      setTimeout(() => this.props.input.onBlur(this.props.input.value), 1)
    }
  }
}
