import React, { useEffect, useState } from "react"
import { evolve, head, propEq, reject } from "ramda"
import { useStore } from "../../lib/store"
import { isLoggedInSelector } from "../../lib/helpers"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { api, ApiKeys } from "../../lib/moberries-api"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { useQueryClient } from "react-query"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { FormattedRelative } from "./formatted-relative"
import { FormattedMessage } from "react-intl"
import { Button } from "./button-component"
import { usePrevious } from "react-hanger"

export const ProductUpdates = () => {
  const isLogged = useStore(isLoggedInSelector)
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)

  const { data: { results: updates = [] } = {}, refetch } = useAxiosQuery(
    [ApiKeys.ProductUpdates],
    api.getProductUpdates,
    {
      enabled: isLogged,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const { mutate: markAsRead, isLoading } = useAxiosMutation(
    api.markProductUpdateAsRead,
    {
      onSuccess: ({ productUpdate: id }) => {
        queryClient.setQueryData(
          [ApiKeys.ProductUpdates],
          evolve({
            results: reject(propEq("id", id)),
          }),
        )
      },
    },
  )

  useEffect(() => {
    if (isLogged) {
      refetch()
    }
  }, [isLogged, refetch])

  const update = head(updates)

  const onDismiss = id => {
    markAsRead({ id })
    setIsOpen(false)
  }

  const prevUpdate = usePrevious(update)
  // In order for the modal to not be empty as it is being dismissed,
  // We keep the `prevUpdate` and display it
  const { title, releasedAt, image, video, text, id } =
    update || prevUpdate || {}

  useEffect(() => {
    if (id) {
      setIsOpen(true)
    }
  }, [id, setIsOpen])

  return (
    <Modal isOpen={isOpen} toggle={() => onDismiss(id)} unmountOnClose>
      <ModalHeader toggle={() => onDismiss(id)}>{title}</ModalHeader>
      <ModalBody>
        <p className="text-muted">
          {releasedAt && <FormattedRelative timestamp={releasedAt} />}
        </p>
        {image && (
          <div className="mb-4 text-center">
            <img className="img-fluid" src={image} alt="Update" />
          </div>
        )}
        {video && (
          <div className="mb-4 embed-responsive embed-responsive-16by9">
            <video
              src={video}
              title="Update"
              className="embed-responsive-item"
              controls
              allowFullScreen
            />
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => onDismiss(id)}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="app.common.ok" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}
