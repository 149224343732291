const configs = {
  borderSkipped: "start",
  callbackLabel: function (value) {
    return `${value.dataset.label}: ${value.formattedValue}%`
  },
  callbackX: function (value) {
    return value + "%"
  },
}

const isZero = f => f === 0

const format = e => e.map(f => f.percentage)

export const empty = (data = []) => format(data).every(isZero)

export const mapper = (data = []) => ({
  configs: configs,
  labels: data.map(f => f.name),
  datasets: [
    {
      label: "Popularity Rate",
      backgroundColor: "#42c4f9",
      data: format(data),
    },
  ],
})
