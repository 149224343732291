import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useFormikContext } from "formik"
import { FormattedMessage } from "react-intl"
import { LabeledInputField } from "../fields/input-field"
import { LabeledSwitchField } from "../fields/switch-field"
import enFlag from "../../assets/img/en.svg"
import deFlag from "../../assets/img/de.svg"
import { UncontrolledButtonDropdown } from "../common/button-dropdown-component"
import { DropdownItem, DropdownMenu, DropdownToggle, Label } from "reactstrap"

export const ManagerProfileFormFields = ({ manager }) => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <Fragment>
      <legend>
        <FormattedMessage id="app.page.account.personal.form.legend" />
      </legend>
      <LabeledInputField
        label={<FormattedMessage id="app.form.name.first" />}
        name="firstName"
        id="firstName"
        placeholder="John"
        isRequired
      />
      <LabeledInputField
        label={<FormattedMessage id="app.form.name.last" />}
        name="lastName"
        id="lastName"
        placeholder="Doe"
        isRequired
      />
      <LabeledSwitchField
        name="notifyAlerts"
        id="notifyAlerts"
        label={<FormattedMessage id="app.page.account.personal.form.alerts" />}
      />

      {manager.teams.length > 1 && (
        <Fragment>
          <Label>
            <FormattedMessage id="app.page.account.personal.form.teams" />
          </Label>
          <div className="tags-wrapper mt-2 mb-5">
            {manager.teams.map(d => (
              <span key={d.id} className="badge badge-default">
                {d.name}
              </span>
            ))}
          </div>
        </Fragment>
      )}
      <UncontrolledButtonDropdown className="btn-block">
        <DropdownToggle color="light" size="lg" block caret>
          {values.locale === "en" && (
            <Fragment>
              <img src={enFlag} width="32" height="32" alt="enFlag" />{" "}
              <FormattedMessage id="app.common.english" />
            </Fragment>
          )}
          {values.locale === "de" && (
            <Fragment>
              <img src={deFlag} width="32" height="32" alt="deFlag" />{" "}
              <FormattedMessage id="app.common.german" />
            </Fragment>
          )}
        </DropdownToggle>
        <DropdownMenu className="btn-block">
          <DropdownItem onClick={() => setFieldValue("locale", "en")}>
            <Fragment>
              <img src={enFlag} width="32" height="32" alt="enFlag" />{" "}
              <FormattedMessage id="app.common.english" />
            </Fragment>
          </DropdownItem>
          <DropdownItem onClick={() => setFieldValue("locale", "de")}>
            <Fragment>
              <img src={deFlag} width="32" height="32" alt="deFlag" />{" "}
              <FormattedMessage id="app.common.german" />
            </Fragment>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </Fragment>
  )
}

ManagerProfileFormFields.propTypes = {
  manager: PropTypes.object.isRequired,
}
