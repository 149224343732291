import { emitter } from "../lib/event-emitter"
import { socket as moWsApi } from "../lib/mo-ws-api"
import { store } from "../lib/store"

export const connectWs = () => {
  let unsubscribe

  moWsApi.on("connect", () => {
    if (unsubscribe) unsubscribe()
    let { jwt } = store.getState()

    moWsApi.emit("authStateChange", { token: jwt })

    unsubscribe = store.subscribe(
      state => state.jwt,
      ({ jwt: newJwt }) => {
        if (!moWsApi.connected) {
          return
        }

        if (newJwt === jwt) {
          return
        }

        jwt = newJwt

        moWsApi.emit("authStateChange", { token: jwt })
      },
    )
  })
}

export const subscribeToWsActions = () => {
  moWsApi.on("action", action => {
    emitter.emit(action.type, action.payload)
  })
}
