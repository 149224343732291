import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useAxiosMutation } from "../hooks/use-axios-mutation"
import { ThreadList } from "../components/messenger/thread-list-component"
import { Container, Row, Col } from "reactstrap"
import { ThreadTopBar } from "../components/messenger/thread-top-bar-component"
import { NewMessage } from "../components/messenger/new-message-component"
import { DEFAULT_INTRO_MESSAGE_TEMPLATE } from "../default-options"
import { useAxiosQuery } from "../hooks/use-axios-query"
import { api, ApiKeys } from "../lib/moberries-api"
import { useHistory } from "react-router"
import { useBreakpoint } from "../hooks/use-breakpoint"

export const MessengerNewThreadPage = ({ match: routeMatch }) => {
  const history = useHistory()
  const matchId = Number(routeMatch.params.matchId)
  const isDesktop = useBreakpoint("lg")

  const { data: match } = useAxiosQuery(
    [ApiKeys.Match, { id: matchId }],
    api.getMatch,
  )

  const {
    mutateAsync: createThread,
    isLoading: isCreatingThread,
    error: creatingThreadErr,
  } = useAxiosMutation(api.createThread, {
    onSuccess: ({ id }) => {
      history.push(`/messages/${id}`)
    },
  })

  return (
    <Fragment>
      <Container>
        <Row noGutters>
          {isDesktop && (
            <Col xs={5}>
              <ThreadList
                onClickThread={thread => {
                  history.push(`/messages/${thread.id}`)
                }}
              />
            </Col>
          )}
          <Col className="border-left bg-white d-flex flex-column messenger-height">
            <ThreadTopBar
              thread={{ candidate: match?.candidate, match, job: match?.job }}
            />
            <div className="flex-grow-1 overflow-auto h-15" />
            <div>
              <NewMessage
                onSend={({ text, candidateId, jobId }) =>
                  createThread({ message: text, candidateId, jobId })
                }
                creating={isCreatingThread}
                candidate={match?.candidate}
                job={match?.job}
                err={creatingThreadErr}
                defaultTemplate={DEFAULT_INTRO_MESSAGE_TEMPLATE}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

MessengerNewThreadPage.propTypes = {
  match: PropTypes.object.isRequired,
}
