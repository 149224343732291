import { generateDateStringsBetweenDates } from "../../../lib/helpers/date"
import { availableSelections } from "../talent-affiliation/helpers"

export function dailyToMonthlyMapper(initData, field) {
  const map = {}
  for (let i = 0; i < initData.length; i++) {
    const current = initData[i]
    const previous = initData[i - 1]
    const isSameMonth =
      !previous || current.date.split("-")[1] === previous.date.split("-")[1]
    const monthString = new Date(current.date).toDateString().split(" ")[1]
    if (isSameMonth) {
      if (!map[monthString]) map[monthString] = 0
      map[monthString] += current[field]
    } else {
      map[monthString] = current[field]
    }
  }
  return Object.entries(map).map(([month, value]) => ({
    date: month,
    [field]: value,
  }))
}

export function datasetTimeRangeMapper(data, range, field) {
  let initData = data
  const isMonthlyRange = [
    availableSelections[2].label,
    availableSelections[3].label,
  ].includes(range.label)
  if (isMonthlyRange) {
    initData = dailyToMonthlyMapper(data, field)
  }
  const result = {
    data: (initData || []).map(d => d[field]),
    labels: (initData || []).map(d => d.date),
  }
  if (range.label === availableSelections[0].label) {
    const emptyDates = generateDateStringsBetweenDates().map(
      d => d.split("T")[0],
    )
    result.labels.push(...emptyDates)
  }
  if (
    range.label === availableSelections[0].label ||
    range.label === availableSelections[1].label
  ) {
    result.labels = result.labels.map(l => l.split("-")[2])
  }
  return result
}
