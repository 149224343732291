import React from "react"
import ContentLoader from "react-content-loader"

export const DonutChartLoader = props => (
  <ContentLoader viewBox="0 0 380 270" className="py-2" {...props}>
    <rect x="290" y="130" rx="4" ry="4" width="50" height="10" />
    <rect x="280" y="150" rx="3" ry="3" width="80" height="10" />
    <rect x="270" y="170" rx="3" ry="3" width="100" height="10" />
    <circle cx="130" cy="140" r="125" />
  </ContentLoader>
)
