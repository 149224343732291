import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

export const StatisticsData = ({ children, className }) => (
  <ul className={cn("list-unstyled mb-0 border-top", className)}>{children}</ul>
)

StatisticsData.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
