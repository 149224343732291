import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { getJobLevelName, getJobRolesDiff } from "../../lib/helpers"
import { DetailViewProgressBar } from "../detail-view/detail-view-progress-bar-component"
import { JOB_LEVELS } from "../../default-options"
import { FormattedMessage } from "react-intl"

export const MatchRolesComparison = ({ candidate, job }) => {
  const {
    matched: matchedJobRoles,
    missing: missingJobRoles,
    rest: otherJobRoles,
  } = getJobRolesDiff(candidate.jobRoles, job.jobRoles)

  return (
    <Fragment>
      {matchedJobRoles.map(jobRole => (
        <DetailViewProgressBar
          key={jobRole.id}
          total={JOB_LEVELS.length}
          completed={jobRole.level < 0 ? 0 : jobRole.level}
          title={jobRole.name}
          value={<FormattedMessage id={getJobLevelName(jobRole.level)} />}
          type="sufficient"
        />
      ))}
      {otherJobRoles.map(jobRole => (
        <DetailViewProgressBar
          key={jobRole.id}
          total={JOB_LEVELS.length}
          completed={jobRole.level < 0 ? 0 : jobRole.level}
          title={jobRole.name}
          value={<FormattedMessage id={getJobLevelName(jobRole.level)} />}
        />
      ))}
      {missingJobRoles.length > 0 && (
        <div className="text-muted text-uppercase text-smaller mt-3">
          <FormattedMessage id="app.page.match.missingExperience" />
        </div>
      )}

      {missingJobRoles.map(jobRole => (
        <DetailViewProgressBar
          key={jobRole.id}
          total={JOB_LEVELS.length}
          completed={jobRole.level < 0 ? 0 : jobRole.level}
          title={jobRole.name}
          value={<FormattedMessage id={getJobLevelName(jobRole.level)} />}
          type="insufficient"
        />
      ))}
    </Fragment>
  )
}

MatchRolesComparison.propTypes = {
  candidate: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
}
