import React, { Fragment } from "react"
import { prop } from "ramda"
import { IntegrationsPageLayout } from "../components/layouts/integrations-page-layout"
import { FormattedMessage } from "react-intl"
import { TabPane } from "reactstrap"
import { AtsIntegrationProviderCard } from "../components/integration/ats-integration-provider-card-component"
import { Link } from "react-router-dom"
import { AtsNothingConnected } from "../components/integration/ats-nothing-connected-component"
import { Button } from "../components/common/button-component"
import { ATS_JOB_SYNC_OPTIONS } from "../constants"
import { useStore } from "../lib/store"

export const AtsIntegrationsPage = () => {
  const atsCompanyIntegration = useStore(state => state.atsCompanyIntegration)
  const providers = useStore(state => state.providers)
  const connectedProvider = useStore(state => state.connectedProvider)

  let connectedProviderSection = <AtsNothingConnected />

  if (connectedProvider) {
    const isAutoJobSyncEnabled =
      prop("jobSyncType", atsCompanyIntegration) === ATS_JOB_SYNC_OPTIONS.AUTO

    connectedProviderSection = (
      <AtsIntegrationProviderCard
        provider={connectedProvider}
        isConnected
        controls={
          <Fragment>
            {!isAutoJobSyncEnabled && (
              <Button color="primary" tag={Link} to="/integrations/ats/import">
                <i className="fas fa-arrow-alt-circle-down mr-md-1" />
                <span className="d-none d-md-inline">
                  <FormattedMessage id="app.page.integrations.importJobs" />
                </span>
              </Button>
            )}
            <div className="ml-2 bg-white">
              <Button
                outline
                color="secondary"
                tag={Link}
                to={`/integrations/ats/${connectedProvider.slug}/connect`}
              >
                <i className="fas fa-cog mr-md-1" />
                <span className="d-none d-md-inline">
                  <FormattedMessage id="app.page.integrations.manage" />
                </span>
              </Button>
            </div>
          </Fragment>
        }
      />
    )
  }

  return (
    <IntegrationsPageLayout>
      <TabPane>
        <div className="bg-white p-3 mb-1 rounded-bottom">
          {connectedProviderSection}
        </div>
      </TabPane>
      <h3 className="mt-5">
        {connectedProvider ? (
          <FormattedMessage id="app.page.integrations.otherAvailableIntegrations" />
        ) : (
          <FormattedMessage id="app.page.integrations.availableIntegrations" />
        )}
      </h3>

      {providers
        .filter(p => p.active && p.id !== connectedProvider?.id)
        // for more details check MOB-1725
        .filter(p => p.slug !== "successfactors")
        .map(p => (
          <AtsIntegrationProviderCard
            provider={p}
            key={p.id}
            className="mb-1 text-decoration-none"
            tag={Link}
            to={`/integrations/ats/${p.slug}`}
          />
        ))}
    </IntegrationsPageLayout>
  )
}
