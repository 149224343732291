import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js"
import { Line as L } from "react-chartjs-2"

import { COLORS } from "../../../constants"
import PropTypes from "prop-types"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

// TODO: future-improvements: Mutation: mutate Lines func to support charts from Strategy Builder Page and remove LinesV2

export function Lines(props) {
  const { data, labels } = props
  return (
    <L
      options={{
        interaction: { mode: "nearest", intersect: false, axis: "xy" },
        hover: {
          mode: "nearest",
        },
        plugins: {
          tooltip: {
            mode: "nearest",
            titleAlign: "center",
            padding: 14,
            backgroundColor: COLORS.getWhite(),
            titleColor: COLORS.manatee,
            titleFont: { size: 12, weight: "normal" },
            titleMarginBottom: 10,
            bodyColor: COLORS.getBlack(),
            bodyAlign: "center",
            bodyFont: { size: 12, weight: "bold", lineHeight: 1 },
            caretPadding: 16,
            caretSize: 10,
            cornerRadius: 10,
            borderColor: COLORS.getBlack(0.06),
            borderWidth: 1,
            displayColors: false,
            callbacks: {
              title(tooltipItems) {
                return tooltipItems[0].label.split(",")
              },
            },
          },
          legend: {
            display: false,
          },
        },
        elements: {
          line: {
            borderDash: [4],

            tension: 0.4,
            borderWidth: 2,
          },
          point: {
            radius: 0,
          },
        },

        scales: {
          y: {
            min: 0,
            grid: {
              tickLength: 0,
              drawBorder: false,
            },
            ticks: {
              // sampleSize: 1,
              stepSize: 1,
              crossAlign: "far",
              color: COLORS.getBlack(0.4),
            },
          },
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              color: COLORS.getBlack(0.4),
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            label: "Invited",
            data: data[0],
            borderColor: "#615A97",
          },
          {
            label: "Active",
            data: data[1],
            borderColor: "#4C7D90",
          },
          {
            label: "In-Process",
            data: data[2],
            borderColor: "#FF8C00",
          },
          {
            label: "Hired",
            data: data[3],
            borderColor: "#997765",
          },
        ],
      }}
      height={200}
    />
  )
}

export const LinesV2 = ({ configs, labels, datasets }) => {
  const options = {
    interaction: { mode: "nearest", intersect: false, axis: "xy" },
    hover: {
      mode: "nearest",
    },
    plugins: {
      tooltip: {
        mode: "nearest",
        titleAlign: "center",
        padding: 14,
        backgroundColor: COLORS.getWhite(),
        titleColor: COLORS.manatee,
        titleFont: { size: 12, weight: "normal" },
        titleMarginBottom: 10,
        bodyColor: COLORS.getBlack(),
        bodyAlign: "center",
        bodyFont: { size: 12, weight: "bold", lineHeight: 1 },
        caretPadding: 16,
        caretSize: 10,
        cornerRadius: 10,
        borderColor: COLORS.getBlack(0.06),
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          label: configs.callbackLabel,
        },
      },
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 2,
      },
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        grid: {
          tickLength: 0,
          drawBorder: false,
        },
        ticks: {
          crossAlign: "far",
          color: COLORS.getBlack(0.4),
          callback: configs.callbackY,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: COLORS.getBlack(0.4),
        },
      },
    },
  }

  const data = {
    labels,
    datasets: datasets.map(dataset => {
      return {
        label: dataset.label,
        data: dataset.data,
        borderColor: dataset.borderColor,
      }
    }),
  }

  return <L options={options} data={data} height={200} />
}

Lines.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

LinesV2.propTypes = {
  configs: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.object.isRequired,
}
