import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { format, parse } from "date-fns"

import styles from "./candidates-header.module.scss"
import { CandidatesSearch } from "../candidates-search/candidates-search"
import { connect } from "react-redux"
import { campaignSelector } from "../../../../selectors/vr-candidate-selector"

const getDuration = ({ startDate, endDate }) =>
  [startDate, endDate]
    .map(d => parse(d, "yyyy-MM-dd", new Date()))
    .map(d => format(d, "dd.MM.yyyy", new Date()))
    .join(" – ")

const CandidatesHeaderComponent = ({ campaignsOpenClickHandler, campaign }) => (
  <div className={styles.header}>
    <div className={styles.main}>
      <div className={styles.titleWithBackButton}>
        <button className={styles.btnBack} onClick={campaignsOpenClickHandler}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <h2 className={styles.title}>
          <FormattedMessage id="app.modal.virtualRecruiter.listOfCandidates" />
        </h2>
      </div>
      <p className={styles.subtitle}>
        <span>
          <FormattedMessage id="app.modal.virtualRecruiter.jobTitle" />
          {` - `}
        </span>
        {campaign?.jobTitle}
        {` | `}
        <span>
          <FormattedMessage id="app.modal.virtualRecruiter.duration" />
          {` - `}
        </span>
        {campaign &&
          getDuration({
            startDate: campaign?.startDate,
            endDate: campaign?.endDate,
          })}
      </p>
    </div>
    <CandidatesSearch />
  </div>
)

CandidatesHeaderComponent.propTypes = {
  campaignsOpenClickHandler: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  campaign: campaignSelector(state),
})

export const CandidatesHeader = connect(mapStateToProps)(
  CandidatesHeaderComponent,
)
