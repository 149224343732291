import React, { useState, useContext } from "react"
import { api as moberriesApi } from "../../lib/moberries-api"
import { FormattedMessage } from "react-intl"
import { PopoverBody } from "reactstrap"
import { Button } from "../common/button-component"
import { Input } from "../common/input-component"
import { InfoHint } from "../common/info-hint-component"
import { FormattedLink } from "../common/formatted-link-component"
import { SubscriptionOrderContext } from "./susbscription-order-context"
import { head } from "ramda"

export function SubscriptionCoupon() {
  const { coupon, setCoupon } = useContext(SubscriptionOrderContext)
  const [isValidating, setIsValidating] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [error, setError] = useState(null)

  const validateCoupon = async () => {
    setIsValidating(true)
    const {
      data: { results: coupons, count },
    } = await moberriesApi.getCouponList({
      params: {
        stripeCouponId: inputValue,
      },
    })

    setIsValidating(false)

    if (count < 1) {
      const err = new Error("Invalid Coupon")
      err.id = "app.page.subscription.order.coupon.invalid"
      setError(err)
      return
    }

    setError(null)

    const coupon = head(coupons)
    setCoupon(coupon)
  }

  return (
    <div>
      <h6 className="text-uppercase text-muted text-smaller">
        <FormattedMessage id="app.page.subscription.order.coupon.title" />
        <InfoHint
          id="couponHint"
          popover={
            <PopoverBody>
              <FormattedMessage
                id="app.page.subscription.order.coupon.hint"
                values={{
                  a: (...children) => (
                    <FormattedLink
                      target="_blank"
                      href="sharedTalentNetworks"
                      rel="noopener noreferrer"
                    >
                      {children}
                    </FormattedLink>
                  ),
                }}
              />
            </PopoverBody>
          }
        />
      </h6>
      <Input
        appendAddon={
          <Button
            onClick={() => validateCoupon(inputValue)}
            loading={isValidating}
            disabled={inputValue === "" || isValidating || Boolean(coupon)}
          >
            {coupon ? (
              <FormattedMessage id="app.common.applied" />
            ) : (
              <FormattedMessage id="app.common.apply" />
            )}
          </Button>
        }
        meta={{
          touched: true,
          error: error,
          valid: !error,
          invalid: Boolean(error),
        }}
        readOnly={Boolean(coupon)}
        className="_lr-hide"
        name="coupon"
        type="text"
        id="coupon"
        input={{
          value: inputValue,
          onChange: ({ target: { value } }) => setInputValue(value),
        }}
      />
    </div>
  )
}
