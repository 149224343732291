import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import { useSelector } from "react-redux"
import { isSubmitting as isSubmittingSelector } from "redux-form"
import { pick, pipe } from "ramda"
import { CompanyBillingFields } from "./company-billing-fields"
import { Button } from "../common/button-component"
import { SubscriptionOrderLayout } from "../layouts/subscription-order-layout"
import { SubscriptionInfo } from "./subscription-info"
import { SubscriptionOrderContext } from "./susbscription-order-context"
import { useScrollReset } from "../../hooks/use-scroll-reset"
import { useStore } from "../../lib/store"
import { companyBillingSchema } from "../../schemas/company-billing-schema"
import { submitAndValidate } from "../../lib/helpers/form-helpers"
import { SubmitButtonField } from "../fields/submit-button-field"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { api } from "../../lib/moberries-api"
import { getChangedFields } from "../../lib/helpers"
import { refreshCompany } from "../../controllers/company-controller"
import { FirstErrorScroll } from "../fields/first-error-scroll"

const formName = "CompanyBillingForm"

const toRequest = companyBilling => values => {
  const getChangedValues = pipe(
    getChangedFields,
    changedFields => pick(changedFields, values),
    changedObject => pick(Object.keys(companyBilling), changedObject),
  )

  return {
    company: {
      companyBilling: getChangedValues(values, companyBilling),
    },
  }
}

export const SubscriptionBillingStep = () => {
  useScrollReset()
  const companyBilling = useStore(state => state.company.companyBilling)
  const isSubmitting = useSelector(isSubmittingSelector(formName))
  const { prevStep, nextStep } = useContext(SubscriptionOrderContext)

  const { mutateAsync: updateCompany } = useAxiosMutation(api.updateCompany, {
    onSuccess: async () => {
      await refreshCompany()
      nextStep()
    },
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...companyBilling,
        isVatChecked: companyBilling.vatId !== "",
      }}
      onSubmit={submitAndValidate(updateCompany, toRequest(companyBilling))}
      validationSchema={companyBillingSchema}
    >
      <Form>
        <FirstErrorScroll />
        <SubscriptionOrderLayout>
          <SubscriptionOrderLayout.Content>
            <h6 className="text-uppercase text-muted text-smaller">
              <FormattedMessage id="app.page.subscription.order.billing.title" />
            </h6>

            <CompanyBillingFields
              form={formName}
              enableReinitialize
              initialValues={companyBilling}
            />
            <div className="mt-auto">
              <Button
                disabled={isSubmitting}
                onClick={prevStep}
                color="secondary"
                outline
              >
                &larr; <FormattedMessage id="app.common.back" />
              </Button>
            </div>
          </SubscriptionOrderLayout.Content>

          <SubscriptionOrderLayout.Sidebar>
            <SubscriptionInfo />
            <SubmitButtonField>
              <FormattedMessage id="app.common.next" />
            </SubmitButtonField>
          </SubscriptionOrderLayout.Sidebar>
        </SubscriptionOrderLayout>
      </Form>
    </Formik>
  )
}
