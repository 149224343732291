import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { PopoverBody } from "reactstrap"
import { InfoHint } from "../common/info-hint-component"
import { WidgetProgressBar } from "./widget-progress-bar"
import { isNil } from "ramda"

export const StatisticsDataRow = ({
  value,
  ratio,
  labelKey,
  hintKey,
  color,
}) => {
  return (
    <li
      className={classNames({
        "border-bottom": isNil(ratio),
      })}
    >
      <div className="d-flex justify-content-between py-2">
        <div>
          <FormattedMessage id={labelKey} />
          {hintKey && (
            <InfoHint
              id={hintKey.replace(/\./g, "")}
              popover={
                <PopoverBody>
                  <FormattedMessage id={hintKey} />
                </PopoverBody>
              }
            />
          )}
        </div>
        <div>
          {!isNil(ratio) && (
            <span className="text-muted text-smaller mx-2">
              {Math.round(ratio * 100)}%
            </span>
          )}
          <b className={`text-${color}`}>{value}</b>
        </div>
      </div>
      {!isNil(ratio) && <WidgetProgressBar value={ratio} />}
    </li>
  )
}

StatisticsDataRow.propTypes = {
  value: PropTypes.number.isRequired,
  ratio: PropTypes.number,
  labelKey: PropTypes.string.isRequired,
  hintKey: PropTypes.string,
  color: PropTypes.string,
}

StatisticsDataRow.defaultProps = {
  value: 0,
  color: "body",
}
