import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormFeedback, FormGroup, Input, Label, PopoverBody } from "reactstrap"
import { nanoid } from "nanoid"
import { useFormikField } from "../../hooks/use-formik-field"
import { CheckboxGroup } from "../common/checkbox-group-new"
import { InfoHint } from "../common/info-hint-component"
import { FormattedErrorMessage } from "../common/formatted-error-message"

export const CheckboxGroupField = props => {
  const [field, , actions] = useFormikField(props)
  return <CheckboxGroup {...props} {...field} onChange={actions.setValue} />
}

export const LabeledCheckboxGroupField = ({
  label,
  hint,
  isRequired,
  ...rest
}) => {
  const [field, meta, actions] = useFormikField(rest)
  const [hintId] = useState(nanoid())

  return (
    <FormGroup>
      <Label>
        {label}
        {hint && (
          <InfoHint id={hintId} popover={<PopoverBody>{hint}</PopoverBody>} />
        )}
        {isRequired && " *"}
      </Label>
      <CheckboxGroup
        {...rest}
        {...field}
        onChange={newValue => {
          actions.setTouched()
          actions.setValue(newValue)
        }}
      />
      <Input type="hidden" invalid={meta.touched && Boolean(meta.error)} />
      {meta.error && (
        <FormFeedback>
          <FormattedErrorMessage error={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  )
}

LabeledCheckboxGroupField.propTypes = {
  label: PropTypes.node.isRequired,
  hint: PropTypes.node,
  isRequired: PropTypes.bool,
}

LabeledCheckboxGroupField.defaultProps = {
  hint: null,
  isRequired: false,
}
