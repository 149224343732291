import { map } from "ramda"
import { anonymizeCandidate, anonymizeMatch } from "./anonymizer"

export function actionSanitizer(action) {
  const data = { type: action.type }

  if (action && action.payload && action.payload.err) {
    data.payload = {
      err: action.payload.err,
    }
  }

  return data
}

export function stateSanitizer(state) {
  return {
    ...state,
    form: null,
    user: {
      ...state.user,
      token: null,
    },
    candidate: {
      ...state.candidate,
      entities: map(anonymizeCandidate, state.candidate.entities),
    },
    match: {
      ...state.match,
      entities: map(anonymizeMatch, state.match.entities),
    },
  }
}

export function requestSanitizer(request) {
  if (request.headers["Authorization"]) {
    request.headers["Authorization"] = ""
  }

  return request
}

export function responseSanitizer(response) {
  if (response.headers["Authorization"]) {
    response.headers["Authorization"] = ""
  }

  return response
}
