import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Header } from "../header/header-component"
import { Footer } from "../common/footer-component"
import cn from "classnames"
import { PastDueSubscriptionAlert } from "../common/past-due-subscription-alert-component"
import { IncompleteSubscriptionAlert } from "../subscription/incomplete-subscription-alert-component"
import { LoggedInGuard } from "../common/logged-in-guard"

export const BaseLayout = props => {
  const css = cn("main-content", props.className)

  return (
    <Fragment>
      <Header />
      <LoggedInGuard>
        <PastDueSubscriptionAlert />
        <IncompleteSubscriptionAlert />
      </LoggedInGuard>

      <main role="main" className={css}>
        {props.children}
      </main>
      <Footer />
    </Fragment>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
